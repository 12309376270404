import React, { FC } from "react";
import { IFormField } from "./../types";
import { Grid, Form, Button } from "semantic-ui-react";
import { Steps } from "antd";
import { validator } from "./../validator";
import Island from "../../../_components/Island";

const { Step } = Steps;
export const AddressInfo: FC<{
  area: IFormField;
  landMark: IFormField;
  buildingNumber: IFormField;
  streetName: IFormField;
  state: IFormField;
  location: IFormField;
  setBuildingNumber: React.Dispatch<React.SetStateAction<IFormField>>;
  setStreetName: React.Dispatch<React.SetStateAction<IFormField>>;
  setLandMark: React.Dispatch<React.SetStateAction<IFormField>>;
  setArea: React.Dispatch<React.SetStateAction<IFormField>>;
  setLocation: React.Dispatch<React.SetStateAction<IFormField>>;
  setState: React.Dispatch<React.SetStateAction<IFormField>>;
  // clearForm: () => void;
  clearMessage: () => void;
  nextStep: () => void;
  previousStep: () => void;
  step: number;
}> = ({
  buildingNumber,
  setBuildingNumber,
  streetName,
  setStreetName,
  landMark,
  setLandMark,
  area,
  setArea,
  location,
  setLocation,
  state,
  setState,
  clearMessage,
  // clearForm,
  step,
  nextStep,
  previousStep,
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Island header="Create new pro">
            <Steps progressDot current={step}>
              <Step title="Personal Information" />
              <Step title="Address Information" />
              <Step title="Financial Information" />
            </Steps>
            <Form>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  required
                  id="form-subcomponent-shorthand-input-building-number"
                  label="House/Flat No"
                  placeholder="123"
                  value={buildingNumber.value}
                  error={buildingNumber.error}
                  {...(!buildingNumber.initial && {
                    icon: !!validator.validate({
                      buildingNumber: buildingNumber.value.replace(/\s/g, ""),
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  onChange={(e) => {
                    const { value } = e.target;
                    clearMessage();
                    setBuildingNumber((state) => {
                      return {
                        ...state,
                        value,
                        initial: false,
                      };
                    });
                  }}
                  onBlur={() => {
                    const { error } = validator.validate({
                      buildingNumber: buildingNumber.value.replace(/\s/g, ""),
                    });
                    if (error) {
                      setBuildingNumber((state) => {
                        return {
                          ...state,
                          error: {
                            content: "Invalid Name. Enter building name",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setBuildingNumber((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                  }}
                />
                <Form.Input
                  fluid
                  required
                  id="form-subcomponent-shorthand-input-street-name"
                  label="Address Line"
                  placeholder="Harrison Ford Street, Pinnock Beach Estate"
                  value={streetName.value}
                  error={streetName.error}
                  {...(!streetName.initial && {
                    icon: !!validator.validate({
                      streetName: streetName.value.replace(/\s/g, ""),
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  onChange={(e) => {
                    const { value } = e.target;
                    clearMessage();
                    setStreetName((state) => {
                      return {
                        ...state,
                        value,
                        initial: false,
                      };
                    });
                  }}
                  onBlur={() => {
                    const { error } = validator.validate({
                      streetName: streetName.value.replace(/\s/g, ""),
                    });
                    if (error) {
                      setStreetName((state) => {
                        return {
                          ...state,
                          error: {
                            content: "Invalid Name. Enter street name",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setStreetName((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                  }}
                />
                <Form.Input
                  fluid
                  required
                  id="form-subcomponent-shorthand-input-area"
                  label="Area"
                  placeholder="Osapa"
                  value={area.value}
                  error={area.error}
                  {...(!area.initial && {
                    icon: !!validator.validate({
                      area: area.value,
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  onChange={(e) => {
                    const { value } = e.target;
                    clearMessage();
                    setArea((state) => {
                      return {
                        ...state,
                        value,
                        initial: false,
                      };
                    });
                  }}
                  onBlur={() => {
                    const { error } = validator.validate({
                      area: area.value.replace(/\s/g, ""),
                    });
                    if (error) {
                      setArea((state) => {
                        return {
                          ...state,
                          error: {
                            content: "Invalid Name. Enter area",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setArea((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                  }}
                />
              </Form.Group>

              {/* City, Landmark, State  */}

              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  required
                  id="form-subcomponent-shorthand-input-city"
                  label="City"
                  placeholder="Lekki"
                  value={location.value}
                  error={location.error}
                  {...(!location.initial && {
                    icon: !!validator.validate({
                      location: location.value.replace(/\s/g, ""),
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  onChange={(e) => {
                    const { value } = e.target;
                    clearMessage();
                    setLocation((state) => {
                      return {
                        ...state,
                        value,
                        initial: false,
                      };
                    });
                  }}
                  onBlur={() => {
                    const { error } = validator.validate({
                      location: location.value.replace(/\s/g, ""),
                    });
                    if (error) {
                      setLocation((state) => {
                        return {
                          ...state,
                          error: {
                            content: "Invalid Name. Enter city",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setLocation((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                  }}
                />
                <Form.Input
                  fluid
                  id="form-subcomponent-shorthand-input-landmark-name"
                  label="Landmark"
                  placeholder="Muson Center"
                  value={landMark.value}
                  onChange={(e) => {
                    const { value } = e.target;
                    clearMessage();
                    setLandMark((state) => {
                      return {
                        ...state,
                        value,
                        initial: false,
                      };
                    });
                  }}
                  onFocus={() => {
                    setLandMark((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                  }}
                />

                <Form.Field>
                  <label htmlFor="state">State</label>
                  <input id="state" readOnly value={state.value} />
                </Form.Field>
              </Form.Group>
            </Form>
            <div className="nextStep-button">
              <Button data-testid="back-button" primary onClick={previousStep}>
                Back
              </Button>
              <Button data-testid="next-button" primary onClick={nextStep}>
                Next
              </Button>
            </div>
          </Island>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
