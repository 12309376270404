import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { formatRelativeTime } from '../../../helpers/helpers';

import { components as LayoutComponents } from '../../layout';
import { TransferStatus } from '../../../types';
import { LIST_REFUNDS } from '../graphql/query';
import { IRefund } from '../types';
import Island from '../../../_components/Island';

const { Trail, CustomTable, CustomLabel } = LayoutComponents;

const RefundConfirmations: FC = () => {
  const history = useHistory();

  const listAllRefundRequests = useQuery(LIST_REFUNDS, {
    fetchPolicy: 'network-only',
  });

  const columns: ColumnsType<IRefund & { key: string }> = [
    {
      title: 'ID',
      render: (text, record) => {
        return <p>{record.id.substr(0, 8)}</p>;
      },
      width: '5%',
    },
    {
      title: 'Order Code',
      render: (text, record) => {
        return <p>{record.order.orderCode}</p>;
      },
      width: '5%',
    },
    {
      title: 'To',
      render: (text, record) => {
        return <p>{record.order.client.name}</p>;
      },
      width: '10%',
    },
    {
      title: 'Amount',
      render: (text, record) => {
        return (
          <p>
            {record.transfer.amount ? `NGN ${record.transfer.amount}` : 'N/A'}
          </p>
        );
      },
      width: '10%',
    },
    {
      title: 'Status',
      render: (text, record) => {
        const color = {
          r: 0,
          g: 0,
          b: 0,
        };

        switch (record.transfer.status) {
          case TransferStatus.PENDING:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case TransferStatus.AWAITING_APPROVAL:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case TransferStatus.CONFIRMED:
            color.r = 86;
            color.g = 189;
            color.b = 102;
            break;
          case TransferStatus.FAILED:
            color.r = 244;
            color.g = 105;
            color.b = 86;
            break;

          default:
            break;
        }

        return (
          <CustomLabel
            color={color}
            text={record.transfer.status.toString().replace('_', ' ')}
          />
        );
      },
      width: '10%',
    },
    {
      title: '',
      render: (text, record) => {
        return (
          <p>{formatRelativeTime(new Date(record.createdAt).getTime())}</p>
        );
      },
      width: '10%',
    },
  ];

  return (
    <Island
      header={<Trail root="Confirmations"></Trail>}
      text="All funds transfer requests awaiting a confirmation"
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '48px',
          color: '#003952',
          cursor: 'pointer',
        }}
        onClick={() => {
          listAllRefundRequests.refetch();
        }}
      >
        <p
          style={{
            margin: '0 10px 0 0',
            fontWeight: 600,
          }}
        >
          Refresh{' '}
        </p>
        <Icon
          loading={listAllRefundRequests.loading}
          name="refresh"
          style={{
            cursor: 'pointer',
            height: '14px',
            lineHeight: '14px',
            margin: 0,
          }}
          onClick={() => {
            listAllRefundRequests.refetch();
          }}
        ></Icon>
      </div>
      <CustomTable
        onRow={(record: any, index: any) => {
          return {
            onClick: () => {
              history.push('/refunds/confirmations/' + record.id);
            },
          };
        }}
        columns={columns}
        data={listAllRefundRequests.data?.listRefundRequests.data
          .filter(
            ({ transfer: { status } }: any) =>
              status === TransferStatus.AWAITING_APPROVAL
          )
          .map((assignmentPayment: IRefund) => {
            return {
              key: assignmentPayment.id,
              ...assignmentPayment,
            };
          })}
        loading={listAllRefundRequests.loading}
        handleTableChange={() => {}}
      ></CustomTable>
    </Island>
  );
};

export default RefundConfirmations;
