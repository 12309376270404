import { FC, useEffect, useState} from "react";
import { Form } from "semantic-ui-react";
import Island from "../../../_components/Island";
import { Client, FieldValue, Location } from "../../../types";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { FETCH_CLIENT_BY_EMAIL, GET_LOCATIONS } from "../../../graphql/query";
import RequestFailed from "../../../_components/RequestFailed";
import { AddressSelector } from "@laborhack/address-selector/lib/AddressSelector";
import { ILocation } from "@laborhack/address-selector/lib/types";
import { ADD_LOCATION } from "@/graphql/mutation";
import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { Input } from "antd";

export interface CreateProps {}

const CREATE_ORDER_FOR_CLIENT = gql`
  mutation CreateOrderForClient($billingAddressId: String!, $email: String!) {
    createOrderForClient(billingAddressId: $billingAddressId, email: $email) {
      id
      orderCode
    }
  }`;

export const Create: FC<CreateProps> = () => {
  const history = useHistory();

  const query = new URLSearchParams(history.location.search);

  const clientEmailFromRedirection = query.get("client_email");

  const clientEmailFromRedirectionIsValid = clientEmailFromRedirection
    && clientEmailFromRedirection.length > 0
    && clientEmailFromRedirection.includes("@");

  let initialEmailState: FieldValue<string> = {
    value: "",
    isValid: false,
  };
  

  if (clientEmailFromRedirectionIsValid) {
    initialEmailState = {
      value: clientEmailFromRedirection,
      isValid: true,
    };
  }

  const [email, setEmail] = useState<FieldValue<string>>(initialEmailState);

  const [clientFetched, setClientFetched] = useState<boolean>(false);

  const [billingAddressId, setBillingAddressId] = useState<string>();

  const [getClientByEmail, {
    loading,
    data: getClientByEmailData,
    error: getClientByEmailError,
  }] = useLazyQuery<{ getClientByEmail: Client | null }>(FETCH_CLIENT_BY_EMAIL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      getClientLocations({ variables: { userId: data.getClientByEmail?.userId } });
    },
  });

  const handleGetClient = async () => {
    await getClientByEmail({ variables: { email: email.value } });
    setClientFetched(true);
  };

  const [getClientLocations, { data: getClientLocationsData }] = useLazyQuery<{
    getClientLocations: { defaultLocationId: string, locations: Location[] }
  }>(GET_LOCATIONS, {
    variables: { userId: getClientByEmailData?.getClientByEmail?.userId },
    fetchPolicy: "network-only",
  });

  const [
    createOrderForClient, {
      loading: createOrderForClientLoading,
      reset: resetOrderCreation,
      error: orderCreationError,
    }
  ] = useMutation(CREATE_ORDER_FOR_CLIENT, {
    onCompleted: (data) => {
      history.push(`/orders/${data.createOrderForClient.orderCode}`);
    },
  });

  const existingLocations: Record<string, ILocation> = getClientLocationsData?.getClientLocations.locations?.reduce((accumulator, location) => {
    accumulator[location.id] = location;
    return accumulator;
  }, {} as Record<string, ILocation>) || {};

  const existingBillingAddressId = getClientByEmailData?.getClientByEmail?.billingAddress ||
    getClientLocationsData?.getClientLocations.defaultLocationId;

  const [addLocation, { loading: addLocationLoading }] = useMutation(
		ADD_LOCATION,
		{
			onCompleted: (data) => {
				setBillingAddressId(data.addLocationForClient.id);
			},
			refetchQueries: [GET_LOCATIONS],
			awaitRefetchQueries: true,
		}
	);

	useEffect(() => {
		if (clientEmailFromRedirectionIsValid) {
			handleGetClient();
		}
	}, []);

	if (getClientByEmailError) {
		return (
			<RequestFailed
				errorMessage={getClientByEmailError.message}
				onRetry={() => {
					window.location.reload();
				}}
			/>
		);
	}

	if (orderCreationError) {
		return (
			<RequestFailed
				errorMessage={orderCreationError.message}
				onRetry={() => {
					handleReset();
				}}
			/>
		);
	}

	const clientByDataIsNull = !getClientByEmailData?.getClientByEmail;

	const selectedBillingAddressId = billingAddressId || existingBillingAddressId;

	const handleChangeLocation = (id: string) => {
		setBillingAddressId(id);
	};

	const handleAddCustomLocation = (payload: Omit<Location, "id">) => {
		addLocation({
			variables: {
				userId: getClientByEmailData?.getClientByEmail?.userId,
				input: { ...payload },
			},
		});
	};

	const handleCreateOrder = () => {
		createOrderForClient({
			variables: {
				billingAddressId: selectedBillingAddressId,
				email: email.value,
			},
		});
	};

	const handleReset = () => {
		resetOrderCreation();
	};

	const handleChangeClient = () => {
		setEmail({ value: "", isValid: false });

		setClientFetched(false);

		setBillingAddressId(undefined);
	};

	return (
		<Island>
			<div className="mb-4">
				<h2 className="text-primary-500 text-xl font-bold m-0">
					Create a new order
				</h2>
			</div>
			<div className="w-[60%]">
				<Form>
					<VStack spacing={6}>
						{!clientFetched && (
							<FormControl>
								<FormLabel fontWeight={"bold"}>Client's Email</FormLabel>
								<Flex direction={"row"} mb={4} justifyContent={"space-between"}>
									<Box width={"70%"}>
										<Input
											required
											value={email.value}
											onChange={(e) =>
												setEmail({
													value: e.target.value,
													isValid: !!e.target.value,
												})
											}
											placeholder={"Enter client email"}
										/>
									</Box>
									<Button
										className="ml-4"
										colorScheme={"primary"}
										isLoading={loading}
										disabled={!email.isValid}
										onClick={handleGetClient}
									>
										Get Client
									</Button>
								</Flex>
							</FormControl>
						)}
						{clientFetched && clientByDataIsNull && (
							<Stack spacing={4} alignSelf={"start"}>
								<Text color={"#FF0000"}>
									No <strong>Client</strong> found with the provided email.
								</Text>
								<HStack spacing={8}>
									<Button colorScheme={"primary"} onClick={handleChangeClient}>
										Change email
									</Button>
									<Button
										colorScheme={"primary"}
										onClick={() =>
											history.push("/clients/create?redirect_to=/orders/create")
										}
									>
										Create client
									</Button>
								</HStack>
							</Stack>
						)}
						{clientFetched && !clientByDataIsNull && (
							<HStack alignSelf={"start"} alignItems={"start"} w={"full"}>
								<VStack alignItems={"start"} mr="auto">
									<Heading as="h3" size="sm" textColor={"#003952"}>
										Client
									</Heading>
									<Text>{`Name: ${getClientByEmailData?.getClientByEmail?.firstName} ${getClientByEmailData?.getClientByEmail?.lastName}`}</Text>
									<Text>{`Email: ${getClientByEmailData?.getClientByEmail?.email}`}</Text>
									<Text>{`Phone Number: ${getClientByEmailData?.getClientByEmail?.phoneNumber}`}</Text>
								</VStack>
								<button
									className="bg-[#DADADA] text-[#595656] rounded-md px-[32px] py-[8px] font-bold"
									onClick={handleChangeClient}
								>
									Change
								</button>
							</HStack>
						)}
						{clientFetched && !clientByDataIsNull && (
							<VStack alignItems={"start"} w={"full"}>
								{selectedBillingAddressId && (
									<Heading as="h3" size="sm" textColor={"#003952"}>
										Billing Address
									</Heading>
								)}
								<AddressSelector
									locations={existingLocations}
									selected={selectedBillingAddressId}
									createNewAddressMessage="Add new address"
									onChange={handleChangeLocation}
									onAddCustomLocation={handleAddCustomLocation}
									loading={addLocationLoading}
								/>
							</VStack>
						)}
						{clientFetched && !clientByDataIsNull && (
							<Button
								className="mt-4"
								isLoading={createOrderForClientLoading}
								colorScheme={"primary"}
								disabled={!email.isValid || !selectedBillingAddressId}
								width={"full"}
								onClick={handleCreateOrder}
							>
								Create order
							</Button>
						)}
					</VStack>
				</Form>
			</div>
		</Island>
	);
};
