import React, { FC, useEffect } from "react";
import { IFormField, ICategoryData } from "./../types";
import { Grid, Form, Input, Button } from "semantic-ui-react";
import { Steps } from "antd";
import { GET_ALL_CAT_SERVICES } from "../../../graphql/query";
import { useQuery } from "@apollo/client";
import { validator } from "./../validator";
import { TRADES_MAN_LEVEL } from "./../constants";
import Island from "../../../_components/Island";
const { Step } = Steps;

interface PersonalInfoProps {
  firstName: IFormField;
  lastName: IFormField;
  email: IFormField;
  phoneNumber: IFormField;
  whatsappNumber: IFormField;
  category: string;
  level: number;
  setFirstName: React.Dispatch<React.SetStateAction<IFormField>>;
  setLastname: React.Dispatch<React.SetStateAction<IFormField>>;
  setEmail: React.Dispatch<React.SetStateAction<IFormField>>;
  setPhoneNumber: React.Dispatch<React.SetStateAction<IFormField>>;
  setWhatsappNumber: React.Dispatch<React.SetStateAction<IFormField>>;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setLevel: React.Dispatch<React.SetStateAction<number>>;
  // clearForm: () => void;
  clearMessage: () => void;
  nextStep: () => void;
  categories: never[];
  step: number;
  setFetchedCategory: React.Dispatch<React.SetStateAction<never[]>>;
}

export const PersonalInfo: FC<PersonalInfoProps> = ({
  firstName,
  setFirstName,
  lastName,
  setLastname,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  clearMessage,
  // clearForm,
  category,
  setCategory,
  level,
  setLevel,
  categories,
  setFetchedCategory,
  step,
  nextStep,
}) => {
  const { data } = useQuery(GET_ALL_CAT_SERVICES);

  useEffect(() => {
    if (data) {
      setFetchedCategory(data.getAllServices.allCategories);
    }
  }, [data, setFetchedCategory]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Island header="Create new pro">
            <Steps progressDot current={step}>
              <Step title="Personal Information" />
              <Step title="Address Information" />
              <Step title="Financial Information" />
            </Steps>
            <Form>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  id="first-name"
                  label="First name"
                  placeholder="First name"
                  value={firstName.value}
                  error={firstName.error}
                  required
                  data-testid="firstname"
                  {...(!firstName.initial && {
                    icon: !!validator.validate({
                      firstName: firstName.value.replace(/\s/g, ""),
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  onChange={(e) => {
                    const { value } = e.target;
                    clearMessage();
                    setFirstName((state) => {
                      return {
                        ...state,
                        value,
                        initial: false,
                      };
                    });
                  }}
                  onBlur={() => {
                    const { error } = validator.validate({
                      firstName: firstName.value.replace(/\s/g, ""),
                    });
                    if (error) {
                      setFirstName((state) => {
                        return {
                          ...state,
                          error: {
                            content: "Invalid Name. Please enter name",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setFirstName((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                  }}
                />
                <Form.Input
                  fluid
                  data-testid="lastname"
                  id="last-name"
                  label="Last name"
                  placeholder="Last name"
                  required
                  {...(!lastName.initial && {
                    icon: !!validator.validate({
                      lastName: lastName.value.replace(/\s/g, ""),
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  value={lastName.value}
                  error={lastName.error}
                  onChange={(e) => {
                    const { value } = e.target;
                    clearMessage();
                    setLastname((state) => {
                      return {
                        ...state,
                        value,
                        initial: false,
                      };
                    });
                  }}
                  onBlur={() => {
                    const { error } = validator.validate({
                      lastName: lastName.value.replace(/\s/g, ""),
                    });
                    if (error) {
                      setLastname((state) => {
                        return {
                          ...state,
                          error: {
                            content: "Invalid Name. Enter lastname",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setLastname((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                  }}
                />
              </Form.Group>
              <Form.Input
                data-testid="email"
                id="form-input-control-error-email"
                control={Input}
                required
                label="Email"
                placeholder="joe@schmoe.com"
                value={email.value}
                error={email.error}
                {...(!email.initial && {
                  icon: !!validator.validate({ email: email.value }).error
                    ? {
                        name: "remove",
                        color: "red",
                      }
                    : {
                        name: "check",
                        color: "green",
                      },
                })}
                onChange={(e) => {
                  const { value } = e.target;
                  clearMessage();
                  setEmail((state) => {
                    return {
                      ...state,
                      value,
                      initial: false,
                    };
                  });
                }}
                onBlur={() => {
                  const { error } = validator.validate({
                    email: email.value,
                  });
                  if (error) {
                    setEmail((state) => {
                      return {
                        ...state,
                        error: {
                          content:
                            "Invalid email. Ensure email is in the right format",
                          pointing: "above",
                        },
                      };
                    });
                  }
                }}
                onFocus={() => {
                  setEmail((state) => {
                    return {
                      ...state,
                      error: false,
                    };
                  });
                }}
              />

              <Form.Field required>
                <label htmlFor="phone number">Phone Number</label>
                <Input
                  id="phone number"
                  data-testid="phoneNumber"
                  label="+234"
                  placeholder="800 000 0001"
                  {...(!phoneNumber.initial && {
                    icon: !!validator.validate({
                      phoneNumber: phoneNumber.value,
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  type="tel"
                  value={phoneNumber.value}
                  error={!!phoneNumber.error}
                  onChange={(e) => {
                    const { value } = e.target;
                    clearMessage();
                    setPhoneNumber((state) => {
                      return {
                        ...state,
                        value: value,
                        initial: false,
                      };
                    });
                  }}
                  onBlur={() => {
                    const { error } = validator.validate({
                      phoneNumber: phoneNumber.value,
                    });
                    if (error) {
                      setPhoneNumber((state) => {
                        return {
                          ...state,
                          error: {
                            content:
                              "Invalid Phone Number. Enter the correct phone number",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setPhoneNumber((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                  }}
                ></Input>
              </Form.Field>

              <Form.Field>
                <Form.Input required label="Select Category">
                  <select
                    name="role"
                    id="role"
                    value={category}
                    onChange={(e) => {
                      const { value } = e.target;
                      clearMessage();
                      setCategory(value);
                    }}
                  >
                    <option value="">Select a category</option>
                    {categories.length > 0 ? (
                      categories.map((category: ICategoryData) => {
                        return (
                          <option
                            data-testid="available-categories"
                            key={category.id}
                            value={category.id}
                          >
                            {category.name}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No available categories</option>
                    )}
                  </select>
                </Form.Input>
              </Form.Field>
              <Form.Field>
                <Form.Input required label="Select Level">
                  <select
                    name="level"
                    id="level"
                    value={level}
                    onChange={(e) => {
                      const { value } = e.target;
                      clearMessage();
                      setLevel(parseInt(value));
                    }}
                  >
                    <option value="">Choose a level</option>
                    {TRADES_MAN_LEVEL.map((options) => {
                      return (
                        <option key={options} value={options}>
                          {options}
                        </option>
                      );
                    })}
                  </select>
                </Form.Input>
              </Form.Field>
            </Form>
            <div className="nextStep-button" style={{ marginTop: 16 }}>
              <Button data-testid="next-button" primary onClick={nextStep}>
                Next
              </Button>
            </div>
          </Island>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
