import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { MarketplaceJobView } from "../../features/marketplace-requests/MarketplaceJobView";
import { MarketplaceRequestList } from "../../features/marketplace-requests/MarketplaceRequestList";
import { MarketplaceRequestView } from "../../features/marketplace-requests/MarketplaceRequestView";

export const MarketplaceRequests = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/jobs/:id`} component={MarketplaceJobView} />
      <Route path={`${path}/:id`} component={MarketplaceRequestView} />
      <Route path={path} exact component={MarketplaceRequestList} />
    </Switch>
  );
};
