import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Icon,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import BackButton from "../../_components/BackButton";
import Island from "../../_components/Island";
import Button from "@laborhack/custom-button";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { MeansOfIdentification, Pro } from "../../types";
import { GET_PRO } from "../../graphql/pros/query";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { IDENTIFICATION_OPTIONS } from "./constants";
import { GrFormCheckmark } from "react-icons/gr";

export const CREATE_IDENTITY_VERIFICATION = gql`
  mutation CreateIdentityVerification($input: CreateIdentityVerificationDTO!) {
    createIdentityVerification(input: $input) {
      id
    }
  }
`;

export interface CreateIdentityVerificationProps {}

export const CreateIdentityVerification = ({}) => {
  const { proId } = useParams<{ proId: string }>();

  const [selectedIdType, setSelectedIdType] = useState<MeansOfIdentification>();

  const { data, loading, error } = useQuery<{ pro: Pro }>(GET_PRO, {
    variables: { id: proId },
    onCompleted: (data) => {
      const identityVerification = data?.pro?.identityVerification;
    
      if(identityVerification?.dateOfBirth){
        setDateOfBirth(moment.utc(identityVerification?.dateOfBirth).format("YYYY-MM-DD"));
      }
      if(identityVerification?.type){
        setSelectedIdType(identityVerification?.type);
      }
      if(identityVerification?.identificationNumber){
        setIdNumber(identityVerification?.identificationNumber);
      }
    }
  });

  const history = useHistory();
  const toast = useToast();

  const [idNumber, setIdNumber] = React.useState("");
  const [dateOfBirth, setDateOfBirth] = React.useState<string>();

  const idNumberIsValid = React.useMemo(() => {
    const idTypeOption = IDENTIFICATION_OPTIONS.find(
      ({ value }) => value === selectedIdType
    );
  
    const idTypeRegex = idTypeOption?.regex;
  
    return idTypeRegex?.test(idNumber);
  }, [idNumber, selectedIdType]);

  const [
    createIdentityVerification,
    createIdentityVerificationCall,
  ] = useMutation(CREATE_IDENTITY_VERIFICATION, {
    onCompleted: () => {
      toast({
        title: "Identity Verification Created",
        description: "Your identity verification has been created successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });

      history.goBack();
    },
  });

  const canSubmit = React.useMemo(() => {
    return (
      idNumberIsValid && selectedIdType &&
      (!!data?.pro.proApplication.dateOfBirth || !!dateOfBirth)
    );
  }, [idNumberIsValid, data, selectedIdType, dateOfBirth]);

  if (loading) return <Loading />;

  if (error || !data) return <RequestFailed />;

  const {
    proApplication: { dateOfBirth: existingDateOfBirth },
    identityVerification,
  } = data.pro;

  const selectedIdTypeName = IDENTIFICATION_OPTIONS.find(
    ({ value }) => value === selectedIdType
  )?.label;

  const handleSubmit = () => {
    createIdentityVerification({
      variables: {
        input: {
          identificationNumber: idNumber,
          dateOfBirth: dateOfBirth
            ? new Date(dateOfBirth!).toISOString()
            : existingDateOfBirth,
          proId,
          type: selectedIdType,
        },
      },
    });
  };

  return (
    <>
      <BackButton />
      <Island>
        <div className="mb-4">
          <h2 className="text-primary-500 text-xl font-bold m-0">
            Create Identity Verification
          </h2>
        </div>
        <div className="mt-8 w-full px-8">
          <div className="grid !grid-cols-1 md:!grid-cols-2 gap-y-4 w-full">
            <div>
              <h3 className="font-semibold text-primary-500">
                Means of Identification
              </h3>
              <p className="text-sm text-primary-300">
                Provide a valid means of identification
              </p>
            </div>
            <Stack spacing={2} className="px-2 md:px-4">
              <FormControl isRequired as="fieldset">
                <FormLabel fontSize={"sm"} as="legend" color="primary.500">
                  Date of Birth
                </FormLabel>
                <Input
                  id="dateOfBirth"
                  fontSize={"sm"}
                  borderColor={"rgb(209, 213, 219)"}
                  type={"date"}
                  alignItems="center"
                  defaultValue={
                    existingDateOfBirth &&
                    moment.utc(existingDateOfBirth).format("YYYY-MM-DD")
                  }
                  value={dateOfBirth}
                  onChange={(event) => {
                    setDateOfBirth(event.target.value);
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize={"sm"} as="legend" color="primary.500">
                  ID type
                </FormLabel>
                <Select 
                value={selectedIdType}
                placeholder="Select option"
                  onChange={(event) => {
                    setSelectedIdType(
                      event.target.value as MeansOfIdentification
                    );
                  }}
                >
                  {IDENTIFICATION_OPTIONS.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  Please select a valid ID type
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={!!idNumber && idNumberIsValid === false}
              >
                 <FormLabel
                    fontSize={"sm"}
                    htmlFor='firstName'
                    color='primary.500'
                  >
                    ID Number
                  </FormLabel>
                <InputGroup>
                  <Input
                    id="idNumber"
                    fontSize={"sm"}
                    type={"text"}
                    alignItems="center"
                    placeholder={
                      selectedIdType
                        ? `Enter your ${selectedIdTypeName}`
                        : "Select ID type first"
                    }
                    value={idNumber}
                    onChange={(event) => {
                      setIdNumber(event.target.value);
                    }}
                  />
                  {idNumberIsValid && (
                    <InputRightElement
                      children={
                        <Icon
                          as={GrFormCheckmark}
                          w="6"
                          h="6"
                          color={"green.500"}
                        />
                      }
                    />
                  )}
                </InputGroup>
                {idNumberIsValid === false && (
                  <FormErrorMessage>
                    {selectedIdType
                      ? `Please enter a valid ${
                          IDENTIFICATION_OPTIONS.find(
                            ({ value }) => value === selectedIdType
                          )?.label
                        }`
                      : "Please select ID type first"}
                  </FormErrorMessage>
                )}{" "}
                {idNumberIsValid === undefined && (
                  <FormHelperText>
                    The ID number of your means of identification, e.g. your
                    passport number, NIN, etc.
                  </FormHelperText>
                )}
              </FormControl>
              <div className="flex justify-end">
                <Button
                  disabled={!canSubmit}
                  onClick={handleSubmit}
                  loading={createIdentityVerificationCall.loading}
                >
                  Submit ID
                </Button>
              </div>
            </Stack>
          </div>

          <div className="my-4 md:my-8 w-full h-0.5 bg-primary-100" />
        </div>
      </Island>
    </>
  );
};
