import React, { FC } from 'react';

import styles from './styles.module.scss';

export interface StepLabelProps {
  step: number;
}

export const StepLabel: FC<StepLabelProps> = ({ step, children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.step}>{step}</div>
      <div className={styles.label}>{children}</div>
    </div>
  );
};
