import { gql, useMutation, useQuery } from "@apollo/client";
import clsx from "clsx";
import React, { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  RecruitmentJob,
  RecruitmentJobShortlistedPro,
} from "../../../../types";
import BackButton from "../../../../_components/BackButton";
import Field from "../../../../_components/Field";
import Island from "../../../../_components/Island";
import Loading from "../../../../_components/Loading";
import RequestFailed from "../../../../_components/RequestFailed";
import Button from "@laborhack/custom-button";
import { Button as SemanticUIButton, Icon } from "semantic-ui-react";
import { Modal } from "semantic-ui-react";
import {
  ADD_PRO_TO_RECRUITMENT_JOB,
  REMOVE_PRO_FROM_RECRUITMENT_JOB,
  REPLACE_PRO_IN_RECRUITMENT_JOB,
} from "@/graphql/recruitment";
import { isAfter } from "date-fns";
import { Alert } from "antd";
import { RecruitmentJobShortlist } from "./RecruitmentJobShortlist";
import {
  ContractRecruitment,
  RecruitmentRequest,
  RecruitmentType,
} from "../../types";
import { RecruitmentContext } from "../context/recruitment.context";
import { MatchedPro } from "@/features/marketplace-requests/MatchedPro";
import { SelectPro } from "@/components/SelectContractPro/components/SelectPro";

const RECRUITMENT = gql`
  query RECRUITMENT($id: String!) {
    contractRecruitment(id: $id) {
      id
      client {
        id
        userId
        firstName
        lastName
        email
      }
      recruitmentType
      requests {
        id
        proCount
        proLevel
        categoryId
        contractDetails {
          length
          interval
        }
        proposedStartDate
        shortlistId
      }
      activeQuotation {
        id
      }
      quotations {
        id
        status
        items {
          isActive
          level
          rate
          proCount
          contractDetails {
            length
            interval
          }
        }
        createdAt
      }
      paymentSettings {
        payBeforeService
      }
      status
      createdAt
    }
  }
`;

const CATEGORIES = gql`
  query Categories {
    categories {
      id
      proTitle
    }
  }
`;

const GET_SHORTLIST = gql`
  query GetRecruitmentJobShortlist(
    $recruitmentId: String!
    $recruitmentRequestId: String!
  ) {
    getRecruitmentJobShortlist(
      recruitmentId: $recruitmentId
      recruitmentRequestId: $recruitmentRequestId
    ) {
      id
      shortlistedPros {
        pro {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        status
        potentialMonthlyGrossSalary
      }
    }
  }
`;

export const RECRUITMENT_JOB = gql`
  query RecruitmentJob($recruitmentId: String!, $requestId: String!) {
    recruitmentJob(recruitmentId: $recruitmentId, requestId: $requestId) {
      id
      matches {
        pro {
          id
        }
      }
      request {
        matchingFeeInvoice {
          id
          sent
          isFullyPaid
          hasAtLeastOnePayment
          payment {
            status
          }
        }
      }
      category {
        name
        id
      }
      schedule {
        start
        end
        workDays
      }
      proCount
      description
      pros {
        id
        firstName
        lastName
        imageUrl
      }
      shortlist {
        id
        shortlistedPros {
          pro {
            id
            firstName
            lastName
            email
            phoneNumber
          }
          status
        }
      }
    }
  }
`;

export interface RecruitmentJobViewProps {}

export const RecruitmentJobView: FC<RecruitmentJobViewProps> = ({}) => {
  const { id, requestId } = useParams<{ id: string; requestId: string }>();

  const history = useHistory();

  const {
    data: recruitmentJobData,
    loading: loadingRecruitmentJob,
    error: recruitmentJobError,
    refetch: refetchJob,
  } = useQuery<{
    recruitmentJob: RecruitmentJob;
  }>(RECRUITMENT_JOB, {
    variables: {
      recruitmentId: id,
      requestId,
    },
  });

  const {
    error: categoriesError,
    loading: loadingCategories,
    data: categoriesData,
  } = useQuery<{
    categories: { id: string; proTitle?: string }[];
  }>(CATEGORIES);

  const { data, loading, error } = useQuery<{
    contractRecruitment: ContractRecruitment;
  }>(RECRUITMENT, {
    variables: { id },
  });

  const {
    data: shortlistData,
    loading: loadingShortlist,
    error: shortlistError,
    refetch: refetchShortlist,
  } = useQuery<any>(GET_SHORTLIST, {
    variables: { recruitmentId: id, recruitmentRequestId: requestId },
  });

  const [showAlert, setShowAlert] = React.useState(false);
  const [showReplaceProAlert, setShowReplaceProAlert] = React.useState(false);

  const clearUpdateAlert = () => {
    setShowAlert(false);
    setShowReplaceProAlert(false);
  };

  const [selectProModalOpen, setSelectProModalOpen] = React.useState(false);

  /**
   * The selected pro is the pro that is currently selected for the replace pro modal
   */
  const [selectedPro, setSelectedPro] = React.useState<string | null>(null);

  const [addProToJob, addProToJobCall] = useMutation(
    ADD_PRO_TO_RECRUITMENT_JOB,
    {
      onCompleted: () => {
        setSelectProModalOpen(false);
        refetchJob();
      },
    }
  );

  const [removeProFromJob, removeProFromJobCall] = useMutation(
    REMOVE_PRO_FROM_RECRUITMENT_JOB,
    {
      onCompleted: (data) => {
        setShowAlert(true);

        setTimeout(clearUpdateAlert, 3000);
        refetchJob();
      },
    }
  );

  const [replaceProInJob, replaceProInJobCall] = useMutation(
    REPLACE_PRO_IN_RECRUITMENT_JOB,
    {
      onCompleted: () => {
        setSelectProModalOpen(false);
        setShowReplaceProAlert(true);

        setTimeout(clearUpdateAlert, 3000);
        refetchJob();
      },
    }
  );

  if (loading || loadingCategories || loadingShortlist || loadingRecruitmentJob)
    return <Loading />;

  if (error || !data || categoriesError || recruitmentJobError) {
    return <RequestFailed errorMessage={error?.message} />;
  }

  if (removeProFromJobCall.error) {
    return (
      <RequestFailed
        errorMessage={removeProFromJobCall.error.message}
        onRetry={removeProFromJobCall.reset}
      />
    );
  }

  const {
    category,
    proCount,
    pros,
    description,
    schedule,
    request: jobRequest,
    shortlist,
  } = recruitmentJobData?.recruitmentJob || ({} as RecruitmentJob);

  const matchingFeeInvoice = jobRequest?.matchingFeeInvoice;

  const matchingFeePaid = matchingFeeInvoice?.isFullyPaid;

  const unassignedShortlistAcceptances =
    shortlistData?.getRecruitmentJobShortlist?.shortlistedPros
      .filter(({ status, pro }: RecruitmentJobShortlistedPro) => {
        const isAssigned = !!pros?.find(
          (assignedPro) => assignedPro.id === pro.id
        );

        return status === "ACCEPTED" && !isAssigned;
      })
      .map(({ pro }: RecruitmentJobShortlistedPro) => pro) || [];

  const request =
    data.contractRecruitment.requests.find(
      (request) => request.id === requestId
    ) || ({} as RecruitmentRequest);

  const categoryName = categoriesData?.categories?.find(
    ({ id }) => id === request.categoryId
  )?.proTitle;

  const requestedProCountMatched = pros?.length >= proCount;

  const handleAddPro = (proId: string) => {
    addProToJob({
      variables: {
        recruitmentId: id,
        requestId,
        proId,
      },
    });
  };

  const handleRemovePro = (proId: string) => {
    removeProFromJob({
      variables: {
        recruitmentId: id,
        requestId,
        proId,
      },
    });
  };

  const handleReplacePro = (replacementProId: string) => {
    replaceProInJob({
      variables: {
        recruitmentId: id,
        requestId,
        replacementProId,
        existingProId: selectedPro,
      },
    });
  };

  const matched = !!request.matchingFeeInvoice?.isFullyPaid;

  const completed = isAfter(new Date(), new Date(schedule?.end));

  return (
    <>
      <RecruitmentContext.Provider
        value={{
          recruitment: data?.contractRecruitment,
          activeRequests: [request],
        }}
      >
        <BackButton />
        <Modal
          open={selectProModalOpen}
          closeIcon
          onClose={() => setSelectProModalOpen(false)}
          closeOnDimmerClick={false}
        >
          {addProToJobCall.error || replaceProInJobCall.error ? (
            <RequestFailed
              errorMessage={
                addProToJobCall.error?.message ||
                replaceProInJobCall.error?.message
              }
              onRetry={() => {
                addProToJobCall.reset();
                replaceProInJobCall.reset();
              }}
            />
          ) : (
            <SelectPro
              pros={unassignedShortlistAcceptances}
              onSelect={({ id }) =>
                selectedPro ? handleReplacePro(id) : handleAddPro(id)
              }
              loading={addProToJobCall.loading || replaceProInJobCall.loading}
            />
          )}
        </Modal>

        <Island>
          <div className="mb-4">
            <h2 className="text-primary-500 text-xl font-bold m-0">
              Recruitment Job{" "}
              <span className="text-primary-300">({requestId})</span>
            </h2>
            <p className="m-0 text-primary-300">View recruitment job</p>
          </div>
          {showAlert && (
            <Alert
              data-testid="pro-removed-alert"
              message="Pro removed"
              type="success"
              showIcon
              closable
              onClose={clearUpdateAlert}
            />
          )}
          {showReplaceProAlert && (
            <Alert
              data-testid="pro-replaced-alert"
              message="Pro replaced"
              type="success"
              showIcon
              closable
              onClose={clearUpdateAlert}
            />
          )}
          <div className="mt-8 w-full">
            <div>
              <div className="mb-4">
                <h3 className="text-lg font-semibold text-primary-500 mb-2">
                  Basic Information
                </h3>
                <div className="pl-2">
                  <Field label="Category" value={categoryName || ""} />
                  <Field
                    label="Description"
                    value={"No description provided"}
                  />
                  <Field
                    label="Proposed Start Date"
                    value={new Date(request.proposedStartDate).toDateString()}
                  />
                  <Field
                    label="Recruitment Type"
                    value={data?.contractRecruitment.recruitmentType.replace(
                      "_",
                      " "
                    )}
                  />
                  {data?.contractRecruitment.recruitmentType ===
                    RecruitmentType.CONTRACT && (
                    <Field
                      label="Contract Length"
                      value={`${
                        request.contractDetails?.length
                      } ${request.contractDetails?.interval.toLowerCase()}`}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-8">
              {
                <div className="mb-4">
                  <h3 className="text-lg font-semibold text-primary-500 mb-2">
                    Matched Pros (
                    <span
                      className={clsx(
                        requestedProCountMatched && "text-success"
                      )}
                    >
                      {pros?.length || 0}/{proCount}
                    </span>
                    )
                  </h3>
                  {(Number(pros?.length) > Number(proCount)) && 
                    <div>
                      <p className="text-red-500">The number of pros selected has exceeded the number of pros required.</p> 
                    </div>
                     }
                  <div className="flex flex-col gap-2">
                    {pros?.map((pro) => {
                      return (
                        <MatchedPro
                          key={pro.id}
                          pro={pro}
                          matched={matched}
                          completed={completed}
                          onRemove={() => handleRemovePro(pro.id)}
                          loading={removeProFromJobCall.loading}
                          hideReplaceButton
                        />
                      );
                    })}
                    {!matched && (
                      <div>
                        <SemanticUIButton
                          icon
                          onClick={() => {
                            setSelectProModalOpen(true);
                          }}
                        >
                          <Icon name="plus" />
                        </SemanticUIButton>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
            <RecruitmentJobShortlist
              job={{
                shortlist: shortlistData?.getRecruitmentJobShortlist,
              }}
              matched={false}
              refetchJob={refetchJob}
              refetchShortlist={refetchShortlist}
            />
          </div>
        </Island>
      </RecruitmentContext.Provider>
    </>
  );
};
