import React, { FC } from "react";
import Button from "@laborhack/custom-button";
import { Filter, FilterClass, Pro } from "@/types";
import { ContractProItem } from "./ContractProItem";
import styles from "../styles.module.scss";
import { Dropdown, Icon } from "semantic-ui-react";
import { AddFilter } from "./AddFilter";

export interface SelectProProps {
  pros: Pro[];
  onSelect: (pro: { id: string; name: string }) => void;
  loading?: boolean;
}

const filterHandlers: {
  [Property in keyof FilterClass]: (pro: Pro, value: any) => boolean;
} = {
  availability: (pro: Pro, value: boolean) => {
    if (value) {
      return !pro.conflicts?.length;
    }

    return !!pro.conflicts?.length;
  },
};

export const SelectPro: FC<SelectProProps> = ({
  pros: proList,
  onSelect,
  loading: loadingProp,
}) => {
  const [filters, setFilters] = React.useState<Filter[]>([
    {
      class: "availability",
      value: true,
      name: "Available",
    },
  ]);

  const [selectedPro, setSelectedPro] = React.useState<{
    id: string;
    name: string;
  }>();

  const pros = proList.filter((pro) => {
    const validityPerClass: {
      [Property in keyof Partial<FilterClass>]: boolean;
    } = {};

    for (const filter of filters) {
      if (!validityPerClass[filter.class]) {
        validityPerClass[filter.class] = filterHandlers[filter.class](
          pro,
          filter.value
        );
      }
    }

    return Object.values(validityPerClass).every(Boolean);
  });

  return (
    <div className={styles.wrapper}>
      <h3 className='text-xl'>Select Pro</h3>
      <div className='mt-8 flex justify-end items-center'>
        <div className='mr-4 flex'>
          {filters.map((filter, index) => {
            return (
              <div
                key={filter.name}
                className='flex ml-2 rounded-full pl-4 pr-2 py-2 bg-primary-150 text-primary-500 font-semibold'
              >
                <span>{filter.name}</span>
                <Icon
                  className='!mr-0 !ml-2 cursor-pointer'
                  name='remove circle'
                  onClick={() =>
                    setFilters((prev) =>
                      prev.filter(
                        (f) => f.class + f.name !== filter.class + filter.name
                      )
                    )
                  }
                />
              </div>
            );
          })}
        </div>
        <Dropdown
          icon={null}
          pointing='top right'
          trigger={
            <Button
              type='subtle'
              variant='basic'
              className='!px-4 !bg-primary-150 !text-primary-500'
            >
              <Icon className='!ml-0' name='plus' />
              Add Filter
            </Button>
          }
        >
          <Dropdown.Menu>
            <AddFilter filters={filters} setFilters={setFilters} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={styles.list}>
        {pros && pros.length ? (
          pros.map(({ conflicts, ...pro }) => {
            const name = `${pro.firstName} ${pro.lastName}`;

            return (
              <ContractProItem
                key={pro.id}
                phoneNumber={pro.phoneNumber}
                name={name}
                conflicts={conflicts}
                selected={pro.id === selectedPro?.id}
                onSelect={() => {
                  setSelectedPro({
                    id: pro.id,
                    name,
                  });
                }}
              />
            );
          })
        ) : (
          <div className='w-full flex justify-center'>
            <div>
              <p className='text-primary-500'>No Pros</p>
            </div>
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <Button
          disabled={!selectedPro?.id}
          onClick={() => {
            selectedPro?.id && onSelect(selectedPro);
          }}
          variant='success'
          loading={loadingProp}
        >
          Assign
        </Button>
      </div>
    </div>
  );
};
