import React, { FC, useState, useEffect } from "react";
import { Form, Input, Button, Grid, Message } from "semantic-ui-react";
import { useQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { IFormField } from "./../types";
import { validator, baseRegisterValidator } from "./../validator";
import { ServiceSelectors } from "../../../redux/store";
import { GET_ALL_ROLES, REGISTER_ADMIN } from "./../../../graphql/query";
import { ServiceAction } from "./../../../redux/service.slice";
import { IAdminRoles } from "./../../../service/types";
import { useHistory } from "react-router-dom";
import Island from "../../../_components/Island";

const AdminForm: FC = () => {
  const [firstName, setFirstName] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [lastname, setLastname] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [email, setEmail] = useState<IFormField>({
    error: false,
    initial: false,
    value: "",
  });
  const [phoneNumber, setPhoneNumber] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });

  const [role, SetRole] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string>("");

  const { servicesFetched } = ServiceAction;
  const dispatch = useDispatch();

  const { data } = useQuery(GET_ALL_ROLES);

  const history = useHistory();

  useEffect(() => {
    if (data) {
      dispatch(servicesFetched(data));
    }
  }, [dispatch, servicesFetched, data]);

  const [submitForm, { loading }] = useMutation(REGISTER_ADMIN);

  const { selectAllRoles } = ServiceSelectors;
  const allRoles: IAdminRoles[] = useSelector(selectAllRoles);

  const clearForm = () => {
    setFirstName((state) => {
      return {
        ...state,
        value: "",
      };
    });
    setLastname((state) => {
      return {
        ...state,
        value: "",
      };
    });
    setEmail((state) => {
      return {
        ...state,
        value: "",
      };
    });
    setPhoneNumber((state) => {
      return {
        ...state,
        value: "",
      };
    });
    SetRole((state) => {
      return {
        ...state,
        value: "",
      };
    });
  };

  const clearMessage = () => {
    setSuccess("");
    setError("");
  };

  const submitAdminForm = async () => {
    try {
      const { data } = await submitForm({
        variables: {
          data: {
            firstName: firstName.value,
            lastName: lastname.value,
            email: email.value,
            phoneNumber: `+234${phoneNumber.value}`,
            imageUrl: "",
            roles: [role.value],
          },
        },
      });
      if (data) {
        setSuccess(data.createAdminUser.message);
        clearForm();
      }
    } catch (error) {
      setError((error as Error).message);
    }
  };
  return (
    <Grid padded>
      <Grid.Row className='o-admin__form-wrapper'>
        <Grid.Column width={10}>
          <Island header='Create new admin'>
            <Form>
              <Form.Group widths='equal'>
                <Form.Input
                  required
                  fluid
                  id='form-subcomponent-shorthand-input-first-name'
                  {...(!firstName.initial && {
                    icon: !!validator.validate({
                      firstname: firstName.value.replace(/\s/g, ""),
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  label='First name'
                  placeholder='First name'
                  value={firstName.value}
                  error={firstName.error}
                  onChange={(e) => {
                    const { value } = e.target;
                    setFirstName((state) => {
                      return {
                        ...state,
                        value,
                        initial: false,
                      };
                    });
                  }}
                  onBlur={() => {
                    const { error } = validator.validate({
                      firstname: firstName.value.replace(/\s/g, ""),
                    });
                    if (error) {
                      setFirstName((state) => {
                        return {
                          ...state,
                          error: {
                            content: "Invalid Name. Please enter name",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setFirstName((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                    clearMessage();
                  }}
                />
                <Form.Input
                  required
                  fluid
                  id='form-subcomponent-shorthand-input-last-name'
                  label='Last name'
                  placeholder='Last name'
                  {...(!lastname.initial && {
                    icon: !!validator.validate({
                      lastname: lastname.value.replace(/\s/g, ""),
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  value={lastname.value}
                  error={lastname.error}
                  onChange={(e) => {
                    const { value } = e.target;
                    setLastname((state) => {
                      return {
                        ...state,
                        value,
                        initial: false,
                      };
                    });
                  }}
                  onBlur={() => {
                    const { error } = validator.validate({
                      lastname: lastname.value.replace(/\s/g, ""),
                    });
                    if (error) {
                      setLastname((state) => {
                        return {
                          ...state,
                          error: {
                            content: "Invalid Name. Please enter name",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setLastname((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                    clearMessage();
                  }}
                />
              </Form.Group>
              <Form.Input
                required
                id='form-input-control-error-email'
                control={Input}
                label='Email'
                placeholder='joe@schmoe.com'
                value={email.value}
                error={email.error}
                {...(!email.initial && {
                  icon: !!validator.validate({ email: email.value }).error
                    ? {
                        name: "remove",
                        color: "red",
                      }
                    : {
                        name: "check",
                        color: "green",
                      },
                })}
                onChange={(e) => {
                  const { value } = e.target;
                  setEmail((state) => {
                    return {
                      ...state,
                      value,
                      initial: false,
                    };
                  });
                }}
                onBlur={() => {
                  const { error } = validator.validate({
                    email: email.value,
                  });
                  if (error) {
                    setEmail((state) => {
                      return {
                        ...state,
                        error: {
                          content:
                            "Invalid email. Ensure email is in the right format",
                          pointing: "above",
                        },
                      };
                    });
                  }
                }}
                onFocus={() => {
                  setEmail((state) => {
                    return {
                      ...state,
                      error: false,
                    };
                  });
                  clearMessage();
                }}
              />

              <Form.Field required>
                <label>Phone Number</label>
                <Input
                  label='+234'
                  placeholder='800 000 0001'
                  {...(!phoneNumber.initial && {
                    icon: !!validator.validate({
                      phoneNumber: phoneNumber.value,
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  type='tel'
                  value={phoneNumber.value}
                  error={!!phoneNumber.error}
                  onChange={(e) => {
                    const { value } = e.target;
                    setPhoneNumber((state) => {
                      return {
                        ...state,
                        value: value,
                        initial: false,
                      };
                    });
                  }}
                  onBlur={() => {
                    const { error } = validator.validate({
                      phoneNumber: phoneNumber.value,
                    });
                    if (error) {
                      setPhoneNumber((state) => {
                        return {
                          ...state,
                          error: {
                            content:
                              "Invalid Phone Number. Enter the correct phone number",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setPhoneNumber((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                    clearMessage();
                  }}
                ></Input>
              </Form.Field>
              <Form.Field>
                <Form.Input label='Choose Role' required>
                  <select
                    name='role'
                    id='role'
                    value={role.value}
                    onChange={(e) => {
                      clearMessage();
                      const { value } = e.target;
                      SetRole((state) => {
                        return {
                          ...state,
                          value: value,
                          initial: false,
                        };
                      });
                    }}
                  >
                    <option
                      value=''
                      disabled
                      defaultValue='choose a role'
                      hidden
                    >
                      Choose a role
                    </option>
                    {allRoles.map((options) => {
                      return (
                        <option key={options.id} value={options.name}>
                          {options.name}
                        </option>
                      );
                    })}
                  </select>
                </Form.Input>
              </Form.Field>
              {success !== "" && (
                <Message
                  success
                  header='Created successfully'
                  content={success}
                />
              )}
              {error !== "" && <Message error header='Error' content={error} />}
              <div>
                <Button basic color='red' onClick={() => history.goBack()}>
                  Back
                </Button>
                {!loading ? (
                  <Button
                    {...(baseRegisterValidator.validate({
                      firstname: firstName.value.replace(/\s/g, ""),
                      lastname: lastname.value.replace(/\s/g, ""),
                      email: email.value,
                      phoneNumber: phoneNumber.value,
                      role: role.value,
                    }).error && { disabled: true })}
                    primary
                    onClick={() => {
                      submitAdminForm();
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button loading primary>
                    Loading
                  </Button>
                )}
              </div>
            </Form>
          </Island>
        </Grid.Column>
        <Grid.Column width={6}></Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AdminForm;
