import { gql } from '@apollo/client';

export const GET_BACKGROUND_CHECK_BY_PRO_ID = gql`
  query GetBackgroundCheckByProId($proId: String!) {
    getBackgroundCheckByProId(proId: $proId) {
      isComplete
      id
      referees {
        relationship
        phoneNumber
        name
        jobTitle
        email
        companyName
        companyAddress
      }
      guarantors {
        companyAddress
        companyName
        email
        jobTitle
        name
        phoneNumber
        relationship
      }
    }
  }
`;

export const CREATE_BACKGROUND_CHECK_FOR_PRO = gql`
  mutation CreateBackgroundCheckForPro($proId: String!, $payload: CreateContactsInput!) {
    createBackgroundCheckForPro(proId: $proId, payload: $payload) {
      id
      isComplete
    }
  }
`;
