import React, { FC } from 'react';

import styles from './styles.module.scss';
import { Job, ObjectOf } from '../../../../../types';

export interface AssignmentCardDetailsProps {
  tasks: Job[];
}

export const AssignmentCardDetails: FC<AssignmentCardDetailsProps> = ({
  tasks,
}) => {
  const jobs: ObjectOf<{ taskName: string; count: number }> = {};

  tasks.forEach(({ task, custom }) => {
    const name = custom?.name || task?.name || '';

    if (jobs[name]) {
      jobs[name].count++;
    } else {
      jobs[name] = {
        taskName: name,
        count: 1,
      };
    }
  });

  return (
    <div className={styles.details}>
      <h6>Tasks</h6>
      {Object.values(jobs).map(({ taskName, count }) => {
        return (
          <p>
            {taskName} <strong>x{count}</strong>
          </p>
        );
      })}
    </div>
  );
};
