import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
	Box,
	Heading,
	Text,
	Button,
	Modal,
	ModalHeader,
	ModalContent,
	ModalBody,
	ModalOverlay,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Stack,
	Input,
} from "@chakra-ui/react";
import BackButton from "../../_components/BackButton";
import Island from "../../_components/Island";
import { components as LayoutComponents } from "../../components/layout";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { Tab } from "../../_components/Tab/Tab";
import { Menu } from "semantic-ui-react";
import { RequestCalls } from "./RequestCalls";
import { Subscription } from "../../types";
import { CreateRequestCallModal } from "./CreateRequestCallModal"; // Import the modal component
import { forceActualDay, formatAsNaira } from "../../helpers/helpers";
import DatePicker from "react-date-picker";

const { Trail } = LayoutComponents;

const GET_SUBSCRIPTION_DETAILS = gql`
	query SubscriptionDetails($subscriptionId: String!) {
		subscriptionDetails(subscriptionId: $subscriptionId) {
			id
			plan {
				interval
				costBreakdown {
					total
				}
				package {
					name
					baseInterval
					maximumCalls
				}
			}
			client {
				email
				firstName
				lastName
				phoneNumber
			}
			category {
				name
				id
			}
			homeownerRequest {
				address {
					address
					state
				}
				startDate
			}
			subscriptionRequestCalls {
				id
				dateOfAssignment
				createdAt
				updatedAt
				assignedPro {
					firstName
					lastName
				}
				proPaymentAmount
			}
			createdAt
		}
	}
`;

const IS_MAX_NO_OF_CALLS_MET = gql`
	query Query($dateOfAssignment: DateTimeISO!, $subscriptionRequest: String!) {
		isMaxNumberOfRequestCallsForBaseIntervalMet(
			dateOfAssignment: $dateOfAssignment
			subscriptionRequest: $subscriptionRequest
		)
	}
`;

const REQUEST_CALL_MUTATION = gql`
	mutation CreateSubscriptionRequestCall(
		$dateOfAssignment: DateTimeISO!
		$subscriptionRequest: String!
	) {
		createSubscriptionRequestCall(
			dateOfAssignment: $dateOfAssignment
			subscriptionRequest: $subscriptionRequest
		) {
			id
		}
	}
`;
export interface SubscriptionDetailsProps {}

export const SubscriptionDetails: FC<SubscriptionDetailsProps> = ({}) => {
	const { id } = useParams<{ id: string }>();

	const [dateOfAssignment, setDateOfAssignment] = useState<Date>(new Date());

	const [
		createRequestCallIModalsOpen,
		setCreateRequestCallIModalsOpen,
	] = useState(false); // State for modal visibility

	const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);

	const {
		loading,
		error,
		data,
		refetch: refetchSubscriptionDetails,
	} = useQuery(GET_SUBSCRIPTION_DETAILS, {
		variables: { subscriptionId: id },
	});

	const [
		isMaxNoOfCallsRequest,
		{
			loading: isMaxNoOfCallsMetLoading,
			error: isMaxNoOfCallsMetError,
			data: isMaxNoOfCallsMetData,
		},
	] = useLazyQuery(IS_MAX_NO_OF_CALLS_MET);

	const [
		requestCall,
		{ loading: requestCallIsLoading, error: requestCallError, reset },
	] = useMutation(REQUEST_CALL_MUTATION, {
		onCompleted: () => {
			setCreateRequestCallIModalsOpen(false);
			refetchSubscriptionDetails();
		},
	});

	const handleRequestCall = async () => {
		if (!dateOfAssignment) {
			return;
		}

		console.log("working");

		const isMaxNoOfCallsMet = (
			await isMaxNoOfCallsRequest({
				variables: {
					subscriptionRequest: id,
					dateOfAssignment: new Date(dateOfAssignment),
				},
			})
		)?.data?.isMaxNumberOfRequestCallsForBaseIntervalMet;

		if (isMaxNoOfCallsMet) {
			setWarningModalIsOpen(true);
			return;
		}

		requestCall({
			variables: {
				subscriptionRequest: id,
				dateOfAssignment: new Date(dateOfAssignment),
			},
		});
	};

	const getInterval = (subscription: Subscription) => {
		const interval = subscription.plan.package.baseInterval;

		//remove 'ly' from the interval
		return interval.slice(0, -2).toLowerCase();
	};

	const handleReset = () => {
		reset();
	};

	const closeWarningModal = () => {
		setWarningModalIsOpen(false);
	};

	if (loading) return <Loading />;
	if (error) return <RequestFailed errorMessage={error.message} />;

	const subscriptionDetails: Subscription = data?.subscriptionDetails;

	const panes = [
		{
			id: "requestCalls",
			title: "Scheduled Requests",
			render: () => <RequestCalls subscription={subscriptionDetails} />,
		},
	];

	const callButtonIsLoading = requestCallIsLoading || isMaxNoOfCallsMetLoading;

	console.log({
		callButtonIsLoading,
		requestCallIsLoading,
		isMaxNoOfCallsMetLoading,
	});

	return (
		<>
			<BackButton />
			<Modal
				isOpen={warningModalIsOpen}
				onClose={() => {
					setWarningModalIsOpen(false);
				}}
				isCentered
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader textAlign={"center"}>Warning!</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing={4}>
							<Text>
								{`You have exceeded the recommended number of requests for this ${getInterval(
									subscriptionDetails
								)}. Are you sure you want to continue?`}
							</Text>

							<Stack direction={"row"} alignSelf={"end"}>
								<Button
									colorScheme="primary"
									onClick={() => {
										requestCall({
											variables: {
												subscriptionRequest: id,
												dateOfAssignment: new Date(dateOfAssignment),
											},
										});

										if (!requestCallIsLoading && !requestCallError) {
											closeWarningModal();
										}
									}}
									isLoading={requestCallIsLoading}
								>
									Yes, Proceed
								</Button>
								<Button
									colorScheme={"red"}
									variant="outline"
									onClick={closeWarningModal}
								>
									No, Cancel
								</Button>
							</Stack>
						</Stack>
					</ModalBody>
				</ModalContent>
			</Modal>

			<Modal
				isOpen={createRequestCallIModalsOpen}
				onClose={() => setCreateRequestCallIModalsOpen(false)}
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Schedule a request</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{requestCallError && (
							<RequestFailed
								errorMessage={requestCallError.message}
								onRetry={handleReset}
							/>
						)}
						{!requestCallError && (
							<>
								<FormControl isRequired mb={4}>
									<FormLabel>Date of Assignment</FormLabel>
									<Input
										type="date"
										value={dateOfAssignment.toISOString().split("T")[0]}
										onChange={(e) =>
											setDateOfAssignment(
												forceActualDay(new Date(e.target.value))
											)
										}
									/>
								</FormControl>
								<Stack alignSelf={"end"}>
									<Button
										colorScheme="primary"
										isLoading={requestCallIsLoading || isMaxNoOfCallsMetLoading}
										isDisabled={!dateOfAssignment}
										onClick={handleRequestCall}
									>
										Submit
									</Button>
								</Stack>
							</>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
			<Island
				header={<Trail root="Subscriptions" child="Subscription Details" />}
			>
				<Box p={4}>
					<Heading as="h2" size="md" mb={4} color="#003952">
						Subscription Details
					</Heading>
					<div className="grid grid-cols-[2fr,4fr] gap-2 max-w-lg mb-8">
						<Text>
							<strong>ID:</strong>
						</Text>
						<Text>{subscriptionDetails.id}</Text>

						<Text>
							<strong>Client:</strong>
						</Text>
						<Text>
							{subscriptionDetails.client.firstName}{" "}
							{subscriptionDetails.client.lastName}
						</Text>

						<Text>
							<strong>Email:</strong>
						</Text>
						<Text>{subscriptionDetails.client.email}</Text>

						<Text>
							<strong>Phone:</strong>
						</Text>
						<Text>{subscriptionDetails.client.phoneNumber}</Text>

						<Text>
							<strong>Address:</strong>
						</Text>
						<Text>
							{subscriptionDetails.homeownerRequest.address.address},{" "}
							{subscriptionDetails.homeownerRequest.address.state}
						</Text>

						<Text>
							<strong>Package:</strong>
						</Text>
						<Text>{subscriptionDetails.plan.package.name}</Text>

						<Text>
							<strong>Frequency:</strong>
						</Text>
						<Text>
							{subscriptionDetails.plan.package.maximumCalls}x{" "}
							{subscriptionDetails.plan.package.baseInterval.toLowerCase()}
						</Text>

						<Text>
							<strong>Payment Plan:</strong>
						</Text>
						<Text>
							{formatAsNaira(subscriptionDetails.plan.costBreakdown.total)}{" "}
							billed {subscriptionDetails.plan.interval.toLowerCase()}
						</Text>

						<Text>
							<strong>Category:</strong>
						</Text>
						<Text>{subscriptionDetails.category.name}</Text>

						<Text>
							<strong>First Request:</strong>
						</Text>
						<Text>
							{new Date(
								subscriptionDetails.homeownerRequest.startDate
							).toLocaleDateString()}
						</Text>

						<Text>
							<strong>Created On:</strong>
						</Text>
						<Text>
							{new Date(subscriptionDetails.createdAt).toLocaleDateString()}
						</Text>
					</div>

					<Button
						mb={8}
						colorScheme="primary"
						onClick={() => setCreateRequestCallIModalsOpen(true)}
					>
						Schedule a Request
					</Button>
					<div className="w-full mb-8">
						<Tab
							menu={{ secondary: true, pointing: true }}
							panes={panes.map(({ render, title }) => ({
								render,
								menuItem: <Menu.Item key={title}>{title}</Menu.Item>,
							}))}
						/>
					</div>
				</Box>
			</Island>
		</>
	);
};
