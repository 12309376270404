import React, { FC } from "react";
import { Dropdown } from "semantic-ui-react";
import { formatAsNaira } from "../../../../../../../../../helpers/helpers";

export interface RateProps {
  rate?: number;
  workdays: number;
}

export const Rate: FC<RateProps> = ({ rate, workdays }) => {
  const [interval, setInterval] = React.useState<
    "daily" | "weekly" | "monthly"
  >("daily");

  let scaledRate = rate;

  if (rate) {
    if (interval === "weekly") {
      scaledRate = rate * workdays;
    }

    if (interval === "monthly") {
      scaledRate = rate * workdays * 4; // 4 weeks
    }
  }

  return (
    <p>
      <strong>{scaledRate ? formatAsNaira(scaledRate) : "N/A"}</strong> per pro
      each{" "}
      <Dropdown
        value={interval}
        onChange={(e, { value }) =>
          setInterval(value as "daily" | "weekly" | "monthly")
        }
        options={[
          {
            key: "daily",
            text: "day",
            value: "daily",
          },
          {
            key: "weekly",
            text: "week",
            value: "weekly",
          },
          {
            key: "monthly",
            text: "month",
            value: "monthly",
          },
        ]}
      />
    </p>
  );
};
