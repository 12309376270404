import { gql } from "@apollo/client";

export const UPDATE_QUOTATION_ITEMS = gql`
  mutation UpdateContractQuotationItems(
    $input: UpdateContractQuotationItemsInput!
  ) {
    updateContractQuotationItems(input: $input) {
      id
      items {
        proCount
        level
        categoryId
        rate
        isActive
      }
    }
  }
`;

export const SEND_QUOTATION = gql`
  mutation SendContractQuotation($id: String!) {
    sendContractQuotation(id: $id) {
      id
      status
    }
  }
`;

export const APPROVE_QUOTATION = gql`
  mutation ApproveContractQuotation($id: String!) {
    approveContractQuotation(id: $id) {
      id
      status
    }
  }
`;
