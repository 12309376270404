import Joi from "@hapi/joi";

export const validator = Joi.object({
  firstName: Joi.string().min(1),
  lastName: Joi.string().min(1),
  email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }),
  phoneNumber: Joi.string().pattern(new RegExp(/^(70|(80|81)|90)\d{8}$/)),
  streetName: Joi.string().min(1),
  buildingNumber: Joi.string().min(1),
  city: Joi.string().min(1),
  area: Joi.string().min(1),
  landmark: Joi.string().min(1),
  state: Joi.string().min(1),
});

export const baseRegisterValidator = validator.and(
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "streetName",
  "buildingNumber",
  "state",
  "city",
  "area"
);
