import React, { FC, useState } from "react";
import { IFormField } from "./../types";
import { Grid, Button } from "semantic-ui-react";
import { PersonalInfo } from "./PersonalInfo";
import { SUBMIT_TRADESMAN_FORM } from "../../../graphql/query";
import { AddressInfo } from "./AddressInfo";
import { FinanceInfo } from "./FinanceInfo";
import { useMutation } from "@apollo/client";

export const TradesmanReg: FC = () => {
  const [step, setStep] = useState<number>(0);
  const [firstName, setFirstName] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [lastName, setLastname] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [email, setEmail] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [phoneNumber, setPhoneNumber] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [whatsappNumber, setWhatsappNumber] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [buildingNumber, setBuildingNumber] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [streetName, setStreetName] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [landMark, setLandMark] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [area, setArea] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [location, setLocation] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });
  const [state, setState] = useState<IFormField>({
    error: false,
    initial: true,
    value: "Lagos",
  });
  const [accountNumber, setAccountNumber] = useState<IFormField>({
    error: false,
    initial: true,
    value: "",
  });

  const [categories, setFetchedCategory] = useState([]);
  const [category, setCategory] = useState<string>("");
  const [level, setLevel] = useState<number>(0);
  const [bankList, setBankList] = useState([]);
  const [bankCode, setBankCode] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };
  const previousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const clearMessage = () => {
    setErrorMessage("");
  };

  const clearForm = () => {
    setFirstName((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setLastname((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setEmail((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setPhoneNumber((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setWhatsappNumber((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setCategory("");
    setLevel(0);
    setBuildingNumber((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setStreetName((state) => {
      return {
        ...state,
        value: "",
      };
    });
    setLandMark((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setArea((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setState((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setLocation((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setAccountNumber((state) => {
      return {
        ...state,
        value: "",
        error: false,
      };
    });
    setBankList([]);
    setAccountName("");
    setBankCode("");
    clearMessage();
  };

  const [submit, { loading: submitLoading }] = useMutation(
    SUBMIT_TRADESMAN_FORM
  );

  const submitForm = async () => {
    try {
      const { data } = await submit({
        variables: {
          input: {
            firstName: firstName.value,
            lastName: lastName.value,
            imageUrl: "",
            email: email.value,
            phoneNumber: `+234${phoneNumber.value}`,
            location: {
              streetName: streetName.value,
              buildingNumber: buildingNumber.value,
              area: area.value,
              location: location.value,
              landmark: landMark.value,
              city: state.value,
            },
            categoryId: category,
            level: level,
            accountNumber: accountNumber.value,
            bankCode: bankCode,
          },
        },
      });
      if (data) {
        setSuccessMessage(data.createTradesman);
        clearForm();
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  const personalInfoProps = {
    clearForm,
    clearMessage,
    level,
    setLevel,
    firstName,
    setFirstName,
    lastName,
    setLastname,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    whatsappNumber,
    setWhatsappNumber,
    category,
    setCategory,
    categories,
    setFetchedCategory,
    step,
    nextStep,
  };

  const addressInfoProps = {
    clearForm,
    clearMessage,
    step,
    nextStep,
    previousStep,
    buildingNumber,
    setBuildingNumber,
    streetName,
    setStreetName,
    landMark,
    setLandMark,
    area,
    setArea,
    location,
    setLocation,
    state,
    setState,
  };

  const financeInfoProps = {
    clearForm,
    clearMessage,
    step,
    previousStep,
    bankList,
    setBankList,
    bankCode,
    setBankCode,
    accountName,
    setAccountName,
    accountNumber,
    setAccountNumber,
    level,
    firstName,
    lastName,
    email,
    phoneNumber,
    whatsappNumber,
    category,
    buildingNumber,
    streetName,
    landMark,
    area,
    location,
    state,
    submitLoading,
    successMessage,
    errorMessage,
    submitForm,
  };
  switch (step) {
    case 0:
      return <PersonalInfo {...personalInfoProps} />;
    case 1:
      return <AddressInfo {...addressInfoProps} />;
    case 2:
      return <FinanceInfo {...financeInfoProps} />;
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Button onClick={nextStep}>Change step</Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
