import { gql } from '@apollo/client';

export const CONFIRM_INVOICE_PAYMENT = gql`
  mutation ConfirmInvoicePayment($invoiceId: String!) {
    confirmInvoicePayment(invoiceId: $invoiceId) {
      status
      message
    }
  }
`;
