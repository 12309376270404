import { gql } from "@apollo/client";

export const MARK_UPLOADED = gql`
  mutation MarkUploaded($proTradeRequirementId: String!) {
    markProTradeRequirementAsProcessed(
      proTradeRequirementId: $proTradeRequirementId
    ) {
      id
      processedAt
    }
  }
`;
