import { gql, useQuery } from "@apollo/client";
import React, { FC } from "react";
import Button from "@laborhack/custom-button";
import Loading from "../../../../_components/Loading";
import RequestFailed from "../../../../_components/RequestFailed";
import {
  Filter,
  FilterClass,
  ProposedContractInfo,
  ProWithContractConflictInformation,
} from "../../types";
import { ContractProItem } from "./components/ContractProItem";
import styles from "./styles.module.scss";
import { Dropdown, Icon, Modal } from "semantic-ui-react";
import { AddFilter } from "./components/AddFilter";

export interface SelectContractProProps {
  proposedContract: ProposedContractInfo;
  categoryId: string;
  onSelect: (pro: { id: string; name: string }) => void;
}

const GET_PROS_WITH_CONFLICT_INFO = gql`
  query GetProsWithConflictInfo(
    $proposed: ProposedContractInfo!
    $categoryId: String!
  ) {
    prosWithContractConflictInformation(
      proposed: $proposed
      categoryId: $categoryId
    ) {
      id
      pro {
        firstName
        lastName
        level
        location {
          area
          location
        }
      }
      conflicts {
        ... on ContractConflict {
          type
          contract {
            id
            contractLength
            contractInterval
            recruitment {
              id
              client {
                firstName
                lastName
              }
            }
          }
        }
        ... on AssignmentConflict {
          type
          assignment {
            id
            date
          }
        }
      }
    }
  }
`;

const filterHandlers: {
  [Property in keyof FilterClass]: (
    pro: ProWithContractConflictInformation,
    value: any
  ) => boolean;
} = {
  availability: (pro: ProWithContractConflictInformation, value: boolean) => {
    if (value) {
      return !pro.conflicts.length;
    }

    return !!pro.conflicts.length;
  },
  level: (
    pro: ProWithContractConflictInformation,
    value: FilterClass["level"]
  ) => {
    return `L${pro.pro.level}` === value;
  },
};

export const SelectContractPro: FC<SelectContractProProps> = ({
  proposedContract,
  categoryId,
  onSelect,
}) => {
  const [filters, setFilters] = React.useState<Filter[]>([
    {
      class: "availability",
      value: true,
      name: "Available",
    },
  ]);

  const [selectedPro, setSelectedPro] = React.useState<{
    id: string;
    name: string;
  }>();

  const { loading, error, data } = useQuery<{
    prosWithContractConflictInformation: ProWithContractConflictInformation[];
  }>(GET_PROS_WITH_CONFLICT_INFO, {
    variables: {
      proposed: proposedContract,
      categoryId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <RequestFailed />;
  }

  const pros = data?.prosWithContractConflictInformation.filter(
    ({ pro, conflicts }) => {
      const validityPerClass: {
        [Property in keyof Partial<FilterClass>]: boolean;
      } = {};

      for (const filter of filters) {
        if (!validityPerClass[filter.class]) {
          validityPerClass[filter.class] = filterHandlers[filter.class](
            { id: "", pro, conflicts },
            filter.value
          );
        }
      }

      return Object.values(validityPerClass).every(Boolean);
    }
  );

  return (
    <div className={styles.wrapper}>
      <h3>Select Pro</h3>
      <div className='mt-8 flex justify-end items-center'>
        <div className='mr-4 flex'>
          {filters.map((filter, index) => {
            return (
              <div className='flex ml-2 rounded-full pl-4 pr-2 py-2 bg-primary-150 text-primary-500 font-semibold'>
                <span>{filter.name}</span>
                <Icon
                  className='!mr-0 !ml-2 cursor-pointer'
                  name='remove circle'
                  onClick={() =>
                    setFilters((prev) =>
                      prev.filter(
                        (f) => f.class + f.name !== filter.class + filter.name
                      )
                    )
                  }
                />
              </div>
            );
          })}
        </div>
        <Dropdown
          icon={null}
          pointing='top right'
          trigger={
            <Button
              type='subtle'
              variant='basic'
              className='!px-4 !bg-primary-150 !text-primary-500'
            >
              <Icon className='!ml-0' name='plus' />
              Add Filter
            </Button>
          }
        >
          <Dropdown.Menu>
            <AddFilter filters={filters} setFilters={setFilters} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={styles.list}>
        {pros && pros.length ? (
          pros.map(({ id, pro, conflicts }) => {
            const name = `${pro.firstName} ${pro.lastName}`;

            return (
              <ContractProItem
                key={id}
                name={name}
                location={`${pro.location.area}, ${pro.location.location}`}
                level={pro.level.toString()}
                conflicts={conflicts}
                selected={id === selectedPro?.id}
                onSelect={() =>
                  setSelectedPro({
                    id,
                    name,
                  })
                }
              />
            );
          })
        ) : (
          <div className='w-full flex justify-center'>
            <div>
              <p className='text-primary-500'>No Pros</p>
            </div>
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <Button
          disabled={!selectedPro?.id}
          onClick={() => {
            selectedPro?.id && onSelect(selectedPro);
          }}
          variant='success'
        >
          Assign
        </Button>
      </div>
    </div>
  );
};
