import { gql } from "@apollo/client";
import { OrderHandlingQuotationStatus } from "../../types";

export const getQuoteStatusColor = (status: OrderHandlingQuotationStatus) => {
  const handlers: Record<OrderHandlingQuotationStatus, string> = {
    [OrderHandlingQuotationStatus.ACCEPTED]: "green",
    [OrderHandlingQuotationStatus.DECLINED]: "red",
    [OrderHandlingQuotationStatus.PENDING]: "yellow",
  };

  return handlers[status] || "gray";
};

export const GET_QUOTE = gql`
  query GetQuote($id: String!) {
    orderHandlingQuotation(id: $id) {
      id
      serialNumber
      sentAt
      status
      notes
      assignments {
        id
        jobs {
          id
          task {
            id
            name
            description
          }
          custom {
            name
            description
          }
        }
        category {
          id
          name
        }
        tradesman {
          id
          firstName
          lastName
        }
        otherPros {
          id
        }
      }
      items {
        materials {
          name
          quantity
          unitPrice
        }
        labor
        job {
          id
        }
      }
      expiresAt
      rejectionReason
      costBreakdown {
        total
        discount
        subTotal
        laborHackFee
        laborFee
        totalMaterialsCost
        materialCommission
        laborCommission
        tax
      }
      invoice {
        id
        invoiceNumber
        canceled
        sent
      }
    }
  }
`;
