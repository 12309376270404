import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Badge,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { formatDistanceToNowStrict } from "date-fns";
import { useMemo } from "react";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaClone,
  FaEllipsisV,
  FaExternalLinkAlt,
  FaPaperPlane,
  FaReceipt,
  FaTimesCircle,
} from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { AssignmentQuoteEntry } from "../../../../../features/orders/assignment-quote-entry";
import {
  getQuoteStatusColor,
  GET_QUOTE,
} from "../../../../../features/orders/constants";
import { formatAsNaira } from "../../../../../helpers/helpers";
import {
  OrderHandlingQuotation,
  CreateOrderHandlingQuotationItem,
  OrderHandlingQuotationStatus,
} from "../../../../../types";
import Loading from "../../../../../_components/Loading";
import RequestFailed from "../../../../../_components/RequestFailed";

const GENERATE_INVOICE = gql`
  mutation GenerateInvoice($input: CreateMaterialsInvoiceInput!) {
    createMaterialsInvoice(input: $input) {
      id
    }
  }
`;

const ACCEPT_QUOTE = gql`
  mutation AcceptQuote($id: String!) {
    acceptOrderHandlingQuotation(id: $id) {
      id
      status
    }
  }
`;

const REJECT_QUOTE = gql`
  mutation RejectQuote($id: String!) {
    rejectOrderHandlingQuotation(id: $id) {
      id
      status
    }
  }
`;

const SEND_QUOTE = gql`
  mutation SendQuote($id: String!) {
    sendOrderHandlingQuotation(id: $id) {
      id
      sentAt
      status
    }
  }
`;

export const ViewQuotePage = () => {
  const { id, orderCode } = useParams<{
    id: string;
    orderCode: string;
  }>();

  const history = useHistory();

  const getQuoteHandle = useQuery<{
    orderHandlingQuotation: OrderHandlingQuotation;
  }>(GET_QUOTE, {
    variables: {
      id,
    },
  });

  const [acceptQuote, acceptQuoteHandle] = useMutation(ACCEPT_QUOTE);
  const [declineQuote, declineQuoteHandle] = useMutation(REJECT_QUOTE);
  const [sendQuote, sendQuoteHandle] = useMutation(SEND_QUOTE);

  const [generateInvoice, generateInvoiceHandle] = useMutation(
    GENERATE_INVOICE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [GET_QUOTE],
    }
  );

  const quote = getQuoteHandle.data?.orderHandlingQuotation;

  const quotationItemMap = useMemo(() => {
    if (!quote) {
      return {};
    }

    return quote.items.reduce((acc, { job, labor, materials }) => {
      const item: CreateOrderHandlingQuotationItem = {
        jobId: job.id,
      };

      if (labor !== null) {
        item.labor = labor;
      }

      if (materials !== null) {
        item.materials = materials.map((material) => ({
          id: `${Math.random()}`,
          name: material.name,
          quantity: material.quantity,
          unitPrice: material.unitPrice,
        }));
      }

      acc[job.id] = item;
      return acc;
    }, {} as Record<string, CreateOrderHandlingQuotationItem>);
  }, [quote]);

  if (getQuoteHandle.loading) {
    return <Loading />;
  }

  if (getQuoteHandle.error || !quote) {
    return <RequestFailed />;
  }

  if (generateInvoiceHandle.error) {
    return (
      <RequestFailed
        errorMessage={generateInvoiceHandle.error.message}
        onRetry={generateInvoiceHandle.reset}
      />
    );
  }

  if (sendQuoteHandle.error) {
    return (
      <RequestFailed
        errorMessage={sendQuoteHandle.error.message}
        onRetry={sendQuoteHandle.reset}
      />
    );
  }

  if (acceptQuoteHandle.error) {
    return (
      <RequestFailed
        errorMessage={acceptQuoteHandle.error.message}
        onRetry={acceptQuoteHandle.reset}
      />
    );
  }

  if (declineQuoteHandle.error) {
    return (
      <RequestFailed
        errorMessage={declineQuoteHandle.error.message}
        onRetry={declineQuoteHandle.reset}
      />
    );
  }

  const handleGenerateInvoice = () => {
    generateInvoice({
      variables: {
        input: {
          quotationId: quote.id,
          allowedPaymentProviders: ["paystack", "offline"],
        },
      },
    });
  };

  const handleSendQuote = () => {
    sendQuote({
      variables: {
        id: quote.id,
      },
    });
  };

  return (
    <>
      <div className='flex flex-col gap-4 '>
        <div className='flex'>
          <Button
            colorScheme='primary'
            variant='ghost'
            leftIcon={<FaArrowLeft />}
            onClick={() => {
              history.push(`/orders/${orderCode}`);
            }}
          >
            Back
          </Button>
        </div>
        <div className='grid grid-cols-3 gap-4'>
          <div className='col-span-2'>
            <div className='flex flex-col gap-8 p-6 bg-white border'>
              <div className='mb-4'>
                <h2 className='text-primary-500 text-lg font-medium m-0'>
                  Quote #{quote.serialNumber}
                </h2>
                <p className='m-0 text-primary-300 text-sm'>
                  Shows details of the quote
                </p>
              </div>
              <div>
                {quote.assignments?.map((assignment) => (
                  <AssignmentQuoteEntry
                    key={assignment.id}
                    assignment={assignment}
                    itemsMap={quotationItemMap}
                    onChange={() => {}}
                    readonly
                  />
                ))}
              </div>
            </div>
          </div>
          <div>
            <div className='flex flex-col gap-6 p-6 bg-white border'>
              <div className='flex justify-between'>
                <h2 className='text-primary-500 text-lg font-medium m-0'>
                  Breakdown
                </h2>

                <div className='flex justify-end items-center gap-2'>
                  {!quote.sentAt && (
                    <Badge colorScheme='yellow' fontSize='lg'>
                      NOT SENT
                    </Badge>
                  )}
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label='Options'
                      icon={<FaEllipsisV />}
                      variant='ghost'
                      isLoading={
                        generateInvoiceHandle.loading || sendQuoteHandle.loading
                      }
                    />
                    <MenuList>
                      <MenuItem
                        isDisabled={!!quote.sentAt}
                        icon={<FaPaperPlane />}
                        onClick={handleSendQuote}
                      >
                        Send
                      </MenuItem>
                      <MenuItem
                        isDisabled={
                          quote.status !==
                            OrderHandlingQuotationStatus.ACCEPTED ||
                          !!quote.invoice
                        }
                        icon={<FaReceipt />}
                        onClick={handleGenerateInvoice}
                      >
                        Generate Invoice
                      </MenuItem>
                      <MenuItem
                        isDisabled={
                          quote.status !== OrderHandlingQuotationStatus.DECLINED
                        }
                        icon={<FaClone />}
                        onClick={() => {
                          history.push(
                            `/orders/${orderCode}/quotes/create?reference=${quote.id}`
                          );
                        }}
                      >
                        Create Quote From
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>

              <Divider />
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col gap-2'>
                  <div className='flex justify-between'>
                    <p className='m-0 text-primary-300 text-sm'>Invoice</p>
                    <p className='m-0 text-primary-500 text-sm font-medium'>
                      {quote.invoice ? (
                        <Button
                          colorScheme='primary'
                          variant='link'
                          rightIcon={
                            <FaExternalLinkAlt className='inline-block' />
                          }
                          onClick={() => {
                            window.open(
                              `/invoices/${quote.invoice?.id}`,
                              "_blank"
                            );
                          }}
                        >
                          #{quote.invoice.invoiceNumber}
                        </Button>
                      ) : (
                        "Not Generated"
                      )}
                    </p>
                  </div>
                  <div className='flex justify-between'>
                    <p className='m-0 text-primary-300 text-sm'>Status</p>
                    <p className='m-0 text-primary-500 text-sm font-medium'>
                      <Badge colorScheme={getQuoteStatusColor(quote.status)}>
                        {quote.status.replace("_", " ")}
                      </Badge>
                    </p>
                  </div>
                  {quote.rejectionReason && (
                    <div className='flex justify-between'>
                      <p className='m-0 text-primary-300 text-sm'>Reason</p>
                      <p className='m-0 text-primary-500 text-sm font-medium'>
                        {quote.rejectionReason}
                      </p>
                    </div>
                  )}
                  <div className='flex justify-between'>
                    <p className='m-0 text-primary-300 text-sm'>Expires In</p>
                    <p className='m-0 text-primary-500 text-sm font-medium'>
                      {formatDistanceToNowStrict(
                        new Date(quote?.expiresAt || "")
                      )}
                    </p>
                  </div>
                  <div className='flex justify-between'>
                    <p className='m-0 text-primary-300 text-sm'>
                      Additional Notes
                    </p>
                    <p className='m-0 text-primary-500 text-sm font-medium'>
                      {quote.notes || "N/A"}
                    </p>
                  </div>
                  <div className='flex justify-between'>
                    <p className='m-0 text-primary-300 text-sm'>Materials</p>
                    <p className='m-0 text-primary-500 text-sm font-medium'>
                      {formatAsNaira(quote.costBreakdown.totalMaterialsCost)}
                    </p>
                  </div>
                  <div className='flex justify-between'>
                    <p className='m-0 text-primary-300 text-sm'>Labor</p>
                    <p className='m-0 text-primary-500 text-sm font-medium'>
                      {formatAsNaira(quote.costBreakdown.laborFee)}
                    </p>
                  </div>
                  <div className='flex justify-between'>
                    <p className='m-0 text-primary-300 text-sm'>
                      Service Charge
                    </p>
                    <p className='m-0 text-primary-500 text-sm font-medium'>
                      {formatAsNaira(
                        quote.costBreakdown.laborCommission +
                          quote.costBreakdown.materialCommission
                      )}
                    </p>
                  </div>
                  <div className='flex justify-between'>
                    <p className='m-0 text-primary-300 text-sm'>Tax</p>
                    <p className='m-0 text-primary-500 text-sm font-medium'>
                      {formatAsNaira(quote.costBreakdown.tax)}
                    </p>
                  </div>
                </div>
              </div>
              <Divider />
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col gap-2'>
                  <div className='flex justify-between'>
                    <p className='m-0 text-primary-300 text-sm'>Total</p>
                    <p className='m-0 text-primary-500 font-semibold'>
                      {formatAsNaira(quote.costBreakdown.total)}
                    </p>
                  </div>
                </div>
              </div>
              <Divider />
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col gap-2'>
                  <Button
                    isLoading={acceptQuoteHandle.loading}
                    isDisabled={
                      quote.status !== OrderHandlingQuotationStatus.PENDING
                    }
                    colorScheme='green'
                    rightIcon={<FaCheckCircle className='inline-block' />}
                    onClick={() => {
                      acceptQuote({
                        variables: {
                          id: quote.id,
                        },
                      });
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    isLoading={declineQuoteHandle.loading}
                    isDisabled={
                      quote.status === OrderHandlingQuotationStatus.DECLINED ||
                      (!!quote.invoice && !quote.invoice.canceled)
                    }
                    colorScheme='red'
                    rightIcon={<FaTimesCircle className='inline-block' />}
                    onClick={() => {
                      declineQuote({
                        variables: {
                          id: quote.id,
                        },
                      });
                    }}
                    variant='ghost'
                  >
                    Decline
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
