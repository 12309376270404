import { GET_REFERRER_PAYOUTS } from "@/features/referrer-payouts/constants";
import { ReferrerPayoutTable } from "@/features/referrer-payouts/table";
import { ReferrerPayout } from "@/features/referrer-payouts/types";
import { TransferStatus } from "@/types";
import Island from "@/_components/Island";
import Loading from "@/_components/Loading";
import RequestFailed from "@/_components/RequestFailed";
import { useQuery } from "@apollo/client";

export default function Page() {
  const { data, loading, error } = useQuery<{
		referrerPayouts: ReferrerPayout[];
	}>(GET_REFERRER_PAYOUTS, {
		variables: {
			transferStatus: [TransferStatus.AWAITING_APPROVAL],
		},
	});

	if (loading) return <Loading />;

	if (error) return <RequestFailed />;

	return (
		<Island>
			<div className="mb-4">
				<h2 className="text-primary-500 text-xl font-bold m-0">
					Referrer Payout Requests
				</h2>
				<p className="m-0 text-primary-300">
					Shows a list of all referrer payout requests awaiting approval
				</p>
			</div>

			<div>
				<ReferrerPayoutTable
					isConfirmations={true}
					data={data?.referrerPayouts}
				/>
			</div>
		</Island>
	);
}