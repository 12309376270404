import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";

interface SetRecruitmentDiscountModalProps {
  handleSubmitRecruitmentDiscount: (recruitmentId: string, recruitmentDiscount: number) => void;
  onClose: () => void;
  isOpen: boolean;
  recruitmentId: string;
}

export const SetRecruitmentDiscountModal: React.FC<SetRecruitmentDiscountModalProps> = ({
  isOpen,
  onClose,
  handleSubmitRecruitmentDiscount,
  recruitmentId,
}) => {
  const [ recruitmentDiscount, setRecruitmentDiscount] = React.useState("");

  return (
    <>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Recruitment Discount</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel fontSize={"sm"} htmlFor="email" color="primary.500">
                Discount(%)
              </FormLabel>
              <InputGroup>
                <Input
                  id="monthly-gross-salary"
                  type={"number"}
                  min={0}
                  max={100}
                  fontSize={"sm"}
                  value={recruitmentDiscount}
                  onChange={({ target: { value } }) => {
                    if ((Number(value) > 99) || (Number(value) < 0)) {
                      return;
                  }
                    setRecruitmentDiscount(!isNaN(Number(value)) ? value : "")
                  }
                }
                />
                <InputRightElement children="%" />
              </InputGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              isDisabled={!recruitmentDiscount}
              onClick={() => {
                handleSubmitRecruitmentDiscount(recruitmentId, Number(recruitmentDiscount)/100);
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
