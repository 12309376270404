import {
  createSlice,
  createAsyncThunk,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import { IEditSubCategory } from "./../components/subcategory/types";

const initialState: IEditSubCategory = {
  name: "",
  subcategoryId: "",
  description: "",
  imageUrl: "",
  submitted: false,
  valid: false,
  success_message: "",
  error_message: "",
  status: "idle",
};

export const editSubCategoryForm = createAsyncThunk(
  "subcategory/editSubcategory",
  async (
    data: { mutation: any },
    {
      dispatch,
      getState,
    }: { dispatch: ThunkDispatch<any, any, AnyAction>; getState: any }
  ) => {
    const { mutation } = data;
    const { editSubcategory }: any = getState();

    try {
      console.log({
        variables: {
          input: {
            name: editSubcategory.name,
            subCategoryId: editSubcategory.subcategoryId,
            description: editSubcategory.description,
            imageUrl: editSubcategory.imageUrl,
          },
        },
      });
      const { data } = await mutation({
        variables: {
          input: {
            name: editSubcategory.name,
            subCategoryId: editSubcategory.subcategoryId,
            description: editSubcategory.description,
            imageUrl: editSubcategory.imageUrl,
          },
        },
      });
      if (data) {
        dispatch(setSuccessMessage("Successfully edited"));
      }
    } catch (error) {
      dispatch(setErrorMessage("An error occured"));
    }
  }
);

export const editSubcategorySlice = createSlice({
  name: "editSubcategory",
  initialState,
  reducers: {
    setDName: (state, action) => {
      state.name = action.payload;
    },
    setDSubCategoryId: (state, action) => {
      state.subcategoryId = action.payload;
    },
    setDDescription: (state, action) => {
      state.description = action.payload;
    },
    setDImage: (state, action) => {
      state.imageUrl = action.payload;
    },
    setDSubmitted: (state, action) => {
      state.submitted = action.payload;
    },
    setDFormValid: (state, action) => {
      state.valid = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.success_message = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error_message = action.payload;
    },
    setDStatus: (state, action) => {
      state.status = action.payload;
    },
    resetDForm: (state) => {
      state.name = "";
      state.subcategoryId = "";
      state.description = "";
      state.imageUrl = "";
      state.valid = false;
      state.submitted = false;
    },
  },
  extraReducers: {
    [editSubCategoryForm.pending.type]: (state, action) => {
      state.status = "pending";
    },
    [editSubCategoryForm.fulfilled.type]: (state, action) => {
      state.status = "idle";
    },
  },
});

// D is to signify Dispatch to differentiate it in the state names in components
export const {
  setDName,
  setDSubCategoryId,
  setDDescription,
  setDImage,
  setDFormValid,
  setDSubmitted,
  resetDForm,
  setSuccessMessage,
  setErrorMessage,
  setDStatus,
} = editSubcategorySlice.actions;

export const selectCategory = (state: any) => state.editSubcategory.category;
export const selectName = (state: any) => state.editSubcategory.name;
export const selectDescription = (state: any) =>
  state.editSubcategory.description;
export const selectImage = (state: any) => state.editSubcategory.imageUrl;
export const selectValidity = (state: any) => state.editSubcategory.valid;
export const selectSubmitted = (state: any) => state.editSubcategory.submitted;
export const selectSubmissionStatus = (state: any) =>
  state.editSubcategory.status;
export const selectSuccessMessage = (state: any) =>
  state.editSubcategory.success_message;
export const selectErrorMessage = (state: any) =>
  state.editSubcategory.error_message;

export const SubCatEditSelectors = {
  selectName,
  selectCategory,
  selectDescription,
  selectValidity,
  selectImage,
  selectSubmitted,
  selectSubmissionStatus,
  selectSuccessMessage,
  selectErrorMessage,
};

export const SubCategoryEditAction = editSubcategorySlice.actions;
export const subCategoryEditReducer = editSubcategorySlice.reducer;
