import React, { FC } from "react";
import { ScheduleConflict } from "../../../types";

import styles from "./styles.module.scss";
import clsx from "clsx";
import { Icon } from "semantic-ui-react";
import { Conflicts } from "./Conflicts";

export interface ContractProItemProps {
  name: string;
  phoneNumber: string;
  conflicts?: ScheduleConflict[];
  selected: boolean;
  onSelect: () => void;
}

export const ContractProItem: FC<ContractProItemProps> = ({
  conflicts,
  name,
  phoneNumber,
  selected,
  onSelect,
}) => {
  const hasConflicts = !!conflicts?.length;

  const [showConflicts, setShowConflicts] = React.useState(false);

  return (
    <div
      data-testid='contract-pro-item'
      className={clsx(
        styles.contractProItem,
        "shadow-md shadow-primary-200 border-2 border-primary-200 cursor-pointer",
        selected && styles.selected,
        hasConflicts && "!border border-red-300 shadow-red-200 cursor-default"
      )}
      onClick={() => !hasConflicts && onSelect()}
    >
      <div className='grid grid-cols-[1.5fr_repeat(3,_1fr)] items-center'>
        <div>
          <h4
            className={clsx(
              "text-lg text-primary-500 font-semibold mb-2",
              selected && "!text-white",
              hasConflicts && "text-primary-200"
            )}
          >
            {name}
          </h4>
          <p
            className={clsx(
              "capitalize text-primary-500",
              selected && "!text-white",
              hasConflicts && "text-primary-200"
            )}
          >
            N/A
          </p>
        </div>
        <h4
          className={clsx(
            "text-lg text-primary-500 font-semibold m-0",
            selected && "!text-white",
            hasConflicts && "italic font-normal !text-red-500"
          )}
        >
          {hasConflicts ? "Has Conflicts" : "Available"}
        </h4>
        <h4
          className={clsx(
            "text-lg text-primary-500 font-semibold m-0",
            selected && "!text-white",
            hasConflicts && "text-primary-200"
          )}
        >
          {phoneNumber}
        </h4>
        <div className='justify-self-end'>
          {hasConflicts ? (
            showConflicts ? (
              <Icon
                size='large'
                className='cursor-pointer text-primary-500'
                name='chevron circle up'
                onClick={() => setShowConflicts(false)}
              />
            ) : (
              <Icon
                size='large'
                className='cursor-pointer text-primary-500'
                name='chevron circle down'
                onClick={() => setShowConflicts(true)}
              />
            )
          ) : (
            <Icon
              size='large'
              name='check circle'
              className={selected ? "text-white" : "text-primary-150"}
            />
          )}
        </div>
      </div>
      {showConflicts && hasConflicts && <Conflicts conflicts={conflicts} />}
    </div>
  );
};
