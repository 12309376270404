import React, { FC } from "react";
import { PropsInterface } from "./../types";
import { userDetails } from "../constants";
import { EditAdminModal } from "./EditAdminModal";
import { types } from "../../../service";
import Island from "../../../_components/Island";
import { useQuery } from "@apollo/client";
import { GET_ALL_ADMINS } from "../../../graphql/query";

const AdminUserDetails: FC<PropsInterface> = (props: PropsInterface) => {
  const { data: adminData, loading, refetch } = useQuery(GET_ALL_ADMINS);
  const allAdmin: types.IAdminData[] = adminData?.listAllAdmins.data;

  const user = allAdmin?.filter((admin: types.IAdminData) => {
    return props.match.params.userId === admin.id;
  });

  const refreshData = () => {
    refetch();
  };

  const { NAME, ROLE, EMAIL, PHONENUMBER } = userDetails;

  if (loading) {
    return <div className="loading">loading...</div>;
  }

  return (
    <div>
      <Island header="Client Information">
        {user?.map((user) => {
          return (
            <div key={user.id}>
              <div className="o-admin__personal-details">
                <div className="o-admin__personal-left">
                  <h3>Personal Information: </h3>
                </div>
                <div className="o-tradesmen__personal-right">
                  <h4>
                    {NAME}
                    {user.firstName} {user.lastName}
                  </h4>

                  <h4>
                    {EMAIL}
                    {user.email}
                  </h4>
                  <h4>
                    {PHONENUMBER}
                    {user.phoneNumber}
                  </h4>
                  <h4>
                    {ROLE}
                    {user.role}
                  </h4>
                </div>
              </div>
              <div className="o-admin__details-buttons">
                <EditAdminModal
                  userId={user.userId}
                  firstname={user.firstName}
                  lastname={user.lastName}
                  propEmail={user.email}
                  refresh={refreshData}
                />
              </div>
            </div>
          );
        })}
      </Island>
    </div>
  );
};

export default AdminUserDetails;
