import {
  createSlice,
  createAsyncThunk,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import { IEditCategory } from "../components/category/types";

const initialState: IEditCategory = {
  name: "",
  title: "",
  categoryId: "",
  imageUrl: "",
  isVisible: false,
  submitted: false,
  valid: false,
  success_message: "",
  error_message: "",
  status: "idle",
};

export const editCategoryForm = createAsyncThunk(
  "category/editCategory",
  async (
    data: { mutation: any },
    {
      dispatch,
      getState,
    }: { dispatch: ThunkDispatch<any, any, AnyAction>; getState: any }
  ) => {
    const { mutation } = data;
    const { editCategory }: any = getState();

    try {
      const { data } = await mutation({
        variables: {
          input: {
            name: editCategory.name,
            categoryId: editCategory.categoryId,
            imageUrl: editCategory.imageUrl,
            proTitle: editCategory.title,
            isVisible: editCategory.isVisible,
          },
        },
      });
      if (data) {
        dispatch(setSuccessMessage("Successfully edited"));
      }
    } catch (error) {
      dispatch(setErrorMessage("An error occured"));
    }
  }
);

export const editCategorySlice = createSlice({
  name: "editCategory",
  initialState,
  reducers: {
    setDName: (state, action) => {
      state.name = action.payload;
    },
    setDTitle: (state, action) => {
      state.title = action.payload;
    },
    setDCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    setDImage: (state, action) => {
      state.imageUrl = action.payload;
    },
    setDIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setDSubmitted: (state, action) => {
      state.submitted = action.payload;
    },
    setDFormValid: (state, action) => {
      state.valid = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.success_message = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error_message = action.payload;
    },
    setDStatus: (state, action) => {
      state.status = action.payload;
    },
    resetDForm: (state) => {
      state.name = "";
      state.title = "";
      state.categoryId = "";
      state.imageUrl = "";
      state.isVisible = false;
      state.valid = false;
      state.submitted = false;
    },
  },
  extraReducers: {
    [editCategoryForm.pending.type]: (state, action) => {
      state.status = "pending";
    },
    [editCategoryForm.fulfilled.type]: (state, action) => {
      state.status = "idle";
    },
  },
});

// D is to signify Dispatch to differentiate it in the state names in components
export const {
  setDName,
  setDTitle,
  setDCategoryId,
  setDImage,
  setDIsVisible,
  setDFormValid,
  setDSubmitted,
  resetDForm,
  setSuccessMessage,
  setErrorMessage,
  setDStatus,
} = editCategorySlice.actions;

export const selectCategory = (state: any) => state.editCategory.category;
export const selectName = (state: any) => state.editCategory.name;
export const selectIsVisible = (state: any) => state.editCategory.isVisible;
export const selectImage = (state: any) => state.editCategory.imageUrl;
export const selectValidity = (state: any) => state.editCategory.valid;
export const selectSubmitted = (state: any) => state.editCategory.submitted;
export const selectSubmissionStatus = (state: any) => state.editCategory.status;
export const selectSuccessMessage = (state: any) =>
  state.editCategory.success_message;
export const selectErrorMessage = (state: any) =>
  state.editCategory.error_message;

export const CatEditSelectors = {
  selectName,
  selectCategory,
  selectValidity,
  selectImage,
  selectIsVisible,
  selectSubmitted,
  selectSubmissionStatus,
  selectSuccessMessage,
  selectErrorMessage,
};

export const CategoryEditAction = editCategorySlice.actions;
export const CategoryEditReducer = editCategorySlice.reducer;
