import { gql } from '@apollo/client';

export const GET_CLIENT = gql`
  query GetClient($userId: String!) {
    getClientByUserId(userId: $userId) {
      status
      message
      data {
        recipientCode
      }
    }
  }
`;

export const LIST_REFUNDS = gql`
  query ListRefunds($data: ListRefundRequestsInput) {
    listRefundRequests(input: $data) {
      status
      message
      data {
        id
        order {
          orderCode
          client {
            name
          }
        }
        transfer {
          id
          amount
          status
        }
        createdAt
      }
    }
  }
`;

export const GET_REFUND_REQUEST = gql`
  query GetRefundRequest($id: String!) {
    getRefundRequestById(id: $id) {
      status
      message
      data {
        id
        reason
        order {
          id
          orderCode
          client {
            name
            userId
          }
        }
        transfer {
          id
          amount
          status
          transferCode
        }
        createdAt
      }
    }
  }
`;

export const GET_BANK_ACCOUNT_DETAILS_BY_RECIPIENT_CODE = gql`
  query GetBankAccountInformationByRecipientCode($recipientCode: String!) {
    getBankAccountInformationByRecipientCode(recipientCode: $recipientCode) {
      status
      message
      data {
        accountName
        accountNumber
      }
    }
  }
`;
