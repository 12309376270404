import {
  createSlice,
  createAsyncThunk,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import { IRegisterSubCategory } from "./../components/subcategory/types";

const initialState: IRegisterSubCategory = {
  name: "",
  category: "",
  description: "",
  imageUrl: "",
  submitted: false,
  valid: false,
  success_message: "",
  error_message: "",
  status: "idle",
};

export const SubmitSubCategoryForm = createAsyncThunk(
  "subcategory/createSubcategory",
  async (
    data: { mutation: any },
    {
      dispatch,
      getState,
    }: { dispatch: ThunkDispatch<any, any, AnyAction>; getState: any }
  ) => {
    const { mutation } = data;
    const { registerSubCategory }: any = getState();

    try {
      const { data } = await mutation({
        variables: {
          input: {
            name: registerSubCategory.name,
            category: registerSubCategory.category,
            description: registerSubCategory.description,
            imageUrl: registerSubCategory.imageUrl,
          },
        },
      });
      if (data) {
        dispatch(setSuccessMessage("Sub category created successfully"));
      }
    } catch (error) {
      dispatch(setErrorMessage("An error occured"));
    }
  }
);

export const registerSubCategorySlice = createSlice({
  name: "registerSubCategory",
  initialState,
  reducers: {
    setDName: (state, action) => {
      state.name = action.payload;
    },
    setDCategory: (state, action) => {
      state.category = action.payload;
    },
    setDDescription: (state, action) => {
      state.description = action.payload;
    },
    setDImage: (state, action) => {
      state.imageUrl = action.payload;
    },
    setDSubmitted: (state, action) => {
      state.submitted = action.payload;
    },
    setDFormValid: (state, action) => {
      state.valid = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.success_message = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error_message = action.payload;
    },
    setDStatus: (state, action) => {
      state.status = action.payload;
    },
    resetDForm: (state) => {
      state.name = "";
      state.category = "";
      state.description = "";
      state.imageUrl = "";
      state.valid = false;
      state.submitted = false;
    },
  },
  extraReducers: {
    [SubmitSubCategoryForm.pending.type]: (state, action) => {
      state.status = "pending";
    },
    [SubmitSubCategoryForm.fulfilled.type]: (state, action) => {
      state.status = "idle";
    },
  },
});

// D is to signify Dispatch to differentiate it in the state names in components
export const {
  setDName,
  setDCategory,
  setDDescription,
  setDImage,
  setDFormValid,
  setDSubmitted,
  resetDForm,
  setSuccessMessage,
  setErrorMessage,
  setDStatus,
} = registerSubCategorySlice.actions;

export const selectCategory = (state: any) =>
  state.registerSubCategory.category;
export const selectName = (state: any) => state.registerSubCategory.name;
export const selectDescription = (state: any) =>
  state.registerSubCategory.description;
export const selectImage = (state: any) => state.registerSubCategory.imageUrl;
export const selectValidity = (state: any) => state.registerSubCategory.valid;
export const selectSubmitted = (state: any) =>
  state.registerSubCategory.submitted;
export const selectSubmissionStatus = (state: any) =>
  state.registerSubCategory.status;
export const selectSuccessMessage = (state: any) =>
  state.registerSubCategory.success_message;
export const selectErrorMessage = (state: any) =>
  state.registerSubCategory.error_message;

export const SubCatRegisterSelectors = {
  selectName,
  selectCategory,
  selectDescription,
  selectValidity,
  selectImage,
  selectSubmitted,
  selectSubmissionStatus,
  selectSuccessMessage,
  selectErrorMessage,
};

export const SubCategoryRegisterAction = registerSubCategorySlice.actions;
export const subCategoryRegReducer = registerSubCategorySlice.reducer;
