import React, { FC, useEffect, useState } from "react";
import {
  Grid,
  Icon,
  Modal,
  Form,
  Input,
  Select,
  Button as SemanticButton,
} from "semantic-ui-react";
import CustomButton from "@laborhack/custom-button";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { CatServiceSelectors } from "../../../redux/store";
import { types } from "../../../service";
import Island from "../../../_components/Island";
import { Table } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_TRADESMEN } from "../../../graphql/query";
import { TRADES_MAN_LEVEL } from "../../tradesmen/constants";
import Button from "../../../_components/Button";
import { CREATE_INVITE } from "../graphql/mutation";
import { ListAllTradesmenSearchBy, ListAllTradesmenSearch } from "@/types";

const searchOptions = [
  {
    key: ListAllTradesmenSearchBy.LOCATION,
    text: "Location",
    value: ListAllTradesmenSearchBy.LOCATION,
  },
];

const AllTradesmen: FC = () => {
  const history = useHistory();
  const { selectCategories } = CatServiceSelectors;
  const allCategory = useSelector(selectCategories);
  const allcategories = Object.entries(allCategory);

  

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [level, setLevel] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [searchBy, setSearchBy] = React.useState<ListAllTradesmenSearchBy>(
    ListAllTradesmenSearchBy.LOCATION
  );
  const [search, setSearch] = React.useState<ListAllTradesmenSearch | undefined>(undefined);

  const { data: tradesmanData, loading, fetchMore, refetch } = useQuery(
    GET_ALL_TRADESMEN,
    {
      variables: {
        search: search,
      },
    }
  );

  useEffect(() => {
    fetchMore({
        variables: {
          search: search,
        },
    });
  }, [search, fetchMore])

  const allTradesman: types.ITradesmenData[] =
    tradesmanData?.listAllTradesmen.data;

  const columns = [
    {
      title: "Full Name",
      dataIndex: "full_name",
      width: 150,
      key: "1",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "2",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "3",
    },
    {
      title: "Category",
      dataIndex: "category",
      width: 150,
      key: "4",
    },
    {
      title: "Level",
      dataIndex: "level",
      width: 80,
      key: "5",
    },
    {
      title: "Location",
      dataIndex: "location",
      width: 150,
      key: "6",
    },
    {
      title: "Points",
      dataIndex: "points",
      width: 80,
      key: "7",
      render: (point: any, record: any) => (
        <div
          onClick={() => {
            history.push(`/tradesmen/user/${record.id}/performance`);
          }}
          className="tradesman__point"
          style={{ cursor: "pointer" }}
        >
          {point}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      width: 120,
      key: "8",
      render: (id: any) => (
        <div
          onClick={() => {
            history.push(`/tradesmen/user/${id}`);
          }}
          style={{ color: "#003952", cursor: "pointer" }}
        >
          <Icon name="arrow right" />
        </div>
      ),
    },
  ];

  const [createInvite, { loading: createInviteLoading }] = useMutation(
    CREATE_INVITE
  );

  const data = allTradesman?.map((data) => ({
    id: data.id,
    key: data.id,
    full_name: `${data.firstName} ${data.lastName}`,
    email: data.email,
    phoneNumber: data.phoneNumber,
    category: allcategories
      .filter(([id, rest]) => {
        return id === data.categoryId;
      })
      .map(([id, userCategory]) => {
        return userCategory.name;
      })[0],
    level: data.level,
    location: data.location.location,
    points: data.rewardPoints,
  }));

  const clearForm = () => {
    setEmail("");
    setLevel(0);
    setCategory("");
  };
  const clearMessage = () => {
    setError("");
    setSuccess("");
  };

  const handleCreateInviteLink = async () => {
    try {
      const { data } = await createInvite({
        variables: {
          input: {
            categoryId: category,
            level: level,
            email: email,
            userType: "PRO",
          },
        },
      });
      if (data) {
        setSuccess("Invite link sent successfully!");
        clearForm();
      }
    } catch (error) {
      setError((error as Error).message);
    }
  };

  const handleSearchDropdownChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) => {
    setSearchBy(data.value as ListAllTradesmenSearchBy);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) => {
    setSearchQuery(data.value);
  };

  const handleSearch = () => {
    setSearch({
      query: searchQuery,
      by: searchBy,
    });
  };

  const handleRemoveSearch = () => {
    setSearch(undefined);
    setSearchQuery("");
  };

  return (
    <div className="o-tradesman__table">
      <Grid columns="1">
        <Grid.Column width="16">
          <Island header="">
            <div className="o-tradesmen__header-wrapper">
              <div className="o-add-wrapper">
                <div className="create-button">
                  <Button
                    className="o-add-button"
                    onClick={() => history.push("/tradesmen/create")}
                  >
                    Create Pro
                  </Button>
                  <Button
                    className="o-add-button"
                    onClick={() => setOpen(true)}
                  >
                    Generate Link
                  </Button>
                </div>

              <div className="flex gap-8">
                <Input
                  type="text"
                  placeholder="Search by"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  action
                >
                  <input />
                  <Select
                    options={searchOptions}
                    value={searchBy}
                    onChange={handleSearchDropdownChange}
                  />
                  <SemanticButton
                    icon
                    className="!bg-primary-500 !text-white"
                    type="submit"
                    onClick={handleSearch}
                    loading={loading}
                  >
                    <Icon name="search" />
                  </SemanticButton>
                </Input>
               

                <div
                  className="refresh-btn"
                  onClick={() => {
                    refetch();
                  }}
                >
                  <p>Refresh </p>
                  <Icon name="refresh"></Icon>
                </div>
                </div>
              </div>
            </div>

            {search?.query && (
                  <div className="text-lg flex items-center gap-4 py-2">
                    <p className="m-0">
                      Search by{" "}
                      <i>
                        <strong>
                          {
                            searchOptions.find(
                              ({ value }) => search.by === value
                            )?.text
                          }
                        </strong>
                      </i>{" "}
                      results for <strong>"{search.query}"</strong>
                    </p>
                    <CustomButton
                      size="small"
                      type="link"
                      onClick={handleRemoveSearch}
                    >
                      Remove Search      
                    </CustomButton>
                  </div>
                )}

            <Table
              columns={columns}
              pagination={false}
              loading={loading}
              dataSource={data}
              scroll={{
                y: 400,
                scrollToFirstRowOnChange: false,
              }}
            ></Table>
          </Island>
        </Grid.Column>
      </Grid>

      <Modal size="small" open={open} onClose={() => setOpen(false)}>
        <Modal.Content>
          <div className="modalContent">
            <h2>Generate Registration Link</h2>
            <p>Enter Laborhack Pro details to generate a registration link </p>
            <div className="formWrapper">
              <Form>
                <Form.Input
                  required
                  id="form-firstname"
                  label="Email Address"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => {
                    const { value } = e.target;
                    setEmail(value);
                  }}
                  onFocus={() => clearMessage()}
                />
                <Form.Group>
                  <Form.Field width="10">
                    <Form.Input required label="Select Category">
                      <select
                        name="role"
                        id="role"
                        value={category}
                        onChange={(e) => {
                          const { value } = e.target;
                          setCategory(value);
                          clearMessage();
                        }}
                      >
                        <option value="">Select a category</option>
                        {allcategories?.map(([categoryId, category]) => {
                          return (
                            <option
                              data-testid="available-categories"
                              key={categoryId}
                              value={categoryId}
                            >
                              {category.name}
                            </option>
                          );
                        })}
                      </select>
                    </Form.Input>
                  </Form.Field>
                  <Form.Field width="6">
                    <Form.Input required label="Select Level">
                      <select
                        name="level"
                        id="level"
                        value={level}
                        onChange={(e) => {
                          clearMessage();
                          const { value } = e.target;
                          setLevel(parseInt(value));
                        }}
                      >
                        <option value={0}>Choose a level</option>
                        {TRADES_MAN_LEVEL.map((options) => {
                          return (
                            <option key={options} value={options}>
                              {options}
                            </option>
                          );
                        })}
                      </select>
                    </Form.Input>
                  </Form.Field>
                </Form.Group>
                <div className="successMessage">{success}</div>
                <div className="errorMessage">{error}</div>
                <div className="modalButton">
                  <Button
                    className="send-button"
                    disabled={email === "" || level === 0 || category === ""}
                    onClick={() => {
                      handleCreateInviteLink();
                    }}
                    loading={createInviteLoading}
                  >
                    Send
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default AllTradesmen;
