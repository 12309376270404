import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { components as LayoutComponents } from '../../../components/layout';
import { Tradesman } from '../../../types';

const { CustomLabel } = LayoutComponents;

const OtherAssignedTradesman: FC<{
  tradesman: Tradesman;
  destination: string;
  amountSent?: number;
}> = ({ tradesman, destination, amountSent }) => {
  const tradesmanName = `${tradesman.firstName} ${tradesman.lastName}`;

  return (
    <div className="o-singlePayment__othersAssignedItem">
      <div
        className="a-singlePayment__othersAssignedItemAvatar"
        style={{
          backgroundImage: `url(${tradesman.imageUrl})`,
        }}
      ></div>
      <div className="m-singlePayment__othersAssignedItemBody">
        <p>{tradesmanName}</p>
        <Icon name="send"></Icon>
        <p>{destination}</p>
        {amountSent && (
          <CustomLabel
            text={`- NGN ${amountSent}`}
            color={{
              r: 244,
              g: 105,
              b: 86,
            }}
          ></CustomLabel>
        )}
      </div>
    </div>
  );
};

export default OtherAssignedTradesman;
