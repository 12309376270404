import React, { FC } from 'react';
import Button from '@laborhack/custom-button';
import { Radio, RadioGroup, Select, Stack } from '@chakra-ui/react'
import { components as LayoutComponents } from '../../../components/layout';
import Island from '../../../_components/Island';
import FormStep from '../../../_components/FormStep';
import SelectClient from '../../../components/SelectClient';
import AddedRecruitmentsList from './components/AddedRecruitmentsList';
import { IAddedRecruitment, LocationData, RecruitmentType, ClientProfile, PaymentSchedule } from '../types';

import styles from './styles.module.scss';
import { useMutation } from '@apollo/client';
import { CREATE_RECRUITMENT } from './graphql/mutation';
import RequestFailed from '../../../_components/RequestFailed';
import { useHistory } from 'react-router-dom';
import { AddLocation } from './components/AddLocation';
import { ViewLocation } from './components/ViewLocation';

const { Trail } = LayoutComponents;

export interface CreateProps {}

export const Create: FC<CreateProps> = () => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = React.useState<string>();

  const [selectedClientId, setSelectedClientId] = React.useState<string>();

  const [selectedBusinessId, setSelectedBusinessId] = React.useState<string>();

  const [selectedRecruitmentType, setSelectedRecruitmentType] = React.useState<RecruitmentType>(RecruitmentType.CONTRACT);

  const [selectedClientProfile, setSelectedClientProfile] = React.useState<string>(ClientProfile.HOMEOWNER);

  const [paymentSchedule, setPaymentSchedule] = React.useState<PaymentSchedule>();

  const [location, setLocation] = React.useState<LocationData|undefined>();

  const [addedRecruitments, setAddedRecruitments] = React.useState<
    IAddedRecruitment[]
  >([]);

  const backToList = () => {
    history.push('/recruitment');
  };

  const [createRecruitment, { loading }] = useMutation(CREATE_RECRUITMENT, {
    onCompleted: (data) => {
      backToList();
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });

  const invalidSubmission = addedRecruitments.length === 0 || !selectedClientId || !paymentSchedule;

  const handleRemoveRecruitment = (id: string) => {
    setAddedRecruitments(
      addedRecruitments.filter((recruitment) => recruitment.id !== id)
    );
  };

  const handleAddRecruitment = (recruitment: IAddedRecruitment) => {
    setAddedRecruitments([...addedRecruitments, recruitment]);
  };

  const handleSubmit = () => {
    createRecruitment({
      variables: {
        data: {
          clientId: selectedClientId,
          ...(selectedBusinessId && { businessId: selectedBusinessId }),
          clientProfile: selectedClientProfile,
          recruitmentType: selectedRecruitmentType,
          paymentSchedule,
          location,
          requests: addedRecruitments.map((recruitment) => ({
            proCount: recruitment.noOfPros,
            proLevel: recruitment.proLevel,
            categoryId: recruitment.categoryId,
            ...(selectedRecruitmentType === RecruitmentType.CONTRACT && { contractDetails: {
              length: recruitment.contractLength,
              interval: recruitment.contractInterval,
            }}),
            proposedStartDate: recruitment.preferredStartDate,
          })),
        },
      },
    });
  };

  if (errorMessage) {
    return (
      <RequestFailed
        errorMessage={errorMessage}
        onRetry={() => setErrorMessage(undefined)}
      />
    );
  }

  return (
    <Island
      header={<Trail root="Recruitment" child="Create" />}
      text="Place new recruitment request"
    >
      <FormStep step={1} label="Select Client">
        <SelectClient
          onChange={({ clientId, businessId }) => {
            setSelectedClientId(clientId);
            businessId && setSelectedBusinessId(businessId);
          }}
        />
      </FormStep>
      <FormStep step={2} label="Select Profile">
        <RadioGroup
          onChange={(value) => setSelectedClientProfile(value)}
          defaultValue={ClientProfile.HOMEOWNER}
        >
          <Stack>
            <Radio value={ClientProfile.BUSINESS} isDisabled={!selectedBusinessId}>
              Business
            </Radio>
            <Radio value={ClientProfile.HOMEOWNER}>Homeowner</Radio>
          </Stack>
        </RadioGroup>
      </FormStep>
      <FormStep step={3} label="Select Recruitment Type">
        <RadioGroup
          value={selectedRecruitmentType}
          onChange={(value) => setSelectedRecruitmentType(value as RecruitmentType)}
          defaultValue={RecruitmentType.CONTRACT}
        >
          <Stack>
            <Radio value={RecruitmentType.FULL_TIME}>
              Full-Time
            </Radio>
            <Radio value={RecruitmentType.CONTRACT} defaultChecked>Contract</Radio>
          </Stack>
        </RadioGroup>
      </FormStep>
      <FormStep  step={4} label="Select Payment Schedule">
        <Select
          onChange={(e) => setPaymentSchedule(e.target.value as PaymentSchedule)}
        >
          <option value="">Select Payment Schedule</option>
          <option value={PaymentSchedule.WEEKLY}>Weekly</option>
          <option value={PaymentSchedule.MONTHLY}>Monthly</option>
          <option value={PaymentSchedule.YEARLY}>Yearly</option>
        </Select>
      </FormStep>
      <FormStep step={5} label="Add Location">
        {location ? (
          <ViewLocation
            location={location}
            removeLocation={() => setLocation(undefined)}
          />
        ) : (
          <AddLocation onAdd={(location) => {
            setLocation(location)} 
          }
          />
        )}
     </FormStep>
      <FormStep step={6} label="Add Requests">
        <AddedRecruitmentsList
          recruitments={addedRecruitments}
          onRemove={handleRemoveRecruitment}
          onAdd={handleAddRecruitment}
          selectedRecruitmentType={selectedRecruitmentType}
        />
      </FormStep>
      <div className={styles.actions}>
        <Button variant="basic" onClick={backToList}>
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={invalidSubmission}
          onClick={handleSubmit}
          variant="success"
        >
          Create
        </Button>
      </div>
    </Island>
  );
};
