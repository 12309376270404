import { ApolloError, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_TRADESMAN_ASSIGNMENT_BY_ID } from '../../../graphql/query';
import { AssignmentPayment } from '../types';

export interface OtherAssignmentPaymentsResponse {
  loading: boolean;
  error?: ApolloError;
  assignmentPayments?: AssignmentPayment[];
}

export interface SuccessfulOtherAssignmentPaymentsResponse
  extends OtherAssignmentPaymentsResponse {
  loading: false;
  assignmentPayments: AssignmentPayment[];
}

export const useOtherAssignmentPayments = (
  currentAssignmentPaymentId: string,
  assignmentId?: string
):
  | SuccessfulOtherAssignmentPaymentsResponse
  | OtherAssignmentPaymentsResponse => {
  const [getTradesmanAssignmentById, { loading, error, data }] = useLazyQuery(
    GET_TRADESMAN_ASSIGNMENT_BY_ID
  );

  useEffect(() => {
    if (assignmentId) {
      getTradesmanAssignmentById({
        variables: {
          assignmentId,
        },
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId]);

  let assignmentPayments: AssignmentPayment[] | undefined;

  if (data && data.getTradesmanAssignmentById) {
    assignmentPayments =
      data.getTradesmanAssignmentById.data.assignmentPayments;
  }

  const otherAssignmentPayments = assignmentPayments?.filter(
    ({ id }) => id !== currentAssignmentPaymentId
  );

  return {
    loading,
    error,
    assignmentPayments: otherAssignmentPayments,
  };
};
