import { gql, useMutation } from "@apollo/client";
import { ProRecruitmentContract } from "@/pages/recruitments/types";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalHeader, ModalOverlay, NumberInput, NumberInputField
} from "@chakra-ui/react";
import React, { useState } from "react"
import { useParams } from "react-router-dom";
import RequestFailed from "@/_components/RequestFailed";

interface Props {
  contractId: string;
  payrollId: string;
  unitMeasured: string;
  createWorksheetEntryIsOpen: boolean;
  refetchContractWorksheetsData: () => void;
  onClose: () => void;
}

const CREATE_CONTRACT_WORKSHEET = gql`
  mutation CreateContractWorksheet($expectedUnitAmount: Float!, $unitAmount: Float!, $payrollId: String!, $contractId: String!) {
  createContractWorksheet(expectedUnitAmount: $expectedUnitAmount, unitAmount: $unitAmount, payrollId: $payrollId, contractId: $contractId) {
    id
  }
}
`;

export const WorksheetEntryModal: React.FC<Props> = ({ contractId, payrollId, unitMeasured, createWorksheetEntryIsOpen, refetchContractWorksheetsData, onClose }) => {
  const [unitAmount, setUnitAmount] = useState(0);
  const [expectedUnitAmount, setExpectedUnitAmount] = useState(0);

  const [createContractWorksheet, createContractWorksheetResponse] = useMutation(CREATE_CONTRACT_WORKSHEET,
    {
      onCompleted: () => {
        onClose();
        setUnitAmount(0);
        setExpectedUnitAmount(0);
        refetchContractWorksheetsData();
      }
    }
  );

  const handlecreateContractWorksheet = (unitAmount: number, expectedUnitAmount: number) => {
    createContractWorksheet({
      variables: {
        expectedUnitAmount,
        unitAmount,
        contractId,
        payrollId
      }
    });
  }

  const handleReset = () => {
    createContractWorksheetResponse.reset();
  }

  const loading = createContractWorksheetResponse.loading;

  const error = createContractWorksheetResponse.error;
  if (error) {
    return (
      <RequestFailed
        errorMessage={
         error.message
        }
        onRetry={handleReset}
      />
    );
  }

  return (
    <Modal
      isOpen={createWorksheetEntryIsOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Work Sheet Entry</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel fontSize={"md"} color="primary.500">
              {`Amount of ${unitMeasured?.toLowerCase()}, done/worked`}
            </FormLabel>
            <NumberInput
              value={unitAmount}
            >
              <NumberInputField
                onChange={(e) => setUnitAmount(Number(e.target.value))}
              />
            </NumberInput>
            <FormHelperText>
              e.g "20" for 20 days or "200" for 200 blocks were laid
            </FormHelperText>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel fontSize={"md"} color="primary.500">
              {`Expected unit amount`}
            </FormLabel>
            <NumberInput
              value={expectedUnitAmount}
            >
              <NumberInputField
                onChange={(e) => setExpectedUnitAmount(Number(e.target.value))}
              />
            </NumberInput>
          </FormControl>
          <Button
            colorScheme="green"
            mb={4}
            isLoading={loading}
            isDisabled={!unitAmount || !expectedUnitAmount}
            onClick={() => handlecreateContractWorksheet(unitAmount, expectedUnitAmount)}
          >
            Confirm Worksheet Entry
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
