import moment from "moment";
import React, { FC } from "react";
import { SingleDatePicker } from "react-dates";
import Button from "@laborhack/custom-button";
import { useAuth } from "../../../firebase/hooks/useAuthHook";
import { forceActualDay, getPendingPayment } from "../../../helpers/helpers";
import { IInvoice } from "../../../types";
import RequestFailed from "../../../_components/RequestFailed";
import { gql, useMutation } from "@apollo/client";

const CONFIRM_OFFLINE_INVOICE_PAYMENT = gql`
  mutation ConfirmOfflineInvoicePayment(
    $invoiceId: String!
    $amount: Float!
    $paymentId: String!
    $transactionDate: DateTimeISO!
    $transactionReference: String!
    $bankName: String!
  ) {
    confirmOfflineInvoicePayment(
      invoiceId: $invoiceId
      amount: $amount
      paymentId: $paymentId
      transactionDate: $transactionDate
      transactionReference: $transactionReference
      bankName: $bankName
    ) {
      id
    }
  }
`;

export interface LogofflinePaymentProps {
  invoice: IInvoice;
  onClose: () => void;
}

export const LogOfflinePayment: FC<LogofflinePaymentProps> = ({
  invoice,
  onClose: handleClose,
}) => {
  const [focusedInput, setFocusedInput] = React.useState<boolean>(false);

  const [bankName, setBankName] = React.useState("");
  const [referenceNumber, setReferenceNumber] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [transactionDate, setTransactionDate] = React.useState<Date>();

  const [confirmOfflineInvoicePayment, { loading, error, reset }] = useMutation(
    CONFIRM_OFFLINE_INVOICE_PAYMENT,
    {
      refetchQueries: ["GetInvoice"],
      onCompleted: () => {
        handleClose();
      },
    }
  );

  const handleSubmit = async () => {
    confirmOfflineInvoicePayment({
      variables: {
        invoiceId: invoice.id,
        amount: parseFloat(amount),
        paymentId: getPendingPayment(invoice)?.id,
        transactionDate,
        transactionReference: referenceNumber,
        bankName,
      },
    });
  };

  if (error) {
    return (
      <RequestFailed errorMessage={error.message} onRetry={() => reset()} />
    );
  }

  return (
    <div className='p-8'>
      <h3 className='text-primary-500 font-bold'>Log Offline Payment</h3>
      <p className='text-primary-300'>
        Manually set the pending invoice payment
      </p>
      <div className='w-full'>
        <div className='flex flex-col my-8'>
          <div className='mb-4'>
            <label className='block !text-primary-300 text-sm font-semibold mb-2'>
              LaborHack&apos;s Bank Name
            </label>
            <input
              className='pl-4 border border-primary-150 border-solid rounded text-primary-500 h-10'
              type='text'
              name='bank'
              placeholder='Bank Name'
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
          </div>

          <div className='mb-4'>
            <label className='block !text-primary-300 text-sm font-semibold mb-2'>
              Reference Number
            </label>
            <input
              className='pl-4 border border-primary-150 border-solid rounded text-primary-500 h-10'
              type='text'
              name='reference'
              placeholder='Reference Number'
              value={referenceNumber}
              onChange={(e) => setReferenceNumber(e.target.value)}
            />
          </div>

          <div className='mb-4'>
            <label className='block !text-primary-300 text-sm font-semibold mb-2'>
              Amount
            </label>
            <input
              className='pl-4 border border-primary-150 border-solid rounded text-primary-500 h-10'
              type='text'
              name='amount'
              placeholder='Amount'
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <div className='mb-4'>
            <label className='block !text-primary-300 text-sm font-semibold mb-2'>
              Transaction Date
            </label>
            <SingleDatePicker
              id='transactionDate'
              date={transactionDate ? moment(transactionDate) : null}
              onDateChange={(date) => {
                date && setTransactionDate(forceActualDay(date.toDate()));
              }}
              focused={focusedInput}
              onFocusChange={({ focused }) => setFocusedInput(focused)}
              numberOfMonths={1}
              enableOutsideDays
              isOutsideRange={() => false}
            />
          </div>
        </div>
        <div className='flex w-full justify-end items-center'>
          <Button onClick={handleSubmit} loading={loading}>
            Log Payment
          </Button>
        </div>
      </div>
    </div>
  );
};
