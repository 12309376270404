import { gql, useMutation } from "@apollo/client";
import { CreateProRecruitmentContractInput, PaymentMethod, PaymentSchedule } from "../types";
import { Button, FormControl, FormHelperText, FormLabel, Input, InputGroup, InputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, NumberInput, NumberInputField, Select } from "@chakra-ui/react";
import RequestFailed from "@/_components/RequestFailed";
import { useState } from "react";
import DatePicker from "react-date-picker";
import { forceActualDay } from "@/helpers/helpers";

const ACTIVATE_PRO_RECRUITMENT_CONTRACT = gql`
  mutation Mutation($createProRecruitmentContractInput: CreateProRecruitmentContractInput!) {
    activateProRecruitmentContract(createProRecruitmentContractInput: $createProRecruitmentContractInput) {
      paymentMethod
      paymentSchedule
      id
    }
  }
`;

interface SetContractPaymentDetailsProps {
  onClose: () => void;
  isOpen: boolean;
  jobId: string;
  proId: string;
  refetchProRecruitmentContracts: () => void;
}
export const SetContractPaymentDetails: React.FC<SetContractPaymentDetailsProps> = ({
  isOpen,
  onClose,
  refetchProRecruitmentContracts,
  jobId,
  proId
}) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [unitMeasured, setUnitMeasured] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [startDate, setStartDate] = useState<Date>();

  const resetForm = () => {
    setPaymentMethod(undefined);
    setUnitMeasured("");
    setPaymentAmount(0);
  };

  const [activateProRecruitmentContract, activateProRecruitmentContractResponse] = useMutation(ACTIVATE_PRO_RECRUITMENT_CONTRACT, {
    onCompleted: () => {
      refetchProRecruitmentContracts();
      resetForm();
      onClose();
    }
  });

  const handleActivateProRecruitmentContract = (createProRecruitmentContractInput: CreateProRecruitmentContractInput ) => {
    activateProRecruitmentContract({
      variables: {
        createProRecruitmentContractInput
      },
    });
  };

  const handleReset = () => {
    activateProRecruitmentContractResponse.reset();
  }

  const {loading} = activateProRecruitmentContractResponse; 

  const error = activateProRecruitmentContractResponse.error;
  if (error) {
    return (
      <RequestFailed
        errorMessage={
         error.message
        }
        onRetry={handleReset}
      />
    );
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Contract Details for this Pro</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            < FormControl isRequired mb={4}>
              <FormLabel fontSize={"md"} color="primary.500">
                Payment Method
              </FormLabel>
              <Select
                onChange={(e) => setPaymentMethod(e.target.value as PaymentMethod)}
              >
                <option value="">Select Payment Method</option>
                <option value={PaymentMethod.FIXED_AMOUNT}>Fixed Amount</option>
                <option value={PaymentMethod.BY_OUTPUT}>By Output (e.g  no. of blocks laid)</option>
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel fontSize={"md"} color="primary.500">
                Unit Measured
              </FormLabel>
              <Input
                value={unitMeasured}
                onChange={(e) => setUnitMeasured(e.target.value)}
              >
              </Input>
              <FormHelperText>
                e.g "Hours", "Days", "Number of blocks laid"
              </FormHelperText>
              
            </FormControl>
            <FormControl isRequired mb={4}>
              <FormLabel fontSize={"md"} color="primary.500">
                Payment amount
              </FormLabel>
              <InputGroup>
                <InputLeftAddon children="₦" />
                <NumberInput
                  value={paymentAmount}
                >
                  <NumberInputField
                    roundedBottomRight={4}
                    roundedTopRight={4}
                    roundedBottomLeft={0}
                    roundedTopLeft={0}
                    onChange={(e) => setPaymentAmount(Number(e.target.value))}
                  />
                </NumberInput>
              </InputGroup>
              <FormHelperText>
                The fixed amount to be paid or the amount paid per output
              </FormHelperText>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel fontSize={"md"} color="primary.500">
                Start Date
              </FormLabel>
              <DatePicker
                value={startDate}
                clearIcon={null}
                onChange={(date: Date) => setStartDate(forceActualDay(date))} />
            </FormControl>
            <Button
              colorScheme="green"
              mb={4}
              isLoading={loading}
              isDisabled={!paymentMethod
                || !paymentAmount
                || !unitMeasured
                || !startDate}
              onClick={() => handleActivateProRecruitmentContract({
                paymentMethod,
                paymentAmount,
                proId,
                recruitmentJobId: jobId,
                unitMeasured,
                startDate
              })}
            >
              Confirm job activation
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}