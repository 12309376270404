import { useQuery } from '@apollo/client';

import { TransferStatus } from '../../../types';
import { LIST_REFUNDS } from '../graphql/query';
import { IRefund } from '../types';

const useRefundExists = (orderId: string | null) => {
  const listRefunds = useQuery(LIST_REFUNDS, {
    variables: {
      data: {
        order: orderId,
      },
    },
  });

  return !!listRefunds.data?.listRefundRequests.data.find(
    ({ transfer: { status } }: IRefund) => {
      return (
        status === TransferStatus.PENDING ||
        status === TransferStatus.AWAITING_APPROVAL
      );
    }
  );
};

export default useRefundExists;
