import { ColumnsType } from "antd/lib/table";
import React, { FC, useContext } from "react";
import {
  Pro,
  RecruitmentJobShortlistedPro,
  RecruitmentJobShortlistStatus,
} from "../../../../types";
import { components as LayoutComponents } from "../../../../components/layout";
import Pill from "../../../../_components/Pill";
import CustomButton from "@laborhack/custom-button";
import { gql, useMutation } from "@apollo/client";
import RequestFailed from "../../../../_components/RequestFailed";
import { Modal, Icon } from "semantic-ui-react";
import { ShortlistPros } from "./ShortlistPros";
import { FullTimeSalaryModal } from "./FullTimeSalaryModal";
import { RecruitmentContext } from "../context/recruitment.context";
import { RecruitmentType } from "../../types";
import { Flex } from "@chakra-ui/react";

const { CustomTable } = LayoutComponents;

export interface RecruitmentJobShortlistProps {
  job: any;
  matched: boolean | any;
  refetchShortlist: () => void;
  refetchJob: () => void;
}

const statusToPillClassName: {
  [Property in RecruitmentJobShortlistStatus]: string;
} = {
  PENDING: "!bg-yellow-100 !text-yellow-800",
  ACCEPTED: "!bg-green-100 !text-green-800",
  DECLINED: "!bg-red-100 !text-red-800",
};

export const ADD_PROS_TO_RECRUITMENT_SHORTLIST = gql`
  mutation AddProsToRecruitmentShortlist(
    $recruitmentId: String!
    $recruitmentRequestId: String!
    $proIds: [String!]!
  ) {
    addProsToRecruitmentShortlist(
      recruitmentId: $recruitmentId
      recruitmentRequestId: $recruitmentRequestId
      proIds: $proIds
    ) {
      id
      shortlistedPros {
        pro {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        status
      }
    }
  }
`;

export const ACCEPT_RECRUITMENT_SHORTLIST_PRO = gql`
  mutation AcceptRecruitmentShortlistPro(
    $shortlistId: String!
    $proId: String!
    $addToJob: Boolean
    $potentialMonthlyGrossSalary: Float
  ) {
    markRecruitmentShortlistedProAcceptance(
      shortlistId: $shortlistId
      proId: $proId
      addToJob: $addToJob
      potentialMonthlyGrossSalary: $potentialMonthlyGrossSalary
    ) {
      id
      shortlistedPros {
        pro {
          id
          firstName
          lastName
        }
        status
      }
    }
  }
`;

export const DECLINE_RECRUITMENT_SHORTLIST_PRO = gql`
  mutation DeclineRecruitmentShortlistPro(
    $shortlistId: String!
    $proId: String!
  ) {
    markRecruitmentShortlistedProDecline(
      shortlistId: $shortlistId
      proId: $proId
    ) {
      id
      shortlistedPros {
        pro {
          id
          firstName
          lastName
        }
        status
      }
    }
  }
`;

const UPDATE_FULL_TIME_PRO_SALARY = gql`
  mutation UpdateFullTimeProSalary(
    $shortlistId: String!
    $proId: String!
    $potentialMonthlyGrossSalary: Float!
  ) {
    updateFullTimeProSalary(
      shortlistId: $shortlistId
      proId: $proId
      potentialMonthlyGrossSalary: $potentialMonthlyGrossSalary
    ) {
      id
    }
  }
`;

export const RecruitmentJobShortlist: FC<RecruitmentJobShortlistProps> = ({
  job: { shortlist, pros, schedule, trade },
  refetchShortlist,
  refetchJob,
  matched,
}) => {
  const { recruitment } = useContext(RecruitmentContext);

  const [addProModalOpen, setAddProModalOpen] = React.useState(false);
  const [fullTimeSalaryModalOpen, setFullTimeSalaryModalOpen] = React.useState(
    false
  );
  const [isEditingSalary, setIsEditingSalary] = React.useState(false);
  const [fullTimePro, setFullTimePro] = React.useState<Pro>({} as Pro);

  const [
    addProsToRecruitmentShortlist,
    addProsToRecruitmentShortlistCall,
  ] = useMutation(ADD_PROS_TO_RECRUITMENT_SHORTLIST, {
    onCompleted: () => {
      setAddProModalOpen(false);
      refetchShortlist();
    },
  });

  const [
    acceptRecruitmentShortlistPro,
    {
      loading: acceptShortlistProLoading,
      error: acceptShortlistProError,
      reset: acceptShortlistProReset,
    },
  ] = useMutation(ACCEPT_RECRUITMENT_SHORTLIST_PRO, {
    onCompleted: () => {
      refetchJob();
    },
  });

  const [
    declineRecruitmentShortlistPro,
    {
      loading: declineShortlistProLoading,
      error: declineShortlistProError,
      reset: declineShortlistProReset,
    },
  ] = useMutation(DECLINE_RECRUITMENT_SHORTLIST_PRO, {
    onCompleted: () => {
      refetchJob();
    },
  });

  const [updateSalary, updateSalaryCall] = useMutation(
    UPDATE_FULL_TIME_PRO_SALARY,
    {
      onCompleted: () => {
        refetchShortlist();
      },
    }
  );

  const handleAcceptShortlistPro = (proId: string) => {
    acceptRecruitmentShortlistPro({
      variables: {
        shortlistId: shortlist?.id,
        proId,
        addToJob: !matched,
      },
    });
  };

  const handleDeclineShortlistPro = (proId: string) => {
    declineRecruitmentShortlistPro({
      variables: {
        shortlistId: shortlist?.id,
        proId,
      },
    });
  };

  const handleAcceptButtonClick = (pro: Pro) => {
    if (recruitment?.recruitmentType === RecruitmentType.FULL_TIME) {
      setFullTimeSalaryModalOpen(true);
      setFullTimePro(pro);
    } else {
      handleAcceptShortlistPro(pro.id);
    }
  };

  const columns: ColumnsType<RecruitmentJobShortlistedPro & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.pro.id.substring(0, 8)}</p>;
      },
      width: "10%",
      fixed: "left",
    },
    {
      title: "First Name",
      render: (text, record) => {
        return <p>{record.pro.firstName}</p>;
      },

      width: "15%",
    },
    {
      title: "Last Name",
      render: (text, record) => {
        return <p>{record.pro.lastName}</p>;
      },

      width: "15%",
    },
    {
      title: "Email",
      render: (text, record) => {
        return <p>{record.pro.email}</p>;
      },
      key: "email",
      width: "20%",
    },
    {
      title: "Phone Number",
      render: (text, record) => {
        return <p>{record.pro.phoneNumber}</p>;
      },
      key: "phoneNumber",
      width: "15%",
    },
    {
      title: "Status",
      render: (text, record) => {
        return (
          <Pill className={statusToPillClassName[record.status]}>
            {record.status}
          </Pill>
        );
      },
      width: "10%",
    },
    ...([
      recruitment?.recruitmentType === RecruitmentType.FULL_TIME
        ? {
            title: "Monthly Gross Salary",
            width: "15%",
            render: (text: any, record: RecruitmentJobShortlistedPro) => {
              return (
                <Flex justifyContent="space-between" alignContent="center">
                  <p>
                    {record.potentialMonthlyGrossSalary?.toLocaleString(
                      "en-NG",
                      {
                        style: "currency",
                        currency: "NGN",
                      }
                    ) || "N/A"}
                  </p>
                  {record.potentialMonthlyGrossSalary && (
                    <Icon
                      style={{ paddingTop: "3px" }}
                      className=""
                      name="edit"
                      onClick={() => {
                        setIsEditingSalary(true);
                        setFullTimeSalaryModalOpen(true);
                        setFullTimePro(record.pro);
                      }}
                    />
                  )}
                </Flex>
              );
            },
          }
        : [],
    ] as any),
    {
      title: "",
      render: (text, record) => {
        const isAssigned = !!pros?.find((pro: any) => pro.id === record.pro.id);

        return (
          <div className="flex gap-2">
            <CustomButton
              disabled={
                isAssigned ||
                record.status === RecruitmentJobShortlistStatus.DECLINED
              }
              data-testid="decline-shortlisted-pro"
              className="!bg-red-500"
              onClick={() => handleDeclineShortlistPro(record.pro.id)}
            >
              Decline
            </CustomButton>

            <CustomButton
              disabled={
                isAssigned ||
                record.status === RecruitmentJobShortlistStatus.ACCEPTED
              }
              data-testid="accept-shortlisted-pro"
              variant="success"
              onClick={() => handleAcceptButtonClick(record.pro)}
            >
              Accept
            </CustomButton>
          </div>
        );
      },
      width: "20%",
      fixed: "right",
    },
  ];

  const handleReset = () => {
    acceptShortlistProReset();
    declineShortlistProReset();
  };

  const error = acceptShortlistProError || declineShortlistProError ||updateSalaryCall.error

  if (error) {
    return (
      <RequestFailed
        errorMessage={
         error.message
        }
        onRetry={handleReset}
      />
    );
  }

  const handleAddPros = (data: {
    recruitmentId: string;
    requestId: string;
    proIds: string[];
  }) => {
    addProsToRecruitmentShortlist({
      variables: {
        recruitmentId: data.recruitmentId,
        recruitmentRequestId: data.requestId,
        proIds: [...data.proIds],
      },
    });
  };

  return (
    <>
      <Modal
        open={addProModalOpen}
        closeIcon
        onClose={() => setAddProModalOpen(false)}
        closeOnDimmerClick={false}
        style={{ width: "90%" }}
      >
        {addProsToRecruitmentShortlistCall.error ? (
          <RequestFailed
            errorMessage={addProsToRecruitmentShortlistCall.error.message}
            onRetry={addProsToRecruitmentShortlistCall.reset}
          />
        ) : (
          <ShortlistPros
            onSelect={(data) => handleAddPros(data)}
            exclusionShortlistId={shortlist?.id}
          />
        )}
      </Modal>

      <FullTimeSalaryModal
        pro={fullTimePro}
        isOpen={fullTimeSalaryModalOpen}
        onClose={() => {
          setFullTimeSalaryModalOpen(false);
        }}
        handleAcceptShortlistPro={(proId, monthlyGrossSalary) => {
          isEditingSalary ?
          updateSalary({
            variables: {
              shortlistId: shortlist?.id,
              proId,
              potentialMonthlyGrossSalary: monthlyGrossSalary,
            },
          }) :
         acceptRecruitmentShortlistPro({
            variables: {
              shortlistId: shortlist?.id,
              proId,
              addToJob: !matched,
              potentialMonthlyGrossSalary: monthlyGrossSalary,
            },
          });
          setFullTimeSalaryModalOpen(false);
        }}
      />

      <div className="mt-8">
        <h3 className="text-lg font-semibold text-primary-500 mb-2">
          Shortlisted Pros
        </h3>
        <div className="flex justify-end gap-2 my-2">
          <CustomButton
            className="mt-2"
            onClick={() => {
              addProsToRecruitmentShortlistCall.reset();
              setAddProModalOpen(true);
            }}
          >
            Add Pro
          </CustomButton>
        </div>
        <CustomTable
          loading={acceptShortlistProLoading || declineShortlistProLoading}
          onRow={(record: any, index: any) => {
            return {};
          }}
          columns={columns}
          data={
            shortlist?.shortlistedPros?.map((pro: any) => {
              return {
                ...pro,
                key: pro.pro.id,
              };
            }) || []
          }
        />
      </div>
    </>
  );
};
