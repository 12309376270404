import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import React from "react";
import { Icon } from "semantic-ui-react";
import CustomLabel from "../../../components/layout/components/CustomLabel";
import { JobProgress } from "../../../types";
import { AssignmentTableRow } from "../types";
import { jobsTableRenderer } from "./jobsTableRenderer";

export interface AssignmentsTableProps {
  assignments: AssignmentTableRow[];
  orderCode: string;
  selectable?: TableRowSelection<AssignmentTableRow>;
  moveTo: (path: string) => void;
}

export const assignmentsTableRenderer = ({
  assignments,
  orderCode,
  selectable,
  moveTo,
}: AssignmentsTableProps) => {
  const columns: ColumnsType<AssignmentTableRow> = [
    {
      title: "Category",
      dataIndex: "category",
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
      render: (text, record) => (
        <p className="a-orderDetail__tableText">{record.category.name}</p>
      ),
      width: "10%",
      sorter: (a, b) => {
        if (a.category.name > b.category.name) {
          return -1;
        }

        if (a.category.name < b.category.name) {
          return 1;
        }

        return 0;
      },
    },
    {
      title: "Tasks",
      dataIndex: "taskCount",
      render: (text, record) => (
        <p className="a-orderDetail__tableText">{record.taskCount}</p>
      ),
      width: "5%",
      sorter: (a, b) => a.taskCount - b.taskCount,
    },
    {
      title: "Location",
      dataIndex: "",
      width: "25%",
      sorter: (a, b) => {
        if (a.location > b.location) {
          return -1;
        }

        if (a.location < b.location) {
          return 1;
        }

        return 0;
      },
      render: (text, record) => (
        <p className="a-orderDetail__tableText">{record.location}</p>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      width: "18%",
      render: (text, record) => (
        <p className="a-orderDetail__tableText">{`${record.tradesman.firstName} ${record.tradesman.lastName}`}</p>
      ),
    },
    {
      title: "Quoted",
      dataIndex: "quoted",
      width: "4%",
      render: (text, record) => {
        return record.quoted ? (
          <Icon color="green" size="large" name="check circle"></Icon>
        ) : (
          <Icon color="red" size="large" name="remove circle"></Icon>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "18%",
      render: (text, record) => {
        let progressIndex = {
          color: {
            r: 0,
            g: 0,
            b: 0,
          },
          text: "Not Started",
        };

        switch (record.progress) {
          case JobProgress.NOT_STARTED:
            progressIndex.color.r = 244;
            progressIndex.color.g = 105;
            progressIndex.color.b = 96;
            progressIndex.text = "Not Started";
            break;

          case JobProgress.PRE_INSPECTION:
            progressIndex.color.r = 199;
            progressIndex.color.g = 156;
            progressIndex.color.b = 26;
            progressIndex.text = "Pre Inspection";
            break;

          case JobProgress.FINAL_QUOTE:
            progressIndex.color.r = 199;
            progressIndex.color.g = 156;
            progressIndex.color.b = 26;
            progressIndex.text = "Final Quote";
            break;

          case JobProgress.MATERIALS_PURCHASED:
            progressIndex.color.r = 199;
            progressIndex.color.g = 156;
            progressIndex.color.b = 26;
            progressIndex.text = "Materials Purchased";
            break;

          case JobProgress.CLIENT_INSPECTION:
            progressIndex.color.r = 199;
            progressIndex.color.g = 156;
            progressIndex.color.b = 26;
            progressIndex.text = "Client Inspection";
            break;

          case JobProgress.COMPLETED:
            progressIndex.color.r = 86;
            progressIndex.color.g = 189;
            progressIndex.color.b = 102;
            progressIndex.text = "Completed";
            break;
          default:
            progressIndex.color.r = 244;
            progressIndex.color.g = 105;
            progressIndex.color.b = 96;
            progressIndex.text = "Not Started";
            break;
        }

        return (
          <CustomLabel text={progressIndex.text} color={progressIndex.color} />
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      width: "20%",
      render: (text: any, record) => {
        return (
          <button
            className="a-orderDetail__tableButton"
            onClick={() => {
              moveTo(`/orders/${orderCode}/assignments/${record.id}`);
            }}
          >
            View Assignment
          </button>
        );
      },
    },
  ];

  return (
    <div className="o-orderDetail__tableWrapper">
      <Table
        className="o-orderDetail__table"
        indentSize={15}
        {...(selectable && {
          rowSelection: selectable,
        })}
        expandable={{
          expandedRowRender: (record) => jobsTableRenderer(record, moveTo),
        }}
        pagination={false}
        columns={columns}
        dataSource={assignments.map((data) => {
          return {
            key: data.id,
            ...data,
          };
        })}
      />
    </div>
  );
};
