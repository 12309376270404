import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient } from "@apollo/client";
import {
  GET_ORDERS_QUERY,
  GET_SINGLE_ORDER_QUERY,
} from "../components/orders/graphql/query";
import { IOrderInitialState } from "../components/orders/types";

const initialState: IOrderInitialState = {
  status: "idle",
  pageInfo: {
    hasNextPage: true,
    startCursor: "",
    endCursor: "",
  },
  edges: [],
  search: null,
  sorts: [
    {
      type: "ORDER_CODE",
      ascending: false,
    },
  ],
  order: null,
};

export const getSingleOrder = createAsyncThunk(
  "orders/singleOrderFetched",
  async ({
    client,
    orderCode,
  }: {
    client: ApolloClient<object>;
    orderCode: string;
  }) => {
    try {
      const { data } = await client.query({
        query: GET_SINGLE_ORDER_QUERY,
        variables: {
          code: orderCode,
        },
      });

      return data.getOrder;
    } catch (error) {
      throw error;
    }
  }
);

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (
    {
      client,
    }: {
      client: ApolloClient<object>;
    },
    { getState }
  ) => {
    const {
      orders: {
        filters,
        search,
        sorts,
        pageInfo: { hasNextPage, endCursor },
      },
    }: any = getState();

    if (!hasNextPage) {
      throw new Error("No More Data");
    }

    const { data } = await client
      .query({
        query: GET_ORDERS_QUERY,
        variables: {
          data: {
            first: 10,
            ...(endCursor && { after: endCursor }),
            ...(filters?.length && { filters }),
            ...(sorts?.length && { sorts }),
            ...(search && { search }),
          },
        },
      })
      .catch((e) => {
        throw e;
      });

    if (!data.listOrders.status) {
      throw new Error("Fetch Failed");
    }

    return data.listOrders.data;
  }
);

const OrderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    addedSearchQuery: (state, action) => {
      state.search = {
        type: action.payload.type,
        query: action.payload.query,
      };
      state.pageInfo = {
        hasNextPage: true,
        startCursor: "",
        endCursor: "",
      };
      state.edges = [];
    },
    addedSort: (state, action) => {
      state.sorts = action.payload;
      state.pageInfo = {
        hasNextPage: true,
        startCursor: "",
        endCursor: "",
      };
      state.edges = [];
    },
  },
  extraReducers: {
    [getOrders.fulfilled.type]: (state, action) => {
      state.edges.push(...action.payload.edges);
      state.pageInfo = action.payload.pageInfo;
      state.status = "idle";
    },
    [getOrders.pending.type]: (state) => {
      state.status = "pending";
    },
    [getOrders.rejected.type]: (state, action) => {
      state.status = "error";
    },
    [getSingleOrder.fulfilled.type]: (state, action) => {
      state.order = action.payload;
      state.status = "idle";
    },
    [getSingleOrder.pending.type]: (state) => {
      state.status = "pending";
    },
    [getSingleOrder.rejected.type]: (state, action) => {
      state.status = "error";
    },
  },
});

export const OrderReducer = OrderSlice.reducer;
export const OrderActions = {
  getOrders,
  getSingleOrder,
  ...OrderSlice.actions,
};
export const OrderSelectors = {
  selectEdges: (state: { orders: IOrderInitialState }) => {
    return state.orders.edges;
  },
  selectRequestStatus: (state: { orders: IOrderInitialState }) => {
    return state.orders.status;
  },
  selectPageInfo: (state: { orders: IOrderInitialState }) => {
    return state.orders.pageInfo;
  },
  selectSearchQuery: (state: { orders: IOrderInitialState }) => {
    return state.orders.search;
  },
  selectSorts: (state: { orders: IOrderInitialState }) => {
    return state.orders.sorts;
  },
  selectOrder: (state: { orders: IOrderInitialState }) => {
    return state.orders.order;
  },
};
