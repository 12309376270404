import React, { FC } from 'react';
import Container from '@laborhack/container';
import AddressSelector from '@laborhack/address-selector';

import styles from './styles.module.scss';
import { IServiceTask } from '../../../../../service/types';
import { Location, ObjectOf, WithoutId } from '../../../../../types';
import CartItem from '../CartItem';
import { truncateString } from '../../../../../helpers/helpers';

export interface CartProps {
  tasks: ObjectOf<IServiceTask>;
  defaultItems: ObjectOf<number>;
  customItems: ObjectOf<{
    description: string;
    category: string;
    count: number;
  }>;
  locations: ObjectOf<Location>;
  onAddCustomLocation: (payload: WithoutId<Location>) => void;
  onChange: (id: string, count: number, isCustom: boolean) => void;
  onSelect: (id: string) => void;
  selectedLocation?: string;
}

export const Cart: FC<CartProps> = ({
  tasks,
  defaultItems,
  customItems,
  onChange,
  locations,
  onAddCustomLocation,
  onSelect,
  selectedLocation,
}) => {
  const customItemsArray = Object.entries(customItems).filter(
    ([, { count }]) => count
  );
  const defaultItemsArray = Object.entries(defaultItems).filter(
    ([, count]) => count
  );

  return (
    <Container noPadding>
      <div className={styles.address}>
        <div className={styles.header}>
          <h3>Task Address</h3>
        </div>
        <AddressSelector
          onChange={onSelect}
          selected={selectedLocation}
          createNewAddressMessage="Add Location"
          locations={locations}
          onAddCustomLocation={onAddCustomLocation}
        />
      </div>
      <div className={styles.summary}>
        <div className={styles.header}>
          <h3>Summary</h3>
          <p>
            You have {customItemsArray.length + defaultItemsArray.length} items
            in your cart
          </p>
        </div>

        <div className={styles.cartItems}>
          {customItemsArray.map(([id, { description, count }]) => {
            return (
              <CartItem
                key={id}
                isCustom={true}
                name={truncateString(description, 25)}
                count={count}
                onCountChange={(newCount: number) =>
                  onChange(id, newCount, true)
                }
              ></CartItem>
            );
          })}
          {defaultItemsArray.map(([id, count]) => {
            return (
              <CartItem
                key={id}
                name={truncateString(tasks[id].name, 25)}
                count={count}
                imageUrl={tasks[id].imageUrl}
                onCountChange={(newCount: number) =>
                  onChange(id, newCount, false)
                }
              ></CartItem>
            );
          })}
        </div>
      </div>
    </Container>
  );
};
