import { LIST_SUBSCRIPTION_REQUEST_PAYOUTS } from "@/features/subscription-request-payouts/constants";
import { SubscriptionRequestsPayoutsTable } from "@/features/subscription-request-payouts/table";
import { SubscriptionRequestCallPayout } from "@/features/subscription-request-payouts/types";
import { TransferStatus } from "@/types";
import Island from "@/_components/Island";
import Loading from "@/_components/Loading";
import RequestFailed from "@/_components/RequestFailed";
import { useQuery } from "@apollo/client";

export default function Page() {
	const { data, loading, error } = useQuery<{
		subscriptionRequestCallPayouts: SubscriptionRequestCallPayout[];
	}>(LIST_SUBSCRIPTION_REQUEST_PAYOUTS, {
		variables: {
			transferStatus: TransferStatus.AWAITING_APPROVAL,
		},
	});

	if (loading) return <Loading />;

	if (error) return <RequestFailed />;

	return (
		<Island>
			<div className="mb-4">
				<h2 className="text-primary-500 text-xl font-bold m-0">
					Subscription Scheduled Request Payouts
				</h2>
				<p className="m-0 text-primary-300">
					Shows a list of all scheduled request payouts awaiting approval
				</p>
			</div>

			<div>
				<SubscriptionRequestsPayoutsTable
					isConfirmations={true}
					data={data?.subscriptionRequestCallPayouts}
				/>
			</div>
		</Island>
	);
}
