import { gql, useQuery } from "@apollo/client";
import { GET_CONTRACT_PAYOUTS } from "../../../features/contract-payouts/constants";
import { ContractPayoutTable } from "../../../features/contract-payouts/table";
import { ContractPayout } from "../../../features/contract-payouts/types";
import { TransferStatus } from "../../../types";
import Island from "../../../_components/Island";
import Loading from "../../../_components/Loading";
import RequestFailed from "../../../_components/RequestFailed";

export default function Page() {
  const { data, loading, error } = useQuery<{
    contractPayouts: ContractPayout[];
  }>(GET_CONTRACT_PAYOUTS, {
    variables: {
      transferStatus: [TransferStatus.AWAITING_APPROVAL],
    },
  });

  if (loading) return <Loading />;

  if (error) return <RequestFailed />;

  return (
    <Island>
      <div className='mb-4'>
        <h2 className='text-primary-500 text-xl font-bold m-0'>
          Contract Payout Confirmations
        </h2>
        <p className='m-0 text-primary-300'>
          Shows a list of contract payout requests
        </p>
      </div>

      <div>
        <ContractPayoutTable
          data={data?.contractPayouts}
          isConfirmations
          isLoading={loading}
        />
      </div>
    </Island>
  );
}
