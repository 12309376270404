export enum LeadSource {
	Herconomy = "Herconomy",
	Social_Media = "Social_Media",
	Youtube = "Youtube",
	Radio = "Radio",
	TV = "TV",
	Voucher = "Voucher",
	Event_or_Exhibition = "Event_or_Exhibition",
	Friend_or_Referral = "Friend_or_Referral",
	Marketing_Flyer = "Marketing_Flyer",
	Online_Search = "Online_Search",
	Newspaper = "Newspaper",
	Sales_Team = "Sales_Team",
	Influencers = "Influencers",
	Other = "Other",
}
