import { gql } from "@apollo/client";

export const LIST_SUBSCRIPTION_REQUEST_PAYOUTS = gql`
	query SubscriptionRequestCallPayouts($transferStatus: [TransferStatus!]) {
		subscriptionRequestCallPayouts(transferStatus: $transferStatus) {
			amount
			createdAt
			id
			pro {
				firstName
				lastName
			}
			period {
				month
				year
			}
			transfer {
				status
			}
		}
	}
`;

export const GET_SUBSCRIPTION_REQUEST_PAYOUT = gql`
	query SubscriptionRequestCallPayoutDetails(
		$subscriptionRequestCallPayoutId: String!
	) {
		subscriptionRequestCallPayoutDetails(
			subscriptionRequestCallPayoutId: $subscriptionRequestCallPayoutId
		) {
			amount
			createdAt
			id
			override {
				reason
				amount
			}
			period {
				year
				month
			}
			subscriptionRequestCalls {
				id
				dateOfAssignment
				proPaymentAmount
				expectedProPaymentAmount
				subscriptionRequest {
					plan {
						package {
							name
							category {
								name
							}
							basePrice {
								breakdown {
									vendorFee
								}
							}
							maximumCalls
						}
					}
					id
				}
			}
			pro {
				firstName
				lastName
				email
				phoneNumber
				userId
			}
			transfer {
				status
				transferCode
			}
		}
	}
`;

export const SET_SUBSCRIPTION_PRO_PAYMENT_AMOUNT = gql`
	mutation SetProPaymentAmountForSubscriptionRequestCall(
		$paymentAmount: Float!
		$subscriptionRequestCallId: String!
	) {
		setProPaymentAmountForSubscriptionRequestCall(
			paymentAmount: $paymentAmount
			subscriptionRequestCallId: $subscriptionRequestCallId
		) {
			proPaymentAmount
			id
		}
	}
`;

export const SYNC_SUBSCRIPTION_REQUEST_CALL_PAYOUT_AMOUNT = gql`
	mutation SyncSubscriptionRequestCallPayoutAmount($payoutId: String!) {
		syncSubscriptionRequestCallPayoutAmount(payoutId: $payoutId) {
			amount
			id
			subscriptionRequestCalls {
				id
				proPaymentAmount
				expectedProPaymentAmount
			}
		}
	}
`;
