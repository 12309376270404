import { gql } from "@apollo/client";

export const GET_PRO_CERTIFICATION = gql`
  query GetProCertification($id: String!) {
    proCertification(id: $id) {
      id
      uploadedAt
      pro {
        id
        firstName
        lastName
        imageUrl
        associatedLaborHackEmail
        identityVerification {
          identificationNumber
          type
          status
          dateOfBirth
          photo
          returnedPhoto
          identificationDocument
        }
        trades {
          id
          name
        }
      }
      certification {
        requirements {
          id
          certification {
            id
          }
          name
          description
          passingScore
          order
          lmsId
        }
      }
      results {
        id
        certificationRequirement {
          id
          type
          lmsId
        }
        score
        attemptId
        submittedAt
      }
    }
  }
`;
