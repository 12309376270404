import { Checkbox } from "antd";
import { Dispatch, FC, SetStateAction } from "react";
import { Filter } from "../../../types";

export interface AddFilterProps {
  filters: Filter[];
  setFilters: Dispatch<SetStateAction<Filter[]>>;
}

export const AddFilter: FC<AddFilterProps> = ({ filters, setFilters }) => {
  console.log(filters);

  const handleAddFilter = (filter: Filter) => {
    setFilters((prev) => [...prev, filter]);
  };

  const handleRemoveFilter = (filter: Filter) => {
    setFilters((prev) =>
      prev.filter((f) => f.class + f.name !== filter.class + filter.name)
    );
  };

  const handleAvailableChange = (checked?: boolean) => {
    if (checked) {
      handleAddFilter({
        class: "availability",
        value: true,
        name: "Available",
      });
    } else {
      handleRemoveFilter({
        class: "availability",
        value: true,
        name: "Available",
      });
    }
  };

  const handleHasConflictsChange = (checked?: boolean) => {
    if (checked) {
      handleAddFilter({
        class: "availability",
        value: false,
        name: "Has Conflicts",
      });
    } else {
      handleRemoveFilter({
        class: "availability",
        value: false,
        name: "Has Conflicts",
      });
    }
  };

  const handleLevelChange = (filter: Filter, checked?: boolean) => {
    return checked ? handleAddFilter(filter) : handleRemoveFilter(filter);
  };

  return (
    <div className='p-8 w-96'>
      <h3 className='text-primary-500 font-semibold'>Filters</h3>
      <div className='mt-8'>
        <div className='mt-4'>
          <h5 className='text-primary-500 text-lg font-semibold'>
            Availability
          </h5>
          <div className='grid grid-cols-1 gap-y-2'>
            <Checkbox
              checked={filters.some((filter) => {
                return filter.class === "availability" && filter.value;
              })}
              onChange={({ target: { checked } }) =>
                handleAvailableChange(checked)
              }
            >
              Available
            </Checkbox>
            <Checkbox
              className='!ml-0'
              checked={filters.some((filter) => {
                return filter.class === "availability" && !filter.value;
              })}
              onChange={({ target: { checked } }) =>
                handleHasConflictsChange(checked)
              }
            >
              Has Conflicts
            </Checkbox>
          </div>
        </div>
        <div className='mt-4'>
          <h5 className='text-primary-500 text-lg font-semibold'>Pro Level</h5>
          <div className='grid grid-cols-1 gap-y-2'>
            <Checkbox
              checked={filters.some((filter) => {
                return filter.class === "level" && filter.value === "L1";
              })}
              onChange={({ target: { checked } }) =>
                handleLevelChange(
                  {
                    class: "level",
                    value: "L1",
                    name: "Level 1",
                  },
                  checked
                )
              }
            >
              Level 1
            </Checkbox>
            <Checkbox
              className='!ml-0'
              checked={filters.some((filter) => {
                return filter.class === "level" && filter.value === "L2";
              })}
              onChange={({ target: { checked } }) =>
                handleLevelChange(
                  {
                    class: "level",
                    value: "L2",
                    name: "Level 2",
                  },
                  checked
                )
              }
            >
              Level 2
            </Checkbox>
            <Checkbox
              className='!ml-0'
              checked={filters.some((filter) => {
                return filter.class === "level" && filter.value === "L3";
              })}
              onChange={({ target: { checked } }) =>
                handleLevelChange(
                  {
                    class: "level",
                    value: "L3",
                    name: "Level 3",
                  },
                  checked
                )
              }
            >
              Level 3
            </Checkbox>
            <Checkbox
              className='!ml-0'
              checked={filters.some((filter) => {
                return filter.class === "level" && filter.value === "L4";
              })}
              onChange={({ target: { checked } }) =>
                handleLevelChange(
                  {
                    class: "level",
                    value: "L4",
                    name: "Level 4",
                  },
                  checked
                )
              }
            >
              Level 4
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};
