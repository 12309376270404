import React, { FC, useState } from "react";
import { Icon } from "semantic-ui-react";

import { Job, Status, TradesmanAssignment } from "../../../types";
import Table, { ColumnsType } from "antd/lib/table";
import {
  calculateLaborHackFee,
  calculateMaterialsAndLabor,
  calculateTotal,
  formatAsNaira,
} from "../../../helpers/helpers";
import { notification } from "antd";

const RefundAssignment: FC<{
  assignment: TradesmanAssignment;
  categories: any;
  selectedRowKeys: string[];
  handleRowSelection: (jobId: string, selected: boolean) => void;
  refundData: {
    [x: string]: { jobId: string; assignmentId: string; refundAmount?: number };
  };
  defaultRefundData: {
    [x: string]: { jobId: string; assignmentId: string; refundAmount: number };
  };
  handleRefundAmountChange: (jobId: string, amount?: number) => void;
}> = ({
  assignment,
  categories,
  refundData,
  selectedRowKeys,
  defaultRefundData,
  handleRefundAmountChange,
  handleRowSelection,
}) => {
  const [open, setOpen] = useState(true);

  const tradesman = assignment.tradesman;
  const location = assignment.jobs[0].contact.location;

  const columns: ColumnsType<Job> = [
    {
      title: "Task Name",
      width: "25%",
      render: (text, record) => {
        return (
          <p className="a-refundAssignment__tableText">
            {record.custom?.name || record.task?.name}
          </p>
        );
      },
    },
    {
      title: "Materials",
      width: "15%",
      render: (text, record) => {
        return (
          <p className="a-refundAssignment__tableText">
            {formatAsNaira(
              record.invoiceDetails?.materials?.reduce(
                (accumulator, { unitPrice, quantity }) => {
                  return accumulator + unitPrice * quantity;
                },
                0
              ) || 0
            )}
          </p>
        );
      },
    },
    {
      title: "Labor",
      width: "15%",
      render: (text, record) => {
        return (
          <p className="a-refundAssignment__tableText">
            {formatAsNaira(record.invoiceDetails?.labor || 0)}
          </p>
        );
      },
    },
    {
      title: "Total Cost (with VAT)",
      width: "15%",
      render: (text, record) => {
        if (!record.invoiceDetails) {
          return 0;
        }

        const { materials, labor } = calculateMaterialsAndLabor([record]);

        const laborHackFee = calculateLaborHackFee(materials, labor);

        const total = calculateTotal(materials, laborHackFee);

        return (
          <p className="a-refundAssignment__tableText">
            {formatAsNaira(total)}
          </p>
        );
      },
    },
    {
      title: "Set Refund Amount",
      width: "30%",
      render: (text, record) => {
        return (
          <div className="m-refundAssignment__tableInputContainer">
            <input
              className="a-refundAssignment__tableInput"
              type="number"
              value={refundData[record.id]?.refundAmount}
              defaultValue={defaultRefundData[record.id].refundAmount}
              onBlur={(e) => {
                const value = Number(e.target.value);

                if (value < 1) {
                  handleRefundAmountChange(
                    record.id,
                    defaultRefundData[record.id].refundAmount
                  );
                }
              }}
              onChange={(e) => {
                const value = Number(e.target.value);

                if (value > defaultRefundData[record.id].refundAmount) {
                  handleRefundAmountChange(
                    record.id,
                    defaultRefundData[record.id].refundAmount
                  );
                  notification.warn({
                    message: "Max value exceeded",
                    duration: 1.5,
                  });
                } else {
                  handleRefundAmountChange(
                    record.id,
                    e.target.value ? Number(e.target.value) : undefined
                  );
                }
              }}
              max={defaultRefundData[record.id].refundAmount}
            />
            <button
              className="a-refundAssignment__tableButton"
              disabled={
                !refundData[record.id] ||
                defaultRefundData[record.id].refundAmount ===
                  refundData[record.id].refundAmount
              }
              onClick={() => {
                handleRefundAmountChange(
                  record.id,
                  defaultRefundData[record.id].refundAmount
                );
              }}
            >
              Max
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div key={assignment.id} className="o-refundAssignment__container">
      <div
        className="m-refundAssignment__header"
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <Icon name={open ? "chevron down" : "chevron right"} />
        <h4>{`${tradesman.firstName} ${tradesman.lastName}'s ${
          categories[tradesman.categoryId].name
        } work at ${location.area}, ${location.location}`}</h4>
      </div>

      {open && (
        <div className="m-refundAssignment__table">
          <Table
            columns={columns}
            rowSelection={{
              selectedRowKeys,
              onSelect: (record, selected) => {
                handleRowSelection(record.id, selected);
              },
            }}
            dataSource={assignment.jobs
              .filter(({ status }) => status === Status.ACTIVE)
              .map((job) => {
                return {
                  key: job.id,
                  ...job,
                };
              })}
            pagination={false}
          />
        </div>
      )}
    </div>
  );
};

export default RefundAssignment;
