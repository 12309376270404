import { SubscriptionDetails } from "../../features/subscriptions/SubscriptionDetails";
import { SubscriptionList } from "../../features/subscriptions/SubscriptionList";
import { Route, Switch, useRouteMatch } from "react-router-dom";

export const Subscriptions = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={SubscriptionList} />
      <Route path={`${path}/:id`} component={SubscriptionDetails} />
    </Switch>
  );
};
