import React, { useState } from "react";
import { Table } from "antd";
import { formatAsNaira } from "@/helpers/helpers";
import { ProRecruitmentContract } from "../../types";
import { Button} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";


interface ProRecruitmentContractsProps {
  proRecruitmentContracts: ProRecruitmentContract[];
}

export const ProRecruitmentContracts: React.FC<ProRecruitmentContractsProps> = ({
  proRecruitmentContracts,
}) => { 
  const history = useHistory();

  const columns = [
    {
      title: "First Name",
      dataIndex: ["pro", "firstName"],
      key: "firstName",
      render: (firstName: string) => <p className="text-sm text-[#003952] font-semibold">{ firstName }</p>
    },
    {
      title: "Last Name",
      dataIndex: ["pro", "lastName"],
      key: "lastName",
      render: (lastName: string) => <p className="text-sm text-[#003952] font-semibold">{ lastName }</p>
    },
    {
      title: "Category",
      dataIndex: ["category", "name"],
      key: "category",
      render: (category: string) => <p className="text-sm text-[#003952] font-semibold">{ category }</p>
    },
    {
      title: "Payment Schedule",
      dataIndex: "paymentSchedule",
      key: "paymentSchedule",
      render: (paymentSchedule: string) => <p className="text-sm text-[#003952] font-semibold">{ paymentSchedule }</p>
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (paymentMethod: string) => <p className="text-sm text-[#003952] font-semibold">{ paymentMethod.replaceAll('_', ' ') }</p>
    },
    /**{
      title: "Payment Amount",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      render: (paymentAmount: number) => <p className="text-sm text-[#003952] font-semibold">{ formatAsNaira(paymentAmount) }</p>
    },
    {
      title: "Unit Measured",
      dataIndex: "unitMeasured",
      key: "unitMeasured",
      render: (unitMeasured: string | undefined) =>
        <p className="text-sm text-[#003952] font-semibold">{ unitMeasured || "N/A" }</p>
    },
    */
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: Date) =>
        <p className="text-sm text-[#003952] font-semibold">{ new Date(startDate).toISOString().split('T')[0]}</p>
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate: Date) =>
        <p className="text-sm text-[#003952] font-semibold">{ new Date(endDate).toISOString().split('T')[0] }</p>
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => <p className="text-sm text-[#003952] font-semibold">{ status }</p>
    },
    {
      key: "action",
      render: (record: ProRecruitmentContract) =>
        (
          <Button
            colorScheme="brand"
            fontSize="small"
            onClick={() => history.push(`/recruitment/pro-recruitment-contract/${record.id}`)}>View Contract</Button>
        )
    },
  ];

  return (
    <>
      <Table
        dataSource={proRecruitmentContracts}
        columns={columns}
        scroll={{
          x: 1600,
          scrollToFirstRowOnChange: false,
        }}
        rowKey="id"
        pagination={false}
      />
    </>
  );
};

export default ProRecruitmentContracts;
