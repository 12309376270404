import React, { PropsWithChildren } from "react";
import { ProsContext } from "../context/ProsContext";
import {
  ListProsFilters,
  ListProsSearch,
  ListProsSort,
  ListProsSortBy,
} from "../types";

export const ProsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [search, setSearch] = React.useState<ListProsSearch>();
  const [filters, setFilters] = React.useState<ListProsFilters>();
  const [sort, setSort] = React.useState<ListProsSort>({
    by: ListProsSortBy.CREATED_AT,
    ascending: false,
  });

  return (
    <ProsContext.Provider
      value={{
        search,
        setSearch,
        filters,
        setFilters,
        sort,
        setSort,
      }}
    >
      {children}
    </ProsContext.Provider>
  );
};
