import { Referrer } from "@/features/referrer-payouts/types";
import { LIST_OF_REFERRERS } from "@/features/referrers/constants";
import { ReferrersTable } from "@/features/referrers/table";
import Island from "@/_components/Island";
import RequestFailed from "@/_components/RequestFailed";
import { useQuery } from "@apollo/client";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function ReferrersList() {
	const { data, loading, error } = useQuery<{
		listOfReferrers: Referrer[];
	}>(LIST_OF_REFERRERS);

	if (error) return <RequestFailed />;

	const referrers = data?.listOfReferrers;

	return (
		<Island>
			<div className="mb-4 flex items-end justify-between">
				<div>
					<h2 className="text-primary-500 text-xl font-bold m-0">Referrers</h2>
					<p className="m-0 text-primary-300">Shows a list of referrers</p>
				</div>
				<div>
					<Link to="/referrers/create-referrer">
						<Button colorScheme="primary" size="md">
							Create Referrer
						</Button>
					</Link>
				</div>
			</div>

			<div>
				<ReferrersTable data={referrers} isLoading={loading} />
			</div>
		</Island>
	);
}
