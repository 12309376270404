import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import Input from "@laborhack/input";
import Button from "@laborhack/custom-button";
import { CatServiceSelectors } from "../../../../redux/store";
import Select from "../../../../_components/Select";

import styles from "./styles.module.scss";
import DatePicker from "react-date-picker";
import { Icon } from "semantic-ui-react";
import { ContractInterval, IAddedRecruitment, RecruitmentType } from "../../types";
import { nanoid } from "@reduxjs/toolkit";
import { startOfDay } from "date-fns";
import { forceActualDay } from "../../../../helpers/helpers";
import { NumberInput, NumberInputField } from "@chakra-ui/react";

export interface AddRecruitmentProps {
  onSubmit: (recruitment: IAddedRecruitment) => void;
  selectedRecruitmentType?: string;
}

const { selectCategories } = CatServiceSelectors;

export const AddRecruitment: FC<AddRecruitmentProps> = ({ onSubmit, selectedRecruitmentType }) => {
  const categories = useSelector(selectCategories);
  const [selectedCategory, setSelectedCategory] = React.useState<string>();
  const [noOfPros, setNoOfPros] = React.useState<number>();
  const [proLevel, setProLevel] = React.useState<number>(1);
  const [
    contractInterval,
    setContractInterval,
  ] = React.useState<ContractInterval>("MONTHS");
  const [contractLength, setContractLength] = React.useState<number>();
  const [preferredStartDate, setPreferredStartDate] = React.useState<Date>(
    forceActualDay(startOfDay(new Date()))
  );

  const categoryOptions = useMemo(() => {
    return Object.entries(categories).map(([id, category]) => ({
      key: id,
      value: id,
      text: category.name,
    }));
  }, [categories]);

  const invalidSubmission =
    !selectedCategory || !noOfPros ||  (selectedRecruitmentType === RecruitmentType.CONTRACT && !contractLength) || !preferredStartDate;

  const handleSubmit = () => {
    if (invalidSubmission) {
      return;
    }

    const values: IAddedRecruitment = {
      id: nanoid(4),
      categoryId: selectedCategory,
      noOfPros,
      proLevel,
      contractInterval,
      contractLength,
      preferredStartDate,
    } as IAddedRecruitment;

    onSubmit(values);
  };

  return (
    <div className={styles.wrapper}>
      <h3>Add Request</h3>
      <div className={styles.form}>
        <div className={styles.field}>
          <label>Choose Category</label>
          <Select
            value={selectedCategory}
            options={categoryOptions}
            onChange={(e, { value }) => setSelectedCategory(value as string)}
          />
        </div>
        <div className={styles.field}>
          <label>No of Pros</label>
          <NumberInput
            value={noOfPros}
          >
            <NumberInputField
              onChange={(e) => setNoOfPros(Number(e.target.value))}
            />
          </NumberInput>
        </div>
        <div className={styles.field}>
          <label>Pro Level</label>
          <Select
            value={proLevel}
            options={[
              {
                key: "1",
                value: 1,
                text: "1",
              },
              {
                key: "2",
                value: 2,
                text: "2",
              },
              {
                key: "3",
                value: 3,
                text: "3",
              },
            ]}
            onChange={(e, { value }) => setProLevel(value as number)}
          />
        </div>
       {selectedRecruitmentType ===  RecruitmentType.CONTRACT && <div className={styles.field}>
          <label>Contract Length</label>
          <div className={styles.contract}>
            <NumberInput
              value={contractLength}
            >
              <NumberInputField
                onChange={(e) => setContractLength(Number(e.target.value))}
              />
            </NumberInput>
            <Select
              value={contractInterval}
              options={[
                {
                  key: "Monthly",
                  value: "MONTHS",
                  text: "Months",
                },
                {
                  key: "Weekly",
                  value: "WEEKS",
                  text: "Weeks",
                },
                {
                  key: "Daily",
                  value: "DAYS",
                  text: "Days",
                },
              ]}
              onChange={(e, { value }) =>
                setContractInterval(value as ContractInterval)
              }
            />
          </div>
        </div>}
        <div className={styles.field}>
          <label>Proposed Start Date</label>
          <DatePicker
            calendarIcon={
              <Icon
                name='calendar alternate outline'
                style={{
                  margin: "0",
                  fontSize: "16px",
                }}
              />
            }
            className={styles.datePicker}
            minDate={new Date()}
            clearIcon={null}
            onChange={(value: any) => {
              setPreferredStartDate(forceActualDay(new Date(value)));
            }}
            value={preferredStartDate}
          />
        </div>

        <div style={{ marginTop: "2rem" }}>
          <Button onClick={handleSubmit} disabled={invalidSubmission}>
            Add Request
          </Button>
        </div>
      </div>
    </div>
  );
};
