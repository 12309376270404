import React, { FC } from "react";

const CustomTextArea: FC<{
  label: string;
  value: string;
  maxLength?: number;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
}> = ({
  label,
  value,
  required,
  disabled,
  onChange,
  placeholder,
  maxLength,
}) => {
  return (
    <div className="m-customInput__container">
      <label className="a-customInput__label" htmlFor="">
        {label}
        {required ? (
          <span className="a-customInput__labelRequired">*</span>
        ) : (
          ""
        )}
      </label>
      <textarea
        className="a-customInput__input -textArea"
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        maxLength={maxLength}
      />
    </div>
  );
};

export default CustomTextArea;
