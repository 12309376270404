import React, { FC } from "react";
import Button from "@laborhack/custom-button";
import { CertificationRequirement, ProCertificationResult } from "../../types";
import { Icon } from "semantic-ui-react";
import { AddScore } from "./AddScore";
import clsx from "clsx";
import { ViewAttemptsModal } from "./ViewAttempts";

export interface RequirementsProps {
  requirements: CertificationRequirement[];
  results: ProCertificationResult[];
}

export const Requirements: FC<RequirementsProps> = ({
  requirements,
  results,
}) => {
  const [
    selectedRequirement,
    setSelectedRequirement,
  ] = React.useState<CertificationRequirement>(requirements[0]);

  const [isAddScoreOpen, setIsAddScoreOpen] = React.useState(false);
  const [isViewAttempsModalOpen, setIsViewAttemptsOpen] = React.useState(false);
  const [certificationAttempts, setCertificationAttempts] = React.useState<ProCertificationResult[]>([]);
  const [selectedCertificationName, setSelectedCertificationName] = React.useState<string>("");

  const handleClose = () => {
    setIsAddScoreOpen(false);
  };

  const handleAddScore = (requirement: CertificationRequirement) => {
    setSelectedRequirement(requirement);
    setIsAddScoreOpen(true);
  };

  const handleViewAttemptsModalClose = () => {
    setIsViewAttemptsOpen(false)
  }

  const handleViewAttempts = (results: ProCertificationResult[], requirement: CertificationRequirement) => {
    const certificationAttempts = results.filter((result => {
      return result.certificationRequirement.id === requirement.id
    }));
    setCertificationAttempts(certificationAttempts)
    setSelectedCertificationName(requirement.name)
    setIsViewAttemptsOpen(true)
  }

  return (
    <>
      <AddScore
        requirement={selectedRequirement}
        isOpen={isAddScoreOpen}
        onClose={handleClose}
      />
      <ViewAttemptsModal
        isOpen={isViewAttempsModalOpen}
        onClose={handleViewAttemptsModalClose}
        certificationAttempts={certificationAttempts}
        certificationName={selectedCertificationName}
      />
      <div className='flex flex-col gap-8'>
        {requirements
          .slice()
          .sort((a, b) => a.order - b.order)
          .map((requirement, index) => {
            const result = results.filter(
              (result) => result.certificationRequirement.id === requirement.id
            ).sort((a, b) => b.score - a.score)[0];

            const resultExists = !!result;

            const hasPassed =
              result && result.score >= requirement.passingScore;

            return (
              <div key={requirement.name+index} className='grid grid-cols-[32px_1fr]'>
                <div className='self-center'>
                  {!resultExists ? (
                    <Icon
                      name='circle outline'
                      size='large'
                      className='text-primary-200 outline-none'
                    />
                  ) : hasPassed ? (
                    <Icon name='check circle' size='large' color='green' />
                  ) : (
                    <Icon name='remove circle' size='large' color='red' />
                  )}
                </div>
                <div className='flex flex-col'>
                  <div className='flex gap-2 items-center'>
                    <div className='flex gap-1'>
                      <h4 className='text-lg font-semibold text-primary-500 m-0 p-0'>
                        {requirement.name}
                      </h4>
                    </div>

                    {resultExists &&
                      <p
                        className={clsx(
                          "text-xl font-semibold",
                          hasPassed ? "text-success" : "text-red-500"
                        )}
                      >
                        {result!.score}%
                      </p>
                    }
                    <Button
                      type='subtle'
                      onClick={() => handleAddScore(requirement)}
                    >
                      Add Score
                    </Button>
                    <Button
                      type='subtle'
                      onClick={() => handleViewAttempts(results, requirement)}
                    >
                      View All Attempts
                    </Button>

                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

