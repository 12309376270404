import {
  createSlice,
  createAsyncThunk,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import { IAddTask } from "./../components/tasks/types";

const initialState: IAddTask = {
  name: "",
  description: "",
  categoryId: "",
  imageUrl: "",
  subCategoryId: "",
  estimatedCost: 0,
  success_message: "",
  error_message: "",
  status: "idle",
  submitted: false,
  valid: false,
};

export const submitTaskForm = createAsyncThunk(
  "task/addTask",
  async (
    data: { mutation: any },
    {
      dispatch,
      getState,
    }: { dispatch: ThunkDispatch<any, any, AnyAction>; getState: any }
  ) => {
    const { mutation } = data;
    const { addTask } = getState();
    try {
      const { data } = await mutation({
        variables: {
          input: {
            name: addTask.name,
            category: addTask.categoryId,
            subCategory: addTask.subCategoryId,
            description: addTask.description,
            imageUrl: addTask.imageUrl,
            estimatedCost: addTask.estimatedCost,
          },
        },
      });
      if (data) {
        dispatch(setSuccessMessage("Created Successfully"));
      }
    } catch (error) {
      dispatch(setErrorMessage("An error occur"));
    }
  }
);

export const registerTaskSlice = createSlice({
  name: "addTask",
  initialState,
  reducers: {
    setDName: (state, action) => {
      state.name = action.payload;
    },
    setDDescription: (state, action) => {
      state.description = action.payload;
    },
    setDCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    setDImageUrl: (state, action) => {
      state.imageUrl = action.payload;
    },
    setDSubCategoryId: (state, action) => {
      state.subCategoryId = action.payload;
    },
    setDEstimatedCost: (state, action) => {
      state.estimatedCost = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.success_message = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error_message = action.payload;
    },
    setDStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: {
    [submitTaskForm.pending.type]: (state, action) => {
      state.status = "pending";
    },
    [submitTaskForm.fulfilled.type]: (state, action) => {
      state.status = "idle";
    },
  },
});

export const {
  setDName,
  setDDescription,
  setDCategoryId,
  setDImageUrl,
  setDSubCategoryId,
  setDEstimatedCost,
  setSuccessMessage,
  setErrorMessage,
  setDStatus,
} = registerTaskSlice.actions;

export const selectSubmissionStatus = (state: any) => state.addTask.status;
export const selectSuccessMessage = (state: any) =>
  state.addTask.success_message;
export const selectErrorMessage = (state: any) => state.addTask.error_message;

export const TaskRegAction = registerTaskSlice.actions;
export const TaskRegReducer = registerTaskSlice.reducer;
export const TaskRegSelectors = {
  selectSubmissionStatus,
  selectSuccessMessage,
  selectErrorMessage,
};
