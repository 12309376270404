import React, { FC } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { Icon } from "semantic-ui-react";

export type CustomButtonVariants = "success" | "negative" | "basic";

export type CustomButtonType = "inverted" | "outline" | "link";

export interface ButtonProps {
  fullWidth?: boolean;
  loading?: boolean;
  type?: CustomButtonType;
  variant?: CustomButtonVariants;
  disabled?: boolean;
  className?: string;
  "data-testid"?: string;
  onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({
  fullWidth,
  variant,
  onClick,
  type,
  disabled,
  loading,
  "data-testid": dataTestId,
  className,
  children,
}) => {
  const classNames = clsx(
    className,
    styles.cartButton,
    fullWidth && styles.fullWidth,
    variant && styles[variant],
    type && styles[type],
    loading && styles.loading
  );

  return (
    <button
      data-testid={dataTestId}
      disabled={disabled || loading}
      onClick={onClick}
      className={classNames}
    >
      {loading ? <Icon name="spinner" loading /> : children}
    </button>
  );
};
