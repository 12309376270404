import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  calculateMaterialsAndLabor,
  invoiceHasAtLeastOnePayment,
} from "../../../helpers/helpers";
import { Location, Tradesman } from "../../../types";
import { GET_ASSIGNMENT_PAYMENT } from "../graphql/query";
import { AssignmentPayment, AssignmentPaymentTypes } from "../types";

export interface UseTransferRequestResponse {
  error?: string;
  loading: boolean;
  data?: {
    assignmentPayment: AssignmentPayment;
    tradesman?: Tradesman;
    othersAssigned?: {
      tradesman: Tradesman;
      destination: Location;
      amount?: number;
    }[];
    balance: number;
  };
}

const handleTransportation = (assignmentPayment: AssignmentPayment) => {
  // check if the order has been paid for
  const assignment = assignmentPayment.assignment;

  if (assignment.inspectionPayment?.status !== "SUCCESS") {
    throw new Error(
      "This transfer request does not have a paid inspection fee on its order"
    );
  }

  return {
    balance: assignment.inspectionPayment.amount,
    allocations: [],
  };
};

const handleMaterials = (assignmentPayment: AssignmentPayment) => {
  if (
    !assignmentPayment.assignment.invoice ||
    !invoiceHasAtLeastOnePayment(assignmentPayment.assignment.invoice)
  ) {
    throw new Error("Client has not paid for this assignment yet");
  }

  const { materials } = calculateMaterialsAndLabor(
    assignmentPayment.assignment.jobs
  );

  return {
    balance: materials,
    allocations: [],
  };
};

const handleLabor = (assignmentPayment: AssignmentPayment) => {
  if (
    !assignmentPayment.assignment.invoice ||
    !invoiceHasAtLeastOnePayment(assignmentPayment.assignment.invoice)
  ) {
    throw new Error("Client has not paid for this assignment yet");
  }

  const { labor } = calculateMaterialsAndLabor(
    assignmentPayment.assignment.jobs
  );

  return {
    balance: labor,
    allocations: [],
  };
};

const useTransferRequest = (
  assignmentPaymentId: string
): UseTransferRequestResponse => {
  const [
    assignmentPayment,
    setAssignmentPayment,
  ] = useState<AssignmentPayment>();

  const [getAssignmentPayment, getAssignmentPaymentResponse] = useLazyQuery<{
    assignmentPayment: AssignmentPayment;
  }>(GET_ASSIGNMENT_PAYMENT);

  useEffect(() => {
    getAssignmentPayment({
      variables: {
        id: assignmentPaymentId,
      },
    });
    // eslint-disable-next-line
  }, [assignmentPaymentId]);

  useEffect(() => {
    if (getAssignmentPaymentResponse.data) {
      setAssignmentPayment(
        getAssignmentPaymentResponse.data?.assignmentPayment
      );
    } // eslint-disable-next-line
  }, [getAssignmentPaymentResponse.data]);

  if (getAssignmentPaymentResponse.error) {
    return {
      error: "Failed to fetch",
      loading: false,
    };
  }

  if (assignmentPayment) {
    const tradesman = assignmentPayment.tradesman;

    const type = assignmentPayment.type;

    let error;
    let result;

    try {
      if (type === AssignmentPaymentTypes.TRANSPORTATION) {
        result = handleTransportation(assignmentPayment);
      } else if (type === AssignmentPaymentTypes.MATERIALS) {
        result = handleMaterials(assignmentPayment);
      } else {
        result = handleLabor(assignmentPayment);
      }
    } catch (e: any) {
      error = e.message;
      throw e;
    }

    return {
      error,
      loading: false,
      data: {
        assignmentPayment,
        tradesman,
        othersAssigned: [],
        balance: result.balance,
      },
    };
  }

  return {
    loading: true,
  };
};

export default useTransferRequest;
