import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";
import clsx from "clsx";
import { useState } from "react";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { formatAsNaira, NairaInputHelpers } from "../../helpers/helpers";
import {
  Job,
  CreateOrderHandlingQuotationItem,
  CreateOrderHandlingQuotationItemMaterial,
} from "../../types";
import { JobQuoteMaterialEntry } from "./job-quote-material-entry";

const { format, parse } = NairaInputHelpers;

interface JobQuoteEntryProps {
  item?: CreateOrderHandlingQuotationItem;
  onChange: (item: CreateOrderHandlingQuotationItem) => void;
  job: Job;
  showErrors?: boolean;
  readonly?: boolean;
}

export const JobQuoteEntry = ({
  item,
  onChange,
  job,
  showErrors,
  readonly,
}: JobQuoteEntryProps) => {
  const [laborEnabled, setLaborEnabled] = useState(!!item?.labor);

  const updateItemMaterials = (
    materials: CreateOrderHandlingQuotationItemMaterial[]
  ) => {
    onChange({
      ...item,
      jobId: job.id,
      materials,
    });
  };

  const updateItemLabor = (labor: number) => {
    onChange({
      ...item,
      jobId: job.id,
      labor,
    });
  };

  const addMaterial = () => {
    const newMaterials = [];

    if (item?.materials) {
      newMaterials.push(...item.materials);
    }

    newMaterials.push({
      id: nanoid(),
      name: "",
      quantity: 1,
      unitPrice: 1,
    });

    onChange({
      ...item,
      jobId: job.id,
      materials: newMaterials,
    });
  };

  const removeMaterial = (materialId: string) => {
    const newMaterials =
      item?.materials?.filter((m) => m.id !== materialId) || [];

    onChange({
      ...item,
      jobId: job.id,
      materials: newMaterials,
    });
  };

  const handleRemoveLabor = () => {
    onChange({
      ...item,
      jobId: job.id,
      labor: undefined,
    });

    setLaborEnabled(false);
  };

  const name = job.custom?.name || job.task?.name;

  const description = job.custom?.description || job.task?.description;

  const materialTotal =
    item?.materials?.reduce(
      (acc, material) => acc + material.quantity * material.unitPrice,
      0
    ) || 0;

  const laborTotal = item?.labor || 0;

  const total = materialTotal + laborTotal;

  const hasMaterials = !!item?.materials?.length;
  const hasLabor = item?.labor !== undefined;

  return (
    <div className='grid grid-cols-[3fr,5fr,2fr] gap-4 p-4'>
      <div className='flex flex-col gap-2'>
        <h5 className='text-sm font-medium'>{name}</h5>
        <p className='text-sm text-primary-300'>{description}</p>
      </div>
      <div className='flex flex-col items-start gap-4'>
        {item?.materials?.map((material) => (
          <JobQuoteMaterialEntry
            key={material.id}
            material={material}
            onChange={(newMaterial) => {
              const update =
                item?.materials?.filter((m) => m.id !== newMaterial.id) || [];

              update.push(newMaterial);
              updateItemMaterials(update);
            }}
            onRemove={() => {
              removeMaterial(material.id);
            }}
            showErrors={showErrors}
            readonly={readonly}
          />
        ))}

        {!readonly && (
          <div className='flex gap-2'>
            <Button
              leftIcon={<FaPlusCircle />}
              rounded='full'
              colorScheme='primary'
              variant='ghost'
              onClick={addMaterial}
            >
              {!!item?.materials?.length
                ? "Add Another Material"
                : "Add Material"}
            </Button>
            <Button
              leftIcon={<FaPlusCircle />}
              rounded='full'
              colorScheme='primary'
              variant='ghost'
              onClick={() => setLaborEnabled(true)}
              isDisabled={laborEnabled}
            >
              Add Labor
            </Button>
          </div>
        )}

        {laborEnabled && (
          <div className='flex justify-end items-end'>
            <FormControl
              isInvalid={showErrors && (!item?.labor || item.labor < 1)}
              className='w-min'
            >
              <FormLabel className='!text-xs uppercase tracking-wide font-medium'>
                Labor
              </FormLabel>
              <NumberInput
                onChange={(valueString: string) =>
                  updateItemLabor(Number(parse(valueString)))
                }
                value={format(String(item?.labor || 0))}
                min={0}
                isReadOnly={readonly}
              >
                <NumberInputField
                  className={clsx(readonly && "!border-0 !px-0")}
                />
                {!readonly && (
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                )}
              </NumberInput>
              {showErrors && <FormErrorMessage></FormErrorMessage>}
            </FormControl>
            {!readonly && (
              <IconButton
                colorScheme='red'
                variant='ghost'
                aria-label='Delete'
                icon={<FaTrashAlt />}
                onClick={handleRemoveLabor}
              />
            )}
          </div>
        )}
        {showErrors && !hasMaterials && !hasLabor && (
          <p className='text-red-500'>
            At least one material or labor fee must be declared
          </p>
        )}
      </div>

      <div className='p-4 text-right'>{formatAsNaira(total || 0)}</div>
    </div>
  );
};
