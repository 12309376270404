import React, { FC, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';

import styles from './styles.module.scss';

export interface CustomInputProps {
  value: string;
  textLabel?: string;
  validate?: { check: (value: string) => boolean; message: string };
  asyncValidate?: {
    check: (value: string) => Promise<boolean>;
    message: string;
  };
  onChange: (value: string, isValid: boolean) => void;
  placeholder?: string;
  width?: any;
  required?: boolean;
  [key: string]: any;
}

const CustomInput: FC<CustomInputProps> = ({
  value,
  validate,
  asyncValidate,
  onChange,
  textLabel,
  width,
  required,
  ...rest
}) => {
  const [fieldState, setFieldState] = useState<{
    status: 'loading' | 'error' | 'success' | 'idle';
    message?: string;
  }>({
    status: 'idle',
  });

  const statusIcons: {
    [key: string]: any;
  } = {
    error: {
      'data-testid': 'error-icon',
      name: 'remove',
      color: 'red',
    },
    success: {
      'data-testid': 'success-icon',
      name: 'check',
      color: 'green',
    },
  };

  return (
    <Form.Field
      required={!!required}
      {...(width && {
        width,
      })}
      className={styles.customInputField}
      error={
        fieldState.message
          ? {
              content: fieldState.message,
              pointing: 'above',
            }
          : undefined
      }
    >
      {textLabel && <label>{textLabel}</label>}
      <Input
        icon={statusIcons[fieldState.status]}
        readOnly={fieldState.status === 'loading'}
        {...rest}
        loading={fieldState.status === 'loading'}
        value={value}
        onChange={(e) => {
          if (fieldState.status === 'loading') return;

          const value = e.target.value.trimStart();
          const isValid = validate ? validate.check(value) : true;

          if (isValid && !asyncValidate) {
            setFieldState({
              status: 'success',
            });
          } else if (!isValid) {
            setFieldState({
              status: 'error',
              message: validate?.message,
            });
          } else {
            setFieldState({
              status: 'idle',
            });
          }

          /**
           * if there is no async validation, then return with the current status of the validity
           * but if there is an async validation we keep the validity as false till that is done
           */
          onChange(value, !asyncValidate ? isValid : false);
        }}
        onFocus={() => {
          setFieldState((state) => {
            if (state.status !== 'error') {
              return state;
            }

            return {
              status: 'idle',
            };
          });
        }}
        onBlur={() => {
          const isValid = validate ? validate.check(value) : true;

          if (!isValid) {
            setFieldState({
              status: 'error',
              message: validate?.message,
            });

            return;
          }

          if (!asyncValidate) {
            return;
          }

          setFieldState({
            status: 'loading',
          });

          asyncValidate
            .check(value)
            .then((valid) => {
              if (!valid) {
                setFieldState({
                  status: 'error',
                  message: asyncValidate.message,
                });
                return;
              }

              setFieldState({
                status: 'success',
              });

              onChange(value, true);
            })
            .catch((e) => {
              setFieldState({
                status: 'error',
                message: e.message,
              });
            });
        }}
      ></Input>
    </Form.Field>
  );
};

export default CustomInput;
