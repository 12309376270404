import React, { FC, useEffect, useState } from "react";
import {
  DashboardOutlined,
  BookOutlined,
  SettingOutlined,
  UserOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import { SideNavigationItem } from "./SideNavigationItem";
import {
  RiToolsLine,
  RiAdminLine,
  RiWalletLine,
  RiCheckDoubleLine,
  RiBuilding2Line,
} from "react-icons/ri";
import { FaFileInvoice, FaHandshake } from "react-icons/fa";

import { GrChapterAdd, GrDocumentUser } from "react-icons/gr";
import { IoIosCash } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { firebase } from "../../../firebase";
import produce from "immer";
import { isAuthorized } from "../../../helpers/helpers";
import { Role } from "../../../types";
import { allRoles } from "../../../constants";

const sideNavigationLinks: {
	name: string;
	path: string;
	roles: {
		[Property in Role]?: boolean;
	};
	subMenu?: {
		name: string;
		path: string;
		icon: any;
		roles: {
			[Property in Role]?: boolean;
		};
	}[];
	icon: any;
}[][] = [
	[
		{
			name: "Overview",
			path: "/",
			icon: <DashboardOutlined />,
			roles: {
				...allRoles,
			},
		},
		{
			name: "Marketplace",
			path: "/marketplace-requests",
			icon: <FaHandshake />,
			roles: {
				[Role.ROOT]: true,
				[Role.PROJECTMANAGER]: true,
				[Role.CUSTOMERSERVICE]: true,
			},
		},
		{
			name: "Recruitment",
			path: "/recruitment",
			icon: <GrDocumentUser />,
			roles: {
				[Role.ROOT]: true,
				[Role.PROJECTMANAGER]: true,
				[Role.CUSTOMERSERVICE]: true,
			},
		},
		{
			name: "Contract Payouts",
			path: "/contract-payouts",
			roles: {
				[Role.ROOT]: true,
				[Role.FINANCE]: true,
			},
			subMenu: [
				{
					name: "Requests",
					path: "/contract-payouts/requests",
					icon: <IoIosCash />,
					roles: {
						[Role.ROOT]: true,
						[Role.FINANCE]: true,
					},
				},
				{
					name: "Confirmations",
					path: "/contract-payouts/confirmations",
					icon: <RiCheckDoubleLine />,
					roles: {
						[Role.ROOT]: true,
						[Role.FINANCE]: true,
					},
				},
			],
			icon: <RiWalletLine />,
		},
		{
			name: "Referrer Payouts",
			path: "/referrer-payouts",
			roles: {
				[Role.ROOT]: true,
				[Role.FINANCE]: true,
			},
			subMenu: [
				{
					name: "Requests",
					path: "/referrer-payouts/requests",
					icon: <IoIosCash />,
					roles: {
						[Role.ROOT]: true,
						[Role.FINANCE]: true,
					},
				},
				{
					name: "Confirmations",
					path: "/referrer-payouts/confirmations",
					icon: <RiCheckDoubleLine />,
					roles: {
						[Role.ROOT]: true,
						[Role.FINANCE]: true,
					},
				},
			],

			icon: <RiWalletLine />,
		},

		{
			name: "Orders",
			path: "/orders",
			roles: {
				[Role.ROOT]: true,
				[Role.PROJECTMANAGER]: true,
				[Role.CUSTOMERSERVICE]: true,
			},
			subMenu: [
				{
					name: "All",
					path: "/orders/all",
					icon: <BookOutlined />,
					roles: {
						[Role.ROOT]: true,
						[Role.PROJECTMANAGER]: true,
						[Role.CUSTOMERSERVICE]: true,
					},
				},
				{
					name: "New",
					path: "/orders/new",
					icon: <GrChapterAdd />,
					roles: {
						[Role.ROOT]: true,
						[Role.PROJECTMANAGER]: true,
						[Role.CUSTOMERSERVICE]: true,
					},
				},
				{
					name: "Assigned",
					path: "/orders/assigned",
					icon: <BookOutlined />,
					roles: {
						[Role.ROOT]: true,
						[Role.PROJECTMANAGER]: true,
						[Role.CUSTOMERSERVICE]: true,
					},
				},
				{
					name: "Completed",
					path: "/orders/completed",
					icon: <BookOutlined />,
					roles: {
						[Role.ROOT]: true,
						[Role.PROJECTMANAGER]: true,
						[Role.CUSTOMERSERVICE]: true,
					},
				},
				{
					name: "Pending",
					path: "/orders/pending",
					icon: <BookOutlined />,
					roles: {
						[Role.ROOT]: true,
						[Role.PROJECTMANAGER]: true,
						[Role.CUSTOMERSERVICE]: true,
					},
				},
			],
			icon: <BookOutlined />,
		},
		{
			name: "Subscriptions",
			path: "/subscriptions",
			roles: {
				[Role.CUSTOMERSERVICE]: true,
			},
			icon: <BookOutlined />,
		},
		{
			name: "Subscription Payouts",
			path: "/subscription-request-payouts",
			roles: {
				[Role.ROOT]: true,
				[Role.FINANCE]: true,
			},
			subMenu: [
				{
					name: "Requests",
					path: "/subscription-request-payouts/requests",
					icon: <IoIosCash />,
					roles: {
						[Role.ROOT]: true,
						[Role.FINANCE]: true,
					},
				},
				{
					name: "Confirmations",
					path: "/subscription-request-payouts/confirmations",
					icon: <RiCheckDoubleLine />,
					roles: {
						[Role.ROOT]: true,
						[Role.FINANCE]: true,
					},
				},
			],

			icon: <RiWalletLine />,
		},
		{
			name: "Payments",
			path: "/payments",
			roles: {
				[Role.ROOT]: true,
				[Role.FINANCE]: true,
			},
			subMenu: [
				{
					name: "Requests",
					path: "/payments/requests",
					icon: <IoIosCash />,
					roles: {
						[Role.ROOT]: true,
						[Role.FINANCE]: true,
					},
				},
				{
					name: "Confirmations",
					path: "/payments/confirmations",
					icon: <RiCheckDoubleLine />,
					roles: {
						[Role.ROOT]: true,
						[Role.FINANCE]: true,
					},
				},
			],
			icon: <RiWalletLine />,
		},
		{
			name: "Refunds",
			path: "/refunds",
			roles: {
				[Role.ROOT]: true,
				[Role.FINANCE]: true,
			},
			subMenu: [
				{
					name: "Requests",
					path: "/refunds/requests",
					icon: <IoIosCash />,
					roles: {
						[Role.ROOT]: true,
						[Role.FINANCE]: true,
					},
				},
				{
					name: "Confirmations",
					path: "/refunds/confirmations",
					icon: <RiCheckDoubleLine />,
					roles: {
						[Role.ROOT]: true,
					},
				},
			],
			icon: <RiWalletLine />,
		},
		{
			name: "Invoices",
			path: "/invoices",
			icon: <FaFileInvoice />,
			roles: {
				[Role.ROOT]: true,
				[Role.CUSTOMERSERVICE]: true,
				[Role.FINANCE]: true,
			},
		},
	],
	[
		{
			name: "Businesses",
			path: "/businesses",
			icon: <RiBuilding2Line />,
			roles: {
				[Role.ROOT]: true,
				[Role.CUSTOMERSERVICE]: true,
				[Role.BUSINESS_DEVELOPER]: true,
				[Role.USER_ADMINISTRATOR]: true,
			},
		},
		{
			name: "Clients",
			path: "/clients",
			icon: <UserOutlined />,
			roles: {
				[Role.ROOT]: true,
				[Role.CUSTOMERSERVICE]: true,
				[Role.BUSINESS_DEVELOPER]: true,
				[Role.USER_ADMINISTRATOR]: true,
			},
		},
		{
			name: "Pros",
			path: "/tradesmen",
			icon: <RiToolsLine />,
			roles: {
				[Role.CUSTOMERSERVICE]: true,
				[Role.USER_ADMINISTRATOR]: true,
				[Role.PRO_ADMINISTRATOR]: true,
				[Role.OUTREACH]: true,
			},
			subMenu: [
				{
					name: "Pros (Legacy)",
					path: "/tradesmen",
					icon: <RiToolsLine />,
					roles: {
						[Role.CUSTOMERSERVICE]: true,
					},
				},
				{
					name: "Pros",
					path: "/pros",
					icon: <RiToolsLine />,
					roles: {
						[Role.PRO_ADMINISTRATOR]: true,
						[Role.OUTREACH]: true,
					},
				},
			],
		},
		{
			name: "Referrers",
			path: "/referrers",
			icon: <RiBuilding2Line />,
			roles: {
				[Role.ROOT]: true,
				[Role.CUSTOMERSERVICE]: true,
				[Role.SYSTEM_ADMINISTRATOR]: true,
				[Role.USER_ADMINISTRATOR]: true,
			},
		},
		{
			name: "Assessments",
			path: "/assessments",
			icon: <RiToolsLine />,
			roles: {
				[Role.USER_ADMINISTRATOR]: true,
				[Role.CERTIFICATION_ADMINISTRATOR]: true,
			},
			subMenu: [
				{
					name: "External",
					path: "/assessments/external",
					icon: <RiToolsLine />,
					roles: {
						[Role.USER_ADMINISTRATOR]: true,
						[Role.CERTIFICATION_ADMINISTRATOR]: true,
					},
				},
			],
		},
		{
			name: "Admins",
			path: "/admins",
			icon: <RiAdminLine />,
			roles: {
				[Role.ROOT]: true,
				[Role.USER_ADMINISTRATOR]: true,
			},
		},
		{
			name: "Categories",
			path: "/categories",
			icon: <AppstoreAddOutlined />,
			roles: {
				[Role.ROOT]: true,
				[Role.CUSTOMERSERVICE]: true,
			},
		},
	],
	[
		{
			name: "Settings",
			path: "/settings",
			icon: <SettingOutlined />,
			roles: {
				...allRoles,
			},
		},
	],
];

export const SideNavigation: FC = () => {
  const { pathname } = useLocation();
  const [userRoles, setUserRoles] = useState<string[]>([]);

  const subMenuStatus: { [x: string]: boolean } = {};

  sideNavigationLinks.flat().forEach(({ path }) => {
    subMenuStatus[path] = false;
  });

  const [subMentOpenOption, setSubMenuOpenOption] = useState<{
    [x: string]: boolean;
  }>(subMenuStatus);

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdTokenResult()
      .then((result) => {
        const roles = [];

        result.claims.role && roles.push(result.claims.role);

        result.claims.roles && roles.push(...result.claims.roles);

        setUserRoles(roles);
      });
  }, []);

  return (
    <div className='o-sideNavigation__wrapper'>
      {sideNavigationLinks.map((section, index) => {
        return (
          <div key={index} className='o-sideNavigation__section'>
            {section
              .filter(({ roles }) => {
                return isAuthorized(roles, userRoles);
              })
              .map(({ name, icon, path, subMenu }) => {
                const re = new RegExp(
                  path === "/" ? "^false" : "^" + path,
                  "i"
                );

                const filteredSubMenu = subMenu?.filter(({ roles }) => {
                  return isAuthorized(roles, userRoles);
                });

                return (
                  <SideNavigationItem
                    key={name}
                    name={name}
                    icon={icon}
                    route={path}
                    {...(subMenu && {
                      filteredSubMenu,
                    })}
                    {...((re.test(pathname) ||
                      (pathname === "/" && path === "/")) && {
                      active: true,
                    })}
                    subMenuOpen={subMentOpenOption[path]}
                    setSubMenuOpen={(route: string) => {
                      setSubMenuOpenOption((prev) => {
                        return produce(prev, (draft) => {
                          Object.keys(prev).forEach((key) => {
                            draft[key] = false;
                          });

                          draft[route] = !prev[route];
                        });
                      });
                    }}
                  />
                );
              })}
          </div>
        );
      })}
    </div>
  );
};
