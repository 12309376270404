import React, { FC } from 'react';
import { PropsInterface } from './../types';
import { userDetails } from '../constants';
import { useSelector } from 'react-redux';
import { CatServiceSelectors } from '../../../redux/store';
import { types } from '../../../service';
import Island from '../../../_components/Island';
import { AddPointModal } from './AddPointModal';
import { useQuery } from '@apollo/client';
import { GET_ALL_TRADESMEN } from '../../../graphql/query';

const Performance: FC<PropsInterface> = (props: PropsInterface) => {
  const { selectCategories } = CatServiceSelectors;
  const allCategory = useSelector(selectCategories);
  const allcategories = Object.entries(allCategory);

  const { data: tradesmanData, loading, refetch } = useQuery(GET_ALL_TRADESMEN);
  const allTradesman: types.ITradesmenData[] =
    tradesmanData?.listAllTradesmen.data;

  if (loading) {
    return <div className="loading">loading...</div>;
  }

  const user = allTradesman?.filter((tradesman: types.ITradesmenData) => {
    return props.match.params.userId === tradesman.id;
  });

  const refreshData = () => {
    refetch();
  };

  const { NAME, CATEGORY, LEVEL } = userDetails;
  return (
    <div>
      <Island header="Tradesman Performance">
        {user?.map((user) => {
          const category = allcategories.filter(([id, rest]) => {
            return id === user.categoryId;
          });
          const name = category.map(([id, data]) => {
            return data.name;
          });
          return (
            <div>
              <div className="o-tradesman__personal-details">
                <div className="o-tradesmen__personal-left">
                  <div className="tradesman-point__details">
                    <h3>{NAME} </h3>
                    <h3>{LEVEL} </h3>
                    <h3>{CATEGORY} </h3>
                    <h3>Point </h3>
                  </div>
                </div>
                <div className="o-tradesmen__personal-right">
                  <h3>
                    {user.firstName} {user.lastName}
                  </h3>

                  <h3>
                    {LEVEL}
                    {user.level}
                  </h3>
                  <h3>{name[0]}</h3>
                  <h3>{user.rewardPoints}</h3>
                </div>
              </div>

              <div className="o-tradesmen__details-buttons">
                <AddPointModal
                  userId={user.id}
                  refresh={refreshData}
                  rewardpoint={user.rewardPoints}
                />
              </div>
            </div>
          );
        })}
      </Island>
    </div>
  );
};

export default Performance;
