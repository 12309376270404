import React, { FC } from 'react';
import { Tradesman } from '../../../../types';
import Badge from '@laborhack/badge';
import styles from './AssignedProListItem.module.scss';

export interface AssignedProListItemProps {
  isDefault?: boolean;
  pro: Tradesman;
}

export const AssignedProListItem: FC<AssignedProListItemProps> = ({
  pro,
  isDefault,
}) => {
  return (
    <div className={styles.proListItem}>
      <div className={styles.proListItemImage}>{pro.firstName.charAt(0)}</div>
      <p>{`${pro.firstName} ${pro.lastName}`}</p>
      {isDefault && (
        <Badge type="basic" inverted>
          Default
        </Badge>
      )}
    </div>
  );
};
