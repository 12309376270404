import { useMutation } from '@apollo/client';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { components as LayoutComponents } from '../../components/layout';
import { UPDATE_ASSIGNMENT_PAYMENT } from './graphql/mutation';
import useTransferRequest from './hooks/useTransferRequestHook';
import { TransferStatus } from './types';
import OtherAssignedTradesman from './components/OtherAssignedTradesman';
import Island from '../../_components/Island';
import { useOtherAssignmentPayments } from './hooks/useOtherAssignmentPaymentsHook';
import { AssignmentPaymentHistory } from './components/AssignmentPaymentHistory/AssignmentPaymentHistory';
import RequestFailed from '../../_components/RequestFailed';
import Select from '../../_components/Select';

const { Trail } = LayoutComponents;

const PaymentRequestView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [description, setDescription] = useState<string>('');
  const [amount, setAmount] = useState<number>();
  const [selectedProId, setSelectedProId] = useState<string>();

  const [
    updateAssignmentPayment,
    updateAssignmentPaymentResponse,
  ] = useMutation(UPDATE_ASSIGNMENT_PAYMENT, {
    onError: () => {},
  });

  const { loading, error, data } = useTransferRequest(id);

  const otherAssignmentPaymentsResponse = useOtherAssignmentPayments(
    id,
    data?.assignmentPayment.assignment.id
  );

  if (loading) {
    return <div>loading...</div>;
  }

  let err;

  if (error) {
    err = error;
  }

  if (updateAssignmentPaymentResponse.error) {
    err = updateAssignmentPaymentResponse.error.message;
  }

  if (otherAssignmentPaymentsResponse.error) {
    err = otherAssignmentPaymentsResponse.error.message;
  }

  if (err || !data) {
    return <RequestFailed errorMessage={err} />;
  }

  const otherAssignmentPaymentsFilteredByCurrentRequestType = otherAssignmentPaymentsResponse.assignmentPayments?.filter(
    ({ type }) => type === data?.assignmentPayment.type
  );

  const validAssignmentPayments = otherAssignmentPaymentsFilteredByCurrentRequestType?.filter(
    ({ transfer: { status } }) =>
      status !== TransferStatus.PENDING && status !== TransferStatus.FAILED
  );

  const totalAmountPaid = validAssignmentPayments?.reduce(
    (acc, { transfer }) => {
      if (!transfer.amount) {
        /**
         * This should never happen, but if it does, we should just ignore it
         */
        return acc;
      }

      return acc + transfer.amount;
    },
    0
  );

  const totalBalanceLeft = data.balance - (totalAmountPaid || 0);

  const allPros = [
    data.assignmentPayment.assignment.tradesman,
    ...data.assignmentPayment.assignment.otherPros,
  ];

  return (
    <Island
      header={
        <Trail
          root="Payment Request"
          child={id.substring(0, 8)}
          {...(!!data && {
            label: {
              text: data.assignmentPayment.transfer.status.replace('_', ' '),
              color: '#003952',
            },
          })}
        ></Trail>
      }
      text={`Send ${data?.assignmentPayment.type.toLowerCase()} fee to tradesmen involved on this order`}
    >
      <div className="o-singlePayment__wrapper">
        <div className="o-singlePayment__container">
          <div className="m-singlePayment__balance">
            <h4>balance</h4>
            <h2>NGN {totalBalanceLeft}</h2>
          </div>
          <div className="o-singlePayment__transferForm">
            <h2>send funds to:</h2>
            <div className="m-singlePayment__transferFormInput">
              <Icon name="send"></Icon>
              <Select
                placeholder="Select Pro"
                options={allPros.map(({ id, firstName, lastName }) => ({
                  text: `${firstName} ${lastName}`,
                  value: id,
                }))}
                disabled={!!data.assignmentPayment.tradesman}
                value={data.assignmentPayment.tradesman?.id || selectedProId}
                onChange={(e, { value }) => setSelectedProId(value as string)}
              />
            </div>
            <div className="m-singlePayment__transferFormInput">
              <Icon name="align left"></Icon>
              <textarea
                placeholder="Enter Description (optional)"
                value={
                  description || data?.assignmentPayment.transfer.description
                }
                readOnly={!!data?.assignmentPayment.transfer.amount}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            <div className="m-singlePayment__transferFormInput">
              <Icon name="money bill alternate outline"></Icon>
              <input
                type="number"
                placeholder="Enter Amount"
                value={amount || data?.assignmentPayment.transfer.amount}
                step={100}
                min={100}
                readOnly={!!data?.assignmentPayment.transfer.amount}
                max={data?.balance}
                onChange={(e) => {
                  setAmount(Number(e.target.value));
                }}
              />
            </div>
            <div className="m-singlePayment__transferFormActions">
              <button
                disabled={
                  updateAssignmentPaymentResponse.data ||
                  data?.assignmentPayment.transfer.status !==
                    TransferStatus.PENDING
                }
                className="a-singlePayment__transferFormButton"
                onClick={() => {
                  updateAssignmentPayment({
                    variables: {
                      data: {
                        assignmentPaymentId: id,
                        proId: selectedProId,
                        description,
                        amount,
                      },
                    },
                  });
                }}
              >
                {updateAssignmentPaymentResponse.loading ? (
                  <Icon name="spinner" loading></Icon>
                ) : updateAssignmentPaymentResponse.data ||
                  data?.assignmentPayment.transfer.status !==
                    TransferStatus.PENDING ? (
                  <>
                    {data?.assignmentPayment.transfer.status.replace('_', ' ')}{' '}
                    <Icon name="check"></Icon>
                  </>
                ) : (
                  'send money'
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="o-singlePayment__container">
          <div className="o-singlePayment__othersAssignedWrapper">
            {!!data?.othersAssigned?.length && <h2>tradesmen assigned here</h2>}

            {data?.othersAssigned?.map(({ amount, tradesman, destination }) => {
              return (
                <OtherAssignedTradesman
                  key={tradesman.id}
                  tradesman={tradesman}
                  destination={`${destination.area}, ${destination.location}`}
                  amountSent={amount}
                />
              );
            })}
          </div>
          {otherAssignmentPaymentsResponse.loading ? (
            <Icon size="huge" name="spinner" loading />
          ) : (
            <AssignmentPaymentHistory
              assignmentPayments={validAssignmentPayments}
              assignment={data?.assignmentPayment.assignment}
            />
          )}
        </div>
      </div>
    </Island>
  );
};

export default PaymentRequestView;
