import { gql } from "@apollo/client";

export const ADD_PRO_TO_JOB = gql`
  mutation MatchPro($jobId: String!, $proId: String!) {
    addProToMarketplaceJob(proId: $proId, marketplaceJobId: $jobId) {
      id
      pros {
        id
        firstName
        lastName
      }
      matches {
        pro {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const REMOVE_PRO_FROM_JOB = gql`
  mutation RemoveProFromJob($jobId: String!, $proId: String!) {
    removeProFromMarketplaceJob(proId: $proId, marketplaceJobId: $jobId) {
      id
      pros {
        id
        firstName
        lastName
      }
      matches {
        pro {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const REPLACE_PRO_IN_JOB = gql`
  mutation ReplaceProInJob(
    $jobId: String!
    $existingProId: String!
    $replacementProId: String!
  ) {
    replaceProInMarketplaceJob(
      existingProId: $existingProId
      replacementProId: $replacementProId
      marketplaceJobId: $jobId
    ) {
      id
      pros {
        id
        firstName
        lastName
      }
      matches {
        pro {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const ACCEPT_SHORTLIST_PRO = gql`
  mutation AcceptShortlistPro(
    $shortlistId: String!
    $proId: String!
    $addToJob: Boolean
  ) {
    markShortlistedProAcceptance(
      shortlistId: $shortlistId
      proId: $proId
      addToJob: $addToJob
    ) {
      id
      shortlistedPros {
        pro {
          id
          firstName
          lastName
        }
        status
      }
    }
  }
`;

export const DECLINE_SHORTLIST_PRO = gql`
  mutation DeclineShortlistPro($shortlistId: String!, $proId: String!) {
    markShortlistedProDecline(shortlistId: $shortlistId, proId: $proId) {
      id
      shortlistedPros {
        pro {
          id
          firstName
          lastName
        }
        status
      }
    }
  }
`;

export const ADD_PROS_TO_SHORTLIST = gql`
  mutation AddProsToShortlist($shortlistId: String!, $proIds: [String!]!) {
    addProsToShortlist(shortlistId: $shortlistId, proIds: $proIds) {
      id
      shortlistedPros {
        pro {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        status
      }
    }
  }
`;
