import { getTransferBadgeColor } from "@/features/referrer-payouts/constants";
import { formatDate } from "@/features/referrers/helpers";
import {
	GET_SUBSCRIPTION_REQUEST_PAYOUT,
	SET_SUBSCRIPTION_PRO_PAYMENT_AMOUNT,
	SYNC_SUBSCRIPTION_REQUEST_CALL_PAYOUT_AMOUNT,
} from "@/features/subscription-request-payouts/constants";
import { SubscriptionRequestCallPayout } from "@/features/subscription-request-payouts/types";
import { formatAsNaira } from "@/helpers/helpers";
import { TransferStatus } from "@/types";
import Loading from "@/_components/Loading";
import RequestFailed from "@/_components/RequestFailed";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
	Badge,
	Button,
	Divider,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Switch,
	Textarea,
	Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaCheckCircle, FaRegEdit, FaStopCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";

const REVIEW_REFERRER_PAYOUT = gql`
	mutation ReviewSubscriptionRequestCallPayout(
		$payoutId: String!
		$override: SubscriptionRequestCallPayoutOverrideInput
	) {
		reviewSubscriptionRequestCallPayout(
			payoutId: $payoutId
			override: $override
		) {
			id
			transfer {
				status
			}
			override {
				reason
				amount
			}
		}
	}
`;

export default function RequestView() {
	const { id } = useParams<{
		id: string;
	}>();

	const {
		data: subscriptionRequestCallPayoutData,
		loading: subscriptionRequestCallPayoutLoading,
		error: subscriptionRequestCallPayoutError,
		refetch,
	} = useQuery<{
		subscriptionRequestCallPayoutDetails: SubscriptionRequestCallPayout;
	}>(GET_SUBSCRIPTION_REQUEST_PAYOUT, {
		variables: {
			subscriptionRequestCallPayoutId: id,
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const [
		reviewSubscriptionPayout,
		reviewSubscriptionPayoutResponse,
	] = useMutation(REVIEW_REFERRER_PAYOUT, {
		onCompleted: () => {
			refetch({ fetchPolicy: "network-only" });
		},
	});

	const [setProPaymentAmount, setProPaymentAmountResult] = useMutation(
		SET_SUBSCRIPTION_PRO_PAYMENT_AMOUNT,
		{
			onCompleted: () => {
				closeEditingPaymentAmountLayover();
				syncSubscriptionRequestCallPayoutAmount({
					variables: {
						payoutId: id,
					},
				});
			},
		}
	);

	const [syncSubscriptionRequestCallPayoutAmount] = useMutation(
		SYNC_SUBSCRIPTION_REQUEST_CALL_PAYOUT_AMOUNT,
		{
			onCompleted: async () => {
				await refetch({
					fetchPolicy: "network-only",
					nextFetchPolicy: "network-only",
				});
			},
		}
	);

	const [isOverridingAmount, setIsOverridingAmount] = useState(false);
	const [overrideAmount, setOverrideAmount] = useState<number | undefined>();
	const [overrideReason, setOverrideReason] = useState<string | undefined>();

	const [
		selectedSubscriptionRequestCall,
		setSelectedSubscriptionRequestCall,
	] = useState<string>();

	const [editingPaymentAmount, setEditingPaymentAmount] = useState(false);

	const [editedPaymentAmount, setEditedPaymentAmount] = useState<number>();

	const handleIsOverridingAmount = () => {
		setIsOverridingAmount(!isOverridingAmount);
	};

	const handleOverrideAmount = (valueAsString: string) => {
		setOverrideAmount(parseFloat(valueAsString));
	};

	const closeEditingPaymentAmountLayover = () => {
		setEditingPaymentAmount(false);
		setEditedPaymentAmount(0);
		setSelectedSubscriptionRequestCall(undefined);
	};

	const openEditingPaymentAmountLayover = (requestCallId: string) => {
		setSelectedSubscriptionRequestCall(requestCallId);
		setEditingPaymentAmount(true);
	};

	const handleSubmitReview = () => {
		reviewSubscriptionPayout({
			variables: {
				payoutId: id,
				...(overriddenAmount &&
					overrideReason && {
						override: {
							amount: overriddenAmount,
							reason: overrideReason,
						},
					}),
			},
		});
	};
	if (subscriptionRequestCallPayoutLoading) return <Loading />;

	if (subscriptionRequestCallPayoutError) {
		return <RequestFailed />;
	}

	if (reviewSubscriptionPayoutResponse.error) {
		return (
			<RequestFailed
				errorMessage={reviewSubscriptionPayoutResponse.error.message}
				onRetry={reviewSubscriptionPayoutResponse.reset}
			/>
		);
	}

	if (!subscriptionRequestCallPayoutData?.subscriptionRequestCallPayoutDetails)
		return <RequestFailed errorMessage="Not Found" />;

	const payout =
		subscriptionRequestCallPayoutData?.subscriptionRequestCallPayoutDetails;
	const transferStatus = payout?.transfer.status;

	const payoutAmount = payout?.amount;

	const isReviewable = transferStatus === TransferStatus.PENDING;

	const overriddenAmount =
		isOverridingAmount && overrideAmount ? overrideAmount : undefined;

	const canSubmitReview =
		isReviewable && (overriddenAmount ? !!overrideReason : true);

	return (
		<div className="grid grid-cols-3 gap-4">
			<div className="col-span-2">
				<div className="flex flex-col gap-8 p-6 bg-white border">
					<div className="mb-4">
						<h2 className="text-primary-500 text-lg font-medium m-0">
							Subscription Scheduled Request Payout #{id.slice(0, 8)}
						</h2>
						<p className="m-0 text-primary-300 text-sm">
							Shows a detailed view of a subscription scheduled request payout
						</p>
					</div>
					<h3 className="text-primary-500 text-md font-medium m-0">
						Pro Information
					</h3>
					<div className="grid grid-cols-[2fr,3fr] gap-2 max-w-lg mb-4">
						<p className="m-0 text-primary-300 text-sm">Pro</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.pro.firstName} {payout?.pro.lastName}
						</p>
						<p className="m-0 text-primary-300 text-sm">Email</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.pro.email}
						</p>
						<p className="m-0 text-primary-300 text-sm">Phone Number</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.pro.phoneNumber}
						</p>
					</div>
					<Divider />
					<h3 className="text-primary-500 text-md font-medium m-0">
						Scheduled Requests
					</h3>
					{payout?.subscriptionRequestCalls.map((call, index) => {
						return (
							<div className="grid grid-cols-[2fr,3fr] gap-2 max-w-lg mb-4">
								<p className="m-0 text-primary-300 text-sm">
									Subscription Request
								</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{call.subscriptionRequest.id}
								</p>
								<p className="m-0 text-primary-300 text-sm">Service</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{call.subscriptionRequest.plan.package.name}
								</p>
								<p className="m-0 text-primary-300 text-sm">
									Date of Assignment
								</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{formatDate(new Date(call.dateOfAssignment)).split(",")[0]}
								</p>
								<p className="m-0 text-primary-300 text-sm">Set Pay</p>
								<div className="flex gap-4">
									<p className="m-0 text-primary-500 text-sm font-medium">
										{call.proPaymentAmount
											? formatAsNaira(call.proPaymentAmount)
											: "Not Set"}
									</p>
									{isReviewable && (
										<div
											className="flex gap-1 items-center hover:cursor-pointer group"
											onClick={() => openEditingPaymentAmountLayover(call.id)}
										>
											<p className="group-hover:text-blue-400 m-0 text-primary-500 text-sm font-normal">
												Edit
											</p>
											<FaRegEdit className="group-hover:text-blue-400 h-3" />
										</div>
									)}
								</div>
								<p className="m-0 text-primary-300 text-sm">Expected Pay</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{formatAsNaira(call.expectedProPaymentAmount)}
								</p>
							</div>
						);
					})}
				</div>
			</div>

			<div>
				<div className="flex flex-col gap-6 p-6 bg-white border">
					<div>
						<h2 className="text-primary-500 text-lg font-medium m-0">
							Breakdown
						</h2>
					</div>
					<Divider />
					<div className="flex flex-col gap-4">
						<div className="flex flex-col gap-2">
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Status</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									<Badge colorScheme={getTransferBadgeColor(transferStatus)}>
										{transferStatus.replace("_", " ")}
									</Badge>
								</p>
							</div>
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Amount (NGN)</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{formatAsNaira(payoutAmount)}
								</p>
							</div>
							{/* {!isReviewable && (
								<div className="flex justify-between">
									<p className="m-0 text-primary-300 text-sm">Overridden</p>
									{payout.override ? (
										<FaCheckCircle className="text-green-500" />
									) : (
										<FaStopCircle className="text-red-500" />
									)}
								</div>
							)} */}
						</div>
					</div>

					{/* {isReviewable && (
						<>
							<Divider />
							<div className="flex flex-col gap-4">
								<div className="flex flex-col gap-6">
									<div className="flex flex-col gap-2">
										<div className="flex justify-end w-full gap-2">
											<p className="m-0 text-primary-500 text-sm">
												Override Amount
											</p>
											<Switch
												size="md"
												isChecked={isOverridingAmount}
												onChange={handleIsOverridingAmount}
												isDisabled={!isReviewable}
											/>
										</div>

										{isOverridingAmount && (
											<FormControl>
												<NumberInput
													onChange={handleOverrideAmount}
													value={overrideAmount}
													min={0}
													defaultValue={
														payout.amount ? payout.amount.toString() : ""
													}
													isDisabled={!isReviewable}
												>
													<NumberInputField />
													<NumberInputStepper>
														<NumberIncrementStepper />
														<NumberDecrementStepper />
													</NumberInputStepper>
												</NumberInput>
											</FormControl>
										)}
									</div>
									{!!overrideAmount && isOverridingAmount && (
										<Textarea
											className="font-normal"
											placeholder="Reason for override"
											value={overrideReason}
											onChange={(e) => setOverrideReason(e.target.value)}
											isDisabled={!isReviewable}
										/>
									)}
								</div>
							</div>
						</>
					)} */}
					<Divider />
					<div className="flex flex-col gap-2">
						<Button
							colorScheme="green"
							rightIcon={<FaCheckCircle />}
							isDisabled={!canSubmitReview}
							onClick={handleSubmitReview}
							isLoading={reviewSubscriptionPayoutResponse.loading}
						>
							Submit Review
						</Button>
					</div>
				</div>
			</div>
			<Modal
				isOpen={editingPaymentAmount}
				onClose={closeEditingPaymentAmountLayover}
				size="md"
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalHeader>Edit Payment Amount</ModalHeader>
					<ModalBody>
						{setProPaymentAmountResult.error && (
							<RequestFailed
								errorMessage={setProPaymentAmountResult.error.message}
								onRetry={setProPaymentAmountResult.reset}
							/>
						)}
						{!setProPaymentAmountResult.error && (
							<Stack>
								<FormControl>
									<FormLabel>Current Payment Amount</FormLabel>
									<Input
										value={
											payout?.subscriptionRequestCalls.find(
												(call) => call.id === selectedSubscriptionRequestCall
											)?.proPaymentAmount
										}
										isDisabled
									/>
								</FormControl>
								<FormControl isRequired>
									<FormLabel>New Payment Amount</FormLabel>
									<NumberInput
										onChange={(valueAsString) => {
											let value = parseFloat(valueAsString);

											if (value < 0) {
												value = 0;
											} else if (isNaN(value)) {
												value = 0;
											}

											setEditedPaymentAmount(value);
										}}
										value={editedPaymentAmount}
										isDisabled={!isReviewable}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</FormControl>
								<Button
									alignSelf={"self-end"}
									colorScheme="green"
									size={"sm"}
									disabled={!editedPaymentAmount}
									isLoading={setProPaymentAmountResult.loading}
									onClick={() => {
										setProPaymentAmount({
											variables: {
												subscriptionRequestCallId: selectedSubscriptionRequestCall,
												paymentAmount: editedPaymentAmount,
											},
										});
									}}
								>
									Save
								</Button>
							</Stack>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</div>
	);
}
