import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Badge,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Skeleton,
  Spinner,
  Switch,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  FaArrowRight,
  FaCheckCircle,
  FaExternalLinkAlt,
  FaStopCircle,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  getTransferBadgeColor,
  GET_CONTRACT_PAYOUT,
} from "../../../../features/contract-payouts/constants";
import { ContractPayout } from "../../../../features/contract-payouts/types";
import { formatAsNaira } from "../../../../helpers/helpers";
import { TransferStatus } from "../../../../types";
import Loading from "../../../../_components/Loading";
import RequestFailed from "../../../../_components/RequestFailed";
import { PaymentMethod } from "../../../recruitments/types";

const REVIEW_CONTRACT_PAYOUT = gql`
  mutation ReviewContractPayout(
    $id: String!
    $override: ContractPayoutOverrideInput
  ) {
    reviewContractPayout(payoutId: $id, override: $override) {
      id
      breakdown {
        gross
        net
        tax
      }
      transfer {
        id
        status
      }
      createdAt
    }
  }
`;

const COMPUTE_PAYOUT_BREAKDOWN = gql`
  query ComputePayoutBreakdown(
    $id: String!
    $override: ContractPayoutOverrideInput
  ) {
    computePayoutBreakdown(payoutId: $id, override: $override) {
      gross
      net
      tax
    }
  }
`;

export default function Page() {
  const { id } = useParams<{
    id: string;
  }>();

  const [isOverridingGross, setIsOverridingGross] = useState(false);

  const [isOverridingTax, setIsOverridingTax] = useState(false);

  const [overrideGross, setOverrideGross] = useState<string>();

  const [overrideTax, setOverrideTax] = useState<string>();

  const [overrideReason, setOverrideReason] = useState<string>();

  const { data, loading, error } = useQuery<{
    contractPayout: ContractPayout | null;
  }>(GET_CONTRACT_PAYOUT, {
    variables: {
      id,
    },
  });

  const isReviewable =
    data?.contractPayout?.transfer.status === TransferStatus.PENDING;

  const overriddenGross =
    isOverridingGross && overrideGross !== undefined
      ? parseFloat(overrideGross || "0")
      : undefined;

  const overriddenTax =
    isOverridingTax && overrideTax !== undefined
      ? parseFloat(overrideTax || "0")
      : undefined;

  const computePayoutBreakdownResponse = useQuery<{
    computePayoutBreakdown: {
      gross: number;
      net: number;
      tax: number;
    };
  }>(COMPUTE_PAYOUT_BREAKDOWN, {
    variables: {
      id,
      ...((overriddenGross !== undefined || overriddenTax !== undefined) && {
        override: {
          amount: overriddenGross,
          tax: overriddenTax,
          reason: "Computing payout breakdown",
        },
      }),
    },
    notifyOnNetworkStatusChange: true,
  });

  const [reviewContractPayout, reviewContractPayoutResponse] = useMutation(
    REVIEW_CONTRACT_PAYOUT
  );

  if (loading) return <Loading />;

  if (error) return <RequestFailed />;

  if (!data?.contractPayout) return <RequestFailed errorMessage='Not Found' />;

  const {
    breakdown,
    override,
    worksheet: {
      expectedUnitAmount,
      unitAmount,
      contract: {
        paymentAmount,
        paymentMethod,
        pro: { firstName, lastName, email, phoneNumber },
        recruitment: {
          id: recruitmentId,
          client: {
            firstName: clientFirstName,
            lastName: clientLastName,
            email: clientEmail,
            business,
          },
        },
      },
      payroll: {
        period: { start, end },
      },
    },
    transfer,
    createdAt,
  } = data.contractPayout;

  const handleOverrideGrossChange = (valueString: string) => {
    setOverrideGross(valueString);
  };

  const handleOverrideTaxChange = (valueString: string) => {
    setOverrideTax(valueString);
  };

  const handleIsOverridingGrossChange = () => {
    setIsOverridingGross((prev) => !prev);
  };

  const handleIsOverridingTaxChange = () => {
    setIsOverridingTax((prev) => !prev);
  };

  const isValidNetAmount =
    !!computePayoutBreakdownResponse.data?.computePayoutBreakdown.net &&
    computePayoutBreakdownResponse.data.computePayoutBreakdown.net > 0;

  const handleSubmitReview = () => {
    reviewContractPayout({
      variables: {
        id,
        ...((overriddenGross !== undefined || overriddenTax !== undefined) && {
          override: {
            amount: overriddenGross,
            tax: overriddenTax,
            reason: overrideReason,
          },
        }),
      },
    });
  };

  const canSubmitReview =
    !computePayoutBreakdownResponse.loading &&
    isValidNetAmount &&
    isReviewable &&
    (overriddenGross !== undefined || overriddenTax !== undefined
      ? !!overrideReason
      : true);

  return (
    <div className='grid grid-cols-3 gap-4'>
      <div className='col-span-2'>
        <div className='flex flex-col gap-8 p-6 bg-white border'>
          <div className='mb-4'>
            <h2 className='text-primary-500 text-lg font-medium m-0'>
              Contract Payout Request #{id.slice(0, 8)}
            </h2>
            <p className='m-0 text-primary-300 text-sm'>
              Shows a detailed view of a contract payout request
            </p>
          </div>
          <div className='grid grid-cols-[1fr,2fr] gap-2 max-w-md'>
            <p className='m-0 text-primary-300 text-sm'>Pro</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {firstName} {lastName}
            </p>
            <p className='m-0 text-primary-300 text-sm'>Email</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>{email}</p>
            <p className='m-0 text-primary-300 text-sm'>Phone Number</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {phoneNumber}
            </p>
            <p className='m-0 text-primary-300 text-sm'>Start</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {new Date(start).toDateString()}
            </p>
            <p className='m-0 text-primary-300 text-sm'>End</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {new Date(end).toDateString()}
            </p>
            <p className='m-0 text-primary-300 text-sm'>ID</p>
            <Link
              href={window.location.origin + `/recruitment/${recruitmentId}`}
              isExternal
              className='!text-primary-500 text-sm font-medium'
            >
              {recruitmentId.slice(0, 8)}
              <FaExternalLinkAlt className='ml-1 inline-block' />
            </Link>
            <p className='m-0 text-primary-300 text-sm'>Business</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {business?.name || "N/A"}
            </p>
            <p className='m-0 text-primary-300 text-sm'>Contact</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {clientFirstName} {clientLastName}
            </p>
            <p className='m-0 text-primary-300 text-sm'>Contact Email</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {clientEmail}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className='flex flex-col gap-6 p-6 bg-white border'>
          <div>
            <h2 className='text-primary-500 text-lg font-medium m-0'>
              Breakdown
            </h2>
          </div>
          <Divider />
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-2'>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Status</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  <Badge colorScheme={getTransferBadgeColor(transfer.status)}>
                    {transfer.status.replace("_", " ")}
                  </Badge>
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Type</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {paymentMethod.replace("_", " ")}
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Base (NGN)</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {formatAsNaira(paymentAmount)}
                  {paymentMethod === PaymentMethod.BY_OUTPUT && " /unit"}
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Worksheet</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {((unitAmount / expectedUnitAmount) * 100).toFixed(0)} %{" "}
                  <span className='text-gray-500'>
                    ({unitAmount}/{expectedUnitAmount})
                  </span>
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>
                  Gross Amount (NGN)
                </p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {formatAsNaira(breakdown.gross)}
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Tax (PAYE)</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  - {breakdown.tax ? formatAsNaira(breakdown.tax) : "N/A"}
                </p>
              </div>
              {!isReviewable && (
                <div className='flex justify-between'>
                  <p className='m-0 text-primary-300 text-sm'>Overridden</p>
                  {override ? (
                    <FaCheckCircle className='text-green-500' />
                  ) : (
                    <FaStopCircle className='text-red-500' />
                  )}
                </div>
              )}
            </div>
          </div>
          <Divider />
          {isReviewable && (
            <>
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col gap-6'>
                  <div className='flex flex-col gap-2'>
                    <div className='flex justify-end w-full gap-2'>
                      <p className='m-0 text-primary-500 text-sm'>
                        Override Gross?
                      </p>
                      <Switch
                        size='md'
                        isChecked={isOverridingGross}
                        onChange={handleIsOverridingGrossChange}
                        isDisabled={!isReviewable}
                      />
                    </div>

                    {isOverridingGross && (
                      <FormControl>
                        <NumberInput
                          onChange={handleOverrideGrossChange}
                          value={overrideGross}
                          min={0}
                          defaultValue={
                            breakdown.gross ? breakdown.gross.toString() : ""
                          }
                          isDisabled={!isReviewable}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </div>
                  <div className='flex flex-col gap-2'>
                    <div className='flex justify-end w-full gap-2'>
                      <p className='m-0 text-primary-500 text-sm'>
                        Override Tax?
                      </p>
                      <Switch
                        size='md'
                        isChecked={isOverridingTax}
                        onChange={handleIsOverridingTaxChange}
                        isDisabled={!isReviewable}
                      />
                    </div>
                    {isOverridingTax && (
                      <FormControl>
                        <NumberInput
                          onChange={handleOverrideTaxChange}
                          value={overrideTax}
                          min={0}
                          defaultValue={
                            breakdown.tax ? breakdown.tax.toString() : ""
                          }
                          isDisabled={!isReviewable}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </div>
                  {!!(overriddenGross || overriddenTax) && (
                    <Textarea
                      className='font-normal'
                      placeholder='Reason for override'
                      value={overrideReason}
                      onChange={(e) => setOverrideReason(e.target.value)}
                      isDisabled={!isReviewable}
                    />
                  )}
                </div>
              </div>
              <Divider />
            </>
          )}
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-2'>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Net Amount (NGN)</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {computePayoutBreakdownResponse.loading ? (
                    <Spinner size='sm' className='ml-2' />
                  ) : (
                    formatAsNaira(
                      computePayoutBreakdownResponse.data
                        ?.computePayoutBreakdown.net || breakdown.net
                    )
                  )}
                </p>
              </div>
            </div>
          </div>
          <Divider />
          <div className='flex flex-col gap-2'>
            <Button
              colorScheme='green'
              rightIcon={<FaCheckCircle />}
              isDisabled={!canSubmitReview}
              onClick={handleSubmitReview}
              isLoading={reviewContractPayoutResponse.loading}
            >
              Submit Review
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
