import { ColumnsType } from "antd/lib/table";
import React, { Dispatch, FC, SetStateAction } from "react";
import {
  Pro,
  RecruitmentJob,
  RecruitmentJobPro,
} from "../../../types";
import RequestFailed from "../../../_components/RequestFailed";
import { Button } from "@chakra-ui/react";
import { CustomTable } from "@/components/layout/components/CustomTable";
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { ProRecruitmentContract } from "../types";


const TERMINATE_PRO_RECRUITMENT_CONTRACT = gql`
  mutation TerminateProRecruitmentContract($contractId: String!) {
    terminateProRecruitmentContract(contractId: $contractId) {
      id
    }
  }
`;

interface AssignedProsProps {
  proRecruitmentContracts?: ProRecruitmentContract[];
  jobs?: RecruitmentJob[];
  refetchProRecruitmentContracts: () => void;
  setContractPaymentDetailsIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedJobId: Dispatch<SetStateAction<string>>;
  setSelectedProId: Dispatch<SetStateAction<string>>;
}


export const AssignedPros: FC<AssignedProsProps> = ({
  jobs,
  refetchProRecruitmentContracts,
  proRecruitmentContracts,
  setContractPaymentDetailsIsOpen,
  setSelectedJobId,
  setSelectedProId
}: AssignedProsProps) => {

  const [terminateContract, terminateContractResponse] = useMutation(TERMINATE_PRO_RECRUITMENT_CONTRACT, {
    onCompleted: () => {
      refetchProRecruitmentContracts();
    }
  });


  if (!jobs) {
    return null;
  }

  const isActiveJob = (proId: string) => {
    return proRecruitmentContracts?.some((contract) => contract.pro.id === proId && contract.status === "ACTIVE");
  }
  

  const assignedPros =  jobs.reduce((acc: Pro[], job) => {
    return [...acc, ...job?.pros.map((pro) => ({
      ...pro,
      category: job.category.name,
      jobId: job.id
    }))]
  }, [] as Pro[])


  const handleActivate = (proId: string, jobId: string) => {
    setSelectedProId(proId);
    setSelectedJobId(jobId);
    setContractPaymentDetailsIsOpen(true);
  }


  const handleTerminate = (contractId: string) => {
    terminateContract({
      variables: {
        contractId,
      },
    })
  }

  const columns: ColumnsType<RecruitmentJobPro & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.pro.id.substring(0, 8)}</p>;
      },
      width: "10%",
      fixed: "left",
    },
    {
      title: "First Name",
      render: (text, record) => {
        return <p>{record.pro.firstName}</p>;
      },

      width: "15%",
    },
    {
      title: "Last Name",
      render: (text, record) => {
        return <p>{record.pro.lastName}</p>;
      },

      width: "15%",
    },
    {
      title: "Category",
      render: (text, record) => {
        return <p>{record.pro.category}</p>;
      },
      key: "category",
      width: "20%",
    },
    {
      title: "Email",
      render: (text, record) => {
        return <p>{record.pro.email}</p>;
      },
      key: "email",
      width: "20%",
    },
    {
      title: "Phone Number",
      render: (text, record) => {
        return <p>{record.pro.phoneNumber}</p>;
      },
      key: "phoneNumber",
      width: "15%",
    },
    {
      title: "",
      render: (text, record) => {
       

        return (
          <div className="flex gap-2">
            <Button
              disabled={isActiveJob(record.pro.id)}
              className="!bg-green-500 text-white"
              variant="success"
              onClick={() => handleActivate(record.pro.id ?? '', record.pro.jobId ?? '')}
              fontSize="11px"
            >
              Activate Job
            </Button>
            <Button
              disabled={!isActiveJob(record.pro.id)}
              fontSize="11px"
              className="!bg-red-500 text-white"
              onClick={() => {
                const contractId = proRecruitmentContracts?.find((contract) => contract.pro.id === record.pro.id)?.id;
                handleTerminate(contractId!);
              }}
            >
              Terminate Job
            </Button>
          </div>
        );
      },
      width: "20%",
      fixed: "right",
    },
  ];

  const error = terminateContractResponse.error;
  if (error) {
    return (
      <RequestFailed
        errorMessage={
         error.message
        }
      />
    );
  }

  return (
    <>
      <div className="mt-8">
        <div className="flex justify-end gap-2 my-2">
        </div>
        <CustomTable
          loading={false}
          onRow={(record: any, index: any) => {
            return {};
          }}
          columns={columns}
          data={assignedPros?.map((pro: any) => {
            return {
              pro: {...pro},
              key: pro.id,
            };
          })}
        />
      </div>
    </>
  );
};
