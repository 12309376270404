import React, { FC } from "react";
import { Tradesman, TradesmanAssignment } from "../../../../types";
import RequestFailed from "../../../../_components/RequestFailed";
import { AssignmentPayment } from "../../types";
import { HistoryItem } from "./HistoryItem";

import styles from "./styles.module.scss";

export interface AssignmentPaymentHistoryProps {
  assignment?: TradesmanAssignment;
  assignmentPayments?: AssignmentPayment[];
}

export const AssignmentPaymentHistory: FC<AssignmentPaymentHistoryProps> = ({
  assignment,
  assignmentPayments,
}) => {
  if (!assignment || !assignmentPayments) {
    return <RequestFailed />;
  }

  return (
    <div className={styles.history}>
      <h3>History</h3>
      <div className='w-full p-4'>
        {assignmentPayments.length > 0 ? (
          assignmentPayments.map((assignmentPayment) => {
            const {
              firstName,
              lastName,
            } = assignmentPayment.tradesman as Tradesman;

            return (
              <HistoryItem
                key={assignmentPayment.id}
                proName={`${firstName} ${lastName}`}
                assignmentPayment={assignmentPayment}
              />
            );
          })
        ) : (
          <h4 className='!text-primary-300 text-lg italic text-center w-full'>
            No history
          </h4>
        )}
      </div>
    </div>
  );
};
