import clsx from "clsx";
import React, { FC } from "react";
import Avatar from "../Avatar";

export interface AvatarGroupProps {
  maxCount?: number;
}

export const AvatarGroup: FC<AvatarGroupProps> = ({ maxCount, children }) => {
  const childrenArray = React.Children.toArray(children);

  let numberOfItemsToRender = childrenArray.length;

  if (maxCount && childrenArray.length > maxCount) {
    numberOfItemsToRender = maxCount;
  }

  const itemsAreOverflowing = childrenArray.length > numberOfItemsToRender;

  /**
   * If there are more than `maxCount` children, we will show the first `maxCount - 1` children
   */

  const childrenToRender = childrenArray.slice(0, numberOfItemsToRender);

  const itemsDisplayed = itemsAreOverflowing
    ? numberOfItemsToRender + 1
    : numberOfItemsToRender;

  return (
    <div className='flex items-center'>
      <div
        className={clsx("grid relative")}
        style={{
          gridTemplateColumns: `repeat(${itemsDisplayed}, 24px)`,
          width: `${itemsDisplayed * 24 + 12}px`,
        }}
      >
        {childrenToRender}
        {itemsAreOverflowing && (
          <Avatar className=' !bg-gray-400'>
            +{childrenArray.length - numberOfItemsToRender}
          </Avatar>
        )}
      </div>
    </div>
  );
};
