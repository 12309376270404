import React, { FC } from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Island from "../../../_components/Island";
import { components as LayoutComponents } from "../../layout";
import { GET_ALL_CLIENTS } from "../../../graphql/query";
import { useApplicationConfig } from "../../../context/ApplicationContext";
import { Client, RelayStylePaginatedResponse } from "../../../types";
import { ColumnsType } from "antd/lib/table";

const { CustomTable } = LayoutComponents;

const AllClients: FC = () => {
  const applicationConfig = useApplicationConfig();

  const { toggles } = applicationConfig;

  const history = useHistory();

  const { data, loading, fetchMore, refetch } = useQuery<{
    clients: RelayStylePaginatedResponse<Client>;
  }>(GET_ALL_CLIENTS, {
    variables: {
      first: 10,
      after: null,
    },
  });

  const columns: ColumnsType<Client & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => record.id.substring(0, 8),
      width: "10%",
    },
    {
      title: "Name",
      render: (text, record) => `${record.firstName} ${record.lastName}`,
      width: "20%",
    },
    {
      title: "Email",
      render: (text, record) => record.email,
      width: "25%",
    },
    {
      title: "Phone Number",
      render: (text, record) => record.phoneNumber || "N/A",
      width: "15%",
    },
    {
      title: "Date Created",
      dataIndex: "",
      render: (text, record) => new Date(record.createdAt).toLocaleString(),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      width: "5%",
      render: (text: any, record) => (
        <Icon
          className='pointer'
          name='long arrow alternate right'
          onClick={() => {
            history.push(`/clients/${record.id}`);
          }}
        ></Icon>
      ),
    },
  ];

  const getMore = () => {
    fetchMore({
      variables: {
        first: 10,
        after: data?.clients.pageInfo.endCursor,
      },
    });
  };

  return (
    <Grid columns='1'>
      <Grid.Column width='16'>
        <Island header='All Clients'>
          <div className='o-clients__header-wrapper'>
            <div className='o-add-wrapper'>
              <Button
                disabled={toggles && !toggles.enable_client_registration}
                className='o-add-button'
                onClick={() => history.push("/clients/create")}
              >
                Create Client
              </Button>
              <div
                className='refresh-btn'
                onClick={() => {
                  refetch();
                }}
              >
                <p>Refresh </p>
                <Icon name='refresh'></Icon>
              </div>
            </div>
          </div>
          <CustomTable
            columns={columns}
            data={
              data?.clients.edges.map((edge) => {
                return { key: edge.node.id, ...edge.node };
              }) || []
            }
            loading={loading}
            onRow={(record: any) => {
              return {
                onClick: () => history.push(`/clients/${record.id}`),
              };
            }}
            hasMore={data?.clients.pageInfo.hasNextPage}
            fetchMore={getMore}
          />
        </Island>
      </Grid.Column>
    </Grid>
  );
};

export default AllClients;
