import { gql } from "@apollo/client";

export const LIST_ASSIGNMENT_PAYMENTS = gql`
  query GetAssignmentPayments(
    $first: Int
    $after: String
    $filters: ListAssignmentPaymentFilters
    $search: ListAssignmentPaymentSearch
  ) {
    assignmentPayments(
      first: $first
      after: $after
      filters: $filters
      search: $search
    ) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
      }
      edges {
        cursor
        node {
          id
          order {
            orderCode
          }
          tradesman {
            firstName
            lastName
            location {
              area
              location
            }
          }
          type
          transfer {
            status
            amount
          }
          createdAt
        }
      }
    }
  }
`;

export const LIST_ASSIGNMENT_PAYMENTS_FOR_ORDER = gql`
  query ListAssignemtPaymentsByOrderId($orderId: String!) {
    listAssignmentPaymentsByOrderId(orderId: $orderId) {
      status
      message
      data {
        id
        assignment {
          id
          order {
            id
            orderCode
            inspectionPayment {
              id
              amount
              status
            }
          }
          jobs {
            id
            invoiceDetails {
              labor
              materials {
                name
                quantity
                unitPrice
              }
            }
          }
          invoice {
            id
            isFullyPaid
            hasAtLeastOnePayment
            payment {
              status
            }
          }
          tradesman {
            id
            level
            firstName
            lastName
            location {
              area
              location
            }
          }
        }
        type
        transfer {
          id
          status
          purpose
          amount
          description
        }
        createdAt
      }
    }
  }
`;

export const GET_ASSIGNMENT_PAYMENT = gql`
  query GetAssignmentPayment($id: String!) {
    assignmentPayment(id: $id) {
      id
      tradesman {
        id
        userId
        firstName
        lastName
      }
      assignment {
        id
        tradesman {
          id
          firstName
          lastName
        }
        otherPros {
          id
          firstName
          lastName
        }
        inspectionPayment {
          status
          amount
        }
        jobs {
          id
          invoiceDetails {
            labor
            materials {
              name
              quantity
              unitPrice
            }
          }
        }
        invoice {
          id
          hasAtLeastOnePayment
          isFullyPaid
          splitPayments {
            percentage
            payment {
              status
            }
          }
          payment {
            status
          }
        }
      }
      type
      transfer {
        id
        status
        purpose
        amount
        description
      }
      createdAt
    }
  }
`;
