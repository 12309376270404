import React, { FC } from 'react';

import styles from './styles.module.scss';

export interface InformationProps {
  item?: React.ReactElement;
  description: string;
}

export const Information: FC<InformationProps> = ({ item, description }) => {
  return (
    <div className={styles.informationItem}>
      {item}
      <p>{description}</p>
    </div>
  );
};
