import {
  ListProsIdentityVerificationStatus,
  ListProsTestStatus,
  PaginationArgs,
  Payment,
  Pro,
} from "../../types";
import { TradeLevel, TradeRequirement, TradeRequirementType } from "./trade";

export interface ProTradeLevelDiscount {
  id: string;
  code: string;
  description: string;
  applicableTradeLevels: TradeLevel[];
  validFrom: Date;
  validUntil?: Date;
  autoApplied: boolean;
  limit?: number;
  pros: Pro[];
  useOnce?: boolean;
  value: number;
}

export interface ProTradeLevelPayment {
  id: string;
  tradeLevel: TradeLevel;
  affectedTradeRequirements: TradeRequirement[];
  pro: Pro;
  payments: Payment[];
  isPaid: boolean;
  discount?: ProTradeLevelDiscount;
  createdAt: Date;
  updatedAt: Date;
}

export interface ProTradeRequirementResult {
  scores: number[];
  attemptId: number;
  submittedAt: Date;
  passed: boolean;
}

export enum ListProTradeRequirementsSortBy {
  CREATED_AT = "CREATED_AT",
}

export enum ListProTradeRequirementsSearchBy {
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  EMAIL = "EMAIL",
}

export enum ExternalProTradeRequirementStatus {
  NOT_PAID = "NOT_PAID",
  AWAITING_UPLOAD = "AWAITING_UPLOAD",
  AWAITING_RESULT = "AWAITING_RESULT",
  PASSED = "PASSED",
  FAILED = "FAILED",
}

export interface ListProTradeRequirementsSort {
  by: ListProTradeRequirementsSortBy;
  ascending?: boolean;
}

export interface ListProTradeRequirementsSearch {
  query: string;
  by: ListProTradeRequirementsSearchBy;
}

export interface ListProTradeRequirementsFilters {
  externalStatus?: ExternalProTradeRequirementStatus[];
  requirementIds?: string[];
  identityVerificationStatus?: ListProsIdentityVerificationStatus[];
  assessmentType?: TradeRequirementType[];
}

export interface ListProTradeRequirementsArgs extends PaginationArgs {
  filters?: ListProTradeRequirementsFilters;
  sort?: ListProTradeRequirementsSort;
  search?: ListProTradeRequirementsSearch;
}

/**
 * This keeps track of the state of a trade requirement for a pro.
 *
 * This is used to determine if a pro has completed a trade requirement, keeps scores, payment information, etc.
 */
export interface ProTradeRequirement {
  id: string;
  pro: Pro;
  requirement: TradeRequirement;
  tradeLevelPayments: ProTradeLevelPayment[];
  attemptsRemaining: number;
  hasPassed: boolean;
  hasCompletedPayment: boolean;
  results: ProTradeRequirementResult[];
  externalStatus: ExternalProTradeRequirementStatus;
  processedAt?: Date;
  createdAt: Date;
}
