import {
    Box,
    Button,
    Flex,
    GridItem,
    Icon,
    Text,
  } from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import { EmbeddedMap } from "./EmbeddedMap";
import { LocationData } from "../../types";

  interface ViewLocationProps {
    location: LocationData;
    removeLocation: () => void;
  }

  
  export const ViewLocation = ({location, removeLocation}: ViewLocationProps) => {
    return (
      <GridItem display='flex' flexDirection='column' gap={4} width="150%">
          <Text fontSize='sm' color='gray.500'>
            This is where you expect the LaborHack Pros to carry out their tasks
          </Text>
        <Flex w='full' direction='column' gap={4}>
          <Box w='full' h='72'>
            <EmbeddedMap placeId={location!.placeId} />
          </Box>
  
          <Text fontSize='sm' fontStyle='italic'>
            {location!.address}, {location!.city}, {location!.state}
          </Text>
        </Flex>
        <Button
          onClick={removeLocation}
          alignSelf='end'
          rightIcon={<Icon as={MdEdit} />}
        >
          Change
        </Button>
      </GridItem>
    );
  };
  