import { useHistory, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import BackButton from "../../../../../../_components/BackButton";
import { gql, useMutation, useQuery } from "@apollo/client";
import { ContractPayoutReimbursement } from "../../../../types";
import {
  FaEllipsisV,
  FaEye,
  FaPaperPlane,
  FaSyncAlt,
  FaTrashAlt,
} from "react-icons/fa";
import { formatAsNaira } from "../../../../../../helpers/helpers";
import RequestFailed from "../../../../../../_components/RequestFailed";
import Loading from "../../../../../../_components/Loading";
import {
  CANCEL_INVOICE,
  SEND_INVOICE,
} from "../../../../../../graphql/mutation";

const GET_CONTRACT_PAYOUT_REIMBURSEMENTS = gql`
  query GetContractPayoutReimbursementsByInvoice($invoiceId: String!) {
    contractPayoutReimbursementsByInvoice(invoiceId: $invoiceId) {
      id
      payout {
        id
        breakdown {
          gross
          net
          tax
        }
        worksheet {
          expectedUnitAmount
          unitAmount
          contract {
            pro {
              firstName
              lastName
            }
            recruitment {
              id
              client {
                firstName
                lastName
                business {
                  name
                }
              }
            }
          }
          payroll {
            period {
              end
              start
            }
          }
        }
        transfer {
          id
          status
        }
        createdAt
      }
      invoice {
        id
        invoiceDate
        invoiceNumber
        isFullyPaid
        canceled
        sent
        recipient {
          name
          email
          phoneNumber
        }
      }
      createdAt
    }
  }
`;

const GET_NEXT_INVOICE_PAYMENT = gql`
  query GetNextInvoicePayment($invoiceId: String!) {
    nextInvoicePayment(invoiceId: $invoiceId) {
      total
    }
  }
`;

export default function Page() {
  const history = useHistory();

  const { invoiceId } = useParams<{ invoiceId: string }>();

  const { data, loading, error, refetch } = useQuery<{
    contractPayoutReimbursementsByInvoice: ContractPayoutReimbursement[];
  }>(GET_CONTRACT_PAYOUT_REIMBURSEMENTS, {
    variables: { invoiceId },
  });

  const nextInvoicePaymentResponse = useQuery<{
    nextInvoicePayment: {
      total: number;
    };
  }>(GET_NEXT_INVOICE_PAYMENT, {
    variables: { invoiceId },
  });

  const [sendInvoice, sendInvoiceResponse] = useMutation(SEND_INVOICE, {
    variables: { invoiceId },
    onCompleted: () => {
      refetch();
    },
  });

  const [cancelInvoice, cancelInvoiceResponse] = useMutation(CANCEL_INVOICE, {
    variables: { invoiceId },
    onCompleted: () => {
      refetch();
    },
  });

  if (loading || nextInvoicePaymentResponse.loading) {
    return <Loading />;
  }

  if (error || nextInvoicePaymentResponse.error) {
    return <RequestFailed />;
  }

  const invoice = data?.contractPayoutReimbursementsByInvoice[0].invoice;

  return (
    <>
      <BackButton />

      <div className='grid grid-cols-3 gap-4'>
        <div className='col-span-2 flex flex-col gap-8'>
          <div className='flex flex-col gap-8 p-6 bg-white border'>
            <div className=''>
              <h2 className='text-primary-500 text-lg font-medium m-0'>
                Invoice Payouts
              </h2>
              <p className='m-0 text-primary-300 text-sm'>
                Shows a list of payouts for this invoice
              </p>
            </div>
            <TableContainer>
              <Table className='border'>
                <Thead className='bg-gray-50'>
                  <Tr>
                    <Th className='border'>Date Created</Th>
                    <Th className='border'>Pro</Th>
                    <Th className='border'>Gross</Th>
                    <Th className='border'>Net</Th>
                    <Th className='border'>Tax</Th>
                    <Th className='border'>Period</Th>
                    <Th className='border'>Worksheet</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {loading && (
                    <Tr>
                      <Td colSpan={6} className='border'>
                        <div className='w-full flex justify-center items-center min-h-[80px]'>
                          <p>
                            Loading <FaSyncAlt />
                          </p>
                        </div>
                      </Td>
                    </Tr>
                  )}
                  {!data?.contractPayoutReimbursementsByInvoice.length && (
                    <Tr>
                      <Td colSpan={6} className='border'>
                        <div className='w-full flex justify-center items-center min-h-[80px]'>
                          <p>No data available</p>
                        </div>
                      </Td>
                    </Tr>
                  )}
                  {data?.contractPayoutReimbursementsByInvoice.map(
                    (reimbursement) => {
                      const payout = reimbursement.payout;
                      return (
                        <Tr key={payout.id}>
                          <Td className='border'>
                            {new Date(payout.createdAt).toLocaleDateString()}
                          </Td>
                          <Td className='border'>
                            {payout.worksheet.contract.pro.firstName}{" "}
                            {payout.worksheet.contract.pro.lastName}
                          </Td>
                          <Td className='border'>
                            {formatAsNaira(payout.breakdown.gross)}
                          </Td>
                          <Td className='border'>
                            {formatAsNaira(payout.breakdown.net)}
                          </Td>
                          <Td className='border'>
                            {payout.breakdown.tax
                              ? formatAsNaira(payout.breakdown.tax)
                              : "N/A"}
                          </Td>
                          <Td className='border'>
                            {new Date(
                              payout.worksheet.payroll.period.start
                            ).toLocaleDateString()}{" "}
                            -{" "}
                            {new Date(
                              payout.worksheet.payroll.period.end
                            ).toLocaleDateString()}
                          </Td>
                          <Td className='border'>
                            {(
                              (payout.worksheet.unitAmount /
                                payout.worksheet.expectedUnitAmount) *
                              100
                            ).toFixed(0)}
                            %{" "}
                            <span className='text-gray-500'>
                              ({payout.worksheet.unitAmount}/
                              {payout.worksheet.expectedUnitAmount})
                            </span>{" "}
                          </Td>
                        </Tr>
                      );
                    }
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className='col-span-1'>
          <div className='flex flex-col gap-8 p-6 bg-white border'>
            <div className='flex justify-between'>
              <div className=''>
                <h2 className='text-primary-500 text-lg font-medium m-0'>
                  Invoice Details
                </h2>
                <p className='m-0 text-primary-300 text-sm'>
                  Shows details of this invoice
                </p>
              </div>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='invoice options'
                  icon={<FaEllipsisV />}
                  isLoading={
                    sendInvoiceResponse.loading || cancelInvoiceResponse.loading
                  }
                />
                <MenuList>
                  <MenuItem
                    icon={<FaEye />}
                    onClick={() => history.push(`/invoices/${invoiceId}`)}
                  >
                    View
                  </MenuItem>
                  <MenuItem
                    isDisabled={invoice?.sent || invoice?.canceled}
                    icon={<FaPaperPlane />}
                    onClick={() => sendInvoice()}
                  >
                    {invoice?.sent ? "Sent" : "Send"}
                  </MenuItem>
                  <MenuItem
                    icon={<FaTrashAlt className='!text-red-500' />}
                    onClick={() => cancelInvoice()}
                    isDisabled={invoice?.canceled}
                  >
                    {invoice?.canceled ? "Canceled" : "Cancel"}
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>

            <Divider />
            {invoice && (
              <div className='grid grid-cols-[1fr,2fr] gap-2 w-full'>
                <p className='m-0 text-primary-300 text-sm'>ID</p>
                <p className='m-0 text-primary-500 text-sm font-medium text-right'>
                  {invoiceId}
                </p>

                <p className='m-0 text-primary-300 text-sm'>Invoice Date</p>
                <p className='m-0 text-primary-500 text-sm font-medium text-right'>
                  {new Date(invoice.invoiceDate).toDateString()}
                </p>

                <p className='m-0 text-primary-300 text-sm'>Invoice Number</p>
                <p className='m-0 text-primary-500 text-sm font-medium text-right'>
                  #{invoice.invoiceNumber}
                </p>

                <p className='m-0 text-primary-300 text-sm'>Status</p>
                <div className='flex justify-end'>
                  {invoice.canceled ? (
                    <Badge colorScheme='red' variant='solid'>
                      Canceled
                    </Badge>
                  ) : invoice.isFullyPaid ? (
                    <Badge colorScheme='green' variant='solid'>
                      Paid
                    </Badge>
                  ) : (
                    <Badge colorScheme='yellow' variant='solid'>
                      Pending
                    </Badge>
                  )}
                </div>

                <p className='m-0 text-primary-300 text-sm'>Recipient Name</p>
                <p className='m-0 text-primary-500 text-sm font-medium text-right'>
                  {invoice.recipient.name}
                </p>

                <p className='m-0 text-primary-300 text-sm'>Recipient Email</p>
                <p className='m-0 text-primary-500 text-sm font-medium text-right'>
                  {invoice.recipient.email}
                </p>

                <p className='m-0 text-primary-300 text-sm'>Recipient Phone</p>
                <p className='m-0 text-primary-500 text-sm font-medium text-right'>
                  {invoice.recipient.phoneNumber}
                </p>

                <p className='m-0 text-primary-300 text-sm'>Total</p>
                <p className='m-0 text-primary-500 text-sm font-medium text-right'>
                  {nextInvoicePaymentResponse.data
                    ? formatAsNaira(
                        nextInvoicePaymentResponse.data.nextInvoicePayment.total
                      )
                    : "N/A"}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
