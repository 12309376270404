import Table, { ColumnsType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { Job } from "../../../types";
import Badge from "../../../_components/Badge";
import { UnAssignedTableRow } from "../types";

export interface UnassignedTableProps {
  rows: UnAssignedTableRow[];
  orderCode: string;
  selectable: TableRowSelection<UnAssignedTableRow>;
  nestedSelectable: {
    onSelectAll: (
      record: UnAssignedTableRow & { key: string },
      selected: boolean
    ) => void;
    onSelect: (
      record: UnAssignedTableRow & { key: string },
      jobRecord: Job & { key: string },
      selected: boolean
    ) => void;
    selectedRowKeys: string[];
  };
  selected?: string;
}

export const UnassignedTable: FC<UnassignedTableProps> = ({
  rows,
  orderCode,
  selectable,
  selected,
  nestedSelectable,
}) => {
  const history = useHistory();

  const columns: ColumnsType<UnAssignedTableRow & { key: string }> = [
    {
      title: "Category",
      dataIndex: "category",
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
      render: (text, record) => (
        <p className="a-orderDetail__tableText">{record.category.name}</p>
      ),
      width: "25%",
      sorter: (a, b) => {
        if (a.category.name > b.category.name) {
          return -1;
        }

        if (a.category.name < b.category.name) {
          return 1;
        }

        return 0;
      },
    },
    {
      title: "Tasks",
      dataIndex: "taskCount",
      render: (text, record) => (
        <p className="a-orderDetail__tableText">{record.taskCount}</p>
      ),
      width: "10%",
      sorter: (a, b) => a.taskCount - b.taskCount,
    },
    {
      title: "Location",
      dataIndex: "",
      width: "45%",
      sorter: (a, b) => {
        if (a.location > b.location) {
          return -1;
        }

        if (a.location < b.location) {
          return 1;
        }

        return 0;
      },
      render: (text, record) => (
        <p className="a-orderDetail__tableText">{record.location}</p>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      width: "20%",
      render: (text: any, record) => {
        return (
          <button
            className="a-orderDetail__tableButton"
            disabled={record.key !== selected}
            onClick={() => {
              history.push(
                `/orders/${orderCode}/assignments/create/selectTradesman?locationId=${record.jobs[0].contact.location.id}`
              );
            }}
          >
            Assign
          </button>
        );
      },
    },
  ];

  return (
    <div className="o-orderDetail__tableWrapper">
      <Table
        className="o-orderDetail__table"
        rowSelection={selectable}
        indentSize={15}
        expandable={{
          expandedRowRender: (record) => {
            const columns: ColumnsType<Job & { key: string }> = [
              {
                title: "Job Code",
                dataIndex: "jobCode",
                sortDirections: ["ascend", "descend", "ascend"],
                defaultSortOrder: "descend",
                render: (text, record) => (
                  <p className="a-orderDetail__nestedTableText">
                    {record.jobCode}
                  </p>
                ),
                width: "14%",
              },
              {
                title: "Task Name",
                dataIndex: "taskName",
                render: (text, record) => (
                  <p className="a-orderDetail__nestedTableText">
                    {record.custom?.name || record.task?.name || ""}
                  </p>
                ),
                width: "36%",
              },
              {
                title: "Type",
                render: (text, record) => (
                  <Badge
                    size="small"
                    inverted
                    type={record.custom ? "progress" : "basic"}
                    text={record.custom ? "custom" : "default"}
                  />
                ),
                width: "15%",
              },
              {
                title: "Status",
                dataIndex: "status",
                render: (text, record) => (
                  <p className="a-orderDetail__nestedTableText">
                    {record.status}
                  </p>
                ),
                width: "10%",
              },
              {
                title: "",
                dataIndex: "",
                render: (text, record) => (
                  <button
                    className="a-orderDetail__link"
                    onClick={() => {
                      history.push(`/orders/job/${record.jobCode}`);
                    }}
                  >
                    view
                  </button>
                ),
                width: "25%",
              },
            ];

            return (
              <Table
                className="o-orderDetail__nestedTable"
                pagination={false}
                columns={columns}
                rowSelection={{
                  onSelectAll: (selected) => {
                    nestedSelectable.onSelectAll(record, selected);
                  },
                  onSelect: (jobRecord, selected) => {
                    nestedSelectable.onSelect(record, jobRecord, selected);
                  },
                  selectedRowKeys: nestedSelectable.selectedRowKeys,
                }}
                dataSource={record.jobs.map((job) => {
                  return {
                    key: job.id,
                    ...job,
                  };
                })}
              />
            );
          },
        }}
        pagination={false}
        columns={columns}
        dataSource={rows.map((data) => {
          return {
            key: `${data.location}${data.category.name}`,
            ...data,
          };
        })}
      />
    </div>
  );
};
