import React, { FC } from "react";
import StatisticCard from "./StatisticCard";
import { Message } from "semantic-ui-react";
import { BookOutlined, UserOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { GET_CLIENT_STATISTICS, GET_ORDER_STATISTICS } from "../graphql/query";

export const Overview: FC = () => {
  const orderStatistics = useQuery(GET_ORDER_STATISTICS);
  const clientStatistics = useQuery(GET_CLIENT_STATISTICS);

  if (orderStatistics.loading || clientStatistics.loading) {
    return <div>loading...</div>;
  }

  if (orderStatistics.error || clientStatistics.error) {
    return (
      <Message negative>
        <Message.Header>Error loading statistics</Message.Header>
      </Message>
    );
  }

  return (
    <div className="o-overviewStats__wrapper">
      <StatisticCard
        title="New Clients"
        backgroundColor="#ED6D5C"
        icon={
          <UserOutlined
            style={{
              fontSize: "24px",
            }}
          />
        }
        count={clientStatistics.data.getClientStatistics.data.new}
      />
      <StatisticCard
        title="New Orders"
        backgroundColor="#F08604"
        icon={
          <BookOutlined
            style={{
              fontSize: "24px",
            }}
          />
        }
        count={orderStatistics.data.getOrderStatistics.data.new}
      />
      <StatisticCard
        title="Total Clients"
        backgroundColor="#28B3A4"
        icon={
          <UserOutlined
            style={{
              fontSize: "24px",
            }}
          />
        }
        count={clientStatistics.data.getClientStatistics.data.all}
      />
      {/* <StatisticCard
        title="Total Orders"
        backgroundColor="red"
        icon={<Icon name="user"></Icon>}
        count={orderStatistics.data.getOrderStatistics.data.all}
      /> */}
    </div>
  );
};
