import React, { FC, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Icon,
  Grid,
  Message,
} from "semantic-ui-react";
import { types } from "../../../service";
import { useMutation } from "@apollo/client";
import { EDIT_TRADESMAN } from "../../../graphql/query";
const { Field } = Form;

export const EditTradesmanModal: FC<types.EditInfoProps> = ({
  firstname,
  lastname,
  propEmail,
  userId,
  refresh,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(firstname);
  const [lastName, setLastName] = useState<string>(lastname);
  const [email, setEmail] = useState<string>(propEmail);
  const [success, setSuccess] = useState<Boolean>(false);
  const [error, setError] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [editForm, { loading }] = useMutation(EDIT_TRADESMAN);

  const close = () => {
    setOpen(false);
    setSuccess(false);
  };
  const clearMessage = () => {
    setSuccess(false);
    setError(false);
  };

  useEffect(() => {
    if (success) {
      setOpen(false);
      clearMessage();
      setSuccess(false);
      refresh();
    }
  }, [success, refresh]);

  return (
    <Modal
      className="o-admin__edit-modal"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="mini"
      trigger={
        <Button
          style={{ background: "#003952", color: "#ffffff" }}
          icon
          labelPosition="right"
          size="medium"
          onClick={() => {
            setOpen(true);
          }}
          {...rest}
        >
          <Icon name="pencil alternate"></Icon>
          Edit
        </Button>
      }
    >
      <Modal.Header>Edit Profile</Modal.Header>
      <Modal.Content>
        <Grid centered>
          <Grid.Column width="16">
            {error && <Message error header="Error" content={errorMessage} />}
            {success && (
              <Message
                success
                header="Update was successful"
                content="User has been successfully updated"
              />
            )}
            <Form>
              <Field>
                <label>First Name</label>
                <Input
                  placeholder="Enter first name"
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    clearMessage();
                  }}
                />
                <label>Last Name</label>
                <Input
                  placeholder="Enter first name"
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    clearMessage();
                  }}
                />
                <label>Email</label>
                <Input
                  placeholder="Enter email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    clearMessage();
                  }}
                />
              </Field>
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          inverted
          onClick={() => {
            close();
          }}
        >
          No
        </Button>
        {loading ? (
          <Button loading primary>
            Loading
          </Button>
        ) : (
          <Button
            color="blue"
            onClick={(e) => {
              e.preventDefault();
              editForm({
                variables: {
                  data: {
                    id: userId,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                  },
                },
              })
                .then((res) => {
                  res.data.editTradesman.status === true && setSuccess(true);
                })
                .catch((err) => {
                  console.log(err);
                  setError(true);
                  setErrorMessage(err.error);
                });
            }}
          >
            Save
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
