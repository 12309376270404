import { useLazyQuery } from "@apollo/client";
import React, { FC } from "react";
import Input from "@laborhack/input";
import Button from "@laborhack/custom-button";
import { Icon } from "semantic-ui-react";
import { GET_CLIENT_BY_EMAIL } from "../../graphql/query";
import RequestFailed from "../../_components/RequestFailed";

import styles from "./styles.module.scss";
import Joi from "@hapi/joi";

interface IClient {
  email: string;
  phoneNumber: string;
  name: string;
  id: string;
  businessId?: string;
}

export interface SelectClientProps {
  onChange: (params: {clientId?: string, businessId?: string}) => void;
}

export const SelectClient: FC<SelectClientProps> = ({ onChange }) => {
  const [selectedClient, setSelectedClient] = React.useState<IClient>();

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [email, setEmail] = React.useState<string>("");

  const handleSelectClient = (client?: IClient) => {
    setSelectedClient(client);
    onChange({clientId: client?.id, businessId: client?.businessId});
  };

  const [getClientByEmail, { loading }] = useLazyQuery(GET_CLIENT_BY_EMAIL, {
    onCompleted: (data) => {
      handleSelectClient({
        id: data.clientByEmail.id,
        name: `${data.clientByEmail.firstName} ${data.clientByEmail.lastName}`,
        phoneNumber: data.clientByEmail.phoneNumber,
        email: data.clientByEmail.email,
        ...(data.clientByEmail.businessId && {businessId: data.clientByEmail.businessId})
      });
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });

  if (errorMessage) {
    return (
      <RequestFailed
        errorMessage={errorMessage}
        onRetry={() => setErrorMessage(undefined)}
      />
    );
  }

  return (
    <div>
      {!selectedClient ? (
        <div className={styles.emailInput}>
          <Input
            type={"email"}
            value={email}
            onChange={(value, isValid) => {
              setEmail(value);
            }}
            validate={{
              check: (value) => {
                return !Joi.string()
                  .email({
                    minDomainSegments: 2,
                    tlds: { allow: false },
                  })
                  .validate(value).error;
              },
              message: "Invalid email address",
            }}
          />
          <Button
            className={styles.getClientButton}
            loading={loading}
            onClick={() => {
              getClientByEmail({ variables: { email } });
            }}
          >
            Get Client
          </Button>
        </div>
      ) : (
        <div className={styles.clientInfo}>
          <div className={styles.clientName}>
            <h4>
              {selectedClient.name} <Icon name='check circle' />
            </h4>
            <p>{selectedClient.email}</p>
          </div>
          <div className={styles.actions}>
            <Icon
              name='trash'
              color='red'
              onClick={() => {
                handleSelectClient();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
