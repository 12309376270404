import React, { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import SingleQuotation from "./single-quotation";

const Quotations: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <SingleQuotation />
      </Route>
    </Switch>
  );
};

export default Quotations;
