import { useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { Tradesman } from "../../types";
import { TradesmanSelectionItem } from "../orders/components/TradesmanSelectionItem";
import { GET_TRADESMAN_WITH_AVAILABILITY } from "../orders/graphql/query";
import Button from "@laborhack/custom-button";
import { Icon } from "semantic-ui-react";
import RequestFailed from "../../_components/RequestFailed";
import styles from "./SelectPro.module.scss";

export interface SelectOrderProProps {
  existingPros: Tradesman[];
  selectedCategory: string;
  scheduledDate: Date;
  onProSelected: (proId: string) => void;
  loading?: boolean;
}

export const SelectOrderPro: FC<SelectOrderProProps> = ({
  scheduledDate,
  selectedCategory,
  onProSelected,
  loading: addProLoading,
  existingPros,
}) => {
  const [selectedProId, setSelectedProId] = useState<string>();

  const { loading, data, error } = useQuery(GET_TRADESMAN_WITH_AVAILABILITY, {
    variables: {
      data: {
        date: scheduledDate.toISOString(),
      },
    },
  });

  if (loading) {
    return <Icon name='spinner' size='large' loading />;
  }

  if (error) {
    return <RequestFailed />;
  }

  const existingProIds = new Set(existingPros.map((pro) => pro.id));

  const filteredProList: Tradesman[] = data.getTradesmenWithAvailabilityByCategory.data.filter(
    ({ id }: Tradesman) => {
      return !existingProIds.has(id);
    }
  );

  return (
    <div className={styles.selectProWrapper}>
      <h3>Select Pro</h3>
      <div className={styles.listHeader}>
        <p>name</p>
        <p>category</p>
        <p>level</p>
        <p>availability</p>
      </div>
      <div className={styles.list}>
        {filteredProList.length ? (
          filteredProList.map((tradesman: Tradesman) => {
            const selected = selectedProId === tradesman.id;

            return (
              <TradesmanSelectionItem
                key={tradesman.id}
                tradesman={tradesman}
                selected={selected}
                onClick={() => {
                  setSelectedProId(tradesman.id);
                }}
              />
            );
          })
        ) : (
          <div className={styles.emptyList}>
            <p>No Pros Available</p>
          </div>
        )}
      </div>
      <div className='mt-8'>
        <Button
          size='big'
          variant='success'
          fullWidth
          disabled={!selectedProId}
          loading={addProLoading}
          onClick={() => selectedProId && onProSelected(selectedProId)}
        >
          Add To Assignment
        </Button>
      </div>
    </div>
  );
};
