import {
  createSlice,
  createAsyncThunk,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import { IClientRegisterState } from "./../components/client/types";

const initialState: IClientRegisterState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  whatsappNumber: "",
  imageUrl:
    "https://res.cloudinary.com/laborhack/image/upload/v1600258815/laborhack/user-img_w2tloh.jpg",
  streetName: "",
  buildingNumber: "",
  area: "",
  location: "",
  city: "",
  landmark: "",
  status: "idle",
  error_message: "",
  success_message: "",
};

export const SubmitClientsForm = createAsyncThunk(
  "register/createClient",
  async (
    data: { mutation: any },
    {
      dispatch,
      getState,
    }: { dispatch: ThunkDispatch<any, any, AnyAction>; getState: any }
  ) => {
    const { mutation } = data;
    const { registerClient }: any = getState();

    try {
      const { data } = await mutation({
        variables: {
          data: {
            firstName: registerClient.firstName,
            lastName: registerClient.lastName,
            imageUrl: registerClient.imageUrl,
            email: registerClient.email,
            phoneNumber: `+234${registerClient.phoneNumber}`,
            // whatsAppNumber: registerClient.whatsappNumber,
            location: {
              streetName: registerClient.streetName,
              buildingNumber: registerClient.buildingNumber,
              area: registerClient.area,
              city: registerClient.city,
              landmark: registerClient.landmark,
              location: registerClient.location,
            },
          },
        },
      });
      if (data) {
        dispatch(setSuccessMessage(data.createClientFromAdmin));
      }
    } catch (error) {
      // dispatch error message
      dispatch(setErrorMessage((error as Error).message));
    }
  }
);

export const registerClientSlice = createSlice({
  name: "registerClient",
  initialState,
  reducers: {
    setDFirstname: (state, action) => {
      state.firstName = action.payload;
    },
    setDlastName: (state, action) => {
      state.lastName = action.payload;
    },
    setDPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setDEmail: (state, action) => {
      state.email = action.payload;
    },
    setImageUrl: (state, action) => {
      state.imageUrl = action.payload;
    },
    setWhatsAppNumber: (state, action) => {
      state.whatsappNumber = action.payload;
    },
    setDstreetName: (state, action) => {
      state.streetName = action.payload;
    },
    setDLocation: (state, action) => {
      state.location = action.payload;
    },
    setDbuildingNumber: (state, action) => {
      state.buildingNumber = action.payload;
    },
    setDCity: (state, action) => {
      state.city = action.payload;
    },
    setDArea: (state, action) => {
      state.area = action.payload;
    },
    setDLandMark: (state, action) => {
      state.landmark = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.success_message = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error_message = action.payload;
    },
  },
  extraReducers: {
    [SubmitClientsForm.fulfilled.type]: (state, action) => {
      state.status = "idle";
    },
    [SubmitClientsForm.pending.type]: (state, action) => {
      state.status = "pending";
    },
  },
});

export const {
  setDFirstname,
  setDlastName,
  setDPhoneNumber,
  setDEmail,
  setImageUrl,
  setWhatsAppNumber,
  setDArea,
  setDCity,
  setDstreetName,
  setDbuildingNumber,
  setDLocation,
  setDLandMark,
  setSuccessMessage,
  setErrorMessage,
} = registerClientSlice.actions;

export const ClientRegisterAction = registerClientSlice.actions;

export const selectFirstName = (state: any) => state.registerClient.firstName;
export const selectLastName = (state: any) => state.registerClient.lastName;
export const selectEmail = (state: any) => state.registerClient.email;
export const selectPhoneNumber = (state: any) =>
  state.registerClient.phoneNumber;
export const selectSuccessMessage = (state: any) =>
  state.registerClient.success_message;
export const selectErrorMessage = (state: any) =>
  state.registerClient.error_message;
export const selectSubmissionStatus = (state: any) =>
  state.registerClient.status;

export const RegisterClientSelectors = {
  selectFirstName,
  selectLastName,
  selectSuccessMessage,
  selectErrorMessage,
  selectSubmissionStatus,
};

export const ClientRegReducer = registerClientSlice.reducer;
