import { useMutation, useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { Icon, Message } from "semantic-ui-react";

import { components as LayoutComponents } from "../../layout";
import { TransferStatus } from "../../../types";
import { REVIEW_REFUND_REQUEST } from "../graphql/mutation";
import { GET_REFUND_REQUEST } from "../graphql/query";
import { IRefund } from "../types";
import RefundInvoiceDetails from "./RefundInvoiceDetails";
import Island from "../../../_components/Island";
import {
  calculateLaborHackFee,
  calculateMaterialsAndLabor,
  calculateTotal,
} from "../../../helpers/helpers";
import { GET_ORDER_INVOICES } from "../../orders/graphql/query";
import {
  InvoiceType,
  IInvoice as Invoice,
  TransactionStatus,
} from "../../../types";

const { Trail } = LayoutComponents;

const RefundRequestView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [description, setDescription] = useState<string>();

  const [reviewRefundRequest, reviewRefundRequestResponse] = useMutation(
    REVIEW_REFUND_REQUEST,
    {
      onError: () => {},
    }
  );

  const getRefundRequest = useQuery(GET_REFUND_REQUEST, {
    variables: {
      id,
    },
  });

  const data: IRefund = getRefundRequest.data?.getRefundRequestById.data;

  const getOrderMaterialInvoicesResponse = useQuery(GET_ORDER_INVOICES, {
    variables: {
      orderId: data?.order.id,
      type: InvoiceType.MATERIALS,
    },
  });

  const invoices: Invoice[] | undefined =
    getOrderMaterialInvoicesResponse.data?.getInvoicesByOrder;

  const paidInvoices = invoices?.filter(({ isFullyPaid }) => isFullyPaid);

  const assignments = paidInvoices?.flatMap(({ assignments }) => assignments);

  const jobs = assignments?.flatMap(({ jobs }) => jobs) || [];

  const {
    materials: totalMaterialsCost,
    labor: totalLaborCost,
  } = calculateMaterialsAndLabor(jobs);

  const laborHackFee = calculateLaborHackFee(
    totalMaterialsCost,
    totalLaborCost
  );

  const invoiceTotal = calculateTotal(totalMaterialsCost, laborHackFee);

  if (
    getRefundRequest.loading ||
    !getRefundRequest.data ||
    getOrderMaterialInvoicesResponse.loading
  ) {
    return <div>loading...</div>;
  }

  let err;

  if (getRefundRequest.error) {
    err = getRefundRequest.error.message;
  }

  if (getOrderMaterialInvoicesResponse.error) {
    err = getOrderMaterialInvoicesResponse.error.message;
  }

  if (reviewRefundRequestResponse.error) {
    err = reviewRefundRequestResponse.error.message;
  }

  return (
    <Island
      header={
        <Trail
          root='Refund Request'
          child={id.substring(0, 8)}
          {...(!!data && {
            label: {
              text: data.transfer.status.toString().replace("_", " "),
              color: "#003952",
            },
          })}
        ></Trail>
      }
      text={`Review refund to client involved on this order`}
    >
      {err && (
        <Message negative>
          <Message.Header>{err}</Message.Header>
        </Message>
      )}
      <div className='o-singlePayment__wrapper'>
        <div className='o-singlePayment__container'>
          <div className='o-singlePayment__transferForm'>
            <h2>send funds to:</h2>
            <div className='m-singlePayment__transferFormInput'>
              <Icon name='send'></Icon>
              <input type='text' value={data.order.client.name} readOnly />
            </div>
            <div className='m-singlePayment__transferFormInput'>
              <Icon name='align left'></Icon>
              <textarea
                placeholder=''
                value={description || data.reason}
                readOnly={!!data.transfer.amount}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            <div className='m-singlePayment__transferFormInput'>
              <Icon name='money bill alternate outline'></Icon>
              <input
                type='number'
                placeholder='Enter Amount'
                value={data.transfer.amount}
                step={100}
                min={100}
                readOnly={!!data.transfer.amount}
              />
            </div>
            <div className='m-singlePayment__transferFormActions'>
              <button
                disabled={
                  reviewRefundRequestResponse.data ||
                  data.transfer.status !== TransferStatus.PENDING
                }
                className='a-singlePayment__transferFormButton'
                onClick={() => {
                  reviewRefundRequest({
                    variables: {
                      id,
                    },
                  });
                }}
              >
                {reviewRefundRequestResponse.loading ? (
                  <Icon name='spinner' loading></Icon>
                ) : reviewRefundRequestResponse.data ||
                  data.transfer.status !== TransferStatus.PENDING ? (
                  <>
                    {data.transfer.status.toString().replace("_", " ")}{" "}
                    <Icon name='check'></Icon>
                  </>
                ) : (
                  "Review Request"
                )}
              </button>
            </div>
          </div>
        </div>
        <div className='o-singlePayment__container'>
          <RefundInvoiceDetails
            materialsCost={totalMaterialsCost}
            totalCost={invoiceTotal}
            laborHackFee={laborHackFee}
          />
        </div>
      </div>
    </Island>
  );
};

export default RefundRequestView;
