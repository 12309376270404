import { useQuery } from "@apollo/client";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
import {
  extractItemsFromRelayStylePaginationResponse,
  formatRelativeTime,
} from "../../helpers/helpers";

import { components as LayoutComponents } from "../../components/layout";
import { LIST_ASSIGNMENT_PAYMENTS } from "./graphql/query";
import { AssignmentPayment, TransferStatus } from "./types";
import Island from "../../_components/Island";
import { RelayStylePaginatedResponse } from "../../types";
import RequestFailed from "../../_components/RequestFailed";
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Switch,
  VStack,
} from "@chakra-ui/react";

const { Trail, CustomTable, CustomLabel } = LayoutComponents;

const PaymentRequests: FC = () => {
  const history = useHistory();

  const [search, setSearch] = useState<string>();

  const [query, setQuery] = useState<string>();

  const handleSearchQuery = useMemo(() => {
    return debounce((value: string) => {
      console.log(value);
      setQuery(value);
    }, 500);
  }, []);

  const [filterStatus, setFilterStatus] = useState<string>();

  const [
    showOlderTransactions,
    setShowOlderTransactions,
  ] = React.useState<boolean>(false);

  const { data, fetchMore, loading, error } = useQuery<{
    assignmentPayments: RelayStylePaginatedResponse<AssignmentPayment>;
  }>(LIST_ASSIGNMENT_PAYMENTS, {
    variables: {
      first: 10,
      after: null,
      filters: {
        transferStatus: filterStatus,
        showOlderTransactions: showOlderTransactions,
      },
      ...(query && {
        search: {
          type: "ORDER_CODE",
          value: query,
        },
      }),
    },
  });

  const columns: ColumnsType<AssignmentPayment & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.id.substring(0, 8)}</p>;
      },
      width: "5%",
    },
    {
      title: "Order Code",
      render: (text, record) => {
        return <p>{record.order.orderCode}</p>;
      },
      width: "5%",
    },
    {
      title: "Purpose",
      render: (text, record) => {
        return (
          <p
            style={{
              textTransform: "capitalize",
            }}
          >
            {record.type.toString().toLowerCase()}
          </p>
        );
      },
      width: "10%",
    },
    {
      title: "To",
      render: (text, record) => {
        return (
          <p>
            {record.tradesman
              ? `${record.tradesman.firstName} ${record.tradesman.lastName}`
              : "Unassigned"}
          </p>
        );
      },
      width: "10%",
    },
    {
      title: "Amount",
      render: (text, record) => {
        return (
          <p>
            {record.transfer.amount ? `NGN ${record.transfer.amount}` : "N/A"}
          </p>
        );
      },
      width: "10%",
    },
    {
      title: "Status",
      render: (text, record) => {
        const color = {
          r: 0,
          g: 0,
          b: 0,
        };

        switch (record.transfer.status) {
          case TransferStatus.PENDING:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case TransferStatus.AWAITING_APPROVAL:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case TransferStatus.CONFIRMED:
            color.r = 86;
            color.g = 189;
            color.b = 102;
            break;
          case TransferStatus.FAILED:
            color.r = 244;
            color.g = 105;
            color.b = 86;
            break;

          default:
            break;
        }

        return (
          <CustomLabel
            color={color}
            text={record.transfer.status.toString().replace("_", " ")}
          />
        );
      },
      width: "10%",
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <p>{formatRelativeTime(new Date(record.createdAt).getTime())}</p>
        );
      },
      width: "10%",
    },
  ];

  if (error) return <RequestFailed />;

  const getMore = () => {
    fetchMore({
      variables: {
        first: 10,
        after: data?.assignmentPayments.pageInfo.endCursor,
        filters: {
          transferStatus: filterStatus,
          showOlderTransactions: showOlderTransactions,
        },
      },
    });
  };

  return (
    <Island
      header={<Trail root='All Requests'></Trail>}
      text='Shows a list of all fund transfer requests'
    >
      <HStack justifyContent='space-between' marginY={4}>
        <FormControl>
          <InputGroup w='sm'>
            <Input
              type='text'
              placeholder='Search By Order Code'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                handleSearchQuery(e.target.value);
              }}
            />
            {loading && (
              <InputRightElement>
                <Spinner size='sm' />
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Filter By</FormLabel>
            <Select
              value={filterStatus || "all"}
              onChange={(e) => {
                if (e.target.value === "all") {
                  setFilterStatus(undefined);
                } else {
                  setFilterStatus(e.target.value);
                }
              }}
              placeholder='Select option'
            >
              <option value={"all"}>All</option>
              {Object.entries(TransferStatus).map(([key, value]) => {
                return (
                  <option key={key} value={value}>
                    {key.replace("_", " ")}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl display='flex' alignItems='start'>
            <FormLabel htmlFor='onboarded-toggle' mb='0'>
              Include Transactions From over 6 Months Ago
            </FormLabel>
            <Switch
              onChange={(e) => setShowOlderTransactions(e.target.checked)}
              isChecked={showOlderTransactions}
            />
          </FormControl>
        </VStack>
      </HStack>

      <CustomTable
        onRow={(record: any, index: any) => {
          return {
            onClick: () => {
              history.push("/payments/requests/" + record.id);
            },
          };
        }}
        columns={columns}
        data={
          data
            ? extractItemsFromRelayStylePaginationResponse(
                data.assignmentPayments
              ).map((assignment: AssignmentPayment) => {
                return {
                  key: assignment.id,
                  ...assignment,
                };
              })
            : []
        }
        loading={loading}
        handleTableChange={() => {}}
        hasMore={data?.assignmentPayments.pageInfo.hasNextPage}
        fetchMore={getMore}
      ></CustomTable>
    </Island>
  );
};

export default PaymentRequests;
