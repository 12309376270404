import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { ProTradeRequirement } from "../../common/types/pro-trade-requirement";
import { useTradeRequirements } from "../../hooks/useTradeRequirements";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { AddResult } from "./AddResult";
import { ResultCard } from "./ResultCard";

export interface ResultsProps {
  proTradeRequirement: ProTradeRequirement;
}

export const Results = ({ proTradeRequirement }: ResultsProps) => {
  const { loading, error, tradeRequirementMap } = useTradeRequirements();

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (loading) return <Loading />;

  if (error) return <RequestFailed errorMessage={error.message} />;

  if (!tradeRequirementMap)
    return (
      <RequestFailed errorMessage="Couldn't generate trade requirement map" />
    );

  const { requirement, results } = proTradeRequirement;

  const hasAtLeastOneResultPassed = results.some((result) => result.passed);

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Result</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddResult
              proTradeRequirement={proTradeRequirement}
              template={tradeRequirementMap[requirement.id].scoringTemplate}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className='flex items-center gap-4'>
        {results.map((result) => {
          return (
            <ResultCard
              key={result.attemptId}
              proTradeRequirementId={proTradeRequirement.id}
              result={result}
              template={tradeRequirementMap[requirement.id].scoringTemplate}
            />
          );
        })}
        <IconButton
          aria-label='Add New'
          boxSize={"4em"}
          icon={<MdAdd size='36' />}
          disabled={hasAtLeastOneResultPassed}
          onClick={onOpen}
        />
      </div>
    </>
  );
};
