import React, { FC, useState } from "react";
import Input from "@laborhack/input";
import { Form } from "semantic-ui-react";
import {
  businessEmailDoesNotExist,
  emailCheck,
} from "../../../helpers/helpers";
import Button from "@laborhack/custom-button";
import RequestFailed from "../../../_components/RequestFailed";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { FieldValue } from "../../../types";

const CHANGE_EMAIL = gql`
  mutation ChangeEmail($id: String!, $newEmail: String!) {
    changeBusinessEmail(id: $id, newEmail: $newEmail) {
      id
      email
    }
  }
`;

export interface EditBusinessEmailProps {
  businessId: string;
  onSave: () => void;
}

export const EditBusinessEmail: FC<EditBusinessEmailProps> = ({
  businessId,
  onSave,
}) => {
  const [businessEmail, setBusinessEmail] = useState<FieldValue<string>>({
    value: "",
    isValid: false,
  });

  const [submissionError, setSubmissionError] = useState(false);

  const client = useApolloClient();

  const [changeEmail, { loading, error }] = useMutation(CHANGE_EMAIL, {
    onError: () => {
      setSubmissionError(true);
    },

    onCompleted: () => {
      onSave();
    },
  });

  if (submissionError) {
    return <RequestFailed errorMessage={error?.message} />;
  }

  const canSubmit = businessEmail.isValid;

  const handleSubmit = () => {
    changeEmail({
      variables: {
        id: businessId,
        newEmail: businessEmail.value,
      },
    });
  };

  return (
    <div className='p-4'>
      <h2 className='text-lg font-bold text-primary-500'>
        Change Business Email
      </h2>
      <div className='mt-8 max-w-md'>
        <Form>
          <Input
            autoComplete='email'
            required
            textLabel='New Email'
            value={businessEmail.value}
            onChange={(value: string, isValid: boolean) => {
              setBusinessEmail({ value, isValid });
            }}
            validate={{
              check: emailCheck,
              message: "Invalid Email",
            }}
            asyncValidate={{
              check: (value: string) => {
                try {
                  return businessEmailDoesNotExist(client, value);
                } catch (error) {
                  setSubmissionError(true);
                }

                return new Promise((resolve) => resolve(false));
              },
              message: "This email already exists",
            }}
            placeholder='Enter Email Address'
          />
        </Form>
        <div className='flex justify-end mt-4'>
          <Button
            loading={loading}
            disabled={!canSubmit}
            onClick={handleSubmit}
          >
            Change
          </Button>
        </div>
      </div>
    </div>
  );
};
