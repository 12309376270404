export const clientList = [
  {
    id: "1",
    name: "Husbane Jeofrey",
    email: "husbyjeo@abc.com",
    phoneNumber: "+2348022222222",
    whatsAppNumber: "+2348022222222",
    location: {
      buildingNumber: "12",
      streetName: "Babs Animashaun street",
      area: "Surulere",
      city: "Lagos",
      landmark: "Surulere",
      location: "Lekki",
    },
  },
  {
    id: "2",
    name: "Richard Jefferson",
    email: "richy@abc.com",
    category: "Electricals",
    phoneNumber: "+2348022222333",
    whatsAppNumber: "+2348022222222",
    location: {
      buildingNumber: "12",
      streetName: "Babs Animashaun street",
      area: "Surulere",
      city: "Lagos",
      landmark: "Surulere",
      location: "Lekki",
    },
  },
];

export const userDetails = {
  HEADER: "Client Details",
  NAME: "First Name: ",
  EMAIL: "Email: ",
  PHONENUMBER: "Phone Number: ",
  WHATSAPPNUMBER: "Whatsapp Number: ",
};
