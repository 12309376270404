import React from "react";
import ReactDOM from "react-dom";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./react-dates-overrides.css";
import "./index.css";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { store } from "./redux/store";
import { client } from "./graphql";
import { components as AppComponents } from "./components/app";
import * as serviceWorker from "./serviceWorker";
import ScrollToTop from "./_components/ScrollToTop/ScrollToTop";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Wrapper as GoogleMapsWrapper } from "@googlemaps/react-wrapper";

const colors = {
  brand: {
    500: "#ED6D5C",
  },
  primary: {
    100: "#F2F5F6",
    150: "#D9E1E5",
    200: "#99B0BA",
    300: "#7c95a0",
    500: "#003952",
  },
};

const theme = extendTheme({
  colors,
});

const { App } = AppComponents;

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <ChakraProvider theme={theme}>
        <GoogleMapsWrapper
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
              libraries={["places"]}
            >
          <App />
          </GoogleMapsWrapper>
        </ChakraProvider>
      </Router>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
