import { gql } from "@apollo/client";

export const NON_REFERENCED_CONTRACT_FIELDS = gql`
  fragment NonReferencedContractFields on Contract {
    id
    categoryId
    agreedRate
    proLevel
    requestedStartDate
    startDate
    endDate
    contractLength
    contractInterval
    nonBillableDates
    nonWorkingDays
    createdAt
  }
`;
