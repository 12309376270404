import React, { FC } from "react";
import Button from "@laborhack/custom-button";

import error from "../../assets/error.svg";

import styles from "./styles.module.scss";

export interface RequestFailedProps {
  onRetry?: () => void;
  errorMessage?: string;
}

export const RequestFailed: FC<RequestFailedProps> = ({
  onRetry,
  errorMessage,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <h2>Something Went Wrong!</h2>
        {errorMessage && <p className='text-center mb-4'>{errorMessage}</p>}
        <img
          src={error}
          alt='an illustration of a female construction worker holding a red flag with a caution sign beside rocks'
        />
        {onRetry && (
          <Button variant='basic' onClick={onRetry}>
            Try again
          </Button>
        )}
      </div>
    </div>
  );
};
