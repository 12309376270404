import { MatchedPro } from "./MatchedPro";
import { useMutation, useQuery } from "@apollo/client";
import clsx from "clsx";
import React, { FC } from "react";
import { useParams } from "react-router-dom";
import {
  MarketplaceJob,
  MarketplaceJobShortlistStatus,
  TransactionStatus,
} from "../../types";
import BackButton from "../../_components/BackButton";
import Field from "../../_components/Field";
import Island from "../../_components/Island";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import Button from "@laborhack/custom-button";
import { Button as SemanticUIButton, Icon } from "semantic-ui-react";
import { Modal } from "semantic-ui-react";
import PillGroup from "../../_components/PillGroup";
import Pill from "../../_components/Pill";
import {
  ADD_PRO_TO_JOB,
  MARKETPLACE_JOB,
  REMOVE_PRO_FROM_JOB,
  REPLACE_PRO_IN_JOB,
} from "@/graphql/marketplace-requests";
import { flushSync } from "react-dom";
import { isAfter } from "date-fns";
import { Alert } from "antd";
import { MarketplaceJobShortlist } from "./MarketplaceJobShortlist";
import { SelectPro } from "../../components/SelectContractPro/components/SelectPro";

export interface MarketplaceJobViewProps {}

export const MarketplaceJobView: FC<MarketplaceJobViewProps> = ({}) => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<{
    marketplaceJob: MarketplaceJob;
  }>(MARKETPLACE_JOB, {
    variables: { id },
  });

  const [showAlert, setShowAlert] = React.useState(false);
  const [showReplaceProAlert, setShowReplaceProAlert] = React.useState(false);

  const clearUpdateAlert = () => {
    setShowAlert(false);
    setShowReplaceProAlert(false);
  };

  const [showAllWorkingDays, setShowAllWorkingDays] = React.useState(false);

  const [selectProModalOpen, setSelectProModalOpen] = React.useState(false);

  /**
   * The selected pro is the pro that is currently selected for the replace pro modal
   */
  const [selectedPro, setSelectedPro] = React.useState<string | null>(null);

  const [addProToJob, addProToJobCall] = useMutation(ADD_PRO_TO_JOB, {
    onCompleted: () => {
      setSelectProModalOpen(false);
    },
  });

  const [removeProFromJob, removeProFromJobCall] = useMutation(
    REMOVE_PRO_FROM_JOB,
    {
      onCompleted: (data) => {
        setShowAlert(true);

        setTimeout(clearUpdateAlert, 3000);
      },
    }
  );

  const [replaceProInJob, replaceProInJobCall] = useMutation(
    REPLACE_PRO_IN_JOB,
    {
      onCompleted: () => {
        setSelectProModalOpen(false);
        setShowReplaceProAlert(true);

        setTimeout(clearUpdateAlert, 3000);
      },
    }
  );

  if (loading) return <Loading />;

  if (error || !data) {
    return <RequestFailed errorMessage={error?.message} />;
  }

  if (removeProFromJobCall.error) {
    return (
      <RequestFailed
        errorMessage={removeProFromJobCall.error.message}
        onRetry={removeProFromJobCall.reset}
      />
    );
  }

  const {
    trade,
    proCount,
    pros,
    description,
    schedule,
    request,
    shortlist,
  } = data.marketplaceJob;

  const unassignedShortlistAcceptances =
    shortlist?.shortlistedPros
      .filter(({ status, pro }) => {
        const isAssigned = !!pros.find(
          (assignedPro) => assignedPro.id === pro.id
        );

        return status === MarketplaceJobShortlistStatus.ACCEPTED && !isAssigned;
      })
      .map(({ pro }) => pro) || [];

  const requestedProCountMatched = pros.length >= proCount;

  const handleAddPro = (proId: string) => {
    addProToJob({
      variables: {
        jobId: id,
        proId,
      },
    });
  };

  const handleRemovePro = (proId: string) => {
    removeProFromJob({
      variables: {
        jobId: id,
        proId,
      },
    });
  };

  const handleReplacePro = (replacementProId: string) => {
    replaceProInJob({
      variables: {
        jobId: id,
        replacementProId,
        existingProId: selectedPro,
      },
    });
  };

  const maxWorkDays = 2;

  const matched = !!request.matchingFeeInvoice?.isFullyPaid;

  const completed = isAfter(new Date(), new Date(schedule.end));

  return (
    <>
      <BackButton />
      <Modal
        open={selectProModalOpen}
        closeIcon
        onClose={() => setSelectProModalOpen(false)}
        closeOnDimmerClick={false}
      >
        {addProToJobCall.error || replaceProInJobCall.error ? (
          <RequestFailed
            errorMessage={
              addProToJobCall.error?.message ||
              replaceProInJobCall.error?.message
            }
            onRetry={() => {
              addProToJobCall.reset();
              replaceProInJobCall.reset();
            }}
          />
        ) : (
          <SelectPro
            pros={unassignedShortlistAcceptances}
            onSelect={({ id }) =>
              selectedPro ? handleReplacePro(id) : handleAddPro(id)
            }
            loading={addProToJobCall.loading || replaceProInJobCall.loading}
          />
        )}
      </Modal>

      <Island>
        <div className='mb-4'>
          <h2 className='text-primary-500 text-xl font-bold m-0'>
            Marketplace Job <span className='text-primary-300'>({id})</span>
          </h2>
          <p className='m-0 text-primary-300'>View marketplace job</p>
        </div>
        {showAlert && (
          <Alert
            data-testid='pro-removed-alert'
            message='Pro removed'
            type='success'
            showIcon
            closable
            onClose={clearUpdateAlert}
          />
        )}
        {showReplaceProAlert && (
          <Alert
            data-testid='pro-replaced-alert'
            message='Pro replaced'
            type='success'
            showIcon
            closable
            onClose={clearUpdateAlert}
          />
        )}
        <div className='mt-8 w-full'>
          <div>
            <div className='mb-4'>
              <h3 className='text-lg font-semibold text-primary-500 mb-2'>
                Basic Information
              </h3>
              <div className='pl-2'>
                <Field label='Trade' value={trade.name} />
                <Field
                  label='Description'
                  value={description || "No description provided"}
                />
                <Field
                  label='Start Date'
                  value={new Date(schedule.start).toDateString()}
                />
                <Field
                  label='End Date'
                  value={new Date(schedule.end).toDateString()}
                />
                <Field
                  label='Working Days'
                  value={
                    <div className='flex w-full justify-end items-center flex-wrap'>
                      <PillGroup
                        className='justify-end'
                        {...(!showAllWorkingDays && {
                          maxCount: maxWorkDays,
                        })}
                      >
                        {schedule.workDays.map((day) => (
                          <Pill key={day}>{day}</Pill>
                        ))}
                      </PillGroup>
                      {schedule.workDays.length > maxWorkDays && (
                        <Button
                          size='small'
                          type='link'
                          className='ml-2'
                          onClick={() =>
                            setShowAllWorkingDays(!showAllWorkingDays)
                          }
                        >
                          {showAllWorkingDays ? "Hide" : "View all"}
                        </Button>
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
          <div className='mt-8'>
            <div className='mb-4'>
              <h3 className='text-lg font-semibold text-primary-500 mb-2'>
                Matched Pros (
                <span
                  className={clsx(requestedProCountMatched && "text-success")}
                >
                  {pros.length || 0}/{proCount}
                </span>
                )
              </h3>
              <div className='flex flex-col gap-2'>
                {pros.map((pro) => {
                  return (
                    <MatchedPro
                      key={pro.id}
                      pro={pro}
                      matched={matched}
                      completed={completed}
                      onRemove={() => handleRemovePro(pro.id)}
                      onReplace={() => {
                        replaceProInJobCall.reset();

                        flushSync(() => {
                          setSelectedPro(pro.id);
                        });

                        setSelectProModalOpen(true);
                      }}
                      loading={removeProFromJobCall.loading}
                    />
                  );
                })}
                {!matched && (
                  <div>
                    <SemanticUIButton
                      icon
                      onClick={() => {
                        setSelectProModalOpen(true);
                      }}
                    >
                      <Icon name='plus' />
                    </SemanticUIButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <MarketplaceJobShortlist
            job={data.marketplaceJob}
            matched={matched}
          />
        </div>
      </Island>
    </>
  );
};
