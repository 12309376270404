import { Trade } from "./common/types/trade";
import { Business } from "./pages/businesses/types";
import {
  Contract,
  ContractRecruitment,
  RecruitmentData,
} from "./pages/recruitments/types";

export interface PaginationArgs {
  first?: number;
  after?: string;
}

export type WorkingDays =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

export interface Category {
  id: string;
  name: string;
  proTitle?: string;
  requestCount: number;
  subCategoryIds: string[];
  imageUrl: string;
}

export interface PaymentProviderData {
  accountNumber: string;
  bankCode: string;
  providers: TransferRecipient[];
}
export interface TransferRecipient {
  providerId: string;
  recipientCode: string;
}

export interface PaymentProviderInformation {
  userId: string;
  defaultAccountNumber: string;
  providerData: PaymentProviderData[];
}

export interface Recipient {
  name: string;

  address: string[];

  phoneNumber: string;

  email: string;
}

export enum TransferTypes {
  HANDLING = "HANDLING", // assignment handling cost like labor, materials, transportation
  REFUND = "REFUND",
  CONTRACTOR_PAYMENT = "CONTRACTOR_PAYMENT",
}

export enum TransferStatus {
  PENDING = "PENDING",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  CONFIRMED = "CONFIRMED",
  FAILED = "FAILED",
  QUEUED = "QUEUED",
  REVERSED = "REVERSED",
}

export interface TransferRequest {
  id: string;

  recipientCode: string;

  purpose: TransferTypes;

  status: TransferStatus;

  description?: string;

  amount?: number;

  initiatedBy: {
    firstName: string;
    lastName: string;
  };

  auditedBy?: {
    firstName: string;
    lastName: string;
  };

  approvedBy?: {
    firstName: string;
    lastName: string;
  };

  transferCode?: string;

  transactionReference?: string;

  createdAt: string;
}

export enum TransactionStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export interface Payment {
  id: string;
  transactionReference: string;
  transactionDate: Date;
  status: TransactionStatus;
  amount: number;
  providerId: string;
}

export interface OrderUser {
  userId: string;
  name: string;
  phoneNumber: string;
  email: string;
}

export interface Location {
  id: string;
  buildingNumber: string;
  streetName: string;
  landmark: string;
  area: string;
  location: string;
  city: string;
}

export interface Point {
  type: string;

  coordinates: number[];
}

export interface Address {
  id: string;

  address: string;

  city: string;

  state: string;

  location: Point;

  createdAt: Date;

  updatedAt: Date;
}

export type WithoutId<T> = {
  [Property in keyof T as Exclude<Property, "id">]: T[Property];
};

export enum Status {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  ARCHIVED = "ARCHIVED",
}

export enum Availability {
  AVAILABLE = "AVAILABLE",
  TENTATIVE = "TENTATIVE",
  ENGAGED = "ENGAGED",
}

export enum JobProgress {
  NOT_STARTED = "NOT_STARTED",
  PRE_INSPECTION = "PRE_INSPECTION",
  FINAL_QUOTE = "FINAL_QUOTE",
  MATERIALS_PURCHASED = "MATERIALS_PURCHASED",
  CLIENT_INSPECTION = "CLIENT_INSPECTION",
  COMPLETED = "COMPLETED",
}

export interface JobProgressLog {
  user: string;
  update: JobProgress;
}

export interface Task {
  id: string;
  description: string;
  name: string;
  estimatedCost: number;
  requestCount: number;
  categoryId: string;
  subCategoryId: string;
  imageUrl: string;
}

export interface Tradesman {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  location: Location;
  level: number;
  imageUrl: string;
  categoryId: string;
  rewardPoints: number;
  referralCode: string;
  availability?: Availability;
}

export interface Contact {
  notes?: string;
  location: Location;
}

export interface JobCustomTask {
  name: string;
  description: string;
  resourceUrl?: string;
  subCategoryId: string;
  categoryId: string;
}

export interface InvoiceMaterial {
  name: string;
  unitPrice: number;
  quantity: number;
}

export interface InvoiceDetails {
  materials?: InvoiceMaterial[];
  labor?: number;
}

export interface Job {
  id: string;
  jobCode: string;
  custom?: JobCustomTask;
  assignedTradesman?: Tradesman;
  task?: Task;
  finalCost: number;
  status: Status;
  completedAt: number;
  payment?: Payment;
  contact: Contact;
  progressLog?: JobProgressLog[];
  invoiceDetails?: InvoiceDetails;
}

export interface LocationSchedule {
  locationId: string;
  scheduledDate: Date;
}

export interface Order {
  id: string;
  orderCode: string;
  inspectionPayment: Payment;
  assignments: TradesmanAssignment[];
  jobs: Job[];
  client: OrderUser;
  createdByUid: string;
  locationSchedules: LocationSchedule[];
  createdAt: Date;
  isEmergency: boolean;
  suggestedTradesmen?: Tradesman[];
  isCompleted?: boolean;
  isAssigned?: boolean;
}

export enum InvoiceType {
  INSPECTION = "INSPECTION",
  MATERIALS = "MATERIALS",
}

export enum PaymentProviderChannel {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
}

export interface PaymentProvider {
  id: string;
  name: string;
  logo: string;
  transactionFee?: number;
  balance?: number;
  isActive: boolean;
  channel: PaymentProviderChannel;
}

export interface InvoiceDiscount {
  id: string;
  code: string;
  description: string;
  value: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IInvoice {
  id: string;

  type: InvoiceType;

  canceled: boolean;

  sent: boolean;

  invoiceNumber: number;

  recruitment: ContractRecruitment;

  recruitmentData: RecruitmentData[];

  order: Order;

  invoiceDate: Date;

  inspectionAmount: number;

  recipient: Recipient;

  discount: number; // float below 1.0 for discount percentage

  appliedDiscount?: InvoiceDiscount;

  assignments: TradesmanAssignment[];

  allowedPaymentProviders: string[];

  payment?: Payment;

  splitPayments?: {
    percentage: number;
    payment?: Payment;
  }[];

  options?: InvoiceOptions;

  isFullyPaid: boolean;
  hasAtLeastOnePayment: boolean;
}

export interface InvoiceOptions {
  /**
   * By default, the materials commission cap on an invoice is set using the
   * default value on environment variable MAX_MATERIALS_COMMISSION.
   * If this option is set, that would be used as the materials commission cap.
   *
   * NOTE: If this is set to 0, there will be no commission cap on this invoice
   * and 10% of the materials cost would be added as commission no matter how large that is
   */
  materialCommissionCap?: number;
}

export interface ProAssignmentChecklist {
  onLaborHackApparel: boolean;
  onProtectiveGear: boolean;
  withProperEquipment: boolean;
  validationImageUrl: string;
  submitted: Date;
}

export interface Checklists {
  startInspection?: ProAssignmentChecklist;
  startJob?: ProAssignmentChecklist;
}

export enum AssignmentPaymentTypes {
  TRANSPORTATION = "TRANSPORTATION",
  MATERIALS = "MATERIALS",
  LABOR = "LABOR",
}

export interface AssignmentPayment {
  id: string;
  order: Order;
  tradesman: Tradesman;
  assignment: TradesmanAssignment;
  type: AssignmentPaymentTypes;
  transfer: TransferRequest;
  createdAt: Date;
}

export interface TradesmanAssignment {
  id: string;
  order: Order;
  jobs: Job[];
  tradesman: Tradesman;
  otherPros: Tradesman[];
  date: Date;
  progress: JobProgress;
  invoice?: IInvoice;
  checklists?: Checklists;
  inspectionPayment?: Payment;
  inspectionWaived?: boolean;
  assignmentPayments: AssignmentPayment[];
  category: Category;
  currentQuote: OrderHandlingQuotation | null;
}

export interface ObjectOf<T> {
  [key: string]: T;
}

export interface Client {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  business?: Business;
  businessMetadata?: {
    activated: boolean;
  };
  createdAt: Date;
  billingAddress: string | null;
  addresses: Address[] | null;
}

export interface FieldValue<T> {
  value: T;
  isValid: boolean;
}

export enum Role {
  ROOT = "ROOT",
  USER_ADMINISTRATOR = "USER_ADMINISTRATOR",
  CUSTOMERSERVICE = "CUSTOMERSERVICE",
  FINANCE = "FINANCE",
  BUSINESS_DEVELOPER = "BUSINESS_DEVELOPER",
  PROJECTMANAGER = "PROJECTMANAGER",
  SYSTEM_ADMINISTRATOR = "SYSTEM_ADMINISTRATOR",
  CERTIFICATION_ADMINISTRATOR = "CERTIFICATION_ADMINISTRATOR",
  PRO_ADMINISTRATOR = "PRO_ADMINISTRATOR",
  OUTREACH = "OUTREACH",
}

export interface Edge<T> {
  cursor: string;
  node: T;
}

export interface RelayStylePaginatedResponse<T> {
  totalCount?: number;
  edges: Edge<T>[];
  pageInfo: {
    hasNextPage: boolean;
    startCursor: string;
    endCursor: string;
  };
}

export interface ProSkill {
  name: string;

  score: number;
}

export interface FilterClass {
  availability: boolean;
}

export interface Filter {
  class: keyof FilterClass;
  value: FilterClass[keyof FilterClass];
  name: string;
}

export enum ScheduleConflictType {
  ASSIGNMENT_SCHEDULED = "ASSIGNMENT_SCHEDULED",
  CONTRACT_OVERLAP = "CONTRACT_OVERLAP",
  MARKETPLACE_MATCH = "MARKETPLACE_MATCH",
}

export interface ContractConflict {
  type: ScheduleConflictType.CONTRACT_OVERLAP;
  contract: Contract;
}

export interface AssignmentConflict {
  type: ScheduleConflictType.ASSIGNMENT_SCHEDULED;
  assignment: TradesmanAssignment;
}

export interface MarketplaceConflict {
  type: ScheduleConflictType.MARKETPLACE_MATCH;
  marketplaceJob: MarketplaceJob;
}

export type ScheduleConflict =
  | ContractConflict
  | AssignmentConflict
  | MarketplaceConflict;

export enum CertificationRequirementType {
  THEORETICAL_ASSESSMENT = "THEORETICAL_ASSESSMENT",
  PRACTICAL_ASSESSMENT = "PRACTICAL_ASSESSMENT",
  CERTIFICATION = "CERTIFICATION",
}

export interface CertificationRequirement {
  id: string;
  certification: Certification;
  type: CertificationRequirementType;
  name: string;
  description: string;
  passingScore: number;
  lmsId?: string;
  order: number;
}

export interface Certification {
  id: string;
  name: string;
  description: string;
  requirements: CertificationRequirement[];
  createdAt: Date;
  updatedAt: Date;
}

export interface ProCertificationResult {
  id: string;
  proCertification: ProCertification;
  certificationRequirement: CertificationRequirement;
  score: number;
  submittedAt: Date;
  attemptId?: number;
}

export interface ProCertification {
  id: string;
  pro: Pro;
  certification: Certification;
  results: ProCertificationResult[];
  uploadedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface Pro {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  associatedLaborHackEmail: string;
  imageUrl?: string;
  verified: boolean;
  authProviderId?: string;
  userId: string;
  categoryIds: string[];
  conflicts?: ScheduleConflict[];
  trades: Trade[];
  tracks?: ProTrack[];
  proApplication: ProApplication;
  certifications: ProCertification[];
  identityVerification?: IdentityVerification;
  onboardedAt?: boolean;
  category?: string;
  jobId?: string;
  updatedAt: Date;
  createdAt: Date;
}

export enum IdentityVerificationIssueCode {
  DUPLICATE_ID = "DUPLICATE_ID",
  DOB_MISMATCH = "DOB_MISMATCH",
  NAME_MISMATCH = "NAME_MISMATCH",
  PHONE_MISMATCH = "PHONE_MISMATCH",
  INVALID_ID = "INVALID_ID",
}

export interface ProListItem {
	id: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string;
	trades: Trade[];
	tracks?: ProTrack[];
	categories: Category[];
	identityVerification?: IdentityVerification;
	identityVerificationStatus?: IdentityVerificationStatus;
	identityVerificationIssues?: IdentityVerificationIssueCode[];
	proApplication?: ProApplication;
	referredBy?: string;
	priorLearningTestStatus: ListProsTestStatus;
	paidForPriorLearningAttempts: boolean;
	softSkillsTrainingStatus: ListProsTestStatus;
	alternativeToPracticalInterviewStatus: ListProsTestStatus;
	paidForSoftSkillsAttempts: boolean;
	bankAccountSubmitted: boolean;
	backgroundCheckComplete: boolean;
	referees: Referee[];
	guarantors: Guarantor[];
	onboardedAt: Date;
	conflicts: ScheduleConflict[];
	isLegacy?: boolean;
	isAvailable?: boolean;
	updatedAt: Date;
	createdAt: Date;
}

export enum PaymentStatus {
  PAID = "PAID",
  NOT_PAID = "NOT_PAID",
}

export enum ListProsIdentityVerificationIssueCode {
  DUPLICATE_ID = "DUPLICATE_ID",
  DOB_MISMATCH = "DOB_MISMATCH",
  NAME_MISMATCH = "NAME_MISMATCH",
  PHONE_MISMATCH = "PHONE_MISMATCH",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

export enum ListProsIdentityVerificationStatus {
  VERIFIED = "VERIFIED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
  NOT_SUBMITTED = "NOT_SUBMITTED",
}

export enum ListProsTestStatus {
  PASSED = "PASSED",
  FAILED = "FAILED",
  NOT_TAKEN = "NOT_TAKEN",
  AWAITING_RESULT = "AWAITING_RESULT",
  NOT_STARTED = "NOT_STARTED",
  SCHEDULED = "SCHEDULED",
}

export enum ListProsSortBy {
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  IDENTITY_VERIFICATION_SUBMITTED_AT = "IDENTITY_VERIFICATION_SUBMITTED_AT",
  CREATED_AT = "CREATED_AT",
}

export enum ListProsSearchBy {
	FIRST_NAME = "FIRST_NAME",
	LAST_NAME = "LAST_NAME",
	EMAIL = "EMAIL",
	PHONE_NUMBER = "PHONE_NUMBER",
	ADDRESS = "ADDRESS",
	REFERRER = "REFERRER",
}
export interface ListProsSort {
  by: ListProsSortBy;
  ascending?: boolean;
}

export interface ListProsSearch {
  query: string;
  by: ListProsSearchBy;
}

export enum ProTrack {
  LOCALGIG = "LOCALGIG",
  STUDYWORK = "STUDYWORK",
}

export interface ListProsFilters {
  tradeId?: string;
  tradeIds?: string[];
  identityVerificationStatus?: ListProsIdentityVerificationStatus[];
  identityVerificationIssueCode?: ListProsIdentityVerificationIssueCode[];
  priorLearningTestStatus?: ListProsTestStatus[];
  softSkillsTrainingStatus?: ListProsTestStatus[];
  alternativeToPracticalInterviewStatus?: ListProsTestStatus[];
  bankAccountSubmitted?: boolean;
  backgroundCheckComplete?: boolean;
  paidForPriorLearningAttempts?: boolean;
  paidForSoftSkillsAttempts?: boolean;
  isLegacy?: boolean;
  categoryIds?: string[];
  leadSource?: string[];
  channelIds?: string[];
  locationIds?: string[];

  /**
   * @deprecated Use `isOnboarded` instead
   */
  onboardingCompleted?: string[];
  isOnboarded?: boolean;
  hasJobScheduleConflict?: string[];
  tracks?: ProTrack[];
}

export interface ProApplication {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  address: string;
  categoryId: string;
  stateOfResidence: string;
  dateOfBirth?: Date;
  gender: Gender;
  identityValidated: boolean;
  profilePictureUrl: string;
  yearsOfExperience?: YearsOfExperience;
  trainingType?: TrainingType;
  incomeLevel?: IncomeLevel;
  identification: {
    meansOfIdentification: MeansOfIdentification;
    identificationNumber: string;
    identificationUrl: string;
  };
  category?: Category;
  leadSource: string;
  reasonForJoiningLaborHack?: string;
  reasonForJoiningLaborHackOtherText?: string;
  channelOutreachSource: OutreachSource & {
    type: OutreachSourceType.CHANNEL;
  };
  createdAt: Date;
  updatedAt: Date;
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum IncomeLevel {
  UNDER_30K = "UNDER_30K",
  BETWEEN_30K_AND_50K = "BETWEEN_30K_AND_50K",
  BETWEEN_50K_AND_70K = "BETWEEN_50K_AND_70K",
  BETWEEN_70K_AND_100K = "BETWEEN_70K_AND_100K",
  BETWEEN_100K_AND_150K = "BETWEEN_100K_AND_150K",
  BETWEEN_150K_AND_200K = "BETWEEN_150K_AND_200K",
  OVER_200K = "OVER_200K",
}

export enum YearsOfExperience {
  UNDER_ONE = "UNDER_ONE",
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE",
  SIX_TO_TEN = "SIX_TO_TEN",
  ABOVE_TEN = "ABOVE_TEN",
}

export enum TrainingType {
  COLLEGE = "COLLEGE",
  POLYTECHNIC = "POLYTECHNIC",
  TRAINING_INSTITUTE = "TRAINING_INSTITUTE",
  APPRENTICE = "APPRENTICE",
  OTHER = "OTHER",
}

export enum ProLeadSource {
  RADIO = "RADIO",
  FROM_A_FRIEND = "FROM_A_FRIEND",
  FLYER = "FLYER",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  GOOGLE = "GOOGLE",
  OPL_ACADEMY = "OPL_ACADEMY",
  COMMUNITY_OUTREACH = "COMMUNITY_OUTREACH",
  OTHER = "OTHER",
}

export enum MeansOfIdentification {
  NIN = "NIN",
  PASSPORT = "PASSPORT",
  DRIVERS_LICENSE = "DRIVERS_LICENSE",
  VOTERS_CARD = "VOTERS_CARD",
  BVN = "BVN",
}

export enum IdentityVerificationStatus {
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
  DECLINED = "DECLINED",
}

export interface IdentityVerificationIssue {
  code: string;
  message: string;
}

export interface IdentityVerification {
  id: string;
  pro: Pro;
  issues?: IdentityVerificationIssue[];
  providerIssues?: IdentityVerificationIssue[];
  returnedDateOfBirth?: Date;
  returnedFullName?: string;
  returnedPhoneNumber?: string;
  returnedPhoto?: string;
  identificationNumber: string;
  type: MeansOfIdentification;
  status: IdentityVerificationStatus;
  dateOfBirth: Date;
  photo: string;
  identificationDocument: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface MarketplaceMatchPro {
  id: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  imageUrl?: string;
}
export interface MarketplaceMatch {
  pro: MarketplaceMatchPro;

  relevantSkills: ProSkill[];
}

export interface MarketplaceJobSchedule {
  start: Date;

  end: Date;

  offDates?: Date[];

  workDays: string[];
}

export interface MarketplaceJob {
  id: string;

  trade: Trade;

  proCount: number;

  schedule: MarketplaceJobSchedule;

  pros: Pro[];

  matches: MarketplaceMatch[];

  description?: string;

  request: MarketplaceRequest;

  shortlist?: MarketplaceJobShortlist;

  createdAt: Date;
}

export interface LocationData {
  address: string;

  city: string;

  state: string;
}

export interface MarketplaceRequest {
  id: string;

  business: Business;

  client: Client;

  matchingFeeInvoice?: IInvoice;

  location: LocationData;

  jobs: MarketplaceJob[];

  createdAt: Date;
}

export interface ProCertificationFilters {
  onlineAssessmentPassed?: boolean;
  uploaded?: boolean;
  identityVerified?: boolean;
}

export interface Category {
  id: string;
  requestCount: number;
  name: string;
  proTitle?: string;
}

export enum MarketplaceJobShortlistStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export interface MarketplaceJobShortlistedPro {
  pro: Pro;
  status: MarketplaceJobShortlistStatus;
}

export interface MarketplaceJobShortlist {
  id: string;
  job: MarketplaceJob;
  shortlistedPros: MarketplaceJobShortlistedPro[];
  createdAt: Date;
  updatedAt: Date;
}

export enum OutreachSourceType {
  CHANNEL = "CHANNEL",
}

export interface OutreachSource {
  id: string;
  name: string;
  type: OutreachSourceType;
}

export interface Referee {
  name: string;
  phoneNumber?: string;
  email: string;
  companyName: string;
  companyAddress: string;
  jobTitle: string;
  relationship: string;
}

export interface Guarantor {
  name: string;
  phoneNumber?: string;
  email: string;
  companyName: string;
  companyAddress: string;
  jobTitle: string;
  relationship: string;
}

export enum ListAllTradesmenSearchBy {
  LOCATION = "LOCATION",
}

export interface ListAllTradesmenSearch {
  query: string;
  by: ListAllTradesmenSearchBy;
}

export enum RecruitmentJobShortlistStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export interface RecruitmentJobShortlistedPro {
  pro: Pro;
  status: RecruitmentJobShortlistStatus;
  potentialMonthlyGrossSalary?: number;
}

export interface RecruitmentJobPro {
  pro: Pro;
}

export interface RecruitmentJob {
  id: string;

  category: Category;

  proCount: number;

  schedule: MarketplaceJobSchedule;

  pros: Pro[];

  matches: MarketplaceMatch[];

  description?: string;

  request: MarketplaceRequest;

  shortlist?: MarketplaceJobShortlist;

  createdAt: Date;
}

export interface Address {
  id: string;
  address: string;
  city: string;
  state: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface HomeownerRequest {
  id: string;
  address: Address;
  startDate: Date;
}

export interface SubscriptionPackage {
	id: string;
	name: string;
	isCustom?: boolean;
	description: string;
	plans: Plan[];
	basePrice: SubscriptionPrice;
	category: Category;
	maximumCalls: number;
	baseInterval: SubscriptionInterval;
}

export interface Plan {
  id: string;
  interval: string;
  currentPrice: SubscriptionPrice;
  package: SubscriptionPackage;

  costBreakdown: {
    total: number;
  };
}

export interface SubscriptionPrice {
	id: string;
	price: number;
	takesEffectFrom: Date;
	breakdown: {
		vendorFee: number;
		serviceCharge: number;
	};
}

export enum SubscriptionInterval {
	MONTHLY = "MONTHLY",
	QUARTERLY = "QUARTERLY",
	YEARLY = "YEARLY",
	BIANNUALLY = "BIANNUALLY",
}

export interface Subscription {
  id: string;

  plan: Plan;

  client: Client;

  category: Category;

  homeownerRequest: HomeownerRequest;

  subscriptionRequestCalls: SubscriptionRequestCall[];

  createdAt: Date;
}

export interface SubscriptionRequestCall {
	id: string;

	subscriptionRequest: Subscription;

	assignedPro?: Pro;

	proPaymentAmount?: number;

	expectedProPaymentAmount: number;

	dateOfAssignment: Date;

	createdAt: Date;

	updatedAt: Date;
}

export interface CreateOrderHandlingQuotationItemMaterial
  extends OrderHandlingQuotationItemMaterial {
  id: string;
}

export interface OrderHandlingQuotationItemMaterial {
  name: string;
  quantity: number;
  unitPrice: number;
}
export interface CreateOrderHandlingQuotationItem {
  materials?: CreateOrderHandlingQuotationItemMaterial[];
  labor?: number;
  jobId: string;
}
export interface OrderHandlingQuotationItem {
  materials: OrderHandlingQuotationItemMaterial[] | null;
  labor: number | null;
  job: Job;
}

export enum OrderHandlingQuotationStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export interface OrderHandlingQuotation {
  id: string;
  serialNumber: number;
  order: Order;
  client: Client;
  assignments: TradesmanAssignment[];
  status: OrderHandlingQuotationStatus;
  items: OrderHandlingQuotationItem[];
  costBreakdown: OrderHandlingQuotationCostBreakdown;
  invoice: IInvoice | null;
  expiresAt: Date;
  rejectionReason: string | null;
  isInUse: boolean | null;
  sentAt: Date | null;
  notes: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface OrderHandlingQuotationCostBreakdown {
  total: number;
  discount: number;
  subTotal: number;
  laborHackFee: number;
  laborFee: number;
  totalMaterialsCost: number;
  materialCommission: number;
  laborCommission: number;
  tax: number;
}
