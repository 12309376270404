import { FC, useEffect } from 'react';

export interface ScrollToTopOnMountProps {
  parent: HTMLElement | null;
}

export const ScrollToTopOnMount: FC<ScrollToTopOnMountProps> = ({ parent }) => {
  useEffect(() => {
    if (parent) {
      parent?.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
