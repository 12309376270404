import React, { FC } from "react";
import { Rating } from "semantic-ui-react";

import styles from "./styles.module.scss";

export interface FeedbackFieldProps {
  onChange: (rating: number) => void;
  label: string;
  rating: number;
  disabled?: boolean;
}

export const FeedbackField: FC<FeedbackFieldProps> = ({
  rating,
  onChange,
  label,
  disabled,
}) => {
  return (
    <div className={styles.field}>
      <p>{label}</p>
      <Rating
        disabled={disabled}
        className={styles.rating}
        maxRating={5}
        rating={rating}
        onRate={(e, { rating }) => {
          if (typeof rating === "number") onChange(rating);
        }}
      ></Rating>
    </div>
  );
};
