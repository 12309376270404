import { useApolloClient } from "@apollo/client";
import React, { FC, useState } from "react";
import { Form } from "semantic-ui-react";
import CustomInput from "@laborhack/input";
import Button from "@laborhack/custom-button";
import { GET_USER_BY_EMAIL } from "../../../graphql/query";
import { FieldValue } from "../../../types";
import {
  addPhoneNumberPrefix,
  nonEmptyString,
  phoneNumberCheck,
  phoneNumberDoesNotExist,
} from "../../../helpers/helpers";
import Joi from "@hapi/joi";
import { CreateBusinessClientPayload } from "../types";
import { nanoid } from "@reduxjs/toolkit";
import RequestFailed from "../../../_components/RequestFailed";

export interface CreateBusinessClientProps {
  onSave: (payload: CreateBusinessClientPayload) => void;
}

export const CreateBusinessClient: FC<CreateBusinessClientProps> = ({
  onSave,
}) => {
  const client = useApolloClient();
  const defaultField = {
    value: "",
    isValid: false,
  };

  const emailDoesNotExist = async (email: string) => {
    try {
      await client.query({
        query: GET_USER_BY_EMAIL,
        variables: {
          email,
        },
      });

      return false;
    } catch (error) {
      return true;
    }
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  /**
   * Contact Details
   */
  const [firstName, setFirstName] = useState<FieldValue<string>>(defaultField);
  const [lastName, setLastName] = useState<FieldValue<string>>(defaultField);
  const [phoneNumber, setPhoneNumber] = useState<FieldValue<string>>(
    defaultField
  );
  const [email, setEmail] = useState<FieldValue<string>>(defaultField);

  const createAccountEnabled =
    firstName.isValid && lastName.isValid && email.isValid;

  const handleSave = async () => {
    try {
      setLoading(true);

      if (phoneNumber.isValid) {
        const phoneNumberIsValid = await phoneNumberDoesNotExist(
          client,
          phoneNumber.value
        );

        if (!phoneNumberIsValid) {
          throw new Error("Phone number already exists");
        }
      }

      const emailIsValid = await emailDoesNotExist(email.value);

      if (!emailIsValid)
        throw new Error("Phone number and/or email already exists");

      setLoading(false);

      onSave({
        id: nanoid(),
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber: phoneNumber.isValid
          ? addPhoneNumberPrefix(phoneNumber.value)
          : undefined,
        email: email.value,
      });
    } catch (error: any) {
      setLoading(false);
      setError(error.message);
    }
  };

  const clearErrors = () => {
    setError(undefined);
  };

  if (error) {
    <RequestFailed errorMessage={error} onRetry={clearErrors} />;
  }

  return (
    <div className='w-96 p-8'>
      <div className='mb-8'>
        <h2 className='text-primary-500 text-xl font-bold m-0'>
          Create Contact
        </h2>
      </div>
      <Form>
        <CustomInput
          autoComplete='given-name'
          required
          textLabel='First Name'
          value={firstName.value}
          onChange={(value: string, isValid: boolean) => {
            setFirstName({ value, isValid });
          }}
          validate={{
            check: nonEmptyString,
            message: "Invalid Name",
          }}
          placeholder='First Name'
        />
        <CustomInput
          autoComplete='family-name'
          required
          textLabel='Last Name'
          value={lastName.value}
          onChange={(value: string, isValid: boolean) => {
            setLastName({ value, isValid });
          }}
          validate={{
            check: nonEmptyString,
            message: "Invalid Name",
          }}
          placeholder='Last Name'
        />
        <CustomInput
          autoComplete='email'
          required
          type='email'
          textLabel='Email Address'
          value={email.value}
          onChange={(value: string, isValid: boolean) => {
            setEmail({ value, isValid });
          }}
          validate={{
            check: (value: string) => {
              return !Joi.string()
                .email({
                  minDomainSegments: 2,
                  tlds: { allow: false },
                })
                .validate(value).error;
            },
            message: "Invalid Email",
          }}
          asyncValidate={{
            check: emailDoesNotExist,
            message: "This email already exists",
          }}
          placeholder='Enter Email Address'
        />

        <CustomInput
          autoComplete='tel'
          textLabel='Phone Number'
          label='+234'
          type='tel'
          value={phoneNumber.value}
          onChange={(value: string, isValid: boolean) => {
            setPhoneNumber({ value, isValid });
          }}
          validate={{
            check: phoneNumberCheck,
            message: "Invalid Phone Number",
          }}
          asyncValidate={{
            check: (value: string) => phoneNumberDoesNotExist(client, value),
            message: "This phone number already exists",
          }}
          placeholder='8000000000'
        />
      </Form>
      <div className='mt-4'>
        <Button
          loading={loading}
          disabled={!createAccountEnabled}
          onClick={handleSave}
          fullWidth
        >
          Add Contact
        </Button>
      </div>
    </div>
  );
};
