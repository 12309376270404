import { FC, useState } from "react";
import { components as LayoutComponents } from "../../components/layout";
import Island from "../../_components/Island";
import Button from "@laborhack/custom-button";
import { Icon, Modal } from "semantic-ui-react";
import { gql, useQuery } from "@apollo/client";
import RequestFailed from "../../_components/RequestFailed";
import Loading from "../../_components/Loading";
import {
  CertificationRequirementType,
  ProCertification,
  ProCertificationFilters,
  RelayStylePaginatedResponse,
} from "../../types";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import Pill from "../../_components/Pill";
import { extractItemsFromRelayStylePaginationResponse } from "../../helpers/helpers";
import { AddFilter } from "../../features/certifications/AddFilter";

const { CustomTable } = LayoutComponents;

const GET_PRO_CERTIFICATIONS = gql`
  query GetProCertifications(
    $first: Int
    $after: String
    $filters: ListProCertificationsFilters
  ) {
    proCertifications(first: $first, after: $after, filters: $filters) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          uploadedAt
          pro {
            id
            firstName
            lastName
          }
          results {
            id
            certificationRequirement {
              id
              type
              lmsId
            }
            score
            submittedAt
          }
          certification {
            id
            name
            requirements {
              id
              name
              passingScore
            }
          }
          createdAt
        }
      }
    }
  }
`;

export interface CertificationListProps {}

export const CertificationList: FC<CertificationListProps> = ({}) => {
  const { path } = useRouteMatch();

  const history = useHistory();

  const [filters, setFilters] = useState<ProCertificationFilters>({
    onlineAssessmentPassed: true,
    identityVerified: true,
    uploaded: false,
  });

  const [showAddFilterModal, setShowAddFilterModal] = useState(false);

  const { data, loading, error, fetchMore, refetch } = useQuery<{
    proCertifications: RelayStylePaginatedResponse<ProCertification>;
  }>(GET_PRO_CERTIFICATIONS, {
    variables: {
      filters,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <RequestFailed />;
  }

  const columns: ColumnsType<ProCertification & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.id.substring(0, 8)}</p>;
      },
      width: "10%",
    },
    {
      title: "Name",
      render: (text, record) => {
        return <p>{`${record.pro.firstName} ${record.pro.lastName}`}</p>;
      },
      width: "15%",
    },
    {
      title: "Certification",
      render: (text, record) => {
        return <Pill>{record.certification.name}</Pill>;
      },
      width: "15%",
    },
    {
      title: "Online Assessment (%)",
      render: (text, record) => {
        const { results, certification } = record;

        const onlineAssessmentResult = results.find(
          ({ certificationRequirement: { type, lmsId } }) => {
            return (
              type === CertificationRequirementType.THEORETICAL_ASSESSMENT &&
              lmsId
            );
          }
        );

        const requirement = certification.requirements.find(({ id }) => {
          return id === onlineAssessmentResult?.certificationRequirement.id;
        });

        const hasPassed =
          onlineAssessmentResult &&
          requirement &&
          onlineAssessmentResult.score >= requirement.passingScore;

        return (
          <div className='flex items-center p-0 m-0 gap-1'>
            <p className='text-base p-0 m-0'>
              {onlineAssessmentResult
                ? onlineAssessmentResult.score + "%"
                : "N/A"}
            </p>
            {onlineAssessmentResult && (
              <Icon
                size='large'
                name={hasPassed ? "check circle" : "remove circle"}
                color={hasPassed ? "green" : "red"}
              />
            )}
          </div>
        );
      },
      width: "15%",
    },
    {
      title: "Uploaded to ECITB",
      render: (text, record) => {
        return (
          <Icon
            size='large'
            name={record.uploadedAt ? "check circle" : "remove circle"}
            color={record.uploadedAt ? "green" : "red"}
          />
        );
      },

      width: "10%",
    },
    {
      title: "Date Created",
      render: (text, record) => {
        return <p>{new Date(record.createdAt).toLocaleDateString()}</p>;
      },
      width: "10%",
    },
  ];

  return (
    <Island>
      <div className='mb-4'>
        <h2 className='text-primary-500 text-xl font-bold m-0'>
          Pro Certifications
        </h2>
        <p className='m-0 text-primary-300'>A list of pro certifications</p>
      </div>
      <div className='w-full flex justify-end my-4'>
        <Button type='link'>
          <Icon name='refresh' /> Refresh
        </Button>
      </div>
      <div>
        <Modal
          onClose={() => setShowAddFilterModal(false)}
          onOpen={() => setShowAddFilterModal(true)}
          open={showAddFilterModal}
          size='small'
          closeIcon
          trigger={
            <Button
              type='subtle'
              variant='basic'
              className='!px-4 !bg-primary-150 !text-primary-500'
            >
              <Icon className='!ml-0' name='filter' />
              Manage Filters
            </Button>
          }
        >
          <AddFilter
            filters={filters}
            setFilters={(filters) => {
              setFilters(filters);
              refetch({ filters });
            }}
            close={() => setShowAddFilterModal(false)}
          />
        </Modal>
      </div>
      <div className='w-full flex justify-end my-2'>
        <p>
          Showing <strong>{data?.proCertifications.edges.length}</strong> of{" "}
          <strong>{data?.proCertifications.totalCount}</strong> results
        </p>
      </div>
      <CustomTable
        onRow={(record: any, index: any) => {
          return {
            onClick: () => {
              history.push(path + "/" + record.id);
            },
          };
        }}
        columns={columns}
        data={
          extractItemsFromRelayStylePaginationResponse(
            data!.proCertifications
          ).map((item) => {
            return {
              key: item.id,
              ...item,
            };
          }) || []
        }
        loading={loading}
        handleTableChange={() => {}}
      ></CustomTable>
    </Island>
  );
};
