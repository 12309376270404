import { PropsWithChildren } from "react";
import BackButton from "../../_components/BackButton";
import Island from "../../_components/Island";
import { ApplicationForm } from "./ApplicationForm";

export interface CreateProProps {}

export const CreatePro = ({}: PropsWithChildren<CreateProProps>) => {
  return (
    <>
      <BackButton />
      <Island>
        <div className='mb-4'>
          <h2 className='text-primary-500 text-xl font-bold m-0'>Create Pro</h2>
          <p className='m-0 text-primary-300'>
            Fill out the form below to create a new pro
          </p>
        </div>
        <div className='mt-8 w-full px-8'>
          <ApplicationForm />
        </div>
      </Island>
    </>
  );
};
