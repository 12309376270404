import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AssignmentActions, AssignmentSelectors } from "../../../redux/store";
import SuggestedProCard from "./SuggestedProCard";
import { Order } from "../../../types";

const {
  tradesmanAdded,
  dateAdded,
  orderIdAddedToAssignment,
} = AssignmentActions;
const { selectCreateAssignmentData } = AssignmentSelectors;

const ScheduleSuggestedPro: FC<{
  selectedCategory: string;
  order: Order;
}> = ({ selectedCategory, order }) => {
  const creationData = useSelector(selectCreateAssignmentData);

  const dispatch = useDispatch();

  if (!order) {
    return <div>loading...</div>;
  }

  const { suggestedTradesmen } = order;

  return (
    <div className="o-scheduleSuggestedPro__wrapper">
      {suggestedTradesmen
        ?.filter(({ categoryId }) => {
          return categoryId === selectedCategory;
        })
        .map((tradesman) => {
          const selected = creationData.tradesman?.id === tradesman.id;

          return (
            <SuggestedProCard
              selected={selected}
              key={tradesman.id}
              tradesman={tradesman}
              onSelect={(date: Date) => {
                if (selected) {
                  dispatch(tradesmanAdded(null));
                } else {
                  dispatch(orderIdAddedToAssignment(order.id));
                  dispatch(tradesmanAdded(tradesman));
                  dispatch(dateAdded(date.toISOString()));
                }
              }}
            />
          );
        })}
    </div>
  );
};

export default ScheduleSuggestedPro;
