import { gql, useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";

import BackButton from "../../../_components/BackButton";
import { ViewBusinessField as Field } from "./ViewBusinessField";
import Island from "../../../_components/Island";
import Loading from "../../../_components/Loading";
import RequestFailed from "../../../_components/RequestFailed";
import { Business } from "../types";
import { ViewBusinessClientCard } from "./ViewBusinessClientCard";
import { ActivationEmailReports } from "./ActivationEmailReports";
import Editable from "../../../_components/Editable";
import { Modal } from "semantic-ui-react";
import { EditBusinessEmail } from "./EditBusinessEmail";

const GET_BUSINESS = gql`
  query GET_BUSINESS($id: String!) {
    business(id: $id) {
      id
      name
      email
      phoneNumber
      clients {
        id
        firstName
        lastName
        email
        phoneNumber
        businessMetadata {
          activated
        }
      }
      location {
        address
        city
        state
      }
    }
  }
`;

export interface ViewBusinessProps {}

export const ViewBusiness: FC<ViewBusinessProps> = ({}) => {
  const { id } = useParams<{ id: string }>();

  const [editBusinessEmailOpen, setEditBusinessEmailOpen] = useState(false);

  const { data, loading, error } = useQuery<{ business: Business }>(
    GET_BUSINESS,
    {
      variables: { id },
    }
  );

  if (loading) return <Loading />;

  if (error || !data) {
    return <RequestFailed errorMessage={error?.message} />;
  }

  const address =
    data.business.location &&
    `${data.business.location.address}, ${data.business.location.city}, ${data.business.location.state}`;

  return (
    <>
      <Modal
        open={editBusinessEmailOpen}
        size='tiny'
        closeIcon
        onClose={() => setEditBusinessEmailOpen(false)}
        closeOnDimmerClick={false}
      >
        <EditBusinessEmail
          businessId={data.business.id}
          onSave={() => setEditBusinessEmailOpen(false)}
        />
      </Modal>
      <BackButton />
      <Island>
        <div className='mb-4'>
          <h2 className='text-primary-500 text-xl font-bold m-0'>
            Business Details{" "}
            <span className='text-primary-300'>
              ({data.business.id.substring(0, 8)})
            </span>
          </h2>
          <p className='m-0 text-primary-300'>View business details</p>
        </div>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <div className='mb-4'>
              <h3 className='text-lg font-bold text-primary-500 mb-2'>
                Basic Information
              </h3>
              <div className=' w-max pl-2'>
                <Field label='Name' value={data.business.name} />

                <Field
                  label='Email'
                  value={
                    <Editable
                      onEdit={() => {
                        setEditBusinessEmailOpen(true);
                      }}
                    >
                      <p className='text-primary-500 font-semibold p-0 m-0 mr-2'>
                        {data.business.email}
                      </p>
                    </Editable>
                  }
                />

                <Field
                  label='Phone Number'
                  value={
                    data.business.phoneNumber || (
                      <p className='italic text-primary-500'>Not Provided</p>
                    )
                  }
                />
                <Field
                  label='Address'
                  value={
                    address || (
                      <p className='italic text-primary-500'>Not Provided</p>
                    )
                  }
                />
              </div>
            </div>
            <div className=''>
              <h3 className='text-lg font-bold text-primary-500 mb-2'>
                Saved Contacts
              </h3>
              <div className='pl-2 mb-2 w-96'>
                {data.business.clients.length === 0 ? (
                  <>
                    <p className='italic text-primary-500'>No saved contacts</p>
                  </>
                ) : (
                  data.business.clients.map((client) => (
                    <ViewBusinessClientCard key={client.id} client={client} />
                  ))
                )}
              </div>
            </div>
          </div>
          <ActivationEmailReports business={data.business} />
        </div>
      </Island>
    </>
  );
};
