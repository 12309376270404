import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import clsx from "clsx";
import { FaTrashAlt } from "react-icons/fa";
import { NairaInputHelpers } from "../../helpers/helpers";
import { CreateOrderHandlingQuotationItemMaterial } from "../../types";

const { format, parse } = NairaInputHelpers;

interface JobQuoteMaterialEntryProps {
  material: CreateOrderHandlingQuotationItemMaterial;
  onChange: (material: CreateOrderHandlingQuotationItemMaterial) => void;
  onRemove: () => void;
  showErrors?: boolean;
  readonly?: boolean;
}

export const JobQuoteMaterialEntry = ({
  material,
  onChange,
  onRemove,
  showErrors,
  readonly,
}: JobQuoteMaterialEntryProps) => {
  const updateMaterialName = (name: string) => {
    onChange({
      ...material,
      name,
    });
  };

  const updateMaterialQty = (qty: string) => {
    onChange({
      ...material,
      quantity: Number(qty),
    });
  };

  const updateMaterialUnitPrice = (unitPrice: string) => {
    onChange({
      ...material,
      unitPrice: Number(unitPrice),
    });
  };

  return (
    <div className='flex gap-2 items-end'>
      <div className=' grid grid-cols-[3fr,1fr,2fr] gap-2'>
        <FormControl isInvalid={showErrors && !material.name} className='w-min'>
          <FormLabel className='!text-xs uppercase tracking-wide font-medium'>
            Name
          </FormLabel>
          <Input
            isReadOnly={readonly}
            className={clsx(readonly && "!border-0 !px-0")}
            placeholder='Name'
            value={material.name}
            onChange={(e) => updateMaterialName(e.target.value)}
          />
          {showErrors && <FormErrorMessage></FormErrorMessage>}
        </FormControl>

        <FormControl
          isInvalid={showErrors && material.quantity < 1}
          className='w-min'
        >
          <FormLabel className='!text-xs uppercase tracking-wide font-medium'>
            Qty
          </FormLabel>
          <NumberInput
            isReadOnly={readonly}
            onChange={(valueString: string) => updateMaterialQty(valueString)}
            value={String(material.quantity)}
            min={1}
          >
            <NumberInputField className={clsx(readonly && "!border-0 !px-0")} />
            {!readonly && (
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            )}
          </NumberInput>
          {showErrors && <FormErrorMessage></FormErrorMessage>}
        </FormControl>

        <FormControl
          isInvalid={showErrors && material.unitPrice < 1}
          className='w-min'
        >
          <FormLabel className='!text-xs uppercase tracking-wide font-medium'>
            Unit Price
          </FormLabel>
          <NumberInput
            isReadOnly={readonly}
            onChange={(valueString: string) =>
              updateMaterialUnitPrice(parse(valueString))
            }
            value={format(String(material.unitPrice))}
            min={1}
          >
            <NumberInputField className={clsx(readonly && "!border-0 !px-0")} />
            {!readonly && (
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            )}
          </NumberInput>
          {showErrors && <FormErrorMessage></FormErrorMessage>}
        </FormControl>
      </div>
      {!readonly && (
        <IconButton
          colorScheme='red'
          variant='ghost'
          aria-label='Delete'
          icon={<FaTrashAlt />}
          onClick={onRemove}
        />
      )}
    </div>
  );
};
