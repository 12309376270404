import { gql, useQuery } from "@apollo/client";
import { TradeRequirement } from "../common/types/trade";

const GET_TRADE_REQUIREMENTS = gql`
  query GetTradeRequirements {
    tradeRequirements {
      id
      name
      description
      type
      price
      scoringTemplate {
        name
        description
        maximumScore
      }
    }
  }
`;

export const useTradeRequirements = () => {
  const { data, loading, error } = useQuery<{
    tradeRequirements: TradeRequirement[];
  }>(GET_TRADE_REQUIREMENTS);

  const tradeRequirementMap = data?.tradeRequirements.reduce(
    (acc, tradeRequirement) => {
      acc[tradeRequirement.id] = tradeRequirement;
      return acc;
    },
    {} as Record<string, TradeRequirement>
  );

  return {
    loading,
    error,
    tradeRequirements: data?.tradeRequirements,
    tradeRequirementMap,
  };
};
