import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Icon, Image, Menu } from "semantic-ui-react";
import {
  CertificationRequirementType,
  IdentityVerificationStatus,
  ProCertification,
} from "../../types";
import Island from "../../_components/Island";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { Tab } from "../../_components/Tab/Tab";
import Button from "@laborhack/custom-button";
import fallback from "../../assets/fallback.png";
import { Details, Identification } from "../../features/certifications";
import BackButton from "../../_components/BackButton";
import Badge from "../../_components/Badge";
import { Requirements } from "../../features/certifications/Requirements";
import { BadgeType } from "../../_components/Badge/Badge";
import { GET_PRO_CERTIFICATION } from "../../features/certifications/constants";

const MARK_UPLOADED = gql`
  mutation MarkUploaded($id: String!) {
    markProCertificationAsUploaded(id: $id) {
      id
      uploadedAt
    }
  }
`;

export const CertificationView = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<{
    proCertification: ProCertification;
  }>(GET_PRO_CERTIFICATION, {
    variables: {
      id,
    },
  });

  const [
    markUploaded,
    { error: markUploadedError, loading: markUploadedLoading },
  ] = useMutation(MARK_UPLOADED, {
    variables: {
      id,
    },
  });

  if (loading) return <Loading />;

  if (error || markUploadedError) return <RequestFailed />;

  if (!data) {
    return <RequestFailed />;
  }

  let identificationBadgeColor: BadgeType;

  switch (data.proCertification.pro.identityVerification?.status) {
    case IdentityVerificationStatus.VERIFIED:
      identificationBadgeColor = "success";
      break;

    case IdentityVerificationStatus.DECLINED:
      identificationBadgeColor = "cancelled";
      break;

    default:
      identificationBadgeColor = "progress";
      break;
  }

  const panes = [
    {
      id: "details",
      title: "Details",
      render: () => <Details pro={data.proCertification.pro} />,
    },
    {
      id: "identification",
      title: (
        <div className='flex flex-nowrap items-center gap-2'>
          <p className='m-0 p-0 '>Identification</p>{" "}
          <Badge
            text={
              data.proCertification.pro.identityVerification?.status.valueOf() ||
              "Pending"
            }
            type={identificationBadgeColor}
          />
        </div>
      ),
      render: () => (
        <Identification
          type={data?.proCertification.pro.identityVerification?.type}
          idNumber={
            data?.proCertification.pro.identityVerification
              ?.identificationNumber
          }
          documentImageUrl={
            data?.proCertification.pro.identityVerification
              ?.identificationDocument
          }
          dateOfBirth={
            data.proCertification.pro.identityVerification?.dateOfBirth
          }
        />
      ),
    },
    {
      id: "requirements",
      title: "Requirements",
      render: () => (
        <Requirements
          requirements={data.proCertification.certification.requirements}
          results={data.proCertification.results}
        />
      ),
    },
  ];

  const onlineAssessmentResult = data.proCertification.results.find(
    ({ certificationRequirement: { type, lmsId } }) => {
      return (
        type === CertificationRequirementType.THEORETICAL_ASSESSMENT && lmsId
      );
    }
  );

  const requirement = data.proCertification.certification.requirements.find(
    ({ id }) => {
      return id === onlineAssessmentResult?.certificationRequirement.id;
    }
  );

  const hasPassed =
    onlineAssessmentResult &&
    requirement &&
    onlineAssessmentResult.score >= requirement.passingScore;

  const photo =
    data.proCertification.pro.identityVerification?.returnedPhoto ||
    data.proCertification.pro.identityVerification?.photo ||
    fallback;

  return (
    <>
      <BackButton />
      <Island>
        <div className='mb-4'>
          <h2 className='text-primary-500 text-xl font-bold m-0'>
            Pro Certification Details{" "}
            <span className='text-primary-300'>
              ({data?.proCertification.id.substring(0, 8)})
            </span>
          </h2>
          <p className='m-0 text-primary-300'>View pro certification details</p>
        </div>
        <div className='w-full flex justify-end mt-2'>
          <Button
            disabled={
              data.proCertification.pro.identityVerification?.status !==
                IdentityVerificationStatus.VERIFIED ||
              !hasPassed ||
              !!data.proCertification.uploadedAt
            }
            variant='success'
            onClick={markUploaded}
            loading={markUploadedLoading}
          >
            <Icon name='check circle' className='p-0 !ml-0 mr-1' /> Mark Upload
          </Button>
        </div>
        <div className='my-8'>
          <div className='flex items-center gap-4'>
            <Image
              src={photo}
              as='a'
              size='small'
              href={photo}
              target='_blank'
            />
            <div className='flex flex-col gap-2'>
              <h3 className='text-primary-500 text-lg font-bold m-0'>
                {data?.proCertification.pro.firstName}{" "}
                {data?.proCertification.pro.lastName}
              </h3>

              <div className='flex gap-2'>
                {data?.proCertification.pro.trades.map((trade) => (
                  <p
                    key={trade.id}
                    className='italic text-primary-300 font-semibold'
                  >
                    {trade.name}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='w-full mb-8'>
          <Tab
            menu={{ secondary: true, pointing: true }}
            onTabChange={(e, data) => {}}
            panes={panes.map(({ render, title, id }) => ({
              render,
              menuItem: <Menu.Item key={id}>{title}</Menu.Item>,
            }))}
          />
        </div>
      </Island>
    </>
  );
};
