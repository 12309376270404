import { Order, Tradesman, TradesmanAssignment } from '../../types';

export enum AssignmentPaymentTypes {
  TRANSPORTATION = 'TRANSPORTATION',
  MATERIALS = 'MATERIALS',
  LABOR = 'LABOR',
}

export enum TransferTypes {
  HANDLING = 'HANDLING', // assignment handling cost like labor, materials, transportation
  REFUND = 'REFUND',
}
export enum TransferStatus {
  PENDING = 'PENDING',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
}

export interface TransferRequest {
  id: string;

  recipientCode: string;

  purpose: TransferTypes;

  status: TransferStatus;

  description?: string;

  amount?: number;

  initiatedBy: {
    firstName: string;
    lastName: string;
  };

  auditedBy?: {
    firstName: string;
    lastName: string;
  };

  approvedBy?: {
    firstName: string;
    lastName: string;
  };

  transferCode?: string;

  transactionReference?: string;

  createdAt: string;
}

export interface AssignmentPayment {
  id: string;
  order: Order;
  tradesman?: Tradesman;
  assignment: TradesmanAssignment;
  type: AssignmentPaymentTypes;
  transfer: TransferRequest;
  createdAt: Date;
}
