import React, { FC, Dispatch, SetStateAction } from "react";
import {
  Job,
  Tradesman,
  InvoiceMaterial,
  InvoiceDetails,
} from "../../../types";
import AssignmentItem from "./AssignmentItem";
import produce from "immer";

const InvoiceSection: FC<{
  jobs: Job[];
  category: {
    name: string;
    id: string;
  };
  location: string;
  tradesman: Tradesman;
  jobIdToInvoiceDetailUpdate: {
    [key: string]: InvoiceDetails;
  };
  setJobIdToInvoiceDetailUpdate: Dispatch<
    SetStateAction<{
      [key: string]: InvoiceDetails;
    }>
  >;
}> = ({
  jobs,
  category,
  location,
  tradesman,
  jobIdToInvoiceDetailUpdate,
  setJobIdToInvoiceDetailUpdate,
}) => {
  return (
    <>
      <tr
        className="o-invoiceSection__header"
        style={{
          borderTop: "none",
        }}
      >
        <td colSpan={3}>
          <div className="m-invoiceSection__divider">
            <div className="a-invoiceSection__dividerLine"></div>
            <p className="a-invoiceSection__dividerText">
              {`${tradesman.firstName} ${
                tradesman.lastName
              }'s ${category.name.toLowerCase()} work at ${location}`}
            </p>
            <div className="a-invoiceSection__dividerLine"></div>
          </div>
        </td>
      </tr>

      {jobs.map(({ id, invoiceDetails, ...rest }) => {
        return (
          <AssignmentItem
            key={id}
            job={{ id, invoiceDetails, ...rest }}
            materials={
              jobIdToInvoiceDetailUpdate[id]?.materials ||
              invoiceDetails?.materials ||
              []
            }
            labor={jobIdToInvoiceDetailUpdate[id]?.labor}
            existingLabor={invoiceDetails?.labor}
            onChange={(update: InvoiceDetails) => {
              setJobIdToInvoiceDetailUpdate((prevState) => {
                return produce(prevState, (newState) => {
                  newState[id] = update;
                });
              });
            }}
          />
        );
      })}

      <tr
        className="o-invoiceSection__header"
        style={{
          borderTop: "1px solid",
        }}
      >
        <td colSpan={3}>
          <div className="m-invoiceSection__divider">
            <div className="a-invoiceSection__dividerLine"></div>
            <p className="a-invoiceSection__dividerText">
              {`${tradesman.firstName} ${
                tradesman.lastName
              }'s ${category.name.toLowerCase()} work at ${location}`}
            </p>
            <div className="a-invoiceSection__dividerLine"></div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default InvoiceSection;
