import clsx from 'clsx';
import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';

import styles from './styles.module.scss';

export interface CounterProps {
  onChange: (count: number) => void;
  count: number;
}

export const Counter: FC<CounterProps> = ({ onChange, count }) => {
  return (
    <div className={styles.counter}>
      <Icon
        name="minus"
        className={clsx(styles.button, count <= 1 && styles.disabled)}
        onClick={() => count > 1 && onChange(count - 1)}
      />
      <div className={styles.count}>
        <span>{count}</span>
      </div>
      <Icon
        name="plus"
        className={styles.button}
        onClick={() => onChange(count + 1)}
      />
    </div>
  );
};
