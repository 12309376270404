import { gql, useQuery, useMutation } from "@apollo/client";
import { Button, Flex, Stack } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { components as LayoutComponents } from "../../../components/layout";
import BackButton from "../../../_components/BackButton";
import Island from "../../../_components/Island";
import Loading from "../../../_components/Loading";
import RequestFailed from "../../../_components/RequestFailed";
import { Tab } from "../../../_components/Tab/Tab";
import { ContractRecruitment, RecruitmentRequestStatus } from "../types";
import BasicRecruitmentDetails from "./components/BasicRecruitmentDetails";
import Contracts from "./components/Contracts";
import Quotations from "./components/Quotations";
import RecruitmentInvoices from "./components/RecruitmentInvoices";
import RequestDetails from "./components/RequestDetails";
import { RecruitmentContext } from "./context/recruitment.context";
import { SetRecruitmentDiscountModal } from "./components/SetRecruitmentDiscountModal";
import CustomButton from "@laborhack/custom-button";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.scss";
import { AssignedPros } from "./AssignedPros";
import { SetContractPaymentDetails } from "./SetContractPaymentDetails";
import ProRecruitmentContracts from "./pro-recruitment-contracts/ProRecruitmentContracts";
import PayrollPeriods from "./recruitment-payroll/PayrollPeriods";

const RECRUITMENT = gql`
  query RECRUITMENT($id: String!) {
    contractRecruitment(id: $id) {
      id
      client {
        id
        userId
        firstName
        lastName
        email
      }
      requests {
        id
        proCount
        proLevel
        categoryId
        contractDetails {
          length
          interval
        }
        proposedStartDate
      }
      activeQuotation {
        id
      }
      quotations {
        id
        status
        items {
          isActive
          level
          rate
          proCount
          contractDetails {
            length
            interval
          }
        }
        createdAt
      }
      paymentSettings {
        payBeforeService
      }
      status
      recruitmentDiscountValue
      jobs {
        id
        category {
          id
          name
        }
        pros {
          id
          firstName
          lastName
          email
          phoneNumber
          categories {
            id
          }
          trades {
            id
          }
        }
        request {
          matchingFeeInvoice {
            id
            sent
            isFullyPaid
            hasAtLeastOnePayment
            payment {
              status
            }
          }
        }
      }
      proRecruitmentContracts {
        id
        pro {
          id
          firstName
          lastName
          email
        }
        status
      }
      createdAt
    }
  }
`;

const ALL_PRO_RECRUITMENT_CONTRACTS_BY_RECRUITMENT = gql`
  query Query($recruitmentId: String!) {
    allProRecruitmentContractsByRecruitment(recruitmentId: $recruitmentId) {
      id
      pro {
        firstName
        lastName
        id
      }
      paymentSchedule
      paymentMethod
      paymentAmount
      unitMeasured
      category {
        name
      }
      startDate
      endDate
      status
    }
  }
`;

const GET_PAYROLL_PERIODS = gql`
  query GetRecruitmentPayrollPeriods($recruitmentId: String!) {
    getRecruitmentPayrollPeriods(recruitmentId: $recruitmentId) {
      start
      end
    }
  }
`;

const UPDATE_CONTRACT_RECRUITMENT = gql`
  mutation UpdateContractRecruitment($update: UpdateContractRecruitmentInput!) {
    updateContractRecruitment(update: $update) {
      id
      recruitmentDiscountValue
    }
  }
`;

const CREATE_RECRUITMENT_MATCH_INVOICE = gql`
  mutation CreateRecruitmentMatchInvoice(
    $recruitmentId: String!
    $recruitmentRequestId: String!
  ) {
    createContractRecruitmentRequestMatchInvoice(
      recruitmentId: $recruitmentId
      recruitmentRequestId: $recruitmentRequestId
    ) {
      id
    }
  }
`;

export const SEND_INVOICE = gql`
  mutation SendRecruitmentMatchInvoiceToC($invoiceId: String!) {
    sendInvoiceToClient(invoiceId: $invoiceId) {
      status
      message
    }
  }
`;

export const CANCEL_INVOICE = gql`
  mutation Cancel($invoiceId: String!) {
    cancelInvoice(invoiceId: $invoiceId) {
      id
      canceled
    }
  }
`;

const { Trail, CustomLabel } = LayoutComponents;

export interface SingleRecruitmentProps {}

export const SingleRecruitment: FC<SingleRecruitmentProps> = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const {
    loading,
    error: recruitmentError,
    data,
    refetch: refetchRecruitment,
  } = useQuery<{
    contractRecruitment: ContractRecruitment;
  }>(RECRUITMENT, {
    variables: {
      id,
    },
  });

  const {
    loading: proRecruitmentContractsLoading,
    error: proRecruitmentContractsError,
    data: proRecruitmentContractsData,
    refetch: refetchProRecruitmentContracts
  } = useQuery(ALL_PRO_RECRUITMENT_CONTRACTS_BY_RECRUITMENT, {
    variables: { recruitmentId: id },
  });

  const proRecruitmentContracts = proRecruitmentContractsData?.allProRecruitmentContractsByRecruitment;


  const [
    recruitmentDiscountModalOpen,
    setRecruitmentDiscountModalOpen,
  ] = React.useState(false);

  const [contractPaymentDetailsIsOpen, setContractPaymentDetailsIsOpen] = useState(false);
  const [selectedProId, setSelectedProId] = useState<string>("");
  const [selectedJobId, setSelectedJobId] = useState<string>("");

  const [updateContractRecruitment, updateRecruitmentCall] = useMutation(
    UPDATE_CONTRACT_RECRUITMENT,
    {
      onCompleted: () => {
        refetchRecruitment();
      },
    }
  );

  const [generateInvoice, generateInvoiceCall] = useMutation(
    CREATE_RECRUITMENT_MATCH_INVOICE,
    {
      variables: {
        recruitmentId: id,
        recruitmentRequestId: data?.contractRecruitment?.requests?.at(0)?.id,
      },
      onCompleted: () => {
        refetchRecruitment();
      },
    }
  );

  const [sendInvoice, sendInvoiceCall] = useMutation(SEND_INVOICE, {
    onCompleted: () => {
      refetchRecruitment();
    },
  });

  const [cancelInvoice, cancelInvoiceCall] = useMutation(CANCEL_INVOICE, {
    onCompleted: () => {
      refetchRecruitment();
    },
  });

  const error =
    recruitmentError ||
    proRecruitmentContractsError ||
    generateInvoiceCall.error ||
    cancelInvoiceCall.error ||
    sendInvoiceCall.error ||
    updateRecruitmentCall.error;

  if (loading || proRecruitmentContractsLoading ) {
    return <Loading />;
  }

  if (error) {
    return <RequestFailed errorMessage={error.message} />;
  }

  if (!data) {
    return <RequestFailed />;
  }

  const { contractRecruitment } = data;

  const { id: recruitmentId, status } = contractRecruitment;

  const color = {
    r: 0,
    g: 0,
    b: 0,
  };

  switch (status) {
    case RecruitmentRequestStatus.PENDING:
      color.r = 199;
      color.g = 156;
      color.b = 26;
      break;
    case RecruitmentRequestStatus.QUOTATION_SENT:
      color.r = 199;
      color.g = 156;
      color.b = 26;
      break;
    case RecruitmentRequestStatus.ASSIGNING:
      color.r = 199;
      color.g = 156;
      color.b = 26;
      break;
    case RecruitmentRequestStatus.FULFILLED:
      color.r = 86;
      color.g = 189;
      color.b = 102;
      break;

    default:
      break;
  }

  const panes = [
    {
      title: "Requests",
      count: contractRecruitment.requests.length,
      render: () => <RequestDetails recruitment={contractRecruitment} />,
    },
    /* {
      title: "Quotes",
      count: 0,
      render: () => (
        <Quotations
          contractRecruitmentId={contractRecruitment.id}
          quotations={contractRecruitment.quotations}
          activeQuotationId={contractRecruitment.activeQuotation?.id}
        ></Quotations>
      ),
    },*/
    {
      title: "Payroll",
      render: () => (
        <PayrollPeriods
        />
      ),
    },
    {
      title: "Contracts",
      render: () => (
        <ProRecruitmentContracts
          proRecruitmentContracts={proRecruitmentContracts} />
      ),
    },
    {
      title: "Invoices",
      render: () => (
        <RecruitmentInvoices contractRecruitmentId={contractRecruitment.id} />
      ),
    },
    {
      title: "Assigned Pros",
      render: () => (
        <>
          <SetContractPaymentDetails
            isOpen={contractPaymentDetailsIsOpen}
            onClose={() => setContractPaymentDetailsIsOpen(false)}
            jobId={selectedJobId}
            proId={selectedProId}
            refetchProRecruitmentContracts={refetchProRecruitmentContracts}
          />
          <AssignedPros
            jobs={contractRecruitment.jobs}
            proRecruitmentContracts={proRecruitmentContracts}
            refetchProRecruitmentContracts={refetchProRecruitmentContracts}
            setContractPaymentDetailsIsOpen={setContractPaymentDetailsIsOpen}
            setSelectedJobId={setSelectedJobId}
            setSelectedProId={setSelectedProId}
          />
        </>
      ),
    },
  ];

  const jobRequest = contractRecruitment?.jobs?.at(0)?.request;

  const matchingFeeInvoice = jobRequest?.matchingFeeInvoice;

  const matchingFeePaid = matchingFeeInvoice?.isFullyPaid;

  return (
    <RecruitmentContext.Provider value={{ recruitment: contractRecruitment }}>
      <SetRecruitmentDiscountModal
        isOpen={recruitmentDiscountModalOpen}
        onClose={() => setRecruitmentDiscountModalOpen(false)}
        recruitmentId={contractRecruitment.id}
        handleSubmitRecruitmentDiscount={(
          recruitmentId,
          recruitmentDiscountValue
        ) => {
          updateContractRecruitment({
            variables: {
              update: {
                id: recruitmentId,
                recruitmentDiscountValue,
              },
            },
          });
          setRecruitmentDiscountModalOpen(false);
        }}
      />
      <BackButton />
      <Island
        header={<Trail root="Recruitment" child={recruitmentId} />}
        text="Single recruitment view to manage contracts, raise quotations etc"
      >
        <div className={styles.labels}>
          <CustomLabel
            color={color}
            text={status.toString().replace("_", " ")}
          />
        </div>
        <Flex
          justifyContent="space-between"
          alignItems="start"
          ml="30px"
          mr="30px"
        >
          <BasicRecruitmentDetails recruitment={contractRecruitment} />

          <div className="w-full flex justify-end my-4">
            {jobRequest?.matchingFeeInvoice && (
              <CustomButton
                className="!bg-primary-150 !text-primary-500"
                type="subtle"
                onClick={() =>
                  history.push(
                    "/invoices/" + jobRequest?.matchingFeeInvoice?.id
                  )
                }
              >
                View Invoice
              </CustomButton>
            )}
            {!matchingFeeInvoice && (
              <Button
                isLoading={updateRecruitmentCall.loading}
                backgroundColor="brand.500"
                color="white"
                fontSize="14px"
                fontFamily="Poppins"
                fontWeight={700}
                mr="8px"
                _focus={{
                  outline: "none",
                }}
                onClick={() => setRecruitmentDiscountModalOpen(true)}
              >
                Set Matching Fee Discount
              </Button>
            )}
            {!matchingFeeInvoice && (
              <CustomButton
                className="bg-primary-500"
                onClick={() => generateInvoice()}
                loading={generateInvoiceCall.loading}
              >
                Generate Invoice
              </CustomButton>
            )}
            {matchingFeeInvoice && !matchingFeeInvoice.isFullyPaid && (
              <CustomButton
                className="ml-2 bg-red-500"
                onClick={() =>
                  cancelInvoice({
                    variables: {
                      invoiceId: matchingFeeInvoice.id,
                    },
                  })
                }
                loading={cancelInvoiceCall.loading}
              >
                Cancel Invoice
              </CustomButton>
            )}
            {matchingFeeInvoice && !matchingFeePaid && (
              <CustomButton
                className="ml-2 bg-primary-500"
                onClick={() => {
                  sendInvoice({
                    variables: {
                      invoiceId: matchingFeeInvoice.id,
                    },
                  });
                }}
                loading={sendInvoiceCall.loading}
              >
                {matchingFeeInvoice.sent ? "Resend" : "Send"} Invoice
              </CustomButton>
            )}
          </div>
        </Flex>
        <div className={styles.tabs}>
          <Tab
            menu={{ secondary: true, pointing: true }}
            onTabChange={(e, data) => {}}
            panes={panes.map(({ render, title, count }) => ({
              render,
              menuItem: <Menu.Item key={title}>{title}</Menu.Item>,
            }))}
          />
        </div>
      </Island>
    </RecruitmentContext.Provider>
  );
};
