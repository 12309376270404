import gql from "graphql-tag";

export const ADD_JOBS_TO_ORDER = gql`
  mutation AddJobs($orderId: String!, $payload: AddJobsToOrderInput!) {
    addJobsToOrder(orderId: $orderId, payload: $payload) {
      id
      jobs {
        id
        jobCode
        custom {
          name
          description
          resourceUrl
          subCategoryId
          categoryId
        }
        task {
          name
          description
          estimatedCost
          categoryId
          subCategoryId
          imageUrl
        }
        finalCost
        status
        invoiceDetails {
          materials {
            name
            unitPrice
            quantity
          }
          labor
        }
        completedAt
        payment {
          transactionReference
          transactionDate
          amount
          status
        }
        contact {
          notes
          location {
            id
            buildingNumber
            streetName
            area
            location
            landmark
            city
          }
        }
      }
      locationSchedules {
        locationId
        scheduledDate
      }
    }
  }
`;

export const SEND_INVOICE = gql`
  mutation send($invoiceId: String!) {
    sendInvoiceToClient(invoiceId: $invoiceId) {
      status
      message
    }
  }
`;

export const CANCEL_INVOICE = gql`
  mutation Cancel($invoiceId: String!) {
    cancelInvoice(invoiceId: $invoiceId) {
      id
      canceled
    }
  }
`;

export const CREATE_INSPECTION_INVOICE = gql`
  mutation Create($orderId: String!, $amount: Float!) {
    createInspectionInvoice(orderId: $orderId, amount: $amount) {
      id
      type
      invoiceNumber
      inspectionAmount
      invoiceDate
      options {
        materialCommissionCap
      }
      discount
      appliedDiscount {
        id
        code
        value
      }
      assignments {
        id
        progress
        order {
          id
          orderCode
          inspectionPayment {
            status
            amount
          }
          locationSchedules {
            locationId
            scheduledDate
          }
          isEmergency
        }
        jobs {
          id
          jobCode
          custom {
            name
            description
            resourceUrl
            subCategoryId
            categoryId
          }
          task {
            id
            name
            description
            estimatedCost
            categoryId
            subCategoryId
            imageUrl
          }
          finalCost
          status
          completedAt
          payment {
            transactionReference
            transactionDate
            amount
            status
          }
          contact {
            notes
            location {
              id
              buildingNumber
              streetName
              area
              location
              landmark
              city
            }
          }
          invoiceDetails {
            materials {
              name
              unitPrice
              quantity
            }
            labor
          }
        }
        date
      }
      recipient {
        name
        email
        phoneNumber
        address
      }
      payment {
        id
        transactionDate
        transactionReference
        status
      }
    }
  }
`;

export const EDIT_INSPECTION_INVOICE = gql`
  mutation EditInspectionInvoice(
    $invoiceId: String!
    $amount: Float!
    $allowedPaymentProviders: [String!]!
  ) {
    editInspectionInvoice(
      invoiceId: $invoiceId
      amount: $amount
      allowedPaymentProviders: $allowedPaymentProviders
    ) {
      id
      inspectionAmount
      allowedPaymentProviders
    }
  }
`;

export const CREATE_MATERIALS_INVOICE = gql`
  mutation CreateMaterialsInvoice($input: CreateMaterialsInvoiceInput!) {
    createMaterialsInvoice(input: $input) {
      id
    }
  }
`;

export const EDIT_MATERIALS_INVOICE = gql`
  mutation EditMaterialsInvoice($input: UpdateMaterialsInvoiceInput!) {
    updateMaterialsInvoice(input: $input) {
      id
    }
  }
`;

export const MERGE_ASSIGNMENTS = gql`
  mutation Merge($target: String!, $sources: [String!]!) {
    mergeAssignments(target: $target, sources: $sources) {
      id
      jobs {
        id
      }
    }
  }
`;
