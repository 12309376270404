import { GET_REFERRER_BY_ID } from "@/features/referrer-payouts/constants";
import { Referrer, ReferrerPayout } from "@/features/referrer-payouts/types";
import { formatDate } from "@/features/referrers/helpers";
import Loading from "@/_components/Loading";
import RequestFailed from "@/_components/RequestFailed";
import { CheckCircleOutlined } from "@ant-design/icons";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Button,
	Divider,
	FormControl,
	FormLabel,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Select,
	Spinner,
	Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const isValidAccountNumber = (accountNumber: string) => {
	return (
		accountNumber.trim().length === 10 &&
		!isNaN((accountNumber as unknown) as number)
	);
};

interface Bank {
	name: string;
	code: string;
}

const GET_LIST_OF_BANKS = gql`
	query ListBanks {
		listBanks {
			data {
				name
				code
			}
		}
	}
`;

const GET_BANK_ACCOUNT_INFO = gql`
	query GetBankAccountInfo($accountNumber: String!, $bankCode: String!) {
		getBankAccountInformation(
			input: { accountNumber: $accountNumber, bankCode: $bankCode }
		) {
			data {
				accountName
				accountNumber
			}
		}
	}
`;

const ADD_REFERRER_PAYMENT_INFO = gql`
	mutation AdminAddReferrerPaymentInformation(
		$referrerId: String!
		$payload: BankAccountInformationInput!
	) {
		adminAddReferrerPaymentInformation(
			referrerId: $referrerId
			payload: $payload
		) {
			id
		}
	}
`;

export default function ReferrerView() {
	const { id } = useParams<{ id: string }>();

	const [inputtingPaymentInfo, setInputtingPaymentInfo] = useState(false);

	const [accountNumber, setAccountNumber] = useState<string>("");
	const [bankCode, setBankCode] = useState<string>("");

	const { loading: bankListLoading, data } = useQuery<{
		listBanks: {
			data: Bank[];
		};
	}>(GET_LIST_OF_BANKS);

	const {
		loading: referrerLoading,
		error: referrerError,
		data: referrerData,
		refetch: refetchReferrer,
	} = useQuery<{ getReferrerById: Referrer }>(GET_REFERRER_BY_ID, {
		variables: {
			referrerId: id,
		},
	});

	const [
		getBankAccountInfo,
		{ loading: bankAccountInfoLoading, data: bankAccountInfo },
	] = useLazyQuery(GET_BANK_ACCOUNT_INFO);

	useEffect(() => {
		if (isValidAccountNumber(accountNumber) && bankCode) {
			getBankAccountInfo({
				variables: {
					bankCode,
					accountNumber,
				},
			});
		}
	}, [accountNumber, bankCode, getBankAccountInfo]);

	const [addReferrerPaymentInfo, addReferrerPaymentInfoResponse] = useMutation(
		ADD_REFERRER_PAYMENT_INFO,
		{
			onCompleted: () => {
				refetchReferrer();
			},
		}
	);

	const handleAddPaymentInfo = () => {
		addReferrerPaymentInfo({
			variables: {
				referrerId: id,
				payload: {
					accountNumber,
					bankCode,
				},
			},
		});
	};

	if (referrerLoading) return <Loading />;

	if (referrerError) return <RequestFailed />;

	if (!referrerData) return <RequestFailed />;

	const referrer = referrerData.getReferrerById;

	const referrerPaymentInfo =
		referrerData?.getReferrerById.paymentProviderInformation?.providerData[0]
			?.providers[0];

	if (addReferrerPaymentInfoResponse.error) {
		return (
			<RequestFailed
				errorMessage={addReferrerPaymentInfoResponse.error.message}
				onRetry={addReferrerPaymentInfoResponse.reset}
			/>
		);
	}

	return (
		<div className="grid grid-cols-3 gap-4">
			<div className="col-span-2">
				<div className="flex flex-col gap-8 p-6 bg-white border">
					<div className="mb-4">
						<h2 className="text-primary-500 text-lg font-medium m-0">
							Referrer #{id.slice(0, 8)}
						</h2>
						<p className="m-0 text-primary-300 text-sm">
							Shows a detailed view of a referrer
						</p>
					</div>
					<div className="grid grid-cols-[2fr,3fr] gap-2 max-w-lg">
						<p className="m-0 text-primary-300 text-sm">Referrer's name</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{referrer.name}
						</p>
						<p className="m-0 text-primary-300 text-sm">Business name</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{referrer.businessName}
						</p>
						<p className="m-0 text-primary-300 text-sm">Email</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{referrer.email}
						</p>
						<p className="m-0 text-primary-300 text-sm">Referral Code</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{referrer.referralCode}
						</p>
						<p className="m-0 text-primary-300 text-sm">Linked Discount</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{referrer.linkedDiscount.code}
						</p>
						<p className="m-0 text-primary-300 text-sm">
							Commission Percentage
						</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{referrer.commissionPercentage * 100}%
						</p>
						<p className="m-0 text-primary-300 text-sm">Date Created</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{formatDate(new Date(referrer.createdAt))}
						</p>
					</div>
				</div>
			</div>

			<div>
				<div className="flex flex-col gap-6 p-6 bg-white border">
					<div className="flex flex-col gap-4">
						<h3 className="font-medium text-primary-500 text-sm">
							Payment Information
						</h3>
						{!referrerPaymentInfo && (
							<Alert status="error">
								<AlertIcon />
								<AlertTitle className="text-sm !font-medium">
									No payment information found
								</AlertTitle>
								<AlertDescription></AlertDescription>
							</Alert>
						)}
						<div className="flex flex-col gap-2">
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Name</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{referrerPaymentInfo?.bankInformation.accountName || "N/A"}
								</p>
							</div>
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">AC Number</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{referrerPaymentInfo?.bankInformation.accountNumber || "N/A"}
								</p>
							</div>
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Bank Name</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{referrerPaymentInfo?.bankInformation.bankName || "N/A"}
								</p>
							</div>
							{!referrerPaymentInfo && (
								<div className="w-full mt-12 space-y-8">
									<Divider />
									{!inputtingPaymentInfo && (
										<Button
											onClick={() => setInputtingPaymentInfo(true)}
											colorScheme="primary"
											size="sm"
											width={"full"}
										>
											Input Payment Information
										</Button>
									)}
									{inputtingPaymentInfo && (
										<Stack spacing={4}>
											<FormControl>
												<FormLabel
													fontSize={"sm"}
													htmlFor="accountNumber"
													color="primary.500"
												>
													Account Number
												</FormLabel>
												<InputGroup>
													<Input
														id="accountNumber"
														fontSize={"sm"}
														type={"text"}
														name="name"
														value={accountNumber}
														onChange={(e) => setAccountNumber(e.target.value)}
														placeholder="1234567890"
													/>
													<InputRightElement>
														{isValidAccountNumber(accountNumber) && (
															<Icon as={CheckCircleOutlined} />
														)}
													</InputRightElement>
												</InputGroup>
											</FormControl>
											<FormControl isDisabled={bankListLoading} isRequired>
												<FormLabel
													fontSize={"sm"}
													htmlFor="bankName"
													color="primary.500"
												>
													Bank
												</FormLabel>
												<Select
													id="bankName"
													fontSize={"sm"}
													placeholder="Select option"
													value={bankCode}
													onChange={(e) => {
														setBankCode(e.target.value);
													}}
												>
													{data?.listBanks.data.map((bank: Bank) => {
														return (
															<option key={bank.code} value={bank.code}>
																{bank.name}
															</option>
														);
													})}
												</Select>
											</FormControl>
											<FormControl isRequired>
												<FormLabel
													fontSize={"sm"}
													htmlFor="accountName"
													color="primary.500"
												>
													Account Name
												</FormLabel>
												<InputGroup>
													<Input
														id="accountName"
														type="text"
														fontSize={"sm"}
														value={
															bankAccountInfo?.getBankAccountInformation?.data
																?.accountName || ""
														}
														disabled
														placeholder="Not available"
													/>
													<InputRightElement>
														{bankAccountInfoLoading && (
															<Spinner color="green.500" />
														)}
													</InputRightElement>
												</InputGroup>
											</FormControl>
											<Button
												colorScheme="primary"
												size={"sm"}
												isDisabled={
													!isValidAccountNumber(accountNumber) ||
													!bankCode ||
													!bankAccountInfo?.getBankAccountInformation?.data
														?.accountName
												}
												isLoading={addReferrerPaymentInfoResponse.loading}
												onClick={() => handleAddPaymentInfo()}
											>
												Save
											</Button>
										</Stack>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
