import React, { FC, useState, useEffect } from "react";
import { Grid, Form, Input, Button, Message } from "semantic-ui-react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { SUBMIT_CATEGORY_FORM } from "../../../graphql/query";
import {
  setDCategoryName,
  setDImage,
  setDStatus,
  SubmitCategoryForm,
  selectSubmissionStatus,
  selectSuccessMessage,
  selectErrorMessage,
  setDTitle,
} from "../../../redux/category.slice";
import { CatServiceSelectors } from "./../../../redux/category-service.slice";
import { IFileName } from "./../types";
import { ICategories } from "./../../allcategories/types";
import { useSelector } from "react-redux";
import Island from "../../../_components/Island";
import { useHistory } from "react-router-dom";

const CategoryForm: FC = () => {
  const { selectCategories } = CatServiceSelectors;
  const dispatch = useDispatch();
  const allCategory = useSelector(selectCategories);
  const allcategories = Object.entries(allCategory);
  const submissionStatus = useSelector(selectSubmissionStatus);
  const successMessage = useSelector(selectSuccessMessage);
  const formErrorMessage = useSelector(selectErrorMessage);

  const [categoryname, setCategoryName] = useState("");
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [img, setImg] = useState();
  const [uploadProgress, setUploadProgress] = useState("0%");
  const [errorMessage, setErrorMessage] = useState("");
  const [nameError, setNameError] = useState(false);

  const [submitForm] = useMutation(SUBMIT_CATEGORY_FORM);

  const CategoryNames: any = [];

  const history = useHistory();

  allcategories.forEach(([id, category]) => {
    return CategoryNames.push(category.name);
  });

  const checkName = () => {
    if (CategoryNames.indexOf(`${categoryname.replace(/\s*$/, "")}`) > -1) {
      setNameError(true);
      setErrorMessage("Category already exist");
    } else {
      setNameError(false);
      setErrorMessage("");
    }
  };

  useEffect(() => {
    if (successMessage !== "") {
      setTimeout(() => {
        history.push("/categories");
        window.location.reload();
      }, 2000);
    }
  }, [successMessage, history]);

  const uploadHandler = async () => {
    const fileData = new FormData();
    const image_file: any = img;
    const trimFileName = (file: IFileName) =>
      file.name.replace(new RegExp(/[^0-9a-zA-Z.]/g), "_");
    fileData.append("file", image_file, trimFileName(image_file));
    fileData.append("upload_preset", "ilq50gln");
    const APIURL = "https://api.cloudinary.com/v1_1/laborhack/image/upload";
    await axios
      .post(APIURL, fileData, {
        onUploadProgress: (ProgressEvent) => {
          setUploadProgress(
            `${Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)}%`
          );
        },
      })
      .then((res) => {
        const image_url = res.data.secure_url;
        setImageUrl(image_url);
        dispatch(setDStatus("pending"));
        dispatch(setDImage(image_url));
      });
  };

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={10}>
          <Island header='Create new category'>
            {successMessage !== "" && (
              <Message
                success
                header='Created successfully'
                content={successMessage}
              />
            )}
            {errorMessage !== "" && (
              <Message error header='Error' content={errorMessage} />
            )}
            {formErrorMessage !== "" && (
              <Message error header='Error' content={formErrorMessage} />
            )}
            <Form>
              <Form.Input
                required
                id='form-input-control-error-name'
                control={Input}
                label='Category Name'
                placeholder='Plumbing'
                value={categoryname}
                onChange={(e) => {
                  const { value } = e.target;
                  setCategoryName(value);
                }}
                onBlur={() => {
                  dispatch(setDCategoryName(categoryname));
                  checkName();
                }}
              />
              <Form.Input
                required
                id='form-input-control-error-name'
                control={Input}
                label='Title'
                placeholder='Plumber'
                value={title}
                onChange={(e) => {
                  const { value } = e.target;
                  setTitle(value);
                }}
                onBlur={() => {
                  dispatch(setDTitle(title));
                }}
              />

              <div>
                <Form.Input label='Upload Image (200px by 134px)'>
                  <input
                    width={5}
                    type='file'
                    name='image'
                    id=''
                    onChange={(e) => {
                      const [file]: any = e.target.files;
                      setImg(file);
                    }}
                  />
                </Form.Input>
              </div>
              <div className='o-category__form-img-details'>
                <p>Uploading: {uploadProgress}</p>
                <img
                  src={imageUrl}
                  className={`o-categoryImage__wrapper ${!img && "d-none"}`}
                  alt='category_image'
                />
              </div>

              <div>
                {submissionStatus === "idle" ? (
                  <Button
                    primary
                    {...((categoryname.replace(/\s/g, "") === "" ||
                      nameError === true) && {
                      disabled: true,
                    })}
                    onClick={(e) => {
                      if (img === undefined) {
                        dispatch(SubmitCategoryForm({ mutation: submitForm }));
                      } else {
                        uploadHandler().then(() => {
                          dispatch(
                            SubmitCategoryForm({ mutation: submitForm })
                          );
                        });
                      }
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button loading primary>
                    Loading
                  </Button>
                )}
              </div>
            </Form>
          </Island>
        </Grid.Column>
        <Grid.Column width={6}></Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CategoryForm;
