import { gql } from "@apollo/client";

export const UPDATE_ASSIGNMENT_PAYMENT = gql`
  mutation UpdateAssignmentPayment($data: UpdateAssignmentPaymentInput!) {
    updateAssignmentPayment(input: $data) {
      status
      message
    }
  }
`;

export const APPROVE_ASSIGNMENT_PAYMENT = gql`
  mutation ApproveAssignemtPayment($data: ApproveAssignmentPaymentInput!) {
    approveAssignmentPayment(input: $data) {
      status
      message
    }
  }
`;

export const FINALIZE_ASSIGNMENT_PAYMENT = gql`
  mutation Finalize($input: FinalizeAssignmentPaymentInput!) {
    finalizeAssignmentPayment(input: $input) {
      status
      message
    }
  }
`;

export const CANCEL_TRANSFER_REQUEST = gql`
  mutation CancelTransferRequest($data: CancelTransferRequestInput!) {
    cancelTransferRequest(data: $data) {
      id
    }
  }
`;

export const CONFIRM_OFFLINE_TRANSFER = gql`
  mutation ConfirmOfflineTransfer(
    $transferId: String!
    $transactionReference: String!
    $transactionDate: DateTimeISO!
    $bankName: String!
  ) {
    confirmOfflineTransfer(
      transferId: $transferId
      transactionReference: $transactionReference
      transactionDate: $transactionDate
      bankName: $bankName
    ) {
      id
      status
    }
  }
`;
