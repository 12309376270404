import { gql } from "@apollo/client";

export const LIST_OF_REFERRERS = gql`
	query ListOfReferrers {
		listOfReferrers {
			name
			referralCode
			paymentProviderInformation {
				providerData {
					providers {
						bankInformation {
							accountName
							accountNumber
						}
					}
					accountNumber
				}
			}
			id
			email
			commissionPercentage
			businessName
			linkedDiscount {
				code
			}
			createdAt
		}
	}
`;

export const CREATE_REFERRER = gql`
	mutation CreateReferrer($referrerInput: ReferrerInput!) {
		createReferrer(referrerInput: $referrerInput) {
			id
			name
		}
	}
`;