import React, { FC } from "react";
import Button from "@laborhack/custom-button";

import { AccountActivation } from "../types";
import { Icon } from "semantic-ui-react";
import { Confirmation } from "../../../_components/Confirmation/Confirmation";
import { gql, useMutation } from "@apollo/client";
import RequestFailed from "../../../_components/RequestFailed";

const RESEND_ACTIVATION_EMAIL = gql`
  mutation RESEND_ACTIVATION_EMAIL($accountActivationId: String!) {
    resendActivationEmail(accountActivationId: $accountActivationId) {
      id
      business {
        id
        email
      }
      client {
        id
        email
      }
      used
      sentEmail {
        id
        deliveryStatus
      }
    }
  }
`;

export interface ActivationEmailReportProps {
  accountActivation: AccountActivation;
}

export const ActivationEmailReport: FC<ActivationEmailReportProps> = ({
  accountActivation: { id, client, business, sentEmail, used },
}) => {
  const [open, setOpen] = React.useState(false);

  const [resend, { loading, error, reset }] = useMutation(
    RESEND_ACTIVATION_EMAIL,
    {
      variables: { accountActivationId: id },
      onCompleted: () => {
        setOpen(false);
      },
      onError: () => {
        setOpen(false);
      },
    }
  );

  const recipientEmail =
    sentEmail?.recipientEmail || client?.email || business.email;

  const deliveryStatus =
    (used && "Opened") || sentEmail?.deliveryStatus || "Delivered";

  const canResend = !used;

  const handleResend = () => {
    resend();
  };

  if (error) {
    return <RequestFailed onRetry={reset} />;
  }

  return (
    <>
      <Confirmation
        loading={loading}
        open={open}
        prompt={`This action would resend the activation email to ${recipientEmail}`}
        onConfirm={handleResend}
        onReject={() => setOpen(false)}
      />
      <div className='p-4 border rounded-lg border-primary-150 mb-2 last:mb-0'>
        <p className='text-lg font-bold text-primary-500 mb-4'>
          {recipientEmail}
        </p>
        <div className='grid grid-cols-[120px_2fr] w-full mb-2 items-center'>
          <p className=' text-primary-300 p-0 m-0'>Delivery Status</p>
          <div className='flex items-center'>
            <p className='text-primary-500 font-semibold p-0 m-0 !mr-4'>
              {deliveryStatus === "Pending" && (
                <Icon name='warning sign' color='yellow' />
              )}
              {deliveryStatus === "Failed" && (
                <Icon name='remove' color='red' />
              )}
              {deliveryStatus === "Delivered" && (
                <Icon name='check' color='green' />
              )}
              {deliveryStatus === "Opened" && (
                <Icon name='check' color='green' />
              )}
              {deliveryStatus}
            </p>
            {canResend && (
              <Button
                variant='basic'
                type='subtle'
                onClick={() => {
                  setOpen(true);
                }}
              >
                Resend
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
