import { FC } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Button from "@laborhack/custom-button";

import { components as LayoutComponents } from "../../../../../components/layout";
import Island from "../../../../../_components/Island";
import { NON_REFERENCED_CONTRACT_FIELDS } from "../../../graphql/fragments";
import { gql, useQuery } from "@apollo/client";
import { Contract } from "../../../types";
import Loading from "../../../../../_components/Loading";
import RequestFailed from "../../../../../_components/RequestFailed";
import { BasicDetails } from "./BasicDetails";
import { CatServiceSelectors } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import BackButton from "../../../../../_components/BackButton";

const { Trail } = LayoutComponents;

const GET_CONTRACT = gql`
  ${NON_REFERENCED_CONTRACT_FIELDS}
  query GetContract($id: String!) {
    contract(id: $id) {
      recruitment {
        id
        client {
          id
          firstName
          lastName
        }
      }
      pro {
        firstName
        lastName
      }
      ...NonReferencedContractFields
    }
  }
`;

const { selectCategories } = CatServiceSelectors;

export const ViewContract: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { path } = useRouteMatch();

  const categories = useSelector(selectCategories);

  const { loading, error, data } = useQuery<{ contract: Contract }>(
    GET_CONTRACT,
    {
      variables: {
        id,
      },
    }
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <RequestFailed errorMessage={error?.message} />;
  }

  const actions = [];

  /**
   * Add edit button if the pro has been assigned
   */
  if (data.contract.pro) {
    actions.push(
      <Button
        key='edit'
        onClick={() => {
          history.push(`${path.replace("/:id", "")}/edit/${id}`);
        }}
      >
        Edit Contract
      </Button>
    );
  } else {
    actions.push(
      <Button
        key='assign'
        onClick={() => history.push(`${path.replace("/:id", "")}/assign/${id}`)}
      >
        Assign Contract
      </Button>
    );
  }

  return (
    <>
      <BackButton />
      <Island header={<Trail root='Contract' child={id} />} actions={actions}>
        <div className='mb-4 flex items-center'>
          <div className='rounded-full py-1 px-3 bg-primary-150'>
            <p className='m-0 text-primary-500 font-semibold'>
              {categories[data.contract.categoryId].name}
            </p>
          </div>
        </div>
        <div className='my-8'>
          <BasicDetails contract={data.contract} />
        </div>
      </Island>
    </>
  );
};
