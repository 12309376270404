import React, { FC, ChangeEvent } from "react";
import { Job, InvoiceMaterial, InvoiceDetails } from "../../../types";
import { Icon } from "semantic-ui-react";
import { produce } from "immer";
import { formatAsNaira } from "../../../helpers/helpers";

const AssignmentItem: FC<{
  job: Job;
  onChange: (input: InvoiceDetails) => void;
  materials: InvoiceMaterial[];
  labor?: number;
  existingLabor?: number;
}> = ({ job, onChange, materials, labor, existingLabor }) => {
  const { task, custom } = job;

  const handleMaterialNameChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const text = e.target.value;

    const newMaterials = produce(materials, (newState) => {
      newState[index].name = text;
    });
    onChange({
      materials: newMaterials,
      labor,
    });
  };

  const handleMaterialUnitPriceChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const text = e.target.value;

    const newMaterials = produce(materials, (newState) => {
      newState[index].unitPrice = Number(text);
    });
    onChange({
      materials: newMaterials,
      labor,
    });
  };

  const handleMaterialQuantityChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const text = e.target.value;

    const newMaterials = produce(materials, (newState) => {
      newState[index].quantity = Number(text);
    });
    onChange({
      materials: newMaterials,
      labor,
    });
  };

  const handleFieldDelete = (index: number) => {
    const newMaterials = produce(materials, (newState) => {
      newState.splice(index, 1);
    });
    onChange({
      materials: newMaterials,
      labor,
    });
  };

  const handleAddField = () => {
    const newMaterials = produce(materials, (newState) => {
      newState.push({
        quantity: 0,
        name: "",
        unitPrice: 0,
      });
    });
    onChange({
      materials: newMaterials,
      labor,
    });
  };

  const handleLaborChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    onChange({
      materials,
      labor: Number(text),
    });
  };

  const calculateItemTotal = (materials: InvoiceMaterial[], labor?: number) => {
    const materialCost = materials.reduce((acc, curr) => {
      return acc + curr.quantity * curr.unitPrice;
    }, 0);

    return materialCost + (labor || 0);
  };

  return (
    <tr>
      <td>{task?.name || custom?.name || ""}</td>
      <td>
        <div className="m-editInvoice__descriptionWrapper">
          {materials.map(({ name, unitPrice, quantity }, index) => {
            return (
              <div
                key={index}
                className="m-editInvoice__descriptionFieldContainer"
              >
                <input
                  className="a-editInvoice__descriptionField"
                  onChange={(e) => {
                    handleMaterialNameChange(index, e);
                  }}
                  placeholder="Enter Material Name"
                  value={name}
                />
                <input
                  style={{
                    width: "60px",
                    flex: 0,
                  }}
                  className="a-editInvoice__descriptionField"
                  onChange={(e) => {
                    handleMaterialQuantityChange(index, e);
                  }}
                  type="number"
                  placeholder="QTY"
                  value={quantity ? quantity : undefined}
                />
                <input
                  style={{
                    width: "150px",
                    flex: 0,
                  }}
                  className="a-editInvoice__descriptionField"
                  onChange={(e) => {
                    handleMaterialUnitPriceChange(index, e);
                  }}
                  type="number"
                  placeholder="Unit Price"
                  value={unitPrice ? unitPrice : undefined}
                />

                <button
                  data-testid={`materialDelete${index}`}
                  className="a-editInvoice__descriptionFieldButton"
                  onClick={() => {
                    handleFieldDelete(index);
                  }}
                >
                  <Icon name="trash alternate" color="red"></Icon>
                </button>
              </div>
            );
          })}

          <div
            style={{
              width: "100%",
            }}
          >
            <button
              className="a-editInvoice__addDescriptionButton"
              onClick={() => {
                handleAddField();
              }}
            >
              <Icon name="plus circle"></Icon>
              Add Material
            </button>
          </div>

          <div
            className="m-editInvoice__laborField"
            style={{
              justifyContent: "flex-end",
            }}
          >
            <div>
              <label htmlFor="labor" style={{ margin: "0 10px 0 0" }}>
                Labor
              </label>
              <input
                style={{
                  flex: 0,
                  width: "150px",
                }}
                name="labor"
                type="number"
                onChange={(e) => {
                  handleLaborChange(e);
                }}
                defaultValue={existingLabor}
                value={labor ? labor : undefined}
                className="a-editInvoice__descriptionField"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </td>
      <td>
        {formatAsNaira(
          calculateItemTotal(
            materials,
            typeof labor === "number" ? labor : existingLabor
          )
        )}
      </td>
    </tr>
  );
};

export default AssignmentItem;
