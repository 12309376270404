import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Checkbox,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Link,
  Textarea,
} from "@chakra-ui/react";
import produce from "immer";
import { useEffect, useMemo, useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaEllipsisH,
  FaExternalLinkAlt,
} from "react-icons/fa";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AssignmentQuoteEntry } from "../../../../../features/orders/assignment-quote-entry";
import { GET_QUOTE } from "../../../../../features/orders/constants";
import {
  OrderHandlingQuotation,
  CreateOrderHandlingQuotationItem,
  TradesmanAssignment,
  CreateOrderHandlingQuotationItemMaterial,
} from "../../../../../types";
import Loading from "../../../../../_components/Loading";
import RequestFailed from "../../../../../_components/RequestFailed";

const CREATE_ORDER_HANDLING_QUOTATION = gql`
  mutation CreateOrderHandlingQuotation(
    $items: [CreateOrderHandlingQuotationItem!]!
    $assignmentIds: [String!]!
    $expiresAt: DateTimeISO!
    $notes: String
    $sendImmediately: Boolean
  ) {
    createOrderHandlingQuotation(
      items: $items
      assignmentIds: $assignmentIds
      expiresAt: $expiresAt
      notes: $notes
      sendImmediately: $sendImmediately
    ) {
      id
    }
  }
`;

const GET_ORDER_BY_CODE = gql`
  query GetOrderByCode($orderCode: String!) {
    getOrder(orderCode: $orderCode) {
      id
    }
  }
`;

const ASSIGNMENTS_BY_ORDER = gql`
  query AssignmentsByOrder($orderId: String!) {
    assignmentsByOrderId(orderId: $orderId) {
      id
      order {
        id
      }
      invoice {
        id
      }
      jobs {
        id
        task {
          id
          name
          description
        }
        custom {
          name
          description
        }
      }
      category {
        id
        name
      }
      tradesman {
        id
        firstName
        lastName
      }
      otherPros {
        id
      }
      currentQuote(includeUnsent: true) {
        id
        serialNumber
        isInUse
      }
    }
  }
`;

export const CreateQuotePage = () => {
  const history = useHistory();

  const { pathname, search } = useLocation();

  const [showError, setShowError] = useState(false);

  const [expiresAt, setExpiresAt] = useState<string>();

  const [notes, setNotes] = useState<string>("");

  const [sendImmediately, setSendImmediately] = useState(false);

  const { orderCode } = useParams<{
    orderCode: string;
  }>();

  /**
   * Get a reference quote from the query params
   */
  const searchParams = new URLSearchParams(search);

  const reference = searchParams.get("reference");

  const [getReferenceQuote, getReferenceQuoteHandle] = useLazyQuery<{
    orderHandlingQuotation: OrderHandlingQuotation;
  }>(GET_QUOTE, {
    variables: {
      id: reference,
    },
    onCompleted: (data) => {
      if (data.orderHandlingQuotation) {
        const { assignments, items } = data.orderHandlingQuotation;

        setSelectedAssignmentIds(
          assignments.map((assignment) => assignment.id)
        );

        setQuotationItems(
          items.map((item) => {
            const { materials, job, labor } = item;

            const convertedItem: CreateOrderHandlingQuotationItem = {
              jobId: job.id,
            };

            if (labor !== null) {
              convertedItem.labor = labor;
            }

            if (materials !== null) {
              convertedItem.materials = materials.map((material) => ({
                id: `${Math.random()}`,
                name: material.name,
                quantity: material.quantity,
                unitPrice: material.unitPrice,
              }));
            }

            return convertedItem;
          })
        );

        if (data.orderHandlingQuotation.notes) {
          setNotes(data.orderHandlingQuotation.notes);
        }

        setQuoteBreakdownOpen(true);
      }
    },
  });

  useEffect(() => {
    if (reference) {
      getReferenceQuote();
    }
  }, [reference]);

  const [selectedAssignmentIds, setSelectedAssignmentIds] = useState<string[]>(
    []
  );

  const [quoteBreakdownOpen, setQuoteBreakdownOpen] = useState(false);

  const getOrderByCodeHandle = useQuery<{
    getOrder: {
      id: string;
    };
  }>(GET_ORDER_BY_CODE, {
    variables: {
      orderCode,
    },
  });

  const [assignmentsByOrder, assignmentsByOrderHandle] = useLazyQuery<{
    assignmentsByOrderId: TradesmanAssignment[];
  }>(ASSIGNMENTS_BY_ORDER);

  const [
    createOrderHandlingQuotation,
    createOrderHandlingQuotationHandle,
  ] = useMutation<{
    createOrderHandlingQuotation: OrderHandlingQuotation;
  }>(CREATE_ORDER_HANDLING_QUOTATION, {
    onCompleted: (data) => {
      history.push(
        pathname.replace("create", data.createOrderHandlingQuotation.id)
      );
    },
  });

  useEffect(() => {
    if (getOrderByCodeHandle.data?.getOrder.id) {
      assignmentsByOrder({
        variables: {
          orderId: getOrderByCodeHandle.data.getOrder.id,
        },
      });
    }
  }, [getOrderByCodeHandle.data?.getOrder.id]);

  const [quotationItems, setQuotationItems] = useState<
    CreateOrderHandlingQuotationItem[]
  >([]);

  const quotationItemMap = useMemo(() => {
    return quotationItems.reduce((acc, item) => {
      acc[item.jobId] = item;
      return acc;
    }, {} as Record<string, CreateOrderHandlingQuotationItem>);
  }, [quotationItems]);

  const handleQuotationItemChange = (
    jobId: string,
    item: CreateOrderHandlingQuotationItem
  ) => {
    setQuotationItems((prev) => {
      return produce(prev, (draft) => {
        const index = draft.findIndex((item) => item.jobId === jobId);

        if (index === -1) {
          draft.push(item);
        }

        draft[index] = item;

        return draft;
      });
    });
  };

  if (getOrderByCodeHandle.loading || getReferenceQuoteHandle.loading) {
    return <Loading />;
  }

  if (getOrderByCodeHandle.error) {
    return <RequestFailed errorMessage={getOrderByCodeHandle.error.message} />;
  }

  if (createOrderHandlingQuotationHandle.error) {
    return (
      <RequestFailed
        errorMessage={createOrderHandlingQuotationHandle.error.message}
        onRetry={createOrderHandlingQuotationHandle.reset}
      />
    );
  }

  const selectedAssignments = assignmentsByOrderHandle.data?.assignmentsByOrderId.filter(
    (assignment) => selectedAssignmentIds.includes(assignment.id)
  );

  const isValidItem = (item: CreateOrderHandlingQuotationItem) => {
    const hasMaterials = item.materials && item.materials.length > 0;
    const hasLabor = item.labor !== undefined;

    if (!hasMaterials && !hasLabor) {
      return false;
    }

    if (hasMaterials) {
      const materialsAreValid = item.materials!.every(isValidMaterial);

      if (!materialsAreValid) {
        return false;
      }
    }

    if (hasLabor && item.labor! <= 0) {
      return false;
    }

    return true;
  };

  const isValidMaterial = (
    material: CreateOrderHandlingQuotationItemMaterial
  ) => {
    return material.name && material.quantity > 0 && material.unitPrice > 0;
  };

  const handleCreate = () => {
    /**
     * Make sure all items are valid and expiry is set
     */
    const itemsAreValid = quotationItems.every(isValidItem);

    const selectedJobs = selectedAssignments?.flatMap(
      (assignment) => assignment.jobs
    );

    const hasAnItemForEveryJob =
      selectedJobs && selectedJobs.length === quotationItems.length;

    if (!itemsAreValid || !expiresAt || !hasAnItemForEveryJob) {
      setShowError(true);
      return;
    }

    createOrderHandlingQuotation({
      variables: {
        items: quotationItems.map((item) => {
          const { materials, ...rest } = item;

          return {
            ...rest,
            materials: materials?.map(({ id, ...material }) => {
              return material;
            }),
          };
        }),
        assignmentIds: selectedAssignmentIds,
        expiresAt: new Date(expiresAt),
        ...(!!notes && { notes }),
        sendImmediately,
      },
    });
  };

  return (
    <div className='flex flex-col gap-4 '>
      <div className='flex'>
        <Button
          colorScheme='primary'
          variant='ghost'
          leftIcon={<FaArrowLeft />}
          onClick={() => {
            history.push(`/orders/${orderCode}`);
          }}
        >
          Back
        </Button>
      </div>
      <div className='flex flex-col gap-8 p-6 bg-white border items-start'>
        <div className='mb-4'>
          <h2 className='text-primary-500 text-lg font-semibold m-0'>
            Create Quote
          </h2>
        </div>
        <div className='flex flex-col gap-8 px-2 w-full'>
          <div className='flex flex-col gap-4 w-full'>
            <div className='flex flex-col gap-2'>
              <h3 className='text-primary-500 font-medium m-0'>
                Choose One or More Assignments
              </h3>
              <p className='text-primary-300 text-sm'>
                All assignments selected here would be used included in the
                quote. You can set cost data for each assignment in the next
                step.
              </p>
            </div>

            <TableContainer>
              <Table border={1} borderStyle='solid' borderColor='gray.200'>
                <Thead>
                  <Tr>
                    <Th className='border-gray-200 border-r'></Th>
                    <Th className='border-gray-200 border-r'>Category</Th>
                    <Th className='border-gray-200 border-r'>Tasks</Th>
                    <Th className='border-gray-200 border-r'>Assigned</Th>
                    <Th className='border-gray-200 border-r'>Linked To</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {assignmentsByOrderHandle.data?.assignmentsByOrderId.map(
                    (assignment) => {
                      const isDisabled =
                        quoteBreakdownOpen || assignment.currentQuote?.isInUse;
                      return (
                        <Tr key={assignment.id}>
                          <Td className='border-gray-200 border-r max-w-[32px]'>
                            <Checkbox
                              isDisabled={!!isDisabled}
                              isChecked={selectedAssignmentIds.includes(
                                assignment.id
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedAssignmentIds((prev) => {
                                    return produce(prev, (draft) => {
                                      draft.push(assignment.id);
                                    });
                                  });
                                } else {
                                  setSelectedAssignmentIds((prev) =>
                                    prev.filter((id) => id !== assignment.id)
                                  );
                                }
                              }}
                            />
                          </Td>
                          <Td className='border-gray-200 border-r'>
                            {assignment.category.name}
                          </Td>
                          <Td className='border-gray-200 border-r'>
                            {assignment.jobs.length}
                          </Td>
                          <Td className='border-gray-200 border-r'>
                            {assignment.tradesman.firstName}{" "}
                            {assignment.tradesman.lastName}
                            {assignment.otherPros.length > 0 && (
                              <Badge>+{assignment.otherPros.length}</Badge>
                            )}
                          </Td>
                          <Td className='border-gray-200 border-r'>
                            {assignment.currentQuote?.isInUse ? (
                              <div className='flex gap-2'>
                                <Link
                                  href={`/orders/${orderCode}/quotes/${assignment.currentQuote.id}`}
                                  isExternal
                                >
                                  #{assignment.currentQuote.serialNumber}{" "}
                                  <FaExternalLinkAlt className='inline-block' />
                                </Link>
                                <Badge colorScheme='red'>In Use</Badge>
                              </div>
                            ) : (
                              <Badge colorScheme='green'>Available</Badge>
                            )}
                          </Td>
                          <Td>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                aria-label='Options'
                                icon={<FaEllipsisH />}
                                variant='unstyled'
                              />
                              <MenuList>
                                <MenuItem
                                  icon={<FaExternalLinkAlt />}
                                  onClick={() => {
                                    window.open(
                                      `/orders/${orderCode}/assignments/${assignment.id}`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  View
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      );
                    }
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            {!quoteBreakdownOpen && (
              <div className='flex justify-end'>
                <Button
                  isDisabled={selectedAssignmentIds.length === 0}
                  colorScheme='primary'
                  rightIcon={<FaArrowRight />}
                  onClick={() => {
                    setQuoteBreakdownOpen(true);
                  }}
                >
                  <a href='#assignment-quote'>Continue</a>
                </Button>
              </div>
            )}
          </div>

          {quoteBreakdownOpen && (
            <>
              <div className='flex flex-col gap-4' id='assignment-quote'>
                <div className='flex flex-col gap-2'>
                  <h3 className='text-primary-500 font-medium m-0'>
                    Set Assignment Costs
                  </h3>
                  <p className='text-primary-300 text-sm'>
                    Set cost data for each assignment. You can set cost data for
                    each job in the assignment
                  </p>
                </div>

                {selectedAssignments?.map((assignment) => (
                  <AssignmentQuoteEntry
                    key={assignment.id}
                    assignment={assignment}
                    itemsMap={quotationItemMap}
                    onChange={(jobId, item) => {
                      handleQuotationItemChange(jobId, item);
                    }}
                    showErrors={showError}
                  />
                ))}
              </div>
              <div className='flex flex-col gap-4 max-w-lg' id='quote-settings'>
                <div className='flex flex-col gap-2'>
                  <h3 className='text-primary-500 font-medium m-0'>Settings</h3>
                  <p className='text-primary-300 text-sm'>
                    Add settings for the quote. Expiry date, notes, etc.
                  </p>
                </div>
                <div className='flex flex-col gap-4 items-start'>
                  <FormControl
                    isInvalid={showError && !expiresAt}
                    className='w-min'
                  >
                    <FormLabel className='!text-sm'>Offer Valid till</FormLabel>
                    <Input
                      type='datetime-local'
                      value={expiresAt}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setExpiresAt(e.target.value);
                      }}
                    />
                    {!showError ? (
                      <FormHelperText>
                        Set the date when the offer expires
                      </FormHelperText>
                    ) : (
                      <FormErrorMessage>
                        Offer expiry date is required
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl className='w-min'>
                    <FormLabel className='!text-sm'>
                      Additional Notes (Optional)
                    </FormLabel>
                    <Textarea
                      placeholder='Add additional notes'
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                    />

                    <FormHelperText>
                      Add any additional notes for the quote. They'll be visible
                      to the client
                    </FormHelperText>
                  </FormControl>

                  <FormControl className='w-min'>
                    <Checkbox
                      className='!text-sm'
                      isChecked={sendImmediately}
                      onChange={() => {
                        setSendImmediately(!sendImmediately);
                      }}
                    >
                      Send Quote Immediately
                    </Checkbox>
                  </FormControl>
                </div>
                <div className='flex justify-end gap-4'>
                  <Button
                    colorScheme='primary'
                    variant='ghost'
                    leftIcon={<FaArrowLeft />}
                    onClick={() => {
                      setQuoteBreakdownOpen(false);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    colorScheme='primary'
                    rightIcon={<FaArrowRight />}
                    onClick={handleCreate}
                    isLoading={createOrderHandlingQuotationHandle.loading}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
