import clsx from 'clsx';
import React, { FC } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import CustomBadge from '@laborhack/badge';
import { formatAsNaira } from '../../../../helpers/helpers';

import styles from './styles.module.scss';

export interface PaymentProviderCardProps {
  name: string;
  logo: string;
  transactionFee?: number;
  balance: number;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
}

export const PaymentProviderCard: FC<PaymentProviderCardProps> = ({
  name,
  logo,
  balance,
  onClick,
  selected,
  disabled,
}) => {
  return (
    <div
      className={clsx(styles.paymentProviderCard, {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
      })}
      onClick={() => !disabled && onClick()}
    >
      <FaCheckCircle className={clsx(styles.stickTopRight, styles.checkIcon)} />
      {disabled && (
        <div className={styles.stickTopRight}>
          <CustomBadge type="basic" size="small" inverted>
            disabled
          </CustomBadge>
        </div>
      )}

      <div className={styles.body}>
        <div className={styles.header}>
          <h3 className={styles.name}>Pay with {name}</h3>
          <p className={styles.transactionFee}>
            <span>
              <strong>{formatAsNaira(balance)}</strong>
            </span>{' '}
            left in wallet
          </p>
        </div>
        <img src={logo} alt={name} />
      </div>
    </div>
  );
};
