import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import clsx from 'clsx';
import Badge from '@laborhack/badge';

import styles from './styles.module.scss';
import { Counter } from './Counter';

export interface CartItemProps {
  imageUrl?: string;
  count: number;
  name: string;
  isCustom?: boolean;
  onCountChange: (count: number) => void;
}

export const CartItem: FC<CartItemProps> = ({
  imageUrl,
  name,
  isCustom,
  count,
  onCountChange,
}) => {
  const shownImage = imageUrl || '';

  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.partition, styles.information)}>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${shownImage})`,
          }}
        ></div>
        <div className={styles.itemInformation}>
          <h6>{name}</h6>
          {isCustom && (
            <Badge type="default" inverted>
              custom
            </Badge>
          )}
        </div>
      </div>
      <div className={clsx(styles.partition, styles.quantity)}>
        <Counter count={count} onChange={onCountChange} />
      </div>
      <div className={clsx(styles.partition, styles.actions)}>
        <Icon
          name="trash"
          onClick={() => {
            onCountChange(0);
          }}
          className={styles.remove}
        />
      </div>
    </div>
  );
};
