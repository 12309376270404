import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { GrFormCheckmark, GrFormClose } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import {
  Category,
  Gender,
  IncomeLevel,
  OutreachSource,
  OutreachSourceType,
  ProLeadSource,
  TrainingType,
  YearsOfExperience,
} from "@/types";
import RequestFailed from "@/_components/RequestFailed";
import { nonEmptyString } from "../../helpers/helpers";
import { Select as CRSelect } from "chakra-react-select";

const experienceLevelOptions = [
  { label: "less than 1 year", value: YearsOfExperience.UNDER_ONE },
  { label: "1 year", value: YearsOfExperience.ONE },
  { label: "2 years", value: YearsOfExperience.TWO },
  { label: "3 years", value: YearsOfExperience.THREE },
  { label: "4 years", value: YearsOfExperience.FOUR },
  { label: "5 years", value: YearsOfExperience.FIVE },
  { label: "6 - 10 years", value: YearsOfExperience.SIX_TO_TEN },
  { label: "more than 10 years", value: YearsOfExperience.ABOVE_TEN },
];

const trainingTypeOptions = [
  { label: "Government Technical College", value: TrainingType.COLLEGE },
  { label: "Polytechnic", value: TrainingType.POLYTECHNIC },
  { label: "TVET Training Institute", value: TrainingType.TRAINING_INSTITUTE },
  { label: "Apprenticeship", value: TrainingType.APPRENTICE },
  { label: "Other", value: TrainingType.OTHER },
];

const incomeLevelOptions = [
  { label: "Below NGN30,000.00", value: IncomeLevel.UNDER_30K },
  {
    label: "NGN30,000.00 - NGN50,000.00",
    value: IncomeLevel.BETWEEN_30K_AND_50K,
  },
  {
    label: "NGN50,000.00 - NGN70,000.00",
    value: IncomeLevel.BETWEEN_50K_AND_70K,
  },
  {
    label: "NGN70,000.00 - NGN100,000.00",
    value: IncomeLevel.BETWEEN_70K_AND_100K,
  },
  {
    label: "NGN100,000.00 - NGN150,000.00",
    value: IncomeLevel.BETWEEN_100K_AND_150K,
  },
  {
    label: "NGN150,000.00 - NGN200,000.00",
    value: IncomeLevel.BETWEEN_150K_AND_200K,
  },
  { label: "Above NGN200,000.00", value: IncomeLevel.OVER_200K },
];

const leadSourceOptions = [
  { label: "Radio", value: ProLeadSource.RADIO },
  { label: "From a friend", value: ProLeadSource.FROM_A_FRIEND },
  { label: "Flyer", value: ProLeadSource.FLYER },
  { label: "Social Media", value: ProLeadSource.SOCIAL_MEDIA },
  { label: "Google Search", value: ProLeadSource.GOOGLE },
  { label: "OPL Academy", value: ProLeadSource.OPL_ACADEMY },
  {
    label: "LaborHack Community Outreach Team",
    value: ProLeadSource.COMMUNITY_OUTREACH,
  },
  { label: "Other", value: ProLeadSource.OTHER },
];

const countries: readonly string[] = [
  "Nigeria",
];

const stateOfResidenceOptions = [{ label: "Lagos", value: "Lagos" }];

const CREATE_NEW_CHANNEL = "CREATE_NEW_CHANNEL";

const CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
      proTitle
      isVisible
    }
  }
`;

const FETCH_OUTREACH_SOURCES = gql`
  query FetchOutreachSources {
    fetchOutreachSources {
      id
      name
      type
    }
  }
`;

const CREATE_PRO_APPLICATION = gql`
  mutation CreateProApplication($input: CreateProApplicationDTO!) {
    createProApplication(input: $input) {
      id
    }
  }
`;

const CREATE_OUTREACH_SOURCE = gql`
  mutation CreateOutreachSource($payload: OutreachSourceInput!) {
    createOutreachSource(payload: $payload) {
      id
      name
      type
    }
  }
`;

export interface ApplicationFormProps {}

export const ApplicationForm = () => {
  const { push } = useHistory();
  const toast = useToast();

  const [
    createOutreachSource,
    { error: newOutreachSourceError, reset: resetCreateOutreachSourceError },
  ] = useMutation(CREATE_OUTREACH_SOURCE, {
    onCompleted: async (data) => {
      setIsRefetching(true);

      await refetchOutreachSources();

      if (data?.createOutreachSource.type === OutreachSourceType.CHANNEL) {
        setChannel(data?.createOutreachSource.id);
      }

      setIsRefetching(false);
    },
  });

  const [createProApplication, createProApplicationResponse] = useMutation(
    CREATE_PRO_APPLICATION,
    {
      onCompleted: (data) => {
        toast({
          title: "Pro Created",
          description: "The application has been submitted successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });

        push("/pros");
      },
    }
  );

  const { error, data } = useQuery<{
    categories: Category[];
  }>(CATEGORIES);

  const categoryMap = useMemo(() => {
    if (!data?.categories) {
      return {};
    }

    return data.categories.reduce((acc, category) => {
      acc[category.id] = category;
      return acc;
    }, {} as Record<string, Category>);
  }, [data?.categories]);

  const {
    loading: loadingOutreachSources,
    data: outreachSourcesData,
    refetch: refetchOutreachSources,
  } = useQuery<{ fetchOutreachSources: OutreachSource[] }>(
    FETCH_OUTREACH_SOURCES
  );

  const outreachChannels = useMemo(() => {
    return outreachSourcesData?.fetchOutreachSources.filter(({ type }) => {
      return type === OutreachSourceType.CHANNEL;
    });
  }, [outreachSourcesData]);

  /** States to hold form data */
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [dateOfBirth, setDateOfBirth] = React.useState<string>();
  const [gender, setGender] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [stateOfResidence, setStateOfResidence] = React.useState("");
  const [experienceLevel, setExperienceLevel] = React.useState<string>("");
  const [incomeLevel, setIncomeLevel] = React.useState("");
  const [country, setCountry] = React.useState("Nigeria");
  
  const [
    numberOfDaysActivePerWeek,
    setNumberOfDaysActivePerWeek,
  ] = React.useState<number>(0);
  const [trainingType, setTrainingType] = React.useState<string>("");
  const [leadSource, setLeadSource] = React.useState<string>(
    ProLeadSource.COMMUNITY_OUTREACH
  );
  const [otherLeadSource, setOtherLeadSource] = React.useState("");
  const [category, setCategory] = React.useState<string>("");
  const [otherCategoryText, setOtherCategoryText] = React.useState<string>("");
  const [channel, setChannel] = React.useState<string>("");
  const [otherChannel, setOtherChannel] = React.useState<string>("");
  const [isRefetching, setIsRefetching] = React.useState<boolean>(false);

  if (loadingOutreachSources) {
    return null;
  }

  const getStringFieldChangeHandler = (
    handler: Dispatch<SetStateAction<string>>
  ) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      handler(e.target.value);
    };
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value.trim());
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value.trim());
  };

  const handleCreateNewChannel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    createOutreachSource({
      variables: {
        payload: {
          name: otherChannel.trim(),
          type: OutreachSourceType.CHANNEL,
        },
      },
    });
  };

  const isValidPhoneNumber = (phoneNumberString: string) => {
    const cleaned = phoneNumberString.trim();

    // Check if the cleaned number is 10 digits long
    if (cleaned.length === 10) {
      return true;
    }

    // If it's not 10 digits long, it's not a number.
    return false;
  };

  if (error) {
    return <RequestFailed errorMessage={error.message} />;
  }

  if (createProApplicationResponse.error || newOutreachSourceError) {
    return (
      <RequestFailed
        errorMessage={
          createProApplicationResponse?.error?.message ||
          newOutreachSourceError?.message
        }
        onRetry={() => {
          createProApplicationResponse.reset();
          resetCreateOutreachSourceError();
        }}
      />
    );
  }

  const isOtherCategory = categoryMap[category]?.name === "Other";

  console.log("isOtherCategory", isOtherCategory);
  console.log("categoryMap", categoryMap);

  const canSubmit =
    firstName &&
    lastName &&
    isValidPhoneNumber(phone) &&
    !!email &&
    dateOfBirth &&
    address &&
    stateOfResidence &&
    country &&
    experienceLevel &&
    incomeLevel &&
    numberOfDaysActivePerWeek &&
    trainingType &&
    leadSource &&
    (leadSource === ProLeadSource.OTHER ? otherLeadSource : true) &&
    category &&
    (isOtherCategory ? !!otherCategoryText : true) &&
    channel !== CREATE_NEW_CHANNEL;

  const handleSubmit = () => {
    const input = {
      firstName,
      lastName,
      email,
      phoneNumber: "+234" + phone,
      dateOfBirth: new Date(dateOfBirth!).toISOString(), // the canSubmit check ensures that this is not null
      gender,
      address,
      stateOfResidence,
      country,
      incomeLevel,
      numberOfDaysWorkedPerWeek: numberOfDaysActivePerWeek,
      yearsOfExperience: experienceLevel,
      trainingType,
      leadSource:
        leadSource === ProLeadSource.OTHER ? otherLeadSource : leadSource,
      categoryId: category,
      ...(isOtherCategory && { otherCategoryText }),
      ...(channel && channel !== CREATE_NEW_CHANNEL
        ? { channelOutreachSourceId: channel }
        : {}),
    };

    createProApplication({ variables: { input } });
  };

  return (
    <>
      <div className='grid !grid-cols-1 md:!grid-cols-2 gap-y-4 w-full'>
        <div>
          <h3 className='font-semibold text-primary-500'>
            Personal Information
          </h3>
          <p className='text-sm text-primary-300'>
            Provide your personal information
          </p>
        </div>
        <Stack spacing={2} className='px-2 md:px-4'>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            isRequired
          >
            <FormLabel fontSize={"sm"} htmlFor='firstName' color='primary.500'>
              First Name
            </FormLabel>
            <Input
              id='firstName'
              fontSize={"sm"}
              type={"text"}
              value={firstName}
              onChange={handleFirstNameChange}
              placeholder='Tolu'
            />
          </FormControl>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            isRequired
          >
            <FormLabel fontSize={"sm"} htmlFor='lastName' color='primary.500'>
              Last Name
            </FormLabel>
            <Input
              id='lastName'
              fontSize={"sm"}
              type={"text"}
              value={lastName}
              onChange={handleLastNameChange}
              placeholder='Holmes'
            />
          </FormControl>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            isRequired
          >
            <FormLabel fontSize={"sm"} color='primary.500'>
              Date of Birth
            </FormLabel>
            <Input
              id='dateOfBirth'
              fontSize={"sm"}
              type={"date"}
              alignItems='center'
              value={dateOfBirth}
              onChange={(event) => {
                setDateOfBirth(event.target.value);
              }}
            />
          </FormControl>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            as='fieldset'
          >
            <FormLabel fontSize={"sm"} as='legend'>
              Sex
            </FormLabel>
            <RadioGroup
              colorScheme={"brand"}
              value={gender}
              onChange={setGender}
            >
              <Stack spacing={2}>
                <Radio size={"sm"} value={Gender.MALE}>
                  Male
                </Radio>
                <Radio size={"sm"} value={Gender.FEMALE}>
                  Female
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        </Stack>
      </div>
      <div className='my-4 md:my-8 w-full h-0.5 bg-primary-100' />
      <div className='grid !grid-cols-1 md:!grid-cols-2 gap-y-4 w-full '>
        <div>
          <h3 className='font-semibold text-primary-500'>
            Contact Information
          </h3>
          <p className='text-sm text-primary-300'>
            Provide your contact information
          </p>
        </div>
        <Stack spacing={2} className='px-2 md:px-4'>
          <FormControl isRequired>
            <FormLabel fontSize={"sm"} htmlFor='email' color='primary.500'>
              Email Address
            </FormLabel>
            <InputGroup>
              <Input
                id='email'
                type={"email"}
                fontSize={"sm"}
                value={email}
                onChange={getStringFieldChangeHandler(setEmail)}
              />
            </InputGroup>
          </FormControl>
          <FormControl
            isRequired
            isDisabled={createProApplicationResponse.loading}
            isInvalid={nonEmptyString(phone) && !isValidPhoneNumber(phone)}
          >
            <FormLabel fontSize={"sm"} htmlFor='phone' color='primary.500'>
              Phone Number
            </FormLabel>
            <InputGroup>
              <InputLeftAddon fontSize={"sm"} children='+234' />
              <Input
                id='phone'
                type={"phone"}
                fontSize={"sm"}
                value={phone}
                onChange={getStringFieldChangeHandler(setPhone)}
                placeholder='80312345678'
              />
              {nonEmptyString(phone) && (
                <InputRightElement
                  children={
                    isValidPhoneNumber(phone) ? (
                      <Icon
                        as={GrFormCheckmark}
                        w='6'
                        h='6'
                        color={"green.500"}
                      />
                    ) : (
                      <Icon as={GrFormClose} w='6' h='6' color={"red.500"} />
                    )
                  }
                />
              )}
            </InputGroup>
            <FormHelperText>
              {!isValidPhoneNumber(phone) && (
                <FormErrorMessage>
                  Please enter a valid phone number
                </FormErrorMessage>
              )}
            </FormHelperText>
          </FormControl>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            isRequired
          >
            <FormLabel fontSize={"sm"} htmlFor='address' color='primary.500'>
              Address
            </FormLabel>
            <Input
              id='address'
              fontSize={"sm"}
              value={address}
              onChange={getStringFieldChangeHandler(setAddress)}
              placeholder='1234, Main Street'
            />
          </FormControl>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            isRequired
          >
            <FormLabel
              fontSize={"sm"}
              htmlFor='stateOfResidence'
              color='primary.500'
            >
              State of Residence
            </FormLabel>
            <Select
              fontSize={"sm"}
              placeholder='Select State'
              value={stateOfResidence}
              onChange={(event) => setStateOfResidence(event.target.value)}
            >
              {stateOfResidenceOptions.map(({ label, value }) => {
                return (
                  <option key={label} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            isRequired
            isInvalid={!country}
          >
            <FormLabel fontSize={"sm"} htmlFor='country' color='primary.500'>
              Country
            </FormLabel>
            <CRSelect
              id='country'
              placeholder='Select option'
              defaultInputValue={country}
              options={countries.map((country) => ({
                label: country,
                value: country,
              }))}
              onChange={(selectedOption) => {
                if (selectedOption) setCountry(selectedOption.value);
              }}
            />

            {!country && (
              <FormErrorMessage fontSize={"sm"}>
                Please select your country
              </FormErrorMessage>
            )}
          </FormControl>
        </Stack>
      </div>
      <div className='my-4 md:my-8 w-full h-0.5 bg-primary-100' />
      <div className='grid !grid-cols-1 md:!grid-cols-2 gap-y-4 w-full '>
        <div>
          <h3 className='font-semibold text-primary-500'>
            Professional Information
          </h3>
          <p className='text-sm text-primary-300'>
            Provide your professional information
          </p>
        </div>
        <Stack spacing={2} className='px-2 md:px-4'>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            isRequired
          >
            <FormLabel fontSize={"sm"} htmlFor='trade' color='primary.500'>
              Trade
            </FormLabel>
            <Select
              id='trade'
              fontSize={"sm"}
              placeholder='Select option'
              value={category}
              onChange={(event) => setCategory(event.target.value)}
            >
              {data?.categories.map((category) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.proTitle}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {isOtherCategory && (
            <FormControl
              isDisabled={createProApplicationResponse.loading}
              isRequired
              isInvalid={!otherCategoryText}
            >
              <FormLabel
                fontSize={"sm"}
                htmlFor='otherCategoryText'
                color='primary.500'
              >
                Please specify your trade
              </FormLabel>
              <Input
                id='otherCategoryText'
                fontSize={"sm"}
                value={otherCategoryText}
                onChange={getStringFieldChangeHandler(setOtherCategoryText)}
              />
              {!otherCategoryText && (
                <FormErrorMessage fontSize={"sm"}>
                  Please specify your trade
                </FormErrorMessage>
              )}
            </FormControl>
          )}
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            isRequired
          >
            <FormLabel fontSize={"sm"} htmlFor='experience' color='primary.500'>
              Experience Level
            </FormLabel>
            <Select
              id='experience'
              fontSize={"sm"}
              placeholder='Select option'
              value={experienceLevel}
              onChange={(event) => setExperienceLevel(event.target.value)}
            >
              {experienceLevelOptions.map(({ label, value }) => {
                return (
                  <option key={label} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            as='fieldset'
          >
            <FormLabel fontSize={"sm"} as='legend' color='primary.500'>
              How did you learn your trade?
            </FormLabel>
            <RadioGroup
              colorScheme={"brand"}
              value={trainingType}
              onChange={setTrainingType}
            >
              <Stack spacing={2}>
                {trainingTypeOptions.map(({ label, value }) => {
                  return (
                    <Radio size={"sm"} value={value} key={value}>
                      {label}
                    </Radio>
                  );
                })}
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            as='fieldset'
          >
            <FormLabel fontSize={"sm"} as='legend' color='primary.500'>
              What is your current monthly income? (I do not earn less than this
              monthly)
            </FormLabel>
            <Select
              fontSize={"sm"}
              placeholder='Select option'
              value={incomeLevel}
              onChange={(event) => setIncomeLevel(event.target.value)}
            >
              {incomeLevelOptions.map(({ label, value }) => {
                return (
                  <option key={label} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            as='fieldset'
          >
            <FormLabel fontSize={"sm"} as='legend' color='primary.500'>
              On average, How many days do you work per week?
            </FormLabel>
            <Select
              fontSize={"sm"}
              placeholder='Select option'
              value={numberOfDaysActivePerWeek}
              onChange={(event) =>
                setNumberOfDaysActivePerWeek(Number(event.target.value))
              }
            >
              {[0, 1, 2, 3, 4, 5, 6, 7].map((value) => {
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            isDisabled={createProApplicationResponse.loading}
            isRequired
          >
            <FormLabel fontSize={"sm"} htmlFor='leadSource' color='primary.500'>
              How did you hear about us?
            </FormLabel>
            <Select
              fontSize={"sm"}
              placeholder='Select option'
              value={leadSource}
              onChange={(event) => setLeadSource(event.target.value)}
            >
              {leadSourceOptions.map(({ label, value }) => {
                return (
                  <option key={label} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {leadSource === ProLeadSource.OTHER && (
            <FormControl
              isDisabled={createProApplicationResponse.loading}
              isRequired
            >
              <FormLabel
                fontSize={"sm"}
                htmlFor='leadSourceOther'
                color='primary.500'
              >
                Please specify how you heard about us
              </FormLabel>
              <Input
                id='leadSourceOther'
                fontSize={"sm"}
                value={otherLeadSource}
                onChange={getStringFieldChangeHandler(setOtherLeadSource)}
              />
            </FormControl>
          )}
          <FormControl isDisabled={createProApplicationResponse.loading}>
            <FormLabel fontSize={"sm"} htmlFor='leadSource' color='primary.500'>
              Channel
            </FormLabel>
            <Select
              fontSize={"sm"}
              placeholder='Select option'
              value={channel}
              onChange={(event) => setChannel(event.target.value)}
            >
              {outreachChannels?.map((channel: OutreachSource) => {
                return (
                  <option key={channel.id} value={channel.id}>
                    {channel.name}
                  </option>
                );
              })}
              <option key={CREATE_NEW_CHANNEL} value={CREATE_NEW_CHANNEL}>
                Create New Channel
              </option>
            </Select>
          </FormControl>
          {channel === CREATE_NEW_CHANNEL && (
            <FormControl
              isDisabled={createProApplicationResponse.loading}
              isRequired
            >
              <FormLabel
                fontSize={"sm"}
                htmlFor='leadSourceOther'
                color='primary.500'
              >
                New Channel Name
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  id='channel-input'
                  fontSize={"sm"}
                  value={otherChannel}
                  onChange={(event) => setOtherChannel(event.target.value)}
                />
                <InputRightElement width='12.5rem'>
                  <Button
                    h='1.75rem'
                    size='sm'
                    isLoading={isRefetching}
                    onClick={handleCreateNewChannel}
                  >
                    Create New Channel
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Stack>
      </div>
      <div className='my-4 md:my-8 w-full h-0.5 bg-primary-100' />
      <div className='flex justify-end'>
        <Button
          fontSize={"sm"}
          colorScheme={"red"}
          variant='ghost'
          className='mr-2'
        >
          Cancel
        </Button>
        <Button
          isLoading={createProApplicationResponse.loading}
          isDisabled={!canSubmit}
          loadingText='Submitting'
          fontSize={"sm"}
          colorScheme={"green"}
          variant={"solid"}
          spinnerPlacement='start'
          onClick={handleSubmit}
        >
          Submit Application
        </Button>
      </div>
    </>
  );
};
