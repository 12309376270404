import { createContext, useEffect, useState } from "react";

interface IToggles {
  [key: string]: boolean;
}

interface IApplicationContext {
  toggles?: IToggles;
}

const environment = process.env.REACT_APP_DEPLOY_ENV || "development";

export const ApplicationContext = createContext<IApplicationContext>({});

export const useApplicationConfig = () => {
  const [configValue, setConfigValue] = useState<IApplicationContext>({});

  useEffect(() => {
    fetch(
      `https://laborhack-feature-toggles.s3.amazonaws.com/toggles/${environment}.json`
    )
      .then((response) => response.json())
      .then((data) => {
        setConfigValue((prevState) => ({
          ...prevState,
          toggles: data,
        }));
      });
  }, []);

  return configValue;
};
