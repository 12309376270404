import clsx from "clsx";
import React, { FC } from "react";

export interface PillGroupProps {
  maxCount?: number;
  className?: string;
}

export const PillGroup: FC<PillGroupProps> = ({
  maxCount,
  className,
  children,
}) => {
  const childrenArray = React.Children.toArray(children);

  let numberOfItemsToRender = childrenArray.length;

  if (maxCount && childrenArray.length > maxCount) {
    numberOfItemsToRender = maxCount;
  }

  const itemsAreOverflowing = childrenArray.length > numberOfItemsToRender;

  /**
   * If there are more than `maxCount` children, we will show the first `maxCount - 1` children
   */

  const childrenToRender = childrenArray.slice(0, numberOfItemsToRender);

  return (
    <div className={clsx(className, "flex items-center")}>
      {childrenToRender}
      {itemsAreOverflowing && (
        <p className='text-primary-300'>
          +{childrenArray.length - numberOfItemsToRender}
        </p>
      )}
    </div>
  );
};
