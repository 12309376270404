import { Button, Divider } from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  CreateOrderHandlingQuotationItem,
  TradesmanAssignment,
} from "../../types";
import { JobQuoteEntry } from "./job-quote-entry";

interface AssignmentQuoteEntryProps {
  assignment: TradesmanAssignment;
  itemsMap: Record<string, CreateOrderHandlingQuotationItem>;
  onChange: (jobId: string, item: CreateOrderHandlingQuotationItem) => void;
  showErrors?: boolean;
  readonly?: boolean;
}

export const AssignmentQuoteEntry = ({
  assignment,
  itemsMap,
  onChange,
  showErrors,
  readonly,
}: AssignmentQuoteEntryProps) => {
  const { orderCode } = useParams<{ orderCode: string }>();

  return (
    <div className='flex flex-col border border-gray-200'>
      <div className='p-4 bg-primary-500'>
        <h4 className='text-white'>
          <Button
            color='white'
            variant='link'
            rightIcon={
              <FaExternalLinkAlt className='text-white inline-block' />
            }
            onClick={() => {
              window.open(
                `/orders/${orderCode}/assignments/${assignment.id}`,
                "_blank"
              );
            }}
          >
            {assignment.tradesman.firstName} {assignment.tradesman.lastName} -{" "}
            {assignment.category.name}
          </Button>
        </h4>
      </div>

      <Divider />
      {assignment.jobs.map((job, index) => {
        return (
          <>
            <JobQuoteEntry
              key={job.id}
              job={job}
              item={itemsMap[job.id]}
              onChange={(item: CreateOrderHandlingQuotationItem) =>
                onChange(job.id, item)
              }
              showErrors={showErrors}
              readonly={readonly}
            />
            {index !== assignment.jobs.length - 1 && <Divider />}
          </>
        );
      })}
    </div>
  );
};
