import React, { FC, ReactElement } from "react";
import { Modal } from "semantic-ui-react";
import Button from "../Button";

import styles from "./styles.module.scss";
export interface ConfirmationProps {
  loading?: boolean;
  prompt: string;
  additionalContent?: ReactElement;
  onConfirm: () => void;
  onReject: () => void;
  open: boolean;
  size?: "tiny" | "small" | "large";
}

export const Confirmation: FC<ConfirmationProps> = ({
  loading,
  prompt,
  additionalContent,
  open,
  onReject,
  onConfirm,
  size,
}) => {
  return (
    <Modal size={size || "tiny"} closeIcon open={open} onClose={onReject}>
      <div className={styles.confirmBoxWrapper}>
        <div className={styles.confirmBoxContainer}>
          <h4>
            {prompt}.
            <br /> Are you sure?
          </h4>
          <div className={styles.confirmBoxAction}>
            {!loading && (
              <Button variant="negative" onClick={onReject}>
                No
              </Button>
            )}
            <Button
              className={styles.yesButton}
              loading={loading}
              variant="success"
              onClick={onConfirm}
            >
              Yes
            </Button>
          </div>
          {additionalContent}
        </div>
      </div>
    </Modal>
  );
};
