import { useMutation } from "@apollo/client";
import { Alert } from "antd";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { TextArea } from "semantic-ui-react";
import { CREATE_REVIEW } from "../../../components/orders/graphql/mutation";
import Button from "../../../_components/Button";
import { FeedbackField } from "./FeedbackField";

import styles from "./styles.module.scss";

export interface FeedbackFormData {
  punctuality: number;
  appearance: number;
  behavior: number;
  properToolUsage: number;
  postServiceCleanUp: number;
  feedback: string;
}

export interface FeedbackFormProps {
  onClose?: () => void;
  assignmentId: string;
  setVisibility: Dispatch<SetStateAction<boolean>>;
}

export const FeedbackForm: FC<FeedbackFormProps> = ({
  setVisibility,
  assignmentId,
  onClose,
}) => {
  const [punctuality, setPunctuality] = useState<number>(1);
  const [appearance, setAppearance] = useState<number>(1);
  const [behavior, setBehavior] = useState<number>(1);
  const [properToolUsage, setProperToolUsage] = useState<number>(1);
  const [postServiceCleanUp, setPostServiceCleanUp] = useState<number>(1);
  const [feedback, setFeedback] = useState<string>("");

  const [
    createReview,
    { loading, error: createReviewError, data: createReviewData },
  ] = useMutation(CREATE_REVIEW, {
    onCompleted: () => {
      onClose?.();
      setTimeout(() => {
        setVisibility(false);
      }, 2000);
    },
    onError: () => {},
  });

  return (
    <div className={styles.form}>
      {createReviewError && (
        <Alert
          banner
          message={createReviewError.message}
          closable
          type="error"
        ></Alert>
      )}
      {createReviewData && (
        <Alert banner message="Feedback Saved" closable type="success"></Alert>
      )}
      <h3 className={styles.formHeading}>Add Feedback</h3>
      <FeedbackField
        disabled={loading}
        label="Punctuality"
        rating={punctuality}
        onChange={(value) => {
          setPunctuality(value);
        }}
      ></FeedbackField>
      <FeedbackField
        disabled={loading}
        label="Appearance"
        rating={appearance}
        onChange={(value) => {
          setAppearance(value);
        }}
      ></FeedbackField>
      <FeedbackField
        disabled={loading}
        label="Behavior"
        rating={behavior}
        onChange={(value) => {
          setBehavior(value);
        }}
      ></FeedbackField>
      <FeedbackField
        disabled={loading}
        label="Proper Tool Usage"
        rating={properToolUsage}
        onChange={(value) => {
          setProperToolUsage(value);
        }}
      ></FeedbackField>
      <FeedbackField
        disabled={loading}
        label="Post Service Cleanup"
        rating={postServiceCleanUp}
        onChange={(value) => {
          setPostServiceCleanUp(value);
        }}
      ></FeedbackField>
      <div className={styles.field}>
        <p>Review</p>
        <TextArea
          value={feedback}
          onChange={(e, { value }) => {
            typeof value === "string" && setFeedback(value);
          }}
          className={styles.textArea}
          rows={5}
        ></TextArea>
      </div>

      <Button
        loading={loading}
        fullWidth
        onClick={() => {
          createReview({
            variables: {
              input: {
                assignmentId,
                punctuality,
                behavior,
                appearance,
                properToolUsage,
                postServiceCleanUp,
                feedback,
              },
            },
          });
        }}
      >
        Save
      </Button>
    </div>
  );
};
