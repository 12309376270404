import React, { FC } from "react";
import { NonBillableDate } from "../../../../../../../types";
import NonBillableDates from "../../../../../../../components/NonBillableDates";
import { WorkingDays } from "../../../../../../../../../types";

export interface AddNonBillableDateProps {
  startDate: Date;
  endDate: Date;
  nonBillableDates: NonBillableDate[];
  setNonBillableDates: (nonBillableDates: NonBillableDate[]) => void;
  nonWorkingDays: WorkingDays[];
}

export const AddNonBillableDate: FC<AddNonBillableDateProps> = (props) => {
  return (
    <div className='mt-8'>
      <div className='grid grid-cols-[1fr_5fr] gap-4 items-start'>
        <div className='self-center'>
          <p className='font-semibold text-primary-500'>Non Billable Dates</p>
        </div>
        <NonBillableDates {...props} />
      </div>
    </div>
  );
};
