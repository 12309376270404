import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import Button from '@laborhack/custom-button';
import { components as LayoutComponents } from '../../../../../components/layout';
import { CatServiceSelectors } from '../../../../../redux/store';
import { contractIntervalNames } from '../../../constants';
import { ContractRecruitment, RecruitmentRequest } from '../../../types';
import { useHistory, useRouteMatch } from "react-router-dom";
// import Avatar from "../../_components/Avatar";

import styles from './styles.module.scss';

const { CustomTable } = LayoutComponents;

const { selectCategories } = CatServiceSelectors;

export interface RequestDetailsProps {
  recruitment: ContractRecruitment;
}

export const RequestDetails: FC<RequestDetailsProps> = ({
  recruitment,
}) => {
  const requests = recruitment.requests;
  const history = useHistory();

  const { path } = useRouteMatch();
  const categories = useSelector(selectCategories);

  const columns: ColumnsType<RecruitmentRequest & { key: string }> = [
    {
      title: 'Category',
      render: (text, record) => {
        return <p>{categories[record.categoryId].name}</p>;
      },
      width: '15%',
    },
    {
      title: 'No of Pros',
      render: (text, record) => {
        return <p>{record.proCount}</p>;
      },
      width: '10%',
    },
    {
      title: 'Pro Level',
      render: (text, record) => {
        return <p>{record.proLevel}</p>;
      },
      width: '10%',
    },
    {
      title: 'Contract Duration',
      render: (text, record) => {
        return record.contractDetails?.length > 0 ? (
          <p>{`${record.contractDetails?.length} ${
            contractIntervalNames[record.contractDetails?.interval]
          }`}</p>
        ) : "FULL TIME" ;
      },
      width: '20%',
    },
    {
      title: 'Proposed Start Date',
      render: (text, record) => {
        return <p>{new Date(record.proposedStartDate).toLocaleString()}</p>;
      },
      width: '45%',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <CustomTable
        onRow={(record: any, index: any) => {
          return {
            onClick: () => {
              history.push(path.replace(":id", recruitment.id) + "/requests/" + record.id, {
                recruitment,
                request: record,
              });
            },
          };
        }}
        columns={columns}
        data={
          requests.map((request, index) => {
            return {
              key: index,
              ...request,
            };
          }) || []
        }
        handleTableChange={() => {}}
      />
    </div>
  );
};
