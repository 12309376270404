import React, { FC, useState, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import {
  PropsInterface,
  ISubCategories,
  ISubCatTasks,
  ISubTasks,
} from './../types';
// import { EditCategoryModal } from "./EditModal";
// import { useHistory } from "react-router-dom";
import { CatServiceSelectors } from '../../../redux/store';
import Island from '../../../_components/Island';
import { Table, Space } from 'antd';
import { EditTaskModal } from './EditTaskModal';
import { EditSubCategoryModal } from './EditSubcategory';

const AllTasks: FC<PropsInterface> = (props: PropsInterface) => {
  const {
    selectCategories,
    selectSubCategories,
    selectTasks,
  } = CatServiceSelectors;

  const allCategory = useSelector(selectCategories);
  const allSubcategory: ISubCategories = useSelector(selectSubCategories);
  const allSubTasks: ISubCatTasks = useSelector(selectTasks);
  //   const allcategories = Object.entries(allCategory);

  const [categoryObject, setCategoryObject] = useState({});
  const [categoryName, setCategoryname] = useState('');
  //   const [categoryKey, setCategoryKey] = useState("");

  //   const history = useHistory();
  const Selectedcategory = Object.entries(allCategory).find(
    ([id, category]) => {
      const categoryname = category.name.replace(/\s+/g, '-').toLowerCase();

      return (
        props.match.params.categoryName.replace(/\s+/g, '-').toLowerCase() ===
        categoryname
      );
    }
  );

  const getKeyByValue = (obj: any, value: any) => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };

  useEffect(() => {
    if (Selectedcategory !== undefined) {
      setCategoryObject(Selectedcategory[1]);
      //   setCategoryKey(Selectedcategory[0]);
      setCategoryname(Selectedcategory[1].name);
    }
  }, [Selectedcategory]);

  const catId = getKeyByValue(allCategory, categoryObject);

  const allSubCats = Object.entries(allSubcategory).filter(([id, rest]) => {
    return rest.categoryId === catId;
  });

  const servicedata = allSubCats.map(([subcatid, values]) => {
    const subcatTaskList: ISubTasks[] = [];

    values.taskIds.forEach((taskId) => {
      subcatTaskList.push(allSubTasks[taskId]);
    });

    const subcatTasks = subcatTaskList.filter((datas) => datas !== undefined);

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Estimated Cost',
        dataIndex: 'estimated_cost',
        key: 'estimated_cost',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <EditTaskModal taskId={record.id} />
          </Space>
        ),
      },
    ];

    const data = subcatTasks.map((task) => ({
      id: getKeyByValue(allSubTasks, task),
      name: task.name,
      estimated_cost: task.estimatedCost,
    }));
    return (
      <Grid.Column
        key={values.name}
        id={`${values.name}`}
        mobile={16}
        tablet={16}
        computer={16}
        className="o-category-section"
      >
        <Island header={values.name}>
          <div className="o-subcategory__modal">
            <p>{values.description}</p>
            <EditSubCategoryModal subcategoryId={subcatid} />
          </div>

          <Table
            columns={columns}
            pagination={false}
            dataSource={data}
            scroll={{
              y: 400,
              scrollToFirstRowOnChange: false,
            }}
          ></Table>
        </Island>
      </Grid.Column>
    );
  });

  return (
    <Grid columns="1">
      <Grid.Column width="16">
        <Island header={`Categories > ${categoryName}`}>{servicedata}</Island>
      </Grid.Column>
    </Grid>
  );
};

export default AllTasks;
