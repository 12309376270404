import { useMutation } from '@apollo/client';
import { Alert } from 'antd';
import React, { FC, useState } from 'react';
import { Form, Modal } from 'semantic-ui-react';
import Button from '@laborhack/custom-button';
import Input from '@laborhack/input';
import { EDIT_CUSTOM_TASK } from '../../_graphql/mutation';

import styles from './styles.module.scss';
import { nonEmptyString } from '../../../../../helpers/helpers';

export interface NameEditProps {
  jobId: string;
  name: string;
  open: boolean;
  handleClose: () => void;
}

export const NameEdit: FC<NameEditProps> = ({
  jobId,
  name: existingName,
  open,
  handleClose,
}) => {
  const [name, setName] = useState(existingName);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (value: string, isValid: boolean) => {
    setName(value);
    setIsValid(isValid);
  };

  const [editTask, { loading, data, error }] = useMutation(EDIT_CUSTOM_TASK, {
    onCompleted: () => {
      handleClose();
    },
    onError: () => {
      handleClose();
    },
  });

  const handleSubmit = () => {
    editTask({
      variables: {
        input: {
          jobId,
          name,
        },
      },
    });
  };

  return (
    <>
      {error && (
        <Alert message={error.message} closable banner type="error"></Alert>
      )}
      {data && (
        <Alert message={'Name Updated'} closable banner type="success"></Alert>
      )}
      <Modal size="tiny" open={open} closeOnDimmerClick onClose={handleClose}>
        <Modal.Content>
          <h4 className={styles.heading}>Edit Name</h4>
          <div className={styles.modalContainer}>
            <Form>
              <Input
                value={name}
                onChange={handleChange}
                validate={{
                  check: nonEmptyString,
                  message: 'Invalid Input',
                }}
              ></Input>
            </Form>
            <Button
              loading={loading}
              disabled={!isValid}
              className={styles.saveButton}
              variant="basic"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
