import { useMemo } from "react";
import { useTradeRequirements } from "../../hooks/useTradeRequirements";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { ProTradeRequirementList } from "../../features/protraderequirements/ProTradeRequirementList";
import { TradeRequirementType } from "../../common/types/trade";
import Island from "../../_components/Island";

export const ExternalAssessments = () => {
  const {
    loading: tradeRequirementsLoading,
    error: tradeRequirementsError,
    tradeRequirements,
  } = useTradeRequirements();

  const externalRequirements = useMemo(() => {
    return tradeRequirements?.filter(({ type }) => {
      return type === TradeRequirementType.EXTERNAL_ASSESSMENT;
    });
  }, [tradeRequirements]);

  if (tradeRequirementsLoading) {
    return <Loading />;
  }

  if (tradeRequirementsError) {
    return <RequestFailed />;
  }

  if (!externalRequirements?.length) {
    return <p>Could not find any external requirements</p>;
  }

  return (
    <Island>
      <div className='mb-4'>
        <h2 className='text-primary-500 text-xl font-bold m-0'>
          External Assessments
        </h2>
        <p className='m-0 text-primary-300'>A list of external assessments</p>
      </div>
      <ProTradeRequirementList />
    </Island>
  );
};
