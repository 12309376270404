import React, { FC } from "react";
import Breakdown from "./components/Breakdown";
import Summary from "./components/Summary";

import styles from "./styles.module.scss";

export const QuotationBreakdown: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <h3>Breakdown</h3>
        <Breakdown />
      </div>
      <div className={styles.section}>
        <h3>Summary</h3>
        <Summary />
      </div>
    </div>
  );
};
