import { FC } from "react";
import Button from "@laborhack/custom-button";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export const BackButton: FC = ({}) => {
  const history = useHistory();

  const canGoBack = history.length > 1;

  return (
    <div className='m-2'>
      <Button
        disabled={!canGoBack}
        className='!text-primary-500'
        type='link'
        onClick={() => history.goBack()}
      >
        <Icon name='chevron left' /> Back
      </Button>
    </div>
  );
};
