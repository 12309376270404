import {
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tag,
  Text,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { TradeRequirement } from "../../common/types/trade";

interface TradeDetailRequirementProps {
  requirement: TradeRequirement;
}
export const TradeDetailRequirement = ({
  requirement,
}: TradeDetailRequirementProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Stack rounded='md' border='1px' borderColor='gray.200'>
      <Stack
        direction='row'
        align='center'
        justify='space-between'
        paddingX={4}
        paddingY={2}
        key={requirement.id}
      >
        <Stack spacing='2'>
          <Text>{requirement.name}</Text>
          <Stack direction='row' align='center' spacing='2'>
            {requirement.discounts?.map((discount) => (
              <Tag
                key={discount.id}
                size='lg'
                colorScheme='blue'
                borderRadius='full'
              >
                {discount.code}
              </Tag>
            ))}
            {requirement.isPassed ? (
              <Tag
                size='lg'
                colorScheme='green'
                borderRadius='full'
                fontWeight='semibold'
              >
                Passed
              </Tag>
            ) : requirement.proTradeRequirement?.results &&
              requirement.proTradeRequirement?.results.length > 0 ? (
              <Tag
                size='lg'
                colorScheme='red'
                borderRadius='full'
                fontWeight='semibold'
              >
                Failed
              </Tag>
            ) : (
              requirement.proTradeRequirement?.attemptsRemaining && (
                <Tag
                  size='lg'
                  colorScheme='gray'
                  borderRadius='full'
                  fontWeight='semibold'
                >
                  {requirement.proTradeRequirement.attemptsRemaining}{" "}
                  {requirement.proTradeRequirement.attemptsRemaining === 1
                    ? "attempt"
                    : "attempts"}{" "}
                  remaining
                </Tag>
              )
            )}
          </Stack>
        </Stack>
        <IconButton
          aria-label='Open requirement details'
          fontSize='24px'
          isRound={true}
          variant='solid'
          colorScheme='gray'
          icon={isExpanded ? <BiChevronUp /> : <BiChevronDown />}
          onClick={handleExpandToggle}
        />
      </Stack>
      {isExpanded && (
        <Stack padding={4}>
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Attempt No.</Th>
                  <Th>Date Submitted</Th>
                  <Th>Status</Th>
                  <Th isNumeric>Score</Th>
                </Tr>
              </Thead>
              <Tbody>
                {requirement.proTradeRequirement?.results?.map(
                  (result, index) => (
                    <Tr key={result.attemptId}>
                      <Td>{index + 1}</Td>
                      <Td>{new Date(result.submittedAt).toLocaleString()}</Td>
                      <Td>
                        {result.passed ? (
                          <Tag
                            size='lg'
                            colorScheme='green'
                            borderRadius='full'
                            fontWeight='semibold'
                          >
                            Passed
                          </Tag>
                        ) : (
                          <Tag
                            size='lg'
                            colorScheme='red'
                            borderRadius='full'
                            fontWeight='semibold'
                          >
                            Failed
                          </Tag>
                        )}
                      </Td>
                      <Td isNumeric>
                        {result.scores.reduce((acc, score) => acc + score, 0)}
                      </Td>
                    </Tr>
                  )
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </Stack>
  );
};
