import { gql } from "@apollo/client";

export const GET_ORDERS_QUERY = gql`
  query ListOrders(
    $limit: Int
    $cursor: String
    $sorts: [OrderSortInput!]
    $filters: [OrderFilterInput!]
    $search: OrderSearchInput
  ) {
    listOrders(
      first: $limit
      after: $cursor
      sorts: $sorts
      filters: $filters
      search: $search
    ) {
      edges {
        cursor
        node {
          id
          inspectionPayment {
            amount
            transactionReference
            status
          }
          jobs {
            completedAt
            status
            invoiceDetails {
              materials {
                quantity
                unitPrice
              }
              labor
            }
          }
          orderCode
          createdAt
          client {
            userId
            name
          }
          isCompleted
          isAssigned
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_SINGLE_ORDER_QUERY = gql`
  query GetOrder($code: String!) {
    getOrder(orderCode: $code) {
      id
      orderCode
      assignments {
        id
        progress
        jobs {
          id
          jobCode
          custom {
            name
            description
            resourceUrl
            subCategoryId
            categoryId
          }
          task {
            id
            name
            description
            estimatedCost
            categoryId
            subCategoryId
            imageUrl
          }
          finalCost
          status
          completedAt
          payment {
            transactionReference
            transactionDate
            amount
            status
          }
          contact {
            notes
            location {
              buildingNumber
              streetName
              area
              location
              landmark
              city
            }
          }
          invoiceDetails {
            materials {
              name
              unitPrice
              quantity
            }
            labor
          }
        }
        tradesman {
          id
          userId
          firstName
          lastName
          imageUrl
          email
          level
          categoryId
          rewardPoints
          location {
            buildingNumber
            streetName
            area
            location
            landmark
            city
          }
          phoneNumber
        }
        date
      }
      inspectionPayment {
        transactionReference
        transactionDate
        amount
        status
      }
      suggestedTradesmen {
        id
        userId
        firstName
        lastName
        email
        level
        categoryId
        location {
          buildingNumber
          streetName
          area
          location
          landmark
          city
        }
        phoneNumber
      }
      jobs {
        id
        jobCode
        custom {
          name
          description
          resourceUrl
          subCategoryId
          categoryId
        }
        task {
          name
          description
          estimatedCost
          categoryId
          subCategoryId
          imageUrl
        }
        assignedTradesman {
          userId
          firstName
          lastName
          email
          level
          categoryId
          location {
            buildingNumber
            streetName
            area
            location
            landmark
            city
          }
          phoneNumber
        }
        finalCost
        status
        invoiceDetails {
          materials {
            name
            unitPrice
            quantity
          }
          labor
        }
        completedAt
        payment {
          transactionReference
          transactionDate
          amount
          status
        }
        contact {
          notes
          location {
            id
            buildingNumber
            streetName
            area
            location
            landmark
            city
          }
        }
      }
      client {
        userId
        name
        phoneNumber
        email
      }
      locationSchedules {
        locationId
        scheduledDate
      }
      isEmergency
      createdAt
    }
  }
`;

export const GET_JOB = gql`
  query GetJob($jobCode: String!) {
    getJobByCode(jobCode: $jobCode) {
      id
      jobCode
      custom {
        name
        description
        resourceUrl
        subCategoryId
        categoryId
      }
      task {
        name
        description
        estimatedCost
        categoryId
        subCategoryId
        imageUrl
      }
      assignedTradesman {
        userId
        firstName
        lastName
        email
        level
        categoryId
        location {
          buildingNumber
          streetName
          area
          location
          landmark
          city
        }
        phoneNumber
      }
      finalCost
      status

      invoiceDetails {
        materials {
          name
          unitPrice
          quantity
        }
        labor
      }
      completedAt
      payment {
        transactionReference
        transactionDate
        amount
        status
      }
      contact {
        notes
        location {
          buildingNumber
          streetName
          area
          location
          landmark
          city
        }
      }
    }
  }
`;

export const GET_TRADESMAN_WITH_AVAILABILITY = gql`
  query TradesmenWithAvailability(
    $data: GetTradesmanWithAvailabilityByCategoryInput!
  ) {
    getTradesmenWithAvailabilityByCategory(data: $data) {
      status
      data {
        id
        userId
        firstName
        lastName
        email
        phoneNumber
        referralCode
        location {
          buildingNumber
          streetName
          landmark
          area
          location
          city
          id
        }
        level
        categoryId
        availability
      }
    }
  }
`;

export const CHECK_TRADESMEN_AVAILABILITY_BY_DATE = gql`
  query CheckAvailability($data: CheckTradesmenAvailabilityByDate!) {
    checkTradesmenAvailabilityByDate(data: $data) {
      status
      data {
        id
        userId
        firstName
        lastName
        email
        phoneNumber
        location {
          buildingNumber
          streetName
          landmark
          area
          location
          city
          id
        }
        level
        categoryId
        availability
      }
    }
  }
`;

export const GET_TRADESMAN_ASSIGNMENTS = gql`
  query GetAssignments($orderId: String!) {
    getTradesmanAssignments(orderId: $orderId) {
      status
      message
      data {
        id
        progress
        inspectionPayment {
          status
          amount
        }
        inspectionWaived
        order {
          id
          orderCode
          client {
            userId
          }
          inspectionPayment {
            status
            amount
          }
          locationSchedules {
            locationId
            scheduledDate
          }
          isEmergency
        }
        invoice {
          id
          sent
          invoiceNumber
          invoiceDate
          canceled
          allowedPaymentProviders
          isFullyPaid
          hasAtLeastOnePayment
          options {
            materialCommissionCap
          }
          splitPayments {
            percentage
            payment {
              id
              status
              amount
              transactionDate
            }
          }
          recipient {
            name
            email
            phoneNumber
            address
          }
          payment {
            id
            transactionDate
            transactionReference
            status
            amount
          }
        }
        jobs {
          id
          jobCode
          custom {
            name
            description
            resourceUrl
            subCategoryId
            categoryId
          }
          task {
            id
            name
            description
            estimatedCost
            categoryId
            subCategoryId
            imageUrl
          }
          finalCost
          status
          completedAt
          payment {
            transactionReference
            transactionDate
            amount
            status
          }
          contact {
            notes
            location {
              buildingNumber
              streetName
              area
              location
              landmark
              city
            }
          }
          invoiceDetails {
            materials {
              name
              unitPrice
              quantity
            }
            labor
          }
        }
        tradesman {
          id
          userId
          firstName
          lastName
          imageUrl
          email
          level
          categoryId
          rewardPoints
          location {
            buildingNumber
            streetName
            area
            location
            landmark
            city
          }
          phoneNumber
        }
        date
      }
    }
  }
`;

export const GET_TRADESMAN_SCHEDULE = gql`
  query GetTradesmanSchedule($tradesmanId: String!) {
    getTradesmanSchedule(tradesmanId: $tradesmanId) {
      status
      message
      data
    }
  }
`;

export const GET_ASSIGNMENT_SCHEDULE = gql`
  query GetAssignmentSchedule($input: GetAssignmentScheduleInput!) {
    getAssignmentSchedule(input: $input) {
      status
      message
      data
    }
  }
`;

export const CHECK_ASSIGNMENT_PAYMENT_STATUS = gql`
  query CheckTransferStatus($assignmentId: String!) {
    checkAssignmentPaymentStatus(assignmentId: $assignmentId) {
      status
      message
      data {
        transportation
        materials
        labor
      }
    }
  }
`;

export const GET_ASSIGNMENT_INSPECTION_INVOICE = gql`
  query GetInvoices($assignmentId: String!) {
    getInspectionInvoiceForAssignment(assignmentId: $assignmentId) {
      id
      type
      sent
      canceled
      inspectionAmount
      invoiceNumber
      invoiceDate
      discount
      appliedDiscount {
        id
        code
        value
      }
      isFullyPaid
      hasAtLeastOnePayment
      options {
        materialCommissionCap
      }
      splitPayments {
        percentage
        payment {
          id
          status
          amount
          transactionDate
        }
      }
      assignments {
        id
        progress
        order {
          id
          orderCode
          inspectionPayment {
            status
            amount
          }
          locationSchedules {
            locationId
            scheduledDate
          }
          isEmergency
        }
        jobs {
          id
          jobCode
          custom {
            name
            description
            resourceUrl
            subCategoryId
            categoryId
          }
          task {
            id
            name
            description
            estimatedCost
            categoryId
            subCategoryId
            imageUrl
          }
          finalCost
          status
          completedAt
          payment {
            transactionReference
            transactionDate
            amount
            status
          }
          contact {
            notes
            location {
              id
              buildingNumber
              streetName
              area
              location
              landmark
              city
            }
          }
          invoiceDetails {
            materials {
              name
              unitPrice
              quantity
            }
            labor
          }
        }
        date
      }
      recipient {
        name
        email
        phoneNumber
        address
      }
      payment {
        id
        transactionDate
        transactionReference
        status
      }
      allowedPaymentProviders
    }
  }
`;

export const GET_INVOICE = gql`
  query GetInvoice($invoiceId: String!) {
    getInvoiceById(invoiceId: $invoiceId) {
      status
      message
      data {
        id
        type
        invoiceNumber
        invoiceDate
        discount
        appliedDiscount {
          id
          code
          value
        }
        isFullyPaid
        hasAtLeastOnePayment
        options {
          materialCommissionCap
        }
        splitPayments {
          percentage
          payment {
            id
            status
            amount
            transactionDate
          }
        }
        assignments {
          id
          progress
          order {
            id
            orderCode
            inspectionPayment {
              status
              amount
            }
            locationSchedules {
              locationId
              scheduledDate
            }
            isEmergency
          }
          jobs {
            id
            jobCode
            custom {
              name
              description
              resourceUrl
              subCategoryId
              categoryId
            }
            task {
              id
              name
              description
              estimatedCost
              categoryId
              subCategoryId
              imageUrl
            }
            finalCost
            status
            completedAt
            payment {
              transactionReference
              transactionDate
              amount
              status
            }
            contact {
              notes
              location {
                id
                buildingNumber
                streetName
                area
                location
                landmark
                city
              }
            }
            invoiceDetails {
              materials {
                name
                unitPrice
                quantity
              }
              labor
            }
          }
          date
        }
        recipient {
          name
          email
          phoneNumber
          address
        }
        payment {
          id
          amount
          transactionDate
          transactionReference
          status
        }
      }
    }
  }
`;

export const GET_ORDER_INVOICES = gql`
  query GetInvoices($orderId: String!, $type: InvoiceType) {
    getInvoicesByOrder(orderId: $orderId, type: $type) {
      id
      type
      sent
      canceled
      inspectionAmount
      invoiceNumber
      invoiceDate
      discount
      appliedDiscount {
        id
        code
        value
      }
      allowedPaymentProviders
      isFullyPaid
      hasAtLeastOnePayment
      options {
        materialCommissionCap
      }
      splitPayments {
        percentage
        payment {
          id
          status
          amount
          transactionDate
        }
      }
      assignments {
        id
        progress
        order {
          id
          orderCode
          inspectionPayment {
            status
            amount
          }
          locationSchedules {
            locationId
            scheduledDate
          }
          isEmergency
        }
        jobs {
          id
          jobCode
          custom {
            name
            description
            resourceUrl
            subCategoryId
            categoryId
          }
          task {
            id
            name
            description
            estimatedCost
            categoryId
            subCategoryId
            imageUrl
          }
          finalCost
          status
          completedAt
          payment {
            transactionReference
            transactionDate
            amount
            status
          }
          contact {
            notes
            location {
              id
              buildingNumber
              streetName
              area
              location
              landmark
              city
            }
          }
          invoiceDetails {
            materials {
              name
              unitPrice
              quantity
            }
            labor
          }
        }
        date
      }
      recipient {
        name
        email
        phoneNumber
        address
      }
      payment {
        id
        amount
        transactionDate
        transactionReference
        status
      }
    }
  }
`;

export const GET_REVIEW = gql`
  query GetReview($assignmentId: String!) {
    getReviewByAssignmentId(assignmentId: $assignmentId) {
      id
      feedback
    }
  }
`;
