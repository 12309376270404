import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_INVOICE, GET_NEXT_INVOICE_PAYMENT } from "../graphql/query";
import Invoice from "@laborhack/invoice";
import Button from "../../../_components/Button";
import { Icon, Modal } from "semantic-ui-react";
import { CONFIRM_INVOICE_PAYMENT } from "../graphql/mutation";
import {
  getPendingPayment,
  invoiceIsPaid,
  isAuthorized,
} from "../../../helpers/helpers";
import { LogOfflinePayment } from "./LogOfflinePayment";
import { useAuth } from "../../../firebase/hooks/useAuthHook";
import { useSelector } from "react-redux";
import { CatServiceSelectors } from "../../../redux/store";
import { InvoicePayment } from "../types";
import Loading from "../../../_components/Loading";
import RequestFailed from "../../../_components/RequestFailed";

const { selectCategories } = CatServiceSelectors;

const InvoiceView: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { idTokenResult } = useAuth();

  const [logOfflinePaymentModalOpen, setLogOfflinePaymentModalOpen] = useState(
    false
  );

  const categories = useSelector(selectCategories);

  const { loading, error, data, refetch } = useQuery(GET_INVOICE, {
    variables: {
      invoiceId: id,
    },
  });

  const {
    loading: nextInvoicePaymentLoading,
    data: nextInvoicePaymentData,
    error: nextInvoicePaymentError,
  } = useQuery<{ nextInvoicePayment: InvoicePayment }>(
    GET_NEXT_INVOICE_PAYMENT,
    {
      variables: {
        invoiceId: id,
      },
      fetchPolicy: "network-only",
    }
  );

  const [confirmInvoicePayment, confirmInvoicePaymentResult] = useMutation(
    CONFIRM_INVOICE_PAYMENT,
    {
      variables: {
        invoiceId: id,
      },
      onCompleted: (data) => {
        data?.confirmInvoicePayment.status && refetch();
      },
    }
  );

  if (loading) {
    return <div>loading</div>;
  }

  if (nextInvoicePaymentLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  if (nextInvoicePaymentError) {
    return <RequestFailed />;
  }

  const invoice: any = data.getInvoiceById.data;

  const pendingPayment = getPendingPayment(invoice);

  const pendingPaymentIsOffline = pendingPayment?.providerId === "offline";

  const handleLogOfflinePaymentModalClose = () => {
    setLogOfflinePaymentModalOpen(false);
    refetch();
  };

  const canHandleOfflinePayment = isAuthorized(
    { FINANCE: true },
    idTokenResult?.claims.roles
  );

  return (
    <>
      <Modal
        open={logOfflinePaymentModalOpen}
        size='tiny'
        closeOnDimmerClick
        onClose={handleLogOfflinePaymentModalClose}
      >
        <LogOfflinePayment
          invoice={invoice}
          onClose={handleLogOfflinePaymentModalClose}
        />
      </Modal>
      <div>
        <div className='m-invoiceView__actions'>
          <Button
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_CLIENT_APP_URL}/invoice/${invoice?.id}`
              );
            }}
          >
            View as Client
          </Button>

          {!invoiceIsPaid(invoice) && (
            <>
              {canHandleOfflinePayment && (
                <Button
                  disabled={!pendingPaymentIsOffline}
                  onClick={() => setLogOfflinePaymentModalOpen(true)}
                >
                  Log Offline Payment
                </Button>
              )}
              <Button
                loading={confirmInvoicePaymentResult.loading}
                onClick={confirmInvoicePayment}
              >
                <Icon
                  name='sync'
                  loading={confirmInvoicePaymentResult.loading}
                />{" "}
                Verify Payment
              </Button>
            </>
          )}
        </div>

        <Invoice
          invoice={invoice}
          summary={nextInvoicePaymentData!.nextInvoicePayment}
          categories={Object.entries(categories).map(([id, value]) => ({
            id,
            ...value,
            proTitle: value.proTitle || "Pro", // use proTitle if it exists, otherwise use "Pro" as a fallback
          }))}
        />
      </div>
    </>
  );
};

export default InvoiceView;
