import { useLocation } from "react-router-dom";

export default function Page() {
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const payoutIds = query.get("payouts")?.split(",") ?? [];

  console.log(payoutIds);

  return <div></div>;
}
