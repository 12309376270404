import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { CatServiceSelectors } from "../../../../../redux/store";
import { IAddedRecruitment, RecruitmentType } from "../../../types";

import styles from "./styles.module.scss";

export interface AddedRecruitmentProps extends IAddedRecruitment {
  onRemove: () => void;
  selectedRecruitmentType?: RecruitmentType
}

const { selectCategories } = CatServiceSelectors;

export const AddedRecruitment: FC<AddedRecruitmentProps> = ({
  onRemove,
  categoryId,
  noOfPros,
  proLevel,
  contractInterval,
  contractLength,
  preferredStartDate,
  selectedRecruitmentType
}) => {
  const categories = useSelector(selectCategories);

  const contractIntervalNames = {
    MONTHS: "month(s)",
    WEEKS: "week(s)",
    DAYS: "day(s)",
  };

  return (
    <div className={styles.addedRecruitmentItem}>
      <div>
        <div className={styles.pro}>
          <h4>{noOfPros}</h4>
          <div className={styles.proDetails}>
            <p>pros</p>
            <p>
              <strong>Level {proLevel}</strong>
            </p>
          </div>
        </div>
        <p>{categories[categoryId].name}</p>
        { selectedRecruitmentType === RecruitmentType.CONTRACT &&
          <p>{`${contractLength} ${contractIntervalNames[contractInterval]}`}</p>
        }
        <p>Starts {preferredStartDate.toDateString()}</p>
      </div>
      <div className={styles.actions}>
        <Icon onClick={onRemove} name='trash' color='red' />
      </div>
    </div>
  );
};
