import { FC } from "react";
import { Icon } from "semantic-ui-react";
import { NonBillableDate } from "../../../types";

export interface NonBillableDateItemProps extends NonBillableDate {
  onRemove?: () => void;
}

export const NonBillableDateItem: FC<NonBillableDateItemProps> = ({
  startDate,
  endDate,
  onRemove,
}) => {
  return (
    <div className='py-2 pl-4 pr-2 rounded-full bg-primary-150 mr-2 last:mr-0'>
      <div className='flex items-center'>
        <p className='m-0 mr-2 text-primary-500 font-semibold'>
          {startDate.toDateString()}
        </p>
        {endDate ? (
          <Icon className='mx-4' name='long arrow alternate right' />
        ) : (
          ""
        )}
        <p className='m-0 mr-2 text-primary-500 font-semibold'>{`${
          endDate ? endDate.toDateString() : ""
        }`}</p>
        {onRemove && (
          <Icon
            className='cursor-pointer hover:text-red-500'
            name='remove circle'
            onClick={onRemove}
          />
        )}
      </div>
    </div>
  );
};
