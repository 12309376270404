import React, { FC, useState } from "react";
import { Form, Input, Button, Message } from "semantic-ui-react";
import { STRINGS } from "../constants";
import { useHistory } from "react-router-dom";
import { firebase } from "../../../firebase";

const { RESET, RESET_EMAIL, FORGOT, HAVE_PASSWORD, RESET_SUCCESS } = STRINGS;
export const ResetPasswordForm: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const history = useHistory();

  const handleReset = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setMessage(RESET_SUCCESS);
    } catch (error) {
      setMessage((error as Error).message);
    }
  };

  return (
    <>
      <div className='m-login__heading'>
        <h3>{FORGOT}</h3>
        <div className='bar -md'></div>
      </div>

      {message && (
        <Message>
          <Message.Header>{message}</Message.Header>
        </Message>
      )}
      <Form>
        <Form.Field
          control={Input}
          type='email'
          label={RESET_EMAIL}
          className='a-login__input'
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
        ></Form.Field>
        <Button className='a-login__button' fluid onClick={handleReset}>
          {RESET}
        </Button>
      </Form>
      <div className='m-login__formOptions'>
        <p
          onClick={() => {
            history.push("/login");
          }}
        >
          {HAVE_PASSWORD}
        </p>
      </div>
    </>
  );
};
