import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useApplicationConfig } from "../../context/ApplicationContext";
import { CreateIdentityVerification } from "../../features/pros/CreateIdentityVerification";
import { CreatePro } from "../../features/pros/CreatePro";
import { ProList as CurrentProList } from "../../features/pros/ProList";
import { OptimizedProList } from "../../features/pros/OptimizedProList";
import { ViewIdentityVerification } from "../../features/pros/ViewIdentityVerification";
import { ViewPro } from "../../features/pros/ViewPro";
import { ProsProvider } from "../../providers/ProsProvider";
import BackgroundCheck from "@/features/pros/BackgroundCheck";

export const Pros = () => {
  const { path } = useRouteMatch();

  const { toggles } = useApplicationConfig();

  const ProList = toggles?.use_optimized_pro_list
    ? OptimizedProList
    : CurrentProList;

  return (
    <ProsProvider>
      <Switch>
        <Route
          path={`${path}/:proId/identity-verification/create`}
          component={CreateIdentityVerification}
        />
        <Route
          path={`${path}/:proId/identity-verification/:id`}
          exact
          component={ViewIdentityVerification}
        />
        <Route path={`${path}/create`} component={CreatePro} />
        <Route path={`${path}/:proId/background-check`} component={BackgroundCheck} />
        <Route path={`${path}/:proId/:tab`} component={ViewPro} />
        <Route path={`${path}`} exact component={ProList} />
      </Switch>
    </ProsProvider>
  );
};
