import React from "react";
import Input from "@laborhack/input";
import Button from "@laborhack/custom-button";
import { Icon } from "semantic-ui-react";

export interface TextFieldProps {
  label: string;
  value: string;
  editable?: boolean;
  onSave?: (value: string) => void;
  saving?: boolean;
}

const TextField = ({
  label,
  value,
  editable,
  onSave,
  saving,
}: TextFieldProps) => {
  const [editMode, setEditMode] = React.useState(false);

  const [inputValue, setInputValue] = React.useState(value);

  return (
    <div className='flex flex-col gap-1'>
      <p className='font-semibold text-sm text-primary-300 m-0 p-0'>{label}</p>
      {editMode ? (
        <div className='flex gap-6 items-center'>
          <Input
            value={inputValue}
            onChange={(value) => setInputValue(value)}
          />
          <div className='flex gap-4 items-center'>
            <Button
              type='link'
              onClick={() => {
                setEditMode(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type='subtle'
              variant='success'
              onClick={() => {
                onSave && onSave(inputValue);
                setEditMode(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className='flex items-center gap-12'>
          <p className='font-semibold text-base text-primary-500 m-0 p-0'>
            {value}
          </p>
          {editable &&
            (saving ? (
              <Icon name='spinner' loading />
            ) : (
              <Button
                size='small'
                variant='basic'
                onClick={() => {
                  setEditMode(true);
                }}
                type='subtle'
              >
                Edit
              </Button>
            ))}
        </div>
      )}
    </div>
  );
};

export default TextField;
