import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { IRegisterState } from "./../components/adminuser/types";

const initialState: IRegisterState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  avatar:
    "https://res.cloudinary.com/laborhack/image/upload/v1600258815/laborhack/user-img_w2tloh.jpg",
  role: "",
  success_message: "",
  error_message: "",
  status: "idle",
};

export const SubmitAdminForm = createAsyncThunk(
  "register/createAdmin",
  async (
    data: { mutation: any },
    {
      dispatch,
      getState,
    }: { dispatch: ThunkDispatch<any, any, AnyAction>; getState: any }
  ) => {
    const { mutation } = data;
    const { registerAdmin }: any = getState();

    try {
      const { data } = await mutation({
        variables: {
          data: {
            firstName: registerAdmin.firstName,
            lastName: registerAdmin.lastName,
            email: registerAdmin.email,
            phoneNumber: registerAdmin.phoneNumber,
            imageUrl: registerAdmin.avatar,
            role: registerAdmin.role,
          },
        },
      });
      if (data) {
        dispatch(resetForm());
        dispatch(setSuccessMessage(data.createAdminUser));
      }
    } catch (error) {
      // dispatch error message
      console.log(error);
      dispatch(setErrorMessage((error as Error).message));
    }
  }
);

export const registerAdminSlice = createSlice({
  name: "registerAdmin",
  initialState,
  reducers: {
    setFirstname: (state, action) => {
      state.firstName = action.payload;
    },
    setDlastName: (state, action) => {
      state.lastName = action.payload;
    },
    setDPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setDEmail: (state, action) => {
      state.email = action.payload;
    },
    setDRole: (state, action) => {
      state.role = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.success_message = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error_message = action.payload;
    },
    resetForm: (state) => {
      state.firstName = "";
      state.lastName = "";
      state.email = "";
      state.phoneNumber = "";
      state.role = "";
      state.avatar = "";
    },
  },
  extraReducers: {
    [SubmitAdminForm.fulfilled.type]: (state, action) => {
      state.status = "idle";
    },
    [SubmitAdminForm.pending.type]: (state, action) => {
      state.status = "pending";
    },
  },
});

export const {
  setFirstname,
  setDlastName,
  setDPhoneNumber,
  setDEmail,
  setDRole,
  setAvatar,
  resetForm,
  setSuccessMessage,
  setErrorMessage,
} = registerAdminSlice.actions;

//Form submission thunk

export const selectFirstName = (state: any) => state.registerAdmin.firstName;
export const selectLastName = (state: any) => state.registerAdmin.lastName;
export const selectPhoneNumber = (state: any) =>
  state.registerAdmin.phoneNumber;
export const selectEmail = (state: any) => state.registerAdmin.email;
export const selectRole = (state: any) => state.registerAdmin.role;
export const selectAvatar = (state: any) => state.registerAdmin.avatar;
export const selectSuccessMessage = (state: any) =>
  state.registerAdmin.success_message;
export const selectErrorMessage = (state: any) =>
  state.registerAdmin.error_message;
export const selectSubmissionStatus = (state: any) =>
  state.registerAdmin.status;

export const RegisterAdminSelectors = {
  selectFirstName,
  selectLastName,
  selectPhoneNumber,
  selectEmail,
  selectRole,
  selectAvatar,
  selectSubmissionStatus,
  // selectFormData,
};

export const RegisterAdminAction = registerAdminSlice.actions;
export const RegisterAdminReducer = registerAdminSlice.reducer;
