import React, { FC } from 'react';
import { useState } from 'react';
import { Image } from 'antd';
import { Icon } from 'semantic-ui-react';
import { ProAssignmentChecklist } from '../../../../types';
import Badge from '../../../../_components/Badge';
import { BadgeProps } from '../../../../_components/Badge/Badge';
import { ChecklistItem } from './ChecklistItem';

import styles from './styles.module.scss';
import clsx from 'clsx';

export interface AssignmentChecklistProps
  extends Partial<ProAssignmentChecklist> {
  type: 'Pre-Inspection' | 'Pre-Job';
}

export const AssignmentChecklist: FC<AssignmentChecklistProps> = ({
  type,
  onLaborHackApparel,
  onProtectiveGear,
  withProperEquipment,
  validationImageUrl,
  submitted,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const checks = [onLaborHackApparel, onProtectiveGear, withProperEquipment];

  const positiveCount = checks.reduce((acc, current) => {
    return current ? acc + 1 : acc;
  }, 0);

  let badgeProps: BadgeProps = {
    text: 'Partially Complied',
    type: 'progress',
    inverted: true,
  };

  switch (positiveCount) {
    case checks.length:
      // all items checked
      badgeProps.text = 'Fully Complied';
      badgeProps.type = 'success';
      break;
    case 0:
      // no item checked
      badgeProps.text = 'Not Complied';
      badgeProps.type = 'cancelled';
      break;

    default:
      break;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.toggle}>
        <Icon
          name={open ? 'chevron circle up' : 'chevron circle down'}
          onClick={() => setOpen((prev) => !prev)}
          className={clsx(open && styles.active)}
        />
      </div>
      <div className={styles.body}>
        <h3>{type} Checklist</h3>
        {open && (
          <div className={styles.inner}>
            <div className={styles.innerList}>
              <ChecklistItem checked={onLaborHackApparel}>
                Wore LaborHack apparel
              </ChecklistItem>
              <ChecklistItem checked={withProperEquipment}>
                Has proper equipment
              </ChecklistItem>
              <ChecklistItem checked={onProtectiveGear}>
                Wore protective gear (face masks etc)
              </ChecklistItem>
            </div>
            <div className={styles.innerImage}>
              <Image width={100} src={validationImageUrl} />
            </div>
          </div>
        )}
        <div className={styles.footer}>
          <Badge {...badgeProps} />
          {submitted && (
            <p>
              Submitted on {new Date(submitted).toDateString()}{' '}
              {new Date(submitted).toLocaleTimeString()}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
