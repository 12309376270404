import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import Button from '@laborhack/custom-button';
import { GET_PAYMENT_PROVIDERS } from './graphql/query';
import {
  DefaultTransferRecipientsResponse,
  PaymentProvidersResponse,
} from './graphql/response';
import { PaymentProvider } from './types';

import styles from './styles.module.scss';
import PaymentProviderCard from './_components/PaymentProviderCard';
import Loading from '../../_components/Loading';
import RequestFailed from '../../_components/RequestFailed';
import { GET_DEFAULT_TRANSFER_RECIPIENTS } from '../../graphql/query';

export interface SelectPaymentProviderProps {
  onDone: (providerId: string) => void;
  recipientUserId: string;
}

export const SelectPaymentProvider: FC<SelectPaymentProviderProps> = ({
  onDone,
  recipientUserId,
}) => {
  const [
    selectedPaymentProvider,
    setSelectedPaymentProvider,
  ] = React.useState<string>();

  // get payment providers with transaction fee query
  const { loading, data, error } = useQuery<PaymentProvidersResponse>(
    GET_PAYMENT_PROVIDERS
  );

  // get recipient's stored default payment provider data
  const {
    loading: defaultTransferRecipientLoading,
    data: defaultTransferRecipientData,
    error: defaultTransferRecipientError,
  } = useQuery<DefaultTransferRecipientsResponse>(
    GET_DEFAULT_TRANSFER_RECIPIENTS,
    {
      variables: {
        userId: recipientUserId,
      },
    }
  );

  if (loading || defaultTransferRecipientLoading) {
    return <Loading />;
  }

  if (error || defaultTransferRecipientError) {
    return <RequestFailed />;
  }

  const paymentProvidersMap: {
    [key: string]: PaymentProvider;
  } = {};

  data?.paymentProviders.forEach((paymentProvider) => {
    paymentProvidersMap[paymentProvider.id] = paymentProvider;
  });

  const storedPaymentProviderIdSet = new Set(
    defaultTransferRecipientData?.defaultTransferRecipients.map(
      ({ providerId }) => providerId
    )
  );

  return (
    <div className={styles.wrapper}>
      <h2>How would you like to make your transfer today?</h2>
      <div className={styles.paymentProviderList}>
        {data?.paymentProviders.map(({ id, name, logo, balance, isActive }) => {
          const disableProviderCard = id === "offline" ? !isActive : !storedPaymentProviderIdSet.has(id) || !isActive;
            
          return (
            <PaymentProviderCard
              selected={selectedPaymentProvider === id}
              key={id}
              name={name}
              logo={logo}
              balance={balance}
              onClick={() => setSelectedPaymentProvider(id)}
              disabled={disableProviderCard}
            />
          );
        })}
      </div>

      {selectedPaymentProvider && (
        <Button
          className={styles.button}
          variant="success"
          size="big"
          fullWidth
          onClick={() => onDone(selectedPaymentProvider)}
        >
          Pay with {paymentProvidersMap[selectedPaymentProvider]?.name}
        </Button>
      )}
    </div>
  );
};
