export const adminList = [
  {
    id: "1",
    firstName: "Brandon",
    lastName: "James",
    role: "FINANCE",
    email: "brandonjames@gmail.com",
    phoneNumber: "+2348102238789",
  },
  {
    id: "2",
    firstName: "Demilade",
    lastName: "Johnson",
    role: "CUSTOMER SERVICE",
    email: "johnsondemilad@gmail.com",
    phoneNumber: "+2348034546988",
  },

  {
    id: "3",
    firstName: "Demilade",
    lastName: "Johnson",
    role: "CUSTOMER SERVICE",
    email: "johnsondemilad@gmail.com",
    phoneNumber: "+2348034546988",
  },

  {
    id: "4",
    firstName: "Demilade",
    lastName: "Johnson",
    role: "CUSTOMER SERVICE",
    email: "johnsondemilad@gmail.com",
    phoneNumber: "+2348034546988",
  },
];

export const userDetails = {
  HEADER: "Admin User Details",
  NAME: "Name: ",
  ROLE: "Role: ",
  EMAIL: "Email: ",
  PHONENUMBER: "Phone Number: ",
};
