import React from "react";
import { ContractQuotationItem } from "../../../../types";

export interface ItemChangeEvent {
  type:
    | "split"
    | "merge"
    | "level"
    | "rate"
    | "active"
    | "workdays"
    | "rateInterval";
  from: Record<string, ContractQuotationItem>;
  to: Record<string, ContractQuotationItem>;
}

interface SingleQuotationContext {
  items: Record<string, ContractQuotationItem>;
  editMode: boolean;
  undoStack: ItemChangeEvent[];
  redoStack: ItemChangeEvent[];
  splitItem: (itemId: string, splitRatio: number[]) => void;
  mergeItems: (itemIds: string[]) => void;
  changeLevel: (itemId: string, level: number) => void;
  changeRate: (itemId: string, rate: number) => void;
  changeActive: (itemId: string, active: boolean) => void;
  changeWorkdays: (itemId: string, workdays: number) => void;
  undo: () => void;
  redo: () => void;
}

export const SingleQuotationContext = React.createContext<SingleQuotationContext>(
  {
    items: {},
    editMode: false,
    undoStack: [],
    redoStack: [],
    splitItem: () => {},
    mergeItems: () => {},
    changeLevel: () => {},
    changeRate: () => {},
    changeActive: () => {},
    changeWorkdays: () => {},
    undo: () => {},
    redo: () => {},
  }
);
