import React, { FC } from "react";
import { CaretRightOutlined } from "@ant-design/icons";

export const BreadCrumb: FC<{
  track: string[];
}> = ({ track }) => {
  return (
    <div className="o-breadCrumb__wrapper">
      {track.map((breadcrumbName, index) => {
        if (index === 0) {
          return <h2 key={breadcrumbName}>{breadcrumbName}</h2>;
        }

        return (
          <div key={breadcrumbName} className="m-breadCrumb__route">
            <CaretRightOutlined />
            <p>{breadcrumbName}</p>
          </div>
        );
      })}
    </div>
  );
};
