import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

interface UnavailableProConfirmationModalProps {
  proName: string;
  isOpen: boolean;
  onClose: () => void;
  selectPro: () => void;
}

export const UnavailableProConfirmationModal: React.FC<UnavailableProConfirmationModalProps> = ({
  proName,
  isOpen,
  onClose,
  selectPro,
}) => {
  return (
    <>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unavailable Pro</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {`${proName} is not available for this job at the moment. Do you still want to select this pro?`}
          </ModalBody>
          <ModalFooter gap="2rem">
            <Button
              width={"7rem"}
              color="white"
              bgColor="green"
              onClick={() => selectPro()}
            >
              Yes
            </Button>
            <Button
              width={"7rem"}
              color="white"
              bgColor="red"
              mr={3}
              onClick={onClose}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
