import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { components as LayoutComponents } from "../../components/layout";
import Button from "@laborhack/custom-button";
import {
  Input,
  Select,
  Button as SemanticButton,
  Icon,
  DropdownProps,
  InputOnChangeData,
} from "semantic-ui-react";
import { gql, useQuery } from "@apollo/client";
import RequestFailed from "../../_components/RequestFailed";
import Loading from "../../_components/Loading";
import {
  IdentityVerificationStatus,
  ListProsIdentityVerificationStatus,
  RelayStylePaginatedResponse,
} from "../../types";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { ColumnsType, TablePaginationConfig, TableProps } from "antd/lib/table";
import Pill from "../../_components/Pill";
import { extractItemsFromRelayStylePaginationResponse } from "../../helpers/helpers";
import {
  ExternalProTradeRequirementStatus,
  ListProTradeRequirementsFilters,
  ListProTradeRequirementsSearch,
  ListProTradeRequirementsSearchBy,
  ProTradeRequirement,
} from "../../common/types/pro-trade-requirement";
import { useTradeRequirements } from "../../hooks/useTradeRequirements";
import { TradeRequirementType } from "../../common/types/trade";
import produce from "immer";

const { CustomTable } = LayoutComponents;

const LIST_PRO_TRADE_REQUIREMENTS = gql`
  query ListProTradeRequirements(
    $first: Int
    $after: String
    $filters: ListProTradeRequirementsFilters
    $search: ListProTradeRequirementsSearch
  ) {
    listProTradeRequirements(
      first: $first
      after: $after
      filters: $filters
      search: $search
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          pro {
            id
            firstName
            lastName
            associatedLaborHackEmail
          }
          requirement {
            id
            name
          }
          results {
            scores
          }
          externalStatus
          createdAt
        }
      }
    }
  }
`;

const searchOptions = [
  {
    key: ListProTradeRequirementsSearchBy.FIRST_NAME,
    text: "First Name",
    value: ListProTradeRequirementsSearchBy.FIRST_NAME,
  },
  {
    key: ListProTradeRequirementsSearchBy.LAST_NAME,
    text: "Last Name",
    value: ListProTradeRequirementsSearchBy.LAST_NAME,
  },
  {
    key: ListProTradeRequirementsSearchBy.EMAIL,
    text: "Email",
    value: ListProTradeRequirementsSearchBy.EMAIL,
  },
];

export interface ProTradeRequirementListProps {}

export const ProTradeRequirementList: FC<ProTradeRequirementListProps> = ({}) => {
  const {
    loading: tradeRequirementsLoading,
    error: tradeRequirementsError,
    tradeRequirements,
  } = useTradeRequirements();

  const externalRequirements = useMemo(() => {
    return tradeRequirements?.filter(({ type }) => {
      return type === TradeRequirementType.EXTERNAL_ASSESSMENT;
    });
  }, [tradeRequirements]);

  /**
   * by default, we always want to filter out NOT_PAID or Internal assessments requirements.
   *
   * This methods looks at the selected filters and converts them to the correct filters for use here.
   * This helps us keep clear filters in the UI till we need to use them.
   *
   * @param filters
   */
  const convertFiltersForExternalRequirements = useCallback(
    (filters: ListProTradeRequirementsFilters) => {
      const convertedFilters: ListProTradeRequirementsFilters = {
        externalStatus: [
          ExternalProTradeRequirementStatus.AWAITING_UPLOAD,
          ExternalProTradeRequirementStatus.AWAITING_RESULT,
          ExternalProTradeRequirementStatus.PASSED,
          ExternalProTradeRequirementStatus.FAILED,
        ],
        requirementIds: externalRequirements?.map(({ id }) => id),
        identityVerificationStatus: [
          ListProsIdentityVerificationStatus.VERIFIED,
        ],
      };

      const { requirementIds, externalStatus } = filters;

      if (externalStatus && externalStatus.length) {
        convertedFilters.externalStatus = externalStatus;
      }

      if (requirementIds && requirementIds.length) {
        convertedFilters.requirementIds = requirementIds;
      }

      return convertedFilters;
    },
    [externalRequirements]
  );

  const { path } = useRouteMatch();

  const [search, setSearch] = useState<ListProTradeRequirementsSearch>();

  const [filters, setFilters] = useState<ListProTradeRequirementsFilters>({});

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchBy, setSearchBy] = useState<ListProTradeRequirementsSearchBy>(
    ListProTradeRequirementsSearchBy.FIRST_NAME
  );

  const { data, loading, error, fetchMore, refetch } = useQuery<{
    listProTradeRequirements: RelayStylePaginatedResponse<ProTradeRequirement>;
  }>(LIST_PRO_TRADE_REQUIREMENTS, {
    variables: {
      filters: convertFiltersForExternalRequirements(filters),
      ...(search && {
        search,
      }),
      first: 10,
    },
  });

  console.log(data);

  const columns: ColumnsType<ProTradeRequirement & { key: string }> = [
    {
      title: "Date Created",
      render: (text, record) => {
        return <p>{new Date(record.createdAt).toLocaleString()}</p>;
      },
      width: "10%",
      fixed: "left",
    },
    {
      title: "Name",
      render: (text, record) => {
        return <p>{`${record.pro.firstName} ${record.pro.lastName}`}</p>;
      },
      width: "10%",
    },
    {
      title: "Email",
      render: (text, record) => {
        return <p>{record.pro.associatedLaborHackEmail}</p>;
      },
      width: "15%",
    },
    {
      title: "Assessment",
      render: (text, record) => {
        return <Pill>{record.requirement.name}</Pill>;
      },
      key: "requirementIds",
      filters: externalRequirements?.map((tradeRequirement) => ({
        text: tradeRequirement.name,
        value: tradeRequirement.id,
      })),
      filteredValue: filters?.requirementIds,
      onFilter: (value, record) => {
        return true;
      },
      width: "20%",
    },
    {
      title: "Status",
      render: (text, record) => {
        let colorClass = "!bg-yellow-100 !text-yellow-800";

        if (
          record.externalStatus ===
            ExternalProTradeRequirementStatus.NOT_PAID ||
          record.externalStatus === ExternalProTradeRequirementStatus.FAILED
        ) {
          colorClass = "!bg-red-100 !text-red-800";
        }

        if (
          record.externalStatus === ExternalProTradeRequirementStatus.PASSED
        ) {
          colorClass = "!bg-green-100 !text-green-800";
        }

        return (
          <Pill className={`!text-xs ${colorClass}`}>
            {record.externalStatus.replace(/_/g, " ")}
          </Pill>
        );
      },
      key: "externalStatus",
      filters: [
        {
          text: "Awaiting Upload",
          value: ExternalProTradeRequirementStatus.AWAITING_UPLOAD,
        },
        {
          text: "Awaiting Results",
          value: ExternalProTradeRequirementStatus.AWAITING_RESULT,
        },
        {
          text: "Passed",
          value: ExternalProTradeRequirementStatus.PASSED,
        },
        {
          text: "Failed",
          value: ExternalProTradeRequirementStatus.FAILED,
        },
      ],
      filteredValue: filters?.externalStatus,
      onFilter: (value, record) => {
        return true;
      },
      width: "10%",
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <Link to={`${path}/${record.id}`}>
            <Button>View</Button>
          </Link>
        );
      },
      fixed: "right",
      width: "5%",
    },
  ];

  const handleSearchDropdownChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setSearchBy(data.value as ListProTradeRequirementsSearchBy);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setSearchQuery(data.value);
  };

  const handleSearch = () => {
    setSearch({
      query: searchQuery,
      by: searchBy,
    });
  };

  const handleRemoveSearch = () => {
    setSearch(undefined);
    setSearchQuery("");
  };

  const handleFilterChange = useCallback(
    (key: keyof ListProTradeRequirementsFilters, filters: any) => {
      setFilters((prev) => {
        return {
          ...prev,
          [key]: filters,
        };
      });
    },
    [setFilters]
  );

  const removeFilter = useCallback(
    (key: keyof ListProTradeRequirementsFilters) => {
      setFilters((prev) => {
        return produce(prev, (draft) => {
          if (draft) {
            delete draft[key];
          }
        });
      });
    },
    [setFilters]
  );

  const handleTableChange: TableProps<ProTradeRequirement>["onChange"] = useCallback(
    (pagination: TablePaginationConfig, filters, sorter) => {
      console.log(filters, sorter);

      if (filters.externalStatus !== undefined) {
        filters.externalStatus === null
          ? removeFilter("externalStatus")
          : handleFilterChange("externalStatus", filters.externalStatus);
      }

      if (filters.requirementIds !== undefined) {
        filters.requirementIds === null
          ? removeFilter("requirementIds")
          : handleFilterChange("requirementIds", filters.requirementIds);
      }
    },
    [handleFilterChange, removeFilter]
  );

  if (loading || tradeRequirementsLoading) {
    return <Loading />;
  }

  if (error || tradeRequirementsError) {
    return <RequestFailed />;
  }

  return (
    <>
      <div className='w-full flex justify-end mb-4'>
        <Input
          type='text'
          placeholder='Search by'
          value={searchQuery}
          onChange={handleSearchInputChange}
          action
        >
          <input />
          <Select
            options={searchOptions}
            value={searchBy}
            onChange={handleSearchDropdownChange}
          />
          <SemanticButton
            icon
            className='!bg-primary-500 !text-white'
            type='submit'
            onClick={handleSearch}
            loading={loading}
          >
            <Icon name='search' />
          </SemanticButton>
        </Input>
      </div>

      {search?.query && (
        <div className='text-lg flex items-center gap-4 py-2'>
          <p className='m-0'>
            Search by{" "}
            <i>
              <strong>
                {searchOptions.find(({ value }) => search.by === value)?.text}
              </strong>
            </i>{" "}
            results for <strong>"{search.query}"</strong>
          </p>
          <Button size='small' type='link' onClick={handleRemoveSearch}>
            Remove Search
          </Button>
        </div>
      )}

      <div className='flex justify-end w-full items-center'>
        <div className='flex justify-end'>
          <p>
            Showing{" "}
            <strong>{data?.listProTradeRequirements.edges.length}</strong> of{" "}
            <strong>{data?.listProTradeRequirements.totalCount}</strong> results
          </p>
        </div>

        <div className='flex justify-end'>
          <Button type='link'>
            <Icon name='refresh' /> Refresh
          </Button>
        </div>
      </div>

      <CustomTable
        onRow={(record: any, index: any) => {}}
        columns={columns}
        data={
          data
            ? extractItemsFromRelayStylePaginationResponse(
                data?.listProTradeRequirements
              ).map((item) => {
                return {
                  key: item.id,
                  ...item,
                };
              })
            : []
        }
        loading={loading}
        handleTableChange={handleTableChange}
        hasMore={data?.listProTradeRequirements.pageInfo.hasNextPage}
        fetchMore={() => {
          fetchMore({
            variables: {
              first: 10,
              after: data?.listProTradeRequirements.pageInfo.endCursor,
              filters,
            },
          });
        }}
        scroll={{ x: 1800 }}
      />
    </>
  );
};
