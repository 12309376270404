import React, { FC } from "react";
import Button from "@laborhack/custom-button";
import { Tradesman } from "../../../../types";
import styles from "./AssignedProList.module.scss";
import { AssignedProListItem } from "./AssignedProListItem";
import { Modal } from "semantic-ui-react";
import SelectPro from "../../../SelectOrderPro";
import { useMutation } from "@apollo/client";
import { ADD_PRO_TO_ASSIGNMENT } from "../../../../graphql/query";
import RequestFailed from "../../../../_components/RequestFailed";

export interface AssignedProListProps {
  assignmentId: string;
  proList: Tradesman[];

  assignmentCategoryId?: string;
}

export const AssignedProList: FC<AssignedProListProps> = ({
  assignmentId,
  proList,
  assignmentCategoryId,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [addProToAssignment, { loading, error }] = useMutation(
    ADD_PRO_TO_ASSIGNMENT,
    {
      onCompleted: () => {
        setShowModal(false);
      },
    }
  );

  const proCategory = assignmentCategoryId || proList[0].categoryId;

  const handleProSelection = (proId: string) => {
    addProToAssignment({
      variables: {
        proId,
        assignmentId,
      },
    });
  };

  return (
    <>
      <Modal
        size='large'
        open={showModal}
        onClose={() => setShowModal(false)}
        closeOnDimmerClick
        closeIcon
      >
        {error ? (
          <RequestFailed />
        ) : (
          <SelectPro
            existingPros={proList}
            selectedCategory={proCategory}
            scheduledDate={new Date()}
            onProSelected={handleProSelection}
            loading={loading}
          />
        )}
      </Modal>
      {proList.map((pro, index) => {
        return (
          <AssignedProListItem key={pro.id} isDefault={index === 0} pro={pro} />
        );
      })}
      <Button className={styles.addPro} onClick={() => setShowModal(true)}>
        Add Pro
      </Button>
    </>
  );
};
