import React, { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CreateRefund from "./CreateRefund";
import RefundConfirmations from "./RefundConfirmations";
import RefundConfirmationView from "./RefundConfirmationView";
import RefundRequests from "./RefundRequests";
import RefundRequestView from "./RefundRequestView";

const Refund: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/create`}>
        <CreateRefund />
      </Route>
      <Route exact path={`${path}/requests`}>
        <RefundRequests />
      </Route>
      <Route path={`${path}/requests/:id`}>
        <RefundRequestView />
      </Route>
      <Route exact path={`${path}/confirmations`}>
        <RefundConfirmations />
      </Route>
      <Route path={`${path}/confirmations/:id`}>
        <RefundConfirmationView />
      </Route>
    </Switch>
  );
};

export default Refund;
