import React, { FC } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import styles from './styles.module.scss';

export interface SelectProps extends DropdownProps {}

export const Select: FC<SelectProps> = (props) => {
  return <Dropdown className={styles.select} fluid selection {...props} />;
};
