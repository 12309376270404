import React, { FC, useState, useEffect } from 'react';
import { Grid, Form, Button, Input, Message } from 'semantic-ui-react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {
  setDName,
  setDCategory,
  setDDescription,
  setDImage,
  setDStatus,
  SubmitSubCategoryForm,
  selectErrorMessage,
  selectSuccessMessage,
  selectSubmissionStatus,
} from '../../../redux/subcategory.slice';
import { useMutation } from '@apollo/client';
import { SUBMIT_SUB_CATEGORY } from '../../../graphql/query';
import { IFileName } from './../types';
import { ISubCategories, ICategories } from './../../allcategories/types';
import { CatServiceSelectors } from './../../../redux/category-service.slice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Island from '../../../_components/Island';

const SubCategoryForm: FC = () => {
  const { selectCategories, selectSubCategories } = CatServiceSelectors;
  const allcategory = useSelector(selectCategories);
  const allsubcategory: ISubCategories = useSelector(selectSubCategories);
  const categories = Object.entries(allcategory);
  const submissionStatus = useSelector(selectSubmissionStatus);
  const successMessage = useSelector(selectSuccessMessage);
  const formErrorMessage = useSelector(selectErrorMessage);

  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [img, setImg] = useState();
  const [uploadProgress, setUploadProgress] = useState('0%');
  const [errorMessage, setErrorMessage] = useState('');
  const [nameError, setNameError] = useState(false);

  const dispatch = useDispatch();
  const [submitForm] = useMutation(SUBMIT_SUB_CATEGORY);

  const SubCategoryNames: any = [];
  const history = useHistory();
  const subcategories = Object.entries(allsubcategory);

  subcategories.forEach(([id, subcategory]) => {
    return SubCategoryNames.push(subcategory.name.toLocaleLowerCase());
  });

  const checkName = () => {
    if (
      SubCategoryNames.indexOf(
        `${name.replace(/\s*$/, '').toLocaleLowerCase()}`
      ) > -1
    ) {
      setNameError(true);
      setErrorMessage('subcategory already exist');
    } else {
      setNameError(false);
      setErrorMessage('');
    }
  };

  useEffect(() => {
    if (successMessage !== '') {
      setTimeout(() => {
        history.push('/categories');
        window.location.reload();
      }, 2000);
    }
  }, [successMessage, history]);

  const uploadHandler = async () => {
    const fileData = new FormData();
    const image_file: any = img;
    const trimFileName = (file: IFileName) =>
      file.name.replace(new RegExp(/[^0-9a-zA-Z.]/g), '_');
    fileData.append('file', image_file, trimFileName(image_file));
    fileData.append('upload_preset', 'ilq50gln');
    const APIURL = 'https://api.cloudinary.com/v1_1/laborhack/image/upload';
    await axios
      .post(APIURL, fileData, {
        onUploadProgress: (ProgressEvent) => {
          setUploadProgress(
            `${Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)}%`
          );
        },
      })
      .then((res) => {
        const image_url = res.data.secure_url;
        setImageUrl(image_url);
        dispatch(setDStatus('pending'));
        dispatch(setDImage(image_url));
      });
  };
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          <Island header="Create sub category">
            {successMessage !== '' && (
              <Message
                success
                header="Created successfully"
                content={successMessage}
              />
            )}
            {errorMessage !== '' && (
              <Message error header="Error" content={errorMessage} />
            )}
            {formErrorMessage !== '' && (
              <Message error header="Error" content={formErrorMessage} />
            )}
            <Form>
              <Form.Input label="Choose Category" required>
                <select
                  name="role"
                  id="role"
                  value={category}
                  onChange={(e) => {
                    const { value } = e.target;

                    setCategory(value);
                    dispatch(setDCategory(value));
                  }}
                >
                  <option
                    value=""
                    disabled
                    defaultValue="choose a category"
                    hidden
                  >
                    Choose a category
                  </option>
                  {categories.length > 0 ? (
                    categories.map(([id, data]) => {
                      return (
                        <option key={id} value={id}>
                          {data.name}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No available categories</option>
                  )}
                </select>
              </Form.Input>
              <Form.Input
                required
                id="form-input-control-error-name"
                control={Input}
                label="Sub Category Name"
                placeholder="Plumbing"
                value={name}
                onChange={(e) => {
                  const { value } = e.target;
                  setName(value);
                }}
                onBlur={() => {
                  dispatch(setDName(name));
                  checkName();
                }}
              />
              <Form.Input
                required
                id="form-input-control-error-description"
                control={Input}
                label="Description"
                placeholder="Enter category description"
                value={description}
                onChange={(e) => {
                  const { value } = e.target;
                  setDescription(value);
                }}
                onBlur={() => {
                  dispatch(setDDescription(description));
                }}
              />
              <div>
                <Form.Input label="Upload Image (400px by 280px)">
                  <input
                    width={5}
                    type="file"
                    name="image"
                    id=""
                    onChange={(e) => {
                      const [file]: any = e.target.files;
                      setImg(file);
                    }}
                  />
                </Form.Input>
              </div>
              <div className="o-category__form-img-details">
                <p>Uploading: {uploadProgress}</p>
                <img
                  src={imageUrl}
                  className={`o-subcategoryImage__wrapper ${!img && 'd-none'}`}
                  alt="subCategory_image"
                />
              </div>

              <div>
                {submissionStatus === 'idle' ? (
                  <Button
                    primary
                    {...((name.replace(/\s/g, '') === '' ||
                      description.replace(/\s/g, '') === '' ||
                      category === '' ||
                      nameError === true) && { disabled: true })}
                    onClick={(e) => {
                      e.preventDefault();
                      if (img === undefined) {
                        dispatch(
                          SubmitSubCategoryForm({ mutation: submitForm })
                        );
                      } else {
                        uploadHandler().then(() => {
                          dispatch(
                            SubmitSubCategoryForm({ mutation: submitForm })
                          );
                        });
                      }
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button loading primary>
                    Loading
                  </Button>
                )}
              </div>
            </Form>
          </Island>
        </Grid.Column>
        <Grid.Column width={6}></Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default SubCategoryForm;
