import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import { components as LayoutComponents } from '../../../components/layout';
import Island from '../../../_components/Island';
import Loading from '../../../_components/Loading';
import RequestFailed from '../../../_components/RequestFailed';
import { Admin } from '../types';
import BasicInformation from '../_components/BasicInformation';
import Permissions from '../_components/Permissions';
import { GET_ADMIN } from '../_graphql/query';

import styles from './styles.module.scss';

const { Trail } = LayoutComponents;

export interface ViewProps {}

export const View: FC<ViewProps> = () => {
  const { id } = useParams<{ id: string }>();

  const { loading, data, error } = useQuery<{ admin: Admin }>(GET_ADMIN, {
    variables: {
      id,
    },
  });

  return (
    <Island header={<Trail root="Admins" child={id}></Trail>}>
      <div className={styles.container}>
        {loading && <Loading />}
        {error && <RequestFailed errorMessage={error.message} />}
        {data && data.admin && (
          <>
            <BasicInformation
              firstName={data.admin.firstName}
              lastName={data.admin.lastName}
              emailAddress={data.admin.email}
              phoneNumber={data.admin.phoneNumber}
            />
            <Permissions admin={data.admin} />
          </>
        )}
      </div>
    </Island>
  );
};
