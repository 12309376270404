import { gql } from "@apollo/client";

export const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    getUserRecordByEmail(email: $email) {
      id
      email
    }
  }
`;

export const GET_USER_BY_PHONENUMBER = gql`
  query GetUserByPhoneNumber($phoneNumber: String!) {
    getUserRecordByPhoneNumber(phoneNumber: $phoneNumber) {
      id
      phoneNumber
    }
  }
`;

export const GET_CLIENT = gql`
  query GetClient($id: String!) {
    client(id: $id) {
      id
      userId
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;

/**
 * @deprecated use FETCH_CLIENT_BY_EMAIL instead
 */
export const GET_CLIENT_BY_EMAIL = gql`
	query GetClientByEmail($email: String!) {
		clientByEmail(email: $email) {
			id
			userId
			firstName
			lastName
			email
			addresses {
				id
			}
			phoneNumber
			businessId
			billingAddressId
			marketingCampaign
		}
	}
`;

export const FETCH_CLIENT_BY_EMAIL = gql`
	query GetClientByEmail($email: String!) {
		getClientByEmail(email: $email) {
			id
			userId
			firstName
			lastName
			email
			addresses {
				id
			}
			phoneNumber
			businessId
			billingAddressId
			marketingCampaign
		}
	}
`;

export const GET_AVAILABLE_AUTO_APPLIED_DISCOUNT_FOR_CLIENT = gql`
	query AvailableAutoAppliedInvoiceDiscountByClientId(
		$clientId: String!
		$invoiceType: InvoiceType
	) {
		availableAutoAppliedInvoiceDiscountByClientId(
			clientId: $clientId
			invoiceType: $invoiceType
		) {
			id
			code
		}
	}
`;

export const GET_DISCOUNT_BALANCE_FOR_CLIENT_BY_DISCOUNT = gql`
	query Query($discountId: String!, $clientId: String!) {
		getDiscountBalanceForClientByDiscountId(
			discountId: $discountId
			clientId: $clientId
		)
	}
`;

export const GET_LOCATIONS = gql`
  query GetLocations($userId: String!) {
    getClientLocations(userId: $userId) {
      defaultLocationId
      locations {
        id
        buildingNumber
        streetName
        landmark
        area
        location
        city
      }
    }
  }
`;

export const ADD_PRO_TO_ASSIGNMENT = gql`
  mutation AddProToAssignment($proId: String!, $assignmentId: String!) {
    addProToAssignment(proId: $proId, assignmentId: $assignmentId) {
      id
      otherPros {
        id
        userId
        firstName
        lastName
        imageUrl
        email
        level
        categoryId
        location {
          buildingNumber
          streetName
          area
          location
          landmark
          city
        }
        phoneNumber
      }
    }
  }
`;

export const GET_TRADESMAN_ASSIGNMENT_BY_ID = gql`
  query GetAssignmentById($assignmentId: String!) {
    getTradesmanAssignmentById(assignmentId: $assignmentId) {
      status
      message
      data {
        id
        progress
        category {
          id
          name
        }
        inspectionPayment {
          status
          amount
        }
        inspectionWaived
        order {
          id
          orderCode
          inspectionPayment {
            status
            amount
          }
          locationSchedules {
            locationId
            scheduledDate
          }
          isEmergency
        }
        invoice {
          id
          sent
          invoiceNumber
          invoiceDate
          canceled
          isFullyPaid
          hasAtLeastOnePayment
          options {
            materialCommissionCap
          }
          splitPayments {
            percentage
            payment {
              id
              status
              amount
              transactionDate
            }
          }
          recipient {
            name
            email
            phoneNumber
            address
          }
          payment {
            id
            transactionDate
            transactionReference
            status
            amount
          }
        }
        jobs {
          id
          jobCode
          custom {
            name
            description
            resourceUrl
            subCategoryId
            categoryId
          }
          task {
            id
            name
            description
            estimatedCost
            categoryId
            subCategoryId
            imageUrl
          }
          finalCost
          status
          completedAt
          payment {
            transactionReference
            transactionDate
            amount
            status
          }
          contact {
            notes
            location {
              buildingNumber
              streetName
              area
              location
              landmark
              city
            }
          }
          invoiceDetails {
            materials {
              name
              unitPrice
              quantity
            }
            labor
          }
        }
        checklists {
          startInspection {
            onLaborHackApparel
            onProtectiveGear
            withProperEquipment
            validationImageUrl
            submitted
          }
          startJob {
            onLaborHackApparel
            onProtectiveGear
            withProperEquipment
            validationImageUrl
            submitted
          }
        }
        tradesman {
          id
          userId
          firstName
          lastName
          imageUrl
          email
          level
          categoryId
          location {
            buildingNumber
            streetName
            area
            location
            landmark
            city
          }
          phoneNumber
        }
        otherPros {
          id
          userId
          firstName
          lastName
          imageUrl
          email
          level
          categoryId
          location {
            buildingNumber
            streetName
            area
            location
            landmark
            city
          }
          phoneNumber
        }
        assignmentPayments {
          id
          type
          createdAt
          tradesman {
            id
            firstName
            lastName
          }
          transfer {
            id
            status
            amount
          }
        }
        date
      }
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query GetAllServices {
    getAllRoles {
      status
      message
      data {
        id
        name
      }
    }
  }
`;

export const GET_ALL_CAT_SERVICES = gql`
  query GetAllServices {
    getAllServices {
      allCategories {
        id
        name
        proTitle
        imageUrl
        requestCount
        subCategoryIds
        isVisible
      }
      allSubCategories {
        id
        categoryId
        name
        imageUrl
        description
        requestCount
        taskIds
      }
      allServiceTasks {
        id
        categoryId
        subCategoryId
        name
        description
        imageUrl
        requestCount
        estimatedCost
      }
    }
  }
`;

export const SUBMIT_TASK_FORM = gql`
  mutation createTask($input: createTaskInput!) {
    createTask(input: $input)
  }
`;

export const SUBMIT_CATEGORY_FORM = gql`
  mutation createNewCategory($input: createCategoryInput!) {
    createNewCategory(input: $input)
  }
`;

export const SUBMIT_SUB_CATEGORY = gql`
  mutation createSubCategory($input: SubCategoryInput!) {
    createSubCategory(input: $input)
  }
`;

export const EDIT_CATEGORY = gql`
  mutation editCategory($input: editCategoryInput!) {
    editCategory(input: $input)
  }
`;

export const EDIT_SUBCATEGORY = gql`
  mutation editSubCategory($input: EditSubCategoryInput!) {
    editSubCategory(input: $input)
  }
`;

export const EDIT_TASK = gql`
  mutation editTask($input: EditTaskInput!) {
    editTask(input: $input)
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: String!) {
    deleteCategory(id: $id)
  }
`;

export const GET_ALL_TRADESMEN = gql`
  query GetAllTradesmen($search: ListAllTradesmenSearch) {
    listAllTradesmen(search: $search) {
      status
      message
      data {
        id
        userId
        firstName
        lastName
        email
        phoneNumber
        imageUrl
        blocked
        location {
          buildingNumber
          streetName
          location
          area
          city
          landmark
        }
        categoryId
        level
        rewardPoints
      }
    }
  }
`;

export const SUBMIT_TRADESMAN_FORM = gql`
  mutation createTradesman($input: CreateTradesmanInput!) {
    createTradesman(input: $input) {
      status
      message
    }
  }
`;

export const EDIT_TRADESMAN = gql`
  mutation editTradesman($data: EditTradesmanInput!) {
    editTradesman(data: $data) {
      status
      message
    }
  }
`;

export const ADD_TRADESMAN_POINT = gql`
  mutation addRewardPointChange($data: AddRewardPointChangeInput!) {
    addRewardPointChange(data: $data) {
      status
      message
    }
  }
`;

export const GET_ALL_ADMINS = gql`
  query GetAllAdmins {
    listAllAdmins {
      status
      message
      data {
        id
        userId
        firstName
        lastName
        phoneNumber
        imageUrl
        email
      }
    }
  }
`;

export const REGISTER_ADMIN = gql`
  mutation createAdmin($data: CreateAdminInput!) {
    createAdmin(data: $data) {
      status
      message
    }
  }
`;

export const EDIT_ADMIN = gql`
  mutation editAdmin($data: EditAdminInput!) {
    editAdmin(data: $data) {
      status
      message
    }
  }
`;

export const GET_ALL_CLIENTS = gql`
  query GetAllClients($first: Int, $after: String) {
    clients(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          userId
          firstName
          lastName
          phoneNumber
          email
          createdAt
          businessMetadata {
            activated
          }
          locations {
            buildingNumber
            streetName
            area
            landmark
            location
            city
          }
        }
      }
    }
  }
`;

export const SUBMIT_CLIENT_FORM = gql`
  mutation createClient($data: CreateClientInput!) {
    createClient(data: $data) {
      status
      message
    }
  }
`;

export const EDIT_CLIENT = gql`
  mutation editClient($data: EditClientInput!) {
    editClient(data: $data) {
      status
      message
    }
  }
`;

export const BLOCK_USER = gql`
  mutation blockAUser($uid: String!) {
    blockAUser(uid: $uid)
  }
`;

export const LIST_BANKS = gql`
  query GetAllBanks {
    listBanks {
      data {
        name
        code
      }
    }
  }
`;

export const GET_ACCOUNT_DETAILS = gql`
  query GetAccountDetails($input: BankAccountInformationInput!) {
    getBankAccountInformation(input: $input) {
      status
      message
      data {
        accountName
        accountNumber
      }
    }
  }
`;

export const GET_DEFAULT_TRANSFER_RECIPIENTS = gql`
  query GetDefaultTransferRecipients($userId: String!) {
    defaultTransferRecipients(userId: $userId) {
      providerId
      recipientCode
    }
  }
`;

export const GET_DEFAULT_TRANSFER_RECIPIENTS_WITH_BANK_INFORMATION = gql`
  query GetDefaultTransferRecipientsWithBankInformation($userId: String!) {
    defaultTransferRecipients(userId: $userId) {
      providerId
      recipientCode
      bankInformation {
        accountName
        accountNumber
        bankName
      }
    }
  }
`;

export const GET_PAYMENT_PROVIDER_INFORMATION = gql`
  query GetPaymentProviderInformation($userId: String!) {
    paymentProviderInformation(userId: $userId) {
      userId
      defaultAccountNumber
      providerData {
        accountNumber
        providers {
          providerId
          recipientCode
        }
      }
    }
  }
`;

export const GET_BUSINESS_BY_EMAIL = gql`
  query GetBusinessByEmail($email: String!) {
    businessByEmail(email: $email) {
      id
      email
      phoneNumber
    }
  }
`;

export const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      proTitle
      isVisible
    }
  }
`;

export const GET_TRADES = gql`
  query GetTrades {
    trades {
      id
      name
      description
      displayPriority
      imageUrl
    }
  }
`;
