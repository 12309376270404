import { gql } from '@apollo/client';

export const GET_PAYMENT_PROVIDERS = gql`
  query GetPaymentProviders {
    paymentProviders {
      id
      name
      balance
      logo
      isActive
    }
  }
`;
