import Joi from "@hapi/joi";

export const validator = Joi.object({
  firstname: Joi.string().min(1),
  lastname: Joi.string().min(1),
  email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }),
  phoneNumber: Joi.string().pattern(new RegExp(/^(70|(80|81)|90)\d{8}$/)),
  role: Joi.string().min(1),
});

export const baseRegisterValidator = validator.and(
  "firstname",
  "lastname",
  "email",
  "phoneNumber",
  "role"
);
