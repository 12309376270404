import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "@laborhack/custom-button";
import { components as LayoutComponents } from "../../../../../components/layout";
import { gql, useQuery } from "@apollo/client";
import { NON_REFERENCED_CONTRACT_FIELDS } from "../../../graphql/fragments";
import Island from "../../../../../_components/Island";
import { Contract } from "../../../types";
import Loading from "../../../../../_components/Loading";
import RequestFailed from "../../../../../_components/RequestFailed";
import { isAfter } from "date-fns";
import { Icon } from "semantic-ui-react";
import BackButton from "../../../../../_components/BackButton";

const { Trail } = LayoutComponents;

const GET_PROPOSED_CONTRACT_END_DATE = gql`
  query GetProposedContractEndDate($proposed: ProposedContractInfo!) {
    proposedContractEndDate(proposed: $proposed)
  }
`;

const GET_CONTRACT = gql`
  ${NON_REFERENCED_CONTRACT_FIELDS}
  query GetContract($id: String!) {
    contract(id: $id) {
      contractLength
      contractInterval
      endDate
      recruitment {
        id
        client {
          id
          firstName
          lastName
        }
      }
      pro {
        firstName
        lastName
      }
      ...NonReferencedContractFields
    }
  }
`;

export const EditContract: FC = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const { loading, error, data } = useQuery<{ contract: Contract }>(
    GET_CONTRACT,
    {
      variables: {
        id,
      },
    }
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <RequestFailed errorMessage={error?.message} />;
  }

  const terminateDisabled =
    data.contract.endDate &&
    isAfter(new Date(), new Date(data.contract.endDate));

  return (
    <>
      <BackButton />
      <Island header={<Trail root='Edit Contract' child={id} />}>
        <div>
          <h4 className='text-lg text-primary-500 font-semibold'>
            Danger Zone
          </h4>
          <div className='border-2 border-red-300 rounded-lg'>
            <div className='grid grid-cols-[3fr_1fr] items-center p-6'>
              <div>
                <h5 className='text-primary-500 text-base font-semibold'>
                  Terminate This Contract
                </h5>
                <p className='text-primary-200 text-sm'>
                  This contract ends on{" "}
                  <strong>
                    {data.contract.endDate
                      ? new Date(data.contract.endDate).toDateString()
                      : "N/A"}
                  </strong>
                  .
                </p>
              </div>
              <div className='justify-self-end'>
                <Button disabled={terminateDisabled} className='!bg-red-500'>
                  Terminate Contract
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Island>
    </>
  );
};
