import { useMutation } from '@apollo/client';
import { Alert } from 'antd';
import React, { FC } from 'react';
import { Client } from '../../../types';
import { Confirmation } from '../../../_components/Confirmation/Confirmation';
import { DELETE_CLIENT } from '../graphql/mutation';

export interface DeleteClientProps {
  client: Client;
  handleClose: () => void;
  isOpen: boolean;
}

export const DeleteClient: FC<DeleteClientProps> = ({
  client,
  handleClose,
  isOpen,
}) => {
  const [deleteClient, { loading, error }] = useMutation(DELETE_CLIENT, {
    onCompleted: () => {
      handleClose();
    },
    onError: () => {
      handleClose();
    },
  });

  const handleConfirm = () => {
    deleteClient({
      variables: {
        clientId: client.id,
      },
    });
  };

  return (
    <>
      {error && <Alert closable message={error?.message} type="error"></Alert>}
      <Confirmation
        loading={loading}
        prompt={`You are about to delete ${client.firstName} ${client.lastName}. This action is IRREVERSIBLE`}
        onConfirm={handleConfirm}
        onReject={handleClose}
        open={isOpen}
      />
    </>
  );
};
