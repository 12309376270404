import { Edge, PageInfo } from "../../graphql/types";

export enum TransactionStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export interface Payment {
  id: string;
  transactionReference: string;
  transactionDate: Date;
  status: TransactionStatus;
  amount: number;
}

export interface OrderUser {
	id: string;
	userId: string;
	name: string;
	phoneNumber: string;
	email: string;
	marketingCampaign?: string;
}

export interface Location {
  id: string;
  buildingNumber: string;
  streetName: string;
  landmark: string;
  area: string;
  location: string;
  city: string;
}

export enum Status {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  ARCHIVED = "ARCHIVED",
}

export enum Availability {
  AVAILABLE = "AVAILABLE",
  TENTATIVE = "TENTATIVE",
  ENGAGED = "ENGAGED",
}

export enum JobProgress {
  NOT_STARTED = "NOT_STARTED",
  PRE_INSPECTION = "PRE_INSPECTION",
  FINAL_QUOTE = "FINAL_QUOTE",
  MATERIALS_PURCHASED = "MATERIALS_PURCHASED",
  CLIENT_INSPECTION = "CLIENT_INSPECTION",
  COMPLETED = "COMPLETED",
}

export interface JobProgressLog {
  user: string;
  update: JobProgress;
}

export interface Task {
  id: string;
  description: string;
  name: string;
  estimatedCost: number;
  requestCount: number;
  categoryId: string;
  subCategoryId: string;
  imageUrl: string;
}

export interface Tradesman {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  location: Location;
  level: number;
  imageUrl: string;
  categoryId: string;
  rewardPoints: number;
  referralCode: string;
  availability?: Availability;
}

export interface Contact {
  notes?: string;
  location: Location;
}

export interface JobCustomTask {
  name: string;
  description: string;
  resourceUrl: string;
  subCategoryId: string;
  categoryId: string;
}

export interface InvoiceMaterial {
  name: string;
  unitPrice: number;
  quantity: number;
}

export interface Job {
  id: string;
  jobCode: string;
  custom?: JobCustomTask;
  assignedTradesman?: Tradesman;
  task?: Task;
  finalCost: number;
  status: Status;
  completedAt: number;
  payment?: Payment;
  contact: Contact;
  progressLog?: JobProgressLog[];
  invoiceDetails?: {
    materials: InvoiceMaterial[];
    labor: number;
  };
}

export interface LocationSchedule {
  locationId: string;
  scheduledDate: Date;
}

export interface Order {
  id: string;
  orderCode: string;
  inspectionPayment: Payment;
  assignments: TradesmanAssignment[];
  jobs: Job[];
  client: OrderUser;
  createdByUid: string;
  locationSchedules: LocationSchedule[];
  createdAt: Date;
  isEmergency: boolean;
  suggestedTradesmen?: Tradesman[];
  isCompleted?: boolean;
  isAssigned?: boolean;
}

interface Recipient {
  name: string;

  address: string[];

  phoneNumber: string;

  email: string;
}

interface InvoiceItem {
  name: string;

  amount: number;

  description: string[];
}

export enum InvoiceType {
  INSPECTION = "INSPECTION",
  MATERIALS = "MATERIALS",
}

export interface Invoice {
  id: string;

  type: InvoiceType;

  inspectionAmount: number;

  invoiceNumber: number;

  invoiceDate: Date;

  recipient: Recipient;

  discount: number; // float below 1.0 for discount percentage

  assignments: TradesmanAssignment[];

  payment?: Payment;
}

export interface TradesmanAssignment {
  id: string;
  order: Order;
  jobs: Job[];
  tradesman: Tradesman;
  date: Date;
  progress: JobProgress;
  invoice?: Invoice;
}

export interface IOrderInitialState {
  status: "idle" | "pending" | "error";
  pageInfo: PageInfo;
  edges: Edge<Order>[];
  search: {
    type: string;
    query: string;
  } | null;
  sorts: { type: string; ascending: boolean }[];
  order: Order | null;
}

export interface IJobInitialState {
  message: string | null;
  error: string | null;
  status: "idle" | "pending" | "error";
  job: Job | null;
  tradesmen: Tradesman[];
}

export interface IAssignmentInitialState {
  message: string | null;
  error: string | null;
  status: "idle" | "pending" | "error";
  availableTradesmenInCurrentOrder: Tradesman[];
  fetchedAssignments: {
    [x: string]: TradesmanAssignment;
  };
  create: {
    date: number | null;
    jobIds: string[];
    orderId: string;
    tradesman: Tradesman | null;
  };
  currentAssignmentPayment: {
    modalOpen: boolean;
    paymentUrl: string;
    transactionReference: string;
  };
  editInvoice: {
    modalOpen: boolean;
  };
}
