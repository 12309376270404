import { JobProgress } from "./types";

export const ONE_DAY_IN_SECONDS = 86400000;

export const STRINGS = {
  CLIENT_INFORMATION: "client information",
  NAME: "name",
  EMAIL: "email",
  PHONE_NUMBER: "phone number",
  TOTAL_DUE: "total due",
  ESTIMATED_COST: "estimated cost",
  FINAL_COST: "final cost",
  PAYMENT_INFORMATION: "payment information",
  JOBS: "jobs",
  DESCRIPTION: "description",
  CATEGORY: "category",
  SUB_CATEGORY: "sub category",
  SCHEDULED_DATE: "date scheduled",
  LOCATION: "location",
  NOTES: "Arrival Instructions",
  TRADESMAN_ASSIGNMENT_SUB:
    "All current assignments on this order are listed here. You can delete, edit or create an  assignment here",
  TRADESMAN_ASSIGNMENT_SELECT_JOB: {
    header: "Select Jobs You want to assign to One Tradesman",
    body: `You can only select multiple jobs from the same category to assign to a tradesman.\n
      Remember these jobs can be at different locations so once a job at one location is selected,\n
      the list is filtered to only have jobs at that location`,
  },
};

export const orderSearchOptions: {
  [x: string]: any;
} = {
  ORDER_CODE: {
    key: "orderId",
    text: "By Order Code",
    value: "ORDER_CODE",
    placeholder: "Search By Order Code...",
  },
  CLIENT_NAME: {
    key: "clientName",
    text: "By Client Name",
    value: "CLIENT_NAME",
    placeholder: "Search By Client Name...",
  },
};

export const jobSearchOptions: {
  [x: string]: any;
} = {
  JOB_CODE: {
    key: "jobCode",
    text: "By Job Code",
    value: "JOB_CODE",
    placeholder: "Search By Job Code...",
  },
  TASK_NAME: {
    key: "taskName",
    text: "By Task Name",
    value: "TASK_NAME",
    placeholder: "Search By Task Name...",
  },
  LOCATION: {
    key: "location",
    text: "By Location",
    value: "LOCATION",
    placeholder: "Search By Location...",
  },
};

export const tradesmanSearchOptions: {
  [x: string]: any;
} = {
  ID: {
    key: "id",
    text: "By Tradesman ID",
    value: "ID",
    placeholder: "Search By Tradesman ID...",
  },
  NAME: {
    key: "name",
    text: "By Name",
    value: "NAME",
    placeholder: "Search By Tradesman's Name...",
  },
  LOCATION: {
    key: "location",
    text: "By Location",
    value: "LOCATION",
    placeholder: "Search By Location...",
  },
};

export const jobStatusOptions = [
  {
    key: "0",
    text: "Not Started",
    value: JobProgress.NOT_STARTED,
  },
  {
    key: "1",
    text: "Pre-Inspection",
    value: JobProgress.PRE_INSPECTION,
  },
  {
    key: "2",
    text: "Final Quote",
    value: JobProgress.FINAL_QUOTE,
  },
  {
    key: "3",
    text: "Materials Bought",
    value: JobProgress.MATERIALS_PURCHASED,
  },
  {
    key: "4",
    text: "Client Inspection",
    value: JobProgress.CLIENT_INSPECTION,
  },
  {
    key: "5",
    text: "Completed",
    value: JobProgress.COMPLETED,
  },
];
