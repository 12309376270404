import { Heading, Stack, Tag, Text } from "@chakra-ui/react";
import { Trade } from "../../common/types/trade";
import { TradeDetailRequirement } from "./trade-detail-requirement";

interface TradeDetailsProps {
  trade: Trade;
}

export const TradeDetails = ({ trade }: TradeDetailsProps) => {
  return (
    <Stack p={8} gap={4}>
      <Heading fontSize='xl'>{trade.name}</Heading>
      {trade.levels.map((level, index) => {
        const passedRequirements = level.requirements.filter(
          (requirement) => requirement.isPassed
        );

        return (
          <Stack key={level.id} bg='white' rounded='md' borderWidth={1}>
            <Stack
              paddingY={3}
              paddingX={6}
              borderBottom='1px'
              borderColor='gray.200'
              direction='row'
              align='center'
            >
              <Text fontWeight='semibold'>Level {index + 1}</Text>
              <Tag
                size='lg'
                colorScheme={
                  passedRequirements.length === level.requirements.length
                    ? "green"
                    : passedRequirements.length === 0
                    ? "red"
                    : "yellow"
                }
                borderRadius='full'
              >
                {passedRequirements.length} / {level.requirements.length}
              </Tag>
            </Stack>
            <Stack paddingX={4} paddingY={4} spacing='4'>
              {level.requirements.map((requirement) => (
                <TradeDetailRequirement
                  key={requirement.id}
                  requirement={requirement}
                />
              ))}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
