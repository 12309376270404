import React, { FC, useRef } from "react";
import { Table, TableProps } from "antd";
import { Form, Dropdown, Input } from "semantic-ui-react";

export const CustomTable: FC<{
  scroll?: any;
  columns: any[];
  data: any[];
  loading?: boolean;
  onRow?: any;
  rowSelection?: any;
  handleTableChange?: TableProps<any>["onChange"];
  fetchMore?: () => void;
  hasMore?: boolean;
  search?: {
    options: any;
    selectedOption: any;
    searchValue: string;
    setSelectedOption: (option: any) => void;
    handleSearchClick: (e: any) => void;
    handleSearchChange: (e: any) => void;
  };
}> = ({
  scroll,
  columns,
  data,
  search,
  loading,
  fetchMore,
  handleTableChange,
  onRow,
  rowSelection,
  hasMore,
}) => {
  const table = useRef<any>(null);

  return (
    <>
      {search && (
        <div className='o-order__tableControls'>
          <Form>
            <Form.Group style={{ justifyContent: "space-between" }}>
              <Form.Field
                width='8'
                control={Input}
                value={search.searchValue}
                onChange={search.handleSearchChange}
                placeholder={search.selectedOption.placeholder}
                action={
                  <Dropdown
                    className='m-order__dropdown -secondary'
                    button
                    value={search.selectedOption.value}
                    options={Object.values(search.options)}
                    onChange={(event, data) => {
                      if (typeof data.value === "string") {
                        search.setSelectedOption(search.options[data.value]);
                      }
                    }}
                  ></Dropdown>
                }
                icon='search'
                iconPosition='left'
              ></Form.Field>
            </Form.Group>
          </Form>
        </div>
      )}
      <div className='o-table__wrapper' ref={table}>
        <Table
          rowSelection={rowSelection}
          onRow={onRow}
          loading={loading}
          onChange={handleTableChange}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            x: scroll?.x || 1300,
            y: 400,
            scrollToFirstRowOnChange: false,
          }}
        ></Table>

        {fetchMore && (
          <div className='m-table__actions'>
            <button
              disabled={loading || !hasMore}
              className='a-table__actionButton'
              onClick={() => {
                fetchMore();
              }}
            >
              {hasMore ? "Load more" : "All Loaded"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};
