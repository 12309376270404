import React, { FC, useState } from "react";
import { userDetails } from "../constants";
import { useQuery } from "@apollo/client";
import CustomButton from "@laborhack/custom-button";
import { EditClientModal } from "./EditClient";
import Island from "../../../_components/Island";
import { DeleteClient } from "./DeleteClient";
import { GET_CLIENT } from "../graphql/query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Client } from "../../../types";

const ClientUserDetails: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, refetch, error } = useQuery<{ client: Client }>(
    GET_CLIENT,
    {
      variables: {
        id,
      },
    }
  );

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const client = data?.client;

  if (error?.message === "Client does not exist") {
    return (
      <p>
        This user has been deleted or does not exist.{" "}
        <Link to='/clients'>
          <strong>Go Back</strong>
        </Link>
      </p>
    );
  }

  if (loading || !client) {
    return <div className='loading'>loading...</div>;
  }

  const { firstName, lastName, email, phoneNumber, userId } = client;

  const { NAME, EMAIL, PHONENUMBER } = userDetails;

  const refreshData = () => {
    refetch();
  };

  return (
    <div>
      <DeleteClient
        isOpen={deleteConfirmationOpen}
        handleClose={() => {
          setDeleteConfirmationOpen(false);
          refetch();
        }}
        client={client}
      />
      <Island
        header='Client Information'
        actions={[
          <CustomButton
            key='deleteUser'
            onClick={() => setDeleteConfirmationOpen(true)}
          >
            Delete
          </CustomButton>,
        ]}
      >
        <div>
          <div className='o-tradesman__personal-details'>
            <div className='o-tradesmen__personal-left'>
              <h3>Personal Information: </h3>
            </div>
            <div className='o-tradesmen__personal-right'>
              <h4>
                {NAME}
                {firstName} {lastName}
              </h4>
              <h4>
                {EMAIL}
                {email}
              </h4>
              <h4>
                {PHONENUMBER}
                {phoneNumber}
              </h4>
            </div>
          </div>

          <div className='o-tradesmen__details-buttons'>
            <EditClientModal
              userId={userId}
              refresh={refreshData}
              firstname={firstName}
              propEmail={email}
              lastname={lastName}
            />
          </div>
        </div>
      </Island>
    </div>
  );
};

export default ClientUserDetails;
