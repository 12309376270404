import React, { FC } from "react";
import moment, { Moment } from "moment";
import Button from "@laborhack/custom-button";
import { Icon, Modal } from "semantic-ui-react";
import NonBillableDateItem from "./NonBillableDateItem";
import { Switch } from "antd";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import { NonBillableDate } from "../../types";
import { isAfter, isBefore, isSameDay } from "date-fns";
import {
  forceActualDay,
  getAllNonBillableDates,
} from "../../../../helpers/helpers";
import { WorkingDays } from "../../../../types";

export interface NonBillableDatesProps {
  startDate: Date;
  endDate: Date;
  nonBillableDates: NonBillableDate[];
  setNonBillableDates: (nonBillableDates: NonBillableDate[]) => void;
  nonWorkingDays: WorkingDays[];
  readOnly?: boolean;
}

export const NonBillableDates: FC<NonBillableDatesProps> = ({
  nonBillableDates,
  setNonBillableDates,
  nonWorkingDays,
  startDate: minDate,
  endDate: maxDate,
  readOnly,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectRange, setSelectRange] = React.useState(false);

  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [focusedInput, setFocusedInput] = React.useState<
    "startDate" | "endDate" | null
  >(null);

  const [
    singleDayFocusedInput,
    setSingleDayFocusedInput,
  ] = React.useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setStartDate(null);
    setEndDate(null);
    setSelectRange(false);
  };

  const allNonBillableDates = getAllNonBillableDates(nonBillableDates);

  const isNonBillableDate = (date: Moment) => {
    return !!allNonBillableDates.find((nonBillableDate) =>
      isSameDay(nonBillableDate, date.toDate())
    );
  };

  const handleSave = () => {
    if (!startDate) {
      return;
    }

    if (selectRange && !endDate) {
      return;
    }

    setNonBillableDates([
      ...nonBillableDates,
      {
        startDate,
        ...(endDate && { endDate }),
      },
    ]);
    handleClose();
  };

  const handleRemove = (startDate: Date, endDate?: Date) => {
    if (!endDate) {
      setNonBillableDates(
        nonBillableDates.filter(
          (nonBillableDate) => !isSameDay(nonBillableDate.startDate, startDate)
        )
      );
    } else {
      setNonBillableDates(
        nonBillableDates.filter((nonBillableDate) => {
          if (!nonBillableDate.endDate) {
            return true;
          }

          return (
            !isSameDay(nonBillableDate.startDate, startDate) &&
            !isSameDay(nonBillableDate.endDate, endDate)
          );
        })
      );
    }

    handleClose();
  };

  const isNonWorkingDay = (date: Moment) => {
    return !!nonWorkingDays.find(
      (day) => moment.weekdays(date.weekday()) === day
    );
  };

  return (
    <>
      <Modal
        open={open}
        size='tiny'
        onClose={handleClose}
        closeIcon
        closeOnDimmerClick
      >
        <div className='p-8'>
          <div className='mb-8'>
            <h3 className='font-semibold text-primary-500'>
              Add Non-Billable Date
            </h3>
            <p>Select a single day or a range of dates</p>
          </div>

          <div className='my-4'>
            <div className='flex items-center my-4'>
              <Switch
                size='small'
                checked={selectRange}
                onChange={(checked) => {
                  setStartDate(null);
                  setEndDate(null);
                  setSelectRange(checked);
                }}
              />
              <p className='ml-2'>Select Date Range</p>
            </div>
            {selectRange ? (
              <DateRangePicker
                startDate={startDate ? moment(startDate) : null}
                startDateId='startId'
                endDate={endDate ? moment(endDate) : null}
                endDateId='endDateId'
                onDatesChange={({ startDate, endDate }) => {
                  startDate && setStartDate(forceActualDay(startDate.toDate()));
                  endDate && setEndDate(forceActualDay(endDate.toDate()));
                }}
                onFocusChange={setFocusedInput}
                focusedInput={focusedInput}
                numberOfMonths={1}
                minDate={moment(minDate)}
                maxDate={moment(maxDate)}
                isOutsideRange={(day) =>
                  isBefore(forceActualDay(day.toDate()), minDate) ||
                  isAfter(forceActualDay(day.toDate()), maxDate)
                }
                isDayBlocked={(day) => {
                  return isNonWorkingDay(day) || isNonBillableDate(day);
                }}
              />
            ) : (
              <SingleDatePicker
                date={startDate ? moment(startDate) : null}
                onDateChange={(date) => {
                  date && setStartDate(forceActualDay(date.toDate()));
                }}
                focused={singleDayFocusedInput}
                onFocusChange={({ focused }) =>
                  setSingleDayFocusedInput(focused)
                }
                id='your_unique_id'
                numberOfMonths={1}
                isOutsideRange={(day) =>
                  isBefore(forceActualDay(day.toDate()), minDate) ||
                  isAfter(forceActualDay(day.toDate()), maxDate)
                }
                isDayBlocked={(day) => {
                  return isNonWorkingDay(day) || isNonBillableDate(day);
                }}
              />
            )}
          </div>
          <div className='flex justify-end'>
            <Button
              onClick={handleSave}
              disabled={selectRange ? !startDate || !endDate : !startDate}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
      <div className='flex flex-wrap items-center'>
        {nonBillableDates.map(({ startDate, endDate }) => {
          return (
            <NonBillableDateItem
              key={`${startDate.toDateString()}${endDate?.toDateString()}`}
              startDate={startDate}
              endDate={endDate}
              onRemove={
                !readOnly
                  ? () => {
                      handleRemove(startDate, endDate);
                    }
                  : undefined
              }
            />
          );
        })}
        {!readOnly && (
          <Button
            onClick={() => {
              setOpen(true);
            }}
            type='link'
          >
            <Icon name='plus' /> Add Date
          </Button>
        )}
      </div>
    </>
  );
};
