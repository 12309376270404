import clsx from "clsx";
import React, { FC, ReactElement } from "react";
import StepLabel from "./StepLabel";

import styles from "./styles.module.scss";

export interface FormStepProps {
  step: number;
  label: string | ReactElement;
  disabled?: boolean;
}

export const FormStep: FC<FormStepProps> = ({
  step,
  label,
  disabled,
  children,
}) => {
  return (
    <div className={clsx(styles.wrapper, disabled && styles.disabled)}>
      <StepLabel step={step}>{label}</StepLabel>
      <div className={styles.stepBodyWrapper}>
        <div className={styles.stepBody}>{children}</div>
      </div>
    </div>
  );
};
