import { FC } from "react";
import { useSelector } from "react-redux";
import { formatAsNaira } from "../../../../../../../../../helpers/helpers";
import { CatServiceSelectors } from "../../../../../../../../../redux/store";
import { ContractQuotationItem } from "../../../../../../../types";

const { selectCategories } = CatServiceSelectors;

export interface SummaryItemProps extends ContractQuotationItem {}

export const SummaryItem: FC<SummaryItemProps> = ({
  proCount,
  categoryId,
  level,
  rate,
}) => {
  const categories = useSelector(selectCategories);

  return (
    <p>
      <span className='font-semibold'>{proCount}</span>{" "}
      <span className='font-semibold'>{categories[categoryId].name}</span>{" "}
      pro(s) at <span className='font-semibold'>Level {level}</span> for{" "}
      <span className='font-semibold'>
        {rate ? formatAsNaira(rate) : "N/A"}
      </span>{" "}
      per pro each day
    </p>
  );
};
