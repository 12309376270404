import React, { FC, useState } from "react";
import { Icon, Progress } from "semantic-ui-react";
import Button from "@laborhack/custom-button";
import { components as LayoutComponents } from "../../layout";
import { orderSearchOptions } from "../constants";

import { useQuery } from "@apollo/client";
import { Order, TransactionStatus } from "../types";
import { ColumnsType } from "antd/lib/table";
import { useHistory } from "react-router-dom";
import Island from "../../../_components/Island";
import { GET_ORDERS_QUERY } from "../graphql/query";
import Badge from "../../../_components/Badge";

const { Trail, CustomTable } = LayoutComponents;

export const AllOrders: FC = () => {
  const options = Object.values(orderSearchOptions);

  const history = useHistory();
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchOption, setSearchOption] = useState(options[0]);

  const { data, loading, fetchMore } = useQuery(GET_ORDERS_QUERY, {
    variables: {
      limit: 10,
      cursor: undefined,
      sorts: [
        {
          type: "ORDER_CODE",
          ascending: false,
        },
      ],
      filters: [],
      search: searchValue
        ? {
            type: searchOption.value,
            query: searchValue,
          }
        : undefined,
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
  });

  const columns: ColumnsType<Order & { key: string }> = [
    {
      title: "Order Code",
      dataIndex: "ORDER_CODE",
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
      render: (text, record) => record.orderCode,
      width: "10%",
    },
    {
      title: "Client Name",
      dataIndex: "CLIENT_NAME",
      render: (text, record) => `${record.client.name}`,
    },
    {
      title: "Tasks",
      dataIndex: "",
      render: (text, record) => {
        const jobsCompleted = record.jobs.filter(
          ({ completedAt }) => !!completedAt
        ).length;

        return (
          <Progress
            className='a-table__progress'
            color={
              ((record.jobs.length > 1) && jobsCompleted === record.jobs.length) || record.isCompleted
                ? "green"
                : "yellow"
            }
            size='small'
            value={jobsCompleted}
            total={record.jobs.length}
            progress='ratio'
          ></Progress>
        );
      },
    },
    {
      title: "Assigned",
      render: (text, record) => {
        return record.isAssigned ? "YES" : "NO";
      },
      width: "10%",
    },
    {
      title: "Inspection Payment",
      dataIndex: "",
      width: "15%",
      render: (text, record) => {
        return (
          <Icon
            size='large'
            {...(record.inspectionPayment?.status === TransactionStatus.SUCCESS
              ? { name: "check circle", color: "green" }
              : { name: "times circle", color: "red" })}
          />
        );
      },
    },
    {
      title: "Status",
      render: (text, record) => {
        if (record.jobs.length === 0) {
          return <Badge type='basic' inverted text='no jobs set'></Badge>;
        } 

        if (!record.isAssigned) {
          return <Badge type='basic' inverted text='processing'></Badge>;
        }

        if (!record.isCompleted) {
          return <Badge type='progress' inverted text='in progress'></Badge>;
        }

        return <Badge type='success' inverted text='complete'></Badge>;
      },
    },
    {
      title: "Date Created",
      dataIndex: "",
      render: (text, record) => new Date(record.createdAt).toLocaleString(),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      width: "5%",
      render: (text: any, record) => (
        <Icon
          className='pointer'
          name='long arrow alternate right'
          onClick={() => {
            history.push("/orders/" + record.orderCode);
          }}
        ></Icon>
      ),
    },
  ];

  const getMore = () => {
    fetchMore({
      variables: {
        limit: 10,
        cursor: data?.listOrders.pageInfo.endCursor,
        sorts: [
          {
            type: "ORDER_CODE",
            ascending: false,
          },
        ],
        filters: [],
        search: searchValue
          ? {
              type: searchOption.value,
              query: searchValue,
            }
          : undefined,
      },
    });
  };

  return (
    <Island header={<Trail root='All Orders'></Trail>} text='All orders'>
      <div className='w-100 flex justify-end mb-4'>
        <Button
          onClick={() => {
            history.push("/orders/create");
          }}
        >
          Create Order
        </Button>
      </div>
      <CustomTable
        columns={columns}
        search={{
          searchValue,
          options: orderSearchOptions,
          selectedOption: searchOption,
          setSelectedOption: setSearchOption,
          handleSearchChange: (e: any) => {
            setSearchValue(e.target.value);
          },
          handleSearchClick: (e: any) => {},
        }}
        data={data?.listOrders.edges.map((edge: any) => {
          return { key: edge.node.id, ...edge.node };
        })}
        onRow={(record: any, index: any) => {
          return {
            onClick: () => {
              history.push("/orders/" + record.orderCode);
            },
          };
        }}
        loading={loading}
        handleTableChange={() => {}}
        hasMore={data?.listOrders.pageInfo.hasNextPage}
        fetchMore={getMore}
      />
    </Island>
  );
};
