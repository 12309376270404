import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { firebase } from "../firebase";

const link = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  return new Promise(async (resolve) => {
    const token = await firebase.auth().currentUser?.getIdToken();

    resolve({
      headers: {
        ...headers,
        authorization: token || "",
      },
    });
  });
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        listOrders: {
          ...relayStylePagination(),
          keyArgs: ["sorts", "filters", "search"],
        },
        clients: {
          ...relayStylePagination(),
        },
        invoices: {
          ...relayStylePagination(),
        },
        assignmentPayments: {
          ...relayStylePagination(),
          keyArgs: ["filters"],
        },
        businesses: { ...relayStylePagination(), keyArgs: ["filters"] },
        proCertifications: { ...relayStylePagination(), keyArgs: ["filters"] },
        listPros: {
          ...relayStylePagination(),
          keyArgs: ["filters", "sort", "search"],
        },
        listProTradeRequirements: {
          ...relayStylePagination(),
          keyArgs: ["filters", "search"],
        },
        listProsForRecruitment: {
          ...relayStylePagination(),
          keyArgs: ["filters", "sort", "search"],
        },
      },
    },
  },
});

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache: cache,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
});
