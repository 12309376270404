import React, { FC } from "react";
import { ColumnsType } from "antd/lib/table";
import Button from "@laborhack/custom-button";
import { components as LayoutComponents } from "../../../../../components/layout";
import { Contract } from "../../../types";
import { gql, useQuery } from "@apollo/client";
import RequestFailed from "../../../../../_components/RequestFailed";
import {
  formatAsNaira,
  invoiceHasAtLeastOnePayment,
  invoiceIsPaid,
} from "../../../../../helpers/helpers";
import { useHistory } from "react-router-dom";
import { IInvoice } from "../../../../../types";
import { format } from "date-fns";
import Badge from "../../../../../_components/Badge";

const { CustomTable } = LayoutComponents;

const GET_INVOICES_FOR_RECRUITMENT = gql`
  query GetInvoicesByRecruitment($recruitmentId: String!) {
    invoicesByRecruitmentId(recruitmentId: $recruitmentId) {
      id
      invoiceNumber
      invoiceDate
      isFullyPaid
      hasAtLeastOnePayment
      options {
        materialCommissionCap
      }
      recruitmentData {
        numberOfBilledDays
        agreedDailyRate
      }
      payment {
        id
        transactionDate
        transactionReference
        status
        providerId
      }
      splitPayments {
        percentage
        payment {
          id
          status
          amount
          transactionDate
          transactionReference
          providerId
        }
      }
    }
  }
`;

export interface RecruitmentInvoicesProps {
  contractRecruitmentId: string;
}

export const RecruitmentInvoices: FC<RecruitmentInvoicesProps> = ({
  contractRecruitmentId,
}) => {
  const history = useHistory();

  const { loading, error, data } = useQuery<{
    invoicesByRecruitmentId: IInvoice[];
  }>(GET_INVOICES_FOR_RECRUITMENT, {
    variables: {
      recruitmentId: contractRecruitmentId,
    },
  });

  const columns: ColumnsType<IInvoice> = [
    {
      title: "Invoice No.",
      render: (text, record) => {
        const { invoiceNumber } = record;

        return <p>#{`${invoiceNumber}`}</p>;
      },
      width: "10%",
    },
    {
      title: "Pros",
      render: (text, record) => {
        const { recruitmentData } = record;

        return <p>{`${recruitmentData.length}`}</p>;
      },
      width: "10%",
    },
    {
      title: "Amount",
      render: (text, record) => {
        const { recruitmentData } = record;

        const total = recruitmentData.reduce(
          (acc, curr) => acc + curr.agreedDailyRate * curr.numberOfBilledDays,
          0
        );

        return <p>{`${formatAsNaira(total)}`}</p>;
      },
      width: "10%",
    },
    {
      title: "Paid",
      render: (text, record) => {
        const isFullyPaid = invoiceIsPaid(record);
        const partiallyPaid = invoiceHasAtLeastOnePayment(record);

        return (
          <Badge
            inverted
            text={
              isFullyPaid
                ? "Paid"
                : partiallyPaid
                ? "Partially Paid"
                : "Not Paid"
            }
            type={
              isFullyPaid
                ? "complete"
                : partiallyPaid
                ? "progress"
                : "cancelled"
            }
          />
        );
      },
      width: "10%",
    },
    {
      title: "Created on",
      render: (text, record) => {
        const { invoiceDate } = record;

        return <p>{`${format(new Date(invoiceDate), "do MMM, yyyy")}`}</p>;
      },
      width: "10%",
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <div>
            <Button
              type='link'
              onClick={() => {
                history.push(`/invoices/${record.id}`);
              }}
            >
              View Invoice
            </Button>
          </div>
        );
      },
      width: "10%",
    },
  ];

  if (error) {
    return <RequestFailed />;
  }

  return (
    <div>
      <CustomTable
        onRow={(record: Contract, index: any) => {
          return {
            onClick: () => {},
          };
        }}
        columns={columns}
        loading={loading}
        data={
          data?.invoicesByRecruitmentId.map((invoice) => {
            return {
              key: invoice.id,
              ...invoice,
            };
          }) || []
        }
        handleTableChange={() => {}}
      />
    </div>
  );
};
