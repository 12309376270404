import Table, { ColumnsType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import React from "react";
import { Icon } from "semantic-ui-react";
import {
  calculateLaborHackFee,
  calculateMaterialsAndLabor,
  calculateTotal,
  formatAsNaira,
} from "../../../helpers/helpers";
import { TransactionStatus } from "../../../types";
import { InvoiceTableRow } from "../types";
import { assignmentsTableRenderer } from "./assignmentsTableRenderer";

export interface OrderInvoicesTableProps {
  invoiceRows: InvoiceTableRow[];
  orderCode: string;
  selectable?: TableRowSelection<InvoiceTableRow>;
  moveTo: (path: string) => void;
}

export const orderInvoicesTableRenderer = ({
  invoiceRows,
  orderCode,
  selectable,
  moveTo,
}: OrderInvoicesTableProps) => {
  const columns: ColumnsType<InvoiceTableRow> = [
    {
      title: "Invoice No",
      render: (text, record) => (
        <p className='a-orderDetail__tableText'>
          #{record.invoice?.invoiceNumber}
        </p>
      ),
      width: "5%",
    },
    {
      title: "Recipient Name",
      render: (text, record) => (
        <p className='a-orderDetail__tableText'>
          {record.invoice?.recipient.name}
        </p>
      ),
      width: "15%",
    },
    {
      title: "Assignments",
      render: (text, record) => (
        <p className='a-orderDetail__tableText'>
          {record.assignmentTableRows.length}
        </p>
      ),
      width: "5%",
      sorter: (a, b) =>
        a.assignmentTableRows.length - b.assignmentTableRows.length,
    },
    {
      title: "Billing Address",

      width: "29%",
      render: (text, record) => (
        <p className='a-orderDetail__tableText'>
          {record.invoice?.recipient.address.join(", ")}
        </p>
      ),
    },
    {
      title: "Total Cost",
      dataIndex: "assignedTo",
      width: "13%",
      render: (text, record) => {
        const jobs = record.assignmentTableRows.flatMap(({ jobs }) => jobs);

        const { materials, labor } = calculateMaterialsAndLabor(jobs);
        const laborHackFee = calculateLaborHackFee(
          materials,
          labor,
          record.invoice?.options
        );
        const total = calculateTotal(materials, laborHackFee);

        return (
          <p className='a-orderDetail__tableText'>{formatAsNaira(total)}</p>
        );
      },
    },
    {
      title: "Amount Due",
      dataIndex: "status",
      width: "13%",
      render: (text, record) => {
        const jobs = record.assignmentTableRows.flatMap(({ jobs }) => jobs);

        const { materials, labor } = calculateMaterialsAndLabor(jobs);
        const laborHackFee = calculateLaborHackFee(
          materials,
          labor,
          record.invoice?.options
        );
        const total = calculateTotal(materials, laborHackFee);

        const hasSplitPayment = !!record.invoice?.splitPayments;

        const hasSinglePayment = !!record.invoice?.payment;

        let paid: number;

        if (!hasSplitPayment && !hasSinglePayment) {
          paid = 0;
        } else if (!!record.invoice?.splitPayments) {
          paid = record.invoice.splitPayments
            .filter(
              ({ payment }) => payment?.status === TransactionStatus.SUCCESS
            )
            .reduce((acc, { payment }) => acc + (payment?.amount || 0), 0);
        } else {
          paid =
            record.invoice?.payment?.status === TransactionStatus.SUCCESS
              ? record.invoice.payment.amount
              : 0;
        }

        const due = total - (paid || 0);

        return <p className='a-orderDetail__tableText'>{formatAsNaira(due)}</p>;
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      width: "20%",
      render: (text: any, record) => {
        return (
          <button
            className='a-orderDetail__tableButton'
            onClick={() => {
              moveTo(`/invoices/${record.invoice?.id}`);
            }}
          >
            View Invoice
          </button>
        );
      },
    },
  ];

  return (
    <div className='o-orderDetail__tableWrapper'>
      <Table
        className='o-orderDetail__table'
        indentSize={15}
        {...(selectable && {
          rowSelection: selectable,
        })}
        expandable={{
          expandedRowRender: (record) =>
            assignmentsTableRenderer({
              assignments: record.assignmentTableRows,
              orderCode,
              moveTo,
            }),
        }}
        pagination={false}
        columns={columns}
        dataSource={invoiceRows.map((data) => {
          return {
            key: data.invoice?.id,
            ...data,
          };
        })}
      />
    </div>
  );
};
