/**
 *
 * A vertical field component
 *
 * @returns
 */
export const VField = ({
  label,
  value,
}: {
  label: string;
  value: string | JSX.Element;
}) => {
  return (
    <div className='flex flex-col gap-1'>
      <p className='font-semibold text-sm text-primary-300 m-0 p-0'>{label}</p>
      {typeof value === "string" ? (
        <p className='font-semibold text-base text-primary-500 m-0 p-0'>
          {value}
        </p>
      ) : (
        value
      )}
    </div>
  );
};
