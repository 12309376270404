import React from "react";
import { ListProsFilters, ListProsSearch, ListProsSort } from "../types";

export interface ProsContextType {
  search?: ListProsSearch;
  setSearch: React.Dispatch<React.SetStateAction<ListProsSearch | undefined>>;
  filters?: ListProsFilters;
  setFilters: React.Dispatch<React.SetStateAction<ListProsFilters | undefined>>;
  sort?: ListProsSort;
  setSort: React.Dispatch<React.SetStateAction<ListProsSort>>;
}

export const ProsContext = React.createContext<ProsContextType>({
  setSearch: () => {},
  setFilters: () => {},
  setSort: () => {},
});
