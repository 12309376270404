import React, { FC } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { AllOrders } from "./AllOrders";
import { OrderDetail } from "./OrderDetail";
import { Job } from "../../../pages/orders/job/[jobCode]";
import NewOrders from "./NewOrders";
import AssignedOrders from "./AssignedOrders";
import CompletedOrders from "./CompletedOrders";
import PendingOrders from "./PendingOrders";
import Create from "../../../pages/orders/create";
import { CreateQuotePage } from "../../../pages/orders/[id]/quotes/create";
import { ViewQuotePage } from "../../../pages/orders/[id]/quotes/[id]";

export const Order: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/create`}>
        <Create />
      </Route>
      <Route exact path={`${path}/all`}>
        <AllOrders />
      </Route>
      <Route exact path={`${path}/new`}>
        <NewOrders />
      </Route>
      <Route exact path={`${path}/assigned`}>
        <AssignedOrders />
      </Route>
      <Route exact path={`${path}/completed`}>
        <CompletedOrders />
      </Route>
      <Route exact path={`${path}/pending`}>
        <PendingOrders />
      </Route>
      <Route path={`${path}/job/:jobCode`}>
        <Job />
      </Route>
      <Route path={`${path}/:orderCode/quotes/create`}>
        <CreateQuotePage />
      </Route>
      <Route path={`${path}/:orderCode/quotes/:id`}>
        <ViewQuotePage />
      </Route>
      <Route path={`${path}/:orderCode`}>
        <OrderDetail />
      </Route>
    </Switch>
  );
};
