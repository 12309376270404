import { PAYMENT_PROVIDERS } from "@/pages/orders/_graphql/query";
import { CONFIRM_OFFLINE_TRANSFER } from "@/pages/payment/graphql/mutation";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Link,
  PinInput,
  PinInputField,
  Radio,
  RadioGroup,
  Select,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { ref } from "@hapi/joi";
import { is } from "immer/dist/internal";
import { useEffect, useState } from "react";
import {
  FaArrowRight,
  FaCheckCircle,
  FaExternalLinkAlt,
  FaStopCircle,
  FaSync,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  getTransferBadgeColor,
  GET_CONTRACT_PAYOUT,
} from "../../../../features/contract-payouts/constants";
import { ContractPayout } from "../../../../features/contract-payouts/types";
import { forceActualDay, formatAsNaira } from "../../../../helpers/helpers";
import { PaymentProvider, TransferStatus } from "../../../../types";
import Loading from "../../../../_components/Loading";
import RequestFailed from "../../../../_components/RequestFailed";
import { PaymentMethod } from "../../../recruitments/types";

const GET_DEFAULT_TRANSFER_RECIPIENT = gql`
  query GetDefaultTransferRecipient($userId: String!) {
    defaultTransferRecipients(userId: $userId) {
      providerId
      bankInformation {
        accountName
        accountNumber
        bankName
      }
    }
  }
`;

export const APPROVE_CONTRACT_PAYOUT = gql`
  mutation ApproveContractPayout($payoutId: String!, $providerId: String!) {
    approveContractPayout(payoutId: $payoutId, providerId: $providerId) {
      id
      transfer {
        id
        transferCode
      }
    }
  }
`;

export const FINALIZE_CONTRACT_PAYOUT = gql`
  mutation FinalizeContractPayout($payoutId: String!, $otp: String!) {
    finalizeContractPayout(payoutId: $payoutId, otp: $otp) {
      id
      transfer {
        id
        status
      }
    }
  }
`;

export const CANCEL_TRANSFER_REQUEST = gql`
  mutation CancelTransferRequest($data: CancelTransferRequestInput!) {
    cancelTransferRequest(data: $data) {
      id
      status
    }
  }
`;

export default function Page() {
  const { id } = useParams<{
    id: string;
  }>();

  const [
    getDefaultTransferRecipient,
    getDefaultTransferRecipientResponse,
  ] = useLazyQuery<{
    defaultTransferRecipients: {
      providerId: string;
      bankInformation: {
        accountName: string;
        accountNumber: string;
        bankName: string;
      };
    }[];
  }>(GET_DEFAULT_TRANSFER_RECIPIENT);

  const { data, loading, error, refetch } = useQuery<{
    contractPayout: ContractPayout | null;
  }>(GET_CONTRACT_PAYOUT, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      getDefaultTransferRecipient({
        variables: {
          userId: data.contractPayout?.worksheet.contract.pro.userId,
        },
      });
    },
  });

  const { loading: paymentProvidersLoading, data: paymentProvidersData, error: paymentProvidersError } = useQuery<{
    paymentProviders: PaymentProvider[];
  }>(PAYMENT_PROVIDERS);

  const paymentProviders = paymentProvidersData?.paymentProviders;

  const [approveContractPayout, approveContractPayoutResponse] = useMutation(
    APPROVE_CONTRACT_PAYOUT,
    {
      onError: () => {},
    }
  );
  const [finalizeContractPayout, finalizeContractPayoutResponse] = useMutation(
    FINALIZE_CONTRACT_PAYOUT,
    {
      onError: () => {},
    }
  );

  const [confirmOfflineTransfer, confirmOfflineTransferCall] = useMutation(
    CONFIRM_OFFLINE_TRANSFER,
    {
      onError: () => { },
      onCompleted: () => {
        refetch();
      }
    }
  );

  const [cancelTransferRequest, cancelTransferRequestResponse] = useMutation(
    CANCEL_TRANSFER_REQUEST,
    {
      onError: () => {},
    }
  );

  const [otp, setOtp] = useState<string>();

  const [radioValue, setRadioValue] = useState<string>();

  const [offlineTransactionReference, setOfflineTransactionReference] = useState<string>();

  const [sourceLaborHackAccount, setSourceLaborHackAccount] = useState<string>("Access Bank");

  const [offlineTransactionDate, setOfflineTransactionDate] = useState<Date>();

  const submitOfflineTransferDetails = async () => {
    if (!offlineTransactionReference || !offlineTransactionDate) return;

    confirmOfflineTransfer({
      variables: {
        transferId: data?.contractPayout?.transfer.id,
        transactionReference: offlineTransactionReference,
        transactionDate: new Date(offlineTransactionDate),
        bankName: sourceLaborHackAccount
      },
    });
  };

  const isApprovable =
    data?.contractPayout?.transfer.status === TransferStatus.AWAITING_APPROVAL;

  if (loading) return <Loading />;

  if (error) return <RequestFailed />;

  if (!data?.contractPayout) return <RequestFailed errorMessage='Not Found' />;

  const {
    breakdown,
    override,
    worksheet: {
      expectedUnitAmount,
      unitAmount,
      contract: {
        paymentAmount,
        paymentMethod,
        pro: { firstName, lastName, email, phoneNumber },
        recruitment: {
          id: recruitmentId,
          client: {
            firstName: clientFirstName,
            lastName: clientLastName,
            email: clientEmail,
            business,
          },
        },
      },
      payroll: {
        period: { start, end },
      },
    },
    transfer,
  } = data.contractPayout;

  const paymentInformation = getDefaultTransferRecipientResponse.data?.defaultTransferRecipients?.find(
    (recipient) => recipient.providerId === "paystack"
  );

  return (
    <div className='grid grid-cols-3 gap-4'>
      <div className='col-span-2'>
        <div className='flex flex-col gap-8 p-6 bg-white border'>
          <div className='mb-4'>
            <h2 className='text-primary-500 text-lg font-medium m-0'>
              Contract Payout Confirmation #{id.slice(0, 8)}
            </h2>
            <p className='m-0 text-primary-300 text-sm'>
              Shows a detailed view of a contract payout request
            </p>
          </div>
          <div className='grid grid-cols-[1fr,2fr] gap-2 max-w-md'>
            <p className='m-0 text-primary-300 text-sm'>Pro</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {firstName} {lastName}
            </p>
            <p className='m-0 text-primary-300 text-sm'>Email</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>{email}</p>
            <p className='m-0 text-primary-300 text-sm'>Phone Number</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {phoneNumber}
            </p>
            <p className='m-0 text-primary-300 text-sm'>Start</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {new Date(start).toDateString()}
            </p>
            <p className='m-0 text-primary-300 text-sm'>End</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {new Date(end).toDateString()}
            </p>
            <p className='m-0 text-primary-300 text-sm'>ID</p>
            <Link
              href={window.location.origin + `/recruitment/${recruitmentId}`}
              isExternal
              className='!text-primary-500 text-sm font-medium'
            >
              {recruitmentId.slice(0, 8)}
              <FaExternalLinkAlt className='ml-1 inline-block' />
            </Link>
            <p className='m-0 text-primary-300 text-sm'>Business</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {business?.name || "N/A"}
            </p>
            <p className='m-0 text-primary-300 text-sm'>Contact</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {clientFirstName} {clientLastName}
            </p>
            <p className='m-0 text-primary-300 text-sm'>Contact Email</p>
            <p className='m-0 text-primary-500 text-sm font-medium'>
              {clientEmail}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className='flex flex-col gap-6 p-6 bg-white border'>
          <div>
            <h2 className='text-primary-500 text-lg font-medium m-0'>
              Breakdown
            </h2>
          </div>
          <Divider />
          <div className='flex flex-col gap-4'>
            <h3 className='font-medium text-primary-500 text-sm'>
              Payment Information
            </h3>
            {!paymentInformation && (
              <Alert status='error'>
                <AlertIcon />
                <AlertTitle className='text-sm !font-medium'>
                  No payment information found
                </AlertTitle>
                <AlertDescription></AlertDescription>
              </Alert>
            )}
            <div className='flex flex-col gap-2'>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Name</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {paymentInformation?.bankInformation.accountName || "N/A"}
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>AC Number</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {paymentInformation?.bankInformation.accountNumber || "N/A"}
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Bank Name</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {paymentInformation?.bankInformation.bankName || "N/A"}
                </p>
              </div>
            </div>
          </div>
          <Divider />
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-2'>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Status</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  <Badge colorScheme={getTransferBadgeColor(transfer.status)}>
                    {transfer.status.replace("_", " ")}
                  </Badge>
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Type</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {paymentMethod.replace("_", " ")}
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Base (NGN)</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {formatAsNaira(paymentAmount)}
                  {paymentMethod === PaymentMethod.BY_OUTPUT && " /unit"}
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Worksheet</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {((unitAmount / expectedUnitAmount) * 100).toFixed(0)} %{" "}
                  <span className='text-gray-500'>
                    ({unitAmount}/{expectedUnitAmount})
                  </span>
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>
                  Gross Amount (NGN)
                </p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {formatAsNaira(breakdown.gross)}
                </p>
              </div>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Tax (PAYE)</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  - {breakdown.tax ? formatAsNaira(breakdown.tax) : "N/A"}
                </p>
              </div>

              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Overridden</p>
                {override ? (
                  <FaCheckCircle className='text-green-500' />
                ) : (
                  <FaStopCircle className='text-red-500' />
                )}
              </div>
            </div>
          </div>
          <Divider />
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-2'>
              <div className='flex justify-between'>
                <p className='m-0 text-primary-300 text-sm'>Net Amount (NGN)</p>
                <p className='m-0 text-primary-500 text-sm font-medium'>
                  {formatAsNaira(breakdown.net)}
                </p>
              </div>
            </div>
          </div>
          {
            isApprovable && (
              <div>
                <Divider />
                <div className="mt-4">
                  <RadioGroup>
                    {
                      paymentProviders?.map((provider) => (
                        <Stack direction='column' key={provider.id}>
                          <Radio value={provider.id}
                            onChange={(e) => setRadioValue(e.target.value)}
                          >
                            Pay with {provider.name}
                          </Radio>
                        </Stack>
                      ))
                    }
                  </RadioGroup>
                </div>
              </div>
            )
          }
          <Divider />
          {
            isApprovable && radioValue === 'offline' && (
              <div className="space-y-6">
                <VStack spacing={8} alignItems={"start"} w={'80%'}>
                  <Box width={'full'}>
                    <FormLabel>Offline Transaction Reference</FormLabel>
                    <Input value={offlineTransactionReference}
                      onChange={
                        (e) => setOfflineTransactionReference(e.target.value)
                      }
                    />
                  </Box>
                  <Box width={'full'}>
                    <FormLabel>Source LaborHack Account</FormLabel>
                    <Select value={sourceLaborHackAccount}
                      onChange={
                        (e) => setSourceLaborHackAccount(e.target.value)
                      }
                    >
                      <option value="Access Bank">Access Bank</option>
                    </Select>
                  </Box>
                  <Box width={'full'}>
                    <FormLabel>Date</FormLabel>
                    <Input type="date" 
                      value={offlineTransactionDate?.toISOString().split('T')[0]}
                      max={forceActualDay(new Date()).toISOString().split('T')[0]}
                      onChange={
                        (e) => setOfflineTransactionDate(new Date(e.target.value))
                      }
                    />
                  </Box>
                    
                </VStack>
                <Divider />
                <Box width={'full'}>
                  <Button
                    colorScheme='green'
                    rightIcon={<FaArrowRight className='inline-block' />}
                    width={'full'}
                    textAlign={'center'}
                    size={'md'}
                    isLoading={confirmOfflineTransferCall.loading}
                    isDisabled={!offlineTransactionReference || !offlineTransactionDate}
                    onClick={submitOfflineTransferDetails}
                  >
                    Approve Payout
                  </Button>
                </Box>
              </div>
            )
          }
          {isApprovable && radioValue === 'paystack' && (
            <div className='flex flex-col gap-2'>
              {!transfer.transferCode && (
                <Button
                  colorScheme='green'
                  rightIcon={<FaArrowRight className='inline-block' />}
                  isLoading={approveContractPayoutResponse.loading}
                  isDisabled={!paymentInformation}
                  onClick={() => {
                    approveContractPayout({
                      variables: {
                        payoutId: id,
                        providerId: "paystack",
                      },
                    });
                  }}
                >
                  Approve Payout
                </Button>
              )}
              {transfer.transferCode && (
                <>
                  <FormControl
                    isInvalid={!!finalizeContractPayoutResponse.error}
                  >
                    <FormLabel>Enter OTP</FormLabel>
                    <HStack>
                      <HStack>
                        <PinInput
                          otp
                          onComplete={(otp) => {
                            setOtp(otp);
                          }}
                          onChange={() => {
                            finalizeContractPayoutResponse.reset();
                          }}
                        >
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                        </PinInput>
                      </HStack>

                      <Button
                        colorScheme='green'
                        rightIcon={<FaCheckCircle className='inline-block' />}
                        isLoading={finalizeContractPayoutResponse.loading}
                        isDisabled={!otp}
                        onClick={() => {
                          finalizeContractPayout({
                            variables: {
                              payoutId: id,
                              otp,
                            },
                          });
                        }}
                      >
                        Finalize Payout
                      </Button>
                    </HStack>
                    <FormErrorMessage>
                      {finalizeContractPayoutResponse.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <div className='flex justify-start'>
                    <Button
                      variant='link'
                      leftIcon={<FaSync className='inline-block' />}
                      isLoading={approveContractPayoutResponse.loading}
                      onClick={() => {
                        approveContractPayout({
                          variables: {
                            payoutId: id,
                            providerId: "paystack",
                          },
                        });
                      }}
                    >
                      Refresh OTP
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
