import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Icon, Image, Menu } from "semantic-ui-react";
import { IdentityVerificationStatus } from "../../types";
import Island from "../../_components/Island";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { Tab } from "../../_components/Tab/Tab";
import Button from "@laborhack/custom-button";
import fallback from "../../assets/fallback.png";
import { Details, Identification } from "../../features/certifications";
import BackButton from "../../_components/BackButton";
import Badge from "../../_components/Badge";
import { BadgeType } from "../../_components/Badge/Badge";
import { ProTradeRequirement } from "../../common/types/pro-trade-requirement";
import { GET_PRO_TRADE_REQUIREMENT } from "../../graphql/assessments/query";
import Pill from "../../_components/Pill";
import { MARK_UPLOADED } from "../../graphql/assessments/mutation";
import { Results } from "../../features/protraderequirements/Results";

export const ProTradeRequirementView = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<{
    proTradeRequirement: ProTradeRequirement;
  }>(GET_PRO_TRADE_REQUIREMENT, {
    variables: {
      id,
    },
  });

  const [
    markUploaded,
    { error: markUploadedError, loading: markUploadedLoading },
  ] = useMutation(MARK_UPLOADED, {
    variables: {
      proTradeRequirementId: id,
    },
  });

  if (loading) return <Loading />;

  if (error || markUploadedError)
    return (
      <RequestFailed
        errorMessage={error?.message || markUploadedError?.message}
      />
    );

  if (!data) {
    return <RequestFailed />;
  }

  let identificationBadgeColor: BadgeType;

  switch (data.proTradeRequirement.pro.identityVerification?.status) {
    case IdentityVerificationStatus.VERIFIED:
      identificationBadgeColor = "success";
      break;

    case IdentityVerificationStatus.DECLINED:
      identificationBadgeColor = "cancelled";
      break;

    default:
      identificationBadgeColor = "progress";
      break;
  }

  const panes = [
    {
      id: "details",
      title: "Details",
      render: () => <Details pro={data.proTradeRequirement.pro} />,
    },
    {
      id: "identification",
      title: (
        <div className='flex flex-nowrap items-center gap-2'>
          <p className='m-0 p-0 '>Identification</p>{" "}
          <Badge
            text={
              data.proTradeRequirement.pro.identityVerification?.status.valueOf() ||
              "Pending"
            }
            type={identificationBadgeColor}
          />
        </div>
      ),
      render: () => (
        <Identification
          type={data?.proTradeRequirement.pro.identityVerification?.type}
          idNumber={
            data?.proTradeRequirement.pro.identityVerification
              ?.identificationNumber
          }
          documentImageUrl={
            data?.proTradeRequirement.pro.identityVerification
              ?.identificationDocument
          }
          dateOfBirth={
            data.proTradeRequirement.pro.identityVerification?.dateOfBirth
          }
        />
      ),
    },
    {
      id: "results",
      title: "Results",
      render: () => <Results proTradeRequirement={data.proTradeRequirement} />,
    },
  ];

  const photo =
    data.proTradeRequirement.pro.identityVerification?.returnedPhoto ||
    data.proTradeRequirement.pro.identityVerification?.photo ||
    fallback;

  return (
    <>
      <BackButton />
      <Island>
        <div className='mb-4'>
          <h2 className='text-primary-500 text-xl font-bold m-0'>
            External Assessment View{" "}
            <span className='text-primary-300'>
              ({data?.proTradeRequirement.id.substring(0, 8)})
            </span>
          </h2>
          <p className='m-0 text-primary-300'>View pro certification details</p>
        </div>
        <div className='w-full flex justify-end mt-2'>
          <Button
            disabled={!!data.proTradeRequirement.processedAt}
            variant='success'
            onClick={markUploaded}
            loading={markUploadedLoading}
          >
            <Icon name='check circle' className='p-0 !ml-0 mr-1' />{" "}
            {!!data.proTradeRequirement.processedAt
              ? "Uploaded"
              : "Mark Upload"}
          </Button>
        </div>
        <div className='my-8'>
          <div className='flex items-center gap-4'>
            <Image
              src={photo}
              as='a'
              size='small'
              href={photo}
              target='_blank'
            />
            <div className='flex flex-col gap-2'>
              <h3 className='text-primary-500 text-lg font-bold m-0'>
                {data?.proTradeRequirement.pro.firstName}{" "}
                {data?.proTradeRequirement.pro.lastName}
              </h3>

              <div className='flex gap-2'>
                <Pill>{data.proTradeRequirement.requirement.name}</Pill>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full mb-8'>
          <Tab
            menu={{ secondary: true, pointing: true }}
            onTabChange={(e, data) => {}}
            panes={panes.map(({ render, title, id }) => ({
              render,
              menuItem: <Menu.Item key={id}>{title}</Menu.Item>,
            }))}
          />
        </div>
      </Island>
    </>
  );
};
