import React, { FC, useState } from "react";
import CustomInput from "@laborhack/input";
import Button from "@laborhack/custom-button";
import { Form, Icon, Modal } from "semantic-ui-react";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import Joi from "@hapi/joi";

import Island from "../../../_components/Island";

import { FieldValue } from "../../../types";
import RequestFailed from "../../../_components/RequestFailed";
import Loading from "../../../_components/Loading";

import {
  addPhoneNumberPrefix,
  businessEmailDoesNotExist,
  nonEmptyString,
  phoneNumberCheck,
  phoneNumberDoesNotExist,
} from "../../../helpers/helpers";
import { useHistory } from "react-router-dom";
import { CreateBusinessClientPayload } from "../types";
import { CreateBusinessClientCard } from "./CreateBusinessClientCard";
import { CreateBusinessClient } from "./CreateBusinessClient";
import BackButton from "../../../_components/BackButton";

export const REGISTER_BUSINESS = gql`
  mutation REGISTER_BUSINESS($input: RegisterBusinessInput!) {
    registerBusinessAsAdmin(input: $input) {
      id
      name
    }
  }
`;

export interface CreateBusinessProps {}

export const CreateBusiness: FC<CreateBusinessProps> = ({}) => {
  const client = useApolloClient();
  const history = useHistory();

  const defaultField = {
    value: "",
    isValid: false,
  };

  const [
    createBusinessClientModalOpen,
    setCreateBusinessClientModalOpen,
  ] = useState(false);

  const handleCreateBusinessClientModalOpen = () => {
    setCreateBusinessClientModalOpen(true);
  };

  const handleCreateBusinessClientModalClose = () => {
    setCreateBusinessClientModalOpen(false);
  };

  /**
   * Business Details
   */
  const [businessName, setBusinessName] = useState<FieldValue<string>>(
    defaultField
  );
  const [businessEmail, setBusinessEmail] = useState<FieldValue<string>>(
    defaultField
  );
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState<
    FieldValue<string>
  >(defaultField);
  const [businessStreet, setBusinessStreet] = useState<FieldValue<string>>(
    defaultField
  );
  const [businessCity, setBusinessCity] = useState<FieldValue<string>>(
    defaultField
  );
  const [businessState, setBusinessState] = useState<FieldValue<string>>(
    defaultField
  );

  const [businessClients, setBusinessClients] = useState<
    CreateBusinessClientPayload[]
  >([]);

  const handleBusinessClientAdd = (client: CreateBusinessClientPayload) => {
    setBusinessClients((prev) => [...prev, client]);
    handleCreateBusinessClientModalClose();
  };

  const handleBusinessClientRemoval = (id: string) => {
    setBusinessClients((prev) => prev.filter((client) => client.id !== id));
  };

  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const [registerBusiness] = useMutation(REGISTER_BUSINESS, {
    onCompleted: () => {
      setLoading(false);
      history.push("/businesses");
    },
    onError: (error) => {
      setLoading(false);
      setSubmissionError(true);
    },
  });

  const createAccountEnabled = businessName.isValid && businessEmail.isValid;

  const createAccount = async () => {
    try {
      setLoading(true);

      /**
       * Send to server
       */
      registerBusiness({
        variables: {
          input: {
            name: businessName.value,
            email: businessEmail.value,
            ...(!!businessPhoneNumber.value && {
              phoneNumber: addPhoneNumberPrefix(businessPhoneNumber.value),
            }),
            ...(!!businessStreet.value && {
              address: businessStreet.value,
              city: businessCity.value,
              state: businessState.value,
            }),
            ...(!!businessClients.length && {
              clients: businessClients.map(({ id, ...rest }) => rest),
            }),
          },
        },
      });
    } catch (error) {
      setSubmissionError(true);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (submissionError) {
    return (
      <RequestFailed
        onRetry={() => {
          setSubmissionError(false);
        }}
      />
    );
  }

  return (
    <>
      <Modal
        open={createBusinessClientModalOpen}
        size='tiny'
        closeIcon
        onClose={handleCreateBusinessClientModalClose}
        closeOnDimmerClick={false}
      >
        <CreateBusinessClient onSave={handleBusinessClientAdd} />
      </Modal>
      <BackButton />
      <Island>
        <div className='mb-4'>
          <h2 className='text-primary-500 text-xl font-bold m-0'>
            Create Business
          </h2>
          <p className='m-0 text-primary-300'>Create a new business.</p>
        </div>
        <div className='w-96'>
          <Form>
            <div className='mt-12'>
              <h3 className='text-primary-500 font-bold'>Business Details</h3>
              <CustomInput
                autoComplete='name'
                required
                textLabel='Business Name'
                value={businessName.value}
                onChange={(value: string, isValid: boolean) => {
                  setBusinessName({ value, isValid });
                }}
                validate={{
                  check: nonEmptyString,
                  message: "Invalid Business Name",
                }}
                placeholder='Business Name'
              />
              <CustomInput
                autoComplete='email'
                type='email'
                required
                textLabel='Business Email Address'
                value={businessEmail.value}
                onChange={(value: string, isValid: boolean) => {
                  setBusinessEmail({ value, isValid });
                }}
                validate={{
                  check: (value: string) => {
                    return !Joi.string()
                      .email({
                        minDomainSegments: 2,
                        tlds: { allow: false },
                      })
                      .validate(value).error;
                  },
                  message: "Invalid Email",
                }}
                asyncValidate={{
                  check: (value: string) => {
                    try {
                      return businessEmailDoesNotExist(client, value);
                    } catch (error) {
                      setSubmissionError(true);
                    }

                    return new Promise((resolve) => resolve(false));
                  },
                  message: "This email already exists",
                }}
                placeholder='Enter Email Address'
              />
              <CustomInput
                autoComplete='tel'
                textLabel='Business Phone Number'
                label='+234'
                type='tel'
                value={businessPhoneNumber.value}
                onChange={(value: string, isValid: boolean) => {
                  setBusinessPhoneNumber({ value, isValid });
                }}
                validate={{
                  check: phoneNumberCheck,
                  message: "Invalid Business Phone Number",
                }}
                asyncValidate={{
                  check: (value: string) =>
                    phoneNumberDoesNotExist(client, value),
                  message: "This phone number already exists",
                }}
                placeholder='8000000000'
              />
              <CustomInput
                autoComplete='street-address'
                textLabel='Business Address'
                value={businessStreet.value}
                onChange={(value: string, isValid: boolean) => {
                  setBusinessStreet({ value, isValid });
                }}
                placeholder='Address'
              />

              <CustomInput
                autoComplete='city'
                textLabel='Business City'
                value={businessCity.value}
                onChange={(value: string, isValid: boolean) => {
                  setBusinessCity({ value, isValid });
                }}
                placeholder='City'
              />
              <CustomInput
                autoComplete='state'
                textLabel='Business State'
                value={businessState.value}
                onChange={(value: string, isValid: boolean) => {
                  setBusinessState({ value, isValid });
                }}
                placeholder='State'
              />
            </div>
            <div className='mt-12'>
              <h3 className='text-primary-500 font-bold'>Contacts</h3>
              {!!businessClients.length && (
                <div className='my-4'>
                  {businessClients.map((client) => {
                    return (
                      <CreateBusinessClientCard
                        {...client}
                        onRemove={() => handleBusinessClientRemoval(client.id)}
                      />
                    );
                  })}
                </div>
              )}

              <div className='flex justify-end pb-8 px-4'>
                <Button
                  type='link'
                  onClick={handleCreateBusinessClientModalOpen}
                >
                  <Icon name='add circle' />
                  add contact
                </Button>
              </div>
            </div>
            <Button
              loading={loading}
              disabled={!createAccountEnabled}
              onClick={createAccount}
              fullWidth
            >
              Create Business
            </Button>
          </Form>
        </div>
      </Island>
    </>
  );
};
