import { IdentityVerificationStatus, MeansOfIdentification } from "../../types";

export const idStatusToPillClassName: {
  [Property in IdentityVerificationStatus]: string;
} = {
  PENDING: "!bg-yellow-100 !text-yellow-800",
  VERIFIED: "!bg-green-100 !text-green-800",
  DECLINED: "!bg-red-100 !text-red-800",
};

export const IDENTIFICATION_OPTIONS = [
  {
    label: "National ID Number (NIN)",
    value: MeansOfIdentification.NIN,
    regex: /^[0-9]{11}$/,
  },
  {
    label: "Driver's License",
    value: MeansOfIdentification.DRIVERS_LICENSE,
    regex: /^[a-zA-Z]{3}([ -]{1})?[A-Z0-9]{6,12}$/i,
  },
  {
    label: "Bank Verification Number (BVN)",
    value: MeansOfIdentification.BVN,
    regex: /^[0-9]{11}$/,
  },
];



