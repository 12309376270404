import { useMutation } from '@apollo/client';
import { Alert } from 'antd';
import React, { FC, useState } from 'react';
import { Dropdown, Modal } from 'semantic-ui-react';
import Button from '@laborhack/custom-button';
import { EDIT_CUSTOM_TASK } from '../../_graphql/mutation';

import styles from './styles.module.scss';
import { CatServiceSelectors } from '../../../../../redux/category-service.slice';
import { useSelector } from 'react-redux';

const { selectCategories } = CatServiceSelectors;

export interface CategoryEditProps {
  jobId: string;
  categoryId: string;
  open: boolean;
  handleClose: () => void;
}

export const CategoryEdit: FC<CategoryEditProps> = ({
  jobId,
  categoryId: existingCategory,
  open,
  handleClose,
}) => {
  const categories = useSelector(selectCategories);
  const [selectedCategory, setSelectedCategory] = useState(existingCategory);

  const [editTask, { loading, data, error }] = useMutation(EDIT_CUSTOM_TASK, {
    onCompleted: () => {
      handleClose();
    },
    onError: () => {
      handleClose();
    },
  });

  const handleSubmit = () => {
    editTask({
      variables: {
        input: {
          jobId,
          categoryId: selectedCategory,
        },
      },
    });
  };

  return (
    <>
      {error && (
        <Alert message={error.message} closable banner type="error"></Alert>
      )}
      {data && (
        <Alert
          message={'Category Updated'}
          closable
          banner
          type="success"
        ></Alert>
      )}
      <Modal size="tiny" open={open} closeOnDimmerClick onClose={handleClose}>
        <Modal.Content>
          <h4 className={styles.heading}>Edit Category</h4>
          <div className={styles.modalContainer}>
            <Dropdown
              fluid
              selection
              value={selectedCategory}
              onChange={(e, { value }) => {
                if (typeof value === 'string') {
                  setSelectedCategory(value);
                }
              }}
              options={Object.entries(categories).map(
                ([id, { name }]: any) => ({
                  text: name,
                  value: id,
                  key: id,
                })
              )}
            ></Dropdown>
            <Button
              loading={loading}
              className={styles.saveButton}
              variant="basic"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
