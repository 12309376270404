import React, { FC } from "react";
import { Icon } from "semantic-ui-react";

export interface SectionLoadingProps {}

export const SectionLoading: FC<SectionLoadingProps> = ({}) => {
  return (
    <div className='w-full p-8 flex justify-center'>
      <Icon size='huge' name='spinner' loading />
    </div>
  );
};
