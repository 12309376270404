import {
  Badge,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaEllipsisH, FaExternalLinkAlt, FaSyncAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { TransferStatus } from "../../types";
import { getTransferBadgeColor } from "./constants";
import { ContractPayout } from "./types";

export interface ContractPayoutTableProps {
  data?: ContractPayout[];
  isConfirmations?: boolean;
  isLoading?: boolean;
}

export const ContractPayoutTable = ({
  data,
  isConfirmations,
  isLoading,
}: ContractPayoutTableProps) => {
  const history = useHistory();

  return (
    <TableContainer>
      <Table className='border'>
        <Thead>
          <Tr>
            <Th className='border'>Date Created</Th>
            <Th className='border'>Pro</Th>
            <Th className='border'>Status</Th>
            <Th className='border'>Recruitment</Th>
            <Th className='border'>Period</Th>
            <Th className='border'>Worksheet</Th>
            <Th className='border'></Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && (
            <Tr>
              <Td colSpan={6} className='border'>
                <div className='w-full flex justify-center items-center min-h-[80px]'>
                  <p>
                    Loading <FaSyncAlt />
                  </p>
                </div>
              </Td>
            </Tr>
          )}
          {!data?.length && (
            <Tr>
              <Td colSpan={6} className='border'>
                <div className='w-full flex justify-center items-center min-h-[80px]'>
                  <p>No data available</p>
                </div>
              </Td>
            </Tr>
          )}
          {data?.map((payout) => (
            <Tr key={payout.id}>
              <Td className='border'>
                {new Date(payout.createdAt).toLocaleDateString()}
              </Td>
              <Td className='border'>
                {payout.worksheet.contract.pro.firstName}{" "}
                {payout.worksheet.contract.pro.lastName}
              </Td>
              <Td className='border'>
                <Badge
                  colorScheme={getTransferBadgeColor(payout.transfer.status)}
                >
                  {payout.transfer.status}
                </Badge>
              </Td>
              <Td className='border'>
                <Link
                  href={
                    window.location.origin +
                    `/recruitment/${payout.worksheet.contract.recruitment.id}`
                  }
                  isExternal
                >
                  {payout.worksheet.contract.recruitment.client.firstName}{" "}
                  {payout.worksheet.contract.recruitment.client.lastName} [
                  {payout.worksheet.contract.recruitment.client.business
                    ?.name || "N/A"}
                  ]
                  <FaExternalLinkAlt className='ml-1 inline-block' />
                </Link>
              </Td>
              <Td className='border'>
                {new Date(
                  payout.worksheet.payroll.period.start
                ).toLocaleDateString()}{" "}
                -{" "}
                {new Date(
                  payout.worksheet.payroll.period.end
                ).toLocaleDateString()}
              </Td>
              <Td className='border'>
                {(
                  (payout.worksheet.unitAmount /
                    payout.worksheet.expectedUnitAmount) *
                  100
                ).toFixed(0)}
                %{" "}
                <span className='text-gray-500'>
                  ({payout.worksheet.unitAmount}/
                  {payout.worksheet.expectedUnitAmount})
                </span>{" "}
              </Td>

              <Td className='border'>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<FaEllipsisH />}
                    variant='outline'
                  />
                  <MenuList>
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/contract-payouts/${
                            isConfirmations ? "confirmations" : "requests"
                          }/${payout.id}`
                        )
                      }
                    >
                      View Details
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
