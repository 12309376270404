import { CREATE_REFERRER } from "@/features/referrers/constants";
import Island from "@/_components/Island";
import RequestFailed from "@/_components/RequestFailed";
import { useMutation } from "@apollo/client";
import { Button } from "@chakra-ui/react";
import { useState } from "react";

export default function CreateReferrer() {
	const [name, setName] = useState<string>();

	const [businessName, setBusinessName] = useState<string>("");

	const [email, setEmail] = useState<string>("");

	const [referralCode, setReferralCode] = useState<string>("");

	const [linkedDiscountCode, setLinkedDiscountCode] = useState<string>("");

	const [commissionPercentage, setCommissionPercentage] = useState<number>(0.1);

	const [showError, setShowError] = useState<boolean>(false);

	const emailIsValid =
		!!email.length && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

	const [createReferrer, createReferrerCall] = useMutation(CREATE_REFERRER, {
		onCompleted: () => {
			window.location.href = "/referrers";
		},
	});

	const handleReset = () => {
		createReferrerCall.reset();
	};

	const handleSubmit = () => {
		const canCreateReferrer =
			name &&
			businessName &&
			emailIsValid &&
			referralCode &&
			linkedDiscountCode &&
			commissionPercentage;

		if (!canCreateReferrer) {
			setShowError(true);
			return;
		} else {
			setShowError(false);
		}

		createReferrer({
			variables: {
				referrerInput: {
					name,
					businessName,
					email,
					referralCode,
					linkedDiscountCode,
					commissionPercentage,
				},
			},
		});
	};

	if (createReferrerCall.error) {
		return (
			<RequestFailed
				errorMessage={createReferrerCall.error?.message}
				onRetry={handleReset}
			/>
		);
	}

	return (
		<Island>
			<form>
				<div className="space-y-12">
					<div className="grid grid-cols-2 gap-x-12 gap-y-10 border-b border-gray-900/10 pb-12 ">
						<div className="w-[80%]">
							<h2 className="text-base font-semibold leading-7 text-[#003952]">
								Referrer Information
							</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								The information provided will be used for payouts and
								communication so please be careful with the details you provide.
							</p>
						</div>

						<div className="grid max-w-4xl space-y-4">
							<div className="sm:col-span-4">
								<label
									htmlFor="name"
									className="block text-sm font-medium leading-6 text-[#003952]"
								>
									Referrer's Name
								</label>
								<div className="mt-2">
									<div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#003952] sm:max-w-lg">
										<input
											id="name"
											name="name"
											value={name}
											type="text"
											placeholder="Personal name (or business name if personal name is not available)"
											onChange={(e) => setName(e.target.value)}
											className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-[#003952] placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
										/>
									</div>
								</div>
								{showError && !name && (
									<p className="text-red-500 text-sm">Please enter a name</p>
								)}
							</div>

							<div className="sm:col-span-4">
								<label
									htmlFor="businessName"
									className="block text-sm font-medium leading-6 text-[#003952]"
								>
									Business Name
								</label>
								<div className="mt-2">
									<div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#003952] sm:max-w-lg">
										<input
											id="businessName"
											name="businessName"
											value={businessName}
											type="text"
											placeholder="Everything Mart"
											onChange={(e) => setBusinessName(e.target.value)}
											className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-[#003952] placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
										/>
									</div>
								</div>
								{showError && !businessName && (
									<p className="text-red-500 text-sm">
										Please enter a business name
									</p>
								)}
							</div>

							<div className="sm:col-span-4">
								<label
									htmlFor="email"
									className="block text-sm font-medium leading-6 text-[#003952]"
								>
									Email
								</label>
								<div className="mt-2">
									<div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#003952] sm:max-w-lg">
										<input
											id="email"
											name="email"
											value={email}
											type="email"
											placeholder="everything@everythingmart.ng"
											onChange={(e) => setEmail(e.target.value)}
											className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-[#003952] placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
										/>
									</div>
								</div>
								{showError && !emailIsValid && (
									<p className="text-red-500 text-sm">
										Please enter a valid email
									</p>
								)}
							</div>

							<div className="sm:col-span-4">
								<label
									htmlFor="referralCode"
									className="block text-sm font-medium leading-6 text-[#003952]"
								>
									Referral Code
								</label>
								<div className="mt-2">
									<div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#003952] sm:max-w-lg">
										<input
											id="referralCode"
											name="referralCode"
											value={referralCode}
											type="text"
											placeholder="EVERYTHINGMART"
											onChange={(e) => setReferralCode(e.target.value)}
											className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-[#003952] placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
										/>
									</div>
								</div>
								{showError && !referralCode && (
									<p className="text-red-500 text-sm">
										Please enter a referral code
									</p>
								)}
							</div>

							<div className="sm:col-span-4">
								<label
									htmlFor="linkedDiscountCode"
									className="block text-sm font-medium leading-6 text-[#003952]"
								>
									Linked Discount
								</label>
								<div className="mt-2">
									<div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#003952] sm:max-w-lg">
										<input
											id="linkedDiscountCode"
											name="linkedDiscountCode"
											value={linkedDiscountCode}
											type="text"
											placeholder="DISCOUNTCODE"
											onChange={(e) => setLinkedDiscountCode(e.target.value)}
											className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-[#003952] placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
										/>
									</div>
								</div>
								{showError && !linkedDiscountCode && (
									<p className="text-red-500 text-sm">
										Please enter a discount code
									</p>
								)}
							</div>

							<div className="sm:col-span-4">
								<label
									htmlFor="commissionPercentage"
									className="block text-sm font-medium leading-6 text-[#003952]"
								>
									Commission Percentage
								</label>
								<div className="mt-2">
									<div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#003952] sm:max-w-lg">
										<select
											id="commissionPercentage"
											name="commissionPercentage"
											value={commissionPercentage}
											onChange={(e) =>
												setCommissionPercentage(Number(e.target.value))
											}
											placeholder="Select"
											className="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-2 text-[#003952] placeholder:text-gray-400 font-semibold shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#003952] sm:text-sm sm:leading-6 [&_*]:text-black"
										>
											<option value={0.1}>10%</option>
										</select>
									</div>
								</div>
								{showError && !commissionPercentage && (
									<p className="text-red-500 text-sm">
										Please select a commission percentage
									</p>
								)}
							</div>
							<Button
								colorScheme={"primary"}
								onClick={handleSubmit}
								isLoading={createReferrerCall.loading}
								className="sm:col-span-4 sm:max-w-lg"
							>
								Create Referrer
							</Button>
						</div>
					</div>
				</div>
			</form>
		</Island>
	);
}
