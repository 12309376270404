import { gql, useQuery } from "@apollo/client";
import { PaymentProviderData } from "../types";

const GET_PRO_BANK_DETAILS = gql`
  query GetDefaultTransferRecipientsForPro($proId: String!) {
    defaultTransferRecipientsForPro(proId: $proId) {
      providerId
      recipientCode
      bankInformation {
        accountName
        accountNumber
        bankName
      }
    }
  }
`;

export const useProBankAccountInfo = (proId: string) => {
  const { loading, error, data, refetch } = useQuery<{
    defaultTransferRecipientsForPro: {
      providerId: string;
      bankInformation: {
        accountName: string;
        accountNumber: string;
        bankName: string;
      };
    }[] | null;
  }>(GET_PRO_BANK_DETAILS, {
    variables: {
      proId,
    },
  });

  const maskAccountNumber = (accountNumber: string) => {
    return `****${accountNumber.slice(-4)}`;
  }

  const bankDetails = data?.defaultTransferRecipientsForPro?.[0]?.bankInformation;

  return {
    loading,
    hasProvidedBankAccountInfo: !!bankDetails,
    maskedAccountNumber: bankDetails && (maskAccountNumber(bankDetails?.accountNumber)),
    accountName: bankDetails?.accountName,
    bankName: bankDetails?.bankName,
    refetchBankAccountInfo: refetch,
    error
  };
};


