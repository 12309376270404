import { getTransferBadgeColor } from "@/constants";
import { formatDate } from "@/features/referrers/helpers";
import { GET_SUBSCRIPTION_REQUEST_PAYOUT } from "@/features/subscription-request-payouts/constants";
import { SubscriptionRequestCallPayout } from "@/features/subscription-request-payouts/types";
import { formatAsNaira } from "@/helpers/helpers";
import { TransferStatus } from "@/types";
import Loading from "@/_components/Loading";
import RequestFailed from "@/_components/RequestFailed";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Badge,
	Button,
	Divider,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	PinInput,
	PinInputField,
	Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import {
	FaArrowRight,
	FaCheckCircle,
	FaStopCircle,
	FaSync,
} from "react-icons/fa";
import { useParams } from "react-router-dom";

export const APPROVE_SUBSCRIPTION_CALL_PAYOUT = gql`
	mutation ApproveSubscriptionRequestCallPayout(
		$payoutId: String!
		$providerId: String!
	) {
		approveSubscriptionRequestCallPayout(
			payoutId: $payoutId
			providerId: $providerId
		) {
			id
			transfer {
				id
				transferCode
				status
			}
		}
	}
`;

export const FINALIZE_SUBSCRIPTION_CALL_PAYOUT = gql`
	mutation FinalizeSubscriptionRequestCallPayout(
		$payoutId: String!
		$otp: String!
	) {
		finalizeSubscriptionRequestCallPayout(payoutId: $payoutId, otp: $otp) {
			id
			transfer {
				id
				status
			}
		}
	}
`;

const GET_DEFAULT_TRANSFER_RECIPIENT = gql`
	query GetDefaultTransferRecipient($userId: String!) {
		defaultTransferRecipients(userId: $userId) {
			providerId
			bankInformation {
				accountName
				accountNumber
				bankName
			}
		}
	}
`;

export default function RequestView() {
	const { id } = useParams<{
		id: string;
	}>();

	const [otp, setOtp] = useState<string>();

	const {
		data: subscriptionRequestCallPayoutData,
		loading: subscriptionRequestCallPayoutLoading,
		error: subscriptionRequestCallPayoutError,
		refetch,
	} = useQuery<{
		subscriptionRequestCallPayoutDetails: SubscriptionRequestCallPayout;
	}>(GET_SUBSCRIPTION_REQUEST_PAYOUT, {
		variables: {
			subscriptionRequestCallPayoutId: id,
		},
		onCompleted: (data) => {
			getDefaultTransferRecipient({
				variables: {
					userId: data.subscriptionRequestCallPayoutDetails.pro.userId,
				},
			});
		},
	});

	const [
		getDefaultTransferRecipient,
		getDefaultTransferRecipientResponse,
	] = useLazyQuery<{
		defaultTransferRecipients: {
			providerId: string;
			bankInformation: {
				accountName: string;
				accountNumber: string;
				bankName: string;
			};
		}[];
	}>(GET_DEFAULT_TRANSFER_RECIPIENT);

	const [
		approveSubscriptionRequestCallPayout,
		approveSubscriptionRequestCallPayoutResponse,
	] = useMutation(APPROVE_SUBSCRIPTION_CALL_PAYOUT, {
		onCompleted: () => {
			refetch();
		},
	});

	const [
		finalizeSubscriptionRequestCallPayout,
		finalizeSubscriptionRequestCallPayoutResponse,
	] = useMutation(FINALIZE_SUBSCRIPTION_CALL_PAYOUT, {
		onError: () => {},
	});

	if (subscriptionRequestCallPayoutLoading) return <Loading />;

	if (subscriptionRequestCallPayoutError) {
		return <RequestFailed />;
	}

	if (approveSubscriptionRequestCallPayoutResponse.error) {
		return (
			<RequestFailed
				errorMessage={
					approveSubscriptionRequestCallPayoutResponse.error.message
				}
				onRetry={approveSubscriptionRequestCallPayoutResponse.reset}
			/>
		);
	}

	if (!subscriptionRequestCallPayoutData?.subscriptionRequestCallPayoutDetails)
		return <RequestFailed errorMessage="Not Found" />;

	const payout =
		subscriptionRequestCallPayoutData?.subscriptionRequestCallPayoutDetails;
	const transferStatus = payout?.transfer.status;

	const payoutTransfer = payout?.transfer;

	const payoutAmount = payout.amount;

	const payoutOverrideAmount = payout.override?.amount;

	const paymentInformation = getDefaultTransferRecipientResponse.data?.defaultTransferRecipients?.find(
		(recipient) => recipient.providerId === "paystack"
	);

	const isApprovable =
		payout?.transfer.status === TransferStatus.AWAITING_APPROVAL;

	return (
		<div className="grid grid-cols-3 gap-4">
			<div className="col-span-2">
				<div className="flex flex-col gap-8 p-6 bg-white border">
					<div className="mb-4">
						<h2 className="text-primary-500 text-lg font-medium m-0">
							Subscription Scheduled Request Payout #{id.slice(0, 8)}
						</h2>
						<p className="m-0 text-primary-300 text-sm">
							Shows a detailed view of a subscription scheduled request payout
						</p>
					</div>
					<h3 className="text-primary-500 text-md font-medium m-0">
						Pro Information
					</h3>
					<div className="grid grid-cols-[2fr,3fr] gap-2 max-w-lg mb-4">
						<p className="m-0 text-primary-300 text-sm">Pro</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.pro.firstName} {payout?.pro.lastName}
						</p>
						<p className="m-0 text-primary-300 text-sm">Email</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.pro.email}
						</p>
						<p className="m-0 text-primary-300 text-sm">Phone Number</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.pro.phoneNumber}
						</p>
					</div>
					<Divider />
					<h3 className="text-primary-500 text-md font-medium m-0">
						Scheduled Requests
					</h3>
					{payout?.subscriptionRequestCalls.map((call, index) => {
						return (
							<div className="grid grid-cols-[2fr,3fr] gap-2 max-w-lg mb-4">
								<p className="m-0 text-primary-300 text-sm">
									Subscription Request
								</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{call.subscriptionRequest.id}
								</p>
								<p className="m-0 text-primary-300 text-sm">Service</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{call.subscriptionRequest.plan.package.name}
								</p>
								<p className="m-0 text-primary-300 text-sm">
									Date of Assignment
								</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{formatDate(new Date(call.dateOfAssignment)).split(",")[0]}
								</p>
								<p className="m-0 text-primary-300 text-sm">Set Pay</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{call.proPaymentAmount
										? formatAsNaira(call.proPaymentAmount)
										: "Not Set"}
								</p>
								<p className="m-0 text-primary-300 text-sm">Expected Pay</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{formatAsNaira(call.expectedProPaymentAmount)}
								</p>
							</div>
						);
					})}
				</div>
			</div>
			<div>
				<div className="flex flex-col gap-6 p-6 bg-white border">
					<div>
						<h2 className="text-primary-500 text-lg font-medium m-0">
							Breakdown
						</h2>
					</div>
					<Divider />
					<div className="flex flex-col gap-4">
						<h3 className="font-medium text-primary-500 text-sm">
							Payment Information
						</h3>
						{!paymentInformation &&
							!getDefaultTransferRecipientResponse.loading && (
								<Alert status="error">
									<AlertIcon />
									<AlertTitle className="text-sm !font-medium">
										No payment information found
									</AlertTitle>
									<AlertDescription></AlertDescription>
								</Alert>
							)}
						<div className="flex flex-col gap-2">
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Name</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{paymentInformation?.bankInformation.accountName || "N/A"}
								</p>
							</div>
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">AC Number</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{paymentInformation?.bankInformation.accountNumber || "N/A"}
								</p>
							</div>
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Bank Name</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{paymentInformation?.bankInformation.bankName || "N/A"}
								</p>
							</div>
						</div>
					</div>

					<Divider />

					<div className="flex flex-col gap-4">
						<div className="flex flex-col gap-2">
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Status</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									<Badge colorScheme={getTransferBadgeColor(transferStatus)}>
										{transferStatus.replace("_", " ")}
									</Badge>
								</p>
							</div>
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Amount (NGN)</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{formatAsNaira(payoutAmount)}
								</p>
							</div>

							{/* <div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Overridden</p>
								{payout.override ? (
									<FaCheckCircle className="text-green-500" />
								) : (
									<FaStopCircle className="text-red-500" />
								)}
							</div>

							{payoutOverrideAmount && (
								<div className="flex justify-between">
									<p className="m-0 text-primary-300 text-sm">
										Override Amount
									</p>
									<p className="m-0 text-primary-500 text-sm font-medium">
										{formatAsNaira(payoutOverrideAmount)}
									</p>
								</div>
							)} */}
						</div>
					</div>
					<Divider />
					{isApprovable && (
						<div className="flex flex-col gap-2">
							{!payoutTransfer.transferCode && (
								<Button
									colorScheme="green"
									rightIcon={<FaArrowRight className="inline-block" />}
									isLoading={
										approveSubscriptionRequestCallPayoutResponse.loading
									}
									isDisabled={!paymentInformation}
									onClick={() => {
										approveSubscriptionRequestCallPayout({
											variables: {
												payoutId: id,
												providerId: "paystack",
											},
										});
									}}
								>
									Approve Payout
								</Button>
							)}
							{payoutTransfer.transferCode && (
								<>
									<FormControl
										isInvalid={
											!!finalizeSubscriptionRequestCallPayoutResponse.error
										}
									>
										<FormLabel>Enter OTP</FormLabel>
										<Stack>
											<HStack w={"100%"}>
												<PinInput
													size={"lg"}
													otp
													onComplete={(otp) => {
														setOtp(otp);
													}}
													onChange={() => {
														finalizeSubscriptionRequestCallPayoutResponse.reset();
													}}
												>
													<PinInputField />
													<PinInputField />
													<PinInputField />
													<PinInputField />
													<PinInputField />
													<PinInputField />
												</PinInput>
											</HStack>

											<HStack justifyContent={"space-between"}>
												<div className="flex justify-start">
													<Button
														variant="link"
														leftIcon={<FaSync className="inline-block" />}
														isLoading={
															approveSubscriptionRequestCallPayoutResponse.loading
														}
														onClick={() => {
															approveSubscriptionRequestCallPayout({
																variables: {
																	payoutId: id,
																	providerId: "paystack",
																},
															});
														}}
													>
														Refresh OTP
													</Button>
												</div>
												<Button
													alignSelf={"f"}
													colorScheme="green"
													rightIcon={<FaCheckCircle className="inline-block" />}
													isLoading={
														finalizeSubscriptionRequestCallPayoutResponse.loading
													}
													isDisabled={!otp}
													onClick={() => {
														finalizeSubscriptionRequestCallPayout({
															variables: {
																payoutId: id,
																otp,
															},
														});
													}}
												>
													Finalize Payout
												</Button>
											</HStack>
										</Stack>
										<FormErrorMessage>
											{
												finalizeSubscriptionRequestCallPayoutResponse.error
													?.message
											}
										</FormErrorMessage>
									</FormControl>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
