import React, { FC } from "react";

const StatisticCard: FC<{
  backgroundColor: string;
  title: string;
  count: number;
  icon: any;
}> = ({ title, icon, count, backgroundColor }) => {
  return (
    <div
      className="o-statisticCard__wrapper"
      style={{
        backgroundColor,
      }}
    >
      <div className="o-statisticCard__container">
        <div className="m-statisticCard__data">
          {icon}
          <h4>{count}</h4>
        </div>
        <h3>{title}</h3>
      </div>
    </div>
  );
};

export default StatisticCard;
