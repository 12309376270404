import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Table as AntTable } from "antd";
import { gql } from "@apollo/client";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
  Badge,
  Icon,
} from "@chakra-ui/react";
import {
  ProRecruitmentContract,
  ContractWorksheet,
  RecruitmentPayroll,
  ContractPayoutReimbursement,
} from "../../types";
import RequestFailed from "@/_components/RequestFailed";
import BackButton from "@/_components/BackButton";
import { WorksheetEntryModal } from "../components/ProRecruitmentContractWorksheetEntry/ProRecruitmentContractWorksheetEntry";
import Loading from "@/_components/Loading";
import {
  FaArrowRight,
  FaCheckCircle,
  FaExternalLinkAlt,
  FaMinus,
  FaTimesCircle,
} from "react-icons/fa";
import { getTransferBadgeColor } from "../../../../features/contract-payouts/constants";
import { ContractPayout } from "../../../../features/contract-payouts/types";
import { IInvoice, TransferStatus } from "../../../../types";
import { isAfter } from "date-fns";
import { Link as ReactRouterLink } from "react-router-dom";

const GET_RECRUITMENT_PAYROLL = gql`
  query GetRecruitmentPayroll($id: String!) {
    recruitmentPayroll(id: $id) {
      id
      period {
        start
        end
      }
    }
  }
`;

const GET_OVERLAPPING_CONTRACTS = gql`
  query GetOverlappingContracts($id: String!) {
    recruitmentPayrollOverlappingContracts(id: $id) {
      id
      pro {
        firstName
        lastName
      }
      unitMeasured
    }
  }
`;

const GET_CONTRACT_WORKSHEETS_FOR_PAYROLL = gql`
  query GetContractWorksheetsForPayroll($payrollId: String!) {
    getContractWorksheetsForPayroll(payrollId: $payrollId) {
      id
      contract {
        id
        pro {
          id
          firstName
          lastName
        }
      }
      payout {
        id
        breakdown {
          net
          gross
        }
        transfer {
          id
          status
        }
        reimbursements {
          id
          invoice {
            id
            canceled
            sent
            invoiceNumber
            invoiceDate
            isFullyPaid
          }
          createdAt
        }
      }
    }
  }
`;

const START_CONTRACT_PAYOUT = gql`
  mutation StartContractPayout($worksheetId: String!) {
    startContractPayout(worksheetId: $worksheetId) {
      id
      worksheet {
        id
      }
    }
  }
`;

const CREATE_REIMBURSEMENT_INVOICE = gql`
  mutation CreateContractPayoutReimbursementInvoice($payoutIds: [String!]!) {
    createContractPayoutReimbursementInvoice(ids: $payoutIds) {
      id
      invoice {
        id
      }
    }
  }
`;

const ViewPayroll = () => {
  const history = useHistory();
  const location = useLocation();

  const { recruitmentId, payrollId } = useParams<{
    recruitmentId: string;
    payrollId: string;
  }>();

  const [selectedPayouts, setSelectedPayouts] = useState<string[]>([]);

  const [selectedContractId, setSelectedContractId] = useState<string>();
  const [
    selectedContractUnitMeasured,
    setSelectedContractUnitMeasured,
  ] = useState<string>();
  const [createWorksheetEntryIsOpen, setCreateWorksheetEntryIsOpen] = useState(
    false
  );

  const {
    loading: payrollLoading,
    error: payrollError,
    data: payrollData,
  } = useQuery<{
    recruitmentPayroll: RecruitmentPayroll;
  }>(GET_RECRUITMENT_PAYROLL, {
    variables: {
      id: payrollId,
    },
  });

  const {
    loading: contractsLoading,
    error: contractsError,
    data: contractsData,
  } = useQuery<{
    recruitmentPayrollOverlappingContracts: ProRecruitmentContract[];
  }>(GET_OVERLAPPING_CONTRACTS, {
    variables: {
      id: payrollId,
    },
  });

  const {
    data: contractWorksheetsData,
    error: contractWorksheetsError,
    loading: contractWorksheetsLoading,
    refetch: refetchContractWorksheetsData,
  } = useQuery<{
    getContractWorksheetsForPayroll: ContractWorksheet[];
  }>(GET_CONTRACT_WORKSHEETS_FOR_PAYROLL, {
    variables: {
      payrollId,
    },
  });

  const invoices = contractWorksheetsData?.getContractWorksheetsForPayroll.flatMap(
    (worksheet) => worksheet.payout?.reimbursements.map((r) => r.invoice) || []
  );

  const invoiceMap =
    invoices?.reduce((acc, invoice) => {
      if (!acc[invoice.id]) {
        acc[invoice.id] = invoice;
      }

      return acc;
    }, {} as Record<string, IInvoice>) || {};

  const uniqueInvoices = Object.values(invoiceMap).sort((a, b) => {
    return isAfter(new Date(a.invoiceDate), new Date(b.invoiceDate)) ? -1 : 1;
  });

  const [startContractPayout, startContractPayoutResponse] = useMutation(
    START_CONTRACT_PAYOUT,
    {
      onCompleted: () => {
        refetchContractWorksheetsData();
      },
    }
  );

  const [
    createReimbursementInvoice,
    createReimbursementInvoiceResponse,
  ] = useMutation<{
    createContractPayoutReimbursementInvoice: ContractPayoutReimbursement[];
  }>(CREATE_REIMBURSEMENT_INVOICE, {
    onCompleted: (data) => {
      history.push(
        `${location.pathname}/invoices/${data.createContractPayoutReimbursementInvoice[0].invoice.id}`
      );
    },
  });

  const worksheetsWithPayout = contractWorksheetsData?.getContractWorksheetsForPayroll.filter(
    (
      worksheet
    ): worksheet is ContractWorksheet & {
      payout: ContractPayout;
    } => !!worksheet.payout
  );

  if (payrollLoading || contractsLoading || contractWorksheetsLoading)
    return <Loading />;
  if (payrollError)
    return <RequestFailed errorMessage={payrollError.message} />;
  if (contractsError)
    return <RequestFailed errorMessage={contractsError.message} />;
  if (contractWorksheetsError)
    return <RequestFailed errorMessage={contractWorksheetsError.message} />;

  if (startContractPayoutResponse.error) {
    return (
      <RequestFailed errorMessage={startContractPayoutResponse.error.message} />
    );
  }

  const payroll = payrollData?.recruitmentPayroll;
  const overlappingContracts =
    contractsData?.recruitmentPayrollOverlappingContracts;

  const columns = [
    {
      title: "Contract ID",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <p className='text-sm text-[#003952] font-semibold'>
          {id.substring(0, 8)}
        </p>
      ),
    },
    {
      title: "Pro",
      key: "pro",
      render: (record: ProRecruitmentContract) => (
        <p className='text-sm text-[#003952] font-semibold'>{`${record.pro.firstName} ${record.pro.lastName}`}</p>
      ),
    },
    {
      title: "Unit Measured",
      key: "unitMeasured",
      render: (record: ProRecruitmentContract) => (
        <p className='text-sm text-[#003952] font-semibold'>
          {record.unitMeasured}
        </p>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record: ProRecruitmentContract) => {
        const worksheet = contractWorksheetsData?.getContractWorksheetsForPayroll.find(
          (worksheet: any) => worksheet.contract.id === record.id
        );

        if (!worksheet) {
          return (
            <Button
              colorScheme='brand'
              fontSize='small'
              onClick={() => {
                setSelectedContractId(record.id);
                setSelectedContractUnitMeasured(record.unitMeasured);
                setCreateWorksheetEntryIsOpen(true);
              }}
            >
              Create Worksheet Entry
            </Button>
          );
        }

        if (!worksheet.payout) {
          return (
            <Button
              colorScheme='gray'
              fontSize='small'
              isLoading={startContractPayoutResponse.loading}
              onClick={() => {
                startContractPayout({
                  variables: {
                    worksheetId: worksheet.id,
                  },
                });
              }}
            >
              Start Payout
            </Button>
          );
        }

        return (
          <Button colorScheme='gray' fontSize='small' isDisabled={true}>
            Payout Started
          </Button>
        );
      },
    },
  ];

  const selectablePayouts = worksheetsWithPayout?.filter((worksheet) => {
    const sorted = worksheet.payout.reimbursements.slice().sort((a, b) => {
      return isAfter(new Date(a.createdAt), new Date(b.createdAt)) ? -1 : 1;
    });

    const currentReimbursement: ContractPayoutReimbursement | undefined =
      sorted[0];

    const isActiveReimbursement =
      currentReimbursement && !currentReimbursement.invoice.canceled;

    const isSelectable =
      worksheet.payout.transfer.status === TransferStatus.CONFIRMED &&
      !isActiveReimbursement;

    return isSelectable;
  });

  const allSelected =
    selectablePayouts?.length === selectedPayouts.length &&
    selectablePayouts?.length !== 0;

  const handleRowSelect = (key: string) => {
    setSelectedPayouts((prev) =>
      prev.includes(key) ? prev.filter((id) => id !== key) : [...prev, key]
    );
  };

  return (
    <>
      <BackButton />

      <div className='grid grid-cols-3 gap-4'>
        <div className='col-span-2 flex flex-col gap-8'>
          <div className='flex flex-col gap-8 p-6 bg-white border'>
            <div className=''>
              <h2 className='text-primary-500 text-lg font-medium m-0'>
                Worksheets
              </h2>
              <p className='m-0 text-primary-300 text-sm'>
                Shows a list of contracts that are eligible for payroll
              </p>
            </div>
            <WorksheetEntryModal
              contractId={selectedContractId!}
              payrollId={payrollId}
              unitMeasured={selectedContractUnitMeasured!}
              createWorksheetEntryIsOpen={createWorksheetEntryIsOpen}
              refetchContractWorksheetsData={refetchContractWorksheetsData}
              onClose={() => setCreateWorksheetEntryIsOpen(false)}
            />
            <AntTable
              dataSource={overlappingContracts}
              columns={columns}
              pagination={false}
            />
          </div>
          <div className='flex flex-col gap-8 p-6 bg-white border'>
            <div className=''>
              <h2 className='text-primary-500 text-lg font-medium m-0'>
                Payouts
              </h2>
              <p className='m-0 text-primary-300 text-sm'>
                Shows a list of contracts with payouts for this payroll
              </p>
            </div>

            <div className='flex flex-col gap-4'>
              <div className='flex justify-end items-center gap-2'>
                {!!selectedPayouts.length && (
                  <p>
                    {selectedPayouts.length} Payout
                    {selectedPayouts.length > 1 ? "s" : ""} selected
                  </p>
                )}
                <Button
                  rightIcon={<FaArrowRight />}
                  colorScheme='brand'
                  isDisabled={selectedPayouts.length === 0}
                  onClick={() => {
                    createReimbursementInvoice({
                      variables: {
                        payoutIds: selectedPayouts,
                      },
                    });
                  }}
                  isLoading={createReimbursementInvoiceResponse.loading}
                >
                  Generate Invoice
                </Button>
              </div>
              <TableContainer>
                <Table className='border'>
                  <Thead className=' bg-gray-50'>
                    <Tr>
                      <Th className='border max-w-[48px] bg-white'>
                        <Checkbox
                          isDisabled={selectablePayouts?.length === 0}
                          icon={!allSelected ? <FaMinus /> : undefined}
                          isChecked={selectedPayouts.length > 0}
                          onChange={() => {
                            if (allSelected) {
                              setSelectedPayouts([]);
                            } else {
                              setSelectedPayouts(
                                selectablePayouts?.map(
                                  (worksheet) => worksheet.payout.id
                                ) || []
                              );
                            }
                          }}
                        />
                      </Th>
                      <Th className='border'>Contract</Th>
                      <Th className='border'>Pro</Th>
                      <Th className='border'>Invoice</Th>
                      <Th className='border'>Status</Th>
                      <Th className='border'></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {worksheetsWithPayout?.map((worksheet) => {
                      const sorted = worksheet.payout.reimbursements
                        .slice()
                        .sort((a, b) => {
                          return isAfter(
                            new Date(a.createdAt),
                            new Date(b.createdAt)
                          )
                            ? -1
                            : 1;
                        });

                      const currentReimbursement:
                        | ContractPayoutReimbursement
                        | undefined = sorted[0];

                      const isActiveReimbursement =
                        currentReimbursement &&
                        !currentReimbursement.invoice.canceled;

                      const isSelectable =
                        worksheet.payout.transfer.status ===
                          TransferStatus.CONFIRMED && !isActiveReimbursement;

                      return (
                        <Tr key={worksheet.id}>
                          <Td className='border'>
                            <Checkbox
                              isChecked={
                                isSelectable
                                  ? selectedPayouts.includes(
                                      worksheet.payout.id
                                    )
                                  : false
                              }
                              isDisabled={!isSelectable}
                              onChange={() => {
                                handleRowSelect(worksheet.payout.id);
                              }}
                            />
                          </Td>
                          <Td className='border'>
                            {worksheet.contract.id.substring(0, 8)}
                          </Td>
                          <Td className='border'>
                            {worksheet.contract.pro.firstName}{" "}
                            {worksheet.contract.pro.lastName}
                          </Td>
                          <Td className='border'>
                            {currentReimbursement ? (
                              <Link
                                as={ReactRouterLink}
                                to={`${location.pathname}/invoices/${currentReimbursement.invoice.id}`}
                              >
                                #{currentReimbursement.invoice.invoiceNumber}
                              </Link>
                            ) : (
                              "N/A"
                            )}
                          </Td>

                          <Td className='border border-r-0'>
                            <Badge
                              colorScheme={
                                worksheet.payout
                                  ? getTransferBadgeColor(
                                      worksheet.payout.transfer.status
                                    )
                                  : "gray"
                              }
                            >
                              {worksheet.payout?.transfer.status || "Pending"}
                            </Badge>
                          </Td>
                          <Td className='border border-l-0'>
                            <Link
                              href={
                                window.location.origin +
                                `/contract-payouts/requests/${worksheet.payout.id}`
                              }
                              isExternal
                              className='!text-primary-500 text-sm font-medium'
                            >
                              View
                              <FaExternalLinkAlt className='ml-2 inline-block' />
                            </Link>
                          </Td>
                        </Tr>
                      );
                    })}
                    {!worksheetsWithPayout?.length && (
                      <Tr>
                        <Td colSpan={6} className='border'>
                          <div className='w-full flex justify-center items-center min-h-[80px]'>
                            <p>No data available</p>
                          </div>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className='flex flex-col gap-8 p-6 bg-white border'>
            <div className=''>
              <h2 className='text-primary-500 text-lg font-medium m-0'>
                Invoices
              </h2>
              <p className='m-0 text-primary-300 text-sm'>
                Shows a list of invoices generated for this payroll
              </p>
            </div>

            <div className='flex flex-col gap-4'>
              <TableContainer>
                <Table className='border'>
                  <Thead className=' bg-gray-50'>
                    <Tr>
                      <Th className='border'>Invoice Number</Th>
                      <Th className='border'>Invoice Date</Th>
                      <Th className='border'>Status</Th>

                      <Th className='border'></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {uniqueInvoices?.map((invoice) => {
                      return (
                        <Tr key={invoice.id}>
                          <Td className='border'>#{invoice.invoiceNumber}</Td>
                          <Td className='border'>
                            {new Date(invoice.invoiceDate).toDateString()}
                          </Td>
                          <Td className='border'>
                            {invoice.canceled ? (
                              <Badge colorScheme='red' variant='solid'>
                                Canceled
                              </Badge>
                            ) : invoice.isFullyPaid ? (
                              <Badge colorScheme='green' variant='solid'>
                                Paid
                              </Badge>
                            ) : (
                              <Badge colorScheme='yellow' variant='solid'>
                                Pending
                              </Badge>
                            )}
                          </Td>

                          <Td className='border border-l-0'>
                            <Link
                              as={ReactRouterLink}
                              to={`${location.pathname}/invoices/${invoice.id}`}
                            >
                              View
                            </Link>
                          </Td>
                        </Tr>
                      );
                    })}
                    {!worksheetsWithPayout?.length && (
                      <Tr>
                        <Td colSpan={6} className='border'>
                          <div className='w-full flex justify-center items-center min-h-[80px]'>
                            <p>No data available</p>
                          </div>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <div className='col-span-1'>
          <div className='flex flex-col gap-8 p-6 bg-white border'>
            <div className='mb-4'>
              <h2 className='text-primary-500 text-lg font-medium m-0'>
                Payroll Details
              </h2>
              <p className='m-0 text-primary-300 text-sm'>
                Shows a list of contracts that are eligible for payroll
              </p>
            </div>
            <div className='grid grid-cols-[1fr,2fr] gap-2 w-full'>
              <p className='m-0 text-primary-300 text-sm'>ID</p>
              <p className='m-0 text-primary-500 text-sm font-medium text-right'>
                {payrollId}
              </p>
              {!!payroll && (
                <>
                  <p className='m-0 text-primary-300 text-sm'>Period</p>
                  <p className='m-0 text-primary-500 text-sm font-medium text-right'>
                    {new Date(payroll.period.start).toDateString()} -{" "}
                    {new Date(payroll.period.end).toDateString()}
                  </p>
                </>
              )}

              <p className='m-0 text-primary-300 text-sm'>Recruitment</p>
              <Link
                href={window.location.origin + `/recruitment/${recruitmentId}`}
                isExternal
                className='!text-primary-500 text-sm font-medium text-right'
              >
                {recruitmentId.slice(0, 8)}
                <FaExternalLinkAlt className='ml-1 inline-block' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPayroll;
