import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Table } from "antd";
import { gql } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { RecruitmentPayroll, RecruitmentPayrollPeriod } from "../../types";
import { Button, Flex } from "@chakra-ui/react";
import RequestFailed from "@/_components/RequestFailed";
import Loading from "@/_components/Loading";

const GET_PAYROLL_PERIODS = gql`
  query GetRecruitmentPayrollPeriods($recruitmentId: String!) {
    getRecruitmentPayrollPeriods(recruitmentId: $recruitmentId) {
      start
      end
    }
  }
`;

const GET_RECRUITMENT_PAYROLLS = gql`
  query GetRecruitmentPayrollsForRecruitment($recruitmentId: String!) {
    getRecruitmentPayrollsForRecruitment(recruitmentId: $recruitmentId) {
      id
      period {
        start
        end
      }
    }
  }
`;

const CREATE_RECRUITMENT_PAYROLL = gql`
  mutation CreateRecruitmentPayroll(
    $period: RecruitmentPayrollPeriodInput!
    $recruitmentId: String!
  ) {
    createRecruitmentPayroll(period: $period, recruitmentId: $recruitmentId) {
      id
    }
  }
`;

const PayrollPeriods = () => {
  const { id: recruitmentId } = useParams<{ id: string }>();
  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState(false);

  const {
    loading: payrollPeriodsIsLoading,
    error: payrollPeriodsError,
    data: payrollPeriodsData,
  } = useQuery(GET_PAYROLL_PERIODS, {
    variables: { recruitmentId },
  });

  const {
    loading: payrollsLoading,
    error: payrollsError,
    data: payrollsData,
    refetch: refetchPayrollsData,
  } = useQuery(GET_RECRUITMENT_PAYROLLS, {
    variables: { recruitmentId },
  });

  const [createPayrollMutation, createPayrollMutationResponse] = useMutation(
    CREATE_RECRUITMENT_PAYROLL,
    {
      onCompleted: () => refetchPayrollsData(),
    }
  );

  if (payrollPeriodsIsLoading || payrollsLoading) return <Table loading />;

  if (payrollPeriodsError) {
    return <RequestFailed errorMessage={payrollPeriodsError.message} />;
  }

  if (payrollsError) {
    return <RequestFailed errorMessage={payrollsError.message} />;
  }

  if (createPayrollMutationResponse.error) {
    return (
      <RequestFailed
        errorMessage={createPayrollMutationResponse.error.message}
      />
    );
  }

  const { loading: createPayrollLoading } = createPayrollMutationResponse;

  const payrollPeriods = payrollPeriodsData.getRecruitmentPayrollPeriods;
  const payrolls = payrollsData.getRecruitmentPayrollsForRecruitment;

  const columns = [
    {
      title: "Start Date",
      dataIndex: "start",
      key: "start",
      render: (start: Date) => (
        <p className='text-sm text-[#003952] font-semibold'>
          {new Date(start).toISOString().split("T")[0]}
        </p>
      ),
    },
    {
      title: "End Date",
      dataIndex: "end",
      key: "end",
      render: (end: Date) => (
        <p className='text-sm text-[#003952] font-semibold'>
          {new Date(end).toISOString().split("T")[0]}
        </p>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: RecruitmentPayrollPeriod) => {
        const existingPayroll = payrolls.find(
          (payroll: RecruitmentPayroll) =>
            payroll.period.start === record.start &&
            payroll.period.end === record.end
        );

        return (
          <Flex gap={4}>
            {!!existingPayroll ? (
              <Button
                colorScheme='brand'
                fontSize='small'
                onClick={() => {
                  history.push(
                    `/recruitment/${recruitmentId}/payroll/${existingPayroll.id}`
                  );
                }}
              >
                View Payroll
              </Button>
            ) : (
              <Button
                colorScheme='brand'
                fontSize='small'
                isLoading={createPayrollLoading}
                onClick={() => createPayroll(record)}
              >
                Create Payroll
              </Button>
            )}
          </Flex>
        );
      },
    },
  ];

  const createPayroll = async (record: RecruitmentPayrollPeriod) => {
    const { start, end } = record;
    await createPayrollMutation({
      variables: {
        period: { start, end },
        recruitmentId,
      },
    });
  };

  return (
    <Table columns={columns} dataSource={payrollPeriods} pagination={false} />
  );
};

export default PayrollPeriods;
