import { Progress } from "semantic-ui-react";
import { ProTradeRequirementResult } from "../../common/types/pro-trade-requirement";
import { ScoringTemplateField } from "../../common/types/trade";
import Pill from "../../_components/Pill";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { gql, useMutation } from "@apollo/client";
import React from "react";

const REMOVE_RESULT = gql`
  mutation RemoveProTradeRequirementResult($id: String!, $attemptId: Float!) {
    removeProTradeRequirementResult(
      proTradeRequirementId: $id
      attemptId: $attemptId
    ) {
      id
      results {
        attemptId
        passed
        scores
        submittedAt
      }
    }
  }
`;

export interface ResultCardProps {
  proTradeRequirementId: string;
  result: ProTradeRequirementResult;
  template?: ScoringTemplateField[];
}

export const ResultCard = ({
  proTradeRequirementId,
  result,
  template,
}: ResultCardProps) => {
  const success = "!bg-green-100 !text-green-800";
  const fail = "!bg-red-100 !text-red-800";

  const cancelRef = React.useRef<any>();

  const {
    isOpen: isAlertDialogOpen,
    onOpen: onAlertDialogOpen,
    onClose: onAlertDialogClose,
  } = useDisclosure();

  const [removeResult, { loading: removeResultLoading }] = useMutation(
    REMOVE_RESULT,
    {
      onCompleted: () => {
        onAlertDialogClose();
      },
    }
  );

  const handleRemoveResult = () => {
    removeResult({
      variables: {
        id: proTradeRequirementId,
        attemptId: result.attemptId,
      },
    });
  };

  return (
    <>
      <AlertDialog
        isOpen={isAlertDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertDialogClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Result
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertDialogClose}>
                Cancel
              </Button>
              <Button
                colorScheme='red'
                onClick={handleRemoveResult}
                ml={3}
                isLoading={removeResultLoading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <div className='text-primary-500 w-80 max-w-xs border p-6'>
        <div className='flex justify-start items-center gap-2 mb-4'>
          <h3 className='text-lg text-primary-500 font-semibold'>
            Attempt #{result.attemptId}
          </h3>
          <Pill
            className={`${
              result.passed ? success : fail
            } !text-xs !font-semibold !uppercase`}
          >
            {result.passed ? "Passed" : "Failed"}
          </Pill>
        </div>

        <div className='flex flex-col gap-2'>
          {!template ? (
            <div>
              <p>Score: {result.scores}</p>
            </div>
          ) : (
            template.map((field, index) => {
              return (
                <div className='w-full'>
                  <p>
                    {field.name}: {result.scores[index]}
                    {field.maximumScore ? `/${field.maximumScore}` : ""}
                  </p>
                  {field.maximumScore ? (
                    <Progress
                      percent={
                        (result.scores[index] / field.maximumScore) * 100
                      }
                      size='tiny'
                      color='blue'
                    />
                  ) : null}
                </div>
              );
            })
          )}
        </div>

        <div className='flex justify-end'>
          <Button
            leftIcon={<MdDelete />}
            colorScheme='red'
            variant='ghost'
            onClick={onAlertDialogOpen}
            isLoading={removeResultLoading}
          >
            Remove
          </Button>
        </div>
      </div>
    </>
  );
};
