import { gql, useMutation, useQuery } from "@apollo/client";
import React, { FC } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { MarketplaceRequest, TransactionStatus } from "../../types";
import BackButton from "../../_components/BackButton";
import Field from "../../_components/Field";
import Island from "../../_components/Island";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import Button from "@laborhack/custom-button";
import { MarketplaceJobList } from "./MarketplaceJobList";
import { CANCEL_INVOICE } from "../../pages/orders/_graphql/mutation";

const SEND_MATCH_REPORT = gql`
  mutation SendMatchReport($id: String!) {
    sendMarketplaceRequestMatchReport(id: $id) {
      id
      matchingFeeInvoice {
        id
        sent
      }
    }
  }
`;

const CREATE_MATCH_INVOICE = gql`
  mutation CreateMatchInvoice($id: String!) {
    createMarketplacerequestMatchInvoice(id: $id) {
      id
      matchingFeeInvoice {
        id
      }
      jobs {
        id
        matches {
          pro {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

const MARKETPLACE_REQUEST = gql`
  query MarketplaceRequest($id: String!) {
    marketplaceRequest(id: $id) {
      id
      client {
        id
        userId
        firstName
        lastName
        email
        phoneNumber
      }
      business {
        id
        name
      }
      matchingFeeInvoice {
        id
        sent
        isFullyPaid
        hasAtLeastOnePayment
        payment {
          status
        }
      }
      jobs {
        id
        trade {
          name
        }
        proCount
        matches {
          pro {
            id
            firstName
            lastName
            imageUrl
          }
        }
      }
      location {
        address
        city
        state
      }
    }
  }
`;

export interface MarketplaceRequestViewProps {}

export const MarketplaceRequestView: FC<MarketplaceRequestViewProps> = ({}) => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const { loading, error, data, refetch } = useQuery<{
    marketplaceRequest: MarketplaceRequest;
  }>(MARKETPLACE_REQUEST, {
    variables: { id },
  });

  const [generateInvoice, generateInvoiceCall] = useMutation(
    CREATE_MATCH_INVOICE,
    {
      variables: { id },
    }
  );

  const [sendReport, sendReportCall] = useMutation(SEND_MATCH_REPORT, {
    variables: { id },
  });

  const [cancelInvoice, cancelInvoiceCall] = useMutation(CANCEL_INVOICE, {
    onCompleted: () => {
      refetch();
    },
  });

  if (loading) return <Loading />;

  if (error || !data) {
    return <RequestFailed />;
  }

  if (generateInvoiceCall.error) {
    return (
      <RequestFailed
        errorMessage={generateInvoiceCall.error.message}
        onRetry={generateInvoiceCall.reset}
      />
    );
  }

  const {
    client,
    business,
    matchingFeeInvoice,
    jobs,
  } = data.marketplaceRequest;

  const { address, city, state } = data.marketplaceRequest.location;

  const matchingFeePaid = matchingFeeInvoice?.isFullyPaid;
  return (
    <>
      <BackButton />
      <Island>
        <div className='mb-4'>
          <h2 className='text-primary-500 text-xl font-bold m-0'>
            Marketplace Request <span className='text-primary-300'>({id})</span>
          </h2>
          <p className='m-0 text-primary-300'>View marketplace request</p>
        </div>
        <div className='w-full flex justify-end my-4'>
          {matchingFeeInvoice && (
            <Button
              className='!bg-primary-150 !text-primary-500'
              type='subtle'
              onClick={() => history.push("/invoices/" + matchingFeeInvoice.id)}
            >
              View Invoice
            </Button>
          )}
          {!matchingFeeInvoice && (
            <Button
              className='bg-primary-500'
              onClick={() => generateInvoice()}
              loading={generateInvoiceCall.loading}
            >
              Generate Invoice
            </Button>
          )}
          {matchingFeeInvoice && !matchingFeeInvoice.isFullyPaid && (
            <Button
              className='ml-2 bg-red-500'
              onClick={() =>
                cancelInvoice({
                  variables: {
                    invoiceId: matchingFeeInvoice.id,
                  },
                })
              }
              loading={cancelInvoiceCall.loading}
            >
              Cancel Invoice
            </Button>
          )}
          {matchingFeeInvoice && !matchingFeePaid && (
            <Button
              className='ml-2 bg-primary-500'
              onClick={sendReport}
              loading={sendReportCall.loading}
            >
              {matchingFeeInvoice.sent ? "Resend" : "Send"} Match Report
            </Button>
          )}
        </div>
        <div>
          <div className='mb-4'>
            <h3 className='text-lg font-bold text-primary-500 mb-2'>
              Basic Information
            </h3>
            <div className='pl-2'>
              <Field
                label='Client'
                value={
                  <p className='text-right'>
                    <Link to={`/clients/${client.id}`}>
                      {client.firstName} {client.lastName} ({business.name})
                    </Link>
                  </p>
                }
              />

              <Field
                label='Email'
                value={
                  client.email || (
                    <p className='italic text-primary-500 text-right'>
                      Not Provided
                    </p>
                  )
                }
              />

              <Field
                label='Phone Number'
                value={
                  client.phoneNumber || (
                    <p className='italic text-primary-500 text-right'>
                      Not Provided
                    </p>
                  )
                }
              />
              <Field
                label='Address'
                value={
                  <p className='text-primary-500 text-right'>
                    {address}, {city}, {state}
                  </p>
                }
              />
              <Field
                label='Matching Fee Paid'
                value={
                  <p className='text-right'>
                    {matchingFeePaid ? (
                      <Icon name='check square' color='green' size='large' />
                    ) : (
                      <Icon name='remove circle' color='red' size='large' />
                    )}
                  </p>
                }
              />
            </div>
          </div>
        </div>
        <div className='mt-8 mb-4'>
          <h3 className='text-lg font-bold text-primary-500 mb-2'>
            Jobs Requested
          </h3>
          <MarketplaceJobList jobs={jobs} />
        </div>
      </Island>
    </>
  );
};
