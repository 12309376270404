import React, { FC } from 'react';
import Button from '@laborhack/custom-button';

import styles from './styles.module.scss';

export interface EditButtonProps {
  onEdit: () => void;
  editDisabled?: boolean;
  onCancel: () => void;
  cancelDisabled?: boolean;
  onSave: () => void;
  saveDisabled?: boolean;
  loading?: boolean;
}

export const EditButton: FC<EditButtonProps> = ({
  onEdit,
  editDisabled,
  onCancel,
  cancelDisabled,
  onSave,
  saveDisabled,
  loading,
}) => {
  const [editMode, setEditMode] = React.useState(false);

  const handleEdit = () => {
    onEdit();
    setEditMode(true);
  };

  const handleCancel = () => {
    onCancel();
    setEditMode(false);
  };

  const handleSave = () => {
    onSave();
    setEditMode(false);
  };

  return (
    <div className={styles.wrapper}>
      {editMode ? (
        <>
          <Button
            disabled={cancelDisabled}
            loading={loading}
            className={styles.cancel}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={saveDisabled}
            loading={loading}
            variant="success"
            onClick={handleSave}
          >
            Save
          </Button>
        </>
      ) : (
        <Button
          disabled={editDisabled}
          loading={loading}
          variant="basic"
          onClick={handleEdit}
        >
          Edit
        </Button>
      )}
    </div>
  );
};
