import React, { FC } from "react";
import Input from "@laborhack/input";
import Button from "@laborhack/custom-button";
import { Modal } from "semantic-ui-react";
import { CertificationRequirement } from "../../types";
import { gql, useMutation } from "@apollo/client";
import { GET_PRO_CERTIFICATION } from "./constants";
import RequestFailed from "../../_components/RequestFailed";
import { useParams } from "react-router-dom";

export interface AddScoreProps {
  isOpen: boolean;
  onClose: () => void;
  requirement: CertificationRequirement;
}

const CREATE_PRO_CERTIFICATION_RESULT = gql`
  mutation CreateProCertificationResult(
    $proCertificationId: String!
    $certificationRequirementId: String!
    $score: Int!
  ) {
    createProCertificationResult(
      proCertificationId: $proCertificationId
      certificationRequirementId: $certificationRequirementId
      score: $score
    ) {
      id
      score
    }
  }
`;

export const AddScore: FC<AddScoreProps> = ({
  isOpen,
  onClose,
  requirement,
}) => {
  const { id } = useParams<{ id: string }>();

  const [score, setScore] = React.useState<number>();

  const [
    createProCertificationResult,
    { loading: isCreating, error: createError, reset },
  ] = useMutation(CREATE_PRO_CERTIFICATION_RESULT, {
    refetchQueries: [
      {
        query: GET_PRO_CERTIFICATION,
        variables: {
          id,
        },
      },
    ],
    onCompleted: () => {
      onClose();
    },
  });

  if (createError)
    return (
      <RequestFailed
        errorMessage={createError.message}
        onRetry={() => {
          reset();
        }}
      />
    );

  const handleSave = () => {
    if (score) {
      createProCertificationResult({
        variables: {
          proCertificationId: id,
          certificationRequirementId: requirement.id,
          score,
        },
      });
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className='p-6 flex flex-col gap-8'>
        <div>
          <h3 className='text-xl font-semibold text-primary-500 m-0'>
            Set {requirement.name} Score
          </h3>
          <p className='text-sm text-gray-500'>
            Adds a score for {requirement.name} to your this certification.
          </p>
        </div>
        <div className='flex flex-col gap-2'>
          <label>Score (%)</label>
          <div className='flex items-center gap-2'>
            <Input
              type='number'
              max={100}
              value={score?.toString() || ""}
              onChange={(value) => {
                setScore(value ? Number(value) : undefined);
              }}
            />
            <Button
              className='mb-1'
              disabled={!score}
              loading={isCreating}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
