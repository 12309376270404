import { gql, useQuery } from "@apollo/client";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useMemo } from "react";
import Button from "@laborhack/custom-button";
import { components as LayoutComponents } from "../../../components/layout";
import Island from "../../../_components/Island";
import RequestFailed from "../../../_components/RequestFailed";
import { Business, BusinessFilter, SentEmailDeliveryStatus } from "../types";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";
import { RelayStylePaginatedResponse } from "../../../types";
import { extractItemsFromRelayStylePaginationResponse } from "../../../helpers/helpers";
import { Badge, BadgeType } from "../../../_components/Badge/Badge";
import { AddFilter } from "./AddFilter";

const { CustomTable } = LayoutComponents;

const LIST_BUSINESSES = gql`
  query ListBusinesses(
    $first: Int
    $after: String
    $filters: ListBusinessesFilters
  ) {
    businesses(first: $first, after: $after, filters: $filters) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          listId
          name
          phoneNumber
          email
          activated
          activationDeliveryStatus
          clients {
            id
          }
          location {
            address
            city
            state
          }
          createdAt
        }
      }
    }
  }
`;

export interface BusinessListProps {}

export const BusinessList: FC<BusinessListProps> = ({}) => {
  const history = useHistory();

  const [filters, setFilters] = React.useState<BusinessFilter[]>([]);

  const deliveryStatusFilters = useMemo(() => {
    return filters.filter((filter) => "deliveryStatus" === filter.class);
  }, [filters]);

  const activationStatus: boolean | undefined = useMemo(() => {
    const activationFilters = filters.filter(
      (filter) => filter.class === "activated"
    );

    if (!activationFilters.length || activationFilters.length === 2) {
      // no activation filters have been set
      return undefined;
    }

    return activationFilters[0].value as boolean;
  }, [filters]);

  const { loading, error, data, fetchMore } = useQuery<{
    businesses: RelayStylePaginatedResponse<Business>;
  }>(LIST_BUSINESSES, {
    variables: {
      first: 20,
      filters: {
        ...(deliveryStatusFilters.length && {
          deliveryStatus: deliveryStatusFilters.map(({ value }) => value),
        }),
        activated: activationStatus,
      },
    },
  });

  if (error) {
    return <RequestFailed />;
  }

  const columns: ColumnsType<Business> = [
    {
      title: "#",
      render: (text, record) => {
        return <p>{record.listId}</p>;
      },
      width: "8%",
    },
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.id.substring(0, 8)}</p>;
      },
      width: "10%",
    },
    {
      title: "Business Name",
      render: (text, record) => {
        return <p>{record.name}</p>;
      },
      width: "15%",
    },
    {
      title: "Email",
      render: (text, record) => {
        return <p>{record.email || "N/A"}</p>;
      },
      width: "20%",
    },

    {
      title: "Contacts",
      render: (text, record) => {
        return <p>{record.clients ? record.clients.length : 0}</p>;
      },
      width: "7%",
    },
    {
      title: "Delivered",
      render: (text, record) => {
        const deliveryStatusToBadgeType: {
          [Property in SentEmailDeliveryStatus]: BadgeType;
        } = {
          Opened: "success",
          Delivered: "success",
          Pending: "progress",
          Failed: "cancelled",
        };

        return (
          <Badge
            inverted
            text={record.activationDeliveryStatus}
            type={deliveryStatusToBadgeType[record.activationDeliveryStatus]}
          />
        );
      },
      width: "10%",
    },
    {
      title: "Activated",
      render: (text, record) => {
        return record.activated ? (
          <Icon name='check square' color='green' size='large' />
        ) : (
          <Icon name='remove circle' color='red' size='large' />
        );
      },
      width: "8%",
    },
    {
      title: "Created At",
      render: (text, record) => {
        return <p>{new Date(record.createdAt).toLocaleDateString()}</p>;
      },
      width: "12%",
    },

    {
      title: "",
      render: (text, record) => {
        return (
          <div className='w-full flex justify-between'>
            <Link to={"/businesses/" + record.id}>
              <Button type='link'>View</Button>
            </Link>
          </div>
        );
      },
      width: "15%",
    },
  ];

  const getMore = () => {
    fetchMore({
      variables: {
        first: 20,
        after: data?.businesses.pageInfo.endCursor,
        filters: {
          ...(deliveryStatusFilters.length && {
            deliveryStatus: deliveryStatusFilters.map(({ value }) => value),
          }),
          activated: activationStatus,
        },
      },
    });
  };

  return (
    <Island>
      <div className='mb-4'>
        <h2 className='text-primary-500 text-xl font-bold m-0'>
          All Businesses
        </h2>
        <p className='m-0 text-primary-300'>
          A list of all businesses registered so far
        </p>
      </div>
      <div className='w-full flex justify-end my-4'>
        <Button onClick={() => history.push("/businesses/create")}>
          Create Business
        </Button>
      </div>
      <div className='mt-8 flex justify-between items-center'>
        <div>
          <Dropdown
            icon={null}
            pointing='top left'
            trigger={
              <Button
                type='subtle'
                variant='basic'
                className='!px-4 !bg-primary-150 !text-primary-500'
              >
                <Icon className='!ml-0' name='filter' />
                Manage Filters
              </Button>
            }
          >
            <Dropdown.Menu>
              <AddFilter filters={filters} setFilters={setFilters} />
            </Dropdown.Menu>
          </Dropdown>
          <div className='mr-4 flex'>
            {filters.map((filter, index) => {
              return (
                <div className='flex items-center ml-2 rounded-full pl-4 pr-2 py-2 bg-primary-150 text-primary-500 font-semibold'>
                  <span>{filter.name}</span>
                  <Icon
                    className='!mr-0 !ml-2 cursor-pointer'
                    name='remove circle'
                    onClick={() =>
                      setFilters((prev) =>
                        prev.filter(
                          (f) => f.class + f.name !== filter.class + filter.name
                        )
                      )
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <Button
            type='subtle'
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_GRAPHQL_URL?.replace(
                  "graphql",
                  "business/report.csv"
                )}`,
                "_blank"
              );
            }}
          >
            Download Report <Icon name='download' />{" "}
          </Button>
        </div>
      </div>
      <div className='w-full flex justify-end my-2'>
        <p>
          Showing <strong>{data?.businesses.edges.length}</strong> of{" "}
          <strong>{data?.businesses.totalCount}</strong> results
        </p>
      </div>
      <CustomTable
        columns={columns}
        loading={loading}
        data={
          data
            ? extractItemsFromRelayStylePaginationResponse(data.businesses)
            : []
        }
        onRow={(record: Business) => {
          return {
            onClick: () => history.push("/businesses/" + record.id),
          };
        }}
        hasMore={data?.businesses.pageInfo.hasNextPage}
        fetchMore={getMore}
      />
    </Island>
  );
};
