import gql from "graphql-tag";

export const GET_CLIENT = gql`
  query GetClient($id: String!) {
    client(clientId: $id) {
      id
      userId
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;
