import { ColumnsType } from "antd/lib/table";
import React, { FC } from "react";
import { MarketplaceJob } from "../../types";
import { components as LayoutComponents } from "../../components/layout";
import clsx from "clsx";
import Button from "@laborhack/custom-button";
import { useHistory, useRouteMatch } from "react-router-dom";
import Avatar from "../../_components/Avatar";
import AvatarGroup from "../../_components/AvatarGroup";

const { CustomTable } = LayoutComponents;

export interface MarketplaceJobListProps {
  jobs: MarketplaceJob[];
}

export const MarketplaceJobList: FC<MarketplaceJobListProps> = ({ jobs }) => {
  const history = useHistory();

  const { path } = useRouteMatch();

  const columns: ColumnsType<MarketplaceJob & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.id.substring(0, 8)}</p>;
      },
      width: "10%",
    },
    {
      title: "Matches",
      render: (text, record) => {
        return (
          <AvatarGroup maxCount={2}>
            {record.matches.map((match) => {
              return (
                <Avatar src={match.pro.imageUrl}>
                  {match.pro.firstName?.substring(0, 1)}
                </Avatar>
              );
            })}
          </AvatarGroup>
        );
      },
      width: "15%",
    },
    {
      title: "Pros Matched/Requested",
      render: (text, record) => {
        return (
          <p
            className={clsx(
              "text-base",
              record.matches.length >= record.proCount && "text-success"
            )}
          >
            {record.matches.length || 0}/{record.proCount}
          </p>
        );
      },
      width: "10%",
    },

    {
      title: "Trade",
      render: (text, record) => {
        return <p>{record.trade.name}</p>;
      },
      width: "15%",
    },
  ];

  return (
    <CustomTable
      onRow={(record: any, index: any) => {
        return {
          onClick: () => {
            history.push(path.replace(":id", "") + "jobs/" + record.id);
          },
        };
      }}
      columns={columns}
      data={
        jobs.map((request) => {
          return {
            key: request.id,
            ...request,
          };
        }) || []
      }
      handleTableChange={() => {}}
    ></CustomTable>
  );
};
