import { DynamicKeyObject } from "./types";

export const STRINGS = {
  LOGOUT: "Logout",
  COMPANY_NAME: "LaborHack",
};

/**
 * NOTE: update as you extend routes to enable breadcrumbs
 * An object containing the trails of each route mapped to a name to be shown as breadcrumbs
 */
export const pathTrailMap: DynamicKeyObject<any> = {
  overview: {
    name: "Overview",
    routes: {},
  },
  orders: {
    name: "Orders",
    routes: {
      all: {
        name: "All",
        routes: {},
      },
    },
  },
  payments: {
    name: "Payments",
    routes: {},
  },
  invoices: {
    name: "Invoices",
    routes: {},
  },
  refunds: {
    name: "Refunds",
    routes: {
      create: {
        name: "Create A Refund",
        routes: {},
      },
    },
  },
  clients: {
    name: "Clients",
    routes: {},
  },
  tradesmen: {
    name: "Pros",
    routes: {},
  },
  admins: {
    name: "Administrators",
    routes: {
      create: {
        name: "Create new administrator",
        routes: {},
      },
    },
  },
  categories: {
    name: "Categories",
    routes: {},
  },
  settings: {
    name: "Settings",
    routes: {},
  },
  notifications: {
    name: "All Notifications",
    routes: {},
  },
  search: {
    name: "Search",
    routes: {},
  },
};
