import React, { FC } from "react";

import styles from "./styles.module.scss";
import EditButton from "../../../../_components/EditButton";
import { useMutation } from "@apollo/client";
import { UPDATE_ADMIN_ROLES } from "../../_graphql/mutation";
import RequestFailed from "../../../../_components/RequestFailed";
import { useSession } from "../../../../firebase/hooks/useSessionHook";
import { isAuthorized } from "../../../../helpers/helpers";
import { Admin } from "../../types";
import { Role } from "../../../../types";

export interface PermissionsProps {
  admin: Admin;
}

const ROLES = [
  { name: "CEO", value: Role.ROOT },
  { name: "User Administrator", value: Role.USER_ADMINISTRATOR },
  { name: "Finance", value: Role.FINANCE },
  { name: "Project Manager", value: Role.PROJECTMANAGER },
  { name: "Customer Service", value: Role.CUSTOMERSERVICE },
  {
    name: "Business Developer",
    value: Role.BUSINESS_DEVELOPER,
  },
  {
    name: "System Administratior",
    value: Role.SYSTEM_ADMINISTRATOR,
  },
  {
    name: "Certification Administrator",
    value: Role.CERTIFICATION_ADMINISTRATOR,
  },
  {
    name: "Pro Administrator",
    value: Role.PRO_ADMINISTRATOR,
  },
  {
    name: "Outreach",
    value: Role.OUTREACH,
  },
];

export const Permissions: FC<PermissionsProps> = ({
  admin: { id, userId, roles },
}) => {
  const { user, idTokenResult } = useSession();

  const canEdit = isAuthorized(
    {
      USER_ADMINISTRATOR: true,
    },
    idTokenResult?.claims.roles
  );

  const [selectedRoles, setSelectedRoles] = React.useState<string[]>(roles);

  const [editMode, setEditMode] = React.useState(false);

  const [error, setError] = React.useState("");

  const [updateRoles, { loading }] = useMutation(UPDATE_ADMIN_ROLES, {
    onError: (error) => {
      setError(error.message);
    },
    onCompleted: () => {
      setEditMode(false);
    },
  });

  const handleSave = () => {
    updateRoles({
      variables: {
        id,
        roles: selectedRoles,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3>Permissions</h3>
        <EditButton
          onEdit={() => {
            setEditMode(true);
          }}
          editDisabled={!canEdit}
          onSave={() => {
            handleSave();
            setEditMode(false);
          }}
          onCancel={() => {
            setSelectedRoles(roles);
            setEditMode(false);
          }}
          loading={loading}
        />
      </div>

      {editMode && user?.uid === userId && (
        <p
          style={{
            color: "red",
          }}
        >
          You are editing your assigned permissions. Changes you make may affect
          your access to this dashboard. <strong>BE CAREFUL</strong>
        </p>
      )}

      {error ? (
        <RequestFailed errorMessage={error} onRetry={() => setError("")} />
      ) : (
        <div className={styles.permissions}>
          {ROLES.map(({ name, value }) => {
            const isSelected = selectedRoles.includes(value);
            return (
              <div key={value} className={styles.permission}>
                <input
                  disabled={!editMode}
                  type='checkbox'
                  checked={isSelected}
                  onChange={() => {
                    if (isSelected) {
                      setSelectedRoles(
                        selectedRoles.filter((r) => r !== value)
                      );
                    } else {
                      setSelectedRoles([...selectedRoles, value]);
                    }
                  }}
                />
                <label>{name}</label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
