import React, { FC } from "react";

const CustomLabel: FC<{
  color: {
    r: number;
    g: number;
    b: number;
  };
  text: string;
}> = ({ color, text }) => {
  const primary = `rgba(${color.r}, ${color.g}, ${color.b}, 100%)`;

  const background = `rgba(${color.r}, ${color.g}, ${color.b}, 15%)`;

  return (
    <div
      className="m-badge__wrapper"
      style={{
        backgroundColor: background,
        color: primary,
        borderColor: primary,
      }}
    >
      <p>{text}</p>
    </div>
  );
};

export default CustomLabel;
