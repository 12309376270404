import React, { FC } from "react";
import { Icon } from "semantic-ui-react";
import { Tradesman } from "../types";
import { CatServiceSelectors } from "../../../redux/store";
import { useSelector } from "react-redux";

const { selectCategories } = CatServiceSelectors;

export const TradesmanSelectionItem: FC<{
  tradesman: Tradesman;
  onClick: () => void;
  selected?: boolean;
}> = ({ tradesman, onClick, selected }) => {
  const categories = useSelector(selectCategories);

  return (
    <div
      className={`o-tradesmanSelectedItem__wrapper ${
        selected ? "-selected" : ""
      }`}
      onClick={onClick}
    >
      <div className="m-tradesmanSelectedItem__details">
        <h4>{`${tradesman.firstName} ${tradesman.lastName}`}</h4>
        <p>{`${tradesman.location.area}, ${tradesman.location.location}`}</p>
      </div>
      <h5>{categories[tradesman.categoryId].name}</h5>
      <h5>{tradesman.level}</h5>
      <h5>{tradesman.availability}</h5>
      <Icon name="chevron right"></Icon>
    </div>
  );
};
