import React, { FC } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

/**
 * Layout information wrapper
 * Simply creates a div with a white background and the shadow
 */

export interface IslandProps {
  header?: string | any;
  actions?: any[];
  text?: string;
  deep?: boolean;
}

export const Island: FC<IslandProps> = ({
  children,
  header,
  text,
  deep,
  actions,
}) => {
  return (
    <div className={styles.wrapper}>
      {header && (
        <div className={clsx(styles.header, deep && styles.deep)}>
          <div className={styles.headerContainer}>
            {typeof header === "string" ? <h2>{header}</h2> : header}
            <div className={styles.headerActions}>{actions}</div>
          </div>
          {text && <p>{text}</p>}
        </div>
      )}
      {children}
    </div>
  );
};
