import React, { FC } from "react";
import { Client } from "../../../types";

export interface ViewBusinessClientCardProps {
  client: Client;
}

export const ViewBusinessClientCard: FC<ViewBusinessClientCardProps> = ({
  client: { firstName, lastName, email, phoneNumber, businessMetadata },
}) => {
  return (
    <div className='p-4 grid grid-cols-[2fr_1fr] items-start border rounded-lg border-primary-150 mb-2 last:mb-0'>
      <div>
        <p className='text-lg font-bold text-primary-500 mb-4'>{`${firstName} ${lastName}`}</p>
        <p className='text-sm text-primary-300'>{email}</p>
        {phoneNumber && (
          <p className='text-sm text-primary-300'>{phoneNumber}</p>
        )}
      </div>
      <div className='justify-self-end'>
        {businessMetadata?.activated && (
          <div className='rounded-full px-2 py-1 w-fit font-semibold text-sm text-success bg-green-50 text-center'>
            Activated
          </div>
        )}
      </div>
    </div>
  );
};
