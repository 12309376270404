import { TransferStatus } from "@/types";
import { gql } from "@apollo/client";

export const getTransferBadgeColor = (status: TransferStatus) => {
  const handlers: Record<TransferStatus, string> = {
    [TransferStatus.CONFIRMED]: "green",
    [TransferStatus.FAILED]: "red",
    [TransferStatus.PENDING]: "yellow",
    [TransferStatus.AWAITING_APPROVAL]: "yellow",
    [TransferStatus.QUEUED]: "blue",
    [TransferStatus.REVERSED]: "red",
  };

  return handlers[status] || "gray";
};

export const GET_REFERRER_PAYOUTS = gql`
	query ReferrerPayouts($transferStatus: [TransferStatus!]) {
		referrerPayouts(transferStatus: $transferStatus) {
			id
			override {
				amount
			}
			createdAt
			period {
				month
				year
			}
			amount
			referrer {
				id
				name
			}
			transfer {
				status
				id
			}
		}
	}
`;

export const GET_REFERRER_PAYOUT = gql`
	query ReferrerPayoutById($referrerPayoutId: String!) {
		referrerPayoutById(referrerPayoutId: $referrerPayoutId) {
			amount
			createdAt
			id
			override {
				amount
			}
			period {
				year
				month
			}
			referrer {
				id
				name
				businessName
				email
				referralCode
			}
			transfer {
				transferCode
				status
			}
		}
	}
`;

export const GET_REFERRER_BY_ID = gql`
	query GetReferrerById($referrerId: String!) {
		getReferrerById(referrerId: $referrerId) {
			name
			businessName
			referralCode
			email
			commissionPercentage
			paymentProviderInformation {
				providerData {
					providers {
						recipientCode
						providerId
						bankInformation {
							accountName
							accountNumber
							bankName
						}
					}
					accountNumber
				}
			}
			linkedDiscount {
				code
			}
			createdAt
		}
	}
`;