import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ExternalAssessments } from "./external";
import { ProTradeRequirementView } from "./ProTradeRequirementView";

export const ProTradeRequirements = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/external/:id`}
        component={ProTradeRequirementView}
      />
      <Route path={`${path}/external`} component={ExternalAssessments} />
    </Switch>
  );
};
