import { gql, useLazyQuery } from "@apollo/client";
import React, { FC, useEffect } from "react";
import { allWorkingDays } from "../../../../../constants";
import {
  formatAsNaira,
  getAllNonBillableDates,
  parseNonBillableDate,
} from "../../../../../helpers/helpers";
import Field from "../../../../../_components/Field";
import NonBillableDates from "../../../components/NonBillableDates";
import { Contract, NonBillableDate } from "../../../types";

const GET_PROPOSED_CONTRACT_END_DATE = gql`
  query GetProposedContractEndDate($proposed: ProposedContractInfo!) {
    proposedContractEndDate(proposed: $proposed)
  }
`;

export interface BasicDetailsProps {
  contract: Contract;
}

export const BasicDetails: FC<BasicDetailsProps> = ({ contract }) => {
  const [getEndDate, getEndDateResponse] = useLazyQuery(
    GET_PROPOSED_CONTRACT_END_DATE
  );

  const { startDate, endDate, nonWorkingDays } = contract;

  const parsedNonBillableDates = contract.nonBillableDates?.map(
    (item: string) => {
      const { dates } = parseNonBillableDate(item);

      const [startDate, endDate] = dates;

      return {
        startDate,
        endDate,
      };
    }
  );

  const [nonBillableDates, setNonBillableDates] = React.useState<
    NonBillableDate[]
  >(parsedNonBillableDates || []);

  useEffect(() => {
    if (contract.startDate) {
      getEndDate({
        variables: {
          proposed: {
            contractStartDate: contract.startDate,
            contractInterval: contract.contractInterval,
            contractLength: contract.contractLength,
            nonWorkingDays: contract.nonWorkingDays || [],
            nonBillableDates: contract.nonBillableDates || [],
          },
        },
      });
    }
  }, []);

  return (
    <div>
      <Field
        label='Client'
        value={`${contract.recruitment.client.firstName} ${contract.recruitment.client.lastName}`}
      />

      <Field
        label='Start Date'
        value={
          contract.startDate
            ? new Date(contract.startDate).toDateString()
            : "N/A"
        }
      />
      <Field
        label='End Date'
        value={endDate ? new Date(endDate).toDateString() : "N/A"}
      />
      <Field
        label='Duration'
        value={`${
          contract.contractLength
        } ${contract.contractInterval.toLowerCase()}`}
      />
      <Field
        label='Assigned Pro'
        value={
          contract.pro
            ? `${contract.pro.firstName} ${contract.pro.lastName}`
            : "N/A"
        }
      />
      <Field
        label='Agreed Daily Rate'
        value={formatAsNaira(contract.agreedRate)}
      />
      {!!startDate && !!endDate && (
        <div className='mt-12'>
          <div className='grid grid-cols-[160px_1fr] items-start justify-items-start'>
            <div className='self-center'>
              <p className='font-semibold text-primary-300'>Work Days Missed</p>
            </div>
            <NonBillableDates
              startDate={new Date(startDate)}
              nonWorkingDays={nonWorkingDays || []}
              nonBillableDates={nonBillableDates}
              setNonBillableDates={setNonBillableDates}
              endDate={new Date(endDate)}
              readOnly
            />
          </div>
        </div>
      )}
      <div className='mt-2'>
        <div className='grid grid-cols-[160px_1fr] items-start justify-items-start'>
          <div className='self-center'>
            <p className='font-semibold text-primary-300'>Non Working Days</p>
          </div>
          <div className='flex'>
            {allWorkingDays
              .filter(
                (day) =>
                  !nonWorkingDays?.length || !nonWorkingDays.includes(day) // quadratic complexity? Yeah, but the max array length is 7
              )
              .map((day) => {
                return (
                  <div
                    key={day}
                    className='rounded-full py-1 px-3 mr-2 bg-primary-150 text-primary-500 font-semibold'
                  >
                    {day}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
