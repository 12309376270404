import Badge from "@/_components/Badge";
import { gql, useMutation, useQuery } from "@apollo/client";
import { HStack, Stack } from "@chakra-ui/react";
import React, { FC } from "react";
import { GET_CATEGORIES } from "../../graphql/query";
import { Pro, ProApplication, Category } from "../../types";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import SelectField from "../../_components/SelectField";
import TextField from "../../_components/TextField";
import Button from "@laborhack/custom-button";
import { BackgroundCheck } from "./types";

const UPDATE_PRO = gql`
  mutation UpdatePro(
    $id: String!
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
  ) {
    updatePro(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
    ) {
      id
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;

const UPDATE_PRO_APPLICATION = gql`
  mutation UpdateProApplication(
    $id: String!
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $address: String
    $stateOfResidence: String
    $categoryId: String
  ) {
    updateProApplication(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      address: $address
      stateOfResidence: $stateOfResidence
      categoryId: $categoryId
    ) {
      id
      firstName
      lastName
      email
      phoneNumber
      address
      stateOfResidence
      categoryId
    }
  }
`;

const GET_PRO_APPLICATION = gql`
  query GetProApplication($id: String!) {
    proApplicationByProId(id: $id) {
      id
      userId
      firstName
      lastName
      phoneNumber
      email
      address
      stateOfResidence
      gender
      categoryId
      reasonForJoiningLaborHack
      reasonForJoiningLaborHackOtherText
      yearsOfExperience
      trainingType
      incomeLevel
    }
  }
`;

const GET_PRO_BACKGROUNDCHECK = gql`
  query GetBackgroundCheckByProId($proId: String!) {
    getBackgroundCheckByProId(proId: $proId) {
      isComplete
      id
    }
  }
`;

export interface DetailsProps {
  pro: Pro;
}

export const Details: FC<DetailsProps> = ({ pro }) => {
  const { id, email, firstName, lastName } = pro;

  const categoriesQuery = useQuery<{
    categories: Category[];
  }>(GET_CATEGORIES);

  const { data, error, loading } = useQuery<{
    proApplicationByProId: ProApplication;
  }>(GET_PRO_APPLICATION, {
    variables: {
      id,
    },
    fetchPolicy: "cache-first",
  });

  const {
    data: backgroundCheckData,
    error: backgroundCheckError,
    loading: backgroundCheckLoading,
  } = useQuery<{ getBackgroundCheckByProId: BackgroundCheck }>(
    GET_PRO_BACKGROUNDCHECK,
    {
      variables: {
        proId: id,
      },
      fetchPolicy: "cache-first",
    }
  );

  const [
    updatePro,
    { error: updateProError, loading: updateProLoading, reset: resetUpdatePro },
  ] = useMutation<{
    updatePro: Pro;
  }>(UPDATE_PRO);

  const [
    updateProApplication,
    {
      error: updateProApplicationError,
      loading: updateProApplicationLoading,
      reset: resetUpdateProApplication,
    },
  ] = useMutation<{
    updateProApplication: ProApplication;
  }>(UPDATE_PRO_APPLICATION);

  if (loading || backgroundCheckLoading) return <Loading />;

  if (error || !data || backgroundCheckError || !backgroundCheckData)
    return <RequestFailed />;

  if (updateProError)
    return (
      <RequestFailed
        errorMessage={updateProError.message}
        onRetry={() => {
          resetUpdatePro();
        }}
      />
    );

  if (updateProApplicationError)
    return (
      <RequestFailed
        errorMessage={updateProApplicationError.message}
        onRetry={() => {
          resetUpdateProApplication();
        }}
      />
    );

  const handleFirstNameChange = (firstName: string) => {
    updatePro({
      variables: {
        id,
        firstName,
      },
    });

    updateProApplication({
      variables: {
        id: data.proApplicationByProId.id,
        firstName,
      },
    });
  };

  const handleLastNameChange = (lastName: string) => {
    updatePro({
      variables: {
        id,
        lastName,
      },
    });

    updateProApplication({
      variables: {
        id: data.proApplicationByProId.id,
        lastName,
      },
    });
  };

  const handleCategoryChange = (categoryId: string) => {
    updateProApplication({
      variables: {
        id: data.proApplicationByProId.id,
        categoryId,
      },
    });
  };

  const handleAddressChange = (address: string) => {
    updateProApplication({
      variables: {
        id: data.proApplicationByProId.id,
        address,
      },
    });
  };

  const handleStateOfResidenceChange = (stateOfResidence: string) => {
    updateProApplication({
      variables: {
        id: data.proApplicationByProId.id,
        stateOfResidence,
      },
    });
  };

  const categoryOptions = categoriesQuery.data?.categories.map((category) => ({
    text: category.proTitle || category.name,
    value: category.id,
  }));

  let reasonForJoiningLaborHack: string;

  if (
    data.proApplicationByProId.reasonForJoiningLaborHack &&
    data.proApplicationByProId.reasonForJoiningLaborHack !== "OTHER"
  ) {
    reasonForJoiningLaborHack = data.proApplicationByProId.reasonForJoiningLaborHack.replaceAll(
      "_",
      " "
    );
  } else if (
    data.proApplicationByProId.reasonForJoiningLaborHack &&
    data.proApplicationByProId.reasonForJoiningLaborHack === "OTHER" &&
    data.proApplicationByProId.reasonForJoiningLaborHackOtherText
  ) {
    reasonForJoiningLaborHack =
      data.proApplicationByProId.reasonForJoiningLaborHackOtherText;
  } else {
    reasonForJoiningLaborHack = "Not Set";
  }

  let backgroundCheckStatus;

  if (!backgroundCheckData.getBackgroundCheckByProId) {
    backgroundCheckStatus = "Unsubmitted";
  } else if (backgroundCheckData.getBackgroundCheckByProId.isComplete) {
    backgroundCheckStatus = "Complete";
  } else {
    backgroundCheckStatus = "Incomplete";
  }

  return (
    <div className='flex flex-col gap-4'>
      <TextField
        label='First Name'
        value={firstName}
        editable
        onSave={handleFirstNameChange}
        saving={updateProLoading || updateProApplicationLoading}
      />
      <TextField
        label='Last Name'
        value={lastName}
        editable
        onSave={handleLastNameChange}
        saving={updateProLoading || updateProApplicationLoading}
      />
      <TextField label={"Email"} value={email} />
      <TextField
        label={"Phone Number"}
        value={data.proApplicationByProId.phoneNumber}
      />
      <SelectField
        label='Category'
        value={data.proApplicationByProId.categoryId}
        options={categoryOptions || []}
        editable={pro.trades.length === 0}
        onSave={handleCategoryChange}
        saving={updateProLoading || updateProApplicationLoading}
      />
      <TextField
        label={"Years of Experience"}
        value={data.proApplicationByProId.yearsOfExperience || "Not Set"}
      />
      <TextField
        label={"Training Type"}
        value={data.proApplicationByProId.trainingType || "Not Set"}
      />
      <TextField
        label={"Income Level"}
        value={data.proApplicationByProId.incomeLevel || "Not Set"}
      />
      <Stack>
        <Stack>
          <p className='font-semibold text-sm text-primary-300 m-0 p-0'>
            Background Check
          </p>
        </Stack>
        <HStack spacing={12}>
          <Badge
            text={backgroundCheckStatus.toUpperCase()}
            type={
              backgroundCheckStatus === "Complete"
                ? "success"
                : backgroundCheckStatus === "Unsubmitted"
                ? "cancelled"
                : "progress"
            }
          />
          <a href={`/pros/${id}/background-check`}>
            <Button
              size='small'
              variant='basic'
              onClick={() => {}}
              type='subtle'
            >
              {backgroundCheckStatus === "Unsubmitted" ? "Create" : "Edit"}
            </Button>
          </a>
        </HStack>
      </Stack>
      <TextField
        label={"Address"}
        value={data.proApplicationByProId.address}
        editable
        onSave={handleAddressChange}
        saving={updateProApplicationLoading}
      />
      <TextField
        label={"State of Residence"}
        value={data.proApplicationByProId.stateOfResidence}
        editable
        onSave={handleStateOfResidenceChange}
        saving={updateProApplicationLoading}
      />
      <TextField label={"Gender"} value={data.proApplicationByProId.gender} />
    </div>
  );
};
