import { useMutation, useQuery } from "@apollo/client";
import { Alert } from "antd";
import React, { FC, useState } from "react";
import { Checkbox, Form, Icon } from "semantic-ui-react";
import { CREATE_ASSIGNMENT_INSPECTION_INVOICE } from "../../../components/orders/graphql/mutation";
import { PaymentProvider, PaymentProviderChannel } from "../../../types";
import Button from "../../../_components/Button";
import { CustomInput } from "../../../_components/Input";
import { PAYMENT_PROVIDERS } from "../_graphql/query";

import styles from "./styles.module.scss";

export interface CreateInspectionInvoiceProps {
  assignmentId: string;
  close: Function;
}

export const CreateInspectionInvoice: FC<CreateInspectionInvoiceProps> = ({
  assignmentId,
  close,
}) => {
  const [inspectionAmount, setInspectionAmount] = useState<string>("");

  const [
    allowedPaymentProviderChannels,
    setAllowedPaymentProviderChannels,
  ] = useState<string[]>([PaymentProviderChannel.ONLINE]);

  const {
    loading: getPaymentProvidersLoading,
    data: getPaymentProvidersData,
    error: getPaymentProvidersError,
  } = useQuery<{
    paymentProviders: PaymentProvider[];
  }>(PAYMENT_PROVIDERS);

  const paymentProviders = getPaymentProvidersData?.paymentProviders;

  const paymentProvidersGroupedByChannel = paymentProviders?.reduce(
    (acc, curr) => {
      if (!acc[curr.channel]) {
        acc[curr.channel] = [];
      }
      acc[curr.channel].push(curr);
      return acc;
    },
    {} as {
      [key: string]: PaymentProvider[];
    }
  );

  const [createInspectionInvoice, { loading, error }] = useMutation(
    CREATE_ASSIGNMENT_INSPECTION_INVOICE,
    {
      onCompleted: () => {
        close();
      },
      onError: () => {},
    }
  );

  const handlePaymentProviderChannelCheckboxChange = (
    channel: string,
    checked?: boolean
  ) => {
    setAllowedPaymentProviderChannels((channels) => {
      if (checked) {
        return [...channels, channel];
      }

      return channels.filter((allowedChannel) => allowedChannel !== channel);
    });
  };

  const getPaymentProviderChannelLabel = (
    channel: string,
    providers: PaymentProvider[]
  ) => {
    return `${channel.toLowerCase()} (${providers
      .map(({ name }) => name)
      .join(", ")})`;
  };

  const handleSubmit = () => {
    createInspectionInvoice({
      variables: {
        assignmentId,
        amount: Number(inspectionAmount),
        ...(paymentProvidersGroupedByChannel && {
          allowedPaymentProviders: allowedPaymentProviderChannels
            .flatMap((channel) => paymentProvidersGroupedByChannel[channel])
            .map(({ id }) => id),
        }),
      },
    });
  };

  return (
    <>
      {error && <Alert banner type='error' message={error.message}></Alert>}
      <h4 className='text-primary-500 font-semibold text-xl'>
        Create Inspection Invoice
      </h4>
      <div className=''>
        <div className='flex flex-col flex-nowrap gap-2'>
          <Form>
            <CustomInput
              textLabel='Set Amount'
              type='number'
              value={inspectionAmount}
              onChange={(value) => {
                setInspectionAmount(value);
              }}
            />
          </Form>

          <div className='my-4 flex flex-col gap-2'>
            <h5 className='text-primary-500 font-semibold'>
              Set Payment Providers
            </h5>
            <div className='flex flex-col gap-1'>
              {getPaymentProvidersLoading ? (
                <Icon name='spinner' loading />
              ) : (
                Object.entries(paymentProvidersGroupedByChannel || {}).map(
                  ([channel, providers]) => {
                    return (
                      <Checkbox
                        className='mb-2 capitalize'
                        label={getPaymentProviderChannelLabel(
                          channel,
                          providers
                        )}
                        checked={allowedPaymentProviderChannels.includes(
                          channel
                        )}
                        onChange={(e, { checked }) =>
                          handlePaymentProviderChannelCheckboxChange(
                            channel,
                            checked
                          )
                        }
                      />
                    );
                  }
                )
              )}
            </div>
          </div>
        </div>

        <div className='flex justify-end'>
          <Button
            loading={loading}
            className={styles.button}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>
      </div>
    </>
  );
};
