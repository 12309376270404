import React, { FC, useState, useEffect } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { components as Layout } from "../../layout";
import { Row, Col, Steps, Alert } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { AssignmentSelectors, AssignmentActions } from "../../../redux/store";
import { Modal, Icon, Dropdown, Menu } from "semantic-ui-react";
import {
  Job,
  TransactionStatus,
  JobProgress,
  TradesmanAssignment,
  Order,
  AssignmentPaymentTypes,
  TransferStatus,
} from "../../../types";
import { useMutation, useQuery } from "@apollo/client";
import {
  CHECK_ASSIGNMENT_PAYMENT_STATUS,
  GET_ASSIGNMENT_INSPECTION_INVOICE,
  GET_REVIEW,
} from "../graphql/query";
import {
  DELETE_ASSIGNMENT,
  REQUEST_ASSIGNMENT_PAYMENT,
  UPDATE_ASSIGNMENT_PROGRESS,
  WAIVE_INSPECTION_FEE,
} from "../graphql/mutation";
import Island from "../../../_components/Island";
import { Confirmation } from "../../../_components/Confirmation/Confirmation";
import Button from "../../../_components/Button";
import { FeedbackForm } from "../../../pages/orders/_components/FeedbackForm";
import {
  calculateMaterialsAndLabor,
  formatAsNaira,
} from "../../../helpers/helpers";
import { Tab } from "../../../_components/Tab/Tab";
import AssignmentChecklist from "../../../pages/orders/_components/AssignmentChecklist";
import { CreateInspectionInvoice } from "../../../pages/orders/_components/CreateInspectionInvoice";
import { EditInspectionInvoice } from "../../../pages/orders/_components/EditInspectionInvoice";
import OrderInvoice from "./OrderInvoice";
import {
  CANCEL_INVOICE,
  SEND_INVOICE,
} from "../../../pages/orders/_graphql/mutation";
import { GET_TRADESMAN_ASSIGNMENT_BY_ID } from "../../../graphql/query";
import AssignedProList from "./AssignedProList";

const { Trail } = Layout;
const { closePaymentModal } = AssignmentActions;
const { selectAssignmentPayment, selectAssignmentStatus } = AssignmentSelectors;

export const ViewAssignment: FC<{
  order: Order;
  refetchAssignments: any;
}> = ({ order, refetchAssignments }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Selectors
  const currentAssignmentPayment = useSelector(selectAssignmentPayment);
  const requestStatus = useSelector(selectAssignmentStatus);

  // Package Hooks
  const { assignmentId } = useParams<{
    assignmentId: string;
  }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [confirmBoxOpen, setConfirmBoxOpen] = useState<boolean>(false);
  const [waiveInspectionOpen, setWaiveInspectionOpen] = useState<boolean>(
    false
  );
  const [feedbackFormOpen, setFeedbackFormOpen] = useState<boolean>(false);

  const [inspectionInvoiceModalOpen, setInspectionInvoiceModalOpen] = useState(
    false
  );

  const [
    sendInspectionInvoiceModalOpen,
    setSendInspectionInvoiceModalOpen,
  ] = useState(false);

  const [
    cancelInspectionInvoiceModalOpen,
    setCancelInspectionInvoiceModalOpen,
  ] = useState(false);

  const getAssignmentById = useQuery(GET_TRADESMAN_ASSIGNMENT_BY_ID, {
    variables: {
      assignmentId,
    },
    fetchPolicy: "network-only",
  });

  const getReviewByAssignmentId = useQuery(GET_REVIEW, {
    variables: {
      assignmentId,
    },
    fetchPolicy: "network-only",
  });

  const getInspectionInvoice = useQuery(GET_ASSIGNMENT_INSPECTION_INVOICE, {
    variables: {
      assignmentId,
    },
  });

  const inspectionInvoice =
    getInspectionInvoice.data?.getInspectionInvoiceForAssignment;

  const [
    deleteAssignment,
    { loading: deleteAssignmentLoading, error: deleteAssignmentError },
  ] = useMutation(DELETE_ASSIGNMENT, {
    onCompleted: () => {
      refetchAssignments();
      history.push(path.replace(`assignments/:assignmentId`, ""));
    },
  });

  const [
    waiveInspectionFee,
    { loading: waiveInspectionFeeLoading, error: waiveInspectionFeeError },
  ] = useMutation(WAIVE_INSPECTION_FEE, {
    onCompleted: () => {
      getAssignmentById.refetch();
      setWaiveInspectionOpen(false);
    },
  });

  const [
    updateProgress,
    {
      loading: updateProgressLoading,
      error: updateProgressError,
      data: updateProgressData,
    },
  ] = useMutation(UPDATE_ASSIGNMENT_PROGRESS, {
    onCompleted: () => {
      getAssignmentById.refetch();
    },
    onError: () => {},
  });

  const data: TradesmanAssignment | undefined =
    getAssignmentById.data?.getTradesmanAssignmentById.data;

  const inspectionFeePaid =
    data?.inspectionPayment?.status === TransactionStatus.SUCCESS ||
    data?.inspectionWaived;

  const finalQuotePaid = data?.invoice?.hasAtLeastOnePayment;

  /**
   * Get the completion status of any valid labor payments if they exist
   */
  const laborAssignmentPayments = data?.assignmentPayments.filter(
    ({ type }) => type === AssignmentPaymentTypes.LABOR
  );

  const noActiveLaborPayments = laborAssignmentPayments?.every(
    ({ transfer: { status } }) =>
      status !== TransferStatus.PENDING &&
      status !== TransferStatus.AWAITING_APPROVAL
  );

  const materialsAssignmentPayments = data?.assignmentPayments.filter(
    ({ type }) => type === AssignmentPaymentTypes.MATERIALS
  );

  const noActiveMaterialsPayments = materialsAssignmentPayments?.every(
    ({ transfer: { status } }) =>
      status !== TransferStatus.PENDING &&
      status !== TransferStatus.AWAITING_APPROVAL
  );

  const { materials, labor } = calculateMaterialsAndLabor(data?.jobs || []);

  const checkAssignmentPaymentStatus = useQuery(
    CHECK_ASSIGNMENT_PAYMENT_STATUS,
    {
      variables: {
        assignmentId,
      },
    }
  );

  const [
    requestAssignmentPayment,
    requestAssignmentPaymentResponse,
  ] = useMutation(REQUEST_ASSIGNMENT_PAYMENT, {
    onCompleted: () => {
      getAssignmentById.refetch();
    },
  });

  const [
    sendInvoice,
    {
      loading: sendInvoiceLoading,
      data: sendInvoiceData,
      error: sendInvoiceError,
    },
  ] = useMutation(SEND_INVOICE, {
    onCompleted: () => {
      getInspectionInvoice.refetch();
      setConfirmBoxOpen(false);
      setSendInspectionInvoiceModalOpen(false);
    },
    onError: () => {
      setConfirmBoxOpen(false);
      setSendInspectionInvoiceModalOpen(false);
    },
  });

  const [
    cancelInvoice,
    {
      loading: cancelInvoiceLoading,
      data: cancelInvoiceData,
      error: cancelInvoiceError,
    },
  ] = useMutation(CANCEL_INVOICE, {
    onCompleted: () => {
      getInspectionInvoice.refetch();
      setCancelInspectionInvoiceModalOpen(false);
    },
    onError: () => {
      setCancelInspectionInvoiceModalOpen(false);
    },
  });

  useEffect(() => {
    if (
      requestAssignmentPaymentResponse.data?.initiateAssignmentPayment.status
    ) {
      checkAssignmentPaymentStatus.refetch();
    }
  });

  //stack similar jobs together
  const reducedJobs: {
    [x: string]: Job & { count: number };
  } = {};

  if (!data || getReviewByAssignmentId.loading) {
    return <h3>loading</h3>;
  }

  data.jobs.forEach((job) => {
    const identifier = job.task?.id || job.custom?.name || "";
    if (reducedJobs[identifier]) {
      reducedJobs[identifier].count++;
    } else {
      reducedJobs[identifier] = { ...job, count: 1 };
    }
  });

  const { location } = data.jobs[0].contact;

  let progressIndex = 0;

  const setProgressOptions = [
    {
      key: "notStarted",
      value: JobProgress.NOT_STARTED,
      text: "Not Started",
    },
    {
      key: "preInspection",
      value: JobProgress.PRE_INSPECTION,
      text: "Pro Inspection",
    },
    {
      key: "finalQuote",
      value: JobProgress.FINAL_QUOTE,
      text: "Final Quote",
    },
    {
      key: "materials",
      value: JobProgress.MATERIALS_PURCHASED,
      text: "Materials Purchased",
    },
    {
      key: "clientInspection",
      value: JobProgress.CLIENT_INSPECTION,
      text: "Client Inspection",
    },
    {
      key: "completed",
      value: JobProgress.COMPLETED,
      text: "Completed",
    },
  ];

  switch (data.progress) {
    case JobProgress.NOT_STARTED:
      progressIndex = 0;
      break;

    case JobProgress.PRE_INSPECTION:
      progressIndex = 1;
      break;

    case JobProgress.FINAL_QUOTE:
      progressIndex = 2;
      break;

    case JobProgress.MATERIALS_PURCHASED:
      progressIndex = 3;
      break;

    case JobProgress.CLIENT_INSPECTION:
      progressIndex = 4;
      break;

    case JobProgress.COMPLETED:
      progressIndex = 5;
      break;
    default:
      progressIndex = 0;
      break;
  }

  const handleFeedbackFormClose = () => {
    getReviewByAssignmentId.refetch();
  };

  const panes = [
    {
      title: "Checklists",
      render: () => (
        <div>
          <AssignmentChecklist
            {...data.checklists?.startInspection}
            type='Pre-Inspection'
          />

          <AssignmentChecklist {...data.checklists?.startJob} type='Pre-Job' />
        </div>
      ),
    },
  ];

  const inspectionAmount =
    inspectionInvoice?.inspectionAmount || data.inspectionPayment?.amount;

  return (
    <>
      <Modal
        size='tiny'
        open={inspectionInvoiceModalOpen}
        closeOnDimmerClick
        onClose={() => setInspectionInvoiceModalOpen(false)}
      >
        <Modal.Content>
          <CreateInspectionInvoice
            assignmentId={data.id}
            close={() => {
              getInspectionInvoice.refetch();
              setInspectionInvoiceModalOpen(false);
            }}
          />
        </Modal.Content>
      </Modal>

      {inspectionInvoice && (
        <Modal
          size='tiny'
          open={inspectionInvoiceModalOpen}
          closeOnDimmerClick
          onClose={() => setInspectionInvoiceModalOpen(false)}
        >
          <Modal.Content>
            <EditInspectionInvoice
              invoice={inspectionInvoice}
              close={() => {
                getInspectionInvoice.refetch();
                setInspectionInvoiceModalOpen(false);
              }}
            />
          </Modal.Content>
        </Modal>
      )}

      {inspectionInvoice && (
        <Confirmation
          loading={sendInvoiceLoading}
          prompt={
            inspectionInvoice.sent
              ? "This resends the invoice to the client"
              : "This action sends the invoice to the client"
          }
          onConfirm={() => {
            sendInvoice({
              variables: { invoiceId: inspectionInvoice.id },
            });
          }}
          onReject={() => {
            setSendInspectionInvoiceModalOpen(false);
          }}
          size='small'
          open={sendInspectionInvoiceModalOpen}
          additionalContent={<OrderInvoice invoice={inspectionInvoice} />}
        />
      )}

      {inspectionInvoice && (
        <Confirmation
          loading={cancelInvoiceLoading}
          prompt='This cancels the existing invoice'
          onConfirm={() => {
            cancelInvoice({
              variables: { invoiceId: inspectionInvoice.id },
            });
          }}
          onReject={() => {
            setCancelInspectionInvoiceModalOpen(false);
          }}
          open={cancelInspectionInvoiceModalOpen}
          size='small'
          additionalContent={<OrderInvoice invoice={inspectionInvoice} />}
        />
      )}
      {updateProgressError && (
        <Alert
          type='error'
          banner
          message={updateProgressError.message}
          closable
        ></Alert>
      )}
      {updateProgressData && (
        <Alert
          type='success'
          banner
          message='Progress changed'
          closable
        ></Alert>
      )}
      <Confirmation
        loading={waiveInspectionFeeLoading}
        prompt={"This action waives the inspection fee"}
        onReject={() => {
          setWaiveInspectionOpen(false);
        }}
        open={waiveInspectionOpen}
        onConfirm={() => {
          waiveInspectionFee({
            variables: {
              assignmentId,
            },
          });
        }}
      ></Confirmation>
      <Confirmation
        loading={deleteAssignmentLoading}
        prompt={"This action deletes the assignment"}
        onReject={() => {
          setConfirmBoxOpen(false);
        }}
        open={confirmBoxOpen}
        onConfirm={() => {
          deleteAssignment({
            variables: {
              assignmentId,
            },
          });
        }}
      ></Confirmation>
      <Modal
        open={feedbackFormOpen}
        closeOnDimmerClick
        onClose={() => {
          setFeedbackFormOpen(false);
        }}
        size='tiny'
      >
        <FeedbackForm
          onClose={handleFeedbackFormClose}
          assignmentId={assignmentId}
          setVisibility={setFeedbackFormOpen}
        />
      </Modal>
      <Modal
        onClose={() => {
          dispatch(closePaymentModal());
          getAssignmentById.refetch();
        }}
        open={currentAssignmentPayment.modalOpen}
      >
        <Modal.Content>
          <Modal.Description>
            <div className='m-viewAssignment__paymentModal'>
              <h4>
                paystack checkout link has been sent to the user via email.{" "}
                <Icon name='check circle outline'></Icon>
              </h4>
              <p>
                <br />
                <strong>Link Generated:</strong>{" "}
                {currentAssignmentPayment.paymentUrl}
              </p>
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>

      <Island
        actions={[
          <Button
            key={1}
            disabled={
              data.progress !== JobProgress.COMPLETED ||
              getReviewByAssignmentId.data
            }
            onClick={() => {
              setFeedbackFormOpen(true);
            }}
          >
            {getReviewByAssignmentId.data ? "Feedback Sent" : "Add Feedback"}
          </Button>,
        ]}
        header={<Trail root='Tradesman Assignment' />}
      >
        <div className='o-viewAssignment__steps'>
          <Steps size='small' current={progressIndex}>
            <Steps.Step title='Not Started'></Steps.Step>
            <Steps.Step title='Inspection'></Steps.Step>
            <Steps.Step title='Final Quote'></Steps.Step>
            <Steps.Step title='Materials Bought'></Steps.Step>
            <Steps.Step title='Client Inspection'></Steps.Step>
            <Steps.Step title='Complete'></Steps.Step>
          </Steps>
        </div>

        <Row>
          <Col span='16'>
            <div className='o-viewAssignment__wrapper'>
              <div className='o-viewAssignment__details'>
                <div className='m-viewAssignment__detailItem'>
                  <h5>Assigned Pros</h5>
                  <AssignedProList
                    assignmentId={assignmentId}
                    proList={[data.tradesman, ...data.otherPros]}
                    assignmentCategoryId={data.category.id}
                  />
                </div>
                <div className='m-viewAssignment__detailItem'>
                  <h5>Scheduled For</h5>
                  <p>{new Date(data.date).toDateString()}</p>
                </div>
                <div className='m-viewAssignment__detailItem'>
                  <h5>Location</h5>
                  <p>{`${location.area}, ${location.location}`}</p>
                </div>
              </div>

              <div className='o-orderDetail__information'>
                <div className='m-orderDetail__informationWrapper'>
                  <h4>Inspection Fee Information</h4>
                  <div className='m-orderDetail__informationField'>
                    <div className='m-orderDetail__informationFieldSection'>
                      <div className='iconTitle'>
                        <h5>
                          Inspection Payment (
                          {data.inspectionWaived
                            ? "Waived"
                            : inspectionAmount
                            ? formatAsNaira(inspectionAmount)
                            : "Not Set"}
                          )
                        </h5>
                        {data.inspectionPayment?.status ===
                          TransactionStatus.SUCCESS || data.inspectionWaived ? (
                          <Icon name='check circle' color='green' />
                        ) : (
                          <Icon name='remove circle' color='red' />
                        )}
                        {inspectionInvoice && (
                          <Button
                            type='link'
                            onClick={() => {
                              history.push("/invoices/" + inspectionInvoice.id);
                            }}
                          >
                            View Invoice
                          </Button>
                        )}
                      </div>

                      {data.inspectionPayment?.status !==
                        TransactionStatus.SUCCESS &&
                        !data.inspectionWaived && (
                          <>
                            {inspectionInvoice ? (
                              inspectionInvoice.sent ? (
                                <Button
                                  className='a-orderDetail__informationFieldButton'
                                  disabled={!inspectionInvoice.sent}
                                  loading={getInspectionInvoice.loading}
                                  variant='negative'
                                  onClick={() => {
                                    setCancelInspectionInvoiceModalOpen(true);
                                  }}
                                >
                                  Cancel Invoice
                                </Button>
                              ) : (
                                <Button
                                  className='a-orderDetail__informationFieldButton'
                                  disabled={inspectionInvoice.sent}
                                  loading={getInspectionInvoice.loading}
                                  onClick={() => {
                                    setInspectionInvoiceModalOpen(true);
                                  }}
                                >
                                  Edit Amount
                                </Button>
                              )
                            ) : (
                              <Button
                                className='a-orderDetail__informationFieldButton'
                                onClick={() => {
                                  setInspectionInvoiceModalOpen(true);
                                }}
                              >
                                Set Amount
                              </Button>
                            )}

                            {inspectionInvoice ? (
                              <Button
                                className='a-orderDetail__informationFieldButton'
                                variant='success'
                                disabled={!inspectionInvoice}
                                loading={getInspectionInvoice.loading}
                                onClick={() => {
                                  setSendInspectionInvoiceModalOpen(true);
                                }}
                              >
                                Send Invoice
                              </Button>
                            ) : (
                              <Button
                                className='a-orderDetail__informationFieldButton'
                                onClick={() => {
                                  setWaiveInspectionOpen(true);
                                }}
                              >
                                Waive
                              </Button>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <Tab
                menu={{ secondary: true, pointing: true }}
                onTabChange={(e, data) => {
                  if (typeof data.activeIndex === "number") {
                    setActiveTabIndex(data.activeIndex);
                  }
                }}
                panes={panes.map(({ render, title }) => ({
                  render,
                  menuItem: <Menu.Item key={title}>{title}</Menu.Item>,
                }))}
              />
            </div>
          </Col>
          <Col offset='1' span='7'>
            <div className='o-assignmentForm__selectedOverviewWrapper'>
              <div className='o-assignmentForm__selectedOverviewItems'>
                <h3>jobs selected</h3>
                {Object.values(reducedJobs).map(({ id, ...job }, index) => {
                  return (
                    <div
                      key={id}
                      className='m-assignmentForm__selectedOverviewItem'
                    >
                      <p>{job.custom?.name || job.task?.name}</p>
                      <p className='a-ssignmentForm__selectedOverviewItemCount'>
                        {`x ${job.count}`}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className='o-assignment__sidebarSection'>
                <h4>Set Progress</h4>
                <Dropdown
                  fluid
                  selection
                  loading={updateProgressLoading}
                  disabled={updateProgressLoading}
                  value={data.progress}
                  options={setProgressOptions}
                  onChange={(e, { value }) => {
                    updateProgress({
                      variables: {
                        assignmentId,
                        progress: value,
                      },
                    });
                  }}
                ></Dropdown>
              </div>
              <div className='o-assignmentForm__selectedOverviewActions'>
                <Button
                  disabled={
                    !inspectionFeePaid ||
                    checkAssignmentPaymentStatus.data
                      ?.checkAssignmentPaymentStatus.data.transportation
                  }
                  loading={
                    checkAssignmentPaymentStatus.loading ||
                    requestAssignmentPaymentResponse.loading
                  }
                  variant='basic'
                  type='inverted'
                  fullWidth
                  onClick={() => {
                    requestAssignmentPayment({
                      variables: {
                        data: {
                          assignmentId,
                          purpose: "TRANSPORTATION",
                        },
                      },
                    });
                  }}
                >
                  {checkAssignmentPaymentStatus.data
                    ?.checkAssignmentPaymentStatus.data.transportation
                    ? "Transportation Requested"
                    : "Pay Transportation"}
                </Button>
                {materials > 0 && (
                  <Button
                    disabled={
                      !inspectionFeePaid ||
                      !finalQuotePaid ||
                      !noActiveMaterialsPayments
                    }
                    variant='basic'
                    type='inverted'
                    fullWidth
                    loading={requestAssignmentPaymentResponse.loading}
                    onClick={() => {
                      requestAssignmentPayment({
                        variables: {
                          data: {
                            assignmentId,
                            purpose: "MATERIALS",
                          },
                        },
                      });
                    }}
                  >
                    {noActiveMaterialsPayments
                      ? "Pay Materials"
                      : "Materials Requested"}
                  </Button>
                )}
                {labor > 0 && (
                  <Button
                    disabled={
                      !inspectionFeePaid ||
                      !finalQuotePaid ||
                      !noActiveLaborPayments
                    }
                    variant='basic'
                    type='inverted'
                    fullWidth
                    loading={requestAssignmentPaymentResponse.loading}
                    onClick={() => {
                      requestAssignmentPayment({
                        variables: {
                          data: {
                            assignmentId,
                            purpose: "LABOR",
                          },
                        },
                      });
                    }}
                  >
                    {noActiveLaborPayments ? "Pay Labor" : "Labor Requested"}
                  </Button>
                )}
                <Button
                  disabled={requestStatus === "pending"}
                  fullWidth
                  variant='negative'
                  loading={requestStatus === "pending"}
                  onClick={() => {
                    setConfirmBoxOpen(true);
                  }}
                >
                  Delete Assignment
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Island>
    </>
  );
};
