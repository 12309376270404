import React, { FC } from "react";

export interface ViewBusinessFieldProps {
  label: string | JSX.Element;
  value: string | JSX.Element;
}

export const ViewBusinessField: FC<ViewBusinessFieldProps> = ({
  label,
  value,
}) => {
  return (
    <div className='grid grid-cols-[1fr_2fr] w-full gap-x-4 mb-2'>
      {typeof label === "string" ? (
        <p className=' text-primary-300 p-0 m-0'>{label}</p>
      ) : (
        label
      )}
      {typeof value === "string" ? (
        <p className='text-primary-500 font-semibold p-0 m-0'>{value}</p>
      ) : (
        value
      )}
    </div>
  );
};
