import React, { FC } from "react";

import styles from "./styles.module.scss";

export interface FieldProps {
  label: string;
  value: string | JSX.Element;
}

export const Field: FC<FieldProps> = ({ label, value }) => {
  return (
    <div className={styles.field}>
      <p className={styles.label}>{label}</p>
      {typeof value === "string" ? (
        <p className='text-primary-500 font-semibold text-right p-0 m-0'>
          {value}
        </p>
      ) : (
        value
      )}
    </div>
  );
};
