import React from "react";

import { Pro } from "../../types";
import Avatar from "../../_components/Avatar";
import { Icon, Popup, Button } from "semantic-ui-react";
import CustomButton from "@laborhack/custom-button";

export interface MatchedProProps {
  pro: Pick<Pro, "firstName" | "lastName" | "imageUrl">;
  matched?: boolean;
  completed?: boolean;
  onRemove?: () => void;
  onReplace?: () => void;
  loading?: boolean;
  hideReplaceButton?: boolean;
}

export const MatchedPro = ({
  pro,
  matched,
  completed,
  onRemove,
  onReplace,
  loading,
  hideReplaceButton,
}: MatchedProProps) => {
  const canRemove = !matched;

  const canReplace = matched && !completed;

  return (
    <div className='flex items-center gap-2' data-testid='matched-pro'>
      <div className='flex items-center gap-1'>
        <Avatar src={pro.imageUrl}>{pro.firstName?.substring(0, 1)}</Avatar>
        <p className='text-primary-500'>
          {pro.firstName} {pro.lastName}
        </p>
      </div>
      <Button.Group>
       {!hideReplaceButton && <Popup
          content='Replace pro'
          trigger={
            <Button
              data-testid='replace-pro'
              disabled={!canReplace}
              loading={loading}
              icon
              onClick={onReplace}
            >
              <Icon name='exchange' />
            </Button>
          }
        /> }
        <Popup
          hoverable
          trigger={
            <Button
              data-testid='remove-pro'
              disabled={!canRemove}
              loading={loading}
              icon
              negative
            >
              <Icon name='remove' />
            </Button>
          }
        >
          <div>
            <p>
              This action would remove{" "}
              <strong>
                {pro.firstName} {pro.lastName}
              </strong>{" "}
              from the job. Are you sure?
            </p>

            <CustomButton type='inverted' onClick={onRemove}>
              Yes, I understand
            </CustomButton>
          </div>
        </Popup>
      </Button.Group>
    </div>
  );
};
