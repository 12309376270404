import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, Heading, Icon, Stack, HStack, InputGroup, InputLeftAddon, FormErrorMessage } from '@chakra-ui/react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_BACKGROUND_CHECK_BY_PRO_ID, CREATE_BACKGROUND_CHECK_FOR_PRO } from './gql';
import { Guarantor, Referee } from './types';
import Loading from '@/_components/Loading';
import RequestFailed from '@/_components/RequestFailed';
import Island from '@/_components/Island';
import { Link, useParams } from 'react-router-dom';
import { BiLeftArrowAlt } from 'react-icons/bi';

interface FormData {
  referees: Referee[];
  guarantors: Guarantor[];
}

const BackgroundCheck: React.FC = () => {
  const params = useParams<{ proId: string }>();
  const { proId } = params;
  const linkToProDetails = `/pros/${proId}/details`;

  const [formData, setFormData] = useState<FormData>({
    referees: [{ companyAddress: '', companyName: '', email: '', jobTitle: '', name: '', phoneNumber: '', relationship: '' }],
    guarantors: [{ companyAddress: '', companyName: '', email: '', jobTitle: '', name: '', phoneNumber: '', relationship: '' }]
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    section: keyof FormData,
    index: number,
    field: keyof Referee | keyof Guarantor
  ) => {
    const newFormData = { ...formData };
    newFormData[section][index][field] = e.target.value;
    setFormData(newFormData);
  };

  const { loading, error, data } = useQuery(GET_BACKGROUND_CHECK_BY_PRO_ID, {
    variables: { proId }
  });

  const [
    createBackgroundCheckForPro, { loading: createLoading, error: createError, reset: resetCreation}
  ] = useMutation(CREATE_BACKGROUND_CHECK_FOR_PRO, {
    onCompleted: () => {
      window.open(linkToProDetails, '_self');
    }
  });

  useEffect(() => {
    if (data && data.getBackgroundCheckByProId) {
      setFormData(data.getBackgroundCheckByProId);
    }
  }, [data]);

  const formatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/\D/g, '').replace(/^234/, '');
  };

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    formData.referees.forEach((referee, index) => {
      Object.entries(referee).forEach(([key, value]) => {
        if (key === 'email' && value && !RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(value)) {
          newErrors[`referees-${index}-${key}`] = 'Please put in a valid email';
        } else if (key === 'phoneNumber' && value && !RegExp(/^\d{10}$/).test(formatPhoneNumber(value))) {
          newErrors[`referees-${index}-${key}`] = 'Please put in a valid phone number';
        } else if (!value) {
          newErrors[`referees-${index}-${key}`] = `Please put in a valid ${key}`;
        }
      });
    });
    formData.guarantors.forEach((guarantor, index) => {
      Object.entries(guarantor).forEach(([key, value]) => {
        if (key === 'email' && value && !RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(value)) {
          newErrors[`guarantors-${index}-${key}`] = 'Please put in a valid email';
        } else if (key === 'phoneNumber' && value && !RegExp(/^\d{10}$/).test(formatPhoneNumber(value))) {
          newErrors[`guarantors-${index}-${key}`] = 'Please put in a valid phone number';
        } else if (!value) {
          newErrors[`guarantors-${index}-${key}`] = `Please put in a valid ${key}`;
        }
      });
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const removeTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);

    const sanitizedFormData = {
      referees: formData.referees.map((referee: Referee) => {
        const formattedReferee = {
          ...referee,
          phoneNumber: `+234${formatPhoneNumber(referee.phoneNumber!)}`
        };
        return JSON.parse(JSON.stringify(formattedReferee), removeTypename);
      }),
      guarantors: formData.guarantors.map(guarantor => {
        const formattedGuarantor = {
          ...guarantor,
          phoneNumber: `+234${formatPhoneNumber(guarantor.phoneNumber!)}`
        };
        return JSON.parse(JSON.stringify(formattedGuarantor), removeTypename);
      })
    };

    await createBackgroundCheckForPro({
      variables: {
        proId,
        payload: sanitizedFormData
      }
    });
  };

  if (loading) return <Loading />;
  if (error) return <RequestFailed />;
  if (createError) return <RequestFailed  onRetry={() => {resetCreation()}}/>;

  const refereesAndGuarantorsAreComplete = formData.referees.every((referee) => Object.values(referee).every(Boolean))
    && formData.guarantors.every((guarantor) => Object.values(guarantor).every(Boolean));

  return (
    <>
      <Stack direction='row' align='center' justify='space-between' mb={4}>
        <Button as={Link} to={linkToProDetails} leftIcon={<Icon as={BiLeftArrowAlt} />}>
          Back
        </Button>
      </Stack>
      <Island>
        <Box>
          <form onSubmit={handleSubmit}>
            <Stack spacing={12}>
              <HStack align="start" spacing={8}>
                <VStack spacing={8} align="start" flex="1">
                  <Heading as="h4" fontSize={'xl'} color={'#003952'}>
                    Pros Reference Collection Section
                  </Heading>
                </VStack>
                <VStack spacing={8} flex="2">
                  <VStack spacing={4} align="stretch" w={'50%'}>
                    {formData.referees.map((referee, index) => (
                      <VStack key={index} spacing={4}>
                        <FormControl isRequired isInvalid={!!errors[`referees-${index}-name`]}>
                          <FormLabel>Reference Full Name</FormLabel>
                          <Input
                            value={referee.name}
                            onChange={(e) => handleChange(e, 'referees', index, 'name')}
                            placeholder="John Doe"
                          />
                          <FormErrorMessage>{errors[`referees-${index}-name`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`referees-${index}-phoneNumber`]}>
                          <FormLabel>Reference Phone Number</FormLabel>
                          <InputGroup>
                            <InputLeftAddon>+234</InputLeftAddon>
                            <Input
                              value={formatPhoneNumber(referee.phoneNumber || '')}
                              onChange={(e) => handleChange(e, 'referees', index, 'phoneNumber')}
                              placeholder="9000000000"
                            />
                          </InputGroup>
                          <FormErrorMessage>{errors[`referees-${index}-phoneNumber`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`referees-${index}-companyAddress`]}>
                          <FormLabel>Reference Work Address</FormLabel>
                          <Input
                            value={referee.companyAddress}
                            onChange={(e) => handleChange(e, 'referees', index, 'companyAddress')}
                            placeholder="123 Main St"
                          />
                          <FormErrorMessage>{errors[`referees-${index}-companyAddress`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`referees-${index}-email`]}>
                          <FormLabel>Reference Email Address</FormLabel>
                          <Input
                            value={referee.email}
                            onChange={(e) => handleChange(e, 'referees', index, 'email')}
                            placeholder="example@example.com"
                          />
                          <FormErrorMessage>{errors[`referees-${index}-email`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`referees-${index}-companyName`]}>
                          <FormLabel>Reference Company</FormLabel>
                          <Input
                            value={referee.companyName}
                            onChange={(e) => handleChange(e, 'referees', index, 'companyName')}
                            placeholder="ABC Corp"
                          />
                          <FormErrorMessage>{errors[`referees-${index}-companyName`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`referees-${index}-jobTitle`]}>
                          <FormLabel>Reference's Title in Company</FormLabel>
                          <Input
                            value={referee.jobTitle}
                            onChange={(e) => handleChange(e, 'referees', index, 'jobTitle')}
                            placeholder="Manager"
                          />
                          <FormErrorMessage>{errors[`referees-${index}-jobTitle`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`referees-${index}-relationship`]}>
                          <FormLabel>Relationship with Reference</FormLabel>
                          <Input
                            value={referee.relationship}
                            onChange={(e) => handleChange(e, 'referees', index, 'relationship')}
                            placeholder="Colleague"
                          />
                          <FormErrorMessage>{errors[`referees-${index}-relationship`]}</FormErrorMessage>
                        </FormControl>
                      </VStack>
                    ))}
                  </VStack>
                </VStack>
              </HStack>
              <HStack align="start" spacing={8}>
                <VStack spacing={8} align="start" flex="1">
                  <Heading as="h4" fontSize={'xl'} color={'#003952'}>
                    Pros Guarantor's Collection Section
                  </Heading>
                </VStack>
                <VStack spacing={8} flex="2">
                  <VStack spacing={4} align="stretch" w={'50%'}>
                    {formData.guarantors.map((guarantor, index) => (
                      <VStack key={index} spacing={4}>
                        <FormControl isRequired isInvalid={!!errors[`guarantors-${index}-name`]}>
                          <FormLabel>Guarantor Name</FormLabel>
                          <Input
                            value={guarantor.name}
                            onChange={(e) => handleChange(e, 'guarantors', index, 'name')}
                            placeholder="Jane Doe"
                          />
                          <FormErrorMessage>{errors[`guarantors-${index}-name`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`guarantors-${index}-phoneNumber`]}>
                          <FormLabel>Guarantor Phone Number</FormLabel>
                          <InputGroup>
                            <InputLeftAddon>+234</InputLeftAddon>
                            <Input
                              value={formatPhoneNumber(guarantor.phoneNumber || '')}
                              onChange={(e) => handleChange(e, 'guarantors', index, 'phoneNumber')}
                              placeholder="9000000000"
                            />
                          </InputGroup>
                          <FormErrorMessage>{errors[`guarantors-${index}-phoneNumber`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`guarantors-${index}-companyAddress`]}>
                          <FormLabel>Guarantor Work Address</FormLabel>
                          <Input
                            value={guarantor.companyAddress}
                            onChange={(e) => handleChange(e, 'guarantors', index, 'companyAddress')}
                            placeholder="123 Main St"
                          />
                          <FormErrorMessage>{errors[`guarantors-${index}-companyAddress`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`guarantors-${index}-email`]}>
                          <FormLabel>Guarantor Email Address</FormLabel>
                          <Input
                            value={guarantor.email}
                            onChange={(e) => handleChange(e, 'guarantors', index, 'email')}
                            placeholder="example@example.com"
                          />
                          <FormErrorMessage>{errors[`guarantors-${index}-email`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`guarantors-${index}-companyName`]}>
                          <FormLabel>Guarantor's Company</FormLabel>
                          <Input
                            value={guarantor.companyName}
                            onChange={(e) => handleChange(e, 'guarantors', index, 'companyName')}
                            placeholder="ABC Corp"
                          />
                          <FormErrorMessage>{errors[`guarantors-${index}-companyName`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`guarantors-${index}-jobTitle`]}>
                          <FormLabel>Guarantor's Title at Company</FormLabel>
                          <Input
                            value={guarantor.jobTitle}
                            onChange={(e) => handleChange(e, 'guarantors', index, 'jobTitle')}
                            placeholder="Manager"
                          />
                          <FormErrorMessage>{errors[`guarantors-${index}-jobTitle`]}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors[`guarantors-${index}-relationship`]}>
                          <FormLabel>Relationship with Guarantor</FormLabel>
                          <Input
                            value={guarantor.relationship}
                            onChange={(e) => handleChange(e, 'guarantors', index, 'relationship')}
                            placeholder="Colleague"
                          />
                          <FormErrorMessage>{errors[`guarantors-${index}-relationship`]}</FormErrorMessage>
                        </FormControl>
                      </VStack>
                    ))}
                  </VStack>
                  <Stack w={'50%'}>
                    <Button
                      isLoading={createLoading}
                      colorScheme={'green'}
                      disabled={!refereesAndGuarantorsAreComplete}
                      type="submit" mt={8}>
                      Save
                    </Button>
                  </Stack>
                </VStack>
              </HStack>
            </Stack>
          </form>
        </Box>
      </Island>
    </>
  );
};

export default BackgroundCheck;
