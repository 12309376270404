import React, { FC, useContext, useMemo } from "react";
import {
  ContractDetails,
  ContractQuotationItem,
} from "../../../../../../../types";
import { SingleQuotationContext } from "../../../../context/single-quotation.context";
import { SummaryItem } from "./SummaryItem";

import styles from "./styles.module.scss";

export const Summary: FC = () => {
  const { items } = useContext(SingleQuotationContext);

  const { groupedItems } = useMemo(() => {
    const groupItemsByCategoryLevelRateWorkdaysAndContract: Record<
      string,
      ContractQuotationItem
    > = {};

    const activeItems = Object.values(items).filter((item) => item.isActive);

    for (const item of activeItems) {
      const {
        categoryId,
        proCount,
        level,
        rate,
        contractDetails,
        workdays,
      } = item as Required<ContractQuotationItem>;

      const key = `${categoryId}-${level}-${rate}-${contractDetails.length}-${contractDetails.interval}-${workdays}`;

      if (!groupItemsByCategoryLevelRateWorkdaysAndContract[key]) {
        groupItemsByCategoryLevelRateWorkdaysAndContract[key] = { ...item };
      } else {
        groupItemsByCategoryLevelRateWorkdaysAndContract[
          key
        ].proCount += proCount;
      }
    }

    /**
     * Group items based on contract details.
     */

    const groupedItems = Object.values(
      groupItemsByCategoryLevelRateWorkdaysAndContract
    ).reduce((acc, item) => {
      const key = `${item.contractDetails.length}-${item.contractDetails.interval}-${item.workdays}`;

      if (!acc[key]) {
        acc[key] = {
          contractDetails: item.contractDetails,
          workdays: item.workdays,
          items: [],
        };
      }

      acc[key].items.push(item);

      return acc;
    }, {} as Record<string, { contractDetails: ContractDetails; workdays: number; items: ContractQuotationItem[] }>);

    return { groupedItems };
  }, [items]);

  return (
    <div className={styles.wrapper}>
      {Object.entries(groupedItems).map(
        ([key, { contractDetails, workdays, items }]) => (
          <div key={key} className='mt-8'>
            <h4 className='font-bold text-primary-500 text-lg'>
              {`${
                contractDetails.length
              } ${contractDetails.interval.toLowerCase()}`}
              <span className='text-primary-300 font-normal italic'>{`${
                contractDetails.interval !== "DAYS"
                  ? ` - ${workdays} work days per week`
                  : ""
              }`}</span>
            </h4>
            <div className='pl-4'>
              {items.map((item) => (
                <SummaryItem
                  key={item.categoryId + item.level + item.proCount}
                  {...item}
                />
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};
