import React, { FC } from "react";
import { Icon } from "semantic-ui-react";

export interface CreateBusinessClientCardProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  onRemove: () => void;
}

export const CreateBusinessClientCard: FC<CreateBusinessClientCardProps> = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  onRemove,
}) => {
  return (
    <div className='p-4 grid grid-cols-[1fr_24px] items-start border rounded-lg border-primary-150 mb-2 last:mb-0'>
      <div>
        <p className='text-xl font-bold text-primary-500 mb-4'>{`${firstName} ${lastName}`}</p>
        <p className='text-sm text-primary-300'>{email}</p>
        {phoneNumber && (
          <p className='text-sm text-primary-300'>{phoneNumber}</p>
        )}
      </div>
      <div>
        <Icon
          className='cursor-pointer'
          onClick={onRemove}
          color='red'
          name='trash'
        />
      </div>
    </div>
  );
};
