import { Role, TransferStatus, WorkingDays } from "./types";

export const allRoles: {
	[Property in Role]: boolean;
} = {
	[Role.ROOT]: true,
	[Role.USER_ADMINISTRATOR]: true,
	[Role.FINANCE]: true,
	[Role.PROJECTMANAGER]: true,
	[Role.BUSINESS_DEVELOPER]: true,
	[Role.CUSTOMERSERVICE]: true,
	[Role.SYSTEM_ADMINISTRATOR]: false, // disable access to admin dashboard for users with only system admin,
	[Role.CERTIFICATION_ADMINISTRATOR]: true,
	[Role.PRO_ADMINISTRATOR]: true,
	[Role.OUTREACH]: true,
};

export const allWorkingDays: WorkingDays[] = [
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
	"Sunday",
];

export const getTransferBadgeColor = (status: TransferStatus) => {
	const handlers: Record<TransferStatus, string> = {
		[TransferStatus.CONFIRMED]: "green",
		[TransferStatus.FAILED]: "red",
		[TransferStatus.PENDING]: "yellow",
		[TransferStatus.AWAITING_APPROVAL]: "yellow",
		[TransferStatus.QUEUED]: "blue",
		[TransferStatus.REVERSED]: "red",
	};

	return handlers[status] || "gray";
};