import { gql, useQuery } from "@apollo/client";
import { ColumnsType } from "antd/lib/table";
import { format } from "date-fns";
import { Link, useRouteMatch } from "react-router-dom";
import Button from "@laborhack/custom-button";
import { components as LayoutComponents } from "../../components/layout";
import {
  IdentityVerification,
  IdentityVerificationStatus,
  Pro,
} from "../../types";
import Loading from "../../_components/Loading";
import Pill from "../../_components/Pill";
import RequestFailed from "../../_components/RequestFailed";
import { idStatusToPillClassName } from "./constants";

const { CustomTable } = LayoutComponents;

const GET_IDENTITY_VERIFICATIONS = gql`
  query GetIdentityVerifications($proId: String!) {
    identityVerificationsForPro(proId: $proId) {
      id
      identificationNumber
      type
      issues {
        message
      }
      dateOfBirth
      photo
      identificationDocument
      status
      createdAt
    }
  }
`;

export const IdentityVerificationList = ({
  pro: { id, identityVerification },
}: {
  pro: Pro;
}) => {
  const { data, loading, error } = useQuery<{
    identityVerificationsForPro: IdentityVerification[];
  }>(GET_IDENTITY_VERIFICATIONS, {
    variables: { proId: id },
  });

  const columns: ColumnsType<IdentityVerification & { key: string }> = [
    {
      title: "Date Submitted",
      render: (text, record) => {
        return <p>{format(new Date(record.createdAt), "PP")}</p>;
      },
      width: "10%",
    },
    {
      title: "ID Type",
      render: (text, record) => {
        return <p>{record.type}</p>;
      },
      width: "15%",
    },
    {
      title: "ID Number",
      render: (text, record) => {
        return <p>{record.identificationNumber}</p>;
      },
      width: "15%",
    },
    {
      title: "Status",
      render: (text, record) => {
        return (
          <Pill className={idStatusToPillClassName[record.status]}>
            {record.status || "NOT SUBMITTED"}
          </Pill>
        );
      },
      width: "15%",
    },
    {
      title: "Date of Birth",
      render: (text, record) => {
        return <p>{format(new Date(record.dateOfBirth), "PP")}</p>;
      },
      width: "15%",
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <Link to={`/pros/${id}/identity-verification/${record.id}`}>
            View
          </Link>
        );
      },
      width: "10%",
    },
  ];

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <RequestFailed />;
  }

  return (
    <>
      <div className='flex justify-end my-4'>
        {identityVerification?.status !==
          IdentityVerificationStatus.VERIFIED && (
          <Link to={`/pros/${id}/identity-verification/create`}>
            <Button>Create ID Verification</Button>
          </Link>
        )}
      </div>
      <CustomTable
        onRow={(record: any, index: any) => {
          return {};
        }}
        columns={columns}
        data={
          data?.identityVerificationsForPro.map((item) => {
            return {
              key: item.id,
              ...item,
            };
          }) || []
        }
        loading={loading}
        handleTableChange={() => {}}
      />
    </>
  );
};
