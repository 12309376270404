import { gql } from "@apollo/client";

export const MARKETPLACE_JOB = gql`
  query MarketplaceJob($id: String!) {
    marketplaceJob(id: $id) {
      id
      request {
        matchingFeeInvoice {
          isFullyPaid
          hasAtLeastOnePayment
          payment {
            status
          }
        }
      }
      trade {
        name
        category {
          name
          id
        }
      }
      schedule {
        start
        end
        workDays
      }
      proCount
      description
      pros {
        id
        firstName
        lastName
        imageUrl
      }
      shortlist {
        id
        shortlistedPros {
          pro {
            id
            firstName
            lastName
            email
            phoneNumber
          }
          status
        }
      }
    }
  }
`;
