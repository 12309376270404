import React from "react";
import Button from "@laborhack/custom-button";
import { Image, Modal } from "semantic-ui-react";
import { gql, useApolloClient } from "@apollo/client";
import RequestFailed from "../RequestFailed";

const GET_PRE_SIGNED_URL = gql`
  query GetPreSignedUrl($path: String!, $fileExtension: String!) {
    getProApplicationUploadPresignedUrl(
      path: $path
      fileExtension: $fileExtension
    )
  }
`;

export const onSelectedFile = (
  event: any,
  setImage: (image: string) => void
) => {
  const file = event.target.files[0];

  const reader = new FileReader();
  reader.onload = (e) => {
    setImage(e.target?.result as string);
  };
  reader.readAsDataURL(file);
};

export interface ImageFieldProps {
  label: string;
  value: string;
  editable?: boolean;
  path?: string;
  onSave?: (value: string) => void;
  saving?: boolean;
}

export interface NonEditableImageFieldProps extends ImageFieldProps {
  editable: false;
}

export interface EditableImageFieldProps extends ImageFieldProps {
  onSave: (value: string) => void;
  path: string;
  editable: true;
}

const ImageField = ({
  label,
  value,
  editable,
  onSave,
  saving,
  path,
}: EditableImageFieldProps | NonEditableImageFieldProps) => {
  const client = useApolloClient();

  const [editMode, setEditMode] = React.useState(false);
  const [file, setFile] = React.useState<File>();
  const [image, setImage] = React.useState<string>();
  const [uploading, setUploading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const getPresignedUrl = async (fileExtension: string) => {
    const { data } = await client.query({
      query: GET_PRE_SIGNED_URL,
      variables: {
        path,
        fileExtension,
      },
    });

    return data.getProApplicationUploadPresignedUrl;
  };

  const handleSelectImage = (event: any) => {
    onSelectedFile(event, setImage);
    setFile(event.target.files[0]);
  };

  const handleClose = () => {
    setFile(undefined);
    setImage(undefined);
    setEditMode(false);
  };

  const uploadImage = async () => {
    if (!editable) {
      return;
    }

    setUploading(true);

    try {
      /**
       * Get presigned S3 url from server
       */

      if (!file) {
        throw new Error("No file selected");
      }

      const fileExtension = file.name.split(".").pop();

      if (!fileExtension) {
        throw new Error("No file extension");
      }

      const presignedUrl = await getPresignedUrl(fileExtension);

      /**
       * Upload image to S3
       *
       */

      const response = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
      });

      if (response.status !== 200) {
        throw new Error("Failed to upload image");
      }

      /**
       * Set image url
       */
      const imageUrl = presignedUrl.split("?")[0];

      onSave(imageUrl);
    } catch (error) {
      setError((error as Error).message);
    }

    setUploading(false);
    handleClose();
  };

  if (error) {
    return (
      <RequestFailed
        errorMessage={error}
        onRetry={() => {
          setError(undefined);
        }}
      />
    );
  }

  return (
    <>
      <Modal open={editMode} onClose={handleClose} closeOnDimmerClick={false}>
        <Modal.Header>Upload Image</Modal.Header>
        <Modal.Content>
          {image ? (
            <Image src={image} size='medium' />
          ) : (
            <input
              type='file'
              name='image'
              id='image'
              onChange={handleSelectImage}
            />
          )}
        </Modal.Content>
        <div className='flex gap-4 items-center justify-end p-6'>
          <Button type='link' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            loading={uploading || saving}
            disabled={!image}
            type='subtle'
            variant='success'
            onClick={() => {
              uploadImage();
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
      <div className='flex flex-col gap-1'>
        <p className='font-semibold text-sm text-primary-300 m-0 p-0'>
          {label}
        </p>

        <div className='flex items-center gap-2'>
          <Image
            src={value}
            as='a'
            size='medium'
            href={value}
            target='_blank'
          />
          {editable && (
            <Button
              type='subtle'
              variant='basic'
              onClick={() => {
                setEditMode(true);
              }}
              loading={saving}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageField;
