import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Modal,
  Icon,
  Grid,
  Header,
  Message,
  Checkbox,
} from "semantic-ui-react";
import {
  setDTitle,
  setDCategoryId,
  setDName,
  setDImage,
  setDIsVisible,
  setDStatus,
  selectIsVisible,
  selectSuccessMessage,
  selectErrorMessage,
  selectSubmissionStatus,
  editCategoryForm,
  setErrorMessage,
  setSuccessMessage,
} from "../../../redux/editCategory.slice";

import { CatServiceSelectors } from "./../../../redux/category-service.slice";
import { useMutation } from "@apollo/client";
import { EDIT_CATEGORY } from "./../../../graphql/query";
import { IFileName } from "./../../category/types";
import { useSelector, useDispatch } from "react-redux";

const { Field } = Form;

export const EditCategoryModal: FC<{
  categoryId: string;
}> = ({ categoryId, ...rest }) => {
  const { selectCategories } = CatServiceSelectors;

  const allCategory = useSelector(selectCategories);
  const submissionStatus = useSelector(selectSubmissionStatus);
  const successMessage = useSelector(selectSuccessMessage);
  const formErrorMessage = useSelector(selectErrorMessage);

  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const isVisible = useSelector(selectIsVisible);

  const [editForm] = useMutation(EDIT_CATEGORY);
  const dispatch = useDispatch();
  const loadData = () => {
    const category = allCategory[categoryId];

    const { name, imageUrl, proTitle, isVisible } = category;

    setName(name);
    dispatch(setDName(name));
    proTitle && setTitle(proTitle);
    dispatch(setDIsVisible(isVisible));
    dispatch(setDTitle(proTitle));
    dispatch(setDImage(imageUrl));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
    dispatch(setDCategoryId(categoryId));
  };
  const clearMessage = () => {
    dispatch(setErrorMessage(""));
    dispatch(setSuccessMessage(""));
  };

  const close = () => {
    setName("");
    setTitle("");
    setImage("");
    setOpen(false);
  };

  const uploadHandler = async () => {
    const fileData = new FormData();
    const image_file: any = image;
    const trimFileName = (file: IFileName) =>
      file.name.replace(new RegExp(/[^0-9a-zA-Z.]/g), "_");
    fileData.append("file", image_file, trimFileName(image_file));
    fileData.append("upload_preset", "j6ph0b2u");
    const APIURL = "https://api.cloudinary.com/v1_1/nath/image/upload";
    await axios.post(APIURL, fileData).then((res) => {
      const image_url = res.data.secure_url;

      dispatch(setDStatus("pending"));
      dispatch(setDImage(image_url));
    });
  };

  useEffect(() => {
    if (successMessage !== "") {
      setTimeout(() => {
        setOpen(false);
        window.location.reload();
      }, 1000);
    }
  }, [successMessage]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size='mini'
      trigger={
        <Button
          color='blue'
          icon
          labelPosition='right'
          size='tiny'
          onClick={() => {
            setOpen(true);
            loadData();
          }}
          {...rest}
        >
          <Icon name='pencil alternate'></Icon>
          Edit
        </Button>
      }
    >
      <Modal.Header>Edit Category</Modal.Header>
      <Modal.Content>
        <Grid centered>
          <Grid.Column width='16'>
            {formErrorMessage !== "" && (
              <Message error header='Error' content={formErrorMessage} />
            )}
            {successMessage !== "" && (
              <Message
                success
                header='Update Successful'
                content={successMessage}
              />
            )}
            <Form>
              <Field>
                <Header as='h3'>Edit {name}</Header>

                <label>Category Name</label>
                <Input
                  placeholder='Enter new name'
                  type='text'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onFocus={() => {
                    clearMessage();
                  }}
                  onBlur={() => {
                    dispatch(setDName(name));
                  }}
                />

                <label>Title</label>
                <Input
                  placeholder='Enter title'
                  type='text'
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  onFocus={() => {
                    clearMessage();
                  }}
                  onBlur={() => {
                    dispatch(setDTitle(title));
                  }}
                />
                <Checkbox
                  className='mt-4'
                  toggle
                  label='Visible'
                  checked={isVisible}
                  onChange={() => {
                    dispatch(setDIsVisible(!isVisible));
                  }}
                />
              </Field>
              <div>
                <Form.Input label='Upload Image (200px by 134px)'>
                  <input
                    width={5}
                    type='file'
                    name='image'
                    id=''
                    onChange={(e) => {
                      const [file]: any = e.target.files;
                      setImage(file);
                    }}
                  />
                </Form.Input>
              </div>
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='red'
          inverted
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>
        {submissionStatus === "idle" ? (
          <Button
            primary
            onClick={(e) => {
              if (image === "") {
                dispatch(editCategoryForm({ mutation: editForm }));
              } else {
                uploadHandler().then(() => {
                  dispatch(editCategoryForm({ mutation: editForm }));
                });
              }
            }}
          >
            Submit
          </Button>
        ) : (
          <Button loading primary>
            Loading
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
