import gql from "graphql-tag";

export const GENERATE_INSPECTION_INVOICE = gql`
  query generate($orderId: String!, $amount: Float!) {
    generateNewInspectionInvoice(orderId: $orderId, amount: $amount) {
      type
      inspectionAmount
      invoiceDate
      discount
      options {
        materialCommissionCap
      }
      assignments {
        id
        progress
        order {
          id
          orderCode
          inspectionPayment {
            status
            amount
          }
          locationSchedules {
            locationId
            scheduledDate
          }
          isEmergency
        }
        jobs {
          id
          jobCode
          custom {
            name
            description
            resourceUrl
            subCategoryId
            categoryId
          }
          task {
            id
            name
            description
            estimatedCost
            categoryId
            subCategoryId
            imageUrl
          }
          finalCost
          status
          completedAt
          payment {
            transactionReference
            transactionDate
            amount
            status
          }
          contact {
            notes
            location {
              id
              buildingNumber
              streetName
              area
              location
              landmark
              city
            }
          }
          invoiceDetails {
            materials {
              name
              unitPrice
              quantity
            }
            labor
          }
        }
        date
      }
      recipient {
        name
        email
        phoneNumber
        address
      }
      payment {
        id
        transactionDate
        transactionReference
        status
      }
    }
  }
`;

export const PAYMENT_PROVIDERS = gql`
  query PaymentProviders {
    paymentProviders {
      id
      name
      channel
    }
  }
`;
