import { gql, useQuery } from "@apollo/client";
import React, { FC } from "react";
import Button from "@laborhack/custom-button";

import { SectionLoading } from "../../../_components/Loading/SectionLoading";
import { AccountActivation, Business } from "../types";
import { ActivationEmailReport } from "./ActivationEmailReport";
import { Icon } from "semantic-ui-react";
import RequestFailed from "../../../_components/RequestFailed";

const ACCOUNT_ACTIVATIONS = gql`
  query ACCOUNT_ACTIVATIONS($businessId: String!) {
    accountActivationsByBusinessId(businessId: $businessId) {
      id
      business {
        id
        email
      }
      client {
        id
        email
      }
      used
      sentEmail {
        id
        deliveryStatus
        recipientEmail
      }
    }
  }
`;

export interface ActivationEmailReportsProps {
  business: Business;
}

export const ActivationEmailReports: FC<ActivationEmailReportsProps> = ({
  business,
}) => {
  const { data, loading, error, refetch } = useQuery<{
    accountActivationsByBusinessId: AccountActivation[];
  }>(ACCOUNT_ACTIVATIONS, {
    variables: { businessId: business.id },
  });

  if (loading) {
    return <SectionLoading />;
  }

  if (error) {
    return <RequestFailed />;
  }

  return (
    <div>
      <div className='w-full flex items-center mb-2'>
        <h2 className='text-lg font-bold text-primary-500'>
          Email Delivery Reports
        </h2>
        <Button
          type='link'
          onClick={() =>
            refetch({
              businessId: business.id,
            })
          }
        >
          <Icon name='redo alternate' loading={loading} />
        </Button>
      </div>

      <div className='w-full'>
        {data?.accountActivationsByBusinessId.map((accountActivation) => (
          <ActivationEmailReport
            key={accountActivation.id}
            accountActivation={accountActivation}
          />
        ))}
      </div>
    </div>
  );
};
