import React, { FC } from "react";
import { WorkingDays } from "../../../../../../../types";
import { NonBillableDate } from "../../../../../types";
import AddNonBillableDate from "./components/AddNonBillableDate";
import SelectWorkingDays from "./components/SelectWorkingDays";

export interface ManageScheduleProps {
  nonWorkingDays: WorkingDays[];
  setNonWorkingDays: (nonWorkingDays: WorkingDays[]) => void;
  nonBillableDates: NonBillableDate[];
  setNonBillableDates: (nonBillableDates: NonBillableDate[]) => void;
  startDate: Date;
  endDate?: Date;
}

export const ManageSchedule: FC<ManageScheduleProps> = ({
  setNonWorkingDays,
  nonWorkingDays,
  nonBillableDates,
  setNonBillableDates,
  startDate,
  endDate,
}) => {
  return (
    <div>
      <SelectWorkingDays
        nonWorkingDays={nonWorkingDays}
        setNonWorkingDays={setNonWorkingDays}
      />
      {endDate ? (
        <AddNonBillableDate
          nonWorkingDays={nonWorkingDays}
          nonBillableDates={nonBillableDates}
          setNonBillableDates={setNonBillableDates}
          startDate={startDate}
          endDate={endDate}
        />
      ) : null}
    </div>
  );
};
