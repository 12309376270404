import { gql } from '@apollo/client';

export const CREATE_RECRUITMENT = gql`
  mutation CreateRecruitment($data: CreateContractRecruitmentInput!) {
    createContractRecruitment(data: $data) {
      id
      client {
        firstName
        lastName
      }
      requests {
        proCount
      }
      status
      createdAt
    }
  }
`;
