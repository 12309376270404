import { gql, useQuery } from "@apollo/client";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { format, formatDistanceToNowStrict, isAfter } from "date-fns";
import { FaArrowRight, FaEllipsisH, FaExternalLinkAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { formatAsNaira } from "../../helpers/helpers";
import { Order, OrderHandlingQuotation } from "../../types";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { getQuoteStatusColor } from "./constants";

const GET_ORDER_QUOTES = gql`
  query GetOrderQuotes($orderId: String!) {
    orderHandlingQuotationsByOrderId(orderId: $orderId) {
      id
      serialNumber
      status
      assignments {
        id
        jobs {
          id
          task {
            id
            name
            description
          }
          custom {
            name
            description
          }
        }
        category {
          id
          name
        }
        tradesman {
          id
          firstName
          lastName
        }
        otherPros {
          id
        }
      }
      items {
        materials {
          name
          quantity
          unitPrice
        }
        labor
        job {
          id
        }
      }
      expiresAt
      costBreakdown {
        total
      }
      createdAt
    }
  }
`;

interface QuotationTabProps {
  order: Order;
}

export const QuotationTab = ({ order }: QuotationTabProps) => {
  const history = useHistory();

  const { data, loading, error } = useQuery<{
    orderHandlingQuotationsByOrderId: OrderHandlingQuotation[];
  }>(GET_ORDER_QUOTES, {
    variables: {
      orderId: order.id,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <RequestFailed />;
  }

  const orderHandlingQuotations = data?.orderHandlingQuotationsByOrderId
    .slice()
    .sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

  return (
    <div className='flex flex-col py-8 gap-4'>
      <div className='flex justify-end'>
        <Button
          colorScheme='primary'
          rightIcon={<FaArrowRight />}
          onClick={() =>
            history.push(`/orders/${order.orderCode}/quotes/create`)
          }
        >
          Create New Quote
        </Button>
      </div>
      <TableContainer>
        <Table border={1} borderStyle='solid' borderColor='gray.200'>
          <Thead>
            <Tr>
              <Th className='border-gray-200 border-r'>S/No.</Th>
              <Th className='border-gray-200 border-r'>Date Created</Th>
              <Th className='border-gray-200 border-r'>Assignments</Th>
              <Th className='border-gray-200 border-r'>Status</Th>
              <Th className='border-gray-200 border-r'>Expires In</Th>
              <Th className='border-gray-200 border-r' isNumeric>
                Total
              </Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {!orderHandlingQuotations?.length && (
              <Tr>
                <Td colSpan={7} className='text-center'>
                  No quotes available
                </Td>
              </Tr>
            )}
            {orderHandlingQuotations?.map((quote) => (
              <Tr key={quote.id}>
                <Td className='border-gray-200 border-r'>
                  {quote.serialNumber}
                </Td>
                <Td className='border-gray-200 border-r'>
                  {format(new Date(quote.createdAt), "PPpp")}
                </Td>
                <Td className='border-gray-200 border-r'>
                  {quote.assignments[0].tradesman.firstName}{" "}
                  {quote.assignments[0].tradesman.lastName} -{" "}
                  {quote.assignments[0].category.name}
                  {quote.assignments.length > 1 && (
                    <span className='text-primary-300'>
                      {" "}
                      +{quote.assignments.length - 1}
                    </span>
                  )}
                </Td>
                <Td className='border-gray-200 border-r'>
                  <Badge colorScheme={getQuoteStatusColor(quote.status)}>
                    {quote.status}
                  </Badge>
                </Td>
                <Td className='border-gray-200 border-r'>
                  {isAfter(new Date(), new Date(quote.expiresAt))
                    ? "Expired"
                    : formatDistanceToNowStrict(new Date(quote.expiresAt))}
                </Td>
                <Td className='border-gray-200 border-r' isNumeric>
                  {formatAsNaira(quote.costBreakdown.total)}
                </Td>
                <Td>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label='Options'
                      icon={<FaEllipsisH />}
                      variant='unstyled'
                    />
                    <MenuList>
                      <MenuItem
                        icon={<FaExternalLinkAlt />}
                        onClick={() => {
                          history.push(`${order.orderCode}/quotes/${quote.id}`);
                        }}
                      >
                        View
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};
