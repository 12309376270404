import React, { FC } from "react";
import { Row, Col } from "antd";
import { LoginForm } from "./LoginForm";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ResetPasswordForm } from "./ResetPasswordForm";

export const Login: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Row>
      <Col xs={0} lg={12}>
        <div className="o-login__headerWrapper">
          <div className="o-login__headerDimmer">
            <h1>LaborHack</h1>
          </div>
        </div>
      </Col>
      <Col xs={24} lg={12}>
        <Row>
          <div className="o-login__formWrapper">
            <Col
              xs={{ span: 20, offset: 2 }}
              lg={{ span: 16, offset: 4 }}
              xl={{ span: 12, offset: 6 }}
            >
              <Switch>
                <Route path={`${path}/reset`}>
                  <ResetPasswordForm />
                </Route>
                <Route path={`${path}`}>
                  <LoginForm />
                </Route>
              </Switch>
            </Col>
          </div>
        </Row>
      </Col>
    </Row>
  );
};
