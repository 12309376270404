import React, { FC, ReactElement } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const SideNavigationItem: FC<{
  icon: ReactElement;
  name: string;
  route: string;
  filteredSubMenu?: {
    name: string;
    path: string;
    icon: any;
  }[];
  active?: boolean;
  subMenuOpen: boolean;
  setSubMenuOpen: (route: string) => void;
}> = ({
  icon,
  name,
  route,
  active,
  filteredSubMenu,
  subMenuOpen,
  setSubMenuOpen,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <div className={`o-sideNavigation__itemWrapper ${active ? "-active" : ""}`}>
      <div
        className={`o-sideNavigation__mainItemWrapper ${
          active ? "-active" : ""
        }`}
        onClick={() => {
          if (filteredSubMenu) {
            setSubMenuOpen(route);
          } else {
            history.push(route);
          }
        }}
      >
        <div className="m-sideNavigation__item">
          {icon}
          <p>{name}</p>
        </div>
        {active && <div className="a-sideNavigation__itemActiveBar"></div>}
      </div>
      {filteredSubMenu && subMenuOpen && (
        <div className="o-sideNavigation__subMenuItemWrapper">
          {filteredSubMenu.map(({ name, path, icon }) => {
            const isActive = new RegExp(path).test(pathname);

            return (
              <div
                key={path}
                className={`o-sideNavigation__subMenuItemContainer ${
                  isActive ? "-sub-active" : ""
                }`}
              >
                <div
                  key={name}
                  className={`m-sideNavigation__subMenuItem`}
                  onClick={() => {
                    history.push(path);
                  }}
                >
                  {icon}
                  <p>{name}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
