import { gql } from '@apollo/client';

export const CREATE_REFUND_REQUEST = gql`
  mutation CreateRefundRequest($data: CreateRefundRequestInput!) {
    createRefundRequest(data: $data) {
      status
      message
    }
  }
`;

export const APPROVE_REFUND_REQUEST = gql`
  mutation ApproveRefundRequest($data: ApproveRefundRequestInput!) {
    approveRefundRequest(input: $data) {
      status
      message
    }
  }
`;

export const FINALIZE_REFUND_REQUEST = gql`
  mutation Finalize($id: String!, $otp: String!) {
    finalizeRefundRequest(id: $id, otp: $otp) {
      status
      message
    }
  }
`;

export const REVIEW_REFUND_REQUEST = gql`
  mutation Review($id: String!) {
    reviewRefundRequest(id: $id) {
      status
      message
    }
  }
`;

export const CANCEL_REFUND_REQUEST = gql`
  mutation Cancel($id: String!) {
    cancelRefundRequest(id: $id) {
      status
      message
    }
  }
`;
