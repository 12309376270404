import { Route, Switch, useRouteMatch } from "react-router-dom";

import Requests from "./requests";
import Confirmations from "./confirmations";
import RequestView from "./requests/view";
import ConfirmationView from "./confirmations/view";

export default function SubscriptionRequestPayouts() {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/requests/:id`}>
				<RequestView />
			</Route>
			<Route path={`${path}/requests`}>
				<Requests />
			</Route>
			<Route path={`${path}/confirmations/:id`}>
				<ConfirmationView />
			</Route>
			<Route path={`${path}/confirmations`}>
				<Confirmations />
			</Route>
		</Switch>
	);
}
