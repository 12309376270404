import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';
import produce from 'immer';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Message, Modal } from 'semantic-ui-react';

import { CatServiceSelectors } from '../../../redux/store';
import { components as LayoutComponents } from '../../layout';
import { GET_TRADESMAN_ASSIGNMENTS } from '../../orders/graphql/query';
import {
  Tradesman,
  TradesmanAssignment,
  TransactionStatus,
} from '../../../types';
import { CREATE_REFUND_REQUEST } from '../graphql/mutation';
import { GET_CLIENT } from '../graphql/query';
import useRefundExists from '../hooks/useRefundExists';
import RefundAssignment from './RefundAssignment';
import RefundClientAccountForm from './RefundClientAccountForm';
import Island from '../../../_components/Island';
import {
  calculateLaborHackFee,
  calculateMaterialsAndLabor,
  calculateTotal,
  invoiceHasAtLeastOnePayment,
} from '../../../helpers/helpers';
import { GET_PAYMENT_PROVIDER_INFORMATION } from '../../../graphql/query';
import { PaymentProviderInformationResponse } from '../../../graphql/types';

const { Trail, CustomInput, CustomTextArea } = LayoutComponents;
const { selectCategories } = CatServiceSelectors;

const CreateRefund: FC = () => {
  const { search } = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(search);

  const orderId = query.get('orderId');

  const refundRequestIsInvalid = useRefundExists(orderId);

  const categories = useSelector(selectCategories);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const [reason, setReason] = useState<string>('');

  const [pointChanges, setPointChanges] = useState<{
    [tradesmanId: string]: number;
  }>({});

  const [submitModalOpen, setSubmitModalOpen] = useState<boolean>(false);

  const [accountDetails, setAccountDetails] = useState<{
    name: string;
    bankCode: string;
    accountNumber: string;
  }>();

  const [newBankInformation, setNewBankInformation] = useState(true);

  const getAssignmentsResponse = useQuery(GET_TRADESMAN_ASSIGNMENTS, {
    variables: {
      orderId,
    },
  });

  const assignments: TradesmanAssignment[] | undefined =
    getAssignmentsResponse.data?.getTradesmanAssignments.data;

  const userId = assignments && assignments[0].order.client.userId;

  const getClientResponse = useQuery(GET_CLIENT, {
    variables: {
      userId,
    },
  });

  const [
    getPaymentProviderInformation,
    getPaymentProviderInformationResponse,
  ] = useLazyQuery<PaymentProviderInformationResponse>(
    GET_PAYMENT_PROVIDER_INFORMATION
  );

  const [submitRefundRequest, submitRefundRequestResponse] = useMutation(
    CREATE_REFUND_REQUEST,
    {
      onCompleted: (data) => {
        if (assignments) {
          notification.success({
            message: data.createRefundRequest.message,
            onClose: () => {
              history.push('/orders/' + assignments[0].order.orderCode);
            },
          });
        }
      },
    }
  );

  useEffect(() => {
    if (userId) {
      getPaymentProviderInformation({
        variables: {
          userId,
        },
      });
    } // eslint-disable-next-line
  }, [userId]);

  const paidAssignments = assignments?.filter(({ invoice, jobs }) => {
    return invoice && invoiceHasAtLeastOnePayment(invoice) && jobs.length;
  });

  const refundData: {
    [x: string]: { jobId: string; assignmentId: string; refundAmount: number };
  } = {};

  const assignmentIdToAssignment: { [x: string]: TradesmanAssignment } = {};

  paidAssignments?.forEach((assignment) => {
    assignmentIdToAssignment[assignment.id] = assignment;

    let percentage = 1;

    if (assignment.invoice?.splitPayments?.length) {
      percentage =
        assignment.invoice.splitPayments
          .filter(
            ({ payment }) => payment?.status === TransactionStatus.SUCCESS
          )
          .reduce((acc, { percentage }) => acc + percentage, 0) / 100;
    }

    assignment.jobs.forEach((job) => {
      const { materials, labor } = calculateMaterialsAndLabor([job]);

      const laborHackFee = calculateLaborHackFee(materials, labor);

      const total = calculateTotal(materials, laborHackFee);

      refundData[job.id] = {
        jobId: job.id,
        assignmentId: assignment.id,
        refundAmount: total * percentage,
      };
    });
  });

  const [jobsToRefund, setJobsToRefund] = useState<{
    [x: string]: {
      jobId: string;
      assignmentId: string;
      refundAmount: number | undefined;
    };
  }>(refundData);

  if (refundRequestIsInvalid) {
    return <div>invalid request</div>;
  }

  if (getAssignmentsResponse.loading) {
    return <div>loading...</div>;
  }

  if (!paidAssignments || !paidAssignments.length) {
    return <div>invalid request</div>;
  }

  if (getAssignmentsResponse.error) {
    return (
      <Message negative>
        <Message.Content>
          {getAssignmentsResponse.error.message}
        </Message.Content>
      </Message>
    );
  }

  const existingPaymentInformation =
    getPaymentProviderInformationResponse.data?.paymentProviderInformation;

  const handleRefundAmountChange = (
    jobId: string,
    amount: number | undefined
  ) => {
    setJobsToRefund((prev) => {
      return produce(prev, (draft) => {
        const data = refundData[jobId];

        if (data) {
          draft[jobId] = {
            ...data,
            refundAmount: amount,
          };
        }
      });
    });
  };

  const handleRowSelection = (jobId: string, selected: boolean) => {
    if (selected) {
      setSelectedRowKeys((prev) => {
        return [...prev, jobId];
      });
    } else {
      setSelectedRowKeys((prev) => {
        return prev.filter((id) => id !== jobId);
      });
    }
  };

  const selectedTradesmen: { [x: string]: Tradesman } = {};

  selectedRowKeys.forEach((jobId) => {
    const tradesman =
      assignmentIdToAssignment[refundData[jobId].assignmentId].tradesman;

    selectedTradesmen[tradesman.id] = tradesman;
  });

  const jobRefundData: {
    jobId: string;
    assignmentId: string;
    amount: number | undefined;
  }[] = selectedRowKeys.map((selectedJob) => {
    const data = jobsToRefund[selectedJob]
      ? jobsToRefund[selectedJob]
      : refundData[selectedJob];

    return {
      jobId: data.jobId,
      assignmentId: data.assignmentId,
      amount: data.refundAmount,
    };
  });

  return (
    <>
      <Modal
        open={submitModalOpen}
        closeOnDimmerClick
        onClose={() => {
          setSubmitModalOpen(false);
        }}
        size="small"
      >
        <div className="o-refundCreate__submitModalContainer">
          <h4 className="a-refundCreate__submitModalHeader">
            Submit Refund Request
          </h4>
          <div className="m-refundCreate__submitModalForm">
            <CustomTextArea
              label="Reason"
              value={reason}
              onChange={(value) => {
                setReason(value);
              }}
              required
            />

            <h5 className="a-refundCreate__submitModalSubHeader">
              Add Point Change to Tradesmen Involved
            </h5>

            {Object.entries(selectedTradesmen).map(([id, tradesman]) => {
              return (
                <CustomInput
                  key={id}
                  placeholder="For Example -55"
                  label={`${tradesman.firstName} ${tradesman.lastName} (Current point total: ${tradesman.rewardPoints})`}
                  type="number"
                  value={pointChanges[id]}
                  onChange={(value) => {
                    setPointChanges((prev) => {
                      return produce(prev, (draft) => {
                        return {
                          ...draft,
                          [id]: value,
                        };
                      });
                    });
                  }}
                />
              );
            })}
          </div>
          <div className="m-refundCreate__submitModalActions">
            <button
              className="a-refundCreate__submitModalButton"
              disabled={!reason.length || submitRefundRequestResponse.data}
              onClick={() => {
                submitRefundRequest({
                  variables: {
                    data: {
                      orderId,
                      reason,
                      jobsToRefund: jobRefundData,
                      tradesmanPointChanges: Object.entries(pointChanges).map(
                        ([tradesmanId, change]) => {
                          return {
                            tradesmanId,
                            change: Number(change),
                          };
                        }
                      ),
                      ...(newBankInformation && {
                        clientRefundData: {
                          accountNumber: accountDetails?.accountNumber,
                          bankCode: accountDetails?.bankCode,
                        },
                      }),
                    },
                  },
                });
              }}
            >
              Send
            </button>
          </div>
        </div>
      </Modal>
      <Island
        header={<Trail root="Order ID" child={orderId || undefined} />}
        text="Select and set appropriate amounts to be refunded "
      >
        <div className="o-refundCreate__container">
          <h3>Select Tasks To Refund</h3>
          <div className="o-refundCreate__section">
            {paidAssignments?.map((assignment) => {
              return (
                <RefundAssignment
                  key={assignment.id}
                  assignment={assignment}
                  categories={categories}
                  selectedRowKeys={selectedRowKeys}
                  refundData={jobsToRefund}
                  defaultRefundData={refundData}
                  handleRowSelection={handleRowSelection}
                  handleRefundAmountChange={handleRefundAmountChange}
                />
              );
            })}
          </div>
          <h3>Set Client Refund Details</h3>
          <div className="o-refundCreate__section">
            {existingPaymentInformation && (
              <>
                <div className="m-refundCreate__clientDetailsRadio">
                  <input
                    type="radio"
                    checked={!newBankInformation}
                    onChange={(e) => {
                      setNewBankInformation(false);
                    }}
                  />
                  <label htmlFor="">
                    Use existing client bank account information (
                    {`${existingPaymentInformation.defaultAccountNumber}`})
                  </label>
                </div>
              </>
            )}

            <div className="m-refundCreate__clientDetailsRadio">
              {getClientResponse.data?.getClientByUserId.data.recipientCode && (
                <input
                  type="radio"
                  checked={newBankInformation}
                  onChange={(e) => {
                    setNewBankInformation(true);
                  }}
                />
              )}
              <label htmlFor="">
                Enter new bank account information (Note: This would replace any
                existing account information)
              </label>
            </div>

            {newBankInformation && (
              <RefundClientAccountForm
                setValidAccountNumber={(name, accountNumber, bankCode) => {
                  if (accountNumber) {
                    setAccountDetails({
                      name,
                      accountNumber,
                      bankCode,
                    });
                  } else {
                    setAccountDetails(undefined);
                  }
                }}
              />
            )}
          </div>

          <div className="m-refundCreate__actions">
            <button
              className="a-refundCreate__actionButton -negative"
              onClick={() => {
                history.push('/orders/' + paidAssignments[0].order.orderCode);
              }}
            >
              Cancel Refund
            </button>
            <button
              className="a-refundCreate__actionButton -positive"
              disabled={
                !selectedRowKeys.length ||
                (newBankInformation && !accountDetails)
              }
              onClick={() => {
                setSubmitModalOpen(true);
              }}
            >
              Request Refund
            </button>
          </div>
        </div>
      </Island>
    </>
  );
};

export default CreateRefund;
