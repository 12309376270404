import React, { FC } from "react";
import { SplitPaymentsField } from "./SplitPaymentsField";

import styles from "./styles.module.scss";

export interface SplitPaymentsProps {
  values: string[];
  onAddNewField: (index: number) => void;
  onRemoveField: (index: number) => void;
  onChange: (value: string, isValid: boolean, index: number) => void;
}

export const SplitPayments: FC<SplitPaymentsProps> = ({
  values,
  onChange,
  onAddNewField,
  onRemoveField,
}) => {
  return (
    <div className={styles.wrapper}>
      {values.map((value, index) => {
        let label;

        switch (index) {
          case 0:
            label = "Upfront";
            break;
          case values.length - 1:
            label = "Balance";
            break;
          default:
            label = "Progress";
            break;
        }

        return (
          <SplitPaymentsField
            key={index}
            label={label}
            value={value}
            onAdd={() => onAddNewField(index)}
            onRemove={() => onRemoveField(index)}
            canAdd={index === 0 && values.length < 3}
            canRemove={index === 1 && values.length > 2}
            readOnly={index === values.length - 1}
            onChange={(value: string, isValid: boolean) => {
              onChange(value, isValid, index);
            }}
          />
        );
      })}
    </div>
  );
};
