import gql from 'graphql-tag';

export const GET_ADMIN = gql`
  query GetAdmin($id: String!) {
    admin(id: $id) {
      id
      userId
      firstName
      lastName
      email
      phoneNumber
      roles
    }
  }
`;
