import {
  createSlice,
  createAsyncThunk,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import { IEditTask } from "./../components/tasks/types";

const initialState: IEditTask = {
  name: "",
  taskId: "",
  description: "",
  imageUrl: "",
  estimatedCost: 0,
  submitted: false,
  valid: false,
  success_message: "",
  error_message: "",
  status: "idle",
};

export const editTaskForm = createAsyncThunk(
  "task/editTask",
  async (
    data: { mutation: any },
    {
      dispatch,
      getState,
    }: { dispatch: ThunkDispatch<any, any, AnyAction>; getState: any }
  ) => {
    const { mutation } = data;
    const { editTask }: any = getState();

    try {
      const { data } = await mutation({
        variables: {
          input: {
            name: editTask.name,
            taskId: editTask.taskId,
            description: editTask.description,
            imageUrl: editTask.imageUrl,
            estimatedCost: editTask.estimatedCost,
          },
        },
      });
      if (data) {
        dispatch(setSuccessMessage("Successfully edited"));
      }
    } catch (error) {
      console.log(error);
      dispatch(setErrorMessage("An error occured"));
    }
  }
);

export const editTaskSlice = createSlice({
  name: "editTask",
  initialState,
  reducers: {
    setDName: (state, action) => {
      state.name = action.payload;
    },
    setDTaskId: (state, action) => {
      state.taskId = action.payload;
    },
    setDDescription: (state, action) => {
      state.description = action.payload;
    },
    setDEstimatedCost: (state, action) => {
      state.estimatedCost = action.payload;
    },
    setDImage: (state, action) => {
      state.imageUrl = action.payload;
    },
    setDSubmitted: (state, action) => {
      state.submitted = action.payload;
    },
    setDFormValid: (state, action) => {
      state.valid = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.success_message = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error_message = action.payload;
    },
    setDStatus: (state, action) => {
      state.status = action.payload;
    },
    resetDForm: (state) => {
      state.name = "";
      state.taskId = "";
      state.description = "";
      state.imageUrl = "";
      state.valid = false;
      state.submitted = false;
    },
  },
  extraReducers: {
    [editTaskForm.pending.type]: (state, action) => {
      state.status = "pending";
    },
    [editTaskForm.fulfilled.type]: (state, action) => {
      state.status = "idle";
    },
  },
});

// D is to signify Dispatch to differentiate it in the state names in components
export const {
  setDName,
  setDTaskId,
  setDDescription,
  setDImage,
  setDEstimatedCost,
  setDFormValid,
  setDSubmitted,
  resetDForm,
  setSuccessMessage,
  setErrorMessage,
  setDStatus,
} = editTaskSlice.actions;

export const selectCategory = (state: any) => state.editTask.category;
export const selectName = (state: any) => state.editTask.name;
export const selectDescription = (state: any) => state.editTask.description;
export const selectImage = (state: any) => state.editTask.imageUrl;
export const selectValidity = (state: any) => state.editTask.valid;
export const selectSubmitted = (state: any) => state.editTask.submitted;
export const selectSubmissionStatus = (state: any) => state.editTask.status;
export const selectSuccessMessage = (state: any) =>
  state.editTask.success_message;
export const selectErrorMessage = (state: any) => state.editTask.error_message;

export const TaskEditSelectors = {
  selectName,
  selectCategory,
  selectDescription,
  selectValidity,
  selectImage,
  selectSubmitted,
  selectSubmissionStatus,
  selectSuccessMessage,
  selectErrorMessage,
};

export const TaskEditAction = editTaskSlice.actions;
export const TaskEditReducer = editTaskSlice.reducer;
