import React, { FC } from "react";
import { Icon, Label } from "semantic-ui-react";

export const Trail: FC<{
  root: string;
  child?: string;
  label?: {
    text: string;
    color: any;
  };
  deep?: boolean;
}> = ({ root, child, label, deep }) => (
  <div className={`m-trail__wrapper ${deep && "-deep"}`}>
    <h2>{root}</h2>
    {child && (
      <>
        <Icon name="chevron right"></Icon>
        <h3>{child}</h3>
      </>
    )}
    {label && <Label color={label.color}>{label.text}</Label>}
  </div>
);
