import Joi from "@hapi/joi";

export const validator = Joi.object({
  firstName: Joi.string().min(1),
  lastName: Joi.string().min(1),
  email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }),
  phoneNumber: Joi.string().pattern(new RegExp(/^(70|(80|81)|(90|91))\d{8}$/)),
  whatsappNumber: Joi.string().pattern(
    new RegExp(/^(70|(80|81)|(90|91))\d{8}$/)
  ),
  streetName: Joi.string().min(2),
  buildingNumber: Joi.string().min(1),
  location: Joi.string().min(1),
  city: Joi.string().min(1),
  area: Joi.string().min(1),
  landmark: Joi.string().min(1),
  accountNumber: Joi.string().pattern(new RegExp("^[0-9]{10}$")),
});

export const baseRegisterValidator = validator.and(
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  // "whatsappNumber",
  "streetName",
  "buildingNumber",
  "location",
  "city",
  "area",
  "accountNumber"
);
