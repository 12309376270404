import React, { FC, useEffect } from "react";
import { IFormField, IBankData } from "./../types";
import { Grid, Form, Button, Message } from "semantic-ui-react";
import { Steps } from "antd";
import { GET_ACCOUNT_DETAILS, LIST_BANKS } from "../../../graphql/query";
import { useQuery, useLazyQuery } from "@apollo/client";
import { validator, baseRegisterValidator } from "./../validator";
import { useHistory } from "react-router-dom";
import Island from "../../../_components/Island";

const { Step } = Steps;

interface FinanceInfoProps {
  submitLoading: boolean;
  accountName: string;
  bankCode: string;
  bankList: never[];
  accountNumber: IFormField;
  firstName: IFormField;
  lastName: IFormField;
  email: IFormField;
  phoneNumber: IFormField;
  whatsappNumber: IFormField;
  category: string;
  level: number;
  area: IFormField;
  landMark: IFormField;
  buildingNumber: IFormField;
  streetName: IFormField;
  location: IFormField;
  state: IFormField;
  setAccountNumber: React.Dispatch<React.SetStateAction<IFormField>>;
  setAccountName: React.Dispatch<React.SetStateAction<string>>;
  setBankCode: React.Dispatch<React.SetStateAction<string>>;
  setBankList: React.Dispatch<React.SetStateAction<never[]>>;
  clearForm: () => void;
  clearMessage: () => void;
  previousStep: () => void;
  step: number;
  submitForm: () => void;
  successMessage: string;
  errorMessage: string;
}
export const FinanceInfo: FC<FinanceInfoProps> = ({
  bankList,
  setBankList,
  bankCode,
  setBankCode,
  accountNumber,
  setAccountNumber,
  accountName,
  setAccountName,
  clearMessage,
  clearForm,
  step,
  previousStep,
  firstName,
  lastName,
  email,
  phoneNumber,
  category,
  level,
  buildingNumber,
  streetName,
  landMark,
  area,
  location,
  state,
  submitLoading,
  submitForm,
  successMessage,
  errorMessage,
}) => {
  const [
    getAccountDetails,
    { loading, data: accountData, error },
  ] = useLazyQuery(GET_ACCOUNT_DETAILS);

  const history = useHistory();
  const { data: bankListData } = useQuery(LIST_BANKS);
  useEffect(() => {
    if (bankListData) {
      setBankList(bankListData.listBanks.data);
    }
  }, [bankListData, setBankList]);

  useEffect(() => {
    if (loading === false) {
      setAccountName(accountData?.getBankAccountInformation?.data?.accountName);
    }
  }, [accountData, loading, accountName, setAccountName]);

  useEffect(() => {
    if (successMessage !== "") {
      setAccountName("");
    }
  }, [successMessage, setAccountName]);

  if (accountName !== undefined || accountName !== "") {
    setAccountName(accountName);
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Island header="Create new pro">
            <Steps progressDot current={step}>
              <Step title="Personal Information" />
              <Step title="Address Information" />
              <Step title="Financial Information" />
            </Steps>
            <Form>
              <Form.Input
                required
                id="form-subcomponent-shorthand-input-account-number"
                label="Account Number"
                placeholder="Enter account number"
                value={accountNumber.value}
                error={accountNumber.error}
                {...(!accountNumber.initial && {
                  icon: !!validator.validate({
                    accountNumber: accountNumber.value.replace(/\s/g, ""),
                  }).error
                    ? {
                        name: "remove",
                        color: "red",
                      }
                    : {
                        name: "check",
                        color: "green",
                      },
                })}
                onChange={(e) => {
                  const { value } = e.target;
                  clearMessage();
                  setAccountNumber((state) => {
                    return {
                      ...state,
                      value,
                      initial: false,
                    };
                  });
                }}
                onBlur={() => {
                  const { error } = validator.validate({
                    accountNumber: accountNumber.value.replace(/\s/g, ""),
                  });
                  if (error) {
                    setAccountNumber((state) => {
                      return {
                        ...state,
                        error: {
                          content: "Invalid Number. Enter account number",
                          pointing: "above",
                        },
                      };
                    });
                  }
                }}
                onFocus={() => {
                  setAccountNumber((state) => {
                    return {
                      ...state,
                      error: false,
                    };
                  });
                }}
              />
              <Form.Input required label="Select Bank">
                <select
                  name="role"
                  id="role"
                  value={bankCode}
                  onChange={(e) => {
                    const { value } = e.target;
                    clearMessage();
                    setBankCode(value);
                  }}
                  onBlur={() => {
                    getAccountDetails({
                      variables: {
                        input: {
                          accountNumber: accountNumber.value,
                          bankCode: bankCode,
                        },
                      },
                    });
                  }}
                >
                  <option value="">Choose bank</option>
                  {bankList.length > 0 ? (
                    bankList.map((data: IBankData) => {
                      return (
                        <option
                          data-testid="banks"
                          key={data.code}
                          value={data.code}
                        >
                          {data.name}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No available data</option>
                  )}
                </select>
              </Form.Input>
              <Form.Input
                fluid
                id="form-subcomponent-shorthand-input-first-name"
                label="Account Name"
                readOnly
                value={
                  loading
                    ? "loading..."
                    : error
                    ? "Account number does not exist with the selected bank, please confirm details again."
                    : accountName
                }
                error={error}
              />
            </Form>
            <div className="nextStep-button" style={{ marginTop: 16 }}>
              {successMessage !== "" && (
                <Message
                  success
                  header="Created successfully"
                  content={successMessage}
                />
              )}
              {errorMessage !== "" && (
                <Message error header="Error" content={errorMessage} />
              )}
              <div>
                <Button primary onClick={previousStep}>
                  Back
                </Button>
                <Button basic color="red" onClick={() => history.goBack()}>
                  Cancel
                </Button>
                {!submitLoading ? (
                  <Button
                    data-testid="submit-button"
                    primary
                    {...((baseRegisterValidator.validate({
                      firstName: firstName.value.replace(/\s/g, ""),
                      lastName: lastName.value.replace(/\s/g, ""),
                      email: email.value,
                      phoneNumber: phoneNumber.value,

                      buildingNumber: buildingNumber.value.replace(/\s/g, ""),
                      streetName: streetName.value.replace(/\s/g, ""),
                      area: area.value.replace(/\s/g, ""),
                      location: location.value.replace(/\s/g, ""),
                      city: state.value.replace(/\s/g, ""),
                      accountNumber: accountNumber.value,
                    }).error ||
                      category === "" ||
                      level === 0 ||
                      accountName === "" ||
                      accountName === undefined ||
                      bankCode === "") && { disabled: true })}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button loading primary>
                    Loading
                  </Button>
                )}
              </div>
            </div>
          </Island>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
