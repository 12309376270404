import { ColumnsType } from "antd/lib/table";
import React, { FC } from "react";
import {
  MarketplaceJob,
  MarketplaceJobShortlistedPro,
  MarketplaceJobShortlistStatus,
} from "../../types";
import { components as LayoutComponents } from "../../components/layout";
import Pill from "../../_components/Pill";
import CustomButton from "@laborhack/custom-button";
import { useMutation } from "@apollo/client";
import {
  ACCEPT_SHORTLIST_PRO,
  ADD_PROS_TO_SHORTLIST,
  DECLINE_SHORTLIST_PRO,
} from "../../graphql/marketplace-requests";
import RequestFailed from "../../_components/RequestFailed";
import { Modal } from "semantic-ui-react";
import SelectContractPro from "../../components/SelectContractPro";
import { allWorkingDays } from "../../constants";

const { CustomTable } = LayoutComponents;

export interface MarketplaceJobShortlistProps {
  job: MarketplaceJob;
  matched: boolean;
}

const statusToPillClassName: {
  [Property in MarketplaceJobShortlistStatus]: string;
} = {
  PENDING: "!bg-yellow-100 !text-yellow-800",
  ACCEPTED: "!bg-green-100 !text-green-800",
  DECLINED: "!bg-red-100 !text-red-800",
};

export const MarketplaceJobShortlist: FC<MarketplaceJobShortlistProps> = ({
  job: { shortlist, pros, schedule, trade },
  matched,
}) => {
  const [addProModalOpen, setAddProModalOpen] = React.useState(false);

  const [addProsToShortlist, addProsToShortlistCall] = useMutation(
    ADD_PROS_TO_SHORTLIST,
    {
      onCompleted: () => {
        setAddProModalOpen(false);
      },
    }
  );

  const [
    acceptShortlistPro,
    {
      loading: acceptShortlistProLoading,
      error: acceptShortlistProError,
      reset: acceptShortlistProReset,
    },
  ] = useMutation(ACCEPT_SHORTLIST_PRO);

  const [
    declineShortlistPro,
    {
      loading: declineShortlistProLoading,
      error: declineShortlistProError,
      reset: declineShortlistProReset,
    },
  ] = useMutation(DECLINE_SHORTLIST_PRO);

  const handleAcceptShortlistPro = (proId: string) => {
    acceptShortlistPro({
      variables: {
        shortlistId: shortlist?.id,
        proId,
        addToJob: !matched,
      },
    });
  };

  const handleDeclineShortlistPro = (proId: string) => {
    declineShortlistPro({
      variables: {
        shortlistId: shortlist?.id,
        proId,
      },
    });
  };

  const columns: ColumnsType<MarketplaceJobShortlistedPro & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.pro.id.substring(0, 8)}</p>;
      },
      width: "10%",
      fixed: "left",
    },
    {
      title: "First Name",
      render: (text, record) => {
        return <p>{record.pro.firstName}</p>;
      },

      width: "15%",
    },
    {
      title: "Last Name",
      render: (text, record) => {
        return <p>{record.pro.lastName}</p>;
      },

      width: "15%",
    },
    {
      title: "Email",
      render: (text, record) => {
        return <p>{record.pro.email}</p>;
      },
      key: "email",
      width: "20%",
    },
    {
      title: "Phone Number",
      render: (text, record) => {
        return <p>{record.pro.phoneNumber}</p>;
      },
      key: "phoneNumber",
      width: "15%",
    },
    {
      title: "Status",
      render: (text, record) => {
        return (
          <Pill className={statusToPillClassName[record.status]}>
            {record.status}
          </Pill>
        );
      },
      width: "10%",
    },
    {
      title: "",
      render: (text, record) => {
        const isAssigned = !!pros?.find((pro) => pro.id === record.pro.id);

        return (
          <div className='flex gap-2'>
            <CustomButton
              disabled={
                isAssigned ||
                record.status === MarketplaceJobShortlistStatus.DECLINED
              }
              data-testid='decline-shortlisted-pro'
              className='!bg-red-500'
              onClick={() => handleDeclineShortlistPro(record.pro.id)}
            >
              Decline
            </CustomButton>

            <CustomButton
              disabled={
                isAssigned ||
                record.status === MarketplaceJobShortlistStatus.ACCEPTED
              }
              data-testid='accept-shortlisted-pro'
              variant='success'
              onClick={() => handleAcceptShortlistPro(record.pro.id)}
            >
              Accept
            </CustomButton>
          </div>
        );
      },
      width: "20%",
      fixed: "right",
    },
  ];

  const handleReset = () => {
    acceptShortlistProReset();
    declineShortlistProReset();
  };

  if (acceptShortlistProError || declineShortlistProError) {
    return (
      <RequestFailed
        errorMessage={
          acceptShortlistProError?.message || declineShortlistProError?.message
        }
        onRetry={handleReset}
      />
    );
  }

  const handleAddPro = (proId: string) => {
    addProsToShortlist({
      variables: {
        shortlistId: shortlist?.id,
        proIds: [proId],
      },
    });
  };

  return (
    <>
      <Modal
        open={addProModalOpen}
        closeIcon
        onClose={() => setAddProModalOpen(false)}
        closeOnDimmerClick={false}
      >
        {addProsToShortlistCall.error ? (
          <RequestFailed
            errorMessage={addProsToShortlistCall.error.message}
            onRetry={addProsToShortlistCall.reset}
          />
        ) : (
          <SelectContractPro
            proposedContract={{
              start: schedule.start,
              end: schedule.end,
              nonWorkingDays: allWorkingDays.filter(
                (day) => !schedule.workDays.includes(day)
              ),
            }}
            categoryId={trade.category.id}
            onSelect={({ id }) => handleAddPro(id)}
            loading={addProsToShortlistCall.loading}
            exclusions={shortlist?.shortlistedPros?.map((pro) => pro.pro.id)}
          />
        )}
      </Modal>

      <div className='mt-8'>
        <h3 className='text-lg font-semibold text-primary-500 mb-2'>
          Shortlisted Pros
        </h3>
        <div className='flex justify-end gap-2 my-2'>
          <CustomButton
            className='mt-2'
            onClick={() => {
              addProsToShortlistCall.reset();
              setAddProModalOpen(true);
            }}
          >
            Add Pro
          </CustomButton>
        </div>
        <CustomTable
          loading={acceptShortlistProLoading || declineShortlistProLoading}
          onRow={(record: any, index: any) => {
            return {};
          }}
          columns={columns}
          data={
            shortlist?.shortlistedPros?.map((pro) => {
              return {
                ...pro,
                key: pro.pro.id,
              };
            }) || []
          }
        />
      </div>
    </>
  );
};
