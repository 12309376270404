import { useContext } from "react";
import { userContext } from "../userContext";

/**
 * Gets the currently signed in user from the userContext
 */
export const useSession = () => {
  const user = useContext(userContext);
  return user;
};
