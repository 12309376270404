import React, { FC } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";

export type BadgeType =
  | "progress"
  | "success"
  | "warning"
  | "complete"
  | "default"
  | "basic"
  | "cancelled";
export interface BadgeProps {
  text: string;
  size?: "small" | "normal";
  inverted?: boolean;
  type: BadgeType;
  dataTestid?: string;
}

export const Badge: FC<BadgeProps> = ({
  text,
  size,
  inverted,
  type,
  dataTestid,
}) => {
  const classNames = clsx(
    styles.layoutComponentBadge,
    size === "small" && styles.small,
    type && styles[type],
    inverted && styles.inverted
  );
  return (
    <div className={classNames} data-testid={dataTestid}>
      {text}
    </div>
  );
};
