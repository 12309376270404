import { getTransferBadgeColor, GET_REFERRER_PAYOUT } from "@/features/referrer-payouts/constants";
import { ReferrerPayout } from "@/features/referrer-payouts/types";
import { formatAsNaira } from "@/helpers/helpers";
import { TransferStatus } from "@/types";
import Loading from "@/_components/Loading";
import RequestFailed from "@/_components/RequestFailed";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Badge, Button, Divider, FormControl, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Switch, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { FaCheckCircle, FaStopCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";

const REVIEW_REFERRER_PAYOUT = gql`
mutation ReviewReferrerPayout($payoutId: String!, $override: ReferrerPayoutOverrideInput) {
  reviewReferrerPayout(payoutId: $payoutId, override: $override) {
    id
    transfer {
      status
    }
    override {
      reason
      amount
    }
  }
}`;

export default function RequestView() {
	const { id } = useParams<{
		id: string;
	}>();

	const {
		data: referrerPayoutData,
		loading: referrerPayoutLoading,
		error: referrerPayoutError,
		refetch,
	} = useQuery<{
		referrerPayoutById: ReferrerPayout;
	}>(GET_REFERRER_PAYOUT, {
		variables: {
			referrerPayoutId: id,
		},
	});

	const [reviewReferrerPayout, reviewReferrerPayoutResponse] = useMutation(
		REVIEW_REFERRER_PAYOUT,
		{
			onCompleted: () => {
				refetch();
			},
		}
	);

	const [isOverridingAmount, setIsOverridingAmount] = useState(false);
	const [overrideAmount, setOverrideAmount] = useState<number | undefined>();
	const [overrideReason, setOverrideReason] = useState<string | undefined>();

	const handleIsOverridingAmount = () => {
		setIsOverridingAmount(!isOverridingAmount);
	};

	const handleOverrideAmount = (valueAsString: string) => {
		setOverrideAmount(parseFloat(valueAsString));
	};

	const handleSubmitReview = () => {
		reviewReferrerPayout({
			variables: {
				payoutId: id,
				...(overriddenAmount &&
					overrideReason && {
						override: {
							amount: overriddenAmount,
							reason: overrideReason,
						},
					}),
			},
		});
	};

	if (referrerPayoutLoading) return <Loading />;

	if (referrerPayoutError || reviewReferrerPayoutResponse.error)
		return <RequestFailed />;

	if (!referrerPayoutData?.referrerPayoutById)
		return <RequestFailed errorMessage="Not Found" />;

	const payout = referrerPayoutData?.referrerPayoutById;

	const transferStatus = payout?.transfer?.status || TransferStatus.PENDING;

	const isReviewable = transferStatus === TransferStatus.PENDING;

	const overriddenAmount =
		isOverridingAmount && overrideAmount ? overrideAmount : undefined;

	const canSubmitReview =
		isReviewable && (overriddenAmount ? !!overrideReason : true);

	return (
		<div className="grid grid-cols-3 gap-4">
			<div className="col-span-2">
				<div className="flex flex-col gap-8 p-6 bg-white border">
					<div className="mb-4">
						<h2 className="text-primary-500 text-lg font-medium m-0">
							Referrer Payout Request #{id.slice(0, 8)}
						</h2>
						<p className="m-0 text-primary-300 text-sm">
							Shows a detailed view of a referrer payout request
						</p>
					</div>
					<div className="grid grid-cols-[2fr,3fr] gap-2 max-w-lg">
						<p className="m-0 text-primary-300 text-sm">Referrer's name</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.referrer.name}
						</p>
						<p className="m-0 text-primary-300 text-sm">Business name</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.referrer.businessName}
						</p>
						<p className="m-0 text-primary-300 text-sm">Email</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.referrer.email}
						</p>
						<p className="m-0 text-primary-300 text-sm">Referral Code</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.referrer.referralCode}
						</p>
					</div>
				</div>
			</div>
			<div>
				<div className="flex flex-col gap-6 p-6 bg-white border">
					<div>
						<h2 className="text-primary-500 text-lg font-medium m-0">
							Breakdown
						</h2>
					</div>
					<Divider />
					<div className="flex flex-col gap-4">
						<div className="flex flex-col gap-2">
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Status</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									<Badge colorScheme={getTransferBadgeColor(transferStatus)}>
										{transferStatus.replace("_", " ")}
									</Badge>
								</p>
							</div>
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">
									Total Amount (NGN)
								</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{formatAsNaira(payout?.amount)}
								</p>
							</div>
							{!isReviewable && (
								<div className="flex justify-between">
									<p className="m-0 text-primary-300 text-sm">Overridden</p>
									{payout.override ? (
										<FaCheckCircle className="text-green-500" />
									) : (
										<FaStopCircle className="text-red-500" />
									)}
								</div>
							)}
						</div>
					</div>

					{isReviewable && (
						<>
							<Divider />
							<div className="flex flex-col gap-4">
								<div className="flex flex-col gap-6">
									<div className="flex flex-col gap-2">
										<div className="flex justify-end w-full gap-2">
											<p className="m-0 text-primary-500 text-sm">
												Override Amount
											</p>
											<Switch
												size="md"
												isChecked={isOverridingAmount}
												onChange={handleIsOverridingAmount}
												isDisabled={!isReviewable}
											/>
										</div>

										{isOverridingAmount && (
											<FormControl>
												<NumberInput
													onChange={handleOverrideAmount}
													value={overrideAmount}
													min={0}
													defaultValue={
														payout.amount ? payout.amount.toString() : ""
													}
													isDisabled={!isReviewable}
												>
													<NumberInputField />
													<NumberInputStepper>
														<NumberIncrementStepper />
														<NumberDecrementStepper />
													</NumberInputStepper>
												</NumberInput>
											</FormControl>
										)}
									</div>
									{!!overrideAmount && isOverridingAmount && (
										<Textarea
											className="font-normal"
											placeholder="Reason for override"
											value={overrideReason}
											onChange={(e) => setOverrideReason(e.target.value)}
											isDisabled={!isReviewable}
										/>
									)}
								</div>
							</div>
						</>
					)}
					<Divider />
					<div className="flex flex-col gap-2">
						<Button
							colorScheme="green"
							rightIcon={<FaCheckCircle />}
							isDisabled={!canSubmitReview}
							onClick={handleSubmitReview}
							isLoading={reviewReferrerPayoutResponse.loading}
						>
							Submit Review
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}