import { gql, NetworkStatus, useQuery } from "@apollo/client";
import { ColumnsType } from "antd/lib/table";
import React, { FC } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { components as LayoutComponents } from "../../components/layout";
import { Subscription } from "../../types";
import Island from "../../_components/Island";
import RequestFailed from "../../_components/RequestFailed";
import Button from "@laborhack/custom-button";
import BackButton from "../../_components/BackButton";

const { Trail, CustomTable } = LayoutComponents;

const LIST_SUBSCRIPTIONS = gql`
  query Subscriptions {
    subscriptions {
      id
      plan {
        id
        interval
        package {
          name
        }
      }
      client {
        email
        firstName
        lastName
      }
      category {
        name
      }
      homeownerRequest {
        id
        address {
          address
          city
          state
        }
      }
      createdAt
    }
  }
`;

export interface SubscriptionListProps {}

export const SubscriptionList: FC<SubscriptionListProps> = ({}) => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { loading, error, data, refetch, networkStatus } = useQuery<{
    subscriptions: Subscription[];
  }>(LIST_SUBSCRIPTIONS, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    return <RequestFailed />;
  }

  const columns: ColumnsType<Subscription & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.id.substring(0, 8)}</p>;
      },
      width: "13%",
    },
    {
      title: "Client",
      render: (text, record) => {
        return <p>{`${record.client.firstName} ${record.client.lastName}`}</p>;
      },
      width: "25%",
    },
    {
      title: "Address",
      render: (text, record) => {
        const { address, city, state } = record.homeownerRequest.address;

        return <p>{ `${address}, ${city}, ${state}` }</p>;
      },
      width: "40%",
    },

    {
      title: "Subscription Package",
      render: (text, record) => {
        return <p>{record.plan.package.name}</p>;
      },
      width: "30%",
    },
    {
      title: "Payment Plan",
      render: (text, record) => {
        return <p>{record.plan.interval}</p>;
      },
      width: "20%",
    },
    {
      title: "Category",
      render: (text, record) => {
        return <p>{record.category.name}</p>;
      },
      width: "20%",
    },
    {
      title: "Date",
      render: (text, record) => {
        return <p>{new Date(record.createdAt).toLocaleDateString()}</p>;
      },
      width: "15%",
    }
  ];

  return (
    <>
      <BackButton />
      <Island header={<Trail root='Subscriptions' />}>
        <div className='w-full flex justify-end my-4'>
          <Button
            onClick={() => refetch()}
            loading={networkStatus === NetworkStatus.refetch}
            type='link'
          >
            <Icon name='refresh' /> Refresh
          </Button>
        </div>
        <CustomTable
          onRow={(record: any, index: any) => {
            return {
              onClick: () => {
                history.push(path + "/" + record.id);
              },
            };
          }}
          columns={columns}
          data={
            data?.subscriptions.map((subscription) => ({
              ...subscription,
              key: subscription.id,
            })) || []
          }
          loading={loading}
          handleTableChange={() => {}}
        ></CustomTable>
      </Island>
    </>
  );
};
