import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import RequestFailed from "@/_components/RequestFailed";
import { formatAsNaira } from "@/helpers/helpers";
import { ProRecruitmentContract, ContractWorksheet } from "../../types";
import { Table } from "antd";
import BackButton from "@/_components/BackButton";
import Island from "@/_components/Island";

const GET_CONTRACT_BY_ID = gql`
  query ProRecruitmentContractById($contractId: String!) {
    proRecruitmentContractById(contractId: $contractId) {
      id
      paymentAmount
      paymentMethod
      paymentSchedule
      category {
        name
      }
      startDate
      endDate
      unitMeasured
      pro {
        firstName
        lastName
      }
    }
  }
`;

const GET_CONTRACT_WORKSHEETS = gql`
  query GetContractWorksheetsForContract($contractId: String!) {
    getContractWorksheetsForContract(contractId: $contractId) {
      id
      expectedUnitAmount
      unitAmount
      contract {
        unitMeasured
      }
      payroll {
        id
        period {
          start
          end
        }
      }
    }
  }
`;

interface Params {
  id: string;
}

const ProRecruitmentContractPage = () => {
  const { id } = useParams<Params>();

  const {
    loading: contractLoading,
    error: contractError,
    data: contractData,
  } = useQuery(GET_CONTRACT_BY_ID, {
    variables: { contractId: id }
  });

  const {
    loading: worksheetsLoading,
    error: worksheetsError,
    data: worksheetsData,
  } = useQuery(GET_CONTRACT_WORKSHEETS, {
    variables: { contractId: id }
  });

  if (contractLoading || worksheetsLoading) return <div>Loading...</div>;
  if (contractError) return <RequestFailed errorMessage={contractError.message} />;
  if (worksheetsError) return <RequestFailed errorMessage={worksheetsError.message} />;

  const proRecruitmentContract: ProRecruitmentContract = contractData?.proRecruitmentContractById;
  const worksheets: ContractWorksheet[] = worksheetsData?.getContractWorksheetsForContract;

  const columns = [
    {
      title: "Payroll Period",
      dataIndex: ["payroll", "period"],
      key: "payrollPeriod",
      render: (period: { start: string; end: string }) => <p className="text-sm text-[#003952] font-semibold">{`${new Date(period.start).toISOString().split('T')[0]} to ${new Date(period.end).toISOString().split('T')[0]}`}</p>
    },
    {
      title: "Unit Measured",
      dataIndex: ["contract", "unitMeasured"],
      key: "unitMeasured",
      render: (unitMeasured: string) => <p className="text-sm text-[#003952] font-semibold">{unitMeasured}</p>
    },
    {
      title: "Unit Amount",
      dataIndex: "unitAmount",
      key: "unitAmount",
      render: (unitAmount: number) => <p className="text-sm text-[#003952] font-semibold">{unitAmount}</p>
    },
    {
      title: "Expected Unit Amount",
      dataIndex: "expectedUnitAmount",
      key: "expectedUnitAmount",
      render: (expectedUnitAmount: number) => <p className="text-sm text-[#003952] font-semibold">{expectedUnitAmount}</p>
    }
  ];

  return (
    <>
      <BackButton />
      <Island>
        <div className="container mx-auto p-4">
          <h1 className="text-xl text-[#003952] font-semibold mb-4">Pro Recruitment Contract Details</h1>
          <div className="mb-4 text-lg">
            <p>Pro: {proRecruitmentContract.pro.firstName} {proRecruitmentContract.pro.lastName}</p>
            <p>Contract ID: {proRecruitmentContract.id}</p>
            <p>Category: { proRecruitmentContract.category.name} </p>
            <p>Payment Amount: {formatAsNaira(proRecruitmentContract.paymentAmount)}</p>
            <p>Payment Method: {proRecruitmentContract.paymentMethod.replaceAll('_', ' ')}</p>
            <p>Payment Schedule: {proRecruitmentContract.paymentSchedule}</p>
            <p>Unit Measured: {proRecruitmentContract.unitMeasured}</p>
            <p>Start Date: {new Date(proRecruitmentContract.startDate).toLocaleDateString()}</p>
            <p>End Date: {new Date(proRecruitmentContract.endDate).toLocaleDateString()}</p>
          </div>
          <h2 className="text-lg text-[#003952] font-semibold mb-2">Worksheet</h2>
          <Table
            className="entries-table"
            columns={columns}
            dataSource={worksheets}
            pagination={false}
          />
        </div>
      </Island>
    </>
  );
}

export default ProRecruitmentContractPage;
