import React, { FC, useEffect } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { CreateAssignment } from './CreateAssignment';
import {
  AssignmentActions,
  AssignmentSelectors,
  OrderSelectors,
} from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { ViewAssignment } from './ViewAssignment';
import { Order, TradesmanAssignment } from '../../../types';

const { assignmentsFetched } = AssignmentActions;
const { selectAssignmentMessage } = AssignmentSelectors;
const { selectOrder } = OrderSelectors;

export const Assignments: FC<{
  creationData: {
    order: Order;
    jobs: string[];
    selectedCategory: string;
  };
  assignments: TradesmanAssignment[];
  refetchAssignments: any;
}> = ({ creationData, refetchAssignments, assignments }) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const order = useSelector(selectOrder);
  const message = useSelector(selectAssignmentMessage);

  useEffect(() => {
    if (order && message === '') {
      dispatch(assignmentsFetched({ client, orderId: order.id }));
    } // eslint-disable-next-line
  }, [message]);

  return (
    <Switch>
      <Route path={`${path}/create`}>
        <CreateAssignment
          selectedCategory={creationData.selectedCategory}
          jobs={creationData.jobs}
          order={creationData.order}
          refetchAssignments={refetchAssignments}
          assignments={assignments}
        />
      </Route>
      <Route path={`${path}/:assignmentId`}>
        <ViewAssignment
          refetchAssignments={refetchAssignments}
          order={creationData.order}
        />
      </Route>
    </Switch>
  );
};
