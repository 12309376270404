import { gql } from "@apollo/client";

export const GET_PROS_WITH_CONFLICTS = gql`
  query GetPros($proposed: ProposedSchedule!, $categoryId: String!) {
    pros(filters: { categoryIds: [$categoryId] }) {
      id
      firstName
      lastName
      phoneNumber
      conflicts(payload: $proposed) {
        ... on MarketplaceConflict {
          type
        }
        ... on ContractConflict {
          type
          contract {
            id
            contractLength
            contractInterval
            recruitment {
              id
              client {
                firstName
                lastName
              }
            }
          }
        }
        ... on AssignmentConflict {
          type
          assignment {
            id
            date
          }
        }
      }
    }
  }
`;

export const GET_PRO = gql`
  query GetPro($id: String!) {
    pro(id: $id) {
      id
      firstName
      lastName
      email
      associatedLaborHackEmail
      phoneNumber
      userId
      proApplication {
        id
        categoryId
        dateOfBirth
        yearsOfExperience
        trainingType
        incomeLevel
      }
      trades {
        id
        name
        currentLevel(proId: $id)
        levels {
          id
          requirements {
            id
            name
            description
            isPassed(proId: $id)
            discounts(proId: $id) {
              id
              code
            }
            proTradeRequirement(proId: $id) {
              attemptsRemaining
              hasPassed
              results {
                attemptId
                passed
                submittedAt
                scores
              }
            }
          }
        }
      }
      identityVerification(showDeclined: true) {
        id
        photo
        returnedPhoto
        identificationNumber
        identificationDocument
        status
        type
      }
    }
  }
`;
