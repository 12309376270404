import { configureStore } from "@reduxjs/toolkit";

import {
  ClientRegReducer,
  ClientRegisterAction,
  RegisterClientSelectors,
} from "./client.slice";

import {
  ServiceReducer,
  ServiceAction,
  ServiceSelectors,
} from "./service.slice";
import {
  TradesmanRegReducer,
  RegisterTradsSelectors,
  TradesmanRegisterAction,
} from "./tradesman.slice";

import {
  RegisterAdminReducer,
  RegisterAdminSelectors,
  RegisterAdminAction,
} from "./admin-register.slice";
import {
  CategoryRegisterAction,
  CategoryRegisterSelectors,
  CategoryRegReducer,
} from "./category.slice";

import {
  SubCatRegisterSelectors,
  subCategoryRegReducer,
  SubCategoryRegisterAction,
} from "./subcategory.slice";
import {
  CatEditSelectors,
  CategoryEditReducer,
  CategoryEditAction,
} from "./editCategory.slice";
import {
  SubCatEditSelectors,
  SubCategoryEditAction,
  subCategoryEditReducer,
} from "./editSubcategory.slice";
import {
  TaskEditSelectors,
  TaskEditAction,
  TaskEditReducer,
} from "./editTask.slice";
import {
  CatServiceReducer,
  CatServiceActions,
  CatServiceSelectors,
} from "./category-service.slice";

import { OrderReducer, OrderSelectors, OrderActions } from "./order.slice";
import { JobReducer, JobActions, JobSelectors } from "./job.slice";
import {
  AssignmentReducer,
  AssignmentActions,
  AssignmentSelectors,
} from "./assignment.slice";
import { TaskRegAction, TaskRegReducer, TaskRegSelectors } from "./tasks.slice";

const store = configureStore({
  reducer: {
    service: ServiceReducer,
    registerClient: ClientRegReducer,
    registerTradesman: TradesmanRegReducer,
    registerAdmin: RegisterAdminReducer,
    registerSubCategory: subCategoryRegReducer,
    categoryService: CatServiceReducer,
    orders: OrderReducer,
    jobs: JobReducer,
    assignment: AssignmentReducer,
    addCategory: CategoryRegReducer,
    addTask: TaskRegReducer,
    editSubcategory: subCategoryEditReducer,
    editTask: TaskEditReducer,
    editCategory: CategoryEditReducer,
  },
});

export {
  store,
  ServiceAction,
  ServiceSelectors,
  RegisterTradsSelectors,
  TradesmanRegisterAction,
  RegisterAdminAction,
  RegisterAdminSelectors,
  SubCatRegisterSelectors,
  SubCategoryRegisterAction,
  CatServiceActions,
  CatServiceSelectors,
  ClientRegisterAction,
  RegisterClientSelectors,
  OrderSelectors,
  OrderActions,
  JobActions,
  JobSelectors,
  AssignmentActions,
  AssignmentSelectors,
  CategoryRegisterAction,
  CategoryRegisterSelectors,
  TaskRegAction,
  TaskRegSelectors,
  SubCatEditSelectors,
  SubCategoryEditAction,
  TaskEditSelectors,
  TaskEditAction,
  CatEditSelectors,
  CategoryEditAction,
};
