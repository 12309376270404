import { useQuery } from "@apollo/client";
import {
	FormControl,
	FormLabel,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Stack,
	Switch,
	Text,
} from "@chakra-ui/react";
import React, {
	Dispatch,
	FC,
	SetStateAction,
	useState,
	useEffect,
	useMemo,
} from "react";
import { Button, Checkbox, Icon, Message } from "semantic-ui-react";
import InvoiceSection from "../../../components/orders/components/InvoiceSection";
import { IInvoice, InvoiceDetails, PaymentProvider } from "../../../types";
import RequestFailed from "../../../_components/RequestFailed";
import { AssignmentTableRow } from "../types";
import { PAYMENT_PROVIDERS } from "../_graphql/query";
import { SplitPayments } from "./SplitPayments/SplitPayments";
import { formatAsNaira } from "../../../helpers/helpers";

export interface EditMaterialsInvoiceProps {
	assignments: AssignmentTableRow[];
	invoice?: IInvoice;
	mode: "create" | "edit";
	jobIdToInvoiceDetailUpdate: {
		[key: string]: InvoiceDetails;
	};
	setJobIdToInvoiceDetailUpdate: Dispatch<
		SetStateAction<{
			[key: string]: InvoiceDetails;
		}>
	>;
	changeMaterialsInvoice: any;
	changeMaterialsInvoiceResponse: any;
	clientMarketingCampaign?: string;
	availableAutoAppliedDiscountBalance?: number;
}

export const EditMaterialsInvoice: FC<EditMaterialsInvoiceProps> = ({
	assignments,
	jobIdToInvoiceDetailUpdate,
	setJobIdToInvoiceDetailUpdate,
	invoice,
	mode,
	changeMaterialsInvoice,
	changeMaterialsInvoiceResponse,
	clientMarketingCampaign,
	availableAutoAppliedDiscountBalance,
}) => {
	const [
		allowedPaymentProviderChannels,
		setAllowedPaymentProviderChannels,
	] = useState<string[]>([]);

	const [enableCommissionCap, setEnableCommissionCap] = useState<boolean>(true);

	const [
		useDefaultCommissionCap,
		setUseDefaultCommissionCap,
	] = useState<boolean>(true);

	const [commissionCap, setCommissionCap] = useState<number>(15000);

	const [showSplitPayment, setShowSplitPayment] = useState(false);

	const [splitPayments, setSplitPayments] = useState<number[]>([50, 50]);

	const { loading, data, error } = useQuery<{
		paymentProviders: PaymentProvider[];
	}>(PAYMENT_PROVIDERS);

	const paymentProviders = data?.paymentProviders;

	const paymentProvidersGroupedByChannel = paymentProviders?.reduce(
		(acc, curr) => {
			if (!acc[curr.channel]) {
				acc[curr.channel] = [];
			}
			acc[curr.channel].push(curr);
			return acc;
		},
		{} as {
			[key: string]: PaymentProvider[];
		}
	);

	const preSelectedChannels = useMemo(() => {
		/**
		 * Initializing a set to make sure channels are unique
		 *
		 * The allowedPaymentProvider property on the invoice is an array of provider ids
		 * We want to get their unique channels
		 */
		const preSelectedChannelsSet = new Set<string>();

		if (invoice && paymentProviders) {
			invoice.allowedPaymentProviders.forEach((allowedPaymentProvider) => {
				/**
				 * Check if the invoice's allowed payment provider exists in the paymentProviders array
				 *
				 * If it does, we want to add its channel to the set
				 *
				 * if it doesn't, then we simply ignore it
				 */
				const providerChannel = paymentProviders.find(
					(provider) => provider.id === allowedPaymentProvider
				)?.channel;

				providerChannel && preSelectedChannelsSet.add(providerChannel);
			});
		}

		return Array.from(preSelectedChannelsSet);
	}, [paymentProviders, invoice]);

	useEffect(() => {
		if (paymentProviders && invoice) {
			setAllowedPaymentProviderChannels(preSelectedChannels);
		}
	}, [paymentProviders, invoice]);

	useEffect(() => {
		/**
		 * Set the split payments state to the invoice's split payments
		 */

		if (invoice) {
			if (invoice.splitPayments?.length) {
				setShowSplitPayment(true);
				setSplitPayments(
					invoice.splitPayments.map(({ percentage }) => percentage)
				);
			}
		}
	}, [invoice]);

	if (error) {
		<RequestFailed errorMessage={error.message} />;
	}

	const handlePaymentProviderChannelCheckboxChange = (
		channel: string,
		checked?: boolean
	) => {
		setAllowedPaymentProviderChannels((channels) => {
			if (checked) {
				return [...channels, channel];
			}

			return channels.filter((allowedChannel) => allowedChannel !== channel);
		});
	};

	const getPaymentProviderChannelLabel = (
		channel: string,
		providers: PaymentProvider[]
	) => {
		return `${channel.toLowerCase()} (${providers
			.map(({ name }) => name)
			.join(", ")})`;
	};

	const addSplitPayment = (index: number) => {
		setSplitPayments((prev) => {
			const clone = prev.slice();
			const splitValue = prev[index] / 2; // split upfront payment into 2, replace the upfront payment with the first half and create  anew payment with the other half
			clone.splice(index, 1, splitValue, splitValue);
			return clone;
		});
	};

	const removeSplitPayment = (index: number) => {
		setSplitPayments((prev) => {
			const clone = prev.slice();
			const removedValue = clone[index];
			clone[0] += removedValue; // add the removed selection back to upfront payments
			clone.splice(index, 1);
			return clone;
		});
	};

	const handleSplitPaymentChange = (
		value: string,
		isValid: boolean,
		index: number
	) => {
		setSplitPayments((prev) => {
			const clone = prev.slice();

			// if there is a progress payment, make sure the change doesn't make balance negative
			const change = Number(value) - clone[index];

			if (clone[clone.length - 1] - change < 1) {
				return clone;
			}

			clone[index] = Number(value);
			clone[clone.length - 1] -= change;
			return clone;
		});
	};

	return (
		<div className="o-orderDetail__editInvoiceWrapper">
			<h4>Edit Invoice</h4>
			{changeMaterialsInvoiceResponse.error && (
				<Message negative>
					<Message.Header>
						{changeMaterialsInvoiceResponse.error.message}
					</Message.Header>
				</Message>
			)}
			<table>
				<thead>
					<tr
						style={{
							borderTop: "none",
						}}
					>
						<th>Item</th>
						<th></th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					{assignments.map(({ category, tradesman, jobs, id, location }) => {
						return (
							<InvoiceSection
								setJobIdToInvoiceDetailUpdate={setJobIdToInvoiceDetailUpdate}
								jobIdToInvoiceDetailUpdate={jobIdToInvoiceDetailUpdate}
								category={category}
								tradesman={tradesman}
								jobs={jobs}
								location={location}
								key={id}
							/>
						);
					})}
				</tbody>
			</table>
			{/**
			 * display client's marketing campaign and available auto applied discount balance
			 */}
			<div className="mt-4">
				{clientMarketingCampaign && (
					<Text>
						<span className="font-semibold text-[#003952]">
							Marketing Campaign:
						</span>{" "}
						{clientMarketingCampaign}
					</Text>
				)}
				{availableAutoAppliedDiscountBalance && (
					<Text>
						<span className="font-semibold text-[#003952]">
							Available Discount Balance:
						</span>{" "}
						{formatAsNaira(availableAutoAppliedDiscountBalance)}
					</Text>
				)}
			</div>

			<div className="my-4">
				<div className="my-4 flex flex-col">
					<h5 className="text-primary-500 font-semibold">
						Set Payment Providers
					</h5>
					{loading ? (
						<Icon name="spinner" loading />
					) : (
						Object.entries(paymentProvidersGroupedByChannel || {}).map(
							([channel, providers]) => {
								return (
									<Checkbox
										className="mb-2 capitalize"
										label={getPaymentProviderChannelLabel(channel, providers)}
										checked={allowedPaymentProviderChannels.includes(channel)}
										onChange={(e, { checked }) =>
											handlePaymentProviderChannelCheckboxChange(
												channel,
												checked
											)
										}
									/>
								);
							}
						)
					)}
				</div>

				<div className="my-4 flex flex-col">
					<h5 className="text-primary-500 font-semibold">Payment Options</h5>
					<Checkbox
						className="mb-2"
						label="Split Payments"
						checked={showSplitPayment}
						onChange={(e, { checked }) => setShowSplitPayment((prev) => !prev)}
					/>
					{showSplitPayment && (
						<SplitPayments
							values={splitPayments.map((value) => value.toString())}
							onAddNewField={addSplitPayment}
							onRemoveField={removeSplitPayment}
							onChange={handleSplitPaymentChange}
						/>
					)}
				</div>

				{mode === "create" && (
					<Stack>
						<Text fontWeight="semibold" color="primary.500">
							Material Commission Settings
						</Text>
						<Stack>
							<FormControl display="flex" alignItems="center">
								<FormLabel htmlFor="enable-commission-cap" mb="0">
									Enable Material Commission Cap
								</FormLabel>
								<Switch
									id="enable-commission-cap"
									isChecked={enableCommissionCap}
									onChange={(e) => setEnableCommissionCap((prev) => !prev)}
									data-testid="enable-commission-cap"
								/>
							</FormControl>
							{enableCommissionCap && (
								<Stack paddingX={4}>
									<FormControl display="flex" alignItems="center">
										<FormLabel htmlFor="use-default-commission-cap" mb="0">
											Use Default Commission Cap
										</FormLabel>
										<Switch
											id="use-default-commission-cap"
											isChecked={useDefaultCommissionCap}
											onChange={(e) =>
												setUseDefaultCommissionCap((prev) => !prev)
											}
											data-testid="use-default-commission-cap"
										/>
									</FormControl>

									{!useDefaultCommissionCap && (
										<NumberInput
											value={commissionCap}
											min={10}
											onChange={(_, value) => setCommissionCap(value)}
											maxW="sm"
											data-testid="commission-cap"
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									)}
								</Stack>
							)}
						</Stack>
					</Stack>
				)}
			</div>

			<div className="m-orderDetail__editInvoiceAction">
				<Button
					disabled={!allowedPaymentProviderChannels.length}
					loading={changeMaterialsInvoiceResponse.loading}
					onClick={() => {
						let customCommissionCap;

						if (enableCommissionCap) {
							if (!useDefaultCommissionCap) {
								customCommissionCap = commissionCap;
							}
						} else {
							customCommissionCap = 0;
						}

						changeMaterialsInvoice({
							variables: {
								input: {
									...(mode === "create"
										? { assignmentIds: assignments.map(({ id }) => id) }
										: { invoiceId: invoice?.id }),
									...(paymentProvidersGroupedByChannel && {
										allowedPaymentProviders: allowedPaymentProviderChannels
											.flatMap(
												(channel) => paymentProvidersGroupedByChannel[channel]
											)
											.map(({ id }) => id),
									}),
									...(customCommissionCap !== undefined && {
										customMaterialCommissionCap: customCommissionCap,
									}),
									splits: showSplitPayment ? splitPayments : undefined,
									jobUpdates: Object.entries(jobIdToInvoiceDetailUpdate).map(
										([key, value]) => {
											const materials = value.materials?.length
												? value.materials?.map(
														({ unitPrice, name, quantity }) => {
															return {
																name,
																unitPrice,
																quantity,
															};
														}
												  )
												: undefined;

											return {
												jobId: key,
												materials,
												labor: value.labor,
											};
										}
									),
								},
							},
						});
					}}
				>
					Save and continue
				</Button>
			</div>
		</div>
	);
};
