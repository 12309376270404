import { getTransferBadgeColor, GET_REFERRER_BY_ID, GET_REFERRER_PAYOUT } from "@/features/referrer-payouts/constants";
import { Referrer, ReferrerPayout } from "@/features/referrer-payouts/types";
import { formatAsNaira } from "@/helpers/helpers";
import { TransferStatus } from "@/types";
import Loading from "@/_components/Loading";
import RequestFailed from "@/_components/RequestFailed";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Badge, Button, Divider, FormControl, FormErrorMessage, FormLabel, HStack, PinInput, PinInputField, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { FaArrowRight, FaCheckCircle, FaStopCircle, FaSync } from "react-icons/fa";
import { useParams } from "react-router-dom";

export const APPROVE_REFERRER_PAYOUT = gql`
  mutation ApproveReferrerPayout($payoutId: String!, $providerId: String!) {
    approveReferrerPayout(payoutId: $payoutId, providerId: $providerId) {
      id
      transfer {
        id
        transferCode
      }
    }
  }
`;

export const FINALIZE_REFERRER_PAYOUT = gql`
  mutation FinalizeReferrerPayout($payoutId: String!, $otp: String!) {
    finalizeReferrerPayout(payoutId: $payoutId, otp: $otp) {
      id
      transfer {
        id
        status
      }
    }
  }
`;

export default function ReferrerPayoutConfirmationView() { 
  const { id } = useParams<{ id: string }>();

  const [otp, setOtp] = useState<string>();

  const {loading: referrerPayoutLoading, error: referrerPayoutError, data: referrerPayoutData, refetch: refetchPayout} = useQuery<{ referrerPayoutById: ReferrerPayout }>(GET_REFERRER_PAYOUT, {
    variables: {
      referrerPayoutId: id,
    },
    onCompleted(data) {
      getReferrer({
        variables: {
          referrerId: data.referrerPayoutById.referrer.id,
        }
      })
    },

  });

  const [getReferrer, {
    loading: referrerLoading,
    error: referrerError,
    data: referrerData,
  }] = useLazyQuery<{ getReferrerById: Referrer }>(GET_REFERRER_BY_ID);
  
  const [approveReferrerPayout, approveReferrerPayoutResponse] = useMutation(APPROVE_REFERRER_PAYOUT, {
    
  });

  const [finalizeReferrerPayout, finalizeReferrerPayoutResponse] = useMutation(FINALIZE_REFERRER_PAYOUT, {
    onCompleted() {
      refetchPayout();
    },
  });

  if (referrerPayoutLoading || referrerLoading) return <Loading />;

  if (
		referrerPayoutError ||
		referrerError ||
		approveReferrerPayoutResponse.error
	)
		return <RequestFailed />;

  if (!referrerPayoutData) return <RequestFailed />;

  const referrerPaymentInfo = referrerData?.getReferrerById.paymentProviderInformation?.providerData[0]?.providers[0];

  const payout = referrerPayoutData?.referrerPayoutById;

  const payoutTransfer = payout?.transfer;

  if (!payoutTransfer) return <RequestFailed />;

  const transferStatus = payoutTransfer.status;
  
  const isApprovable =
    payout?.transfer?.status === TransferStatus.AWAITING_APPROVAL;
  
  const payoutAmount = payout.amount;
  
  const payoutOverrideAmount = payout.override?.amount;

  return (
		<div className="grid grid-cols-3 gap-4">
			<div className="col-span-2">
				<div className="flex flex-col gap-8 p-6 bg-white border">
					<div className="mb-4">
						<h2 className="text-primary-500 text-lg font-medium m-0">
							Referrer Payout Confirmation #{id.slice(0, 8)}
						</h2>
						<p className="m-0 text-primary-300 text-sm">
							Shows a detailed view of a referrer payout request
						</p>
					</div>
					<div className="grid grid-cols-[2fr,3fr] gap-2 max-w-lg">
						<p className="m-0 text-primary-300 text-sm">Referrer's name</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.referrer.name}
						</p>
						<p className="m-0 text-primary-300 text-sm">Business name</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.referrer.businessName}
						</p>
						<p className="m-0 text-primary-300 text-sm">Email</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.referrer.email}
						</p>
						<p className="m-0 text-primary-300 text-sm">Referral Code</p>
						<p className="m-0 text-primary-500 text-sm font-medium">
							{payout?.referrer.referralCode}
						</p>
					</div>
				</div>
			</div>

			<div>
				<div className="flex flex-col gap-6 p-6 bg-white border">
					<div>
						<h2 className="text-primary-500 text-lg font-medium m-0">
							Breakdown
						</h2>
					</div>
					<Divider />
					<div className="flex flex-col gap-4">
						<h3 className="font-medium text-primary-500 text-sm">
							Payment Information
						</h3>
						{!referrerPaymentInfo && (
							<Alert status="error">
								<AlertIcon />
								<AlertTitle className="text-sm !font-medium">
									No payment information found
								</AlertTitle>
								<AlertDescription></AlertDescription>
							</Alert>
						)}
						<div className="flex flex-col gap-2">
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Name</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{referrerPaymentInfo?.bankInformation.accountName || "N/A"}
								</p>
							</div>
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">AC Number</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{referrerPaymentInfo?.bankInformation.accountNumber || "N/A"}
								</p>
							</div>
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Bank Name</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{referrerPaymentInfo?.bankInformation.bankName || "N/A"}
								</p>
							</div>
						</div>
					</div>

					<Divider />

					<div className="flex flex-col gap-4">
						<div className="flex flex-col gap-2">
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Status</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									<Badge colorScheme={getTransferBadgeColor(transferStatus)}>
										{transferStatus.replace("_", " ")}
									</Badge>
								</p>
							</div>
							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Amount (NGN)</p>
								<p className="m-0 text-primary-500 text-sm font-medium">
									{formatAsNaira(payoutAmount)}
								</p>
							</div>

							<div className="flex justify-between">
								<p className="m-0 text-primary-300 text-sm">Overridden</p>
								{payout.override ? (
									<FaCheckCircle className="text-green-500" />
								) : (
									<FaStopCircle className="text-red-500" />
								)}
							</div>

							{payoutOverrideAmount && (
								<div className="flex justify-between">
									<p className="m-0 text-primary-300 text-sm">
										Override Amount
									</p>
									<p className="m-0 text-primary-500 text-sm font-medium">
										{formatAsNaira(payoutOverrideAmount)}
									</p>
								</div>
							)}
						</div>
					</div>
					<Divider />
					{isApprovable && (
						<div className="flex flex-col gap-2">
							{!payoutTransfer.transferCode && (
								<Button
									colorScheme="green"
									rightIcon={<FaArrowRight className="inline-block" />}
									isLoading={approveReferrerPayoutResponse.loading}
									isDisabled={!referrerPaymentInfo}
									onClick={() => {
										approveReferrerPayout({
											variables: {
												payoutId: id,
												providerId: "paystack",
											},
										});
									}}
								>
									Approve Payout
								</Button>
							)}
							{payoutTransfer.transferCode && (
								<>
									<FormControl
										isInvalid={!!finalizeReferrerPayoutResponse.error}
									>
										<FormLabel>Enter OTP</FormLabel>
										<Stack>
											<HStack w={"100%"}>
												<PinInput
													size={"lg"}
													otp
													onComplete={(otp) => {
														setOtp(otp);
													}}
													onChange={() => {
														finalizeReferrerPayoutResponse.reset();
													}}
												>
													<PinInputField />
													<PinInputField />
													<PinInputField />
													<PinInputField />
													<PinInputField />
													<PinInputField />
												</PinInput>
											</HStack>

											<HStack justifyContent={"space-between"}>
												<div className="flex justify-start">
													<Button
														variant="link"
														leftIcon={<FaSync className="inline-block" />}
														isLoading={approveReferrerPayoutResponse.loading}
														onClick={() => {
															approveReferrerPayout({
																variables: {
																	payoutId: id,
																	providerId: "paystack",
																},
															});
														}}
													>
														Refresh OTP
													</Button>
												</div>
												<Button
													alignSelf={"f"}
													colorScheme="green"
													rightIcon={<FaCheckCircle className="inline-block" />}
													isLoading={finalizeReferrerPayoutResponse.loading}
													isDisabled={!otp}
													onClick={() => {
														finalizeReferrerPayout({
															variables: {
																payoutId: id,
																otp,
															},
														});
													}}
												>
													Finalize Payout
												</Button>
											</HStack>
										</Stack>
										<FormErrorMessage>
											{finalizeReferrerPayoutResponse.error?.message}
										</FormErrorMessage>
									</FormControl>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}