import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { types } from "../service";
import { ApolloClient } from "@apollo/client";
import {
  GET_ALL_ADMINS,
  GET_ALL_TRADESMEN,
  GET_ALL_CLIENTS,
} from "./../graphql/query";

const initialState: types.IServiceState = {
  getAllRoles: [],
  listAllAdmins: [],
  listAllTradesmen: [],
  listAllClientsFromAdmin: [],
  status: "idle",
  error: null,
};

export const fetchAdmins = createAsyncThunk(
  "services/fetchedAdmins",
  async ({ client }: { client: ApolloClient<object> }, { dispatch }) => {
    const { data } = await client.query({
      query: GET_ALL_ADMINS,
    });
    return data;
  }
);

export const fetchTradesmen = createAsyncThunk(
  "services/fetchedTradesmen",
  async ({ client }: { client: ApolloClient<object> }, { dispatch }) => {
    const { data } = await client.query({
      query: GET_ALL_TRADESMEN,
    });
    return data;
  }
);
export const fetchClients = createAsyncThunk(
  "services/fetchedClients",
  async ({ client }: { client: ApolloClient<object> }, { dispatch }) => {
    const { data } = await client.query({
      query: GET_ALL_CLIENTS,
    });
    return data;
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    servicesFetched: {
      reducer: (state, action: any) => {
        state.getAllRoles = action.payload.allRoles;
      },
      prepare: (data: types.IRoleDataResult) => {
        const allRoles: types.IAdminRoles[] = [];

        data.getAllRoles.data.forEach((data) => {
          allRoles.push(data);
        });

        return {
          payload: { allRoles },
        };
      },
    },
  },
  extraReducers: {
    [fetchAdmins.pending.type]: (state) => {
      state.status = "loading";
    },
    [fetchAdmins.rejected.type]: (state) => {
      state.status = "failed";
    },
    [fetchTradesmen.pending.type]: (state) => {
      state.status = "loading";
    },
    [fetchTradesmen.rejected.type]: (state) => {
      state.status = "failed";
    },
    [fetchClients.pending.type]: (state) => {
      state.status = "loading";
    },
    [fetchClients.rejected.type]: (state) => {
      state.status = "failed";
    },
    [fetchAdmins.fulfilled.type]: (state, action) => {
      const data: types.IGetAdminResult = action.payload;
      const allAdmins: types.IAdminData[] = [];

      data.listAllAdmins !== undefined &&
        data.listAllAdmins.data.forEach((data) => {
          allAdmins.push(data);
        });

      state.listAllAdmins = allAdmins;
      state.status = "idle";
    },
    [fetchTradesmen.fulfilled.type]: (state, action) => {
      const data: types.IGetTradesmanResult = action.payload;
      const allTradesmen: types.ITradesmenData[] = [];

      data.listAllTradesmen !== undefined &&
        data.listAllTradesmen.data.forEach((data) => {
          allTradesmen.push(data);
        });

      state.listAllTradesmen = allTradesmen;
      state.status = "idle";
    },
    [fetchClients.fulfilled.type]: (state, action) => {
      const data: types.IGetClientsResult = action.payload;
      const allClients: types.IClientData[] = [];

      data.listAllClients !== undefined &&
        data.listAllClients.data.forEach((data) => {
          allClients.push(data);
        });
      state.listAllClientsFromAdmin = allClients;
      state.status = "idle";
    },
  },
});
const selectAllRoles = (state: any) => state.service.getAllRoles;
const selectAllAdmin = (state: any) => state.service.listAllAdmins;
const selectAllTradesmen = (state: any) => state.service.listAllTradesmen;
const selectAllClients = (state: any) => state.service.listAllClientsFromAdmin;
const selectStatus = (state: any) => state.service.listAllTradesmen;

export const ServiceReducer = serviceSlice.reducer;
export const ServiceAction = {
  fetchAdmins,
  fetchTradesmen,
  fetchClients,
  ...serviceSlice.actions,
};

export const ServiceSelectors = {
  selectAllRoles,
  selectAllAdmin,
  selectAllTradesmen,
  selectAllClients,
  selectStatus,
};
