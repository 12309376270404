import { useQuery } from "@apollo/client";
import { ColumnsType } from "antd/lib/table";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import {
  extractItemsFromRelayStylePaginationResponse,
  formatRelativeTime,
} from "../../helpers/helpers";

import { components as LayoutComponents } from "../../components/layout";
import { LIST_ASSIGNMENT_PAYMENTS } from "./graphql/query";
import { AssignmentPayment, TransferStatus } from "./types";
import Island from "../../_components/Island";
import { RelayStylePaginatedResponse } from "../../types";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";

const { Trail, CustomTable, CustomLabel } = LayoutComponents;

const PaymentConfirmations: FC = () => {
  const history = useHistory();

  const { data, fetchMore, loading, error, refetch } = useQuery<{
    assignmentPayments: RelayStylePaginatedResponse<AssignmentPayment>;
  }>(LIST_ASSIGNMENT_PAYMENTS, {
    variables: {
      first: 10,
      after: null,
      filters: {
        transferStatus: TransferStatus.AWAITING_APPROVAL,
      },
    },
  });

  const columns: ColumnsType<AssignmentPayment & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.id.substring(0, 8)}</p>;
      },
      width: "5%",
    },
    {
      title: "Order Code",
      render: (text, record) => {
        return <p>{record.order.orderCode}</p>;
      },
      width: "5%",
    },
    {
      title: "Purpose",
      render: (text, record) => {
        return (
          <p
            style={{
              textTransform: "capitalize",
            }}
          >
            {record.type.toString().toLowerCase()}
          </p>
        );
      },
      width: "10%",
    },
    {
      title: "To",
      render: (text, record) => {
        return (
          <p>{`${record.tradesman?.firstName} ${record.tradesman?.lastName}`}</p>
        );
      },
      width: "10%",
    },
    {
      title: "Amount",
      render: (text, record) => {
        return (
          <p>
            {record.transfer.amount ? `NGN ${record.transfer.amount}` : "N/A"}
          </p>
        );
      },
      width: "10%",
    },
    {
      title: "Status",
      render: (text, record) => {
        const color = {
          r: 0,
          g: 0,
          b: 0,
        };

        switch (record.transfer.status) {
          case TransferStatus.PENDING:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case TransferStatus.AWAITING_APPROVAL:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case TransferStatus.CONFIRMED:
            color.r = 86;
            color.g = 189;
            color.b = 102;
            break;
          case TransferStatus.FAILED:
            color.r = 244;
            color.g = 105;
            color.b = 86;
            break;

          default:
            break;
        }

        return (
          <CustomLabel
            color={color}
            text={record.transfer.status.toString().replace("_", " ")}
          />
        );
      },
      width: "10%",
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <p>{formatRelativeTime(new Date(record.createdAt).getTime())}</p>
        );
      },
      width: "10%",
    },
  ];

  if (loading) return <Loading />;

  if (error || !data) return <RequestFailed />;

  const getMore = () => {
    fetchMore({
      variables: {
        first: 10,
        after: data?.assignmentPayments.pageInfo.endCursor,
        filters: {
          transferStatus: TransferStatus.AWAITING_APPROVAL,
        },
      },
    });
  };

  return (
    <Island
      header={<Trail root='Confirmations'></Trail>}
      text='All funds transfer requests awaiting a confirmation'
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          height: "48px",
          color: "#003952",
          cursor: "pointer",
        }}
        onClick={() => {
          refetch();
        }}
      >
        <p
          style={{
            margin: "0 10px 0 0",
            fontWeight: 600,
          }}
        >
          Refresh{" "}
        </p>
        <Icon
          loading={loading}
          name='refresh'
          style={{
            cursor: "pointer",
            height: "14px",
            lineHeight: "14px",
            margin: 0,
          }}
          onClick={() => {
            refetch();
          }}
        ></Icon>
      </div>
      <CustomTable
        onRow={(record: any, index: any) => {
          return {
            onClick: () => {
              history.push("/payments/confirmations/" + record.id);
            },
          };
        }}
        columns={columns}
        data={extractItemsFromRelayStylePaginationResponse(
          data.assignmentPayments
        ).map((assignmentPayment: AssignmentPayment) => {
          return {
            key: assignmentPayment.id,
            ...assignmentPayment,
          };
        })}
        loading={loading}
        handleTableChange={() => {}}
        fetchMore={getMore}
        hasMore={data.assignmentPayments.pageInfo.hasNextPage}
      ></CustomTable>
    </Island>
  );
};

export default PaymentConfirmations;
