import React, { FC } from "react";
import { hooks } from "../../../firebase";
import { Route, Redirect } from "react-router-dom";

const { useAuth } = hooks;
export const ProtectedRoute: FC<{
  path: string;
}> = ({ path, children }) => {
  const { user, initializing } = useAuth();

  if (initializing) {
    return <p>Loading...</p>;
  }

  return user ? (
    <Route path={path}>{children}</Route>
  ) : (
    <Redirect to="/login"></Redirect>
  );
};
