import clsx from "clsx";
import React, { FC } from "react";

export interface PillProps {
  className?: string;
}

export const Pill: FC<PillProps> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        "rounded-full py-1 px-3 mr-2 bg-primary-150 text-primary-500 font-semibold w-fit",
        className
      )}
    >
      {children}
    </div>
  );
};
