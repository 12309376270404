import { useMutation, useQuery } from "@apollo/client";
import { notification } from "antd";
import React, { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Icon, Message, Modal } from "semantic-ui-react";

import { components as LayoutComponents } from "../../layout";
import { TransferStatus } from "../../../types";
import {
  APPROVE_REFUND_REQUEST,
  CANCEL_REFUND_REQUEST,
  FINALIZE_REFUND_REQUEST,
} from "../graphql/mutation";
import { GET_REFUND_REQUEST } from "../graphql/query";
import { IRefund } from "../types";
import RefundInvoiceDetails from "./RefundInvoiceDetails";
import Island from "../../../_components/Island";
import {
  calculateLaborHackFee,
  calculateMaterialsAndLabor,
  calculateTotal,
} from "../../../helpers/helpers";
import { GET_ORDER_INVOICES } from "../../orders/graphql/query";
import {
  InvoiceType,
  IInvoice as Invoice,
  TransactionStatus,
} from "../../../types";
import { SelectPaymentProvider } from "../../SelectPaymentProvider/SelectPaymentProvider";

const { Trail } = LayoutComponents;

const RefundConfirmationView: FC = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const [otp, setOtp] = useState<string>("");
  const [selectProviderOpen, setSelectProviderOpen] = useState<boolean>(false);

  const getRefundRequest = useQuery(GET_REFUND_REQUEST, {
    variables: {
      id,
    },
  });

  const data: IRefund = getRefundRequest.data?.getRefundRequestById.data;

  const [approveRefundRequest, approveRefundRequestResult] = useMutation(
    APPROVE_REFUND_REQUEST,
    {
      onError: () => {},
    }
  );
  const [finalizeRefundRequest, finalizeRefundRequestResponse] = useMutation(
    FINALIZE_REFUND_REQUEST,
    {
      onError: () => {},
    }
  );

  const [cancelRefundRequest, cancelRefundRequestResult] = useMutation(
    CANCEL_REFUND_REQUEST,
    {
      onError: () => {},
    }
  );

  const getOrderMaterialInvoicesResponse = useQuery(GET_ORDER_INVOICES, {
    variables: {
      orderId: data?.order.id,
      type: InvoiceType.MATERIALS,
    },
  });

  const invoices: Invoice[] | undefined =
    getOrderMaterialInvoicesResponse.data?.getInvoicesByOrder;

  const paidInvoices = invoices?.filter(({ isFullyPaid }) => isFullyPaid);

  const assignments = paidInvoices?.flatMap(({ assignments }) => assignments);

  const jobs = assignments?.flatMap(({ jobs }) => jobs) || [];

  const {
    materials: totalMaterialsCost,
    labor: totalLaborCost,
  } = calculateMaterialsAndLabor(jobs);

  const laborHackFee = calculateLaborHackFee(
    totalMaterialsCost,
    totalLaborCost
  );

  const invoiceTotal = calculateTotal(totalMaterialsCost, laborHackFee);

  if (
    getRefundRequest.loading ||
    !getRefundRequest.data ||
    getOrderMaterialInvoicesResponse.loading
  ) {
    return <div>loading...</div>;
  }

  if (cancelRefundRequestResult.data) {
    notification.success({
      message: cancelRefundRequestResult.data.cancelRefundRequest.message,
      duration: 1.5,
      onClose: () => {
        history.push("/refunds/confirmations");
      },
    });
  }

  let err;

  if (getRefundRequest.error) {
    err = getRefundRequest.error.message;
  }

  if (approveRefundRequestResult.error) {
    err = approveRefundRequestResult.error.message;
  }

  if (getOrderMaterialInvoicesResponse.error) {
    err = getOrderMaterialInvoicesResponse.error.message;
  }

  if (finalizeRefundRequestResponse.error) {
    err = finalizeRefundRequestResponse.error.message;
  }

  if (cancelRefundRequestResult.error) {
    err = cancelRefundRequestResult.error.message;
  }

  const handleApproveRefund = (providerId: string) => {
    approveRefundRequest({
      variables: {
        data: {
          refundRequestId: data.id,
          providerId,
        },
      },
    });

    setSelectProviderOpen(false);
  };

  return (
    <>
      <Modal
        open={selectProviderOpen}
        size='small'
        closeOnDimmerClick
        closeIcon
        onClose={() => setSelectProviderOpen(false)}
      >
        <SelectPaymentProvider
          recipientUserId={data.order.client.userId}
          onDone={handleApproveRefund}
        />
      </Modal>
      <Island
        header={
          <Trail root='Payment Confirmation' child={id.substring(0, 8)}></Trail>
        }
        text={`Send refund to client involved on this order`}
      >
        {err && (
          <Message negative>
            <Message.Header>{err}</Message.Header>
          </Message>
        )}
        <div className='o-singlePayment__wrapper'>
          <div className='o-singlePayment__container'>
            {finalizeRefundRequestResponse.data ||
            data?.transfer.status === TransferStatus.CONFIRMED ? (
              <div className='o-singlePayment__successWrapper'>
                <Icon name='check circle outline' />
                <h3>Payment Queued Successfully</h3>
              </div>
            ) : (
              <div className='o-singlePayment__transferForm'>
                <h2>send funds to:</h2>
                <div className='m-singlePayment__transferFormInput'>
                  <Icon name='send'></Icon>
                  <input type='text' value={data.order.client.name} readOnly />
                </div>
                <div className='m-singlePayment__transferFormInput'>
                  <Icon name='align left'></Icon>
                  <textarea placeholder='' value={data.reason} readOnly />
                </div>
                <div className='m-singlePayment__transferFormInput'>
                  <Icon name='money bill alternate outline'></Icon>
                  <input
                    type='number'
                    placeholder='Enter Amount'
                    value={data.transfer.amount}
                    step={100}
                    min={100}
                    readOnly
                  />
                </div>
                {(data.transfer.transferCode ||
                  approveRefundRequestResult.data) && (
                  <div className='m-singlePayment__transferFormInput'>
                    <Icon name='lock'></Icon>
                    <input
                      type='text'
                      placeholder='Enter OTP'
                      value={otp}
                      maxLength={6}
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                    />
                  </div>
                )}
                <div className='m-singlePayment__transferFormActions'>
                  {data.transfer.transferCode ||
                  approveRefundRequestResult.data ? (
                    <button
                      className='a-singlePayment__transferFormButton -positive'
                      onClick={() => {
                        finalizeRefundRequest({
                          variables: {
                            id,
                            otp,
                          },
                        });
                      }}
                    >
                      {finalizeRefundRequestResponse.loading ? (
                        <Icon name='spinner' loading />
                      ) : (
                        "Confirm Refund"
                      )}
                    </button>
                  ) : (
                    <>
                      <button
                        className='a-singlePayment__transferFormButton -negative'
                        onClick={() => {
                          cancelRefundRequest({
                            variables: {
                              id,
                            },
                          });
                        }}
                      >
                        {cancelRefundRequestResult.loading ? (
                          <Icon name='spinner' loading />
                        ) : (
                          "Cancel Request"
                        )}
                      </button>
                      <button
                        className='a-singlePayment__transferFormButton'
                        onClick={() => {
                          setSelectProviderOpen(true);
                        }}
                      >
                        {approveRefundRequestResult.loading ? (
                          <Icon name='spinner' loading></Icon>
                        ) : (
                          "Approve Refund"
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='o-singlePayment__container'>
            <RefundInvoiceDetails
              materialsCost={totalMaterialsCost}
              totalCost={invoiceTotal}
              laborHackFee={laborHackFee}
            />
          </div>
        </div>
      </Island>
    </>
  );
};

export default RefundConfirmationView;
