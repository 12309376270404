import { Route, Switch, useRouteMatch } from "react-router-dom";
import CreateReferrer from "./create-referrer";
import ReferrersList from "./referrers-list";
import ReferrerView from "./[id]";

export default function Referrers() {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/create-referrer`}>
				<CreateReferrer />
			</Route>
			<Route path={`${path}/:id`}>
				<ReferrerView />
			</Route>
			<Route path={`${path}`}>
				<ReferrersList />
			</Route>
		</Switch>
	);
}
