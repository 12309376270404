import React, { FC, useState } from 'react';
import { Dropdown, Icon, Input } from 'semantic-ui-react';
import DatePicker from 'react-date-picker';
import { TradesmanSelectionItem } from './TradesmanSelectionItem';
import { AssignmentActions, AssignmentSelectors } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import {
  GET_TRADESMAN_WITH_AVAILABILITY,
  GET_ASSIGNMENT_SCHEDULE,
} from '../graphql/query';
import {
  Tradesman,
  Availability,
  Order,
  TradesmanAssignment,
} from '../../../types';
import { ONE_DAY_IN_SECONDS } from '../constants';

const {
  tradesmanAdded,
  dateAdded,
  orderIdAddedToAssignment,
} = AssignmentActions;
const { selectCreateAssignmentData } = AssignmentSelectors;

const options = [
  { key: 'name', text: 'By Name', value: 'name' },
  { key: 'ref', text: 'By Reference Code', value: 'ref' },
];

// helper functions to filter returned tradesmen
const filterFunctions = {
  name: (query: string, value: Tradesman) => {
    const { firstName, lastName } = value;

    if (
      query.length > firstName.length &&
      query.length > lastName.length &&
      query.length > (firstName + ' ' + lastName).length
    ) {
      return false;
    }

    for (let index = 0; index < query.length; index++) {
      if (
        query[index].toLowerCase() !== firstName[index]?.toLowerCase() &&
        query[index].toLowerCase() !== lastName[index]?.toLowerCase() &&
        query[index].toLowerCase() !==
          (firstName + ' ' + lastName)[index]?.toLowerCase()
      ) {
        return false;
      }
    }

    return true;
  },
  id: (query: string, value: Tradesman) => {
    const { id } = value;

    if (query.length > id.length) {
      return false;
    }

    for (let index = 0; index < query.length; index++) {
      if (query[index] !== id[index]) {
        return false;
      }
    }

    return true;
  },

  ref: (query: string, value: Tradesman) => {
    const { referralCode } = value;

    if (query.length > referralCode?.length) {
      return false;
    }

    for (let index = 0; index < query.length; index++) {
      if (query[index].toUpperCase() !== referralCode?.[index]) {
        return false;
      }
    }

    return true;
  },
};

const SelectAvailablePro: FC<{
  scheduledDate?: Date;
  selectedCategory: string;
  order: Order;
  assignments: TradesmanAssignment[];
}> = ({ selectedCategory, order, scheduledDate, assignments }) => {
  const dispatch = useDispatch();

  const creationData = useSelector(selectCreateAssignmentData);

  const [searchFilter, setSearchFilter] = useState<'name' | 'id'>('name');
  const [query, setQuery] = useState('');
  const [dateSelected, setDateSelected] = useState<Date>(
    (scheduledDate && new Date(scheduledDate)) || new Date()
  );
  const [startDate, setStartDate] = useState<Date>(
    (scheduledDate && new Date(scheduledDate)) || new Date()
  );

  const getAssignmentSchedule = useQuery(GET_ASSIGNMENT_SCHEDULE, {
    variables: {
      input: {
        categoryId: selectedCategory,
        startDate,
        endDate: new Date(startDate.getTime() + ONE_DAY_IN_SECONDS * 31),
      },
    },
  });

  const getTradesmenWithAvailability = useQuery(
    GET_TRADESMAN_WITH_AVAILABILITY,
    {
      variables: {
        data: {
          categoryId: selectedCategory,
          date: dateSelected.toISOString(),
        },
      },
    }
  );

  const unavailable = new Set(
    getAssignmentSchedule.data?.getAssignmentSchedule.data.map(
      (timestamp: string) => {
        const date = new Date(timestamp);

        return date.toLocaleDateString();
      }
    )
  );

  const uniqueProIds = new Set();

  const uniquePros: Tradesman[] = [];

  assignments.forEach((assignment) => {
    if (!uniqueProIds.has(assignment.tradesman.id)) {
      uniquePros.push(assignment.tradesman);
      uniqueProIds.add(assignment.tradesman.id);
    }
  });

  return (
    <div className="o-selectAvailablePro__wrapper">
      <div className="o-selectAvailablePro__controls">
        <div className="m-selectAvailablePro__controlContainer">
          <Input
            action={
              <Dropdown
                className="a-selectAvailablePro__inputDropdown"
                button
                basic
                floating
                options={options}
                defaultValue="name"
                onChange={(e, data) => {
                  const value: any = data.value;
                  setSearchFilter(value);
                }}
              />
            }
            type="text"
            name="search"
            onChange={(e, data) => {
              setQuery(data.value);
            }}
            icon="search"
            iconPosition="left"
            placeholder="Search Tradesman..."
          ></Input>
        </div>
        <div className="m-selectAvailablePro__controlContainer">
          {/* <label className="a-selectAvailablePro__label" htmlFor="">
            Select a date
          </label> */}
          {scheduledDate && (
            <DatePicker
              calendarIcon={
                <Icon
                  name="calendar alternate outline"
                  style={{
                    margin: '0',
                    fontSize: '16px',
                  }}
                />
              }
              onActiveStartDateChange={({ activeStartDate }) => {
                setStartDate(activeStartDate);
              }}
              tileClassName={({ activeStartDate, date, view }) => {
                return view === 'month' &&
                  unavailable.has(date.toLocaleDateString())
                  ? 'a-suggestedProCard__calenderTile -unavailable'
                  : null;
              }}
              tileDisabled={({ activeStartDate, date, view }) => {
                return (
                  view === 'month' && unavailable.has(date.toLocaleDateString())
                );
              }}
              className="o-selectAvailablePro__datePicker"
              minDate={new Date()}
              clearIcon={null}
              onChange={(value: any) => {
                setDateSelected(value);
                dispatch(dateAdded(value.toISOString()));
              }}
              value={dateSelected}
            ></DatePicker>
          )}
        </div>
      </div>

      <div className="o-selectAvailablePro__results">
        {getTradesmenWithAvailability.loading && (
          <div className="o-selectAvailablePro__resultContainer">
            <div className="m-selectAvailablePro__resultStatus">
              <Icon name="spinner" size="big" loading />
            </div>
          </div>
        )}
        {!getTradesmenWithAvailability.loading &&
          getTradesmenWithAvailability.data && (
            <div className="o-selectAvailablePro__resultContainer">
              {getTradesmenWithAvailability.data.getTradesmenWithAvailabilityByCategory.data
                .filter((tradesman: Tradesman) => {
                  if (query) {
                    return filterFunctions[searchFilter](query, tradesman);
                  }

                  return tradesman.availability !== Availability.ENGAGED;
                })
                .map((tradesman: Tradesman) => {
                  const selected = creationData.tradesman?.id === tradesman.id;

                  return (
                    <TradesmanSelectionItem
                      key={tradesman.id}
                      tradesman={tradesman}
                      selected={selected}
                      onClick={() => {
                        if (selected) {
                          dispatch(tradesmanAdded(null));
                        } else {
                          dispatch(orderIdAddedToAssignment(order.id));
                          dispatch(tradesmanAdded(tradesman));
                          dispatch(dateAdded(dateSelected.toISOString()));
                        }
                      }}
                    />
                  );
                })}
            </div>
          )}

        <h5 className="a-selectAvailablePro__resultHeader">
          Pros already on this order
        </h5>
        <div className="o-selectAvailablePro__resultContainer -small">
          {uniquePros
            .filter(({ categoryId }) => selectedCategory === categoryId)
            .map((tradesman) => {
              const selected = creationData.tradesman?.id === tradesman.id;

              return (
                <TradesmanSelectionItem
                  key={tradesman.id}
                  tradesman={tradesman}
                  selected={selected}
                  onClick={() => {
                    if (selected) {
                      dispatch(tradesmanAdded(null));
                    } else {
                      dispatch(orderIdAddedToAssignment(order.id));
                      dispatch(tradesmanAdded(tradesman));
                      dispatch(dateAdded(dateSelected.toISOString()));
                    }
                  }}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SelectAvailablePro;
