import { gql, useMutation } from "@apollo/client";
import React, { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Icon, Modal } from "semantic-ui-react";

import { components as LayoutComponents } from "../../components/layout";
import {
  APPROVE_ASSIGNMENT_PAYMENT,
  CANCEL_TRANSFER_REQUEST,
  CONFIRM_OFFLINE_TRANSFER,
  FINALIZE_ASSIGNMENT_PAYMENT,
} from "./graphql/mutation";
import useTransferRequest from "./hooks/useTransferRequestHook";
import { TransferStatus } from "./types";
import OtherAssignedTradesman from "./components/OtherAssignedTradesman";
import Island from "../../_components/Island";
import { SelectPaymentProvider } from "../../components/SelectPaymentProvider/SelectPaymentProvider";
import { AssignmentPaymentHistory } from "./components/AssignmentPaymentHistory/AssignmentPaymentHistory";
import RequestFailed from "../../_components/RequestFailed";
import { useOtherAssignmentPayments } from "./hooks/useOtherAssignmentPaymentsHook";
import moment from "moment";
import { useAuth } from "../../firebase/hooks/useAuthHook";

const { Trail } = LayoutComponents;

const PaymentConfirmationView: FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const OFFLINE = "offline";

  const { id } = useParams<{ id: string }>();
  const [otp, setOtp] = useState<string>("");
  const [selectProviderOpen, setSelectProviderOpen] = useState<boolean>(false);
  const [isOfflineTransfer, setIsOfflineTransfer] = useState<boolean>(false);
  const [bankName, setBankName] = React.useState("");
  const [offlineReferenceId, setOfflineReferenceId] = React.useState("");
  const [transactionDate, setTransactionDate] = React.useState<Date>(
    new Date()
  );
  const [
    offlinePaymentConfirmed,
    setOfflinePaymentConfirmed,
  ] = React.useState<boolean>(false);

  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const { loading, error, data } = useTransferRequest(id);

  const otherAssignmentPaymentsResponse = useOtherAssignmentPayments(
    id,
    data?.assignmentPayment.assignment.id
  );

  const [
    approveAssignmentPayment,
    approveAssignmentPaymentResult,
  ] = useMutation(APPROVE_ASSIGNMENT_PAYMENT, {
    onError: () => {},
  });
  const [
    finalizeAssignmentPayment,
    finalizeAssignmentPaymentResponse,
  ] = useMutation(FINALIZE_ASSIGNMENT_PAYMENT, {
    onError: () => {},
  });

  const [cancelTransferRequest, cancelTransferRequestResult] = useMutation(
    CANCEL_TRANSFER_REQUEST,
    {
      onError: () => {},
    }
  );

  const [confirmOfflineTransfer, confirmOfflineTransferResult] = useMutation(
    CONFIRM_OFFLINE_TRANSFER,
    {
      refetchQueries: ["GetAssignmentPayment"],
    }
  );

  const handleApproveTransfer = async (providerId: string) => {
    if (providerId === OFFLINE) {
      setIsOfflineTransfer(true);
      setSelectProviderOpen(false);
      return;
    }

    approveAssignmentPayment({
      variables: {
        data: {
          assignmentPaymentId: data?.assignmentPayment.id,
          providerId,
        },
      },
    });

    setSelectProviderOpen(false);
  };

  if (loading) {
    return <div>loading...</div>;
  }

  if (cancelTransferRequestResult.data) {
    history.push("/payments/confirmations");
  }

  let err;

  if (error) {
    err = error;
  }

  if (approveAssignmentPaymentResult.error) {
    err = approveAssignmentPaymentResult.error.message;
  }

  if (finalizeAssignmentPaymentResponse.error) {
    err = finalizeAssignmentPaymentResponse.error.message;
  }

  if (cancelTransferRequestResult.error) {
    err = cancelTransferRequestResult.error.message;
  }

  if (otherAssignmentPaymentsResponse.error) {
    err = otherAssignmentPaymentsResponse.error.message;
  }

  if (err || !data) {
    return <RequestFailed errorMessage={err} />;
  }

  const otherAssignmentPaymentsFilteredByCurrentRequestType = otherAssignmentPaymentsResponse.assignmentPayments?.filter(
    ({ type }) => type === data?.assignmentPayment.type
  );

  const validAssignmentPayments = otherAssignmentPaymentsFilteredByCurrentRequestType?.filter(
    ({ transfer: { status } }) =>
      status !== TransferStatus.PENDING && status !== TransferStatus.FAILED
  );

  const totalAmountPaid = validAssignmentPayments?.reduce(
    (acc, { transfer }) => {
      if (!transfer.amount) {
        /**
         * This should never happen, but if it does, we should just ignore it
         */
        return acc;
      }

      return acc + transfer.amount;
    },
    0
  );

  const totalBalanceLeft = data.balance - (totalAmountPaid || 0);

  const submitOfflineTransferDetails = async () => {
    if (!transactionDate || !bankName || !offlineReferenceId) {
      setHasErrors(true);
      return;
    }

    confirmOfflineTransfer({
      variables: {
        transferId: data?.assignmentPayment.transfer.id,
        transactionReference: offlineReferenceId,
        transactionDate: transactionDate,
        bankName,
      },
    });
  };

  return (
    <>
      {data?.tradesman && (
        <Modal
          open={selectProviderOpen}
          size='small'
          closeOnDimmerClick
          closeIcon
          onClose={() => setSelectProviderOpen(false)}
        >
          <SelectPaymentProvider
            recipientUserId={data.tradesman.userId}
            onDone={handleApproveTransfer}
          />
        </Modal>
      )}
      <Island
        header={
          <Trail root='Payment Confirmation' child={id.substring(0, 8)}></Trail>
        }
        text={`Send ${data?.assignmentPayment.type.toLowerCase()} fee to tradesmen involved on this order`}
      >
        <div className='o-singlePayment__wrapper'>
          <div className='o-singlePayment__container'>
            <div className='m-singlePayment__balance'>
              <h4>balance</h4>
              <h2>NGN {totalBalanceLeft}</h2>
            </div>
            {finalizeAssignmentPaymentResponse.data ||
            data?.assignmentPayment.transfer.status ===
              TransferStatus.CONFIRMED ||
            offlinePaymentConfirmed ? (
              <div className='o-singlePayment__successWrapper'>
                <Icon name='check circle outline' />
                <h3>
                  Payment{" "}
                  {isOfflineTransfer ? "Confirmed" : "Queued Successfully"}{" "}
                </h3>
              </div>
            ) : (
              <div className='o-singlePayment__transferForm'>
                <h2>send funds to:</h2>
                <div className='m-singlePayment__transferFormInput'>
                  <Icon name='send'></Icon>
                  <input
                    type='text'
                    value={`${data.tradesman?.firstName} ${data.tradesman?.lastName}`}
                    readOnly
                  />
                </div>
                <div className='m-singlePayment__transferFormInput'>
                  <Icon name='align left'></Icon>
                  <textarea
                    placeholder='Enter Description (optional)'
                    value={data?.assignmentPayment.transfer.description || ""}
                    readOnly
                  />
                </div>
                <div className='m-singlePayment__transferFormInput'>
                  <Icon name='money bill alternate outline'></Icon>
                  <input
                    type='number'
                    placeholder='Enter Amount'
                    value={data?.assignmentPayment.transfer.amount}
                    step={100}
                    min={100}
                    readOnly
                    max={data?.balance}
                  />
                </div>
                {(data?.assignmentPayment.transfer.transferCode ||
                  approveAssignmentPaymentResult.data) && (
                  <div className='m-singlePayment__transferFormInput'>
                    <Icon name='lock'></Icon>
                    <input
                      type='text'
                      placeholder='Enter OTP'
                      value={otp}
                      maxLength={7}
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                    />
                  </div>
                )}
                {isOfflineTransfer && (
                  <>
                    <div className='m-singlePayment__transferFormInput'>
                      <Icon name='building'></Icon>
                      <input
                        type='text'
                        placeholder='Enter Bank Name'
                        value={bankName}
                        onChange={(e) => {
                          setBankName(e.target.value);
                        }}
                      />
                    </div>
                    <div className='m-singlePayment__transferFormInput'>
                      <Icon name='hashtag'></Icon>
                      <input
                        type='text'
                        placeholder='Enter Offline Transaction Reference'
                        value={offlineReferenceId}
                        onChange={(e) => {
                          setOfflineReferenceId(e.target.value);
                        }}
                      />
                    </div>
                    <div className='m-singlePayment__transferFormInput'>
                      <Icon name='calendar alternate'></Icon>
                      <input
                        type='date'
                        placeholder='Enter Transaction Date'
                        value={moment.utc(transactionDate).format("YYYY-MM-DD")}
                        onChange={(e) => {
                          const today = new Date();
                          today.setUTCHours(0, 0, 0, 0);
                          let date = new Date(e.target.value);
                          if (date > today) {
                            date = today;
                          }
                          setTransactionDate(date);
                        }}
                      />
                    </div>
                    {hasErrors && (
                      <p className='my-4 text-red-400'>
                        Please fill all required fields before submitting
                      </p>
                    )}
                  </>
                )}
                <div className='m-singlePayment__transferFormActions'>
                  {data?.assignmentPayment.transfer.transferCode ||
                  approveAssignmentPaymentResult.data ||
                  isOfflineTransfer ? (
                    <button
                      className='a-singlePayment__transferFormButton -positive'
                      onClick={() => {
                        if (isOfflineTransfer) {
                          submitOfflineTransferDetails();
                          return;
                        }

                        finalizeAssignmentPayment({
                          variables: {
                            input: {
                              assignmentPaymentId: id,
                              otp,
                            },
                          },
                        });
                      }}
                    >
                      {approveAssignmentPaymentResult.loading ||
                      confirmOfflineTransferResult.loading ||
                      finalizeAssignmentPaymentResponse.loading ? (
                        <Icon name='spinner' loading />
                      ) : (
                        "Confirm Transfer"
                      )}
                    </button>
                  ) : (
                    <>
                      <button
                        className='a-singlePayment__transferFormButton -negative'
                        onClick={() => {
                          cancelTransferRequest({
                            variables: {
                              data: {
                                transferRequestId:
                                  data?.assignmentPayment.transfer.id,
                              },
                            },
                          });
                        }}
                      >
                        {cancelTransferRequestResult.loading ? (
                          <Icon name='spinner' loading />
                        ) : (
                          "Cancel Request"
                        )}
                      </button>
                      <button
                        className='a-singlePayment__transferFormButton'
                        onClick={() => {
                          setSelectProviderOpen(true);
                        }}
                      >
                        {approveAssignmentPaymentResult.loading ? (
                          <Icon name='spinner' loading></Icon>
                        ) : (
                          "Send Money"
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='o-singlePayment__container'>
            <div className='o-singlePayment__othersAssignedWrapper'>
              {!!data?.othersAssigned?.length && (
                <h2>tradesmen assigned here</h2>
              )}

              {data?.othersAssigned?.map(
                ({ amount, tradesman, destination }) => {
                  return (
                    <OtherAssignedTradesman
                      key={tradesman.id}
                      tradesman={tradesman}
                      destination={`${destination.area}, ${destination.location}`}
                      amountSent={amount}
                    />
                  );
                }
              )}
            </div>
            {otherAssignmentPaymentsResponse.loading ? (
              <Icon size='huge' name='spinner' loading />
            ) : (
              <AssignmentPaymentHistory
                assignmentPayments={validAssignmentPayments}
                assignment={data?.assignmentPayment.assignment}
              />
            )}
          </div>
        </div>
      </Island>
    </>
  );
};

export default PaymentConfirmationView;
