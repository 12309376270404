import React, { FC } from "react";
import { TabToggleProps } from "../types";
import { Icon } from "semantic-ui-react";

const TabToggle: FC<TabToggleProps> = ({ optionOne, optionTwo, selected }) => {
  return (
    <div className="o-tabToggle__wrapper">
      <div
        className="o-tabToggle__optionContainer"
        onClick={optionOne.onSelect}
      >
        <div
          className={`m-tabToggle__optionLabel ${
            selected === "one" ? "-selected" : ""
          }`}
        >
          <p>{optionOne.label}</p>
          <Icon
            name={selected === "one" ? "dot circle" : "dot circle outline"}
          ></Icon>
        </div>
      </div>
      <div className="a-tabToggle__divider">
        <p>OR</p>
      </div>
      <div
        className="o-tabToggle__optionContainer"
        onClick={optionTwo.onSelect}
      >
        <div
          className={`m-tabToggle__optionLabel ${
            selected === "two" ? "-selected" : ""
          }`}
        >
          <p>{optionTwo.label}</p>
          <Icon
            name={selected === "two" ? "dot circle" : "dot circle outline"}
          ></Icon>
        </div>
      </div>
    </div>
  );
};

export default TabToggle;
