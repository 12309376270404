import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient } from "@apollo/client";
import {
  GET_JOB,
  GET_TRADESMAN_WITH_AVAILABILITY,
} from "../components/orders/graphql/query";
import {
  UPDATE_JOB_STATUS,
  ASSIGN_TRADESMAN,
  UPDATE_JOB_COST,
} from "../components/orders/graphql/mutation";
import { IJobInitialState, JobProgress } from "../components/orders/types";

const initialState: IJobInitialState = {
  message: null,
  status: "idle",
  error: "",
  job: null,
  tradesmen: [],
};

export const assignTradesman = createAsyncThunk(
  "jobs/tradesmanAssigned",
  async ({
    client,
    tradesmanId,
    jobId,
  }: {
    tradesmanId: string;
    jobId: string;
    client: ApolloClient<object>;
  }) => {
    const { data } = await client
      .mutate({
        mutation: ASSIGN_TRADESMAN,
        variables: {
          data: {
            tradesmanId,
            jobId,
          },
        },
      })
      .catch((e) => {
        throw e;
      });

    if (!data.assignTradesman.status) {
      throw new Error(data.assignTradesman.message);
    }

    return data.assignTradesman.message;
  }
);

export const getAvailableTradesmen = createAsyncThunk(
  "jobs/fetchedAvailableTradesmen",
  async (
    {
      client,
      date,
      categoryId,
    }: {
      date?: string | number;
      categoryId?: string;
      client: ApolloClient<object>;
    },
    { getState }
  ) => {
    const {
      jobs: { job },
    }: any = getState();

    const { data } = await client
      .query({
        query: GET_TRADESMAN_WITH_AVAILABILITY,
        variables: {
          data: {
            date: date || job.scheduledDate,
            categoryId:
              categoryId || job.custom?.categoryId || job.task?.categoryId,
          },
        },
        fetchPolicy: "network-only",
      })
      .catch((e) => {
        throw e;
      });

    if (!data.getTradesmenWithAvailabilityByCategory.status) {
      throw new Error(data.getTradesmenWithAvailabilityByCategory.message);
    }

    return data.getTradesmenWithAvailabilityByCategory.data;
  }
);

export const updateJobProgress = createAsyncThunk(
  "jobs/jobProgressUpdated",
  async ({
    client,
    jobId,
    progress,
  }: {
    progress: JobProgress;
    jobId: string;
    client: ApolloClient<object>;
  }) => {
    const { data } = await client
      .mutate({
        mutation: UPDATE_JOB_STATUS,
        variables: {
          data: {
            id: jobId,
            progressState: progress,
          },
        },
      })
      .catch((e) => {
        throw e;
      });

    if (!data.updateJobProgress.status) {
      throw new Error(data.updateJobProgress.message);
    }

    return data.updateJobProgress.data;
  }
);

export const updateJobCost = createAsyncThunk(
  "jobs/finalCostUpdated",
  async ({
    client,
    jobId,
    costUpdate,
  }: {
    client: ApolloClient<object>;
    costUpdate: number;
    jobId: string;
  }) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_JOB_COST,
        variables: {
          data: {
            jobId,
            finalCost: Number(costUpdate),
          },
        },
      });

      return data.updateJobFinalCost;
    } catch (error) {
      throw error;
    }
  }
);

const JobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    resetMessages: (state) => {
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: {
    [updateJobProgress.fulfilled.type]: (state, action) => {
      state.status = "idle";
    },
    [updateJobProgress.pending.type]: (state) => {
      state.status = "pending";
    },
    [updateJobProgress.rejected.type]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
    },
    [getAvailableTradesmen.fulfilled.type]: (state, action) => {
      state.tradesmen = action.payload;
      state.status = "idle";
    },
    [getAvailableTradesmen.pending.type]: (state) => {
      state.status = "pending";
    },
    [getAvailableTradesmen.rejected.type]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
    },
    [assignTradesman.fulfilled.type]: (state, action) => {
      state.message = action.payload;
      state.status = "idle";
    },
    [assignTradesman.pending.type]: (state) => {
      state.status = "pending";
    },
    [assignTradesman.rejected.type]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
    },
    [updateJobCost.fulfilled.type]: (state, action) => {
      state.message = action.payload.message;
      state.status = "idle";
    },
    [updateJobCost.pending.type]: (state) => {
      state.status = "pending";
    },
    [updateJobCost.rejected.type]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export const JobReducer = JobSlice.reducer;
export const JobActions = {
  assignTradesman,
  getAvailableTradesmen,
  updateJobProgress,
  updateJobCost,
  ...JobSlice.actions,
};
export const JobSelectors = {
  selectJob: (state: { jobs: IJobInitialState }) => {
    return state.jobs.job;
  },
  selectRequestStatus: (state: { jobs: IJobInitialState }) => {
    return state.jobs.status;
  },
  selectError: (state: { jobs: IJobInitialState }) => {
    return state.jobs.error;
  },
  selectTradesmen: (state: { jobs: IJobInitialState }) => {
    return state.jobs.tradesmen;
  },
  selectMessage: (state: { jobs: IJobInitialState }) => {
    return state.jobs.message;
  },
};
