import React, { FC } from 'react';
import Button from '@laborhack/custom-button';
import { IAddedRecruitment, RecruitmentType } from '../../../types';
import { AddedRecruitment } from './AddedRecruitment';

import styles from './styles.module.scss';
import { Modal } from 'semantic-ui-react';
import AddRecruitment from '../../../components/AddRecruitment';

export interface AddedRecruitmentsListProps {
  recruitments: IAddedRecruitment[];
  onRemove: (id: string) => void;
  onAdd: (recruitment: IAddedRecruitment) => void;
  selectedRecruitmentType?: RecruitmentType;
}

export const AddedRecruitmentsList: FC<AddedRecruitmentsListProps> = ({
  recruitments,
  onRemove,
  onAdd,
  selectedRecruitmentType
}) => {
  const [addRecruitmentModalOpen, setAddRecruitmentModalOpen] = React.useState(
    false
  );

  const handleAddRecruitment = (recruitment: IAddedRecruitment) => {
    onAdd(recruitment);
    setAddRecruitmentModalOpen(false);
  };

  return (
    <>
      <Modal
        open={addRecruitmentModalOpen}
        onClose={() => setAddRecruitmentModalOpen(false)}
        closeOnDimmerClick
        size="small"
      >
        <AddRecruitment onSubmit={handleAddRecruitment} selectedRecruitmentType={selectedRecruitmentType}/>
      </Modal>
      <div>
        {recruitments.length > 0 && (
          <div className={styles.list}>
            {recruitments.map((recruitment) => (
              <AddedRecruitment
                key={recruitment.id}
                {...recruitment}
                onRemove={() => onRemove(recruitment.id)}
                selectedRecruitmentType={selectedRecruitmentType}
              />
            ))}
          </div>
        )}
        <Button onClick={() => setAddRecruitmentModalOpen(true)}>
          Add Request
        </Button>
      </div>
    </>
  );
};
