import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { FC, useEffect } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Button from "@laborhack/custom-button";
import { components as LayoutComponents } from "../../../../../components/layout";
import Island from "../../../../../_components/Island";
import Loading from "../../../../../_components/Loading";
import RequestFailed from "../../../../../_components/RequestFailed";
import {
  ContractQuotation,
  ContractQuotationItem,
  QuotationStatus,
} from "../../../types";

import styles from "./styles.module.scss";
import QuotationBreakdown from "./components/QuotationBreakdown";
import produce from "immer";
import {
  ItemChangeEvent,
  SingleQuotationContext,
} from "./context/single-quotation.context";
import { nanoid } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import {
  APPROVE_QUOTATION,
  SEND_QUOTATION,
  UPDATE_QUOTATION_ITEMS,
} from "./graphql/mutations";
import { Icon } from "semantic-ui-react";

const { Trail, CustomLabel } = LayoutComponents;

const GET_RECRUITMENT_ACTIVE_QUOTATION = gql`
  query GET_RECRUITMENT_ACTIVE_QUOTATION($id: String!) {
    contractRecruitment(id: $id) {
      id
      activeQuotation {
        id
      }
    }
  }
`;

const GET_QUOTATION = gql`
  query GetQuotation($id: String!) {
    quotation(id: $id) {
      id
      contractRecruitmentId
      status
      items {
        isActive
        proCount
        level
        categoryId
        rate
        requestedStartDate
        workdays
        contractDetails {
          length
          interval
        }
      }
      createdAt
    }
  }
`;

export const SingleQuotation: FC = () => {
  const { id: quotationId } = useParams<{ id: string }>();

  const history = useHistory();

  const [editMode, setEditMode] = React.useState(false);

  const { loading, error: getQuotationError, data } = useQuery<{
    quotation: ContractQuotation;
  }>(GET_QUOTATION, {
    variables: {
      id: quotationId,
    },
  });

  const [
    getRecruitmentActiveQuotation,
    getRecruitmentActiveQuotationResponse,
  ] = useLazyQuery(GET_RECRUITMENT_ACTIVE_QUOTATION);

  const [updateItems, updateItemsResponse] = useMutation<{
    updateContractQuotationItems: ContractQuotation;
  }>(UPDATE_QUOTATION_ITEMS, {
    onCompleted: (data) => {
      setEditMode(false);
    },
  });

  const [sendQuotation, sendQuotationResponse] = useMutation<{
    sendContractQuotation: ContractQuotation;
  }>(SEND_QUOTATION);

  const [approveQuotation, approveQuotationResponse] = useMutation<{
    approveContractQuotation: ContractQuotation;
  }>(APPROVE_QUOTATION);

  const [items, setItems] = React.useState<
    Record<string, ContractQuotationItem>
  >({});

  const [undoStack, setUndoStack] = React.useState<ItemChangeEvent[]>([]);
  const [redoStack, setRedoStack] = React.useState<ItemChangeEvent[]>([]);

  const allRatesAreSet = data?.quotation.items
    .filter((item) => item.isActive)
    .every((item) => item.rate);

  const error =
    getQuotationError ||
    updateItemsResponse.error ||
    sendQuotationResponse.error ||
    approveQuotationResponse.error ||
    getRecruitmentActiveQuotationResponse.error;

  const mutationReset =
    updateItemsResponse.reset ||
    sendQuotationResponse.reset ||
    approveQuotationResponse.reset;

  useEffect(() => {
    if (data) {
      // get active quotation
      getRecruitmentActiveQuotation({
        variables: {
          id: data.quotation.contractRecruitmentId,
        },
      });

      // if the data changes, update the items
      // deep clone the items to avoid mutating the original data
      const clones = produce(data.quotation.items, (draft) => {});

      const clonesAsObject = clones.reduce((acc, item) => {
        acc[nanoid(6)] = item;
        return acc;
      }, {} as Record<string, ContractQuotationItem>);

      setItems(clonesAsObject);

      /**
       * The change in data that causes all items to have new ids, so the current undo (or redo) stack is invalid
       */
      setUndoStack([]);
      setRedoStack([]);
    }
  }, [data]);

  if (loading || getRecruitmentActiveQuotationResponse.loading) {
    return <Loading />;
  }

  if (error || !data || !getRecruitmentActiveQuotationResponse.data) {
    return (
      <RequestFailed errorMessage={error?.message} onRetry={mutationReset} />
    );
  }

  const { id, status } = data.quotation;

  const addToUndoStack = (event: ItemChangeEvent) => {
    setUndoStack((stack) => [...stack, event]);

    setRedoStack([]);
  };

  const splitItem = (itemId: string, splitRatio: number[]) => {
    const oldState = produce(items, (draft) => {});

    const item = oldState[itemId];

    if (!item) {
      throw new Error(`Item with id ${itemId} does not exist`);
    }

    /**
     * check if all the split ratios are valid and they all add up to the initial pro count
     */
    if (splitRatio.reduce((acc, ratio) => acc + ratio, 0) !== item.proCount) {
      throw new Error("Split ratios must add up to the initial pro count");
    }

    if (splitRatio.find((ratio) => ratio < 1)) {
      throw new Error("Split ratios must be greater than 0");
    }

    const newItems = [
      ...splitRatio.map((ratio) => ({ ...item, proCount: ratio })),
    ];

    const newState = produce(items, (draft) => {
      delete draft[itemId];
      newItems.forEach((newItem) => {
        draft[nanoid(6)] = newItem;
      });
    });

    addToUndoStack({
      type: "split",
      from: oldState,
      to: newState,
    });

    setItems(newState);
  };

  /**
   * The first id in the array is used as the target for the merge
   * @param itemIds the ids of the items to merge
   */
  const mergeItems = (itemIds: string[]) => {
    const oldState = produce(items, (draft) => {});

    const itemsToMerge = itemIds.map((itemId) => {
      const item = oldState[itemId];

      if (!item) {
        throw new Error(`Item with id ${itemId} does not exist`);
      }

      return item;
    });

    const newItem = {
      ...itemsToMerge[0],
      proCount: itemsToMerge.reduce((acc, item) => acc + item.proCount, 0),
    };

    const newState = produce(items, (draft) => {
      itemIds.forEach((itemId) => {
        delete draft[itemId];
      });

      draft[nanoid(6)] = newItem;
    });

    addToUndoStack({
      type: "merge",
      from: oldState,
      to: newState,
    });

    setItems(newState);
  };

  const undo = () => {
    const clone = cloneDeep(undoStack);

    const lastChange = clone.pop();

    if (lastChange) {
      setUndoStack(clone);
      setRedoStack((stack) =>
        produce(stack, (draft) => [...draft, lastChange])
      );
      setItems(produce(lastChange.from, (draft) => {}));
    }
  };

  const redo = () => {
    const clone = cloneDeep(redoStack);
    const lastChange = clone.pop();

    if (lastChange) {
      setRedoStack(clone);
      setUndoStack((stack) =>
        produce(stack, (draft) => [...draft, lastChange])
      );
      setItems(produce(lastChange.to, (draft) => {}));
    }
  };

  const changeLevel = (itemId: string, level: number) => {
    const oldState = produce(items, (draft) => {});

    const item = oldState[itemId];

    if (!item) {
      throw new Error(`Item with id ${itemId} does not exist`);
    }

    const newState = produce(items, (draft) => {
      draft[itemId] = {
        ...item,
        level,
      };
    });

    addToUndoStack({
      type: "level",
      from: oldState,
      to: newState,
    });

    setItems(newState);
  };

  const changeRate = (itemId: string, rate: number) => {
    const oldState = produce(items, (draft) => {});

    const item = oldState[itemId];

    if (!item) {
      throw new Error(`Item with id ${itemId} does not exist`);
    }

    const newState = produce(items, (draft) => {
      draft[itemId] = {
        ...item,
        rate,
      };
    });

    addToUndoStack({
      type: "rate",
      from: oldState,
      to: newState,
    });

    setItems(newState);
  };

  const changeWorkdays = (itemId: string, workdays: number) => {
    const oldState = produce(items, (draft) => {});

    const item = oldState[itemId];

    if (!item) {
      throw new Error(`Item with id ${itemId} does not exist`);
    }

    const newState = produce(items, (draft) => {
      draft[itemId] = {
        ...item,
        workdays,
      };
    });

    addToUndoStack({
      type: "workdays",
      from: oldState,
      to: newState,
    });

    setItems(newState);
  };

  const changeActive = (itemId: string, active: boolean) => {
    const oldState = produce(items, (draft) => {});

    const item = oldState[itemId];

    if (!item) {
      throw new Error(`Item with id ${itemId} does not exist`);
    }

    const newState = produce(items, (draft) => {
      draft[itemId] = {
        ...item,
        isActive: active,
      };
    });

    addToUndoStack({
      type: "active",
      from: oldState,
      to: newState,
    });

    setItems(newState);
  };

  const color = {
    r: 0,
    g: 0,
    b: 0,
  };

  switch (status) {
    case QuotationStatus.NOT_SENT:
      color.r = 199;
      color.g = 156;
      color.b = 26;
      break;
    case QuotationStatus.AWAITING_APPROVAL:
      color.r = 199;
      color.g = 156;
      color.b = 26;
      break;
    case QuotationStatus.CANCELLED:
      color.r = 199;
      color.g = 156;
      color.b = 26;
      break;
    case QuotationStatus.APPROVED:
      color.r = 86;
      color.g = 189;
      color.b = 102;
      break;

    default:
      break;
  }

  const handleSave = () => {
    const payload = Object.values(items);

    updateItems({
      variables: {
        input: {
          id: quotationId,
          items: payload.map(
            ({
              proCount,
              isActive,
              rate,
              level,
              categoryId,
              requestedStartDate,
              contractDetails,
              workdays,
            }) => {
              // to remove __typename
              const { length, interval } = contractDetails;
              return {
                proCount,
                rate,
                level,
                isActive,
                categoryId,
                requestedStartDate,
                workdays,
                contractDetails: {
                  length,
                  interval,
                },
              };
            }
          ),
        },
      },
    });
  };

  const handleSend = () => {
    sendQuotation({
      variables: {
        id,
      },
    });
  };

  const handleApprove = () => {
    approveQuotation({
      variables: {
        id,
      },
    });
  };

  const isActiveQuotation =
    getRecruitmentActiveQuotationResponse.data?.contractRecruitment
      .activeQuotation.id === id;

  return (
    <SingleQuotationContext.Provider
      value={{
        items,
        editMode,
        undoStack,
        redoStack,
        splitItem,
        mergeItems,
        changeLevel,
        changeRate,
        changeActive,
        changeWorkdays,
        undo,
        redo,
      }}
    >
      <div
        style={{
          marginBottom: "1rem",
        }}
      >
        <Button type='link' variant='basic' onClick={() => history.goBack()}>
          <Icon name='chevron left' />
          Back
        </Button>
      </div>
      <Island header={<Trail root='Quotation' child={id} />}>
        <div className={styles.labels}>
          <CustomLabel
            color={color}
            text={status.toString().replace("_", " ")}
          />
          {isActiveQuotation && (
            <CustomLabel
              color={{
                // green
                r: 86,
                g: 189,
                b: 102,
              }}
              text='Active'
            />
          )}
        </div>
        <div className={styles.wrapper}>
          <div>
            <QuotationBreakdown />
            {isActiveQuotation && (
              <div className={styles.actions}>
                {!editMode && status === QuotationStatus.NOT_SENT && (
                  <Button disabled={editMode} onClick={() => setEditMode(true)}>
                    Edit
                  </Button>
                )}
                {editMode && (
                  <Button
                    variant='success'
                    loading={updateItemsResponse.loading}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
                {!editMode && status === QuotationStatus.NOT_SENT && (
                  <Button
                    disabled={!allRatesAreSet}
                    variant='success'
                    loading={sendQuotationResponse.loading}
                    onClick={handleSend}
                  >
                    Send
                  </Button>
                )}
                {status === QuotationStatus.AWAITING_APPROVAL && (
                  <Button
                    variant='success'
                    onClick={handleApprove}
                    loading={approveQuotationResponse.loading}
                  >
                    Approve
                  </Button>
                )}
              </div>
            )}
          </div>
          <div></div>
        </div>
      </Island>
    </SingleQuotationContext.Provider>
  );
};
