import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { BiCaretRight } from "react-icons/bi";
import Button from "@laborhack/custom-button";
import {
  IdentityVerification,
  IdentityVerificationStatus,
  MeansOfIdentification,
} from "../../types";
import fallback from "../../assets/fallback.png";
import Island from "../../_components/Island";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { Icon, Image, List } from "semantic-ui-react";
import { VField as Field } from "../../_components/VField";
import { format } from "date-fns";
import Pill from "../../_components/Pill";
import { idStatusToPillClassName } from "./constants";
import { Alert } from "antd";
import { useState } from "react";
import BackButton from "../../_components/BackButton";
import TextField from "../../_components/TextField";
import ImageField from "../../_components/ImageField";

const meansOfIdentificationToFilePathPrefix: Record<
  MeansOfIdentification,
  string
> = {
  [MeansOfIdentification.NIN]: "nin-slips",
  [MeansOfIdentification.DRIVERS_LICENSE]: "drivers-licenses",
  [MeansOfIdentification.PASSPORT]: "passports",
  [MeansOfIdentification.VOTERS_CARD]: "voters-cards",
  [MeansOfIdentification.BVN]: "bvn",
};

const UPDATE_IDENTITY_VERIFICATION = gql`
  mutation UpdateIdentityVerification(
    $id: String!
    $status: IdentityVerificationStatus
    $identificationNumber: String
    $identificationDocument: String
    $photo: String
    $type: MeansOfIdentification
  ) {
    updateIdentityVerification(
      id: $id
      status: $status
      identificationNumber: $identificationNumber
      identificationDocument: $identificationDocument
      photo: $photo
      type: $type
    ) {
      id
      status
      identificationNumber
      identificationDocument
      photo
      type
    }
  }
`;

const GET_IDENTITY_VERIFICATION = gql`
  query GetIdentityVerification($id: String!) {
    identityVerification(id: $id) {
      id
      type
      identificationNumber
      dateOfBirth
      returnedDateOfBirth
      returnedFullName
      returnedPhoneNumber
      issues {
        message
      }
      providerIssues {
        message
      }
      photo
      returnedPhoto
      identificationDocument
      returnedIdentificationDocument
      status
      pro {
        id
        firstName
        lastName
        phoneNumber
        email
        associatedLaborHackEmail
      }
    }
  }
`;

export const ViewIdentityVerification = () => {
  const { id } = useParams<{ id: string }>();

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { data, loading, error } = useQuery<{
    identityVerification: IdentityVerification;
  }>(GET_IDENTITY_VERIFICATION, {
    variables: { id },
  });

  const [
    updateIdentityVerification,
    { loading: updateLoading, error: updateError },
  ] = useMutation<{
    updateIdentityVerification: IdentityVerification;
  }>(UPDATE_IDENTITY_VERIFICATION, {
    onCompleted: (data) => {
      if (
        data.updateIdentityVerification.status !==
        IdentityVerificationStatus.PENDING
      ) {
        setShowAlert(true);
      } else {
        setShowAlert(false);
      }
    },
  });

  const clearUpdateAlert = () => {
    setShowAlert(false);
  };

  if (loading) return <Loading />;
  if (error || !data) return <RequestFailed />;

  if (updateError) return <RequestFailed />;

  const { identityVerification } = data;

  const handleApprove = async () => {
    updateIdentityVerification({
      variables: {
        id: identityVerification.id,
        status: IdentityVerificationStatus.VERIFIED,
      },
    });
  };

  const handleReject = async () => {
    updateIdentityVerification({
      variables: {
        id: identityVerification.id,
        status: IdentityVerificationStatus.DECLINED,
      },
    });
  };

  const handleUndo = async () => {
    updateIdentityVerification({
      variables: {
        id: identityVerification.id,
        status: IdentityVerificationStatus.PENDING,
      },
    });
  };

  const handleUpdateIdentificationNumber = async (id: string) => {
    updateIdentityVerification({
      variables: {
        id: identityVerification.id,
        identificationNumber: id,
      },
    });
  };

  const handleUpdateIdentificationDocument = async (url: string) => {
    updateIdentityVerification({
      variables: {
        id: identityVerification.id,
        identificationDocument: url,
      },
    });
  };

  const handleUpdatePhoto = async (url: string) => {
    updateIdentityVerification({
      variables: {
        id: identityVerification.id,
        photo: url,
      },
    });
  };

  const canEdit = true;

  return (
    <>
      <BackButton />
      <Island>
        <div className='mb-4'>
          <div className='flex items-center gap-2'>
            <h2 className='text-primary-500 text-xl font-bold m-0'>Pro</h2>
            <BiCaretRight />
            <h2 className='text-primary-500 text-xl font-bold m-0'>
              Identity Verification
            </h2>
            <BiCaretRight />
            <h2 className='text-primary-500 text-xl font-bold m-0'>
              {identityVerification.id}
            </h2>
            <Pill
              className={idStatusToPillClassName[identityVerification.status]}
            >
              {identityVerification.status}
            </Pill>
          </div>

          <p className='m-0 text-primary-300'>
            View and manage a submission for identity verification by a pro.
          </p>
        </div>
        {showAlert && (
          <Alert
            message='Identity Verification Status Updated'
            type='success'
            showIcon
            closable
            onClose={clearUpdateAlert}
          />
        )}

        <div className='w-full flex justify-end gap-2 mt-2'>
          {identityVerification.status !==
          IdentityVerificationStatus.PENDING ? (
            <Button
              variant='basic'
              loading={updateLoading}
              onClick={handleUndo}
            >
              Undo
            </Button>
          ) : (
            <>
              <Button
                className='bg-red-500'
                disabled={
                  identityVerification.status !==
                  IdentityVerificationStatus.PENDING
                }
                loading={updateLoading}
                onClick={handleReject}
              >
                Reject <Icon name='remove circle' className='p-0 !ml-1 !mr-0' />
              </Button>
              <Button
                disabled={
                  identityVerification.status !==
                  IdentityVerificationStatus.PENDING
                }
                variant='success'
                loading={updateLoading}
                onClick={handleApprove}
              >
                Accept <Icon name='check circle' className='p-0 !ml-1 !mr-0' />
              </Button>
            </>
          )}
        </div>
        <div className='w-full grid grid-cols-2 mt-8'>
          <div className='flex flex-col gap-4'>
            <Field
              label='Pro Name'
              value={
                <div className='flex items-center gap-2 font-semibold text-primary-500'>
                  <p className='m-0 p-0'>{`${identityVerification.pro.firstName} ${identityVerification.pro.lastName}`}</p>
                  <p className='m-0 p-0 text-primary-300'>|</p>
                  <p className='m-0 p-0 text-primary-300 font-normal'>
                    {identityVerification.returnedFullName || "Not Returned"}
                  </p>
                </div>
              }
            />
            <Field
              label='Pro Phone Number'
              value={
                <div className='flex items-center gap-2 font-semibold text-primary-500'>
                  <p className='m-0 p-0'>
                    {identityVerification.pro.phoneNumber}
                  </p>
                  <p className='m-0 p-0 text-primary-300'>|</p>
                  <p className='m-0 p-0 text-primary-300 font-normal'>
                    {identityVerification.returnedPhoneNumber || "Not Returned"}
                  </p>
                </div>
              }
            />
            <Field
              label='Identification Type'
              value={identityVerification.type}
            />
            <TextField
              label='Identification Number'
              value={identityVerification.identificationNumber}
              editable={canEdit}
              onSave={handleUpdateIdentificationNumber}
              saving={updateLoading}
            />
            <Field
              label='Date of Birth'
              value={
                <div className='flex items-center gap-2 font-semibold text-primary-500'>
                  <p className='m-0 p-0'>
                    {format(new Date(identityVerification.dateOfBirth), "PP")}
                  </p>
                  <p className='m-0 p-0 text-primary-300'>|</p>
                  <p className='m-0 p-0 text-primary-300 font-normal'>
                    {identityVerification.returnedDateOfBirth
                      ? format(
                          new Date(identityVerification.returnedDateOfBirth),
                          "PP"
                        )
                      : "Not Returned"}
                  </p>
                </div>
              }
            />
            <ImageField
              label='Photo'
              value={identityVerification.photo || fallback}
              editable={canEdit}
              path='profile-pictures'
              onSave={handleUpdatePhoto}
              saving={updateLoading}
            />
            {!!identityVerification.returnedPhoto && (
              <Field
                label='ID Authority Photo'
                value={
                  <Image
                    src={identityVerification.returnedPhoto}
                    as='a'
                    size='medium'
                    href={identityVerification.returnedPhoto}
                    target='_blank'
                  />
                }
              />
            )}
            <ImageField
              label='Identification Document'
              value={identityVerification.identificationDocument || fallback}
              editable={canEdit}
              path={
                meansOfIdentificationToFilePathPrefix[identityVerification.type]
              }
              onSave={handleUpdateIdentificationDocument}
              saving={updateLoading}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <Field
              label='Issues'
              value={
                identityVerification.issues &&
                identityVerification.issues.length > 0 ? (
                  <List bulleted>
                    {identityVerification.issues?.map((issue) => (
                      <List.Item key={issue.message}>{issue.message}</List.Item>
                    ))}
                  </List>
                ) : (
                  "No Issues Found"
                )
              }
            />
            <Field
              label='Provider Issues'
              value={
                identityVerification.providerIssues &&
                identityVerification.providerIssues.length > 0 ? (
                  <List bulleted>
                    {identityVerification.providerIssues?.map((issue) => (
                      <List.Item key={issue.message}>{issue.message}</List.Item>
                    ))}
                  </List>
                ) : (
                  "No Issues Found"
                )
              }
            />
          </div>
        </div>
      </Island>
    </>
  );
};
