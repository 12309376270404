import { useQuery } from "@apollo/client";
import { Link, useHistory, useParams } from "react-router-dom";
import { Image, Menu, TabProps } from "semantic-ui-react";
import { IdentityVerificationStatus, Pro } from "../../types";
import Island from "../../_components/Island";
import fallback from "../../assets/fallback.png";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { Details } from "./Details";
import Badge from "../../_components/Badge";
import { BadgeType } from "../../_components/Badge/Badge";
import { Tab } from "../../_components/Tab/Tab";
import { IdentityVerificationList } from "./IdentityVerificationList";
import { GET_PRO } from "../../graphql/pros/query";
import { BankAccountInformation } from "./BankAccountInformation";
import { Trades } from "./trades";
import { Stack, Button, Icon } from "@chakra-ui/react";
import { BiLeftArrowAlt } from "react-icons/bi";

export const ViewPro = () => {
  const params = useParams<{ proId: string; tab: string }>();

  const { proId, tab } = params;

  const { push } = useHistory();

  const { data, loading, error } = useQuery<{ pro: Pro }>(GET_PRO, {
    variables: { id: proId },
  });

  if (loading) return <Loading />;

  if (error || !data) return <RequestFailed />;

  const { identityVerification, firstName, lastName, trades } = data.pro;

  let identificationBadgeColor: BadgeType;

  switch (identityVerification?.status) {
    case IdentityVerificationStatus.VERIFIED:
      identificationBadgeColor = "success";
      break;

    case IdentityVerificationStatus.DECLINED:
      identificationBadgeColor = "cancelled";
      break;

    default:
      identificationBadgeColor = "progress";
      break;
  }

  const panes = [
    {
      id: "details",
      title: "Details",
      render: () => <Details pro={data.pro} />,
    },
    {
      id: "trades",
      title: "Trades",
      render: () => <Trades pro={data.pro} />,
    },
    {
      id: "identityVerifications",
      title: (
        <div className='flex flex-nowrap items-center gap-2'>
          <p className='m-0 p-0 '>Identity Verifications</p>{" "}
          <Badge
            text={identityVerification?.status.valueOf() || "Pending"}
            type={identificationBadgeColor}
          />
        </div>
      ),
      render: () => <IdentityVerificationList pro={data.pro} />,
    },
    {
      id: "bankAccountInformation",
      title: "Bank Account Information",
      render: () => <BankAccountInformation pro={data.pro} />,
    },
  ];

  const photo = identityVerification?.photo || fallback;

  const onTabChange = (e: any, { activeIndex }: TabProps) => {
    if (typeof activeIndex !== "number") return;

    const { id } = panes[activeIndex];
    push(`/pros/${proId}/${id}`);
  };

  return (
    <>
      <Stack direction='row' align='center' justify='space-between' mb={4}>
        <Button as={Link} to='/pros' leftIcon={<Icon as={BiLeftArrowAlt} />}>
          Back to List
        </Button>
      </Stack>
      <Island>
        <div className='mb-4'>
          <h2 className='text-primary-500 text-xl font-bold m-0'>
            Pro Details
          </h2>
        </div>
        <div className='my-8'>
          <div className='flex items-center gap-4'>
            <Image
              src={photo}
              as='a'
              size='small'
              href={photo}
              target='_blank'
            />
            <div className='flex flex-col gap-2'>
              <h3 className='text-primary-500 text-lg font-bold m-0'>
                {firstName} {lastName}
              </h3>

              <div className='flex gap-2'>
                {trades.map((trade) => (
                  <p
                    key={trade.id}
                    className='italic text-primary-300 font-semibold'
                  >
                    {trade.name}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='w-full mb-8'>
          <Tab
            menu={{ secondary: true, pointing: true }}
            activeIndex={panes.findIndex(({ id }) => id === tab)}
            onTabChange={onTabChange}
            panes={panes.map(({ render, title, id }) => ({
              render,
              menuItem: <Menu.Item key={id}>{title}</Menu.Item>,
            }))}
          />
        </div>
      </Island>
    </>
  );
};
