import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { components as LayoutComponents } from "../../layout";
import { ColumnsType } from "antd/lib/table";
import { Icon } from "semantic-ui-react";
import { LIST_INVOICES } from "../graphql/query";
import { useQuery } from "@apollo/client";
import Island from "../../../_components/Island";
import { IInvoice, RelayStylePaginatedResponse } from "../../../types";
import { extractItemsFromRelayStylePaginationResponse } from "../../../helpers/helpers";
import Loading from "../../../_components/Loading";
import RequestFailed from "../../../_components/RequestFailed";

const { Trail, CustomTable, CustomLabel } = LayoutComponents;

const AllInvoices: FC = () => {
  const history = useHistory();
  const [filterStatus, setFilterStatus] = useState<string>("ALL");

  const { data, loading, error, fetchMore, refetch } = useQuery<{
    invoices: RelayStylePaginatedResponse<IInvoice>;
  }>(LIST_INVOICES, {
    variables: {
      first: 10,
      after: null,
    },
  });

  const columns: ColumnsType<IInvoice & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.id.substring(0, 8)}</p>;
      },
      width: "5%",
    },
    {
      title: "Invoice No",
      render: (text, record) => {
        return <p>{record.invoiceNumber}</p>;
      },
      width: "5%",
    },
    {
      title: "Invoice Date",
      render: (text, record) => {
        return <p>{new Date(record.invoiceDate).toLocaleString()}</p>;
      },
      width: "10%",
    },
    {
      title: "Recipient",
      render: (text, record) => {
        return <p>{`${record.recipient.name}`}</p>;
      },
      width: "15%",
    },
    {
      title: "Amount",
      render: (text, record) => {
        return (
          <p>
            {record.payment?.amount ? `NGN ${record.payment.amount}` : "N/A"}
          </p>
        );
      },
      width: "10%",
    },
    {
      title: "Status",
      render: (text, record) => {
        const color = {
          r: 0,
          g: 0,
          b: 0,
        };

        let label;

        if (record.isFullyPaid) {
          color.r = 86;
          color.g = 189;
          color.b = 102;
          label = "paid";
        } else {
          color.r = 199;
          color.g = 156;
          color.b = 26;
          label = "pending";
        }

        if (record.canceled) {
          color.r = 244;
          color.g = 105;
          color.b = 86;
          label = "canceled";
        }
        return <CustomLabel color={color} text={label} />;
      },
      width: "15%",
    },
  ];

  if (loading) return <Loading />;

  if (error || !data) return <RequestFailed />;

  const getMore = () => {
    fetchMore({
      variables: {
        first: 10,
        after: data?.invoices.pageInfo.endCursor,
      },
    });
  };

  return (
    <>
      <Island header={<Trail root='All Invoices'></Trail>}>
        <div className='o-invoice__tableControls'>
          <div className='o-invoice__tableFilterWrapper'>
            Filter By Status:
            <select
              value={filterStatus}
              onChange={(e) => {
                setFilterStatus(e.target.value);
              }}
              className='o-invoice__tableFilter'
              name='filterSelect'
              id='select'
            >
              <option value={"ALL"}>ALL</option>
              <option value={"UNPAID"}>UNPAID</option>
              <option value={"PAID"}>PAID</option>
              <option value={"CANCELED"}>CANCELED</option>
            </select>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "48px",
              color: "#003952",
              cursor: "pointer",
            }}
            onClick={() => {
              refetch();
            }}
          >
            <p
              style={{
                margin: "0 10px 0 0",
                fontWeight: 600,
              }}
            >
              Refresh{" "}
            </p>
            <Icon
              name='refresh'
              style={{
                cursor: "pointer",
                height: "14px",
                lineHeight: "14px",
                margin: 0,
              }}
            ></Icon>
          </div>
        </div>
        <CustomTable
          onRow={(record: any, index: any) => {
            return {
              onClick: () => {
                history.push("/invoices/" + record.id);
              },
            };
          }}
          columns={columns}
          data={extractItemsFromRelayStylePaginationResponse(data?.invoices)
            .filter((invoice: IInvoice) => {
              const { payment, canceled } = invoice;

              if (filterStatus === "CANCELED") {
                return canceled;
              }

              if (filterStatus === "ALL") {
                return true;
              }
              if (filterStatus === "UNPAID") {
                return !canceled && !invoice.isFullyPaid;
              }

              if (filterStatus === "PAID") {
                return invoice.isFullyPaid;
              }
            })
            .sort((a: IInvoice, b: IInvoice) => {
              return a.invoiceNumber && b.invoiceNumber
                ? b.invoiceNumber - a.invoiceNumber
                : 0;
            })
            .map((invoice: IInvoice) => {
              return {
                key: invoice.id,
                ...invoice,
              };
            })}
          loading={loading}
          handleTableChange={() => {}}
          fetchMore={getMore}
          hasMore={data?.invoices.pageInfo.hasNextPage}
        ></CustomTable>
      </Island>
    </>
  );
};

export default AllInvoices;
