import React, { FC, useState, useEffect } from 'react';
import { Grid, Form, Button, Input, Message } from 'semantic-ui-react';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import { SUBMIT_TASK_FORM } from '../../../graphql/query';
import { CatServiceSelectors } from './../../../redux/category-service.slice';
import { IFileName } from './../types';
import {
  ICategories,
  ISubCategories,
  ISubCatTasks,
} from './../../allcategories/types';
import { useSelector, useDispatch } from 'react-redux';
import {
  setDName,
  setDCategoryId,
  setDDescription,
  setDImageUrl,
  setDSubCategoryId,
  setDEstimatedCost,
  submitTaskForm,
  selectSubmissionStatus,
  selectSuccessMessage,
  selectErrorMessage,
  setDStatus,
} from '../../../redux/tasks.slice';
import { useHistory } from 'react-router-dom';
import Island from '../../../_components/Island';

const TaskForm: FC = () => {
  const {
    selectTasks,
    selectCategories,
    selectSubCategories,
  } = CatServiceSelectors;
  const allCategory = useSelector(selectCategories);
  const allSubCategories: ISubCategories = useSelector(selectSubCategories);
  const allTask: ISubCatTasks = useSelector(selectTasks);
  const successMessage = useSelector(selectSuccessMessage);
  const formErrorMessage = useSelector(selectErrorMessage);
  const submissionStatus = useSelector(selectSubmissionStatus);
  const dispatch = useDispatch();

  const allCategories = Object.entries(allCategory);
  const allsubcategories = Object.entries(allSubCategories);
  const alltasks = Object.entries(allTask);

  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [estimatedCost, setEstimatedCost] = useState('');
  const [img, setImg] = useState();
  const [uploadProgress, setUploadProgress] = useState('0%');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [nameError, setNameError] = useState(false);

  const [submitForm] = useMutation(SUBMIT_TASK_FORM);

  const TaskNames: any = [];
  const history = useHistory();

  alltasks.forEach(([id, task]) => {
    return TaskNames.push(task.name.toLocaleLowerCase());
  });

  const checkName = () => {
    if (
      TaskNames.indexOf(`${name.replace(/\s*$/, '').toLocaleLowerCase()}`) > -1
    ) {
      setNameError(true);
      setErrorMessage('Category already exist');
    } else {
      setNameError(false);
      setErrorMessage('');
    }
  };

  useEffect(() => {
    if (successMessage !== '') {
      setTimeout(() => {
        history.push('/categories');
        window.location.reload();
      }, 2000);
    }
  }, [successMessage, history]);

  const uploadHandler = async () => {
    const fileData = new FormData();
    const image_file: any = img;
    const trimFileName = (file: IFileName) =>
      file.name.replace(new RegExp(/[^0-9a-zA-Z.]/g), '_');
    fileData.append('file', image_file, trimFileName(image_file));
    fileData.append('upload_preset', 'ilq50gln');
    const APIURL = 'https://api.cloudinary.com/v1_1/laborhack/image/upload';
    await axios
      .post(APIURL, fileData, {
        onUploadProgress: (ProgressEvent) => {
          setUploadProgress(
            `${Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)}%`
          );
        },
      })
      .then((res) => {
        const image_url = res.data.secure_url;
        setImageUrl(image_url);
        dispatch(setDStatus('pending'));
        dispatch(setDImageUrl(image_url));
      });
  };

  allsubcategories.filter(([id, subcategory]) => {
    return subcategory.categoryId === category;
  });
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          <Island header="Create new task">
            {successMessage !== '' && (
              <Message
                success
                header="Created successfully"
                content={successMessage}
              />
            )}
            {errorMessage !== '' && (
              <Message error header="Error" content={errorMessage} />
            )}
            {formErrorMessage !== '' && (
              <Message error header="Error" content={formErrorMessage} />
            )}
            <Form>
              <Form.Input label="Choose Category" required>
                <select
                  name="role"
                  id="role"
                  value={category}
                  onChange={(e) => {
                    const { value } = e.target;
                    setCategory(value);
                    dispatch(setDCategoryId(value));
                  }}
                >
                  <option
                    value=""
                    disabled
                    defaultValue="choose a category"
                    hidden
                  >
                    Choose a category
                  </option>
                  {allCategories.length > 0 ? (
                    allCategories.map(([id, data]) => {
                      return (
                        <option key={id} value={id}>
                          {data.name}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No available categories</option>
                  )}
                </select>
              </Form.Input>
              <Form.Input label="Choose Sub Category" required>
                <select
                  name="role"
                  id="role"
                  value={subCategory}
                  onChange={(e) => {
                    const { value } = e.target;

                    setSubCategory(value);
                    dispatch(setDSubCategoryId(value));
                  }}
                >
                  <option
                    value=""
                    disabled
                    defaultValue="choose a subcategory"
                    hidden
                  >
                    Choose a sub category
                  </option>

                  {allsubcategories.length > 0 ? (
                    allsubcategories
                      .filter(([id, rest]) => {
                        return rest.categoryId === category;
                      })
                      .map(([id, data]) => {
                        return (
                          <option key={id} value={id}>
                            {data.name}
                          </option>
                        );
                      })
                  ) : (
                    <option value="">No available subcategories</option>
                  )}
                </select>
              </Form.Input>
              <Form.Input
                required
                id="form-input-control-error-name"
                control={Input}
                label="Service Name"
                placeholder="Pipe fixing"
                value={name}
                onChange={(e) => {
                  const { value } = e.target;
                  setName(value);
                }}
                onBlur={() => {
                  checkName();
                  dispatch(setDName(name));
                }}
              />

              <Form.Input
                required
                id="form-input-control-error-description"
                control={Input}
                label="Description"
                placeholder="Enter category description"
                value={description}
                onChange={(e) => {
                  const { value } = e.target;
                  setDescription(value);
                }}
                onBlur={() => {
                  dispatch(setDDescription(description));
                }}
              />
              <Form.Input
                required
                id="form-input-control-error-name"
                control={Input}
                type="text"
                pattern="[0-9]+"
                label="Estimated Cost (Amount in naira)"
                placeholder="5000"
                value={estimatedCost}
                onChange={(e) => {
                  const { value } = e.target;
                  setEstimatedCost(value);
                }}
                onBlur={() => {
                  dispatch(setDEstimatedCost(parseInt(estimatedCost)));
                }}
              />
              <div>
                <Form.Input label="Upload Image (400px by 400px)" required>
                  <input
                    width={5}
                    type="file"
                    name="image"
                    id=""
                    onChange={(e) => {
                      const [file]: any = e.target.files;
                      setImg(file);
                    }}
                  />
                </Form.Input>
              </div>
              <div className="o-category__form-img-details">
                <p>Uploading: {uploadProgress}</p>
                <img
                  src={imageUrl}
                  className={`o-taskImage__wrapper ${!img && 'd-none'}`}
                  alt="task_image"
                />
              </div>

              <div>
                {submissionStatus === 'idle' ? (
                  <Button
                    primary
                    {...((name.replace(/\s/g, '') === '' ||
                      category === '' ||
                      subCategory === '' ||
                      description.replace(/\s/g, '') === '' ||
                      img === undefined ||
                      estimatedCost.replace(/\s/g, '') === '' ||
                      nameError === true) && { disabled: true })}
                    onClick={(e) => {
                      e.preventDefault();
                      if (img === undefined) {
                        dispatch(submitTaskForm({ mutation: submitForm }));
                      } else {
                        uploadHandler().then(() => {
                          dispatch(submitTaskForm({ mutation: submitForm }));
                        });
                      }
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button loading primary>
                    Loading
                  </Button>
                )}
              </div>
            </Form>
          </Island>
        </Grid.Column>
        <Grid.Column width={6}></Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default TaskForm;
