import React, { FC, useContext } from "react";
import Button from "@laborhack/custom-button";
import { SingleQuotationContext } from "../../../../context/single-quotation.context";

import styles from "./styles.module.scss";

export interface SplitItemProps {
  itemId: string;
  onSplit: () => void;
}

export const SplitItem: FC<SplitItemProps> = ({ itemId, onSplit }) => {
  const { items, splitItem } = useContext(SingleQuotationContext);

  const { proCount } = items[itemId];

  const [splitRatio, setSplitRatio] = React.useState<string>(
    proCount.toString()
  );

  const splitRatioAsArray = splitRatio
    .trim()
    .split(",")
    .map((item) => Number(item.trim()));

  const addsUpToProCount =
    splitRatioAsArray.reduce((acc, current) => acc + current, 0) === proCount;

  const noInValidCharacters = splitRatioAsArray.every((split) => split > 0);

  const splitRatioIsValid = addsUpToProCount && noInValidCharacters;

  const splitRatioWouldCauseNoChange =
    splitRatioAsArray.length === 1 && splitRatioAsArray[0] === proCount;

  return (
    <div className={styles.splitItem}>
      <h3>Split</h3>
      <div className={styles.body}>
        <div className={styles.prompt}>
          <p>
            <strong>{proCount} Pros</strong> are on this item
          </p>
        </div>
        <div className={styles.dataEntry}>
          <label htmlFor=''>Enter split ratio</label>
          <input
            type='text'
            value={splitRatio}
            onChange={(e) => setSplitRatio(e.target.value)}
          />
          {!splitRatioIsValid && (
            <div className={styles.errors}>
              {!addsUpToProCount && (
                <p>
                  Split ratio does not add up to total number of pros on this
                  item
                </p>
              )}
              {!noInValidCharacters && (
                <p>Split ratio contains invalid values</p>
              )}
            </div>
          )}
        </div>
        <div className={styles.note}>
          <p>
            A split ratio is a comma separated list of numbers that determines
            how the current item would be split.
            <br />
            <br />
            For example, if a quotation request item has 5 pros and you enter a
            split ratio of <code>2,2,1</code> it will create 3 items with 2
            pros, 2 pros and 1 pro respectively
            <br />
            <br />
            Make sure the split ratio adds up to the total number of pros in the
            item
          </p>
        </div>
        <div className={styles.actions}>
          <Button
            disabled={!splitRatioIsValid || splitRatioWouldCauseNoChange}
            onClick={() => {
              splitItem(itemId, splitRatioAsArray);
              onSplit();
            }}
          >
            Split
          </Button>
        </div>
      </div>
    </div>
  );
};
