import React, { FC } from "react";
import { ContractRecruitment } from "../../../types";
import Field from "../../../../../_components/Field";

export interface BasicRecruitmentDetailsProps {
  recruitment: ContractRecruitment;
}

export const BasicRecruitmentDetails: FC<BasicRecruitmentDetailsProps> = ({
  recruitment,
}) => {
  const { client, createdAt } = recruitment;

  return (
    <div className='mt-8'>
      <Field label='Client' value={`${client.firstName} ${client.lastName}`} />
      <Field
        label='Request Date'
        value={new Date(createdAt).toLocaleString()}
      />
    </div>
  );
};
