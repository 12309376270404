import { gql } from "@apollo/client";

export const UPDATE_JOB_STATUS = gql`
  mutation UpdateJobStatus($data: UpdateJobProgressInput!) {
    updateJobProgress(data: $data) {
      status
      message
      data
    }
  }
`;

export const UPDATE_ASSIGNMENT_PROGRESS = gql`
  mutation UpdateAssignmentProgress(
    $assignmentId: String!
    $progress: AssignmentProgress!
  ) {
    updateAssignmentProgress(assignmentId: $assignmentId, progress: $progress) {
      status
      message
    }
  }
`;

export const CREATE_REVIEW = gql`
  mutation CreateReview($input: CreateReviewInput!) {
    createReview(input: $input) {
      status
      message
    }
  }
`;

export const ASSIGN_TRADESMAN = gql`
  mutation Assign($data: AssignTradesmanInput!) {
    assignTradesman(data: $data) {
      status
      message
    }
  }
`;

export const UNASSIGN_TRADESMAN = gql`
  mutation Unassign($data: UnassignTradesmanInput!) {
    unassignTradesmanFromJob(data: $data) {
      status
      message
    }
  }
`;

export const CREATE_NEW_ASSIGNMENT = gql`
  mutation Assign($data: AssignJobsToTradesmanInput!) {
    assignJobsToTradesman(data: $data) {
      status
      message
    }
  }
`;

export const DELETE_ASSIGNMENT = gql`
  mutation Delete($assignmentId: String!) {
    deleteAssignment(assignmentId: $assignmentId) {
      status
      message
    }
  }
`;

export const UPDATE_JOB_COST = gql`
  mutation UpdateJobCost($data: UpdateJobFinalCostInput!) {
    updateJobFinalCost(data: $data) {
      status
      message
    }
  }
`;

export const CREATE_ASSIGNMENT_INSPECTION_INVOICE = gql`
  mutation CreateInspectionInvoice(
    $assignmentId: String!
    $amount: Float!
    $allowedPaymentProviders: [String!]!
  ) {
    createAssignmentInspectionInvoice(
      assignmentId: $assignmentId
      amount: $amount
      allowedPaymentProviders: $allowedPaymentProviders
    ) {
      status
      message
    }
  }
`;

export const WAIVE_INSPECTION_FEE = gql`
  mutation WaiveInspectionFee($assignmentId: String!) {
    waiveInspectionFee(assignmentId: $assignmentId) {
      status
      message
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation CreateInvoice($data: RaiseInvoiceInput!) {
    createInvoiceFromAssignment(input: $data) {
      status
      message
      data {
        invoice {
          type
          invoiceDate
          discount
          options {
            materialCommissionCap
          }
          assignments {
            id
            progress
            order {
              id
              orderCode
              inspectionPayment {
                status
                amount
              }
              locationSchedules {
                locationId
                scheduledDate
              }
              isEmergency
            }
            jobs {
              id
              jobCode
              custom {
                name
                description
                resourceUrl
                subCategoryId
                categoryId
              }
              task {
                id
                name
                description
                estimatedCost
                categoryId
                subCategoryId
                imageUrl
              }
              finalCost
              status
              completedAt
              payment {
                transactionReference
                transactionDate
                amount
                status
              }
              contact {
                notes
                location {
                  id
                  buildingNumber
                  streetName
                  area
                  location
                  landmark
                  city
                }
              }
              invoiceDetails {
                materials {
                  name
                  unitPrice
                  quantity
                }
                labor
              }
            }
            date
          }
          recipient {
            name
            email
            phoneNumber
            address
          }
          payment {
            id
            transactionDate
            transactionReference
            status
          }
        }
      }
    }
  }
`;

export const SEND_MATERIALS_INVOICE = gql`
  mutation SendInvoice($assignmentIds: [String!]!) {
    sendMaterialsInvoiceEmailToClient(assignmentIds: $assignmentIds) {
      status
      message
    }
  }
`;

export const UPDATE_MULTIPLE_JOB_INVOICE_DETAILS = gql`
  mutation UpdateJobs($data: UpdateJobsInvoiceDetailsInput!) {
    updateMultipleJobInvoiceDetails(data: $data) {
      status
      message
    }
  }
`;

export const REQUEST_ASSIGNMENT_PAYMENT = gql`
  mutation initiateAssignmentPayment($data: InitiateAssignmentPaymentInput!) {
    initiateAssignmentPayment(input: $data) {
      status
      message
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation deleteOrder($orderId: String!) {
    deleteOrder(orderId: $orderId) {
      status
      message
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation Cancel($orderId: String!) {
    cancelOrder(orderId: $orderId) {
      id
    }
  }
`;
