import {
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";
import { FaEllipsisH } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Referrer } from "../referrer-payouts/types";
import { formatDate } from "./helpers";

export interface ReferrerPayoutTableProps {
	data?: Referrer[];
	isLoading?: boolean;
}

export const ReferrersTable = ({
	data,
	isLoading,
}: ReferrerPayoutTableProps) => {
	const history = useHistory();

	return (
		<TableContainer>
			<Table className="border">
				<Thead>
					<Tr>
						<Th className="border">Date Created</Th>
						<Th className="border">Referrer's Name</Th>
						<Th className="border">Business Name</Th>
						<Th className="border">Email</Th>
						<Th className="border">Referral Code</Th>
						<Th className="border">Linked Discount</Th>
						<Th className="border"></Th>
					</Tr>
				</Thead>
				<Tbody>
					{isLoading && (
						<Tr>
							<Td colSpan={6} className="border">
								<div className="w-full flex justify-center items-center min-h-[80px]">
									<p>Loading...</p>
								</div>
							</Td>
						</Tr>
					)}
					{!data?.length && !isLoading && (
						<Tr>
							<Td colSpan={6} className="border">
								<div className="w-full flex justify-center items-center min-h-[80px]">
									<p>No data available</p>
								</div>
							</Td>
						</Tr>
					)}
					{data?.map((referrer) => (
						<Tr key={referrer.id}>
							<Td className="border">
								{formatDate(new Date(referrer.createdAt))}
							</Td>
							<Td className="border">{referrer.name}</Td>
							<Td className="border">{referrer.businessName}</Td>
							<Td className="border">{referrer.email}</Td>
							<Td className="border">{referrer.referralCode}</Td>
							<Td className="border">{referrer.linkedDiscount.code}</Td>

							<Td className="border">
								<Menu>
									<MenuButton
										as={IconButton}
										aria-label="Options"
										icon={<FaEllipsisH />}
										variant="outline"
									/>
									<MenuList>
										<MenuItem
											onClick={() => history.push(`/referrers/${referrer.id}`)}
										>
											View Details
										</MenuItem>
									</MenuList>
								</Menu>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
