import React, { FC } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@laborhack/custom-button';

import { components as Layout } from '../../../components/layout';
import Island from '../../../_components/Island';
import { AssignmentTableRow } from '../types';
import AssignmentCardList from './components/AssignmentCardList';
import styles from './styles.module.scss';
import { Information } from './Information';
import Badge from '@laborhack/badge';
import { useMutation } from '@apollo/client';
import { MERGE_ASSIGNMENTS } from '../_graphql/mutation';
import { Alert } from 'antd';

const { Trail } = Layout;

export interface MergeAssignmentsProps {
  assignments: AssignmentTableRow[];
}

export const MergeAssignments: FC<MergeAssignmentsProps> = ({
  assignments,
}) => {
  const history = useHistory();

  const assignmentsMap = new Map<string, AssignmentTableRow>();

  assignments.forEach((assignment) => {
    assignmentsMap.set(assignment.id, assignment);
  });

  const { orderCode } = useParams<{ orderCode: string }>();

  const [[target, ...sources], setSelectedAssignments] = useState<string[]>([]);

  const [mergeAssignments, { loading, error }] = useMutation(
    MERGE_ASSIGNMENTS,
    {
      onCompleted: () => {
        history.push(`/orders/${orderCode}`);
      },
    }
  );

  const handleCardClick = (id: string) => {
    if (id === target) {
      setSelectedAssignments([]);
      return;
    }

    if (sources.find((source) => source === id)) {
      setSelectedAssignments((prev) => {
        return prev.filter((source) => source !== id);
      });

      return;
    }

    setSelectedAssignments((prev) => {
      const clone = prev.slice();
      clone.push(id);
      return clone;
    });
  };

  const sourceBadge = (
    <Badge type="basic" size="small">
      source
    </Badge>
  );

  const targetBadge = (
    <Badge type="success" size="small">
      target
    </Badge>
  );

  const mergableAssignments = assignments.filter((assignment) => {
    if (!target) {
      return true;
    }

    if (assignment.id === target) {
      return true;
    }

    const targetAssignment = assignmentsMap.get(target);

    if (assignment.tradesman.id !== targetAssignment?.tradesman.id) {
      return false;
    }

    if (assignment.location !== targetAssignment.location) {
      return false;
    }

    if (assignment.inspection !== 'waived') {
      return false;
    }

    return true;
  });

  const handleMerge = () => {
    mergeAssignments({
      variables: {
        target,
        sources,
      },
    });
  };

  return (
    <Island
      header={<Trail root="Merge Assignments" child={orderCode} deep></Trail>}
    >
      <div className={styles.information}>
        <Information description="Note: only assignments with the same pro, done at the same location and inspection fee waived can be merged" />
        <Information
          item={targetBadge}
          description="tasks in all selected assignments would be merged into this.
only one target can be selected"
        />
        <Information
          item={sourceBadge}
          description="all tasks in this assignment would be added to the target.
you can select multiple sources"
        />
      </div>

      <h4 className={styles.header}>Select assignments you want to merge</h4>
      <AssignmentCardList
        assignments={mergableAssignments}
        sources={sources}
        target={target}
        onClick={handleCardClick}
      />

      {error && (
        <Alert type="success" banner closable message={error?.message} />
      )}

      <div className={styles.actions}>
        <Button
          variant="success"
          disabled={!target || sources.length < 1}
          loading={loading}
          onClick={handleMerge}
        >
          Merge
        </Button>
      </div>
    </Island>
  );
};
