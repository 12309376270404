import { Checkbox } from "antd";
import { Dispatch, FC, SetStateAction } from "react";
import { BusinessFilter as Filter } from "../types";

export interface AddFilterProps {
  filters: Filter[];
  setFilters: Dispatch<SetStateAction<Filter[]>>;
}

export const AddFilter: FC<AddFilterProps> = ({ filters, setFilters }) => {
  const handleAddFilter = (filter: Filter) => {
    setFilters((prev) => [...prev, filter]);
  };

  const handleRemoveFilter = (filter: Filter) => {
    setFilters((prev) =>
      prev.filter((f) => f.class + f.name !== filter.class + filter.name)
    );
  };

  const handleStatusChange = (filter: Filter, checked?: boolean) => {
    return checked ? handleAddFilter(filter) : handleRemoveFilter(filter);
  };

  return (
    <div className='p-8 w-96'>
      <h3 className='text-primary-500 font-semibold'>Filters</h3>
      <div className='mt-8'>
        <div className='mt-4'>
          <h5 className='text-primary-500 text-lg font-semibold mb-2'>
            Activation
          </h5>
          <div className='grid grid-cols-1 gap-y-2'>
            <Checkbox
              checked={filters.some((filter) => {
                return filter.class === "activated" && filter.value;
              })}
              onChange={({ target: { checked } }) =>
                handleStatusChange(
                  {
                    class: "activated",
                    value: true,
                    name: "Activated",
                  },
                  checked
                )
              }
            >
              Activated
            </Checkbox>
            <Checkbox
              className='!ml-0'
              checked={filters.some((filter) => {
                return filter.class === "activated" && !filter.value;
              })}
              onChange={({ target: { checked } }) =>
                handleStatusChange(
                  {
                    class: "activated",
                    value: false,
                    name: "Not Activated",
                  },
                  checked
                )
              }
            >
              Not Activated
            </Checkbox>
          </div>
        </div>
        <div className='mt-4'>
          <h5 className='text-primary-500 text-lg font-semibold mb-2'>
            Delivery Status
          </h5>
          <div className='grid grid-cols-1 gap-y-2'>
            <Checkbox
              checked={filters.some((filter) => {
                return (
                  filter.class === "deliveryStatus" && filter.value === "Failed"
                );
              })}
              onChange={({ target: { checked } }) =>
                handleStatusChange(
                  {
                    class: "deliveryStatus",
                    value: "Failed",
                    name: "Failed",
                  },
                  checked
                )
              }
            >
              Failed
            </Checkbox>
            <Checkbox
              className='!ml-0'
              checked={filters.some((filter) => {
                return (
                  filter.class === "deliveryStatus" &&
                  filter.value === "Pending"
                );
              })}
              onChange={({ target: { checked } }) =>
                handleStatusChange(
                  {
                    class: "deliveryStatus",
                    value: "Pending",
                    name: "Pending",
                  },
                  checked
                )
              }
            >
              Pending
            </Checkbox>
            <Checkbox
              className='!ml-0'
              checked={filters.some((filter) => {
                return (
                  filter.class === "deliveryStatus" &&
                  filter.value === "Delivered"
                );
              })}
              onChange={({ target: { checked } }) =>
                handleStatusChange(
                  {
                    class: "deliveryStatus",
                    value: "Delivered",
                    name: "Delivered",
                  },
                  checked
                )
              }
            >
              Delivered
            </Checkbox>
            <Checkbox
              className='!ml-0'
              checked={filters.some((filter) => {
                return (
                  filter.class === "deliveryStatus" && filter.value === "Opened"
                );
              })}
              onChange={({ target: { checked } }) =>
                handleStatusChange(
                  {
                    class: "deliveryStatus",
                    value: "Opened",
                    name: "Opened",
                  },
                  checked
                )
              }
            >
              Opened
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};
