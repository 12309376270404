import React, { FC } from "react";
import { Form, Icon } from "semantic-ui-react";
import { CustomInput } from "../../../../_components/Input";

import styles from "./styles.module.scss";

export interface SplitPaymentsFieldProps {
  onChange: (value: string, isValid: boolean) => void;
  value: string;
  label?: string;
  canAdd?: boolean;
  canRemove?: boolean;
  onAdd?: () => void;
  onRemove?: () => void;
  readOnly?: boolean;
}

export const SplitPaymentsField: FC<SplitPaymentsFieldProps> = ({
  value,
  onChange,
  canAdd,
  canRemove,
  label,
  onAdd,
  onRemove,
  readOnly,
}) => {
  return (
    <Form>
      <div className={styles.field}>
        <CustomInput
          readOnly={readOnly}
          value={value}
          type="number"
          textLabel={label}
          onChange={(value, isValid) => onChange(value, isValid)}
        />
        {canAdd && <Icon name="add circle" size="large" onClick={onAdd} />}
        {canRemove && (
          <Icon name="trash" color="red" size="large" onClick={onRemove} />
        )}
      </div>
    </Form>
  );
};
