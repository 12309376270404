import React, { FC, useState } from "react";
import Input from "@laborhack/input";
import Button from "@laborhack/custom-button";
import Island from "../../_components/Island";
import { FieldValue } from "../../types";
import {
  addPhoneNumberPrefix,
  emailCheck,
  emailDoesNotExist,
  nonEmptyString,
  phoneNumberCheck,
  phoneNumberDoesNotExist,
} from "../../helpers/helpers";
import { Form, Icon } from "semantic-ui-react";
import Select from "../../_components/Select";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { useHistory } from "react-router-dom";
import { LeadSource } from "./types";

const leadSourceOptions: {
	key: string;
	text: string;
	value: LeadSource;
}[] = [
	{
		key: "referral",
		text: "Friend or Referral",
		value: LeadSource.Friend_or_Referral,
	},
	{
		key: "flyer",
		text: "Marketing Flyer",
		value: LeadSource.Marketing_Flyer,
	},
	{
		key: "social_media",
		text: "Social Media",
		value: LeadSource.Social_Media,
	},
	{
		key: "online_search",
		text: "Online Search",
		value: LeadSource.Online_Search,
	},

	{
		key: "youtube",
		text: "Youtube",
		value: LeadSource.Youtube,
	},

	{
		key: "radio",
		text: "Radio",
		value: LeadSource.Radio,
	},
	{
		key: "tv",
		text: "TV",
		value: LeadSource.TV,
	},
	{
		key: "voucher",
		text: "Voucher",
		value: LeadSource.Voucher,
	},
	{
		key: "event",
		text: "Event or Exhibition",
		value: LeadSource.Event_or_Exhibition,
	},
	{
		key: "herconomy",
		text: "Herconomy",
		value: LeadSource.Herconomy,
	},
	{
		key: "newspaper",
		text: "Newspaper",
		value: LeadSource.Newspaper,
	},
	{
		key: "sales_team",
		text: "Sales Team",
		value: LeadSource.Sales_Team,
	},
	{
		key: "influencers",
		text: "Influencers",
		value: LeadSource.Influencers,
	},
	{
		key: "other",
		text: "Other",
		value: LeadSource.Other,
	},
];

const CREATE_CLIENT = gql`
	mutation createClient($data: CreateClientInput!) {
		createClient(data: $data) {
			status
			message
		}
	}
`;

export interface CreateClientProps {}

export const CreateClient: FC<CreateClientProps> = ({}) => {
	const client = useApolloClient();

	const history = useHistory();

	const query = new URLSearchParams(history.location.search);

	const redirectTo = query.get("redirect_to");

	const [firstName, setFirstName] = useState<FieldValue<string>>({
		value: "",
		isValid: false,
	});

	const [lastName, setLastName] = useState<FieldValue<string>>({
		value: "",
		isValid: false,
	});

	const [phoneNumber, setPhoneNumber] = useState<FieldValue<string>>({
		value: "",
		isValid: false,
	});

	const [email, setEmail] = useState<FieldValue<string>>({
		value: "",
		isValid: false,
	});

	const [leadSource, setLeadSource] = useState<FieldValue<LeadSource>>({
		value: LeadSource.Friend_or_Referral,
		isValid: true,
	});

	const [otherLeadSourceText, setOtherLeadSourceText] = useState<string>("");

	const [created, setCreated] = useState<boolean>(false);

	const [createClient, { loading, error, reset }] = useMutation(CREATE_CLIENT, {
		onCompleted: (data) => {
			setCreated(true);

			if (redirectTo && redirectTo === "/orders/create") {
				history.push(`/orders/create?client_email=${email.value}`);
			} else {
				setTimeout(() => {
					history.push("/clients");
				}, 2000);
			}
		},
	});

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <RequestFailed errorMessage={error.message} onRetry={reset} />;
	}

	const canSubmit =
		firstName.isValid &&
		lastName.isValid &&
		email.isValid &&
		phoneNumber.isValid &&
		leadSource.isValid &&
		(leadSource.value === LeadSource.Other
			? nonEmptyString(otherLeadSourceText)
			: true);

	const handleCreateClient = async () => {
		createClient({
			variables: {
				data: {
					firstName: firstName.value,
					lastName: lastName.value,
					phoneNumber: addPhoneNumberPrefix(phoneNumber.value),
					email: email.value,
					leadSource: leadSource.value,
					otherLeadSourceText,
				},
			},
		});
	};

	return (
		<Island>
			<div className="mb-4">
				<h2 className="text-primary-500 text-xl font-bold m-0">
					Create Client
				</h2>
				<p className="m-0 text-primary-300">Create a new client.</p>
			</div>
			<div className="w-96">
				{created ? (
					<div className="text-success flex justify-center items-center flex-col h-96">
						<div className="w-100">
							<Icon name="check" size="huge" className="focus:outline-none" />
						</div>

						<h3 className="w-100 text-success font-bold mt-4">
							Client Created
						</h3>
					</div>
				) : (
					<Form>
						<Input
							textLabel="First Name"
							placeholder="Ciroma"
							value={firstName.value}
							onChange={(value, isValid) => {
								setFirstName({ value, isValid });
							}}
							validate={{
								check: (value) => nonEmptyString(value),
								message: "Please enter a name",
							}}
							required
						/>
						<Input
							textLabel="Last Name"
							placeholder="Adekunle"
							value={lastName.value}
							onChange={(value, isValid) => {
								setLastName({ value, isValid });
							}}
							validate={{
								check: (value) => nonEmptyString(value),
								message: "Please enter a name",
							}}
							required
						/>
						<Input
							label="+234"
							textLabel="Phone Number"
							placeholder="8012345678"
							value={phoneNumber.value}
							type="tel"
							onChange={(value, isValid) => {
								setPhoneNumber({ value, isValid });
							}}
							validate={{
								check: phoneNumberCheck,
								message: "Please enter a valid phone number",
							}}
							asyncValidate={{
								check: (value) => phoneNumberDoesNotExist(client, value),
								message: "Phone number already exists",
							}}
							required
						/>
						<Input
							textLabel="Email Address"
							placeholder="person@place.com"
							value={email.value}
							type="email"
							onChange={(value, isValid) => {
								setEmail({ value, isValid });
							}}
							validate={{
								check: emailCheck,
								message: "Please enter a valid email",
							}}
							asyncValidate={{
								check: (value) => emailDoesNotExist(client, value),
								message: "Email already exists",
							}}
							required
						/>

						<div className="mt-2">
							<label className="!text-primary-500 opacity-60 font-medium block mb-1">
								How did you hear about us?
							</label>
							<Select
								className="mb-4 font-semibold"
								value={leadSource.value}
								onChange={(_, data) => {
									setLeadSource({
										value: data.value as LeadSource,
										isValid: true,
									});
								}}
								required
								placeholder="Select One"
								options={leadSourceOptions}
							/>
							{leadSource.value === LeadSource.Other && (
								<Input
									placeholder="Please specify"
									value={otherLeadSourceText}
									onChange={(value) => setOtherLeadSourceText(value)}
									validate={{
										check: nonEmptyString,
										message: "Please specify how you heard about us",
									}}
									required
								/>
							)}
						</div>

						<div className="my-8">
							<Button
								fullWidth
								disabled={!canSubmit}
								onClick={handleCreateClient}
							>
								Create Account
							</Button>
						</div>
					</Form>
				)}
			</div>
		</Island>
	);
};
