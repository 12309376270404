import React, { FC, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Icon,
  Grid,
  Header,
  Message,
} from "semantic-ui-react";
import { EDIT_ADMIN } from "../../../graphql/query";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { ServiceSelectors } from "../../../redux/store";
import { types } from "../../../service";
const { Field } = Form;

export const EditAdminModal: FC<types.EditInfoProps> = ({
  firstname,
  lastname,
  propEmail,
  userId,
  refresh,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(firstname);
  const [lastName, setLastName] = useState<string>(lastname);
  const [email, setEmail] = useState<string>(propEmail);
  const [filteredUser, setFilteredUser] = useState<types.IAdminData[]>([]);
  const [success, setSuccess] = useState<Boolean>(false);
  const [error, setError] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { selectAllAdmin } = ServiceSelectors;
  const allAdmin: types.IAdminData[] = useSelector(selectAllAdmin);

  useEffect(() => {
    if (allAdmin) {
      const UserData = allAdmin.filter((user) => {
        return user.id === userId;
      });
      setFilteredUser(UserData);
    }
  }, [allAdmin, userId]);

  const [editForm, { loading }] = useMutation(EDIT_ADMIN);

  const clearMessage = () => {
    setError(false);
    setSuccess(false);
  };
  const close = () => {
    setOpen(false);
    clearMessage();
  };

  useEffect(() => {
    if (success) {
      setOpen(false);
      clearMessage();
      setSuccess(false);
      refresh();
    }
  }, [success, refresh]);

  return (
    <Modal
      className="o-admin__edit-modal"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="mini"
      trigger={
        <Button
          color="blue"
          icon
          labelPosition="right"
          size="tiny"
          onClick={() => {
            setOpen(true);
          }}
          {...rest}
        >
          <Icon name="pencil alternate"></Icon>
          Edit
        </Button>
      }
    >
      <Modal.Header>Edit User</Modal.Header>
      <Modal.Content>
        <Grid centered>
          <Grid.Column width="16">
            {error && <Message error header="Error" content={errorMessage} />}
            {success && (
              <Message
                success
                header="Update was successful"
                content="User has been successfully updated"
              />
            )}
            <Form>
              <Field>
                {filteredUser.map((data: any) => {
                  return (
                    <Header key={data.id} as="h3">
                      Edit profile
                    </Header>
                  );
                })}
                <label>First Name</label>
                <Input
                  placeholder="Enter first name"
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    clearMessage();
                  }}
                />
                <label>Last Name</label>
                <Input
                  placeholder="Enter first name"
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    clearMessage();
                  }}
                />
                <label>Email</label>
                <Input
                  placeholder="Enter email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    clearMessage();
                  }}
                />
              </Field>
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          inverted
          onClick={() => {
            close();
          }}
        >
          No
        </Button>

        {loading ? (
          <Button loading primary>
            Loading
          </Button>
        ) : (
          <Button
            color="blue"
            onClick={(e) => {
              e.preventDefault();

              editForm({
                variables: {
                  data: {
                    id: userId,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                  },
                },
              })
                .then((res) => {
                  if (res.data) {
                    setSuccess(true);
                  }
                })
                .catch((err) => {
                  setError(true);
                  setErrorMessage(err.error);
                });
            }}
          >
            Save
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
