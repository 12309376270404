import React from "react";
import { Route, Switch } from "react-router-dom";
import { BusinessList } from "./components/BusinessList";
import { CreateBusiness } from "./components/CreateBusiness";
import { ViewBusiness } from "./components/ViewBusiness";

export default function () {
  return (
    <Switch>
      <Route path='/businesses/create'>
        <CreateBusiness />
      </Route>
      <Route path='/businesses/:id'>
        <ViewBusiness />
      </Route>
      <Route path='/businesses'>
        <BusinessList />
      </Route>
    </Switch>
  );
}
