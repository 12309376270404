import React, { FC } from "react";
import { formatAsNaira } from "../../../helpers/helpers";

const RefundInvoiceDetails: FC<{
  totalCost: number;
  materialsCost: number;
  laborHackFee: number;
}> = ({ totalCost, materialsCost, laborHackFee }) => {
  return (
    <div className="o-refundInvoiceDetails__wrapper">
      <div className="m-refundInvoiceDetails__information">
        <h6 className="a-refundInvoiceDetails__informationHeader">
          Total Cost (including VAT)
        </h6>
        <p className="a-refundInvoiceDetails__informationText -large">
          {formatAsNaira(totalCost)}
        </p>
      </div>
      <div className="m-refundInvoiceDetails__information">
        <h6 className="a-refundInvoiceDetails__informationHeader">
          Materials Cost
        </h6>
        <p className="a-refundInvoiceDetails__informationText">
          {formatAsNaira(materialsCost)}
        </p>
      </div>
      <div className="m-refundInvoiceDetails__information">
        <h6 className="a-refundInvoiceDetails__informationHeader">
          LaborHack Fee
        </h6>
        <p className="a-refundInvoiceDetails__informationText">
          {formatAsNaira(laborHackFee)}
        </p>
      </div>
    </div>
  );
};

export default RefundInvoiceDetails;
