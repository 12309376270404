import { getTransferBadgeColor } from "@/constants";
import { formatAsNaira } from "@/helpers/helpers";
import {
	Badge,
	IconButton,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";
import { FaEllipsisH, FaExternalLinkAlt, FaSyncAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { TransferStatus } from "../../types";
import { SubscriptionRequestCallPayout } from "./types";

export interface SubscriptionRequestCallPayoutTableProps {
	data?: SubscriptionRequestCallPayout[];
	isConfirmations?: boolean;
	isLoading?: boolean;
}

export const SubscriptionRequestsPayoutsTable = ({
	data,
	isConfirmations,
	isLoading,
}: SubscriptionRequestCallPayoutTableProps) => {
	const history = useHistory();

	return (
		<TableContainer>
			<Table className="border">
				<Thead>
					<Tr>
						<Th className="border">Date Created</Th>
						<Th className="border">Pro</Th>
						<Th className="border">Status</Th>
						<Th className="border">Period</Th>
						<Th className="border">Amount</Th>
						<Th className="border"></Th>
					</Tr>
				</Thead>
				<Tbody>
					{isLoading && (
						<Tr>
							<Td colSpan={5} className="border">
								<div className="w-full flex justify-center items-center min-h-[80px]">
									<p>
										Loading <FaSyncAlt />
									</p>
								</div>
							</Td>
						</Tr>
					)}
					{!data?.length && (
						<Tr>
							<Td colSpan={6} className="border">
								<div className="w-full flex justify-center items-center min-h-[80px]">
									<p>No data available</p>
								</div>
							</Td>
						</Tr>
					)}
					{data?.map((payout) => (
						<Tr key={payout.id}>
							<Td className="border">
								{new Date(payout.createdAt).toLocaleDateString()}
							</Td>
							<Td className="border">{`${payout.pro.firstName} ${payout.pro.lastName}`}</Td>
							<Td className="border">
								<Badge
									colorScheme={getTransferBadgeColor(payout?.transfer?.status)}
								>
									{payout?.transfer?.status.replace("_", " ")}
								</Badge>
							</Td>
							<Td className="border">
								{`${payout.period.month + 1}`.padStart(2, "0")}/
								{payout.period.year}
							</Td>
							<Td className="border">{formatAsNaira(payout.amount)}</Td>

							<Td className="border">
								<Menu>
									<MenuButton
										as={IconButton}
										aria-label="Options"
										icon={<FaEllipsisH />}
										variant="outline"
									/>
									<MenuList>
										<MenuItem
											onClick={() =>
												history.push(
													`/subscription-request-payouts/${
														isConfirmations ? "confirmations" : "requests"
													}/${payout.id}`
												)
											}
										>
											View Details
										</MenuItem>
									</MenuList>
								</Menu>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
