import { Route, Switch, useRouteMatch } from "react-router-dom";
import { CertificationList } from "./CertificationList";
import { CertificationView } from "./CertificationView";

export const Certifications = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={CertificationList} />
      <Route path={`${path}/:id`} component={CertificationView} />
    </Switch>
  );
};
