export const tradesmenList = [
  {
    id: "1",
    name: "Husbane Jeofrey",
    email: "husbyjeo@abc.com",
    category: "Plumbing",
    phoneNumber: "+2348022222222",
    level: 1,
    location: {
      buildingNumber: "12",
      streetName: "Babs Animashaun street",
      area: "Surulere",
      city: "Lagos",
      landmark: "Surulere",
      location: "Lekki",
    },
  },
  {
    id: "2",
    name: "Richard Jefferson",
    email: "richy@abc.com",
    category: "Electricals",
    phoneNumber: "+2348022222333",
    level: 1,
    location: {
      buildingNumber: "12",
      streetName: "Babs Animashaun street",
      area: "Surulere",
      city: "Lagos",
      landmark: "Surulere",
      location: "Lekki",
    },
  },
];

export const userDetails = {
  HEADER: "Tradesman Details",
  NAME: "Full Name: ",
  CATEGORY: "Category: ",
  EMAIL: "Email: ",
  PHONENUMBER: "Phone Number: ",
  LEVEL: "Level: ",
  location: {
    buildingNumber: "12",
    streetName: "Babs Animashaun street",
    area: "Surulere",
    city: "Lagos",
    landmark: "Surulere",
    location: "Lekki",
  },
};
