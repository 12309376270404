import { gql } from "@apollo/client";

export const GET_SUBSCRIPTION_REQUEST_CALL = gql`
	query SubscriptionRequestCall($subscriptionRequestCallId: String!) {
		subscriptionRequestCall(
			subscriptionRequestCallId: $subscriptionRequestCallId
		) {
			id
			proPaymentAmount
			expectedProPaymentAmount
		}
	}
`;
