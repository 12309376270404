import {
  Button,
  Icon,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BiRightArrowAlt } from "react-icons/bi";
import { Pro } from "../../types";

interface TradesListProps {
  pro: Pro;
  onSelectTrade: (tradeId: string) => void;
  selectedTradeId?: string;
}

export const TradesList = ({
  pro,
  onSelectTrade,
  selectedTradeId,
}: TradesListProps) => {
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Current Level</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {pro.trades.map((trade) => (
            <Tr
              key={trade.id}
              bg={selectedTradeId === trade.id ? "gray.50" : ""}
            >
              <Td fontWeight='semibold'>{trade.name}</Td>
              <Td>
                <Tag
                  size='lg'
                  colorScheme={
                    trade.currentLevel === trade.levels.length
                      ? "green"
                      : trade.currentLevel === 0
                      ? "red"
                      : "yellow"
                  }
                  borderRadius='full'
                >
                  {trade.currentLevel} / {trade.levels.length}
                </Tag>
              </Td>
              <Td isNumeric>
                <Button
                  variant='ghost'
                  colorScheme='blue'
                  rightIcon={<Icon as={BiRightArrowAlt} />}
                  onClick={() => onSelectTrade(trade.id)}
                >
                  View
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
