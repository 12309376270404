import React, { FC, useState } from "react";
import { components as LayoutComponents } from "../../layout";
import { STRINGS } from "../constants";
import { Row, Col, Result } from "antd";
import Button from "../../../_components/Button";
import { Job, Order, TradesmanAssignment } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { AssignmentSelectors, AssignmentActions } from "../../../redux/store";
import { useMutation } from "@apollo/client";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import SelectAvailablePro from "./SelectAvailablePro";
import ScheduleSuggestedPro from "./ScheduleSuggestedPro";
import { CREATE_NEW_ASSIGNMENT } from "../graphql/mutation";
import Island from "../../../_components/Island";

const { Trail, TabToggle } = LayoutComponents;
const { TRADESMAN_ASSIGNMENT_SELECT_JOB } = STRINGS;
const { selectCreateAssignmentData } = AssignmentSelectors;
const { tradesmanAdded, dateAdded } = AssignmentActions;

export const SelectTradesmanAssignment: FC<{
  selectedCategory: string;
  selectedJobs: {
    [x: string]: Job;
  };
  order: Order;
  refetchAssignments: any;
  assignments: TradesmanAssignment[];
}> = ({
  selectedJobs,
  selectedCategory,
  order,
  refetchAssignments,
  assignments,
}) => {
  const { path } = useRouteMatch();
  const [selectedTab, setSelectedTab] = useState<"one" | "two">("one");
  const data = useSelector(selectCreateAssignmentData);
  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const locationSchedule = order.locationSchedules.find(
    ({ locationId }) => locationId === query.get("locationId")
  );

  const [submitAssignment, submitAssignmentResult] = useMutation(
    CREATE_NEW_ASSIGNMENT
  );

  if (submitAssignmentResult.data?.assignJobsToTradesman.status) {
    refetchAssignments();
    history.push(path.replace("/assignments/create", ""));
  }

  const selectedJobsArray = Object.values(selectedJobs);

  if (!selectedJobsArray.length) {
    return (
      <Result title='You cannot assign a tradesman without selecting jobs first'>
        <Button
          onClick={() => {
            history.push(path.replace("assignments/create", ""));
          }}
        >
          Go Back
        </Button>
      </Result>
    );
  }

  const resetCreationData = () => {
    dispatch(tradesmanAdded(null));
    dispatch(dateAdded(null));
  };

  return (
    <Island
      header={<Trail root={TRADESMAN_ASSIGNMENT_SELECT_JOB.header}></Trail>}
    >
      <Row>
        <Col span='16'>
          <TabToggle
            selected={selectedTab}
            optionOne={{
              label: "Schedule Suggested Pro",
              onSelect: () => {
                resetCreationData();
                setSelectedTab("one");
              },
            }}
            optionTwo={{
              label: "Select Available Pro",
              onSelect: () => {
                resetCreationData();
                setSelectedTab("two");
              },
            }}
          />

          {selectedTab === "one" && (
            <ScheduleSuggestedPro
              order={order}
              selectedCategory={selectedCategory}
            />
          )}
          {selectedTab === "two" && (
            <SelectAvailablePro
              scheduledDate={locationSchedule?.scheduledDate}
              order={order}
              selectedCategory={selectedCategory}
              assignments={assignments}
            />
          )}
        </Col>
        <Col offset='1' span='7'>
          <div className='o-assignmentForm__selectedOverviewWrapper'>
            <div className='o-assignmentForm__selectedOverviewItems'>
              <h3>jobs selected</h3>
              {selectedJobsArray.map((job, index) => {
                return (
                  <div
                    key={job.id}
                    className='m-assignmentForm__selectedOverviewItem'
                  >
                    <p>{job.custom?.name || job.task?.name}</p>
                  </div>
                );
              })}
            </div>
            <div className='o-assignmentForm__selectedOverviewActions'>
              <Button
                disabled={!data.tradesman || !selectedJobsArray.length}
                fullWidth
                loading={submitAssignmentResult.loading}
                variant='success'
                onClick={() => {
                  submitAssignment({
                    variables: {
                      data: {
                        date: data.date,
                        jobIds: selectedJobsArray.map(({ id }) => id),
                        orderId: data.orderId,
                        tradesmanId: data.tradesman?.id,
                      },
                    },
                  });
                }}
              >
                Assign To{" "}
                {(data.tradesman
                  ? `${data.tradesman.firstName} ${data.tradesman.lastName}`
                  : undefined) || "Tradesman"}
              </Button>
              <Button
                fullWidth
                variant='negative'
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel Assignment
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Island>
  );
};
