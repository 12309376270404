import React, { FC, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Icon,
  Grid,
  Message,
} from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import { ADD_TRADESMAN_POINT } from "../../../graphql/query";

const { Field } = Form;

interface EditPointProp {
  userId: string;
  rewardpoint: number;
  refresh: () => void;
}

export const AddPointModal: FC<EditPointProp> = ({
  userId,
  rewardpoint,
  refresh,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState("");
  const [success, setSuccess] = useState<Boolean>(false);
  const [error, setError] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [valueError, setValueError] = useState<boolean>(false);

  const [editForm, { loading }] = useMutation(ADD_TRADESMAN_POINT);

  const clearForm = () => {
    setValue("");
  };

  const close = () => {
    setOpen(false);
    setSuccess(false);
  };
  const clearMessage = () => {
    setSuccess(false);
    setError(false);
  };

  useEffect(() => {
    if (success) {
      setOpen(false);
      setSuccess(false);
      refresh();
    }
  }, [success, refresh]);

  return (
    <Modal
      className="o-admin__edit-modal"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="mini"
      trigger={
        <Button
          style={{ background: "#003952", color: "#ffffff" }}
          icon
          labelPosition="right"
          size="small"
          onClick={() => {
            setOpen(true);
          }}
          {...rest}
        >
          <Icon name="pencil alternate"></Icon>
          Add Point
        </Button>
      }
    >
      <Modal.Header>Add/Remove point</Modal.Header>
      <Modal.Content>
        <Grid centered>
          <Grid.Column width="16">
            {error && <Message error header="Error" content={errorMessage} />}
            {success && (
              <Message
                success
                header="Update was successful"
                content="Reward points has been updated"
              />
            )}
            <Form>
              <h2 style={{ fontSize: 18, fontWeight: 700 }}>
                Current Point: {rewardpoint}
              </h2>
              <Field>
                {valueError && (
                  <p style={{ color: "red" }}>
                    Can't remove more than the current point
                  </p>
                )}
                <Input
                  placeholder="Enter value"
                  type="text"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    clearMessage();
                  }}
                  onFocus={() => {
                    setValueError(false);
                  }}
                />
                <small style={{ fontSize: "100%" }}>
                  Add a negative symbol to remove point e.g (-10)
                </small>
              </Field>
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          inverted
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
        {loading ? (
          <Button loading primary>
            Loading
          </Button>
        ) : (
          <Button
            {...(valueError && { disabled: true })}
            color="blue"
            onClick={(e) => {
              e.preventDefault();

              editForm({
                variables: {
                  data: {
                    tradesmanId: userId,
                    point: parseInt(value),
                  },
                },
              })
                .then((res) => {
                  res.data.addRewardPointChange.status === true
                    ? setSuccess(true)
                    : console.log();
                  clearForm();
                })
                .catch((err) => {
                  setError(true);
                  setErrorMessage(err.error);
                });
            }}
          >
            Save
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
