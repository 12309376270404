import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Modal,
  Icon,
  Grid,
  Header,
  Message,
} from "semantic-ui-react";
import { ISubCatTasks } from "./../types";
import {
  setDTaskId,
  setDName,
  setDDescription,
  setDImage,
  setDEstimatedCost,
  setDStatus,
  selectSubmissionStatus,
  selectSuccessMessage,
  selectErrorMessage,
  editTaskForm,
  setSuccessMessage,
  setErrorMessage,
} from "../../../redux/editTask.slice";
import { CatServiceSelectors } from "./../../../redux/category-service.slice";
import { useMutation } from "@apollo/client";
import { EDIT_TASK } from "./../../../graphql/query";
import { IFileName } from "./../../category/types";
import { useSelector, useDispatch } from "react-redux";

const { Field } = Form;

export const EditTaskModal: FC<{
  taskId: string;
}> = ({ taskId, ...rest }) => {
  const { selectTasks } = CatServiceSelectors;

  const allSubTasks: ISubCatTasks = useSelector(selectTasks);
  const submissionStatus = useSelector(selectSubmissionStatus);
  const successMessage = useSelector(selectSuccessMessage);
  const formErrorMessage = useSelector(selectErrorMessage);

  const allTasks = Object.entries(allSubTasks);
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState("");
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [img, setImg] = useState("");
  const [uploadProgress, setUploadProgress] = useState("0%");

  const [editForm] = useMutation(EDIT_TASK);

  const dispatch = useDispatch();

  const loadUpdata = () => {
    if (allTasks) {
      const task = allTasks.filter(([id, rest]) => {
        return id === taskId;
      });
      const name = task.map(([id, data]) => {
        return data.name;
      });
      const description = task.map(([id, data]) => {
        return data.description;
      });
      const estimatedCost = task.map(([id, data]) => {
        return data.estimatedCost;
      });
      const image = task.map(([id, data]) => {
        return data.imageUrl;
      });
      setName(name[0]);
      setDescription(description[0]);
      setEstimatedCost(estimatedCost[0]);
      dispatch(setDName(name[0]));
      dispatch(setDDescription(description[0]));
      dispatch(setDEstimatedCost(estimatedCost[0]));
      dispatch(setDImage(image[0]));
      dispatch(setDTaskId(taskId));
      dispatch(setErrorMessage(""));
      dispatch(setSuccessMessage(""));
    }
  };

  useEffect(() => {
    if (successMessage !== "") {
      setTimeout(() => {
        setOpen(false);
        window.location.reload();
      }, 1000);
    }
  }, [successMessage]);

  const close = () => {
    setName("");
    setImg("");
    setOpen(false);
  };

  const clearMessage = () => {
    dispatch(setErrorMessage(""));
    dispatch(setSuccessMessage(""));
  };

  const uploadHandler = async () => {
    const fileData = new FormData();
    const image_file: any = img;
    const trimFileName = (file: IFileName) =>
      file.name.replace(new RegExp(/[^0-9a-zA-Z.]/g), "_");
    fileData.append("file", image_file, trimFileName(image_file));
    fileData.append("upload_preset", "ilq50gln");
    const APIURL = "https://api.cloudinary.com/v1_1/laborhack/image/upload";
    await axios
      .post(APIURL, fileData, {
        onUploadProgress: (ProgressEvent) => {
          setUploadProgress(
            `${Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)}%`
          );
        },
      })
      .then((res) => {
        const image_url = res.data.secure_url;
        dispatch(setDStatus("pending"));
        dispatch(setDImage(image_url));
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="mini"
      trigger={
        <Button
          color="blue"
          icon
          labelPosition="right"
          size="tiny"
          onClick={() => {
            setOpen(true);
            loadUpdata();
          }}
          {...rest}
        >
          <Icon name="pencil alternate"></Icon>
          Edit
        </Button>
      }
    >
      <Modal.Header>Edit task</Modal.Header>
      <Modal.Content>
        <Grid centered>
          <Grid.Column width="16">
            {formErrorMessage !== "" && (
              <Message error header="Error" content={formErrorMessage} />
            )}
            {successMessage !== "" && (
              <Message
                success
                header="Update Successful"
                content={successMessage}
              />
            )}

            <Form>
              <Field>
                <Header as="h3">Edit {name}</Header>

                <label>Name</label>
                <Input
                  placeholder="Enter new name"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);

                    clearMessage();
                  }}
                  onBlur={() => {
                    dispatch(setDName(name));
                  }}
                />
                <label>Description</label>
                <Input
                  placeholder="Enter new name"
                  type="text"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    clearMessage();
                  }}
                  onBlur={() => {
                    dispatch(setDDescription(description));
                  }}
                />
                <label>Estimated Cost</label>
                <Input
                  placeholder="Enter new name"
                  type="text"
                  value={estimatedCost}
                  onChange={(e) => {
                    setEstimatedCost(parseInt(e.target.value));
                    clearMessage();
                  }}
                  onBlur={() => {
                    dispatch(setDEstimatedCost(estimatedCost));
                  }}
                />
              </Field>
              <div>
                <Form.Input label="Upload Image (400px by 400px)">
                  <input
                    width={5}
                    type="file"
                    name="image"
                    id=""
                    onChange={(e) => {
                      const [file]: any = e.target.files;
                      setImg(file);
                      clearMessage();
                    }}
                  />
                </Form.Input>
              </div>
              <div className="o-category__form-img-details">
                <p>Uploading: {uploadProgress}</p>
              </div>
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          inverted
          onClick={() => {
            close();
          }}
        >
          No
        </Button>

        {submissionStatus === "idle" ? (
          <Button
            primary
            onClick={(e) => {
              if (img === "") {
                dispatch(editTaskForm({ mutation: editForm }));
              } else {
                uploadHandler().then(() => {
                  dispatch(editTaskForm({ mutation: editForm }));
                });
              }
            }}
          >
            Submit
          </Button>
        ) : (
          <Button loading primary>
            Loading
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

// editForm({
//     variables: {
//       input: {
//         name: name,
//         description: description,
//         estimatedCost: estimatedCost,
//         taskId: taskId,
//         imageUrl: imageUrl,
//       },
//     },
//   })
//     .then((res) => {
//       setSuccess(true);
//     })
//     .catch((err) => {
//       setError(true);
//       setErrorMessage(err.error);
//     });
