import React, { FC } from 'react';

import styles from './styles.module.scss';

export interface BasicInformationProps {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
}

export const BasicInformation: FC<BasicInformationProps> = ({
  firstName,
  lastName,
  emailAddress,
  phoneNumber,
}) => {
  return (
    <div className={styles.wrapper}>
      <h3>Basic Information</h3>
      <div className={styles.information}>
        <div className={styles.field}>
          <h5>First Name</h5>
          <p>{firstName}</p>
        </div>
        <div className={styles.field}>
          <h5>Last Name</h5>
          <p>{lastName}</p>
        </div>
        <div className={styles.field}>
          <h5>Email Address</h5>
          <p>{emailAddress}</p>
        </div>
        <div className={styles.field}>
          <h5>Phone Number</h5>
          <p>{phoneNumber}</p>
        </div>
      </div>
    </div>
  );
};
