import { gql } from "@apollo/client";

export const GET_PRO_TRADE_REQUIREMENT = gql`
  query GetProTradeRequirement($id: String!) {
    proTradeRequirement(id: $id) {
      id
      requirement {
        id
        name
      }
      results {
        attemptId
        submittedAt
        passed
        scores
      }
      processedAt
      pro {
        id
        firstName
        lastName
        imageUrl
        associatedLaborHackEmail
        identityVerification {
          identificationNumber
          type
          status
          dateOfBirth
          photo
          returnedPhoto
          identificationDocument
        }
        trades {
          id
          name
        }
      }
    }
  }
`;
