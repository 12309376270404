import { gql } from "@apollo/client";
import { TransferStatus } from "../../types";

export const getTransferBadgeColor = (status: TransferStatus) => {
  const handlers: Record<TransferStatus, string> = {
    [TransferStatus.CONFIRMED]: "green",
    [TransferStatus.FAILED]: "red",
    [TransferStatus.PENDING]: "yellow",
    [TransferStatus.AWAITING_APPROVAL]: "yellow",
    [TransferStatus.QUEUED]: "blue",
    [TransferStatus.REVERSED]: "red",
  };

  return handlers[status] || "gray";
};

export const GET_CONTRACT_PAYOUT = gql`
  query GetContractPayout($id: String!) {
    contractPayout(id: $id) {
      id
      breakdown {
        gross
        net
        tax
      }
      override {
        amount
        tax
      }
      worksheet {
        expectedUnitAmount
        unitAmount
        contract {
          paymentAmount
          paymentMethod
          pro {
            id
            userId
            firstName
            lastName
            email
            phoneNumber
          }
          recruitment {
            id
            client {
              firstName
              lastName
              email
              business {
                name
              }
            }
          }
        }
        payroll {
          period {
            end
            start
          }
        }
      }
      transfer {
        id
        transferCode
        status
      }
      createdAt
    }
  }
`;

export const GET_CONTRACT_PAYOUTS = gql`
  query GetContractPayouts($transferStatus: [TransferStatus!]) {
    contractPayouts(transferStatus: $transferStatus) {
      id
      breakdown {
        gross
        net
        tax
      }
      worksheet {
        expectedUnitAmount
        unitAmount
        contract {
          pro {
            firstName
            lastName
          }
          recruitment {
            id
            client {
              firstName
              lastName
              business {
                name
              }
            }
          }
        }
        payroll {
          period {
            end
            start
          }
        }
      }
      transfer {
        id
        status
      }
      createdAt
    }
  }
`;
