import React, { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Create from "./create";
import { Recruitments } from "./Recruitments";
import SingleRecruitment from "./single-recruitment";
import { Contracts } from "./single-recruitment/contracts";
import Quotations from "./single-recruitment/quotations";
import { RecruitmentJobView } from "./single-recruitment/shortlist-pros/RecruitmentJobView";
import ProRecruitmentContractPage from "./single-recruitment/pro-recruitment-contracts/ProRecruitmentContractPage";
import ViewPayroll from "./single-recruitment/recruitment-payroll/ViewPayroll";
import CreateInvoice from "./single-recruitment/recruitment-payroll/invoices/create";
import ManageInvoice from "./single-recruitment/recruitment-payroll/invoices/[id]";

const Recruitment: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/create`}>
        <Create />
      </Route>
      <Route path={`${path}/quotations`}>
        <Quotations />
      </Route>
      <Route path={`${path}/contracts`}>
        <Contracts />
      </Route>
      <Route exact path={`${path}/:id/requests/:requestId`}>
        <RecruitmentJobView />
      </Route>
      <Route exact path={`${path}/:id`}>
        <SingleRecruitment />
      </Route>
      <Route exact path={`${path}/pro-recruitment-contract/:id`}>
        <ProRecruitmentContractPage />
      </Route>
      <Route exact path={`${path}/:recruitmentId/payroll/:payrollId`}>
        <ViewPayroll />
      </Route>
      <Route
        exact
        path={`${path}/:recruitmentId/payroll/:payrollId/invoices/new`}
      >
        <CreateInvoice />
      </Route>
      <Route
        exact
        path={`${path}/:recruitmentId/payroll/:payrollId/invoices/:invoiceId`}
      >
        <ManageInvoice />
      </Route>
      <Route path={path}>
        <Recruitments />
      </Route>
    </Switch>
  );
};

export default Recruitment;
