import { useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import Calendar from "react-calendar";
import { Icon } from "semantic-ui-react";
import { GET_TRADESMAN_SCHEDULE } from "../graphql/query";
import { Tradesman } from "../types";

const SuggestedProCard: FC<{
  tradesman: Tradesman;
  onSelect: (date: Date) => void;
  selected?: boolean;
}> = ({ tradesman, onSelect, selected }) => {
  const [open, setOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState(new Date());

  const getTradesmanSchedule = useQuery(GET_TRADESMAN_SCHEDULE, {
    variables: {
      tradesmanId: tradesman.id,
    },
  });

  const unavailable = new Set(
    getTradesmanSchedule.data?.getTradesmanSchedule.data.map(
      (timestamp: string) => {
        const date = new Date(timestamp);

        return date.toLocaleDateString();
      }
    )
  );

  const { firstName, lastName, location } = tradesman;

  return (
    <div
      className={`o-suggestedProCard__wrapper ${selected ? "-selected" : ""}`}
    >
      <div className="o-suggestedProCard__overview">
        <div className="m-suggestedProCard__proDetails">
          <h5>{firstName + " " + lastName}</h5>
          <p>{location.location + ", " + location.city}</p>
        </div>
        <div
          className="a-suggestedProCard__toggle"
          onClick={() => {
            setOpen((value) => !value);
          }}
        >
          {open ? (
            <Icon name="chevron circle up" />
          ) : (
            <Icon name="chevron circle down" />
          )}
        </div>
      </div>
      {open && (
        <div className="o-suggestedProCard__actions">
          <div className="o-suggestedProCard__selectDate">
            <p>Select a day</p>
            <div className="a-suggestedProCard__calender">
              <Calendar
                minDate={new Date()}
                onChange={(value: any) => {
                  setDateSelected(value);
                }}
                tileClassName={({ activeStartDate, date, view }) => {
                  return view === "month" &&
                    unavailable.has(date.toLocaleDateString())
                    ? "a-suggestedProCard__calenderTile -unavailable"
                    : null;
                }}
                tileDisabled={({ activeStartDate, date, view }) => {
                  return (
                    view === "month" &&
                    unavailable.has(date.toLocaleDateString())
                  );
                }}
                value={dateSelected}
              />
            </div>
          </div>
          <button
            className={`a-suggestedProCard__button ${
              selected ? "-selected" : ""
            }`}
            onClick={() => {
              onSelect(dateSelected);
            }}
          >
            {selected ? "Remove" : "Add"}
          </button>
        </div>
      )}
    </div>
  );
};

export default SuggestedProCard;
