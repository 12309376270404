export const STRINGS = {
  WELCOME: "welcome back",
  EMAIL: "email",
  PASSWORD: "password",
  LOGIN: "login",
  FORGOT: "forgot password?",
  REMEMBER: "remember me",
  RESET: "reset password",
  RESET_EMAIL: "enter your registered email",
  HAVE_PASSWORD: "i know my password",
  RESET_SUCCESS: "Password reset link has been sent to your email",
};
