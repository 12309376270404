import Table, { ColumnsType } from "antd/lib/table";
import React from "react";
import { Job } from "../../../types";
import Badge from "../../../_components/Badge";
import { AssignmentTableRow } from "../types";

export const jobsTableRenderer = (
  record: AssignmentTableRow,
  moveTo: (path: string) => void
) => {
  const columns: ColumnsType<Job & { key: string }> = [
    {
      title: "Job Code",
      dataIndex: "jobCode",
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
      render: (text, record) => (
        <p className="a-orderDetail__nestedTableText">{record.jobCode}</p>
      ),
      width: "14%",
    },
    {
      title: "Task Name",
      dataIndex: "taskName",
      render: (text, record) => (
        <p className="a-orderDetail__nestedTableText">
          {record.custom?.name || record.task?.name || ""}
        </p>
      ),
      width: "36%",
    },
    {
      title: "Type",
      render: (text, record) => (
        <Badge
          size="small"
          inverted
          type={record.custom ? "progress" : "basic"}
          text={record.custom ? "custom" : "default"}
        ></Badge>
      ),
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <p className="a-orderDetail__nestedTableText">{record.status}</p>
      ),
      width: "10%",
    },
    {
      title: "",
      dataIndex: "",
      render: (text, record) => (
        <button
          className="a-orderDetail__link"
          onClick={() => {
            moveTo(`/orders/job/${record.jobCode}`);
          }}
        >
          view
        </button>
      ),
      width: "25%",
    },
  ];

  return (
    <Table
      className="o-orderDetail__nestedTable"
      pagination={false}
      columns={columns}
      dataSource={record.jobs.map((job) => {
        return {
          key: job.id,
          ...job,
        };
      })}
    />
  );
};
