import React, { FC, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Icon, Modal } from "semantic-ui-react";
import Button from "@laborhack/custom-button";
import { CatServiceSelectors } from "../../../../../../../../../redux/store";
import { ContractQuotationItem } from "../../../../../../../types";
import { SingleQuotationContext } from "../../../../context/single-quotation.context";
import { BreakdownItem } from "./BreakdownItem";
import { SplitItem } from "./SplitItem";

import styles from "./styles.module.scss";

const { selectCategories } = CatServiceSelectors;

export interface BreakdownProps {}

export const Breakdown: FC<BreakdownProps> = ({}) => {
  const categories = useSelector(selectCategories);

  const { items, editMode, undoStack, redoStack, undo, redo } = useContext(
    SingleQuotationContext
  );

  const [splitItemId, setSplitItemId] = React.useState<string>();
  const [splitItemModalOpen, setSplitItemModalOpen] = React.useState<boolean>(
    false
  );

  const groupedItems = useMemo(() => {
    const groupedItems: Record<
      string,
      {
        items: (ContractQuotationItem & { id: string })[];
        contractDetailsText: string;
        category: string;
      }
    > = {};

    Object.entries(items).forEach(([id, item]) => {
      const key = `${item.categoryId}-${item.contractDetails.interval}-${item.contractDetails.length}`;

      if (!groupedItems[key]) {
        groupedItems[key] = {
          items: [],
          contractDetailsText: `${
            item.contractDetails.length
          } ${item.contractDetails.interval.toLowerCase()}`,
          category: categories[item.categoryId].name,
        };
      }

      groupedItems[key].items.push({ ...item, id });
    });

    return groupedItems;
  }, [items]);

  const handleSplitItem = (id: string) => {
    setSplitItemId(id);
    setSplitItemModalOpen(true);
  };

  const handleCloseSplitItemModal = () => {
    setSplitItemId(undefined);
    setSplitItemModalOpen(false);
  };

  return (
    <>
      <Modal
        open={splitItemModalOpen}
        size='tiny'
        onClose={handleCloseSplitItemModal}
        closeOnDimmerClick
        closeIcon
      >
        {splitItemId && (
          <SplitItem itemId={splitItemId} onSplit={handleCloseSplitItemModal} />
        )}
      </Modal>
      <div className={styles.breakdown}>
        {editMode && (
          <div className={styles.actions}>
            <Button type='link' disabled={!undoStack.length} onClick={undo}>
              <Icon name='undo' />
              Undo {!!undoStack.length &&
                undoStack[undoStack.length - 1].type}{" "}
              change
            </Button>
            <Button type='link' disabled={!redoStack.length} onClick={redo}>
              <Icon name='redo' />
              Redo {!!redoStack.length &&
                redoStack[redoStack.length - 1].type}{" "}
              change
            </Button>
          </div>
        )}
        {Object.entries(groupedItems).map(
          ([key, { items, category, contractDetailsText }]) => {
            return (
              <div className={styles.section} key={key}>
                <h4>
                  {`${category} - `}
                  <span className='italic text-primary-300'>
                    {contractDetailsText}
                  </span>
                </h4>
                {items.map((item) => (
                  <BreakdownItem
                    key={item.id}
                    onSplitItem={() => handleSplitItem(item.id)}
                    {...item}
                  />
                ))}
              </div>
            );
          }
        )}
      </div>
    </>
  );
};
