import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { types } from "../service";
import { ApolloClient } from "@apollo/client";
import { GET_ALL_CAT_SERVICES } from "../graphql/query";

const initialState: types.ICategoriesServiceState = {
  allCategories: {},
  allSubCategories: {},
  allServiceTasks: {},
  status: "idle",
  error: null,
};

export const fetchServices = createAsyncThunk(
  "categorySlice/fetchedServices",
  async ({ client }: { client: ApolloClient<object> }, { dispatch }) => {
    const { data } = await client.query({
      query: GET_ALL_CAT_SERVICES,
    });

    return data.getAllServices;
  }
);

const catServiceSlice = createSlice({
  name: "categoryService",
  initialState,

  reducers: {
    //
  },
  extraReducers: {
    [fetchServices.pending.type]: (state) => {
      state.status = "loading";
    },
    [fetchServices.rejected.type]: (state) => {
      state.status = "failed";
    },
    [fetchServices.fulfilled.type]: (state, action) => {
      const data: types.IGetAllServicesResult = action.payload;
      // changing data structure to optimize for reading load
      const allCategories: types.IGenericServiceObject<types.IServiceCategory> = {};

      const allSubCategories: types.IGenericServiceObject<types.IServiceSubCategory> = {};

      const allServiceTasks: types.IGenericServiceObject<types.IServiceTask> = {};

      data.allCategories.forEach(({ id, ...rest }) => {
        allCategories[id] = rest;
      });

      data.allSubCategories.forEach(({ id, ...rest }) => {
        allSubCategories[id] = rest;
      });
      data.allServiceTasks.forEach(({ id, ...rest }) => {
        allServiceTasks[id] = rest;
      });

      state.allCategories = allCategories;
      state.allSubCategories = allSubCategories;
      state.allServiceTasks = allServiceTasks;
      state.status = "idle";
    },
  },
});

const selectCategories = (state: {
  categoryService: types.ICategoriesServiceState;
}) => state.categoryService.allCategories;

const selectSubCategories = (state: {
  categoryService: types.ICategoriesServiceState;
}) => state.categoryService.allSubCategories;
const selectTasks = (state: {
  categoryService: types.ICategoriesServiceState;
}) => state.categoryService.allServiceTasks;
export const CatServiceSelectors = {
  selectCategories,
  selectSubCategories,
  selectTasks,
};
export const CatServiceReducer = catServiceSlice.reducer;
export const CatServiceActions = { fetchServices, ...catServiceSlice.actions };
