import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Modal,
  Icon,
  Grid,
  Header,
  Message,
} from "semantic-ui-react";
import {
  setDSubCategoryId,
  setDName,
  setDDescription,
  setDImage,
  setDStatus,
  selectSuccessMessage,
  selectErrorMessage,
  selectSubmissionStatus,
  editSubCategoryForm,
  setErrorMessage,
  setSuccessMessage,
} from "../../../redux/editSubcategory.slice";
import { ISubCategories } from "./../types";
import { CatServiceSelectors } from "./../../../redux/category-service.slice";
import { useMutation } from "@apollo/client";
import { EDIT_SUBCATEGORY } from "./../../../graphql/query";
import { IFileName } from "./../../category/types";
import { useSelector, useDispatch } from "react-redux";

const { Field } = Form;

export const EditSubCategoryModal: FC<{
  subcategoryId: string;
}> = ({ subcategoryId, ...rest }) => {
  const { selectSubCategories } = CatServiceSelectors;

  const allSubcategory: ISubCategories = useSelector(selectSubCategories);
  const submissionStatus = useSelector(selectSubmissionStatus);
  const successMessage = useSelector(selectSuccessMessage);
  const formErrorMessage = useSelector(selectErrorMessage);

  const allSubcategories = Object.entries(allSubcategory);
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState<string>("");
  const [uploadProgress, setUploadProgress] = useState("0%");

  const [editForm] = useMutation(EDIT_SUBCATEGORY);

  const dispatch = useDispatch();

  const loadData = () => {
    if (allSubcategories) {
      const subcategory = allSubcategories.filter(([id, rest]) => {
        return id === subcategoryId;
      });
      const name = subcategory.map(([id, data]) => {
        return data.name;
      });
      const description = subcategory.map(([id, data]) => {
        return data.description;
      });
      const image = subcategory.map(([id, data]) => {
        return data.imageUrl;
      });

      setName(name[0]);
      setDescription(description[0]);
      dispatch(setDSubCategoryId(subcategoryId));
      dispatch(setDName(name[0]));
      dispatch(setDDescription(description[0]));
      dispatch(setDImage(image[0]));
      dispatch(setErrorMessage(""));
      dispatch(setSuccessMessage(""));
    }
  };

  const clearMessage = () => {
    dispatch(setErrorMessage(""));
    dispatch(setSuccessMessage(""));
  };

  useEffect(() => {
    if (successMessage !== "") {
      setTimeout(() => {
        setOpen(false);
        window.location.reload();
      }, 1000);
    }
  }, [successMessage]);

  const close = () => {
    setName("");
    setImg("");
    setOpen(false);
  };

  const uploadHandler = async () => {
    const fileData = new FormData();
    const image_file: any = img;
    const trimFileName = (file: IFileName) =>
      file.name.replace(new RegExp(/[^0-9a-zA-Z.]/g), "_");
    fileData.append("file", image_file, trimFileName(image_file));
    fileData.append("upload_preset", "ilq50gln");
    const APIURL = "https://api.cloudinary.com/v1_1/laborhack/image/upload";
    await axios
      .post(APIURL, fileData, {
        onUploadProgress: (ProgressEvent) => {
          setUploadProgress(
            `${Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)}%`
          );
        },
      })
      .then((res) => {
        const image_url = res.data.secure_url;
        dispatch(setDStatus("pending"));
        dispatch(setDImage(image_url));
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="mini"
      trigger={
        <Button
          color="blue"
          icon
          labelPosition="right"
          size="tiny"
          onClick={() => {
            setOpen(true);
            loadData();
          }}
          {...rest}
        >
          <Icon name="pencil alternate"></Icon>
          Edit
        </Button>
      }
    >
      <Modal.Header>Edit SubCategory</Modal.Header>
      <Modal.Content>
        <Grid centered>
          <Grid.Column width="16">
            {formErrorMessage !== "" && (
              <Message error header="Error" content={formErrorMessage} />
            )}
            {successMessage !== "" && (
              <Message
                success
                header="Update Successful"
                content={successMessage}
              />
            )}
            <Form>
              <Field>
                <Header as="h3">Edit Data</Header>

                <label>Name</label>
                <Input
                  placeholder="Enter new name"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onFocus={() => {
                    clearMessage();
                  }}
                  onBlur={() => {
                    dispatch(setDName(name));
                  }}
                />
                <label>Description</label>
                <Input
                  placeholder="Enter new name"
                  type="text"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    clearMessage();
                  }}
                  onBlur={() => {
                    dispatch(setDDescription(description));
                  }}
                />
              </Field>
              <div>
                <Form.Input label="Upload Image (400px by 280px)">
                  <input
                    width={5}
                    type="file"
                    name="image"
                    id=""
                    onChange={(e) => {
                      const [file]: any = e.target.files;
                      setImg(file);
                      clearMessage();
                    }}
                  />
                </Form.Input>
              </div>
              <div className="o-category__form-img-details">
                <p>Uploading: {uploadProgress}</p>
              </div>
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          inverted
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>
        {submissionStatus === "idle" ? (
          <Button
            primary
            onClick={(e) => {
              if (img === "") {
                dispatch(editSubCategoryForm({ mutation: editForm }));
              } else {
                uploadHandler().then(() => {
                  dispatch(editSubCategoryForm({ mutation: editForm }));
                });
              }
            }}
          >
            Submit
          </Button>
        ) : (
          <Button loading primary>
            Loading
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
