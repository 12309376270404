import { ApolloClient, ApolloError } from "@apollo/client";
import Joi from "@hapi/joi";
import { validate as isValidEmail } from "email-validator";
import {
  GET_BUSINESS_BY_EMAIL,
  GET_USER_BY_EMAIL,
  GET_USER_BY_PHONENUMBER,
} from "../graphql/query";
import {
  NonBillableDate,
  RecruitmentRequest,
  RecruitmentType,
} from "../pages/recruitments/types";
import {
  Guarantor,
  IInvoice,
  InvoiceOptions,
  Job,
  Referee,
  RelayStylePaginatedResponse,
  TransactionStatus,
} from "../types";
import { addMonths, addQuarters, addYears } from "date-fns";

export const extractItemsFromRelayStylePaginationResponse = <T>(
  data: RelayStylePaginatedResponse<T>
) => {
  return data.edges.map((edge) => edge.node);
};

export const formatAsNaira = (amount: number) => {
  return (
    "NGN " +
    amount.toLocaleString("en-NG", {
      minimumFractionDigits: 2,
    })
  );
};

export const formatRelativeTime = (timestamp: number) => {
  const currentTimestamp = new Date().getTime();

  const timeDifference = currentTimestamp - timestamp;

  const timeBoxes = [
    ["week", 604800000],
    ["day", 86400000],
    ["hr", 3600000],
    ["min", 60000],
    ["sec", 1000],
  ];

  const result = {
    relative: {
      greater: true,
      lesser: true,
    },
    timebox: ["sec", 0],
    count: 0,
    multiple: false,
  };

  if (timeDifference > timeBoxes[0][1]) {
    result.timebox = timeBoxes[0];
  } else {
    if (timeDifference > timeBoxes[1][1]) {
      result.timebox = timeBoxes[1];
    } else {
      if (timeDifference > timeBoxes[2][1]) {
        result.timebox = timeBoxes[2];
      } else {
        if (timeDifference > timeBoxes[3][1]) {
          result.timebox = timeBoxes[3];
        } else {
          result.timebox = timeBoxes[4];
        }
      }
    }
  }

  const count = timeDifference / Number(result.timebox[1]);
  const actualCount = Math.round(count);

  result.count = actualCount;

  if (actualCount >= count) {
    result.relative.lesser = false;
  } else {
    result.relative.greater = false;
  }

  if (actualCount > 1) {
    result.multiple = true;
  }

  return `${result.count} ${result.timebox[0]}${
    result.multiple ? "s" : ""
  } ago`;
};

// Invoice Calculations
export const calculateMaterialsAndLabor = (jobs: Job[]) => {
  let labor = 0;

  const materials = jobs.reduce((acc, { invoiceDetails }) => {
    if (!invoiceDetails) {
      return acc;
    }

    const materialsCost =
      invoiceDetails.materials?.reduce((acc, { unitPrice, quantity }) => {
        return acc + unitPrice * quantity;
      }, 0) || 0;

    labor += invoiceDetails.labor || 0;

    return acc + materialsCost;
  }, 0);

  return {
    materials,
    labor,
  };
};

export const calculateLaborHackFee = (
  materials: number,
  labor: number,
  invoiceOptions?: InvoiceOptions
) => {
  let materialCommission = materials * 0.1;

  const zeroCommissionCap =
    invoiceOptions?.materialCommissionCap === 0 ? true : false;

  if (!zeroCommissionCap) {
    if (!process.env.REACT_APP_MAX_MATERIAL_COMMISSION)
      throw new Error("MAX_MATERIAL_COMMISSION is not set in environment");

    const materialCommissionCap =
      invoiceOptions?.materialCommissionCap ||
      Number(process.env.REACT_APP_MAX_MATERIAL_COMMISSION);

    if (materialCommission > materialCommissionCap) {
      materialCommission = materialCommissionCap;
    }
  }

  const laborCommission = labor * 0.1;

  const commission = laborCommission + materialCommission;

  const tax = commission * Number(process.env.REACT_APP_VALUE_ADDED_TAX);

  return labor + commission + tax;
};

export const calculateTotal = (materials: number, laborHackFee: number) => {
  return materials + laborHackFee;
};

export const invoiceHasAtLeastOnePayment = (invoice: IInvoice) => {
  const { payment, splitPayments } = invoice;

  return (
    payment?.status === TransactionStatus.SUCCESS ||
    !!splitPayments?.find(
      ({ payment }) => payment?.status === TransactionStatus.SUCCESS
    )
  );
};

export const getPendingPayment = (invoice: IInvoice) => {
  const { payment, splitPayments } = invoice;

  const allPayments = [
    payment,
    ...(splitPayments?.map(({ payment }) => payment) || []),
  ];

  return allPayments.find(
    (payment) => payment?.status === TransactionStatus.PENDING
  );
};

export const invoiceIsPaid = (invoice: IInvoice) => {
  const { payment, splitPayments } = invoice;

  return (
    (splitPayments?.length &&
      !splitPayments.find(
        ({ payment }) => payment?.status !== TransactionStatus.SUCCESS
      )) ||
    (!splitPayments?.length && payment?.status === TransactionStatus.SUCCESS)
  );
};

export const isAuthorized = (
  authorizedRoles: { [key: string]: boolean },
  userRoles: string[] | undefined
) => {
  return !!userRoles?.find((role) => authorizedRoles[role]);
};

export const truncateString = (value: string, maxLength: number) => {
  return `${value.substr(0, maxLength)}${
    value.length > maxLength ? "..." : ""
  }`;
};

export const nonEmptyString = (value: string) => {
  return !!value.length;
};

const getDatesBetween = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const getAllNonBillableDates = (
  nonBillableDates?: NonBillableDate[]
): Date[] => {
  if (!nonBillableDates) {
    return [];
  }

  return nonBillableDates.flatMap((nonBillableDate) => {
    const { startDate, endDate } = nonBillableDate;

    if (endDate) {
      return getDatesBetween(startDate, endDate);
    }

    return [startDate];
  });
};

export const parseNonBillableDate = (
  nonBillableDate: string
): { dates: Date[]; isDateRange: boolean } => {
  const dates = nonBillableDate.split("--").map((date) => new Date(date));

  return {
    dates,
    isDateRange: dates.length === 2,
  };
};

export const formatNonBillableDate = (nonBillableDate: NonBillableDate) => {
  const { startDate, endDate } = nonBillableDate;

  if (endDate) {
    return `${startDate.toISOString()}--${endDate.toISOString()}`;
  }

  return startDate.toISOString();
};

/**
 * Positive timezones make the ISO date the day before the actual date.
 *
 * @param date
 * @returns
 */
export const forceActualDay = (date: Date) => {
  const oneIndexedMonth = date.getMonth() + 1;

  const month = oneIndexedMonth < 10 ? `0${oneIndexedMonth}` : oneIndexedMonth;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return new Date(`${date.getFullYear()}-${month}-${day}`);
};

export const startsWithZero = (value: string): boolean => {
  return value[0] === "0";
};

export const addPhoneNumberPrefix = (phoneNumber: string) => {
  return (
    "+234" +
    (startsWithZero(phoneNumber) ? phoneNumber.substring(1) : phoneNumber)
  );
};

export const emailDoesNotExist = async (
  client: ApolloClient<any>,
  email: string
) => {
  try {
    await client.query({
      query: GET_USER_BY_EMAIL,
      variables: {
        email,
      },
    });

    return false;
  } catch (error) {
    return true;
  }
};

export const businessEmailDoesNotExist = async (
  client: ApolloClient<any>,
  email: string
) => {
  try {
    await client.query({
      query: GET_BUSINESS_BY_EMAIL,
      variables: {
        email,
      },
    });

    return false;
  } catch (error) {
    console.log(error);
    const { message } = error as ApolloError;

    if (message === "Business not found") return true;

    throw error;
  }
};

export const phoneNumberDoesNotExist = async (
  client: ApolloClient<any>,
  phoneNumber: string
) => {
  try {
    await client.query({
      query: GET_USER_BY_PHONENUMBER,
      variables: {
        phoneNumber: addPhoneNumberPrefix(phoneNumber),
      },
    });

    return false;
  } catch (error: any) {
    if (error.message.includes("no user record")) return true;
    throw error;
  }
};

export const phoneNumberCheck = (value: string) => {
  const pattern = startsWithZero(value)
    ? new RegExp(/^0(70|(80|81)|(90|91))\d{8}$/)
    : new RegExp(/^(70|(80|81)|(90|91))\d{8}$/);

  return !Joi.string().pattern(pattern).validate(value).error;
};

export const emailCheck = (value: string) => {
  return !Joi.string()
    .email({
      minDomainSegments: 2,
      tlds: { allow: false },
    })
    .validate(value).error;
};

export const isValidPhoneNumber = (phoneNumberString?: string) => {
  let cleaned = phoneNumberString?.trim();

  // remove phone number prefix
  cleaned = cleaned?.replace(/^\+234/, "");

  // remove braces, dashes and spaces
  cleaned = cleaned?.replace(/-|\s|\(|\)/g, "");

  const invalidPrefix = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
  ].some((prefix) => cleaned?.startsWith(prefix));

  if (invalidPrefix) {
    return false;
  }

  if (cleaned?.length === 10 && !cleaned.startsWith("0")) {
    return true;
  }

  if (cleaned?.length === 11 && cleaned.startsWith("0")) {
    return true;
  }

  return false;
};

export const isValidRefereeOrGuarantor = (person: Referee | Guarantor) => {
  return !!(
    person.name &&
    isValidPhoneNumber(person.phoneNumber) &&
    isValidEmail(person.email) &&
    person.companyName &&
    person.companyAddress &&
    person.jobTitle &&
    person.relationship
  );
};

export const calculateRecruitmentRequestsEndDates = (
  requests: RecruitmentRequest[],
  recruitmentType: RecruitmentType
) => {
  return requests.map((request) => {
    let endDate = new Date(request.proposedStartDate);
    if (recruitmentType !== RecruitmentType.CONTRACT) {
      // Set endDate to 120 years from start Date to allow for long term.
      // Full time length is like a very long term contract since the end date is not provided.
      endDate.setFullYear(endDate.getFullYear() + 120);
      return endDate.toISOString();
    }
    const { length, interval } = request.contractDetails;

    switch (interval) {
      case "DAYS":
        endDate.setDate(endDate.getDate() + length);
        break;
      case "WEEKS":
        endDate.setDate(endDate.getDate() + length * 7);
        break;
      case "MONTHS":
        endDate.setMonth(endDate.getMonth() + length);
        break;
      default:
        // Handle unexpected interval case
        return null;
    }

    return endDate.toISOString();
  });
};

export const calculateSubscriptionEndDate = (
  startDate: Date,
  baseInterval: string
) => {
  let intervalEndDate: Date;
  switch (baseInterval) {
    case "MONTHLY":
      intervalEndDate = addMonths(startDate, 1);
      break;
    case "QUARTERLY":
      intervalEndDate = addQuarters(startDate, 1);
      break;
    case "BIANNUALLY":
      intervalEndDate = addMonths(startDate, 6);
      break;
    case "YEARLY":
      intervalEndDate = addYears(startDate, 1);
      break;
    default:
      throw new Error("Invalid subscription interval");
  }
  return intervalEndDate;
};

export const generateRecruitmentRequestDropdownOptionsArray = (
  requests: RecruitmentRequest[],
  categoriesData: {
    categories: {
      id: string;
      proTitle?: string;
    }[];
  }
) => {
  if (!categoriesData || categoriesData.categories.length === 0) {
    return [];
  }

  const categoryMap: Record<string, string> = {};
  for (const category of categoriesData.categories) {
    categoryMap[category.id] = category.proTitle || "Unknown";
  }

  return requests.map((request) => {
    const categoryName = categoryMap[request.categoryId] || "Unknown";
    const interval = request.contractDetails.interval.toLowerCase();
    const duration = `${request.contractDetails.length} ${interval}(s)`;
    const startDate = new Date(request.proposedStartDate).toLocaleDateString(
      "en-NG",
      {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }
    );

    return `${request.proCount} ${categoryName}(s) - ${duration} - ${startDate}`;
  });
};

export const NairaInputHelpers = {
  format: (val: string) => `₦` + val,
  parse: (val: string) => val.replace(/^\₦/, ""),
};
