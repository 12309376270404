import { ContractPayout } from "../../features/contract-payouts/types";
import {
  Category,
  Client,
  IInvoice,
  Pro,
  RecruitmentJob,
  ScheduleConflict,
  Tradesman,
  WorkingDays,
} from "../../types";

export type ContractInterval = "MONTHS" | "WEEKS" | "DAYS";

export interface IAddedRecruitment {
  id: string;
  contractLength: number;
  contractInterval: ContractInterval;
  noOfPros: number;
  proLevel: number;
  categoryId: string;
  preferredStartDate: Date;
  userProfile: string;
  recruitmentType: string;
  location: string;
}

export enum RecruitmentRequestStatus {
  PENDING = "PENDING",
  QUOTATION_SENT = "QUOTATION_SENT",
  ASSIGNING = "ASSIGNING",
  FULFILLED = "FULFILLED",
}

export interface ContractDetails {
  length: number;
  interval: ContractInterval;
}

export interface RecruitmentRequest {
  id: string;
  /**
   * The number of professionals to recruit
   */

  proCount: number;

  /**
   * The level of the professionals to recruit
   */

  proLevel: number;

  categoryId: string;

  contractDetails: ContractDetails;

  proposedStartDate: Date;

  shortlistId?: string;

  matchingFeeInvoice?: IInvoice;
}

export enum QuotationStatus {
  NOT_SENT = "NOT_SENT",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
}

export interface ContractQuotationItem {
  isActive: boolean;

  categoryId: string;

  proCount: number;

  level: number;

  contractDetails: ContractDetails;

  workdays: number;

  requestedStartDate: Date;

  /**
   * Daily Rate
   */
  rate?: number;
}

export interface ContractQuotation {
  id: string;

  contractRecruitmentId: string;

  items: ContractQuotationItem[];

  status: QuotationStatus;

  createdAt: Date;
}

export interface ContractRecruitment {
  id: string;

  client: Client;

  requests: RecruitmentRequest[];

  activeQuotation?: ContractQuotation;

  quotations?: ContractQuotation[];

  paymentSettings?: {
    payBeforeService: boolean;
  };

  status: RecruitmentRequestStatus;

  recruitmentType: RecruitmentType;

  jobs?: RecruitmentJob[];

  proRecruitmentContracts: ProRecruitmentContract[];

  createdAt: Date;
}

export enum PaymentSchedule {
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum PaymentMethod {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  BY_OUTPUT = "BY_OUTPUT",
}

export interface CreateProRecruitmentContractInput {
  proId: string;

  recruitmentJobId: string;

  paymentMethod?: PaymentMethod;

  unitMeasured?: string;

  paymentAmount?: number;

  startDate?: Date;
}

export interface ContractPayoutReimbursement {
  id: string;
  payout: ContractPayout;
  invoice: IInvoice;
  createdAt: Date;
  updatedAt: Date;
}

export interface ContractWorksheet {
  id: string;
  contract: ProRecruitmentContract;
  payroll: RecruitmentPayroll;
  unitAmount: number;
  expectedUnitAmount: number;
  payout?: ContractPayout;
}

export interface ProRecruitmentContract {
  id: string;

  pro: Pro;

  recruitment: ContractRecruitment;

  category: Category;

  unitMeasured?: string;

  paymentMethod: PaymentMethod;

  paymentSchedule: PaymentSchedule;

  paymentAmount: number;

  status: string;

  workSheets: ContractWorksheet[];

  startDate: Date;

  endDate: Date;
}

export interface RecruitmentPayrollPeriod {
  start: Date;

  end: Date;
}

export interface RecruitmentPayroll {
  id: string;
  recruitment: ContractRecruitment;
  period: RecruitmentPayrollPeriod;
  worksheets: ContractWorksheet[];
}

export interface NonBillableDate {
  startDate: Date;
  /**
   * End date is optional
   *
   * If not provided, the date is assumed to be a single day
   */
  endDate?: Date;
}

export interface Contract {
  id: string;

  pro?: Tradesman;

  proId?: string;

  recruitment: ContractRecruitment;

  recruitmentId: string;

  proLevel: number; // requested pro level for this contract

  categoryId: string; // requested category for this contract

  agreedRate: number; // agreed daily rate for this contract

  startDate?: Date;

  endDate?: Date;

  requestedStartDate: Date;

  contractLength: number;

  contractInterval: ContractInterval;

  /**
   * Dates that do not count during payment
   *
   * This is a string array with entries representing a single date or a date range.
   * For example:
   * 2022-03-31T00:00:00.000Z--2022-04-01T00:00:00.000Z would represent the date range 31st of March to the 1st of April.
   *
   * This is to reduce storage space and to avoid having to store every single date in a range of 3 months for instance.
   */
  nonBillableDates?: string[];

  /**
   * Days that do not count during payment
   *
   */
  nonWorkingDays?: WorkingDays[];

  /**
   * Holds non-working day dates where the pro was called in
   */
  overtimeDates?: Date[];

  status: ContractStatus;

  terminatedAt?: Date;

  createdAt: Date;
}

export enum ContractStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
}

export interface Period {
  start: Date;
  end: Date;
}

export interface RecruitmentData {
  contract: Contract;
  period: Period;
  numberOfBilledDays: number;
  agreedDailyRate: number;
}

export interface ContractDatesInfo {
  contractStartDate: Date;
  contractEndDate: Date;
  nonBillableDates?: string[];
  nonWorkingDays?: WorkingDays[];
  overtimeDates?: Date[];
}

export interface ProposedContractInfo
  extends Omit<ContractDatesInfo, "contractEndDate"> {
  contractLength: number;
  contractInterval: ContractInterval;
}

export interface ScheduledAssignmentInfo {
  assignmentId: string;
  assignmentDate: Date;
}

export interface FilterClass {
  availability: boolean;
  level: "L4" | "L3" | "L2" | "L1";
}

export interface ProWithContractConflictInformation {
  id: string;
  pro: Tradesman;
  conflicts: ScheduleConflict[];
}

export interface Filter {
  class: keyof FilterClass;
  value: FilterClass[keyof FilterClass];
  name: string;
}

export interface LatLong {
  latitude: number;
  longitude: number;
}

export interface LocationData {
  coordinates?: LatLong;
  placeId: string;
  address: string;
  city: string;
  state: string;
}

export enum RecruitmentType {
  CONTRACT = "CONTRACT",
  FULL_TIME = "FULL_TIME",
}

export enum ClientProfile {
  BUSINESS = "BUSINESS",
  HOMEOWNER = "HOMEOWNER",
}
