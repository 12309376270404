import clsx from "clsx";
import React, { FC } from "react";

export interface AvatarProps {
  src?: string;
  className?: string;
}

export const Avatar: FC<AvatarProps> = ({ src, className, children }) => {
  return src ? (
    <img
      className={clsx(
        className,
        "rounded-full w-9 h-9 mr-1 border-2 border-solid border-white"
      )}
      src={src}
      alt=''
    />
  ) : (
    <div
      className={clsx(
        className,
        "rounded-full w-9 h-9 bg-primary-500 flex justify-center items-center mr-1 border-2 border-solid border-white"
      )}
    >
      <p className='m-0 p-0 text-base font-semibold text-white'>{children}</p>
    </div>
  );
};
