import {
  createSlice,
  createAsyncThunk,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import { ITradesmanRegisterState } from "./../components/tradesmen/types";

const initialState: ITradesmanRegisterState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  whatsappNumber: "",
  imageUrl:
    "https://res.cloudinary.com/laborhack/image/upload/v1600258815/laborhack/user-img_w2tloh.jpg",
  level: 0,
  categoryId: "",
  streetName: "",
  buildingNumber: "",
  area: "",
  location: "",
  city: "",
  landmark: "",
  accountNumber: "",
  bankCode: "",
  status: "idle",
  error_message: "",
  success_message: "",
};

export const SubmitTradsForm = createAsyncThunk(
  "register/createTradesman",
  async (
    data: { mutation: any },
    {
      dispatch,
      getState,
    }: { dispatch: ThunkDispatch<any, any, AnyAction>; getState: any }
  ) => {
    const { mutation } = data;
    const { registerTradesman }: any = getState();

    try {
      const { data } = await mutation({
        variables: {
          input: {
            firstName: registerTradesman.firstName,
            lastName: registerTradesman.lastName,
            imageUrl: registerTradesman.imageUrl,
            email: registerTradesman.email,
            phoneNumber: registerTradesman.phoneNumber,
            // whatsAppNumber: registerTradesman.whatsappNumber,
            location: {
              streetName: registerTradesman.streetName,
              buildingNumber: registerTradesman.buildingNumber,
              area: registerTradesman.area,
              city: registerTradesman.city,
              landmark: registerTradesman.landmark,
              location: registerTradesman.location,
            },
            categoryId: registerTradesman.categoryId,
            level: parseInt(registerTradesman.level),
            accountNumber: registerTradesman.accountNumber,
            bankCode: registerTradesman.bankCode,
          },
        },
      });
      if (data) {
        dispatch(setSuccessMessage(data.createTradesman));
      }
    } catch (error) {
      // dispatch error message
      dispatch(setErrorMessage((error as Error).message));
    }
  }
);

export const registerTradesmanSlice = createSlice({
  name: "registerTradesman",
  initialState,
  reducers: {
    setDFirstname: (state, action) => {
      state.firstName = action.payload;
    },
    setDlastName: (state, action) => {
      state.lastName = action.payload;
    },
    setDPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setDEmail: (state, action) => {
      state.email = action.payload;
    },
    setDLevel: (state, action) => {
      state.level = action.payload;
    },
    setImageUrl: (state, action) => {
      state.imageUrl = action.payload;
    },
    setWhatsAppNumber: (state, action) => {
      state.whatsappNumber = action.payload;
    },
    setDCategory: (state, action) => {
      state.categoryId = action.payload;
    },
    setDstreetName: (state, action) => {
      state.streetName = action.payload;
    },
    setDLocation: (state, action) => {
      state.location = action.payload;
    },
    setDbuildingNumber: (state, action) => {
      state.buildingNumber = action.payload;
    },
    setDCity: (state, action) => {
      state.city = action.payload;
    },
    setDArea: (state, action) => {
      state.area = action.payload;
    },
    setDLandMark: (state, action) => {
      state.landmark = action.payload;
    },
    setDAccountNumber: (state, action) => {
      state.accountNumber = action.payload;
    },
    setDBankCode: (state, action) => {
      state.bankCode = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.success_message = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error_message = action.payload;
    },
  },
  extraReducers: {
    [SubmitTradsForm.fulfilled.type]: (state, action) => {
      state.status = "idle";
    },
    [SubmitTradsForm.pending.type]: (state, action) => {
      state.status = "pending";
    },
  },
});

export const {
  setDFirstname,
  setDlastName,
  setDPhoneNumber,
  setDEmail,
  setDCategory,
  setImageUrl,
  setWhatsAppNumber,
  setDLevel,
  setDArea,
  setDCity,
  setDstreetName,
  setDbuildingNumber,
  setDLocation,
  setDLandMark,
  setDAccountNumber,
  setDBankCode,
  setSuccessMessage,
  setErrorMessage,
} = registerTradesmanSlice.actions;

export const TradesmanRegisterAction = registerTradesmanSlice.actions;

export const selectFirstName = (state: any) =>
  state.registerTradesman.firstName;
export const selectLastName = (state: any) => state.registerTradesman.lastName;
export const selectEmail = (state: any) => state.registerTradesman.email;
export const selectPhoneNumber = (state: any) =>
  state.registerTradesman.phoneNumber;
export const selectSuccessMessage = (state: any) =>
  state.registerTradesman.success_message;
export const selectErrorMessage = (state: any) =>
  state.registerTradesman.error_message;
export const selectSubmissionStatus = (state: any) =>
  state.registerTradesman.status;

export const RegisterTradsSelectors = {
  selectFirstName,
  selectLastName,
  selectSuccessMessage,
  selectErrorMessage,
  selectSubmissionStatus,
};

export const TradesmanRegReducer = registerTradesmanSlice.reducer;
