import { Select } from "antd";
import produce from "immer";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { Icon } from "semantic-ui-react";
import Button from "@laborhack/custom-button";
import { ProCertificationFilters } from "../../types";

const filterOptions = [
  {
    label: "Online Assessment Passed",
    value: "onlineAssessmentPassed",
  },
  {
    label: "Uploaded To ECITB",
    value: "uploaded",
  },
  {
    label: "Identity Verified",
    value: "identityVerified",
  },
];

export interface AddFilterProps {
  filters: ProCertificationFilters;
  setFilters: (filters: ProCertificationFilters) => void;
  close: () => void;
}

export const AddFilter: FC<AddFilterProps> = ({
  filters: existingFilters,
  setFilters: setExistingFilters,
  close,
}) => {
  /**
   * We are adding an array state to eep track of changes to the filters made in this component
   *
   * After the user has made changes to the filters, we will merge the changes with the existing filters
   *
   * The existing filters are passed down from the parent component as a map of key value pairs
   */

  const [filters, setFilters] = useState<
    {
      name?: keyof ProCertificationFilters;
      value?: boolean;
    }[]
  >(
    Object.entries(existingFilters).map(([name, value]) => ({
      name: name as keyof ProCertificationFilters,
      value: value as boolean,
    }))
  );

  const addFilter = () => {
    /**
     * This function will add a new filter to the filters array
     */

    setFilters((prev) => [...prev, {}]);
  };

  const removeFilter = (index: number) => {
    /**
     * This function will remove a filter from the filters array
     */

    setFilters((prev) => {
      return produce(prev, (draft) => {
        draft.splice(index, 1);
      });
    });
  };

  const saveFilters = () => {
    /**
     * This function will merge the changes made in this component with the existing filters
     */

    const newFilters: ProCertificationFilters = {};

    filters.forEach(({ name, value }) => {
      if (name && value !== undefined) {
        newFilters[name] = value;
      }
    });

    setExistingFilters(newFilters);

    close();
  };

  const disableAddFilter = () => {
    /**
     * This function will check if the user can add a new filter
     *
     * The user can only add a new filter if there is at least one filter that is not selected
     */

    return (
      filterOptions.filter(
        (option) =>
          filters.findIndex((filter) => filter.name === option.value) === -1
      ).length === 0
    );
  };

  return (
    <div className='p-8'>
      <h3 className='text-primary-500 text-xl font-bold'>Manage Filters</h3>
      <div className='mt-8'>
        {filters.map(({ name, value }, index) => {
          /**
           * Remove all already selected filters from the options
           *
           * Add this specific filter to the new set of options to make sure it is not removed
           */

          const options = filterOptions.filter(
            (option) =>
              filters.findIndex((filter) => filter.name === option.value) ===
                -1 || option.value === name
          );

          return (
            <div className='mt-4 flex items-center gap-4'>
              <p className='m-0'>Where</p>

              <Select
                defaultValue={name}
                style={{ minWidth: 250 }}
                onChange={(data) => {
                  setFilters((prev) => {
                    return produce(prev, (draft) => {
                      draft[index].name = data;
                    });
                  });
                }}
                options={options}
              />

              <p className='m-0 italic'>is</p>

              <Select
                defaultValue={value as any}
                style={{ minWidth: 100 }}
                onChange={(data) => {
                  setFilters((prev) => {
                    return produce(prev, (draft) => {
                      draft[index].value = data;
                    });
                  });
                }}
                options={[
                  {
                    label: "True",
                    value: true as any,
                  },
                  {
                    label: "False",
                    value: false as any,
                  },
                ]}
              />
              <Button type='link' onClick={() => removeFilter(index)}>
                <Icon name='remove' className='!m-0' />
              </Button>
            </div>
          );
        })}
      </div>
      <div className='w-full flex justify-start mt-4'>
        <Button
          type='link'
          variant='basic'
          onClick={addFilter}
          disabled={disableAddFilter()}
        >
          <Icon name='plus' className='!m-0 p-0' />
          Add Filter
        </Button>
      </div>
      <div className='w-full flex justify-end mt-4'>
        <Button type='subtle' variant='success' onClick={saveFilters}>
          <Icon name='check circle' className='!ml-0 p-0' />
          Save Filters
        </Button>
      </div>
    </div>
  );
};
