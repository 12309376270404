import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { Pro } from "../../types";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { GET_PROS_WITH_CONFLICTS } from "@/graphql/pros";
import { SelectPro } from "./components/SelectPro";

export interface SelectContractProProps {
  proposedContract: {
    start: Date;
    end: Date;
    nonWorkingDays: string[];
  };
  categoryId: string;
  exclusions?: string[]; // pro ids to exclude
  onSelect: (pro: { id: string; name: string }) => void;
  loading?: boolean;
}

export const SelectContractPro: FC<SelectContractProProps> = ({
  proposedContract,
  categoryId,
  exclusions,
  onSelect,
  loading: loadingProp,
}) => {
  const { loading, error, data } = useQuery<{
    pros: Pro[];
  }>(GET_PROS_WITH_CONFLICTS, {
    variables: {
      proposed: proposedContract,
      categoryId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <RequestFailed errorMessage={error?.message} />;
  }

  return (
    <SelectPro
      pros={data.pros.filter((pro) => !exclusions?.includes(pro.id))}
      onSelect={onSelect}
      loading={loadingProp}
    />
  );
};
