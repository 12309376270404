import React, { FC } from "react";
import { Image } from "semantic-ui-react";
import { VField as Field } from "../../_components/VField";
import fallback from "../../assets/fallback.png";

export interface IdentificationProps {
  type?: string;
  idNumber?: string;
  documentImageUrl?: string;
  dateOfBirth?: Date;
}

export const Identification: FC<IdentificationProps> = ({
  type,
  idNumber,
  documentImageUrl,
  dateOfBirth,
}) => {
  const photo = documentImageUrl || fallback;

  return (
    <div className='flex flex-col gap-4'>
      <Field label={"Type"} value={type || "N/A"} />
      <Field label={"Identification Number"} value={idNumber || "N/A"} />
      <Field
        label='Date Of Birth'
        value={dateOfBirth ? new Date(dateOfBirth).toLocaleDateString() : "N/A"}
      />
      <div className='flex flex-col gap-1'>
        <p className='font-semibold text-sm text-primary-300 m-0 p-0'>
          Document Photo
        </p>
        <Image src={photo} as='a' size='large' href={photo} target='_blank' />
      </div>
    </div>
  );
};
