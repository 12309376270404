import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { Dropdown, Icon, Message, Modal } from 'semantic-ui-react';

import { CatServiceSelectors } from '../../../redux/store';
import { formatAsNaira } from '../../../helpers/helpers';
import { CHANGE_CATEGORY } from './_graphql/mutation';

import { components as LayoutComponents } from '../../../components/layout';
import Island from '../../../_components/Island';
import { GET_JOB } from '../../../components/orders/graphql/query';
import { Job as IJob } from '../../../components/orders/types';
import Button from '../../../_components/Button';
import { DataField } from './_components/DataField';
import { DataSection } from './_components/DataSection';
import styles from './styles.module.scss';
import { Alert } from 'antd';
import { CategoryEdit } from './_components/EditModals/CategoryEdit';
import { DescriptionEdit } from './_components/EditModals/DescriptionEdit';
import { NameEdit } from './_components/EditModals/NameEdit';

const { selectCategories, selectSubCategories } = CatServiceSelectors;
const { Trail } = LayoutComponents;

export const Job: FC = () => {
  const { jobCode } = useParams<{ jobCode: string }>();
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);

  const getJobResponse = useQuery(GET_JOB, {
    variables: {
      jobCode,
    },
    fetchPolicy: 'cache-first',
  });

  const job: IJob = getJobResponse.data?.getJobByCode;

  const [editCategoryOpen, setEditCategoryOpen] = useState(false);

  const [editNameOpen, setEditNameOpen] = useState(false);

  const [editDescriptionOpen, setEditDescriptionOpen] = useState(false);

  if (getJobResponse.loading) {
    return <div>loading</div>;
  }

  if (getJobResponse.error) {
    return (
      <Message negative>
        <Message.Header>{getJobResponse.error.message}</Message.Header>
      </Message>
    );
  }

  let imgTransformVar = '/upload/w_320/';
  const finalCost =
    job?.invoiceDetails &&
    job.invoiceDetails.labor +
      job.invoiceDetails.materials.reduce((accumulator, current) => {
        return accumulator + current.quantity * current.unitPrice;
      }, 0);

  const resourceUrl = job.custom?.resourceUrl;

  return (
    <>
      {job.custom && (
        // Edit modals
        <>
          <CategoryEdit
            jobId={job.id}
            open={editCategoryOpen}
            handleClose={() => setEditCategoryOpen(false)}
            categoryId={job.custom.categoryId}
          />
          <DescriptionEdit
            jobId={job.id}
            open={editDescriptionOpen}
            handleClose={() => setEditDescriptionOpen(false)}
            description={job.custom.description}
          />
          <NameEdit
            jobId={job.id}
            open={editNameOpen}
            handleClose={() => setEditNameOpen(false)}
            name={job.custom.name}
          />
        </>
      )}

      <Island
        header={
          <Trail
            root="Job Code"
            child={jobCode}
            {...(!!job.custom && {
              label: {
                color: 'blue',
                text: 'custom',
              },
            })}
            deep
          ></Trail>
        }
      >
        <div className={styles.dataWrapper}>
          <h4>Task Details</h4>
          <DataSection>
            <DataField>
              <h5 className={styles.fieldHeader}>Name</h5>
              <p className={clsx(styles.fieldDescription, styles.capitalize)}>
                {job.task?.name || job.custom?.name}
              </p>
              {job.custom && (
                <Icon
                  data-testid="edit-name"
                  className={styles.fieldAction}
                  name="edit outline"
                  onClick={() => setEditNameOpen(true)}
                ></Icon>
              )}
            </DataField>
            <DataField>
              <h5 className={styles.fieldHeader}>Description</h5>
              <p className={styles.fieldDescription}>
                {job.task?.description || job.custom?.description}
              </p>
              {job.custom && (
                <Icon
                  data-testid="edit-description"
                  className={styles.fieldAction}
                  name="edit outline"
                  onClick={() => setEditDescriptionOpen(true)}
                ></Icon>
              )}
            </DataField>

            {job.custom && (
              <>
                <DataField>
                  <h5 className={styles.fieldHeader}>Location</h5>
                  <p
                    className={clsx(styles.fieldDescription, styles.capitalize)}
                  >{`${job.contact.location?.buildingNumber || ''} ${
                    job.contact.location.streetName
                  }, ${job.contact.location.area}, ${
                    job.contact.location.location
                  }`}</p>
                </DataField>
                <DataField>
                  <h5 className={styles.fieldHeader}>Notes</h5>
                  <p
                    className={clsx(styles.fieldDescription, styles.capitalize)}
                  >
                    {job.contact.notes || 'N/A'}
                  </p>
                </DataField>
              </>
            )}
          </DataSection>
          <DataSection>
            <DataField>
              <h5 className={styles.fieldHeader}>Category</h5>
              <p className={clsx(styles.fieldDescription, styles.capitalize)}>
                {
                  categories[
                    job.task?.categoryId || job.custom?.categoryId || ''
                  ]?.name
                }
              </p>
              {job.custom && (
                <Icon
                  data-testid="edit-category"
                  className={styles.fieldAction}
                  name="edit outline"
                  onClick={() => setEditCategoryOpen(true)}
                ></Icon>
              )}
            </DataField>
            {!job.custom && (
              <DataField>
                <h5 className={styles.fieldHeader}>Sub Category</h5>
                <p className={styles.fieldDescription}>
                  {
                    subCategories[
                      job.task?.subCategoryId || job.task?.subCategoryId || ''
                    ]?.name
                  }
                </p>
              </DataField>
            )}
            <DataField>
              <h5 className={styles.fieldHeader}>Final Cost</h5>
              <p className={clsx(styles.fieldDescription, styles.capitalize)}>
                {finalCost ? formatAsNaira(finalCost) : 'N/A'}
              </p>
            </DataField>
          </DataSection>
          <DataSection>
            {!job.custom && (
              <>
                <DataField>
                  <h5 className={styles.fieldHeader}>Location</h5>
                  <p
                    className={clsx(styles.fieldDescription, styles.capitalize)}
                  >{`${job.contact.location?.buildingNumber || ''} ${
                    job.contact.location.streetName
                  }, ${job.contact.location.area}, ${
                    job.contact.location.location
                  }`}</p>
                </DataField>
                <DataField>
                  <h5 className={styles.fieldHeader}>Notes</h5>
                  <p
                    className={clsx(styles.fieldDescription, styles.capitalize)}
                  >
                    {job.contact.notes || 'N/A'}
                  </p>
                </DataField>
              </>
            )}

            {resourceUrl && (
              <DataField>
                <h5 className={styles.fieldHeader}>Task Image/Video</h5>
                <div className={styles.customJobFile}>
                  {resourceUrl.includes('video') ? (
                    <video controls src={resourceUrl}></video>
                  ) : (
                    <img
                      src={resourceUrl.replace(
                        '/upload/',
                        `${imgTransformVar}`
                      )}
                      alt="custom job resource file"
                    />
                  )}
                </div>
              </DataField>
            )}
          </DataSection>
        </div>
      </Island>
    </>
  );
};
