import { Switch } from "antd";
import React, { FC, useContext } from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { ContractQuotationItem } from "../../../../../../../types";
import { SingleQuotationContext } from "../../../../context/single-quotation.context";

import styles from "./styles.module.scss";
import { formatAsNaira } from "../../../../../../../../../helpers/helpers";
import { Rate } from "./Rate";
import clsx from "clsx";

export interface BreakdownItemProps extends ContractQuotationItem {
  id: string;
  onSplitItem: () => void;
}

export const BreakdownItem: FC<BreakdownItemProps> = ({
  isActive,
  proCount,
  level,
  rate,
  workdays,
  contractDetails,
  id,
  onSplitItem,
}) => {
  const {
    editMode,
    changeActive,
    changeLevel,
    changeRate,
    changeWorkdays,
  } = useContext(SingleQuotationContext);

  return (
    <div className={styles.item}>
      <div>
        {
          <Switch
            disabled={!editMode}
            size='small'
            checked={isActive}
            onChange={(checked) => changeActive(id, checked)}
          />
        }
      </div>
      <div>{proCount} pro(s)</div>
      <div>
        <Icon
          name='unlink'
          onClick={onSplitItem}
          disabled={proCount === 1 || !editMode}
        />
      </div>
      <div>
        <Dropdown
          disabled={!editMode}
          value={level}
          onChange={(e, { value }) => changeLevel(id, value as number)}
          options={[
            {
              key: "1",
              text: "L1",
              value: 1,
            },
            {
              key: "2",
              text: "L2",
              value: 2,
            },
            {
              key: "3",
              text: "L3",
              value: 3,
            },
            {
              key: "4",
              text: "L4",
              value: 4,
            },
          ]}
        />
      </div>
      <div>
        <input
          disabled={!editMode}
          placeholder='Daily Rate'
          className={styles.rate}
          value={rate || ""}
          onChange={(e) => changeRate(id, Number(e.target.value))}
        />
      </div>
      <div>
        <div className='flex items-center'>
          <input
            disabled={!editMode || contractDetails.interval === "DAYS"}
            placeholder='Daily Rate'
            className='w-12 rounded pl-3 py-2 border border-solid border-primary-150 mr-2 disabled:border-0 disabled:w-6 disabled:mr-1'
            value={workdays}
            type={
              editMode && !(contractDetails.interval === "DAYS")
                ? "number"
                : "text"
            }
            max={7}
            min={1}
            onChange={(e) => changeWorkdays(id, Number(e.target.value))}
          />
          <p className='text-primary-500 font-bold m-0'>/ 7 work days</p>
          <div className='group relative ml-2 cursor-pointer'>
            <Icon name='info circle' className='peer' />
            <div className='absolute bottom-8 p-4 hidden peer-hover:block w-80 bg-white rounded-lg z-50 shadow'>
              <h5 className='text-primary-500 font-semibold'>Work Days</h5>
              <p className='text-primary-300'>
                The number of days in a week pros would work.
              </p>
              <p className='mt-4 text-primary-300'>
                <strong>Note:</strong> This setting is disabled for items with a
                daily contract interval
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Rate rate={rate} workdays={workdays} />
      </div>
    </div>
  );
};
