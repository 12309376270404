import {
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    GridItem,
    Icon,
    Input,
    Text,
  } from "@chakra-ui/react";
  import debounce from "lodash.debounce";
  import { useState } from "react";
  import {
    getNewSessionToken,
    getPlaceDetails,
    getTextSearchPredictions,
  } from "@/service/google-maps.service";
  import { useEffect } from "react";
  import { MdLocationOn } from "react-icons/md";
import { LocationData } from "../../types";
import { SectionLoading } from "@/_components/Loading/SectionLoading";


  interface AddLocationProps {
    onAdd: (location: LocationData) => void;
  }
  
  export const AddLocation = ({onAdd}: AddLocationProps) => {
    const [getPredictionsLoading, setGetPredictionsLoading] = useState(false);
  
  
    const [sessionToken, setSessionToken] =
      useState<google.maps.places.AutocompleteSessionToken | null>(null);
  
    useEffect(() => {
      setSessionToken(getNewSessionToken());
    }, []);
  
    const [predictions, setPredictions] = useState<
      google.maps.places.AutocompletePrediction[]
    >([]);
  
    const debouncedGetPredictions = () => {
      setGetPredictionsLoading(true);
      
      return debounce((value: string) => {
      getTextSearchPredictions(value, sessionToken!).then((predictions) => {
        setPredictions(predictions);
        setGetPredictionsLoading(false);
      }).catch((e) => {
        setPredictions([])
        setGetPredictionsLoading(false);
      });
    }, 500)};
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      debouncedGetPredictions()(e.target.value);
    };
  
    const handlePredictionClick = (
      prediction: google.maps.places.AutocompletePrediction
    ) => {
      getPlaceDetails(prediction.place_id, sessionToken!).then((place) => {
        const state = place.address_components!.find((component) =>
          component.types.includes("administrative_area_level_1")
        );
  
        const city = place.address_components!.find((component) =>
          component.types.includes("administrative_area_level_2")
        );
  
      onAdd({
          coordinates: {
            latitude: place.geometry!.location!.lat(),
            longitude: place.geometry!.location!.lng(),
          },
          address: place.name!,
          placeId: place.place_id!,
          state: state!.long_name!,
          city: city!.long_name!,
        });
      });
    };
  
    return (
      <GridItem display='flex' flexDirection='column' gap={4}>
        <Flex direction='column' gap={2}>
          <Text fontSize='sm' color='gray.500'>
            This is where you expect the LaborHack Pros to carry out their tasks
          </Text>
        </Flex>
        <FormControl isRequired>
          <FormLabel fontSize='sm'>Enter Address</FormLabel>
          <Input
            type='text'
            onChange={handleInputChange}
            fontSize='sm'
            placeholder='Start typing your address'
            maxW='xl'
          />
          <FormHelperText>
            If you need to define multiple locations, you can create multiple
            requests{" "}
          </FormHelperText>
        </FormControl>
        <Flex w='full' maxW={"xl"} direction='column' gap={4}>
          {getPredictionsLoading ? (
            <SectionLoading />
          ) : (
            predictions.map((prediction) => (
              <Flex
                p={6}
                justifyContent='space-between'
                boxShadow='md'
                maxW={"xl"}
                borderWidth={"thin"}
                borderStyle='solid'
                borderColor='gray.300'
                rounded='md'
                cursor='pointer'
                key={prediction.place_id}
                onClick={() => handlePredictionClick(prediction)}
                _hover={{
                  bg: "gray.50",
                }}
              >
                <Text fontSize='sm' fontStyle='italic'>
                  {prediction.description}
                </Text>
                <Flex alignItems={"center"} gap={4}>
                  <Text fontSize='sm' fontStyle='italic' color='gray.500'>
                    {prediction.structured_formatting.secondary_text}
                  </Text>
                  <Icon as={MdLocationOn} w={6} h={6} color='red.500' />
                </Flex>
              </Flex>
            ))
          )}
        </Flex>
      </GridItem>
    );
  };
  