import React, { FC } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { types } from '../../../service';
import Island from '../../../_components/Island';
import { Table } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_ALL_ADMINS } from '../../../graphql/query';

const AllAdmins: FC = () => {
  const history = useHistory();

  const { data: adminData, loading, refetch } = useQuery(GET_ALL_ADMINS);

  const allAdmin: types.IAdminData[] = adminData?.listAllAdmins.data;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: '1',
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: '2',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: '4',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: '5',
    },
  ];

  const data = allAdmin?.map((data) => ({
    key: data.id,
    id: data.id,
    full_name: `${data.firstName} ${data.lastName}`,
    role: data.role,
    email: data.email,
    phoneNumber: data.phoneNumber,
  }));

  return (
    <Grid columns="1">
      <Grid.Column width="16">
        <Island header="All Admins">
          <div className="o-admin__header-wrapper">
            <div className="o-add-wrapper">
              <Button
                className="o-add-button"
                onClick={() => history.push('/admins/create')}
              >
                Create Admin
              </Button>
              <div
                className="refresh-btn"
                onClick={() => {
                  refetch();
                }}
              >
                <p>Refresh </p>
                <Icon name="refresh"></Icon>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            pagination={false}
            dataSource={data}
            loading={loading}
            onRow={(user) => {
              return {
                onClick: () => history.push(`/admins/user/${user.id}`),
              };
            }}
            scroll={{
              y: 400,
              scrollToFirstRowOnChange: false,
            }}
          ></Table>
        </Island>
      </Grid.Column>
    </Grid>
  );
};

export default AllAdmins;
