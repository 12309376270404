import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IAssignmentInitialState,
  TradesmanAssignment,
} from "../components/orders/types";
import { ApolloClient } from "@apollo/client";
import {
  CREATE_NEW_ASSIGNMENT,
  UNASSIGN_TRADESMAN,
  DELETE_ASSIGNMENT,
  CREATE_INVOICE,
} from "../components/orders/graphql/mutation";
import {
  GET_TRADESMAN_ASSIGNMENTS,
  CHECK_TRADESMEN_AVAILABILITY_BY_DATE,
} from "../components/orders/graphql/query";

const initialState: IAssignmentInitialState = {
  message: "",
  status: "idle",
  error: "",
  fetchedAssignments: {},
  availableTradesmenInCurrentOrder: [],
  create: {
    date: null,
    jobIds: [],
    orderId: "",
    tradesman: null,
  },
  currentAssignmentPayment: {
    modalOpen: false,
    paymentUrl: "",
    transactionReference: "",
  },
  editInvoice: {
    modalOpen: false,
  },
};

const assignmentsFetched = createAsyncThunk(
  "assignment/assignmentsFetched",
  async ({
    client,
    orderId,
  }: {
    orderId: string;
    client: ApolloClient<object>;
  }) => {
    try {
      const { data } = await client.query({
        query: GET_TRADESMAN_ASSIGNMENTS,
        variables: {
          orderId,
        },
        fetchPolicy: "network-only",
      });

      if (!data.getTradesmanAssignments.status) {
        throw new Error("Assignments Fetch Failed");
      }

      return data.getTradesmanAssignments;
    } catch (error) {
      throw (error as Error).message;
    }
  }
);

const availableOrderTradesmen = createAsyncThunk(
  "assignment/tradesmenFetched",
  async (
    {
      client,
    }: {
      client: ApolloClient<object>;
    },
    { getState }
  ) => {
    const state: any = getState();

    const {
      fetchedAssignments,
      create: { date },
    }: IAssignmentInitialState = state.assignment;

    try {
      const { data } = await client.query({
        query: CHECK_TRADESMEN_AVAILABILITY_BY_DATE,
        variables: {
          data: {
            tradesmanIds: Object.values(fetchedAssignments).map(
              ({ tradesman }) => tradesman.id
            ),
            date,
          },
        },
      });

      if (!data.checkTradesmenAvailabilityByDate.status) {
        throw new Error("Assignments Fetch Failed");
      }
      return data.checkTradesmenAvailabilityByDate;
    } catch (error) {
      throw (error as Error).message;
    }
  }
);

const assignmentSubmitted = createAsyncThunk(
  "assignment/assignmentSubmitted",
  async (
    { client }: { client: ApolloClient<object> },
    { getState, dispatch }
  ) => {
    const {
      assignment: {
        create: { tradesman, ...rest },
      },
    }: any = getState();

    try {
      const { data } = await client.mutate({
        mutation: CREATE_NEW_ASSIGNMENT,
        variables: {
          data: {
            ...rest,
            tradesmanId: tradesman.id,
          },
        },
      });

      if (!data.assignJobsToTradesman.status) {
        throw new Error("Assignment Failed");
      }

      setTimeout(() => {
        dispatch(assignmentSlice.actions.createReset());
      }, 2000);

      return data.assignJobsToTradesman;
    } catch (error) {
      throw (error as Error).message;
    }
  }
);

const unassignmentSubmitted = createAsyncThunk(
  "assignment/unassignmentSubmitted",
  async ({ client }: { client: ApolloClient<object> }, { getState }) => {
    const {
      orders: {
        order: { id },
      },
      jobs: { job },
    }: any = getState();

    try {
      const { data } = await client.mutate({
        mutation: UNASSIGN_TRADESMAN,
        variables: {
          data: {
            orderId: id,
            jobId: job.id,
          },
        },
      });

      if (!data.unassignTradesmanFromJob.status) {
        throw new Error("Assignment Failed");
      }

      return data.unassignTradesmanFromJob;
    } catch (error) {
      throw (error as Error).message;
    }
  }
);

const deleteAssignment = createAsyncThunk(
  "assignment/assignmentDeleted",
  async (
    {
      client,
      assignmentId,
    }: {
      client: ApolloClient<object>;
      assignmentId: string;
    },
    { dispatch }
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: DELETE_ASSIGNMENT,
        variables: {
          assignmentId,
        },
      });

      setTimeout(() => {
        dispatch(assignmentSlice.actions.createReset());
      }, 2000);

      return data.deleteAssignment;
    } catch (error) {
      throw error;
    }
  }
);

const generateInvoice = createAsyncThunk(
  "assignment/invoiceGenerated",
  async ({
    client,
    assignmentId,
  }: {
    client: ApolloClient<object>;
    assignmentId: string;
  }) => {
    try {
      const { data } = await client.mutate({
        mutation: CREATE_INVOICE,
        variables: {
          data: {
            assignmentId,
            callbackUrl: process.env.REACT_APP_CALLBACK_URL,
          },
        },
      });

      return data.createInvoiceFromAssignment;
    } catch (error) {
      throw error;
    }
  }
);

const assignmentSlice = createSlice({
  name: "assignments",
  initialState,
  reducers: {
    addedJobToAssignment: (state, action) => {
      state.create.jobIds.push(action.payload);
    },
    addedJobsToAssignment: (state, action) => {
      state.create.jobIds.push(...action.payload);
    },
    removedJobFromAssignment: (state, action) => {
      state.create.jobIds.splice(action.payload, 1);
    },
    orderIdAddedToAssignment: (state, action) => {
      state.create.orderId = action.payload;
    },
    dateAdded: (state, action) => {
      state.create.date = action.payload;
    },
    tradesmanAdded: (state, action) => {
      state.create.tradesman = action.payload;
    },
    closePaymentModal: (state) => {
      state.currentAssignmentPayment.modalOpen = false;
    },
    openEditInvoiceModal: (state) => {
      state.editInvoice.modalOpen = true;
    },
    closeEditInvoiceModal: (state) => {
      state.editInvoice.modalOpen = false;
    },
    createReset: (state) => {
      state.message = "";
      state.error = "";
      state.create.date = new Date().getTime();
      state.create.jobIds = [];
      state.create.orderId = "";
      state.create.tradesman = null;
    },
  },
  extraReducers: {
    [availableOrderTradesmen.pending.type]: (state) => {
      state.status = "pending";
    },
    [availableOrderTradesmen.fulfilled.type]: (state, action) => {
      state.status = "idle";
      state.message = action.payload.message;
      state.availableTradesmenInCurrentOrder = action.payload.data;
    },
    [availableOrderTradesmen.rejected.type]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [assignmentsFetched.pending.type]: (state) => {
      state.status = "pending";
    },
    [assignmentsFetched.fulfilled.type]: (state, action) => {
      state.status = "idle";
      state.message = action.payload.message;

      const data: {
        [x: string]: TradesmanAssignment;
      } = {};

      action.payload.data.forEach((assignment: TradesmanAssignment) => {
        data[assignment.id] = assignment;
      });
      state.fetchedAssignments = data;
    },
    [assignmentsFetched.rejected.type]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [assignmentSubmitted.pending.type]: (state) => {
      state.status = "pending";
    },
    [assignmentSubmitted.fulfilled.type]: (state, action) => {
      state.status = "idle";
      state.message = action.payload.message;
    },
    [assignmentSubmitted.rejected.type]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [unassignmentSubmitted.pending.type]: (state) => {
      state.status = "pending";
    },
    [unassignmentSubmitted.fulfilled.type]: (state, action) => {
      state.status = "idle";
      state.message = action.payload.message;
    },
    [unassignmentSubmitted.rejected.type]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [deleteAssignment.pending.type]: (state) => {
      state.status = "pending";
    },
    [deleteAssignment.fulfilled.type]: (state, action) => {
      state.status = "idle";
      state.message = action.payload.message;
    },
    [deleteAssignment.rejected.type]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
    },
    [generateInvoice.pending.type]: (state) => {
      state.status = "pending";
    },
    [generateInvoice.fulfilled.type]: (state, action) => {
      state.status = "idle";
      state.currentAssignmentPayment.paymentUrl =
        action.payload.data.paymentUrl;
      state.currentAssignmentPayment.transactionReference =
        action.payload.data.transactionReference;
      state.currentAssignmentPayment.modalOpen = true;
    },
    [generateInvoice.rejected.type]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export const AssignmentReducer = assignmentSlice.reducer;
export const AssignmentActions = {
  deleteAssignment,
  assignmentSubmitted,
  assignmentsFetched,
  availableOrderTradesmen,
  generateInvoice,
  ...assignmentSlice.actions,
  unassignmentSubmitted,
};
export const AssignmentSelectors = {
  selectFetchedAssignments: (state: {
    assignment: IAssignmentInitialState;
  }) => {
    return state.assignment.fetchedAssignments;
  },
  selectCreateAssignmentData: (state: {
    assignment: IAssignmentInitialState;
  }) => {
    return state.assignment.create;
  },
  selectOrderTradesmen: (state: { assignment: IAssignmentInitialState }) => {
    return state.assignment.availableTradesmenInCurrentOrder;
  },
  selectAssignmentMessage: (state: { assignment: IAssignmentInitialState }) => {
    return state.assignment.message;
  },
  selectAssignmentStatus: (state: { assignment: IAssignmentInitialState }) => {
    return state.assignment.status;
  },
  selectAssignmentPayment: (state: { assignment: IAssignmentInitialState }) => {
    return state.assignment.currentAssignmentPayment;
  },
  selectEditInvoiceModalOpen: (state: {
    assignment: IAssignmentInitialState;
  }) => {
    return state.assignment.editInvoice.modalOpen;
  },
  selectAssignmentRequestError: (state: {
    assignment: IAssignmentInitialState;
  }) => {
    return state.assignment.error;
  },
};
