import React, { FC } from 'react';
import Button from '@laborhack/custom-button';
import Island from '../../_components/Island';
import { components as LayoutComponents } from '../../components/layout';

import styles from './styles.module.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { ContractRecruitment, RecruitmentRequestStatus } from './types';
import { gql, useQuery } from '@apollo/client';
import RequestFailed from '../../_components/RequestFailed';

const { Trail, CustomTable, CustomLabel } = LayoutComponents;

export const GET_RECRUITMENTS = gql`
  query getRecruitments {
    contractRecruitments {
      id
      client {
        firstName
        lastName
      }
      requests {
        proCount
      }
      status
      createdAt
    }
  }
`;

export interface RecruitmentsProps {}

export const Recruitments: FC<RecruitmentsProps> = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { loading, error, data } = useQuery<{
    contractRecruitments: ContractRecruitment[];
  }>(GET_RECRUITMENTS);

  if (error) {
    return <RequestFailed errorMessage={error.message} />;
  }

  const columns: ColumnsType<ContractRecruitment & { key: string }> = [
    {
      title: 'ID',
      render: (text, record) => {
        return <p>{record.id.substring(0, 8)}</p>;
      },
      width: '10%',
    },
    {
      title: 'Client',
      render: (text, record) => {
        return <p>{`${record.client.firstName} ${record.client.lastName}`}</p>;
      },
      width: '40%',
    },
    {
      title: 'Request Date',
      render: (text, record) => {
        return <p>{new Date(record.createdAt).toLocaleString()}</p>;
      },
      width: '15%',
    },
    {
      title: 'No of Pros',
      render: (text, record) => {
        const pros = record.requests.reduce((acc, { proCount }) => {
          return acc + proCount;
        }, 0);

        return <p>{pros}</p>;
      },
      width: '10%',
    },
    {
      title: 'Status',
      render: (text, record) => {
        const color = {
          r: 0,
          g: 0,
          b: 0,
        };

        switch (record.status) {
          case RecruitmentRequestStatus.PENDING:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case RecruitmentRequestStatus.QUOTATION_SENT:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case RecruitmentRequestStatus.ASSIGNING:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case RecruitmentRequestStatus.FULFILLED:
            color.r = 86;
            color.g = 189;
            color.b = 102;
            break;

          default:
            break;
        }

        return (
          <CustomLabel
            color={color}
            text={record.status.toString().replace('_', ' ')}
          />
        );
      },
      width: '25%',
    },
  ];

  return (
    <Island header={<Trail root="Recruitments" />}>
      <div className={styles.actions}>
        <Button onClick={() => history.push(`${path}/create`)}>
          Create Recruitment
        </Button>
      </div>
      <CustomTable
        onRow={(record: any, index: any) => {
          return {
            onClick: () => {
              history.push(path + '/' + record.id);
            },
          };
        }}
        columns={columns}
        data={
          data?.contractRecruitments.map((recruitment) => {
            return {
              key: recruitment.id,
              ...recruitment,
            };
          }) || []
        }
        loading={loading}
        handleTableChange={() => {}}
      ></CustomTable>
    </Island>
  );
};
