import { FC } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { ScheduleConflict, ScheduleConflictType } from "../../../types";

export interface ConflictsProps {
  conflicts: ScheduleConflict[];
}

export const Conflicts: FC<ConflictsProps> = ({ conflicts }) => {
  return (
    <div className='mt-8'>
      <div className='flex items-center'>
        <Icon color='red' name='warning sign' />
        <h5 className='text-red-500'>
          Found {conflicts.length} conflict(s) with this pro
        </h5>
      </div>
      <div className='mt-4'>
        {conflicts.map((conflict) => {
          if (conflict.type === ScheduleConflictType.CONTRACT_OVERLAP) {
            const {
              id,
              contractLength,
              contractInterval,
              recruitment: {
                client: { firstName, lastName },
              },
            } = conflict.contract;

            return (
              <p className='text-primary-500'>
                <span className='font-medium mr-2'>
                  {`${firstName} ${lastName}`}&apos;s Recruitment -{" "}
                  {`${contractLength} ${contractInterval.toLowerCase()}`}
                </span>
                <Link to={`/recruitment/contracts/${id}`}>View Contract</Link>
              </p>
            );
          }

          if (conflict.type === ScheduleConflictType.ASSIGNMENT_SCHEDULED) {
            return (
              <p>
                <span>
                  Assignment -{" "}
                  {new Date(conflict.assignment.date).toLocaleDateString()}
                </span>
              </p>
            );
          }
        })}
      </div>
    </div>
  );
};
