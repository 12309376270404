import { gql, useQuery } from "@apollo/client";
import {
  ColumnType,
  ColumnsType,
  TablePaginationConfig,
  TableProps,
} from "antd/lib/table";
import Button from "@laborhack/custom-button";
import { components as LayoutComponents } from "../../../../components/layout";
import {
  ListProsFilters,
  ListProsSearchBy,
  ListProsSortBy,
  Pro,
  RelayStylePaginatedResponse,
  ProListItem,
  ListProsSearch,
  ListProsSort,
} from "../../../../types";
import Island from "../../../../_components/Island";
import Loading from "../../../../_components/Loading";
import RequestFailed from "../../../../_components/RequestFailed";
import Pill from "../../../../_components/Pill";
import {
  calculateRecruitmentRequestsEndDates,
  extractItemsFromRelayStylePaginationResponse,
} from "../../../../helpers../../helpers/helpers";
import React, { FC, useContext, useEffect, useMemo } from "react";
import {
  Input,
  Select,
  Button as SemanticButton,
  Icon,
  DropdownProps,
  InputOnChangeData,
} from "semantic-ui-react";
import produce from "immer";
import PillGroup from "../../../../_components/PillGroup";
import {
  FormControl,
  FormLabel,
  Switch,
  Checkbox,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { RecruitmentContext } from "../context/recruitment.context";
import { RecruitmentType } from "../../types";
import { UnavailableProConfirmationModal } from "./UnvailableProConfirmationModal";

const { CustomTable } = LayoutComponents;

const searchOptions = [
  {
    key: ListProsSearchBy.FIRST_NAME,
    text: "First Name",
    value: ListProsSearchBy.FIRST_NAME,
  },
  {
    key: ListProsSearchBy.LAST_NAME,
    text: "Last Name",
    value: ListProsSearchBy.LAST_NAME,
  },
  {
    key: ListProsSearchBy.EMAIL,
    text: "Email",
    value: ListProsSearchBy.EMAIL,
  },
  {
    key: ListProsSearchBy.PHONE_NUMBER,
    text: "Phone Number",
    value: ListProsSearchBy.PHONE_NUMBER,
  },
  {
    key: ListProsSearchBy.ADDRESS,
    text: "Address",
    value: ListProsSearchBy.ADDRESS,
  },
];

const LIST_PROS_FOR_RECRUITMENT = gql`
  query ListProsForRecruitment(
    $first: Int
    $after: String
    $search: ListProsSearch
    $sort: ListProsSort
    $filters: ListProsFilters
    $proposedSchedule: ProposedSchedule
    $exclusionShortlistId: String
  ) {
    listProsForRecruitment(
      first: $first
      after: $after
      search: $search
      sort: $sort
      filters: $filters
      proposedSchedule: $proposedSchedule
      exclusionShortlistId: $exclusionShortlistId
    ) {
      edges {
        cursor
        node {
          id
          firstName
          lastName
          email
          phoneNumber
          isLegacy
          createdAt
          trades {
            id
            name
          }
          categories {
            id
            proTitle
          }
          proApplication {
            address
            stateOfResidence
          }
          onboardedAt
          conflicts {
            ... on MarketplaceConflict {
              type
            }
            ... on ContractConflict {
              type
            }
            ... on AssignmentConflict {
              type
            }
          }
          isAvailable
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const CATEGORIES = gql`
  query Categories {
    categories {
      id
      proTitle
    }
  }
`;

export interface ShortlistProsProps {
  onSelect: (data: {
    recruitmentId: string;
    requestId: string;
    proIds: string[];
  }) => void;
  exclusionShortlistId?: string;
}

export const ShortlistPros: FC<ShortlistProsProps> = ({
  onSelect,
  exclusionShortlistId,
}) => {
  const { recruitment, activeRequests } = useContext(RecruitmentContext);

  const requestsProposedEndDates = useMemo(
    () =>
      calculateRecruitmentRequestsEndDates(
        activeRequests || [],
        recruitment?.recruitmentType || ("" as RecruitmentType)
      ),
    [activeRequests, recruitment?.recruitmentType]
  );

  const { data, loading, error, fetchMore, refetch } = useQuery<{
    listProsForRecruitment: RelayStylePaginatedResponse<ProListItem>;
  }>(LIST_PROS_FOR_RECRUITMENT, {
    variables: {
      first: 10,
      sort: {
        by: ListProsSortBy.CREATED_AT,
        ascending: false,
      },
      ...(activeRequests?.at(0) && {
        proposedSchedule: {
          start: activeRequests?.at(0)?.proposedStartDate,
          end: requestsProposedEndDates?.at(0),
        },
      }),
      exclusionShortlistId: exclusionShortlistId,
    },
    fetchPolicy: "cache-only",
  });

  const {
    error: categoriesError,
    loading: loadingCategories,
    data: categoriesData,
  } = useQuery<{
    categories: { id: string; proTitle?: string }[];
  }>(CATEGORIES);

  const recruitmentRequestsCategoryIds = activeRequests?.map(
    (request) => request.categoryId
  );

  const [search, setSearch] = React.useState<ListProsSearch>();
  const [filters, setFilters] = React.useState<ListProsFilters>({
    categoryIds: recruitmentRequestsCategoryIds,
  });
  const [sort, setSort] = React.useState<ListProsSort>({
    by: ListProsSortBy.CREATED_AT,
    ascending: false,
  });
  const [activeRequestIndex, setActiveRequestIndex] = React.useState<number>(0);
  const [
    shortlistedProsPerRequest,
    setShortlistedProsPerRequest,
  ] = React.useState<Record<string, ProListItem[]>>({ "0": [] });

  const [
    onlyShortlistedProsShown,
    setOnlyShortlistedProsShown,
  ] = React.useState<boolean>(false);

  useEffect(() => {
    if (!filters.categoryIds || filters.categoryIds?.length === 0) {
      setFilters((prev) => {
        return {
          ...prev,
          categoryIds: recruitmentRequestsCategoryIds,
        };
      });
    }
  }, [filters.categoryIds, recruitmentRequestsCategoryIds]);

  useEffect(() => {
    refetch({
      first: 10,
      sort,
      search,
      filters,
      ...(activeRequests && {
        proposedSchedule: {
          start: activeRequests?.at(activeRequestIndex)?.proposedStartDate,
          end: requestsProposedEndDates?.at(activeRequestIndex),
        },
      }),
      exclusionShortlistId: exclusionShortlistId,
    });
  }, [
    sort,
    search,
    filters,
    refetch,
    activeRequests,
    activeRequestIndex,
    requestsProposedEndDates,
    exclusionShortlistId,
  ]);

  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [searchBy, setSearchBy] = React.useState<ListProsSearchBy>(
    ListProsSearchBy.FIRST_NAME
  );
  const [showUnavailableProConfirmationModal, setShowUnavailableProConfirmationModal] = React.useState({
    isOpen: false,
    proName: "",
    action: () => {}
  });

  if (loading || loadingCategories) {
    return <Loading />;
  }

  if (error || !data || categoriesError) {
    return <RequestFailed />;
  }

  let columns: ColumnsType<ProListItem & { key: string }> = [
    {
      title: "Select Pros",
      render: (text, record) => {
        return (
          <Checkbox
            size='lg'
            colorScheme='blue'
            borderColor={"blue.500"}
            isChecked={shortlistedProsPerRequest[`${activeRequestIndex}`]?.some(
              (pro) => pro.id === record.id
            )}
            onChange={() => {
              const updatedShortlistedPros = { ...shortlistedProsPerRequest };

              if (!updatedShortlistedPros[activeRequestIndex]) {
                updatedShortlistedPros[activeRequestIndex] = [];
              }

              const currentSelection =
                updatedShortlistedPros[activeRequestIndex];
              const existingIndex = currentSelection.findIndex(
                (pro) => pro.id === record.id
              );

              if (existingIndex > -1) {
                // Remove the record if it's already in the array
                updatedShortlistedPros[activeRequestIndex] = [
                  ...currentSelection.slice(0, existingIndex),
                  ...currentSelection.slice(existingIndex + 1),
                ];
              } else {
                // Add the record if it's not in the array
                // Show confirmation modal if pro is not available
                if(!!record.isAvailable === false) {
                  const selectShortlistedPro = () => {
                    updatedShortlistedPros[activeRequestIndex] = [
                      ...currentSelection,
                      record,
                    ];

                    setShortlistedProsPerRequest(updatedShortlistedPros);
                  }

                  return setShowUnavailableProConfirmationModal({
                    isOpen: true,
                    proName: `${record.firstName} ${record.lastName}`,  
                    action: () => selectShortlistedPro()
                  });

                }
                updatedShortlistedPros[activeRequestIndex] = [
                  ...currentSelection,
                  record,
                ];
              }

              setShortlistedProsPerRequest(updatedShortlistedPros);
            }}
          />
        );
      },
      width: "10%",
      fixed: "left",
    },
    {
      title: "First Name",
      render: (text, record) => {
        return <p>{record.firstName}</p>;
      },
      key: ListProsSortBy.FIRST_NAME,
      width: "10%",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => 0,
    },
    {
      title: "Last Name",
      render: (text, record) => {
        return <p>{record.lastName}</p>;
      },
      key: ListProsSortBy.LAST_NAME,
      width: "10%",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => 0,
    },
    {
      title: "Email",
      render: (text, record) => {
        return <p>{record.email}</p>;
      },
      key: "email",
      width: "10%",
    },
    {
      title: "Phone Number",
      render: (text, record) => {
        return <p>{record.phoneNumber}</p>;
      },
      key: "phoneNumber",
      width: "10%",
    },
    {
      title: "Is Legacy",
      render: (text, record) => {
        return record.isLegacy ? (
          <Icon size='large' name='check circle' color='green' />
        ) : (
          <Icon size='large' name='remove circle' color='red' />
        );
      },
      key: "isLegacy",
      width: "12%",
      filters: [
        {
          text: "Yes",
          value: "TRUE",
        },
        {
          text: "No",
          value: "FALSE",
        },
      ],
      filteredValue: !!filters?.isLegacy
        ? [filters?.isLegacy ? "TRUE" : "FALSE"]
        : undefined,
      onFilter: (value, record) => {
        return true;
      },
    },
    {
      title: "Address",
      render: (text, record) => {
        if (!record.proApplication) return <p>N/A</p>;

        return (
          <p>{`${record.proApplication.address}, ${record.proApplication.stateOfResidence}`}</p>
        );
      },
      key: "address",
      width: "10%",
    },
    {
      title: "Onboarding Completed",
      render: (text, record) => {
        return !!record.onboardedAt ? (
          <Icon size='large' name='check circle' color='green' />
        ) : (
          <Icon size='large' name='remove circle' color='red' />
        );
      },
      key: "onboardingCompleted",
      width: "10%",
      filters: [
        {
          text: "Complete",
          value: "TRUE",
        },
        {
          text: "Not Complete",
          value: "FALSE",
        },
      ],
      filteredValue: filters?.onboardingCompleted?.length
        ? filters.onboardingCompleted
        : undefined,
      onFilter: (value, record) => {
        return true;
      },
    },
    {
      title: "Availability",
      render: (text, record) => {
        return record?.isAvailable ? (
          <div className="bg-green-400 rounded-full text-center p-1">AVAILABLE</div>
        ) : (
          <div className="bg-red-400 rounded-full text-center p-1">HAS CONFLICT</div>
        );
      },
      key: "hasJobScheduleConflict",
      width: "10%",
      filters: [
        {
          text: "Available",
          value: "AVAILABLE",
        },
        {
          text: "Has conflict",
          value: "HAS_CONFLICT",
        },
      ],
      filteredValue: filters?.hasJobScheduleConflict?.length
        ? filters.hasJobScheduleConflict
        : undefined,
      onFilter: (value, record) => {
        return true;
      },
    },
    {
      title: "Category",
      render: (text, record) => {
        if (!record.categories.length) {
          return <p>Not Set</p>;
        }

        return (
          <PillGroup maxCount={1}>
            {record.categories.map((category) => (
              <Pill key={category.id}>{category.proTitle}</Pill>
            ))}
          </PillGroup>
        );
      },
      key: "categoryIds",
      width: "10%",
      filters: categoriesData?.categories
        .filter(({ id }) => recruitmentRequestsCategoryIds?.includes(id))
        .map((category) => ({
          text: category.proTitle,
          value: category.id,
        })),
      filteredValue: filters?.categoryIds,
      onFilter: (value, record) => {
        return true;
      },
    },
    {
      title: "Trade",
      render: (text, record) => {
        if (!record.trades.length) {
          return <p>Not Set</p>;
        }

        return (
          <PillGroup maxCount={1}>
            {record.trades.map((trade) => (
              <Pill key={trade.id}>{trade.name}</Pill>
            ))}
          </PillGroup>
        );
      },
      key: "trade",
      width: "10%",
    },
  ];

  /* To hide filter components when showing only onboarded pros */
  if (onlyShortlistedProsShown) {
    columns = columns.map(
      (
        column: ColumnType<
          ProListItem & {
            key: string;
          }
        >
      ) => {
        const { filters, ...rest } = column;
        return rest;
      }
    );
  }

  const handleSearchDropdownChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setSearchBy(data.value as ListProsSearchBy);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setSearchQuery(data.value);
  };

  const handleSearch = () => {
    setSearch({
      query: searchQuery,
      by: searchBy,
    });
  };

  const handleRemoveSearch = () => {
    setSearch(undefined);
    setSearchQuery("");
  };

  const handleSortChange = (
    key: ListProsSortBy,
    direction?: "ascend" | "descend" | null
  ) => {
    setSort((prev) => {
      return {
        by: key,
        ascending: direction === "ascend",
      };
    });
  };

  const handleFilterChange = (key: keyof ListProsFilters, filters: any) => {
    setFilters((prev) => {
      return {
        ...prev,
        [key]: filters,
      };
    });
  };

  const removeFilter = (key: keyof ListProsFilters) => {
    setFilters((prev) => {
      return produce(prev, (draft) => {
        if (draft) {
          delete draft[key];
        }
      });
    });
  };

  const handleUpdateShortlistedPros = () => {
    onSelect({
      recruitmentId: recruitment?.id!,
      requestId: activeRequests?.at(activeRequestIndex)?.id!,
      proIds: shortlistedProsPerRequest[activeRequestIndex]?.map(
        (pro) => pro.id
      ),
    });
  };

  const handleTableChange: TableProps<Pro>["onChange"] = (
    pagination: TablePaginationConfig,
    filters,
    sorter
  ) => {
    console.log(filters, sorter);

    if (filters.isLegacy !== undefined) {
      const hasMultipleValues =
        Array.isArray(filters.isLegacy) && filters.isLegacy.length > 1;

      if (hasMultipleValues) {
        removeFilter("isLegacy");
      } else {
        filters.isLegacy === null
          ? removeFilter("isLegacy")
          : handleFilterChange(
              "isLegacy",
              filters.isLegacy[0] === "TRUE" ? true : false
            );
      }
    }

    if (filters.categoryIds !== undefined) {
      filters.categoryIds === null
        ? removeFilter("categoryIds")
        : handleFilterChange("categoryIds", filters.categoryIds);
    }

    if (filters.onboardingCompleted !== undefined) {
      filters.onboardingCompleted === null
        ? removeFilter("onboardingCompleted")
        : handleFilterChange(
            "onboardingCompleted",
            filters.onboardingCompleted
          );
    }

    if (filters.hasJobScheduleConflict !== undefined) {
      filters.hasJobScheduleConflict === null
        ? removeFilter("hasJobScheduleConflict")
        : handleFilterChange(
            "hasJobScheduleConflict",
            filters.hasJobScheduleConflict
          );
    }

    if (Array.isArray(sorter)) {
      return;
    } else {
      const { columnKey, order } = sorter;

      if (columnKey) {
        handleSortChange(columnKey as ListProsSortBy, order);
      }
    }
  };

  const hasMore = data?.listProsForRecruitment?.pageInfo?.hasNextPage || false;

  return (
    <Island>
      <UnavailableProConfirmationModal
        proName={showUnavailableProConfirmationModal.proName}
        isOpen={showUnavailableProConfirmationModal.isOpen}
        onClose={() => setShowUnavailableProConfirmationModal({
          isOpen: false,
          proName: "",
          action: () => {}
        
        })}
        selectPro={() => {
          showUnavailableProConfirmationModal.action();
          setShowUnavailableProConfirmationModal({
            isOpen: false,
            proName: "",
            action: () => {}
          });
        }}
      />
      <div className="flex justify-between gap-0 py-4">
        <div className="flex">
          <ChakraButton
            bgColor="#ED6E5C"
            color="white"
            loadingText="Submitting"
            colorScheme="red"
            variant="outline"
            _hover={{
              bgColor: "#ED6E5C",
              color: "white",
            }}
            _focus={{
              outline: "none",
              boxShadow: "none",
            }}
            onClick={handleUpdateShortlistedPros}
          >
            Update Shortlisted Pros
          </ChakraButton>
        </div>
        <div className="flex justify-between gap-4">
          <Input
            type="text"
            placeholder="Search by"
            value={searchQuery}
            onChange={handleSearchInputChange}
            action
          >
            <input />
            <Select
              options={searchOptions}
              value={searchBy}
              onChange={handleSearchDropdownChange}
            />
            <SemanticButton
              icon
              className="!bg-primary-500 !text-white"
              type="submit"
              onClick={handleSearch}
              loading={loading}
            >
              <Icon name="search" />
            </SemanticButton>
          </Input>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="onboarded-toggle" mb="0">
              Show only Selected Pros
            </FormLabel>
            <Switch
              onChange={(e) => setOnlyShortlistedProsShown(e.target.checked)}
            />
          </FormControl>
        </div>
      </div>

      {search?.query && (
        <div className="text-lg flex items-center gap-4 py-2">
          <p className="m-0">
            Search by{" "}
            <i>
              <strong>
                {searchOptions.find(({ value }) => search.by === value)?.text}
              </strong>
            </i>{" "}
            results for <strong>"{search.query}"</strong>
          </p>
          <Button size="small" type="link" onClick={handleRemoveSearch}>
            Remove Search
          </Button>
        </div>
      )}
      <div className="flex justify-end">
        {!onlyShortlistedProsShown && (
          <p>
            Showing {data?.listProsForRecruitment?.edges?.length || 0} results
          </p>
        )}
      </div>
      <CustomTable
        handleTableChange={handleTableChange}
        onRow={(record: any, index: any) => {
          return {};
        }}
        columns={columns}
        data={
          onlyShortlistedProsShown
            ? shortlistedProsPerRequest[activeRequestIndex]
            : extractItemsFromRelayStylePaginationResponse(
                data.listProsForRecruitment
              ).map((item) => {
                return {
                  key: item.id,
                  ...item,
                };
              }) || []
        }
        // scroll={{ x: 4000 }}
        loading={loading}
        hasMore={hasMore}
        fetchMore={() =>
          fetchMore({
            variables: {
              first: 10,
              after: data.listProsForRecruitment.pageInfo.endCursor,
              sort: sort,
              search: search,
              filters: filters,
              ...(activeRequests && {
                proposedSchedule: {
                  start: activeRequests.at(activeRequestIndex)
                    ?.proposedStartDate,
                  end: requestsProposedEndDates?.at(activeRequestIndex),
                },
              }),
              exclusionShortlistId: exclusionShortlistId,
            },
          })
        }
      />
    </Island>
  );
};
