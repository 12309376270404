import { FC, useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import {
	Box,
	Heading,
	Button,
	Modal,
	ModalHeader,
	ModalOverlay,
	ModalContent,
	FormControl,
	ModalBody,
	ModalCloseButton,
	FormLabel,
	Input,
	FormHelperText,
	Stack,
	Text,
} from "@chakra-ui/react";
import { Subscription, SubscriptionRequestCall } from "../../types";
import { components as LayoutComponents } from "../../components/layout";
import { AssignProToRequestCall } from "./AssignProToRequestCall";
import RequestFailed from "@/_components/RequestFailed";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { GET_SUBSCRIPTION_REQUEST_CALL } from "./constants";
import { get } from "http";
import { SET_SUBSCRIPTION_PRO_PAYMENT_AMOUNT } from "../subscription-request-payouts/constants";

const { CustomTable } = LayoutComponents;

interface RequestCallsProps {
	subscription: Subscription;
}

export const RequestCalls: FC<RequestCallsProps> = ({ subscription }) => {
	const { subscriptionRequestCalls } = subscription;

	const [selectedRequestCallId, setSelectedRequestCallId] = useState("");

	const [
		selectedRequestCallDateOfAssignment,
		setSelectedRequestCallDateOfAssignment,
	] = useState<Date>(subscription.createdAt);

	const [
		assignProToRequestCallIsOpen,
		setAssignProToRequestCallIsOpen,
	] = useState(false);

	const [
		inputProPaymentAmountIsOpen,
		setInputProPaymentAmountIsOpen,
	] = useState(false);

	const [setProPaymentAmount, setProPaymentAmountResult] = useMutation(
		SET_SUBSCRIPTION_PRO_PAYMENT_AMOUNT,
		{
			onCompleted: () => {
				closePaymentAmountLayover();
			},
		}
	);

	const [
		getSubscriptionScheduledRequest,
		getSubscriptionScheduledRequestResult,
	] = useLazyQuery<{
		subscriptionRequestCall: SubscriptionRequestCall;
	}>(GET_SUBSCRIPTION_REQUEST_CALL);

	const [paymentAmount, setPaymentAmount] = useState<number>(0);

	const proPaymentAmount = Math.floor(
		getSubscriptionScheduledRequestResult.data?.subscriptionRequestCall
			?.expectedProPaymentAmount ?? 0
	);

	useEffect(() => {
		if (getSubscriptionScheduledRequestResult.data) {
			setPaymentAmount(proPaymentAmount);
		}
	}, [getSubscriptionScheduledRequestResult.data]);

	const columns: ColumnsType<SubscriptionRequestCall> = [
		{
			title: "ID",
			render: (text, record) => {
				return <p>{record.id.substring(0, 8)}</p>;
			},
			width: "10%",
		},
		{
			title: "Assigned Pro",
			render: (text, record) => {
				return record.assignedPro ? (
					<p>{`${record.assignedPro?.firstName} ${record.assignedPro?.lastName}`}</p>
				) : (
					"None Assigned"
				);
			},
			key: "assignedPro",
			width: "25%",
		},
		{
			title: "Date of Assignment",
			render: (text, record) => {
				return <p>{new Date(record.dateOfAssignment).toLocaleDateString()}</p>;
			},
			key: "dateOfAssignment",
			width: "15%",
		},
		{
			title: "Created At",
			render: (text, record) => {
				return <p>{new Date(record.createdAt).toLocaleDateString()}</p>;
			},
			key: "createdAt",
			width: "15%",
		},
		{
			title: "Action",
			render: (text, record) => {
				return record.assignedPro === null ? (
					<Button
						colorScheme="primary"
						onClick={() => handleAssignPro(record.id, record.dateOfAssignment)}
					>
						Assign Pro
					</Button>
				) : record.assignedPro && !record.proPaymentAmount ? (
					<Button
						colorScheme="gray"
						onClick={() => openPaymentAmountLayover(record.id)}
					>
						Input Pro Payment Amount
					</Button>
				) : (
					<Button colorScheme="primary" disabled>
						Pro Assigned
					</Button>
				);
			},
			key: "action",
			width: "20%",
		},
	];

	const handleAssignPro = (callId: string, dateOfAssignment: Date) => {
		setSelectedRequestCallId(callId);

		setSelectedRequestCallDateOfAssignment(dateOfAssignment);

		setAssignProToRequestCallIsOpen(true);
	};

	const openPaymentAmountLayover = (callId: string) => {
		setSelectedRequestCallId(callId);

		getSubscriptionScheduledRequest({
			variables: {
				subscriptionRequestCallId: callId,
			},
		});

		if (getSubscriptionScheduledRequestResult.data?.subscriptionRequestCall) {
			setPaymentAmount(proPaymentAmount);
		}

		setInputProPaymentAmountIsOpen(true);
	};

	const closePaymentAmountLayover = () => {
		setPaymentAmount(0);
		setInputProPaymentAmountIsOpen(false);
	};

	const handleSetProPaymentAmount = (callId: string, paymentAmount: number) => {
		setProPaymentAmount({
			variables: {
				paymentAmount,
				subscriptionRequestCallId: callId,
			},
		});
	};

	return (
		<>
			<Stack>
				<CustomTable
					columns={columns}
					data={subscriptionRequestCalls.map((call) => ({
						...call,
						key: call.id,
					}))}
				/>

				<AssignProToRequestCall
					isOpen={assignProToRequestCallIsOpen}
					onClose={() => setAssignProToRequestCallIsOpen(false)}
					subscription={subscription}
					requestCallId={selectedRequestCallId}
					dateOfAssignment={selectedRequestCallDateOfAssignment}
				/>
				<Modal
					isOpen={inputProPaymentAmountIsOpen}
					onClose={closePaymentAmountLayover}
					isCentered
					size="md"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalCloseButton size={"sm"} />
						{getSubscriptionScheduledRequestResult.loading && (
							<ModalBody>
								<Text color="#003952">Loading...</Text>
							</ModalBody>
						)}
						{getSubscriptionScheduledRequestResult.error && (
							<RequestFailed
								errorMessage={
									getSubscriptionScheduledRequestResult.error.message
								}
							/>
						)}
						{getSubscriptionScheduledRequestResult.data && (
							<ModalBody mt={8}>
								{setProPaymentAmountResult.error && (
									<RequestFailed
										errorMessage={setProPaymentAmountResult.error.message}
										onRetry={setProPaymentAmountResult.reset}
									/>
								)}
								{!setProPaymentAmountResult.error && (
									<Stack>
										<FormControl isRequired mb={4}>
											<FormLabel>Payment Amount</FormLabel>
											<Input
												value={paymentAmount}
												onChange={(e) => {
													setPaymentAmount(Number(e.target.value));
												}}
											/>
											<FormHelperText>
												The amount to be paid to the pro handling this request
											</FormHelperText>
										</FormControl>
										<Button
											colorScheme="primary"
											isLoading={setProPaymentAmountResult.loading}
											isDisabled={!paymentAmount}
											onClick={() => {
												handleSetProPaymentAmount(
													selectedRequestCallId,
													paymentAmount
												);
											}}
											size="sm"
											alignSelf={"self-end"}
										>
											Submit
										</Button>
									</Stack>
								)}
							</ModalBody>
						)}
					</ModalContent>
				</Modal>
			</Stack>
		</>
	);
};
