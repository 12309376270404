import gql from 'graphql-tag';

export const UPDATE_ADMIN_ROLES = gql`
  mutation updateAdminRoles($id: String!, $roles: [String!]!) {
    updateAdminRoles(id: $id, roles: $roles) {
      id
      roles
    }
  }
`;
