import { useQuery } from "@apollo/client";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { formatRelativeTime } from "../../../helpers/helpers";

import { components as LayoutComponents } from "../../layout";
import { TransferStatus } from "../../../types";
import { LIST_REFUNDS } from "../graphql/query";
import { IRefund } from "../types";
import Island from "../../../_components/Island";

type FilterStatusType = TransferStatus | "all";

const { Trail, CustomTable, CustomLabel } = LayoutComponents;

const RefundRequests: FC = () => {
  const history = useHistory();

  const [filterStatus, setFilterStatus] = useState<FilterStatusType>("all");

  const listAllRefundRequests = useQuery(LIST_REFUNDS);

  const columns: ColumnsType<IRefund & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.id.substr(0, 8)}</p>;
      },
      width: "5%",
    },
    {
      title: "Order Code",
      render: (text, record) => {
        return <p>{record.order.orderCode}</p>;
      },
      width: "5%",
    },
    {
      title: "To",
      render: (text, record) => {
        return <p>{record.order.client.name}</p>;
      },
      width: "10%",
    },
    {
      title: "Amount",
      render: (text, record) => {
        return (
          <p>
            {record.transfer.amount ? `NGN ${record.transfer.amount}` : "N/A"}
          </p>
        );
      },
      width: "10%",
    },
    {
      title: "Status",
      render: (text, record) => {
        const color = {
          r: 0,
          g: 0,
          b: 0,
        };

        switch (record.transfer.status) {
          case TransferStatus.PENDING:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case TransferStatus.AWAITING_APPROVAL:
            color.r = 199;
            color.g = 156;
            color.b = 26;
            break;
          case TransferStatus.CONFIRMED:
            color.r = 86;
            color.g = 189;
            color.b = 102;
            break;
          case TransferStatus.FAILED:
            color.r = 244;
            color.g = 105;
            color.b = 86;
            break;

          default:
            break;
        }

        return (
          <CustomLabel
            color={color}
            text={record.transfer.status.toString().replace("_", " ")}
          />
        );
      },
      width: "10%",
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <p>{formatRelativeTime(new Date(record.createdAt).getTime())}</p>
        );
      },
      width: "10%",
    },
  ];

  return (
    <Island
      header={<Trail root='All Requests'></Trail>}
      text='Shows a list of all refund requests'
    >
      <div className='o-paymentRequest__tableControls'>
        <div className='m-paymentRequest__tableFilterWrapper'>
          Filter By Status:
          <select
            value={filterStatus}
            onChange={(e) => {
              setFilterStatus(e.target.value as FilterStatusType);
            }}
            className='a-paymentRequest__tableFilter'
            name=''
            id=''
          >
            <option value={"all"}>All</option>
            {Object.entries(TransferStatus).map(([key, value]) => {
              return (
                <option key={key} value={value}>
                  {key.replace("_", " ")}
                </option>
              );
            })}
          </select>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "48px",
            color: "#003952",
            cursor: "pointer",
          }}
          onClick={() => {
            listAllRefundRequests.refetch();
          }}
        >
          <p
            style={{
              margin: "0 10px 0 0",
              fontWeight: 600,
            }}
          >
            Refresh{" "}
          </p>
          <Icon
            name='refresh'
            style={{
              cursor: "pointer",
              height: "14px",
              lineHeight: "14px",
              margin: 0,
            }}
          ></Icon>
        </div>
      </div>

      <CustomTable
        onRow={(record: any, index: any) => {
          return {
            onClick: () => {
              history.push("/refunds/requests/" + record.id);
            },
          };
        }}
        columns={columns}
        data={listAllRefundRequests.data?.listRefundRequests.data
          .filter(({ transfer: { status } }: IRefund) => {
            if (filterStatus === "all") {
              return true;
            }

            return status === (filterStatus as TransferStatus);
          })
          .map((refund: IRefund) => {
            return {
              key: refund.id,
              ...refund,
            };
          })}
        loading={listAllRefundRequests.loading}
        handleTableChange={() => {}}
      ></CustomTable>
    </Island>
  );
};

export default RefundRequests;
