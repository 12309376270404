import { Box, Center, Grid, GridItem, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Pro } from "../../types";
import { TradeDetails } from "./trade-details";
import { TradesList } from "./trades-list";

interface TradesProps {
  pro: Pro;
}

export const Trades = ({ pro }: TradesProps) => {
  const [selectedTradeId, setSelectedTradeId] = useState<string>();

  const handleTradeClick = (tradeId: string) => {
    setSelectedTradeId(tradeId);
  };

  return (
    <Grid templateColumns='repeat(5, 1fr)' gap={6}>
      <GridItem colSpan={2}>
        <TradesList
          pro={pro}
          onSelectTrade={handleTradeClick}
          selectedTradeId={selectedTradeId}
        />
      </GridItem>
      <GridItem colSpan={3}>
        <Box
          {...(!selectedTradeId && {
            minH: "lg",

            bg: "gray.50",
          })}
          width='full'
          borderWidth={1}
          rounded='md'
        >
          {selectedTradeId ? (
            <TradeDetails
              trade={pro.trades.find((trade) => trade.id === selectedTradeId)!}
            />
          ) : (
            <Center minH='lg'>
              <Text fontSize='lg'>Select a trade to view its levels</Text>
            </Center>
          )}
        </Box>
      </GridItem>
    </Grid>
  );
};
