import { useLazyQuery, useQuery } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { Dropdown, Icon, Message } from "semantic-ui-react";
import { GET_ACCOUNT_DETAILS, LIST_BANKS } from "../../../graphql/query";

import { components as LayoutComponents } from "../../layout";

const { CustomInput } = LayoutComponents;

const RefundClientAccountForm: FC<{
  setValidAccountNumber: (
    name: string,
    accountNumber: string,
    bankCode: string
  ) => void;
}> = ({ setValidAccountNumber }) => {
  const getBankListResponse = useQuery(LIST_BANKS);

  const [selectedBank, setSelectedBank] = useState<any>();
  const [accountNumber, setAccountNumber] = useState<string>("");

  const [getAccountDetails, getAccountDetailsResponse] = useLazyQuery(
    GET_ACCOUNT_DETAILS,
    {
      onCompleted: (data) => {
        setValidAccountNumber(
          data.getBankAccountInformation.data.accountName,
          accountNumber,
          selectedBank
        );
      },
      onError: () => {
        setValidAccountNumber("", "", "");
      },
    }
  );
  useEffect(() => {
    if (accountNumber.length === 10) {
      getAccountDetails({
        variables: {
          input: {
            accountNumber,
            bankCode: selectedBank,
          },
        },
      });
    } else {
      setValidAccountNumber("", "", "");
    } // eslint-disable-next-line
  }, [accountNumber, selectedBank]);

  useEffect(() => {
    if (getAccountDetailsResponse.loading) {
      setValidAccountNumber("", "", "");
    } // eslint-disable-next-line
  }, [getAccountDetailsResponse.loading]);

  if (getBankListResponse.error) {
    return (
      <Message negative>
        <Message.Content>{getBankListResponse.error.message}</Message.Content>
      </Message>
    );
  }

  if (getBankListResponse.loading) {
    return <div>loading...</div>;
  }

  const banks = getBankListResponse.data?.listBanks.data.map(
    ({ name, code }: { name: string; code: string }) => {
      return {
        key: code,
        value: code,
        text: name,
      };
    }
  );

  const clientAccountName =
    getAccountDetailsResponse.data?.getBankAccountInformation.data.accountName;

  return (
    <div className="o-refundClientAccountForm__container">
      <div className="m-refundClientAccountForm__column">
        <Dropdown
          className="a-refundClientAccountForm__dropdown"
          placeholder="Select Bank"
          search
          selection
          options={banks}
          value={selectedBank}
          onChange={(e, data) => {
            setSelectedBank(data.value);
          }}
        />
        <CustomInput
          label="Account Number"
          value={accountNumber}
          onChange={(value) => {
            setAccountNumber(value);
          }}
          maxLength={10}
          placeholder="Enter Account Number"
          disabled={!selectedBank}
          required
        />
      </div>
      <Icon size="large" name="long arrow alternate right"></Icon>
      <div className="m-refundClientAccountForm__column">
        <div className="m-refundClientAccountForm__accountInformation">
          {accountNumber.length === 10 && clientAccountName ? (
            <>
              <h4 className="a-refundClientAccountForm__text">
                {clientAccountName}
              </h4>
              <Icon name="check circle" color="green"></Icon>
            </>
          ) : (
            <>
              <h4 className="a-refundClientAccountForm__text -disabled -italics">
                Unassigned
              </h4>
              {getAccountDetailsResponse.loading && (
                <Icon name="spinner" loading></Icon>
              )}
              {getAccountDetailsResponse.error && (
                <Icon
                  data-testid="invalid-account-details"
                  name="remove circle"
                  color="red"
                ></Icon>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RefundClientAccountForm;
