import { gql, useMutation } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { ProTradeRequirement } from "../../common/types/pro-trade-requirement";
import { ScoringTemplateField } from "../../common/types/trade";
import RequestFailed from "../../_components/RequestFailed";

const ADD_SCORES = gql`
  mutation AddProTradeRequirementResult(
    $id: String!
    $scores: [Int!]!
    $passed: Boolean!
  ) {
    addProTradeRequirementResult(
      proTradeRequirementId: $id
      scores: $scores
      passed: $passed
    ) {
      id
      results {
        attemptId
        passed
        scores
        submittedAt
      }
    }
  }
`;

export interface AddResultProps {
  proTradeRequirement: ProTradeRequirement;
  template?: ScoringTemplateField[];
  onClose: () => void;
}

export const AddResult = ({
  proTradeRequirement,
  template,
  onClose,
}: AddResultProps) => {
  const [
    addScores,
    { loading: addScoresLoading, error: addScoresError },
  ] = useMutation(ADD_SCORES, {
    onCompleted: () => {
      onClose();
    },
  });

  const [scores, setScores] = useState<string[]>(
    new Array(template?.length || 1).fill("")
  );

  const [status, setStatus] = useState<"passed" | "failed">();

  const [showErrors, setShowErrors] = useState<boolean>(false);

  const handleAddScore = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const newScores = [...scores];
      newScores[index] = e.target.value;
      setScores(newScores);
    };
  };

  const handleStatusChange = (value: string) => {
    setStatus(value as "passed" | "failed");
  };

  const handleSubmit = () => {
    for (let score of scores) {
      if (isNaN(parseInt(score))) {
        setShowErrors(true);
        return;
      }
    }

    addScores({
      variables: {
        id: proTradeRequirement.id,
        scores: scores.map((score) => parseInt(score)),
        passed: status === "passed",
      },
    });
  };

  if (addScoresError)
    return <RequestFailed errorMessage={addScoresError.message} />;

  return (
    <VStack alignItems='end'>
      {template ? (
        template.map((field, index) => {
          return (
            <FormControl
              isInvalid={showErrors && isNaN(parseInt(scores[index]))}
            >
              <FormLabel>{field.name}</FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  value={scores[index]}
                  onChange={handleAddScore(index)}
                />
                <InputRightAddon children={`/${field.maximumScore}`} />
              </InputGroup>
            </FormControl>
          );
        })
      ) : (
        <FormControl>
          <FormLabel>Overall</FormLabel>
          <Input type='text' value={scores[0]} onChange={handleAddScore(0)} />
        </FormControl>
      )}
      <FormControl isInvalid={showErrors && !status}>
        <RadioGroup onChange={handleStatusChange} value={status}>
          <Stack direction='column'>
            <Radio value='passed'>Passed</Radio>
            <Radio value='failed'>Failed</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      <ButtonGroup>
        <Button colorScheme='red' onClick={onClose}>
          Cancel
        </Button>
        <Button
          colorScheme='green'
          isLoading={addScoresLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </ButtonGroup>
    </VStack>
  );
};
