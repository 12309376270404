import React, { FC } from "react";
import {
  SearchOutlined,
  BellOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Badge, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { firebase, hooks } from "../../../firebase";
import { STRINGS } from "../constants";

const { LOGOUT, COMPANY_NAME } = STRINGS;
const { useSession } = hooks;

export const MainNavigation: FC = () => {
  const history = useHistory();
  const { user } = useSession();

  return (
    <nav className="o-mainNavigation__wrapper">
      <div className="a-mainNavigation__logo">
        <h2>{COMPANY_NAME}</h2>
      </div>
      <div className="m-mainNavigation__actions">
        <SearchOutlined
          onClick={() => {
            history.push("/search");
          }}
        />
        <Badge count={0}>
          <BellOutlined
            onClick={() => {
              history.push("/notifications");
            }}
          />
        </Badge>
        <Popover
          content={
            <div className="m-mainNavigation__popover">
              <div
                className="m-mainNavigation__button"
                onClick={() => {
                  firebase.auth().signOut();
                }}
              >
                <p>{LOGOUT}</p>
              </div>
            </div>
          }
        >
          <div className="a-mainNavigation__account">
            <p>Hi, {user?.displayName}</p>
            <CaretDownOutlined
              style={{
                fontSize: "12px",
                marginLeft: "10px",
              }}
            />
          </div>
        </Popover>
        <div className="m-mainNavigation__avatarWrapper">
          <div className="a-mainNavigation__avatar"></div>
        </div>
      </div>
    </nav>
  );
};
