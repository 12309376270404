import React from "react";
import { Pro } from "../../../../types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
} from "@chakra-ui/react";

interface FullTimeSalaryModalProps {
  pro: Pro;
  handleAcceptShortlistPro: (proId: string, monthlyGrossSalary: number) => void;
  onClose: () => void;
  isOpen: boolean;
}

export const FullTimeSalaryModal: React.FC<FullTimeSalaryModalProps> = ({
  pro,
  isOpen,
  onClose,
  handleAcceptShortlistPro,
}) => {
  const [fullTimeGrossSalary, setFullTimeGrossSalary] = React.useState("");

  return (
    <>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Set ${pro.firstName} ${pro.lastName}'s  Monthly Gross Salary`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel fontSize={"sm"} htmlFor="email" color="primary.500">
                Salary
              </FormLabel>
              <InputGroup>
                <Input
                  id="monthly-gross-salary"
                  type={"number"}
                  fontSize={"sm"}
                  value={fullTimeGrossSalary}
                  onChange={({ target: { value } }) =>
                    setFullTimeGrossSalary(!isNaN(Number(value)) ? value : "")
                  }
                />
              </InputGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              isDisabled={!fullTimeGrossSalary}
              onClick={() => {
                handleAcceptShortlistPro(pro.id, Number(fullTimeGrossSalary));
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
