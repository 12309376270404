import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import TaskSelector from '@laborhack/task-selector';
import Button from '@laborhack/custom-button';

import { CatServiceSelectors } from '../../../redux/category-service.slice';
import { Location, ObjectOf, WithoutId } from '../../../types';
import Cart from './components/Cart';
import styles from './styles.module.scss';
import produce from 'immer';
import { nanoid } from '@reduxjs/toolkit';
import { useMutation } from '@apollo/client';
import { ADD_JOBS_TO_ORDER } from '../_graphql/mutation';

const {
  selectCategories,
  selectSubCategories,
  selectTasks,
} = CatServiceSelectors;

export interface EditOrderProps {
  onClose: () => void;
  locations: Location[];
  orderId: string;
}

export const EditOrder: FC<EditOrderProps> = ({
  onClose,
  locations,
  orderId,
}) => {
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const tasks = useSelector(selectTasks);

  const [cartOpen, setCartOpen] = useState<boolean>(false);

  const [cart, setCart] = useState<ObjectOf<number>>({});
  const [category, setCategory] = useState<string>();
  
  const defaultLocation: string = locations[0]?.id || '';
  const [location, setLocation] = useState<string>(defaultLocation);
  const [customLocations, setCustomLocations] = useState<Location[]>([]);
  const [customItems, setCustomItems] = useState<
    ObjectOf<{
      description: string;
      name: string;
      category: string;
      count: number;
    }>
  >({});

  const allLocations: ObjectOf<Location> = {};

  [...locations, ...customLocations].forEach((item) => {
    allLocations[item.id] = item;
  });

  const defaultItemsCount = Object.entries(cart).filter(([, count]) => count)
    .length;

  const customItemsCount = Object.entries(customItems).filter(
    ([id, { count }]) => count
  ).length;

  const cartIsEmpty = !defaultItemsCount && !customItemsCount;

  const handleAddCustomLocation = (payload: WithoutId<Location>) => {
    setCustomLocations((prev) => {
      const nextId = prev.length.toString();
      return produce(prev, (draft) => {
        draft.push({ ...payload, id: nextId });
      });
    });

    setLocation((setCustomLocations.length - 1).toString());
  };

  const handleChangeLocation = (id: string) => {
    setLocation(id);
  };

  const handleAddCustomItem = (
    description: string,
    name: string,
    category: string
  ) => {
    const item = {
      description,
      name,
      category,
      count: 1,
    };

    setCustomItems((prev) => {
      return produce(prev, (draft) => {
        draft[nanoid()] = item;
      });
    });
  };

  const handleChange = ({
    cart,
    categoryInViewId,
  }: {
    cart: ObjectOf<number>;
    categoryInViewId?: string;
  }) => {
    console.log(cart, categoryInViewId);
    setCart(cart);
    setCategory(categoryInViewId);
  };

  const handleCartChange = (id: string, count: number, isCustom: boolean) => {
    if (isCustom) {
      setCustomItems((prev) => {
        return produce(prev, (draft) => {
          draft[id].count = count;
        });
      });
    } else {
      setCart((prev) => ({ ...prev, [id]: count }));
    }
  };

  const [addJobsToOrder, { loading, data }] = useMutation(ADD_JOBS_TO_ORDER, {
    onCompleted: () => {
      onClose();
    },
  });

  const handleAddToOrder = () => {
    const customLocation = customLocations[Number(location)];

    const isCustomLocation = !!customLocation;

    const cartItems: {
      task?: {
        id: string;
        categoryId: string;
      };
      locationId: string;
      custom?: {
        name: string;
        description: string;
        categoryId: string;
      };
    }[] = [];

    Object.entries(cart).forEach(([id, count]) => {
      for (let i = 0; i < count; i++) {
        cartItems.push({
          locationId: location,
          task: {
            id,
            categoryId: tasks[id].categoryId,
          },
        });
      }
    });

    Object.values(customItems).forEach(
      ({ description, name, category, count }) => {
        for (let i = 0; i < count; i++) {
          cartItems.push({
            locationId: location,
            custom: {
              name,
              description,
              categoryId: category,
            },
          });
        }
      }
    );

    let jobLocationData: {
      id?: string;
      scheduledDate: Date;
      customLocation?: WithoutId<Location>;
    };

    console.log(customLocation);

    if (isCustomLocation) {
      const { id, ...rest } = customLocation;
      jobLocationData = {
        customLocation: rest,
        scheduledDate: new Date(),
      };
    } else {
      jobLocationData = {
        id: location,
        scheduledDate: new Date(),
      };
    }

    addJobsToOrder({
      variables: {
        orderId,
        payload: {
          cartItems,
          location: jobLocationData,
        },
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Button
          variant="basic"
          disabled={!cartOpen}
          onClick={() => setCartOpen(false)}
        >
          Back to Selector
        </Button>
        <div className={styles.actions}>
          {!cartOpen && (
            <Button onClick={() => setCartOpen(true)}>View Cart</Button>
          )}
          <Button
            className={styles.btn}
            disabled={cartIsEmpty || !location}
            variant="success"
            onClick={handleAddToOrder}
            loading={loading}
          >
            Add to order
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        {cartOpen ? (
          <Cart
            selectedLocation={location}
            onSelect={handleChangeLocation}
            tasks={tasks}
            defaultItems={cart}
            customItems={customItems}
            onChange={handleCartChange}
            onAddCustomLocation={handleAddCustomLocation}
            locations={allLocations}
          />
        ) : (
          <TaskSelector
            categories={categories}
            subCategories={subCategories}
            tasks={tasks}
            onChange={handleChange}
            cart={cart}
            categoryInView={category}
            onAddCustomTask={handleAddCustomItem}
          />
        )}
      </div>
    </div>
  );
};
