import React, { FC } from 'react';
import { AssignmentTableRow } from '../../../types';
import { AssignmentCard } from '../AssignmentCard/AssignmentCard';

import styles from './styles.module.scss';

export interface AssignmentCardListProps {
  target?: string;
  sources: string[];
  assignments: AssignmentTableRow[];
  onClick: (id: string) => void;
}

export const AssignmentCardList: FC<AssignmentCardListProps> = ({
  assignments,
  onClick,
  target,
  sources,
}) => {
  return (
    <div>
      <div className={styles.assignmentCardTitle}>
        <div className={styles.toggleWrapper}></div>
        <div className={styles.category}>
          <p>Category</p>
        </div>
        <div className={styles.pro}>
          <p>Assigned Pro</p>
        </div>
        <div className={styles.location}>
          <p>Location</p>
        </div>
        <div className={styles.inspection}>
          <p>Inspection Fee</p>
        </div>
        <div className={styles.status}>
          <p>Status</p>
        </div>
        <div className={styles.selectedPreview}></div>
      </div>
      {assignments.map((assignment) => {
        let selected: 'target' | 'source' | undefined;

        if (assignment.id === target) {
          selected = 'target';
        }

        if (sources.find((id) => id === assignment.id)) {
          selected = 'source';
        }

        return (
          <AssignmentCard
            key={assignment.id}
            assignment={assignment}
            selected={selected}
            onClick={() => onClick(assignment.id)}
          />
        );
      })}
    </div>
  );
};
