import React, { FC, Key, useContext, useState } from "react";
import { components as LayoutComponents } from "../../layout";
import {
  useParams,
  useHistory,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { Progress, Icon, Message, Modal, Menu } from "semantic-ui-react";
import { STRINGS } from "../constants";
import {
	Job,
	TradesmanAssignment,
	Order,
	JobProgress,
	TransactionStatus,
	InvoiceDetails,
	ObjectOf,
	Location,
	InvoiceDiscount,
} from "../../../types";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
	GET_INVOICE,
	GET_ORDER_INVOICES,
	GET_SINGLE_ORDER_QUERY,
	GET_TRADESMAN_ASSIGNMENTS,
} from "../graphql/query";
import {
	CatServiceSelectors,
	AssignmentActions,
	AssignmentSelectors,
} from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { Assignments } from "./Assignments";
import { CANCEL_ORDER } from "../graphql/mutation";
import OrderInvoice from "./OrderInvoice";
import { Alert } from "antd";
import { LIST_REFUNDS } from "../../refund/graphql/query";
import { IRefund } from "../../refund/types";
import { TransferStatus } from "../../../types";
import Island from "../../../_components/Island";
import Button from "../../../_components/Button";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { IInvoice } from "../../../types";
import {
	CANCEL_INVOICE,
	CREATE_MATERIALS_INVOICE,
	EDIT_MATERIALS_INVOICE,
	SEND_INVOICE,
} from "../../../pages/orders/_graphql/mutation";
import { Confirmation } from "../../../_components/Confirmation/Confirmation";
import { LayoutContext } from "../../layout/LayoutContext";
import { ScrollToTopOnMount } from "../../../_components/ScrollToTop/ScrollToTopOnMount";
import {
	AssignmentTableRow,
	InvoiceTableRow,
	UnAssignedTableRow,
} from "../../../pages/orders/types";
import { assignmentsTableRenderer } from "../../../pages/orders/_components/assignmentsTableRenderer";
import { UnassignedTable } from "../../../pages/orders/_components/UnassignedTable";
import { Tab } from "../../../_components/Tab/Tab";
import { orderInvoicesTableRenderer } from "../../../pages/orders/_components/orderInvoicesTableRenderer";
import { EditMaterialsInvoice } from "../../../pages/orders/_components/EditMaterialsInvoice";
import { EditOrder } from "../../../pages/orders/edit/EditOrder";
import MergeAssignments from "../../../pages/orders/merge";
import { QuotationTab } from "../../../features/orders/quotation-tab";
import { ILocation } from "@laborhack/address-selector/lib/types";
import {
	GET_AVAILABLE_AUTO_APPLIED_DISCOUNT_FOR_CLIENT,
	GET_CLIENT_BY_EMAIL,
	GET_DISCOUNT_BALANCE_FOR_CLIENT_BY_DISCOUNT,
	GET_LOCATIONS,
} from "@/graphql/query";
import { InvoiceType, OrderUser } from "../types";

const { Trail } = LayoutComponents;
const { selectCategories } = CatServiceSelectors;
const { createReset } = AssignmentActions;
const { selectCreateAssignmentData } = AssignmentSelectors;
const { CLIENT_INFORMATION, NAME, EMAIL, PHONE_NUMBER } = STRINGS;

export const OrderDetail: FC = () => {
	const { orderCode } = useParams<{
		orderCode: string;
	}>();
	const { path } = useRouteMatch();
	const history = useHistory();
	const dispatch = useDispatch();
	const categories = useSelector(selectCategories);
	const createData = useSelector(selectCreateAssignmentData);
	const { toggles } = useContext(ApplicationContext);
	const { wrapperRef } = useContext(LayoutContext);

	const [confirmBoxOpen, setConfirmBoxOpen] = useState<boolean>(false);
	const [editOrderOpen, setEditOrderOpen] = useState<boolean>(false);

	const [changeMaterialsInvoiceMode, setChangeMaterialsInvoiceMode] = useState<
		"create" | "edit"
	>("create");

	const [jobIdToInvoiceDetailUpdate, setJobIdToInvoiceDetailUpdate] = useState<{
		[key: string]: InvoiceDetails;
	}>({});

	const [editInvoiceOpen, setEditInvoiceOpen] = useState(false);

	const [selectedCategory, setSelectedCategory] = useState<string>("");

	const [assignedSelectedRowKeys, setAssignedSelectedRowKeys] = useState<
		string[]
	>([]);

	const [invoiceSelectedRowKey, setInvoiceSelectedRowKey] = useState<Key>();

	const [
		assignedJobsSelectedRowKeys,
		setAssignedJobsSelectedRowKeys,
	] = useState<string[]>([]);

	const [unassignedSelectedRowKeys, setUnassignedSelectedRowKeys] = useState<
		string[]
	>([]);

	const [cancelOrderOpen, setCancelOrderOpen] = useState(false);

	const [
		cancelMaterialsInvoiceModalOpen,
		setCancelMaterialsInvoiceModalOpen,
	] = useState(false);

	const getOrderByCode = useQuery(GET_SINGLE_ORDER_QUERY, {
		variables: {
			code: orderCode,
		},
	});

	const order: Order = getOrderByCode.data?.getOrder;

	const {
		loading: getClientByEmailIsLoading,
		data: getClientByEmailData,
		refetch,
	} = useQuery<{ clientByEmail: OrderUser }>(GET_CLIENT_BY_EMAIL, {
		variables: { email: order?.client.email },
		onCompleted: (data) => {
			getClientLocations({ variables: { userId: data.clientByEmail.userId } });

			getAvailablAutoAppliedDiscountForClientMaterialInvoice({
				variables: {
					clientId: data.clientByEmail.id,
					invoiceType: InvoiceType.MATERIALS,
				},
			});
		},
	});

	const [
		getClientLocations,
		{ loading: getClientLocationsLoading, data: getClientLocationsData },
	] = useLazyQuery<{
		getClientLocations: { defaultLocationId: string; locations: Location[] };
	}>(GET_LOCATIONS, {
		fetchPolicy: "network-only",
	});

	const [
		getAvailablAutoAppliedDiscountForClientMaterialInvoice,
		{
			data: availableDiscountData,
			loading: availableDiscountLoading,
			error: availableDiscountError,
		},
	] = useLazyQuery<{
		availableAutoAppliedInvoiceDiscountByClientId: InvoiceDiscount;
	}>(GET_AVAILABLE_AUTO_APPLIED_DISCOUNT_FOR_CLIENT, {
		onCompleted: (data) => {
			data.availableAutoAppliedInvoiceDiscountByClientId?.id &&
				getDiscountBalance({
					variables: {
						discountId: data.availableAutoAppliedInvoiceDiscountByClientId.id,
						clientId: getClientByEmailData?.clientByEmail.id,
					},
				});
		},
	});

	const [
		getDiscountBalance,
		{
			data: discountBalanceData,
			loading: discountBalanceLoading,
			error: discountBalanceError,
		},
	] = useLazyQuery<{
		getDiscountBalanceForClientByDiscountId: number;
	}>(GET_DISCOUNT_BALANCE_FOR_CLIENT_BY_DISCOUNT);

	const listRefunds = useQuery(LIST_REFUNDS, {
		variables: {
			data: {
				order: order?.id,
			},
		},
	});

	const clearSelected = () => {
		setInvoiceSelectedRowKey(undefined);
		setAssignedSelectedRowKeys([]);
	};

	const getAssignments = useQuery(GET_TRADESMAN_ASSIGNMENTS, {
		variables: {
			orderId: order?.id,
		},
		notifyOnNetworkStatusChange: true,
		onCompleted: () => {
			if (createData.tradesman) {
				dispatch(createReset());
			}
		},
	});

	const getOrderInvoices = useQuery(GET_ORDER_INVOICES, {
		variables: {
			orderId: order?.id,
		},
	});

	const [getInvoice, getInvoiceResponse] = useLazyQuery(GET_INVOICE);

	const [
		sendInvoice,
		{
			loading: sendInvoiceLoading,
			data: sendInvoiceData,
			error: sendInvoiceError,
		},
	] = useMutation(SEND_INVOICE, {
		onCompleted: () => {
			getOrderInvoices.refetch();
			getAssignments.refetch();
			setConfirmBoxOpen(false);

			clearSelected();
		},
		onError: () => {
			setConfirmBoxOpen(false);
		},
	});

	const [
		cancelInvoice,
		{
			loading: cancelInvoiceLoading,
			data: cancelInvoiceData,
			error: cancelInvoiceError,
		},
	] = useMutation(CANCEL_INVOICE, {
		onCompleted: () => {
			getOrderInvoices.refetch();
			getAssignments.refetch();
			setCancelMaterialsInvoiceModalOpen(false);
			clearSelected();
		},
		onError: () => {
			setCancelMaterialsInvoiceModalOpen(false);
		},
	});

	const [
		cancelOrder,
		{
			loading: cancelOrderLoading,
			data: cancelOrderData,
			error: cancelOrderError,
		},
	] = useMutation(CANCEL_ORDER, {
		onCompleted: () => {
			setTimeout(() => {
				history.push("/orders/all");
			}, 1000);

			setCancelOrderOpen(false);
		},
		onError: () => {
			setCancelOrderOpen(false);
		},
	});

	const orderInvoices: IInvoice[] | undefined =
		getOrderInvoices.data?.getInvoicesByOrder;

	const hasPaidInvoice = !!orderInvoices?.find(
		(invoice) => invoice.hasAtLeastOnePayment
	);

	const [createMaterialsInvoice, createMaterialsInvoiceResponse] = useMutation(
		CREATE_MATERIALS_INVOICE,
		{
			onCompleted: (data) => {
				setEditInvoiceOpen(false);
				clearSelected();
				getAssignments.refetch();
			},
			onError: () => {},
		}
	);

	const [updateMaterialsInvoice, updateMaterialsInvoiceResponse] = useMutation(
		EDIT_MATERIALS_INVOICE,
		{
			onCompleted: (data) => {
				setEditInvoiceOpen(false);
				getAssignments.refetch();
			},
			onError: () => {},
		}
	);

	const availableAutoAppliedDiscount =
		availableDiscountData?.availableAutoAppliedInvoiceDiscountByClientId;
	const clientMarketingCampaign =
		getClientByEmailData?.clientByEmail?.marketingCampaign;
	const availableAutoAppliedDiscountBalance =
		discountBalanceData?.getDiscountBalanceForClientByDiscountId;

	if (
		getOrderByCode.loading ||
		getClientByEmailIsLoading ||
		getClientLocationsLoading ||
		availableDiscountLoading ||
		discountBalanceLoading
	) {
		return <h1>loading</h1>;
	}

	if (getOrderByCode.error) {
		return (
			<Message negative>
				<Message.Header>{getOrderByCode.error.message}</Message.Header>
			</Message>
		);
	}

	if (!listRefunds.data?.listRefundRequests) {
		return <h1>loading</h1>;
	}

	if (listRefunds.error) {
		return (
			<Message negative>
				<Message.Header>{listRefunds.error.message}</Message.Header>
			</Message>
		);
	}

	if (availableDiscountError) {
		return (
			<Message negative>
				<Message.Header>{availableDiscountError.message}</Message.Header>
			</Message>
		);
	}

	if (discountBalanceError) {
		return (
			<Message negative>
				<Message.Header>{discountBalanceError.message}</Message.Header>
			</Message>
		);
	}

	if (!getAssignments.data?.getTradesmanAssignments) {
		return <h1>loading</h1>;
	}

	if (getAssignments.error) {
		return (
			<Message negative>
				<Message.Header>{getAssignments.error.message}</Message.Header>
			</Message>
		);
	}

	const assignments: TradesmanAssignment[] =
		getAssignments.data.getTradesmanAssignments.data;

	// Get all unassigned jobs in this order
	const assignedJobs = assignments.reduce((acc: Job[], curr) => {
		return [...acc, ...curr.jobs];
	}, []);

	const assignedJobIds = new Set(assignedJobs.map(({ id }) => id));

	const unassignedJobs = order.jobs.filter(({ id }) => !assignedJobIds.has(id));

	// get total number of unique category to location pairs

	const locationCategory = new Set<string>();

	const locations: Record<string, ILocation> =
		getClientLocationsData?.getClientLocations.locations?.reduce(
			(accumulator, location) => {
				accumulator[location.id] = location;
				return accumulator;
			},
			{} as Record<string, ILocation>
		) || {};

	order.jobs.forEach(({ task, custom, contact: { location } }) => {
		locationCategory.add(
			`${location.id}${task?.categoryId || custom?.categoryId}`
		);

		locations[location.id] = location;
	});

	const assignmentsLeft = locationCategory.size - assignments.length;

	const isAssignable =
		(toggles && toggles.enforce_order_manual_processing) ||
		assignmentsLeft >= 1;

	// Restructure assignments array for table
	const assigned: AssignmentTableRow[] = assignments
		.filter(({ jobs }) => jobs.length)
		.map(
			({
				jobs,
				tradesman,
				progress,
				invoice,
				id,
				inspectionPayment,
				inspectionWaived,
			}) => {
				const job = jobs[0];

				return {
					inspection: inspectionWaived
						? "waived"
						: inspectionPayment?.status === TransactionStatus.SUCCESS
						? "paid"
						: undefined,
					invoice: invoice,
					sent: invoice && !invoice.canceled,
					paid: invoice?.isFullyPaid,
					category: {
						name:
							categories[job.task?.categoryId || job.custom?.categoryId || ""]
								.name,
						id: job.task?.categoryId || job.custom?.categoryId || "",
					},
					quoted: !jobs.find(({ invoiceDetails }) => {
						return (
							!invoiceDetails ||
							((!invoiceDetails.materials ||
								!invoiceDetails.materials.length) &&
								!invoiceDetails.labor)
						);
					}),
					taskCount: jobs.length,
					location: `${job.contact.location.area}, ${job.contact.location.location}`,
					tradesman,
					progress,
					jobs: jobs.map(({ invoiceDetails, ...rest }) => {
						return {
							...rest,
							invoiceDetails,
							finalCost: invoiceDetails
								? (invoiceDetails.labor || 0) +
								  (invoiceDetails.materials?.reduce((acc, curr) => {
										return acc + curr.quantity * curr.unitPrice;
								  }, 0) || 0)
								: 0,
						};
					}),
					id,
				};
			}
		);

	const justAssigned: AssignmentTableRow[] = assigned.filter(
		({ invoice }) => !invoice || invoice.canceled
	);

	const paidInvoices: InvoiceTableRow[] = [];

	const unpaidInvoices: InvoiceTableRow[] = [];

	const notSentInvoices: InvoiceTableRow[] = [];

	// combine assignments with the same invoice and push them to appropriate status
	const invoiceToAssignmentTableRows = assigned
		.filter(({ invoice }) => invoice && !invoice.canceled)
		.reduce<{
			[key: string]: {
				invoice: IInvoice;
				assignmentTableRows: AssignmentTableRow[];
			};
		}>((acc, current) => {
			const { invoice, ...rest } = current;

			if (!invoice) return acc;

			if (acc[invoice.id]) {
				acc[invoice.id].assignmentTableRows.push(rest);
			} else {
				acc[invoice.id] = {
					invoice,
					assignmentTableRows: [rest],
				};
			}

			return acc;
		}, {});

	Object.values(invoiceToAssignmentTableRows).forEach((row) => {
		// paid
		if (row.invoice.isFullyPaid) {
			paidInvoices.push(row);
			return;
		}

		// sent
		if (row.invoice.sent) {
			unpaidInvoices.push(row);
			return;
		}

		//not sent
		notSentInvoices.push(row);
	});

	const refundRequestDisabled =
		!assigned.length ||
		!assignments.find(({ invoice, jobs }) => {
			return invoice && invoice.hasAtLeastOnePayment && jobs.length;
		}) ||
		!!listRefunds.data?.listRefundRequests.data.find(
			({ transfer: { status } }: IRefund) => {
				return (
					status === TransferStatus.PENDING ||
					status === TransferStatus.AWAITING_APPROVAL
				);
			}
		);

	const assignedAsObject: {
		[x: string]: AssignmentTableRow;
	} = {};

	assigned.forEach((item) => {
		assignedAsObject[item.id] = item;
	});

	// Resturcture unassigned jobs for table
	const unassigned: {
		[key: string]: UnAssignedTableRow;
	} = {};

	unassignedJobs.forEach((job) => {
		const categoryId = job.custom?.categoryId || job.task?.categoryId || "";
		const locationString = `${job.contact.location.area}, ${job.contact.location.location}`;
		const key = categoryId + locationString;

		if (unassigned[key]) {
			const { category, taskCount, jobs, location } = unassigned[key];

			unassigned[key] = {
				jobs: [...jobs, job],
				taskCount: taskCount + 1,
				location,
				category,
			};
		} else {
			unassigned[key] = {
				location: locationString,
				jobs: [job],
				taskCount: 1,
				category: { name: categories[categoryId].name, id: categoryId },
			};
		}
	});

	const unassignedArray = Object.values(unassigned);

	const panes = [
		{
			title: "Not Assigned",
			count: unassignedArray.length,
			render: () => (
				<>
					<div className="m-assignmentPane__actions">
						<Button onClick={() => setEditOrderOpen(true)}>
							<Icon className="icon" name="add circle" /> Add Tasks
						</Button>
					</div>

					<UnassignedTable
						rows={unassignedArray}
						selectable={{
							type: "radio",
							selectedRowKeys: unassignedSelectedRowKeys,
							onSelect: (record, selected) => {
								if (!isAssignable) return;

								setUnassignedSelectedRowKeys((prev) => {
									return [`${record.location}${record.category.name}`];
								});

								setSelectedCategory(record.category.id);

								setAssignedJobsSelectedRowKeys(record.jobs.map(({ id }) => id));
							},
						}}
						selected={unassignedSelectedRowKeys[0]}
						nestedSelectable={{
							onSelectAll: (
								record: UnAssignedTableRow & { key: string },
								selected: boolean
							) => {
								if (!isAssignable) return;
								setAssignedJobsSelectedRowKeys((prev) => {
									if (selected) {
										return record.jobs.map(({ id }) => id);
									} else {
										return [record.jobs.map(({ id }) => id)[0]];
									}
								});
							},
							selectedRowKeys: assignedJobsSelectedRowKeys,
							onSelect: (
								record: UnAssignedTableRow & { key: string },
								jobRecord: Job & { key: string },
								selected: boolean
							) => {
								if (!isAssignable) return;
								if (
									record.jobs.find(
										({ id }) => assignedJobsSelectedRowKeys[0] === id
									)
								) {
									setAssignedJobsSelectedRowKeys((prev) => {
										if (selected) {
											return [...prev, jobRecord.id];
										} else {
											return prev.filter((key) => jobRecord.id !== key);
										}
									});
								} else {
									setSelectedCategory(record.category.id);
									setUnassignedSelectedRowKeys([record.key]);
									setAssignedJobsSelectedRowKeys([jobRecord.id]);
								}
							},
						}}
						orderCode={orderCode}
					/>
				</>
			),
		},
		{
			title: "Assigned",
			count: justAssigned.length,
			render: () => (
				<>
					<div className="m-assignmentPane__actions">
						<Button
							disabled={!assignedSelectedRowKeys.length}
							className="a-orderDetail__informationFieldButton mr-2"
							onClick={() => {
								setEditInvoiceOpen(true);
							}}
						>
							Create Invoice
						</Button>
						<Button
							onClick={() => {
								history.push(`/orders/${orderCode}/merge`);
							}}
						>
							<Icon className="icon" name="add circle" /> Merge Assignments
						</Button>
					</div>
					{assignmentsTableRenderer({
						assignments: justAssigned,
						orderCode,
						selectable: {
							selectedRowKeys: assignedSelectedRowKeys,
							onSelect: (record, selected) => {
								if (selected) {
									setChangeMaterialsInvoiceMode("create");
									setInvoiceSelectedRowKey(undefined);
									setAssignedSelectedRowKeys((prev) => {
										if (record.id) {
											return [...prev, record.id];
										} else {
											return prev;
										}
									});
								} else {
									setAssignedSelectedRowKeys((prev) => {
										return prev.filter((key) => record.id !== key);
									});
								}
							},
						},
						moveTo: history.push,
					})}
				</>
			),
		},
		{
			title: "Quotes",
			render: () => <QuotationTab order={order} />,
		},
		{
			title: "Not Sent",
			count: notSentInvoices.length,
			render: () => (
				<>
					<div className="m-assignmentPane__actions">
						<Button
							disabled={
								!invoiceSelectedRowKey || !assignedSelectedRowKeys.length
							}
							className="a-orderDetail__informationFieldButton mr-2"
							onClick={() => {
								setEditInvoiceOpen(true);
							}}
						>
							Edit Invoice
						</Button>
						<Button
							disabled={!(!!assignedSelectedRowKeys.length && sendable())}
							className="a-orderDetail__informationFieldButton mr-2"
							variant="success"
							onClick={() => {
								getInvoice({
									variables: {
										invoiceId: invoiceSelectedRowKey,
									},
								});
								setConfirmBoxOpen(true);
							}}
						>
							Send Invoice
						</Button>
						<Button
							disabled={
								!assignedSelectedRowKeys.length || atLeastOneSplitPaymentMade
							}
							className="a-orderDetail__informationFieldButton mr-2"
							variant="negative"
							onClick={() => {
								setCancelMaterialsInvoiceModalOpen(true);
							}}
						>
							Cancel Invoice
						</Button>
					</div>
					{orderInvoicesTableRenderer({
						invoiceRows: notSentInvoices,
						orderCode,
						selectable: {
							type: "radio",
							selectedRowKeys: invoiceSelectedRowKey
								? [invoiceSelectedRowKey]
								: [],
							onChange: (selectedRowKeys: Key[], selectedRows) => {
								setChangeMaterialsInvoiceMode("edit");
								setInvoiceSelectedRowKey(selectedRowKeys?.[0]);
								setAssignedSelectedRowKeys(
									selectedRows?.[0].assignmentTableRows.map(({ id }) => id)
								);
							},
						},
						moveTo: history.push,
					})}
				</>
			),
		},
		{
			title: "Not Paid",
			count: unpaidInvoices.length,
			render: () => (
				<>
					<div className="m-assignmentPane__actions">
						<Button
							disabled={
								!assignedSelectedRowKeys.length || atLeastOneSplitPaymentMade
							}
							className="a-orderDetail__informationFieldButton mr-2"
							variant="negative"
							onClick={() => {
								setCancelMaterialsInvoiceModalOpen(true);
							}}
						>
							Cancel Invoice
						</Button>
						<Button
							disabled={!(!!assignedSelectedRowKeys.length && sendable())}
							className="a-orderDetail__informationFieldButton"
							variant="success"
							onClick={() => {
								getInvoice({
									variables: {
										invoiceId: invoiceSelectedRowKey,
									},
								});
								setConfirmBoxOpen(true);
							}}
						>
							Send Invoice
						</Button>
					</div>
					{orderInvoicesTableRenderer({
						invoiceRows: unpaidInvoices,
						orderCode,
						selectable: {
							type: "radio",
							selectedRowKeys: invoiceSelectedRowKey
								? [invoiceSelectedRowKey]
								: [],
							onChange: (selectedRowKeys: Key[], selectedRows) => {
								setChangeMaterialsInvoiceMode("edit");
								setInvoiceSelectedRowKey(selectedRowKeys?.[0]);
								setAssignedSelectedRowKeys(
									selectedRows?.[0].assignmentTableRows.map(({ id }) => id)
								);
							},
						},
						moveTo: history.push,
					})}
				</>
			),
		},
		{
			title: "Paid",
			count: paidInvoices.length,
			render: () =>
				orderInvoicesTableRenderer({
					invoiceRows: paidInvoices,
					orderCode,
					moveTo: history.push,
				}),
		},
	];

	const selectedMaterialInvoice =
		!!assignedSelectedRowKeys.length &&
		assignments.find(({ id }) => assignedSelectedRowKeys[0] === id)?.invoice;

	const calculatedData: {
		finalCost: any;
		tasksCompleted: any;
		estimatedCost: any;
		paid: number[];
	} = {
		finalCost: 0,
		tasksCompleted: 0,
		estimatedCost: 0,
		paid: [],
	};

	assignments.forEach(({ progress }) => {
		if (progress === JobProgress.COMPLETED) {
			calculatedData.tasksCompleted++;
		}
	});

	const sendable = () => {
		if (!invoiceSelectedRowKey) return false;

		for (let key of assignedSelectedRowKeys) {
			if (!assignedAsObject[key].quoted) {
				return false;
			}
		}

		return true;
	};

	const atLeastOneSplitPaymentMade = invoiceSelectedRowKey
		? invoiceToAssignmentTableRows[invoiceSelectedRowKey]?.invoice
				.hasAtLeastOnePayment
		: undefined;

	return (
		<Switch>
			<Route path={path + "/merge"}>
				<MergeAssignments assignments={justAssigned} />
			</Route>
			<Route path={path.replace(":orderCode", orderCode) + "/assignments"}>
				<Assignments
					creationData={{
						order,
						jobs: assignedJobsSelectedRowKeys,
						selectedCategory,
					}}
					assignments={assignments}
					refetchAssignments={getAssignments.refetch}
				/>
			</Route>
			<Route path={path}>
				<Modal
					closeIcon
					style={{
						width: "95%",
					}}
					open={editOrderOpen}
					onClose={() => setEditOrderOpen(false)}
				>
					<EditOrder
						orderId={order.id}
						onClose={() => setEditOrderOpen(false)}
						locations={Object.values(locations)}
					/>
				</Modal>

				<Modal
					size="large"
					closeOnDimmerClick
					closeIcon
					open={confirmBoxOpen}
					onClose={() => setConfirmBoxOpen(false)}
				>
					<div className="o-sendInvoice__confirmBoxWrapper">
						<div className="o-sendInvoice__confirmBoxContainer">
							<h4 className="prompt">
								This sends an invoice for all checked assignments to this
								client.
								<br /> Are you sure?
							</h4>

							<div className="m-sendInvoice__confirmBoxAction">
								<Button
									className="confirmBoxButton"
									variant="negative"
									onClick={() => {
										setConfirmBoxOpen(false);
									}}
								>
									No
								</Button>
								<Button
									variant="success"
									className="confirmBoxButton"
									disabled={getInvoiceResponse.loading}
									loading={sendInvoiceLoading}
									onClick={() => {
										sendInvoice({
											variables: {
												invoiceId: invoiceSelectedRowKey,
											},
										});
									}}
								>
									Yes, Send it
								</Button>
							</div>
							<div
								style={{
									margin: "20px 0",
									overflow: "scroll",
									width: "100%",
									display: "flex",
									justifyContent: "center",
								}}
							>
								<OrderInvoice
									loading={getInvoiceResponse.loading}
									invoice={getInvoiceResponse.data?.getInvoiceById.data}
								/>
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					className="o-orderDetail__editInvoiceModal"
					size="fullscreen"
					closeIcon
					closeOnDimmerClick={false}
					onClose={() => {
						setEditInvoiceOpen(false);
					}}
					open={editInvoiceOpen}
				>
					<Modal.Content>
						<Modal.Description>
							<EditMaterialsInvoice
								changeMaterialsInvoice={
									changeMaterialsInvoiceMode === "create"
										? createMaterialsInvoice
										: updateMaterialsInvoice
								}
								changeMaterialsInvoiceResponse={
									changeMaterialsInvoiceMode === "create"
										? createMaterialsInvoiceResponse
										: updateMaterialsInvoiceResponse
								}
								assignments={assigned.filter(
									({ id }) =>
										!!assignedSelectedRowKeys.find((key) => id === key)
								)}
								setJobIdToInvoiceDetailUpdate={setJobIdToInvoiceDetailUpdate}
								jobIdToInvoiceDetailUpdate={jobIdToInvoiceDetailUpdate}
								invoice={
									invoiceSelectedRowKey
										? invoiceToAssignmentTableRows[invoiceSelectedRowKey]
												?.invoice
										: undefined
								}
								mode={changeMaterialsInvoiceMode}
								availableAutoAppliedDiscountBalance={
									availableAutoAppliedDiscountBalance
								}
								clientMarketingCampaign={clientMarketingCampaign}
							/>
						</Modal.Description>
					</Modal.Content>
				</Modal>

				{selectedMaterialInvoice && (
					<Confirmation
						loading={cancelInvoiceLoading}
						prompt="This cancels the existing invoice"
						onConfirm={() => {
							cancelInvoice({
								variables: { invoiceId: selectedMaterialInvoice.id },
							});
						}}
						onReject={() => {
							setCancelMaterialsInvoiceModalOpen(false);
						}}
						open={cancelMaterialsInvoiceModalOpen}
						size="small"
					/>
				)}

				<Confirmation
					loading={cancelOrderLoading}
					prompt="This cancels the order"
					onConfirm={() => {
						cancelOrder({
							variables: { orderId: order.id },
						});
					}}
					onReject={() => {
						setCancelOrderOpen(false);
					}}
					open={cancelOrderOpen}
					size="small"
				/>

				{sendInvoiceData && (
					<>
						<ScrollToTopOnMount parent={wrapperRef} />
						<Alert
							type="success"
							banner
							closable
							message={sendInvoiceData.sendInvoiceToClient.message}
						/>
					</>
				)}
				{cancelInvoiceData && (
					<>
						<ScrollToTopOnMount parent={wrapperRef} />
						<Alert
							type="success"
							banner
							closable
							message={"Invoice canceled successfully"}
						/>
					</>
				)}
				{cancelOrderData && (
					<>
						<ScrollToTopOnMount parent={wrapperRef} />
						<Alert
							type="success"
							banner
							closable
							message={"Order canceled successfully"}
						/>
					</>
				)}
				{sendInvoiceError && (
					<>
						<ScrollToTopOnMount parent={wrapperRef} />
						<Alert
							type="error"
							banner
							closable
							message={sendInvoiceError.message}
						/>
					</>
				)}
				{cancelInvoiceError && (
					<>
						<ScrollToTopOnMount parent={wrapperRef} />
						<Alert
							type="error"
							banner
							closable
							message={cancelInvoiceError.message}
						/>
					</>
				)}
				{cancelOrderError && (
					<>
						<ScrollToTopOnMount parent={wrapperRef} />
						<Alert
							type="error"
							banner
							closable
							message={cancelOrderError.message}
						/>
					</>
				)}

				<Island
					header={<Trail root="Order Code" child={orderCode} />}
					actions={[
						<Button
							key="refundButton"
							disabled={refundRequestDisabled}
							onClick={() => {
								history.push(`/refunds/create?orderId=${order.id}`);
							}}
						>
							Request a Refund
						</Button>,
						<Button
							variant="negative"
							key="cancelButton"
							disabled={hasPaidInvoice}
							onClick={() => {
								setCancelOrderOpen(true);
							}}
						>
							Cancel Order
						</Button>,
					]}
				>
					<Progress
						value={calculatedData.tasksCompleted}
						total={assignments.length}
						color={
							assignments.length > 0 &&
							calculatedData.tasksCompleted === assignments.length
								? "green"
								: "yellow"
						}
						progress="ratio"
					></Progress>
					<div className="o-orderDetail__information">
						<div className="m-orderDetail__informationWrapper">
							<h4>{CLIENT_INFORMATION}</h4>
							<div className="m-orderDetail__informationField">
								<h5>{NAME}</h5>
								<p className="-capitalize">{order?.client.name}</p>
							</div>
							<div className="m-orderDetail__informationField">
								<h5>{EMAIL}</h5>
								<p>{order?.client.email}</p>
							</div>
							<div className="m-orderDetail__informationField">
								<h5>{PHONE_NUMBER}</h5>
								<p>{order?.client.phoneNumber}</p>
							</div>
						</div>
					</div>

					<Tab
						menu={{ secondary: true, pointing: true }}
						onTabChange={(e, data) => {
							setAssignedSelectedRowKeys([]);
							setInvoiceSelectedRowKey(undefined);
						}}
						panes={panes.map(({ render, title, count }) => ({
							render,
							menuItem: (
								<Menu.Item key={title}>{`${title}${
									count !== undefined ? ` (${count})` : ""
								}`}</Menu.Item>
							),
						}))}
					/>
				</Island>
			</Route>
		</Switch>
	);
};;
