import React, { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AssignContract from "./assign-contract";
import EditContract from "./edit-contract";
import ViewContract from "./view-contract";

export const Contracts: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/assign/:id`}>
        <AssignContract />
      </Route>
      <Route path={`${path}/edit/:id`}>
        <EditContract />
      </Route>
      <Route path={`${path}/:id`}>
        <ViewContract />
      </Route>
    </Switch>
  );
};
