import React, { FC, useState } from 'react';
import Badge from '@laborhack/badge';
import clsx from 'clsx';

import styles from './styles.module.scss';
import { AssignmentTableRow } from '../../../types';
import { AssignmentCardDetails } from './AssignmentCardDetails';
import { Icon } from 'semantic-ui-react';
import { JobProgress } from '../../../../../types';
import { components as Layout } from '../../../../../components/layout';

const { CustomLabel } = Layout;

export interface AssignmentCardProps {
  selected?: 'source' | 'target';
  assignment: AssignmentTableRow;
  onClick: () => void;
}

export const AssignmentCard: FC<AssignmentCardProps> = ({
  selected,
  assignment,
  onClick,
}) => {
  const [open, setOpen] = useState(false);

  let progressIndex = {
    color: {
      r: 0,
      g: 0,
      b: 0,
    },
    text: 'Not Started',
  };

  switch (assignment.progress) {
    case JobProgress.NOT_STARTED:
      progressIndex.color.r = 244;
      progressIndex.color.g = 105;
      progressIndex.color.b = 96;
      progressIndex.text = 'Not Started';
      break;

    case JobProgress.PRE_INSPECTION:
      progressIndex.color.r = 199;
      progressIndex.color.g = 156;
      progressIndex.color.b = 26;
      progressIndex.text = 'Pre Inspection';
      break;

    case JobProgress.FINAL_QUOTE:
      progressIndex.color.r = 199;
      progressIndex.color.g = 156;
      progressIndex.color.b = 26;
      progressIndex.text = 'Final Quote';
      break;

    case JobProgress.MATERIALS_PURCHASED:
      progressIndex.color.r = 199;
      progressIndex.color.g = 156;
      progressIndex.color.b = 26;
      progressIndex.text = 'Materials Purchased';
      break;

    case JobProgress.CLIENT_INSPECTION:
      progressIndex.color.r = 199;
      progressIndex.color.g = 156;
      progressIndex.color.b = 26;
      progressIndex.text = 'Client Inspection';
      break;

    case JobProgress.COMPLETED:
      progressIndex.color.r = 86;
      progressIndex.color.g = 189;
      progressIndex.color.b = 102;
      progressIndex.text = 'Completed';
      break;
    default:
      progressIndex.color.r = 244;
      progressIndex.color.g = 105;
      progressIndex.color.b = 96;
      progressIndex.text = 'Not Started';
      break;
  }

  const sourceBadge = (
    <Badge type="basic" size="small">
      source
    </Badge>
  );

  const targetBadge = (
    <Badge type="success" size="small">
      target
    </Badge>
  );

  return (
    <div
      className={clsx(
        styles.assignmentCard,
        selected && styles.selected,
        selected === 'source' && styles.source,
        selected === 'target' && styles.target
      )}
      onClick={onClick}
    >
      <div className={styles.summary}>
        <div
          className={clsx(styles.column, styles.toggleWrapper)}
          onClick={(e: any) => {
            e.stopPropagation();
            setOpen((prev) => !prev);
          }}
        >
          <Icon
            name={open ? 'chevron circle up' : 'chevron circle down'}
            className={styles.toggle}
          />
        </div>
        <div className={clsx(styles.column, styles.category)}>
          <p>{assignment.category.name}</p>
        </div>
        <div className={clsx(styles.column, styles.pro)}>
          <p>{`${assignment.tradesman.firstName} ${assignment.tradesman.lastName}`}</p>
        </div>
        <div className={clsx(styles.column, styles.location)}>
          <p>{assignment.location}</p>
        </div>
        <div className={clsx(styles.column, styles.inspection)}>
          {assignment.inspection === 'waived' && <p>waived</p>}
          {assignment.inspection === 'paid' && (
            <Icon
              name="check circle outline"
              className={clsx(styles.icon, styles.success)}
            />
          )}
          {!assignment.inspection && (
            <Icon
              name="remove circle"
              className={clsx(styles.icon, styles.fail)}
            />
          )}
        </div>
        <div className={clsx(styles.column, styles.status)}>
          <CustomLabel text={progressIndex.text} color={progressIndex.color} />
        </div>
        <div className={clsx(styles.column, styles.selectedPreview)}>
          {selected === 'source' && sourceBadge}
          {selected === 'target' && targetBadge}
        </div>
      </div>

      {open && <AssignmentCardDetails tasks={assignment.jobs} />}
    </div>
  );
};
