import {
  createSlice,
  createAsyncThunk,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import { IRegisterCategory } from "./../components/category/types";

const initialState: IRegisterCategory = {
  categoryName: "",
  title: "",
  description: "",
  image: "",
  submitted: false,
  valid: false,
  success_message: "",
  error_message: "",
  status: "idle",
};
export const SubmitCategoryForm = createAsyncThunk(
  "category/createCategory",
  async (
    data: { mutation: any },
    {
      dispatch,
      getState,
    }: { dispatch: ThunkDispatch<any, any, AnyAction>; getState: any }
  ) => {
    const { mutation } = data;
    const { addCategory }: any = getState();
    try {
      const { data } = await mutation({
        variables: {
          input: {
            name: addCategory.categoryName,
            proTitle: addCategory.title,
            imageUrl: addCategory.image,
          },
        },
      });
      if (data) {
        dispatch(setSuccessMessage("Category created successfully"));
      }
    } catch (error) {
      dispatch(setErrorMessage("An error occured"));
    }
  }
);

export const registerCategorySlice = createSlice({
  name: "addCategory",
  initialState,
  reducers: {
    setDCategoryName: (state, action) => {
      state.categoryName = action.payload;
    },
    setDTitle: (state, action) => {
      state.title = action.payload;
    },
    setDDescription: (state, action) => {
      state.description = action.payload;
    },
    setDImage: (state, action) => {
      state.image = action.payload;
    },
    setDSubmitted: (state, action) => {
      state.submitted = action.payload;
    },
    setDFormValid: (state, action) => {
      state.valid = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.success_message = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error_message = action.payload;
    },
    setDStatus: (state, action) => {
      state.status = action.payload;
    },
    resetDForm: (state) => {
      state.categoryName = "";
      state.title = "";
      state.description = "";
      state.image = "";
      state.valid = false;
      state.submitted = false;
    },
  },
  extraReducers: {
    [SubmitCategoryForm.pending.type]: (state, action) => {
      state.status = "pending";
    },
    [SubmitCategoryForm.fulfilled.type]: (state, action) => {
      state.status = "idle";
    },
  },
});

export const {
  setDCategoryName,
  setDTitle,
  setDDescription,
  setDImage,
  setDFormValid,
  setDSubmitted,
  setDStatus,
  resetDForm,
  setSuccessMessage,
  setErrorMessage,
} = registerCategorySlice.actions;

export const selectCategoryName = (state: any) =>
  state.addCategory.categoryName;
export const selectDescription = (state: any) => state.addCategory.description;
export const selectImage = (state: any) => state.addCategory.image;
export const selectValidity = (state: any) => state.addCategory.valid;
export const selectSubmitted = (state: any) => state.addCategory.submitted;
export const selectSubmissionStatus = (state: any) => state.addCategory.status;
export const selectSuccessMessage = (state: any) =>
  state.addCategory.success_message;
export const selectErrorMessage = (state: any) =>
  state.addCategory.error_message;

export const CategoryRegisterSelectors = {
  selectCategoryName,
  selectDescription,
  selectValidity,
  selectImage,
  selectSubmitted,
  selectSubmissionStatus,
  selectSuccessMessage,
  selectErrorMessage,
};

export const CategoryRegisterAction = registerCategorySlice.actions;
export const CategoryRegReducer = registerCategorySlice.reducer;
