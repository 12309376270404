import { createContext } from 'react';
import firebase from 'firebase';

const data: {
  user: null | firebase.User;
  idTokenResult?: firebase.auth.IdTokenResult;
} = {
  user: null,
};

export const userContext = createContext(data);
