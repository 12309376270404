import { Layout } from "./Layout";
import { CustomTable } from "./CustomTable";
import { Trail } from "./Trail";
import CustomLabel from "./CustomLabel";
import TabToggle from "./TabToggle";
import CustomInput from "./CustomInput";
import CustomTextArea from "./CustomTextArea";
import "./styles.scss";

export default {
  Layout,
  CustomTable,
  Trail,
  CustomLabel,
  TabToggle,
  CustomInput,
  CustomTextArea,
};
