import { Category } from "../../types";
import {
  ProTradeLevelDiscount,
  ProTradeRequirement,
} from "./pro-trade-requirement";

export enum TradeRequirementType {
  INTERNAL_ASSESSMENT = "INTERNAL_ASSESSMENT",
  EXTERNAL_ASSESSMENT = "EXTERNAL_ASSESSMENT",
}

export interface ScoringTemplateField {
  name: string;
  description?: string;

  /**
   * Purely for display purposes.
   *
   * Note: Should not be used for any calculations.
   */
  maximumScore?: number;
}

export interface TradeRequirement {
  id: string;
  name: string;
  description: string;
  prompt: string;
  price: number;
  type: TradeRequirementType;
  lmsId?: string;
  preRequisites?: TradeRequirement[];
  passingScore?: number;
  scoringTemplate?: ScoringTemplateField[];
  isPassed?: boolean;
  discounts?: ProTradeLevelDiscount[];
  proTradeRequirement?: ProTradeRequirement;
  createdAt: Date;
  updatedAt: Date;
}

export interface TradeLevel {
  id: string;
  trade: Trade;
  order: number;
  requirements: TradeRequirement[];
  hasAccess?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface Trade {
  id: string;
  name: string;
  description: string;
  minimumDailyRate: number;
  category: Category;
  levels: TradeLevel[];
  currentLevel?: number;
  createdAt: Date;
}
