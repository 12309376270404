import React, { FC } from "react";
import { Icon } from "semantic-ui-react";

export interface EditableProps {
  onEdit: () => void;
}

export const Editable: FC<EditableProps> = ({ children, onEdit }) => {
  return (
    <div className='flex items-center'>
      {children}
      <Icon
        name='edit'
        className='text-primary-300 hover:text-primary-500 !ml-2 cursor-pointer'
        onClick={onEdit}
      />
    </div>
  );
};
