import { gql, useQuery } from "@apollo/client";
import {
  ColumnType,
  ColumnsType,
  TablePaginationConfig,
  TableProps,
} from "antd/lib/table";
import { Link, useRouteMatch } from "react-router-dom";
import Button from "@laborhack/custom-button";
import { components as LayoutComponents } from "../../components/layout";
import {
  IdentityVerificationStatus,
  ListProsFilters,
  ListProsIdentityVerificationStatus,
  ListProsTestStatus,
  ListProsSearchBy,
  ListProsSortBy,
  Pro,
  RelayStylePaginatedResponse,
  ProListItem,
  ProLeadSource,
  OutreachSource,
  OutreachSourceType,
  ListProsIdentityVerificationIssueCode,
  ProTrack,
} from "../../types";
import Island from "../../_components/Island";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import Pill from "../../_components/Pill";
import { format } from "date-fns";
import {
  extractItemsFromRelayStylePaginationResponse,
  isValidRefereeOrGuarantor,
} from "../../helpers/helpers";
import React, { useContext, useEffect, useMemo } from "react";
import {
  Input,
  Select,
  Button as SemanticButton,
  Icon,
  DropdownProps,
  InputOnChangeData,
} from "semantic-ui-react";
import { ProsContext } from "../../context/ProsContext";
import produce from "immer";
import PillGroup from "../../_components/PillGroup";
import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { GET_TRADES } from "../../graphql/query";
import { Trade } from "../../common/types/trade";

const { CustomTable } = LayoutComponents;

const searchOptions = [
	{
		key: ListProsSearchBy.FIRST_NAME,
		text: "First Name",
		value: ListProsSearchBy.FIRST_NAME,
	},
	{
		key: ListProsSearchBy.LAST_NAME,
		text: "Last Name",
		value: ListProsSearchBy.LAST_NAME,
	},
	{
		key: ListProsSearchBy.EMAIL,
		text: "Email",
		value: ListProsSearchBy.EMAIL,
	},
	{
		key: ListProsSearchBy.PHONE_NUMBER,
		text: "Phone Number",
		value: ListProsSearchBy.PHONE_NUMBER,
	},
	{
		key: ListProsSearchBy.ADDRESS,
		text: "Address",
		value: ListProsSearchBy.ADDRESS,
	},
	{
		key: ListProsSearchBy.REFERRER,
		text: "Referrer",
		value: ListProsSearchBy.REFERRER,
	},
];

const LIST_PROS = gql`
	query ListPros(
		$first: Int
		$after: String
		$search: ListProsSearch
		$sort: ListProsSort
		$filters: ListProsFilters
	) {
		listPros(
			first: $first
			after: $after
			search: $search
			sort: $sort
			filters: $filters
		) {
			edges {
				cursor
				node {
					id
					firstName
					lastName
					email
					phoneNumber
					createdAt
					trades {
						id
						name
					}
					tracks
					categories {
						id
						proTitle
					}
					identityVerification {
						id
						status
						issues {
							code
						}
						createdAt
					}
					identityVerificationStatus
					identityVerificationIssues
					proApplication {
						address
						stateOfResidence
						leadSource
						channelOutreachSource {
							id
							name
						}
					}
					referredBy
					priorLearningTestStatus
					softSkillsTrainingStatus
					alternativeToPracticalInterviewStatus
					bankAccountSubmitted
					backgroundCheckComplete
					isLegacy
					referees {
						name
						phoneNumber
						email
						companyName
						companyAddress
						jobTitle
						relationship
					}
					guarantors {
						name
						phoneNumber
						email
						companyName
						companyAddress
						jobTitle
						relationship
					}
					onboardedAt
				}
			}
			pageInfo {
				hasNextPage
				startCursor
				endCursor
			}
		}
	}
`;

const GET_LIST_PROS_COUNT = gql`
	query ListProsTotalCount(
		$first: Int
		$after: String
		$search: ListProsSearch
		$sort: ListProsSort
		$filters: ListProsFilters
	) {
		listProsTotalCount(
			first: $first
			after: $after
			search: $search
			sort: $sort
			filters: $filters
		)
	}
`;

const CATEGORIES = gql`
	query Categories {
		categories {
			id
			proTitle
		}
	}
`;

const FETCH_OUTREACH_SOURCES = gql`
	query FetchOutreachSources {
		fetchOutreachSources {
			id
			name
			type
		}
	}
`;

export const OptimizedProList = () => {
	const { data, loading, error, fetchMore, refetch } = useQuery<{
		listPros: RelayStylePaginatedResponse<
			Omit<
				ProListItem,
				"paidForPriorLearningAttempts" | "paidForSoftSkillsAttempts"
			>
		>;
	}>(LIST_PROS, {
		variables: {
			first: 10,
			sort: {
				by: ListProsSortBy.CREATED_AT,
				ascending: false,
			},
		},
		fetchPolicy: "cache-only",
	});

	const {
		data: listProsTotalCountData,
		loading: listProsTotalCountLoading,
		error: listProsTotalCountError,
		refetch: listProsTotalCountRefetch,
	} = useQuery<{
		listProsTotalCount: number;
	}>(GET_LIST_PROS_COUNT, {
		variables: {
			first: 10,
			sort: {
				by: ListProsSortBy.CREATED_AT,
				ascending: false,
			},
		},
		fetchPolicy: "cache-only",
	});

	const {
		error: categoriesError,
		loading: loadingCategories,
		data: categoriesData,
	} = useQuery<{
		categories: { id: string; proTitle?: string }[];
	}>(CATEGORIES);

	const { data: tradesData } = useQuery<{ trades: Trade[] }>(GET_TRADES);

	const { sort, setSort, search, setSearch, filters, setFilters } = useContext(
		ProsContext
	);

	const {
		loading: loadingOutreachSources,
		data: outreachSourcesData,
	} = useQuery<{ fetchOutreachSources: OutreachSource[] }>(
		FETCH_OUTREACH_SOURCES
	);

	const outreachChannels = useMemo(() => {
		return outreachSourcesData?.fetchOutreachSources.filter(({ type }) => {
			return type === OutreachSourceType.CHANNEL;
		});
	}, [outreachSourcesData]);

	useEffect(() => {
		refetch({
			first: 10,
			sort,
			search,
			filters,
		});

		listProsTotalCountRefetch({
			first: 10,
			sort,
			search,
			filters,
		});
	}, [sort, search, filters, refetch, listProsTotalCountRefetch]);

	const [searchQuery, setSearchQuery] = React.useState<string>("");
	const [searchBy, setSearchBy] = React.useState<ListProsSearchBy>(
		ListProsSearchBy.FIRST_NAME
	);

	const { path } = useRouteMatch();

	const idStatusToPillClassName: {
		[Property in IdentityVerificationStatus]: string;
	} = {
		PENDING: "!bg-yellow-100 !text-yellow-800",
		VERIFIED: "!bg-green-100 !text-green-800",
		DECLINED: "!bg-red-100 !text-red-800",
	};

	const testStatusToPillClassName: {
		[Property in ListProsTestStatus]: string;
	} = {
		[ListProsTestStatus.NOT_TAKEN]: "!bg-yellow-100 !text-yellow-800",
		[ListProsTestStatus.PASSED]: "!bg-green-100 !text-green-800",
		[ListProsTestStatus.FAILED]: "!bg-red-100 !text-red-800",
		[ListProsTestStatus.SCHEDULED]: "!bg-blue-100 !text-blue-800",
		[ListProsTestStatus.AWAITING_RESULT]: "!bg-yellow-100 !text-yellow-800",
		[ListProsTestStatus.NOT_STARTED]: "!bg-gray-100 !text-gray-800",
	};

	if (loading || loadingCategories || loadingOutreachSources) {
		return <Loading />;
	}

	if (error || !data || categoriesError) {
		return <RequestFailed />;
	}

	const trades = tradesData?.trades;

	let columns: ColumnsType<ProListItem & { key: string }> = [
		{
			title: "Date Created",
			render: (text, record) => {
				return <p>{format(new Date(record.createdAt), "PP")}</p>;
			},
			key: ListProsSortBy.CREATED_AT,
			width: "10%",
			sortDirections: ["ascend", "descend", "ascend"],
			sorter: (a, b) => 0,
			fixed: "left",
		},
		{
			title: "Onboarded",
			render: (text, record) => {
				return record.onboardedAt ? (
					<Icon size="large" name="check circle" color="green" />
				) : (
					<Icon size="large" name="remove circle" color="red" />
				);
			},
			key: "isOnboarded",
			width: "12%",
			filters: [
				{
					text: "Yes",
					value: "TRUE",
				},
				{
					text: "No",
					value: "FALSE",
				},
			],
			filteredValue: !!filters?.isOnboarded
				? [filters?.isOnboarded ? "TRUE" : "FALSE"]
				: undefined,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "Is Legacy",
			render: (text, record) => {
				return record.isLegacy ? (
					<Icon size="large" name="check circle" color="green" />
				) : (
					<Icon size="large" name="remove circle" color="red" />
				);
			},
			key: "isLegacy",
			width: "12%",
			filters: [
				{
					text: "Yes",
					value: "TRUE",
				},
				{
					text: "No",
					value: "FALSE",
				},
			],
			filteredValue: !!filters?.isLegacy
				? [filters?.isLegacy ? "TRUE" : "FALSE"]
				: undefined,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "First Name",
			render: (text, record) => {
				return <p>{record.firstName}</p>;
			},
			key: ListProsSortBy.FIRST_NAME,
			width: "13%",
			sortDirections: ["ascend", "descend", "ascend"],
			sorter: (a, b) => 0,
		},
		{
			title: "Last Name",
			render: (text, record) => {
				return <p>{record.lastName}</p>;
			},
			key: ListProsSortBy.LAST_NAME,
			width: "10%",
			sortDirections: ["ascend", "descend", "ascend"],
			sorter: (a, b) => 0,
		},
		{
			title: "Email",
			render: (text, record) => {
				return <p>{record.email}</p>;
			},
			key: "email",
			width: "16%",
		},
		{
			title: "Phone Number",
			render: (text, record) => {
				return <p>{record.phoneNumber}</p>;
			},
			key: "phoneNumber",
			width: "20%",
		},
		{
			title: "Address",
			render: (text, record) => {
				if (!record.proApplication) return <p>N/A</p>;

				return (
					<p>{`${record.proApplication.address}, ${record.proApplication.stateOfResidence}`}</p>
				);
			},
			key: "address",
			width: "20%",
		},
		{
			title: "Prior Learning Status",
			render: (text, record) => {
				return (
					<Pill
						className={
							testStatusToPillClassName[record.priorLearningTestStatus]
						}
					>
						{record.priorLearningTestStatus.replace("_", " ")}
					</Pill>
				);
			},
			key: "priorLearningTestStatus",
			width: "15%",
			filters: [
				{
					text: "Passed",
					value: ListProsTestStatus.PASSED,
				},
				{
					text: "Failed",
					value: ListProsTestStatus.FAILED,
				},
				{
					text: "Awaiting Result",
					value: ListProsTestStatus.AWAITING_RESULT,
				},
				{
					text: "Not Started",
					value: ListProsTestStatus.NOT_STARTED,
				},
			],
			filteredValue: filters?.priorLearningTestStatus,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "Soft Skills Status",
			render: (text, record) => {
				return (
					<Pill
						className={
							testStatusToPillClassName[record.softSkillsTrainingStatus]
						}
					>
						{record.softSkillsTrainingStatus.replace("_", " ")}
					</Pill>
				);
			},
			key: "softSkillsTrainingStatus",
			width: "15%",
			filters: [
				{
					text: "Passed",
					value: ListProsTestStatus.PASSED,
				},
				{
					text: "Failed",
					value: ListProsTestStatus.FAILED,
				},
				{
					text: "Awaiting Result",
					value: ListProsTestStatus.AWAITING_RESULT,
				},
				{
					text: "Not Started",
					value: ListProsTestStatus.NOT_STARTED,
				},
			],
			filteredValue: filters?.softSkillsTrainingStatus,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "Alternative to Practical Status",
			render: (text, record) => {
				return (
					<Pill
						className={
							testStatusToPillClassName[
								record.alternativeToPracticalInterviewStatus
							]
						}
					>
						{record.alternativeToPracticalInterviewStatus.replace("_", " ")}
					</Pill>
				);
			},
			key: "alternativeToPracticalInterviewStatus",
			width: "15%",
			filters: [
				{
					text: "Passed",
					value: ListProsTestStatus.PASSED,
				},
				{
					text: "Failed",
					value: ListProsTestStatus.FAILED,
				},
				{
					text: "Scheduled",
					value: ListProsTestStatus.SCHEDULED,
				},
				{
					text: "Awaiting Result",
					value: ListProsTestStatus.AWAITING_RESULT,
				},
				{
					text: "Not Started",
					value: ListProsTestStatus.NOT_STARTED,
				},
			],
			filteredValue: filters?.alternativeToPracticalInterviewStatus,
			onFilter: (value, record) => {
				return true;
			},
		},

		{
			title: "Tracks",
			render: (text, record) => {
				if (!record.tracks?.length) {
					return <p>Not Set</p>;
				}

				return (
					<PillGroup maxCount={1}>
						{record.tracks?.map((track, idx) => (
							<Pill key={idx}>{track}</Pill>
						))}
					</PillGroup>
				);
			},
			key: "tracks",
			width: "16%",
			filters: [
				{
					text: "Study Work",
					value: ProTrack.STUDYWORK,
				},
				{
					text: "Local Gig",
					value: ProTrack.LOCALGIG,
				},
			],
			filteredValue: filters?.tracks,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "Trade",
			render: (text, record) => {
				if (!record.trades.length) {
					return <p>Not Set</p>;
				}

				return (
					<PillGroup maxCount={1}>
						{record.trades.map((trade) => (
							<Pill key={trade.id}>{trade.name}</Pill>
						))}
					</PillGroup>
				);
			},
			key: "tradeIds",
			width: "25%",
			filters: trades?.map((trade) => ({
				text: trade.name,
				value: trade.id,
			})),
			filteredValue: filters?.tradeIds,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "Background Check",
			width: "15%",
			render: (text, record) => {
				return record.backgroundCheckComplete ? (
					<Icon size="large" name="check circle" color="green" />
				) : (
					<Icon size="large" name="remove circle" color="red" />
				);
			},
			key: "backgroundCheckComplete",
			filters: [
				{
					text: "Complete",
					value: "TRUE",
				},
				{
					text: "Not Complete",
					value: "FALSE",
				},
			],
			filteredValue: !!filters?.backgroundCheckComplete
				? [filters?.backgroundCheckComplete ? "TRUE" : "FALSE"]
				: undefined,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "Bank Details",
			width: "13%",
			render: (text, record) => {
				return record.bankAccountSubmitted ? (
					<Icon size="large" name="check circle" color="green" />
				) : (
					<Icon size="large" name="remove circle" color="red" />
				);
			},
			key: "bankAccountSubmitted",
			filters: [
				{
					text: "Complete",
					value: "TRUE",
				},
				{
					text: "Not Complete",
					value: "FALSE",
				},
			],
			filteredValue: !!filters?.bankAccountSubmitted
				? [filters?.bankAccountSubmitted ? "TRUE" : "FALSE"]
				: undefined,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "ID Verification",
			render: (text, record) => {
				return (
					<Pill
						className={
							record.identityVerificationStatus
								? idStatusToPillClassName[record.identityVerificationStatus]
								: "!bg-red-100 !text-red-800"
						}
					>
						{record.identityVerificationStatus || "NOT SUBMITTED"}
					</Pill>
				);
			},
			key: "identityVerification",
			width: "15%",
			filters: [
				{
					text: "Not Submitted",
					value: ListProsIdentityVerificationStatus.NOT_SUBMITTED,
				},
				{
					text: "Pending",
					value: ListProsIdentityVerificationStatus.PENDING,
				},
				{
					text: "Verified",
					value: ListProsIdentityVerificationStatus.VERIFIED,
				},
				{
					text: "Declined",
					value: ListProsIdentityVerificationStatus.DECLINED,
				},
			],
			filteredValue: filters?.identityVerificationStatus,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "ID Evaluation Result",
			render: (text, record) => {
				return record.identityVerificationIssues?.length ? (
					<PillGroup maxCount={1}>
						{record.identityVerificationIssues?.map((code) => (
							<Pill key={code}>{code}</Pill>
						))}
					</PillGroup>
				) : (
					<p>N/A</p>
				);
			},
			key: "identityVerificationIssueCode",
			width: "25%",
			filters: Object.values(ListProsIdentityVerificationIssueCode).map(
				(reason) => ({
					text: reason,
					value: reason,
				})
			),
			filteredValue: filters?.identityVerificationIssueCode,
			onFilter: () => {
				return true;
			},
		},
		{
			title: "Lead Source",
			render: (text, record) => {
				return record.proApplication?.leadSource || "N/A";
			},
			key: "leadSource",
			width: "18%",
			filters: [
				{ text: "RADIO", value: ProLeadSource.RADIO },
				{ text: "FROM_A_FRIEND", value: ProLeadSource.FROM_A_FRIEND },
				{ text: "FLYER", value: ProLeadSource.FLYER },
				{ text: "SOCIAL_MEDIA", value: ProLeadSource.SOCIAL_MEDIA },
				{ text: "GOOGLE", value: ProLeadSource.GOOGLE },
				{ text: "OPL_ACADEMY", value: ProLeadSource.OPL_ACADEMY },
				{ text: "COMMUNITY_OUTREACH", value: ProLeadSource.COMMUNITY_OUTREACH },
				{ text: "OTHER", value: ProLeadSource.OTHER },
			],
			filteredValue: filters?.leadSource,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "Referred By",
			width: "18%",
			render: (text, record) => {
				return record.referredBy || "N/A";
			},
			key: "referredBy",
		},
		{
			title: "Channel",
			render: (text, record) => {
				return record.proApplication?.channelOutreachSource?.name || "N/A";
			},
			key: "channelIds",
			width: "18%",
			filters: outreachChannels?.map((channel) => ({
				text: channel.name,
				value: channel.id,
			})),
			filteredValue: filters?.channelIds,
			onFilter: (value, record) => {
				return true;
			},
		},
		{
			title: "",
			render: (text, record) => {
				return (
					<Link to={`${path}/${record.id}/details`}>
						<Button>View</Button>
					</Link>
				);
			},
			width: "10%",
			fixed: "right",
		},
	];

	const handleSearchDropdownChange = (
		event: React.SyntheticEvent<HTMLElement, Event>,
		data: DropdownProps
	) => {
		setSearchBy(data.value as ListProsSearchBy);
	};

	const handleSearchInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		data: InputOnChangeData
	) => {
		setSearchQuery(data.value);
	};

	const handleSearch = () => {
		setSearch({
			query: searchQuery,
			by: searchBy,
		});
	};

	const handleRemoveSearch = () => {
		setSearch(undefined);
		setSearchQuery("");
	};

	const handleSortChange = (
		key: ListProsSortBy,
		direction?: "ascend" | "descend" | null
	) => {
		setSort((prev) => {
			return {
				by: key,
				ascending: direction === "ascend",
			};
		});
	};

	const handleFilterChange = (key: keyof ListProsFilters, filters: any) => {
		setFilters((prev) => {
			return {
				...prev,
				[key]: filters,
			};
		});
	};

	const removeFilter = (key: keyof ListProsFilters) => {
		setFilters((prev) => {
			return produce(prev, (draft) => {
				if (draft) {
					delete draft[key];
				}
			});
		});
	};

	const handleTableChange: TableProps<Pro>["onChange"] = (
		pagination: TablePaginationConfig,
		filters,
		sorter
	) => {
		if (filters.identityVerification !== undefined) {
			filters.identityVerification === null
				? removeFilter("identityVerificationStatus")
				: handleFilterChange(
						"identityVerificationStatus",
						filters.identityVerification
				  );
		}

		if (filters.identityVerificationIssueCode !== undefined) {
			filters.identityVerificationIssueCode === null
				? removeFilter("identityVerificationIssueCode")
				: handleFilterChange(
						"identityVerificationIssueCode",
						filters.identityVerificationIssueCode
				  );
		}

		if (filters.priorLearningTestStatus !== undefined) {
			filters.priorLearningTestStatus === null
				? removeFilter("priorLearningTestStatus")
				: handleFilterChange(
						"priorLearningTestStatus",
						filters.priorLearningTestStatus
				  );
		}

		if (filters.alternativeToPracticalInterviewStatus !== undefined) {
			filters.alternativeToPracticalInterviewStatus === null
				? removeFilter("alternativeToPracticalInterviewStatus")
				: handleFilterChange(
						"alternativeToPracticalInterviewStatus",
						filters.alternativeToPracticalInterviewStatus
				  );
		}

		if (filters.softSkillsTrainingStatus !== undefined) {
			filters.softSkillsTrainingStatus === null
				? removeFilter("softSkillsTrainingStatus")
				: handleFilterChange(
						"softSkillsTrainingStatus",
						filters.softSkillsTrainingStatus
				  );
		}

		if (filters.paidForPriorLearningAttempts !== undefined) {
			/**
			 * If the user has selected multiple values, we need to remove the filter because only true or false can be selected
			 * and if the user has selected multiple values, it means they have selected both true and false which means we should
			 * not filter by this field
			 */
			const hasMultipleValues =
				Array.isArray(filters.paidForPriorLearningAttempts) &&
				filters.paidForPriorLearningAttempts.length > 1;

			if (hasMultipleValues) {
				removeFilter("paidForPriorLearningAttempts");
			} else {
				filters.paidForPriorLearningAttempts === null
					? removeFilter("paidForPriorLearningAttempts")
					: handleFilterChange(
							"paidForPriorLearningAttempts",
							filters.paidForPriorLearningAttempts[0] === "TRUE" ? true : false
					  );
			}
		}

		if (filters.paidForSoftSkillsAttempts !== undefined) {
			const hasMultipleValues =
				Array.isArray(filters.paidForSoftSkillsAttempts) &&
				filters.paidForSoftSkillsAttempts.length > 1;

			if (hasMultipleValues) {
				removeFilter("paidForSoftSkillsAttempts");
			} else {
				filters.paidForSoftSkillsAttempts === null
					? removeFilter("paidForSoftSkillsAttempts")
					: handleFilterChange(
							"paidForSoftSkillsAttempts",
							filters.paidForSoftSkillsAttempts[0] === "TRUE" ? true : false
					  );
			}
		}

		if (filters.isLegacy !== undefined) {
			const hasMultipleValues =
				Array.isArray(filters.isLegacy) && filters.isLegacy.length > 1;

			if (hasMultipleValues) {
				removeFilter("isLegacy");
			} else {
				filters.isLegacy === null
					? removeFilter("isLegacy")
					: handleFilterChange(
							"isLegacy",
							filters.isLegacy[0] === "TRUE" ? true : false
					  );
			}
		}

		if (filters.isOnboarded !== undefined) {
			const hasMultipleValues =
				Array.isArray(filters.isOnboarded) && filters.isOnboarded.length > 1;

			if (hasMultipleValues) {
				removeFilter("isOnboarded");
			} else {
				filters.isOnboarded === null
					? removeFilter("isOnboarded")
					: handleFilterChange(
							"isOnboarded",
							filters.isOnboarded[0] === "TRUE" ? true : false
					  );
			}
		}

		if (filters.backgroundCheckComplete !== undefined) {
			const hasMultipleValues =
				Array.isArray(filters.backgroundCheckComplete) &&
				filters.backgroundCheckComplete.length > 1;

			if (hasMultipleValues) {
				removeFilter("backgroundCheckComplete");
			} else {
				filters.backgroundCheckComplete === null
					? removeFilter("backgroundCheckComplete")
					: handleFilterChange(
							"backgroundCheckComplete",
							filters.backgroundCheckComplete[0] === "TRUE" ? true : false
					  );
			}
		}

		if (filters.bankAccountSubmitted !== undefined) {
			const hasMultipleValues =
				Array.isArray(filters.bankAccountSubmitted) &&
				filters.bankAccountSubmitted.length > 1;

			if (hasMultipleValues) {
				removeFilter("bankAccountSubmitted");
			} else {
				filters.bankAccountSubmitted === null
					? removeFilter("bankAccountSubmitted")
					: handleFilterChange(
							"bankAccountSubmitted",
							filters.bankAccountSubmitted[0] === "TRUE" ? true : false
					  );
			}
		}

		if (filters.categoryIds !== undefined) {
			filters.categoryIds === null
				? removeFilter("categoryIds")
				: handleFilterChange("categoryIds", filters.categoryIds);
		}

		if (filters.tradeIds !== undefined) {
			filters.tradeIds === null
				? removeFilter("tradeIds")
				: handleFilterChange("tradeIds", filters.tradeIds);
		}

		if (filters.tracks !== undefined) {
			filters.tracks === null
				? removeFilter("tracks")
				: handleFilterChange("tracks", filters.tracks);
		}

		if (filters.leadSource !== undefined) {
			filters.leadSource === null
				? removeFilter("leadSource")
				: handleFilterChange("leadSource", filters.leadSource);
		}

		if (filters.channelIds !== undefined) {
			filters.channelIds === null
				? removeFilter("channelIds")
				: handleFilterChange("channelIds", filters.channelIds);
		}

		if (Array.isArray(sorter)) {
			return;
		} else {
			const { columnKey, order } = sorter;

			if (columnKey) {
				handleSortChange(columnKey as ListProsSortBy, order);
			}
		}
	};

	const hasMore = data?.listPros?.pageInfo?.hasNextPage || false;

	return (
		<Island>
			<div className="mb-4">
				<h2 className="text-primary-500 text-xl font-bold m-0">Pro List</h2>
				<p className="m-0 text-primary-300">A list of pros</p>
			</div>
			<div className="flex justify-between gap-4 py-4">
				<Link to={`${path}/create`}>
					<Button>Create Pro</Button>
				</Link>
				<div className="flex justify-between gap-4">
					<Input
						type="text"
						placeholder="Search by"
						value={searchQuery}
						onChange={handleSearchInputChange}
						action
					>
						<input />
						<Select
							options={searchOptions}
							value={searchBy}
							onChange={handleSearchDropdownChange}
						/>
						<SemanticButton
							icon
							className="!bg-primary-500 !text-white"
							type="submit"
							onClick={handleSearch}
							loading={loading}
						>
							<Icon name="search" />
						</SemanticButton>
					</Input>
				</div>
			</div>
			{search?.query && (
				<div className="text-lg flex items-center gap-4 py-2">
					<p className="m-0">
						Search by{" "}
						<i>
							<strong>
								{searchOptions.find(({ value }) => search.by === value)?.text}
							</strong>
						</i>{" "}
						results for <strong>"{search.query}"</strong>
					</p>
					<Button size="small" type="link" onClick={handleRemoveSearch}>
						Remove Search
					</Button>
				</div>
			)}
			<div className="flex justify-end">
				<p>
					Showing {data?.listPros?.edges?.length || 0} of{" "}
					{listProsTotalCountData?.listProsTotalCount || "Many"} results
				</p>
			</div>
			<CustomTable
				handleTableChange={handleTableChange}
				onRow={(record: any, index: any) => {
					return {};
				}}
				columns={columns}
				data={
					extractItemsFromRelayStylePaginationResponse(data.listPros).map(
						(item) => {
							return {
								key: item.id,
								...item,
							};
						}
					) || []
				}
				scroll={{ x: 4000 }}
				loading={loading}
				hasMore={hasMore}
				fetchMore={() =>
					fetchMore({
						variables: {
							first: 10,
							after: data.listPros.pageInfo.endCursor,
							sort: sort,
							search: search,
							filters,
						},
					})
				}
			/>
		</Island>
	);
};
