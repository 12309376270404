import { useProBankAccountInfo } from "@/hooks/useProBankAccountInfo";
import { Pro } from "@/types";
import RequestFailed from "@/_components/RequestFailed";
import { CheckCircleOutlined } from "@ant-design/icons";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";

const GET_LIST_OF_BANKS = gql`
  query ListBanks {
    listBanks {
      data {
        name
        code
      }
    }
  }
`;

const GET_BANK_ACCOUNT_INFO = gql`
  query GetBankAccountInfo($accountNumber: String!, $bankCode: String!) {
    getBankAccountInformation(
      input: { accountNumber: $accountNumber, bankCode: $bankCode }
    ) {
      data {
        accountName
        accountNumber
      }
    }
  }
`;

const SAVE_BANK_ACCOUNT_INFO = gql`
  mutation AdminAddProPaymentInformation($proId: String!, $payload: BankAccountInformationInput!) {
    adminAddProPaymentInformation(proId: $proId, payload: $payload) {
      id
      userId
    }
  }
`;

export interface Bank {
  name: string;
  code: string;
}

export interface BankAccountInformationProps {
  pro: Pro;
}

export const BankAccountInformation: FC<BankAccountInformationProps> = ({
  pro,
}) => {
  const {
    loading: loadingBankAccountInfo,
    hasProvidedBankAccountInfo,
    refetchBankAccountInfo,
    maskedAccountNumber,
    accountName,
    bankName,
  } = useProBankAccountInfo(pro.id);

  const [accountNumber, setAccountNumber] = useState<string>("");
  const [bankCode, setBankCode] = useState<string>("");

  const isValidAccountNumber = (accountNumber: string) => {
    return (
      accountNumber.trim().length === 10 &&
      !isNaN(accountNumber as unknown as number)
    );
  };

  const { loading: bankListLoading, data } = useQuery<{
    listBanks: {
      data: Bank[];
    };
  }>(GET_LIST_OF_BANKS);

  const [
    getBankAccountInfo,
    { loading: bankAccountInfoLoading, data: bankAccountInfo },
  ] = useLazyQuery(GET_BANK_ACCOUNT_INFO);

  const [saveBankAccountInfo, saveBankAccountInfoResponse] = useMutation(
    SAVE_BANK_ACCOUNT_INFO,
    {
      onCompleted: async () => {
        try {
          await refetchBankAccountInfo();
        } catch (error) {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (isValidAccountNumber(accountNumber) && bankCode) {
      getBankAccountInfo({
        variables: {
          bankCode,
          accountNumber,
        },
      });
    }
  }, [accountNumber, bankCode, getBankAccountInfo]);

  if (loadingBankAccountInfo) {
    return null;
  }

  const handleSubmit = () => {
    saveBankAccountInfo({
      variables: {
        payload: {
          accountNumber,
          bankCode,
        },
        proId: pro.id,
      },
    });
  };

  if (saveBankAccountInfoResponse.error) {
    return (
      <RequestFailed
        errorMessage={saveBankAccountInfoResponse.error.message}
        onRetry={saveBankAccountInfoResponse.reset}
      />
    );
  }

  const canSubmit = isValidAccountNumber(accountNumber) && bankCode;
  const hasSubmittedBankAccountDetails = hasProvidedBankAccountInfo;

  return (
		<div className="bg-white p-4 lg:p-8">
			{!hasSubmittedBankAccountDetails && (
				<h4 className="font-semibold text-primary-500 mb-6">
					Status:{" "}
					<span className="text-red-500">ACCOUNT DETAILS NOT SUBMITTED</span>
				</h4>
			)}
			{hasSubmittedBankAccountDetails ? (
				<div className="w-1/2 mt-8 lg:mt-0 flex flex-col">
					<h1 className="text-lg mb-4  font-semibold text-primary-500">
						Bank account details submitted
					</h1>
					<Box className="text-left">
						<p className="text-primary-300 mt-4 p-2 bg-gray-100 rounded-md">
							<span className="font-medium">Account name:</span> {accountName}
							<br />
							<span className="font-medium">Account number:</span>{" "}
							{maskedAccountNumber}
							<br />
							{bankName && (
								<span>
									<span className="font-medium">Bank name:</span> {bankName}
								</span>
							)}
						</p>
					</Box>
				</div>
			) : (
				<div className="w-1/2">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-4">
						<div>
							<h3 className="font-semibold text-primary-500">Bank Details</h3>
							<p className="text-sm text-primary-300">
								Provide bank details for this pro
							</p>
						</div>
						<Stack spacing={2} className="">
							<FormControl
								isRequired
								isInvalid={!isValidAccountNumber(accountNumber)}
							>
								<FormLabel
									fontSize={"sm"}
									htmlFor="accountNumber"
									color="primary.500"
								>
									Bank Account Number
								</FormLabel>
								<InputGroup>
									<Input
										id="accountNumber"
										fontSize={"sm"}
										type={"text"}
										name="name"
										value={accountNumber}
										onChange={(e) => setAccountNumber(e.target.value)}
										placeholder="1234567890"
									/>
									<InputRightElement>
										{isValidAccountNumber(accountNumber) && (
											<Icon as={CheckCircleOutlined} />
										)}
									</InputRightElement>
								</InputGroup>
								<FormErrorMessage>
									Ensure account number is correct
								</FormErrorMessage>
							</FormControl>
							<FormControl isDisabled={bankListLoading} isRequired>
								<FormLabel
									fontSize={"sm"}
									htmlFor="bankName"
									color="primary.500"
								>
									Bank
								</FormLabel>
								<Select
									id="bankName"
									fontSize={"sm"}
									placeholder="Select option"
									value={bankCode}
									onChange={(e) => {
										setBankCode(e.target.value);
									}}
								>
									{data?.listBanks.data.map((bank: Bank) => {
										return (
											<option key={bank.code} value={bank.code}>
												{bank.name}
											</option>
										);
									})}
								</Select>
							</FormControl>
							<FormControl isRequired>
								<FormLabel
									fontSize={"sm"}
									htmlFor="accountName"
									color="primary.500"
								>
									Bank Account Name
								</FormLabel>
								<InputGroup>
									<Input
										id="accountName"
										type="text"
										fontSize={"sm"}
										value={
											bankAccountInfo?.getBankAccountInformation?.data
												?.accountName || ""
										}
										disabled
										placeholder="Not available"
									/>
									<InputRightElement>
										{bankAccountInfoLoading && <Spinner color="green.500" />}
									</InputRightElement>
								</InputGroup>
							</FormControl>
						</Stack>
					</div>
					<div className="my-4 md:my-8 w-full h-0.5 bg-primary-100" />
					<div className="flex justify-end">
						<Button
							isLoading={saveBankAccountInfoResponse.loading}
							isDisabled={!canSubmit}
							loadingText="Submitting"
							fontSize={"sm"}
							colorScheme={"green"}
							variant={"solid"}
							spinnerPlacement="start"
							onClick={handleSubmit}
						>
							Submit
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

