import { gql, NetworkStatus, useQuery } from "@apollo/client";
import { ColumnsType } from "antd/lib/table";
import React, { FC } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { components as LayoutComponents } from "../../components/layout";
import { MarketplaceRequest, TransactionStatus } from "../../types";
import Island from "../../_components/Island";
import RequestFailed from "../../_components/RequestFailed";
import Button from "@laborhack/custom-button";

const { Trail, CustomTable } = LayoutComponents;

const LIST_MARKETPLACE_REQUESTS = gql`
  query ListMarketplaceRequests {
    marketplaceRequests {
      id
      client {
        firstName
        lastName
      }
      location {
        address
      }
      matchingFeeInvoice {
        isFullyPaid
        hasAtLeastOnePayment
        payment {
          status
        }
      }
      createdAt
    }
  }
`;

export interface MarketplaceRequestListProps {}

export const MarketplaceRequestList: FC<MarketplaceRequestListProps> = ({}) => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { loading, error, data, refetch, networkStatus } = useQuery<{
    marketplaceRequests: MarketplaceRequest[];
  }>(LIST_MARKETPLACE_REQUESTS, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    return <RequestFailed />;
  }

  const columns: ColumnsType<MarketplaceRequest & { key: string }> = [
    {
      title: "ID",
      render: (text, record) => {
        return <p>{record.id.substring(0, 8)}</p>;
      },
      width: "10%",
    },
    {
      title: "Client",
      render: (text, record) => {
        return <p>{`${record.client.firstName} ${record.client.lastName}`}</p>;
      },
      width: "25%",
    },
    {
      title: "Location",
      render: (text, record) => {
        const { address } = record.location;

        return <p>{address}</p>;
      },
      width: "30%",
    },

    {
      title: "Matched",
      render: (text, record) => {
        return record.matchingFeeInvoice ? (
          <Icon name='check square' color='green' size='large' />
        ) : (
          <Icon name='remove circle' color='red' size='large' />
        );
      },
      width: "10%",
    },
    {
      title: "Paid",
      render: (text, record) => {
        return record.matchingFeeInvoice?.isFullyPaid ? (
          <Icon name='check square' color='green' size='large' />
        ) : (
          <Icon name='remove circle' color='red' size='large' />
        );
      },
      width: "10%",
    },
    {
      title: "Request Date",
      render: (text, record) => {
        return <p>{new Date(record.createdAt).toLocaleString()}</p>;
      },
      width: "15%",
    },
  ];

  return (
    <Island header={<Trail root='Marketplace Requests' />}>
      <div className='w-full flex justify-end my-4'>
        <Button
          onClick={() => refetch()}
          loading={networkStatus === NetworkStatus.refetch}
          type='link'
        >
          <Icon name='refresh' /> Refresh
        </Button>
      </div>
      <CustomTable
        onRow={(record: any, index: any) => {
          return {
            onClick: () => {
              history.push(path + "/" + record.id);
            },
          };
        }}
        columns={columns}
        data={
          data?.marketplaceRequests.map((request) => {
            return {
              key: request.id,
              ...request,
            };
          }) || []
        }
        loading={loading}
        handleTableChange={() => {}}
      ></CustomTable>
    </Island>
  );
};
