import { gql } from "@apollo/client";

export const GET_ORDER_STATISTICS = gql`
  {
    getOrderStatistics {
      data {
        all
        new
      }
    }
  }
`;

export const GET_CLIENT_STATISTICS = gql`
  {
    getClientStatistics {
      data {
        all
        new
      }
    }
  }
`;
