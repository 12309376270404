import React, { FC, useState } from "react";
import {
  Form,
  Input,
  Icon,
  Button,
  Checkbox,
  Message,
} from "semantic-ui-react";
import { STRINGS } from "../constants";
import { useHistory } from "react-router-dom";
import { firebase } from "../../../firebase";
import { isAuthorized } from "../../../helpers/helpers";
import { allRoles } from "../../../constants";

const { WELCOME, EMAIL, PASSWORD, LOGIN, REMEMBER, FORGOT } = STRINGS;

export const LoginForm: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const history = useHistory();

  const handleLogin = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      const tokenResult = await firebase.auth().currentUser?.getIdTokenResult();
      const roles = tokenResult?.claims.roles
        ? tokenResult?.claims.roles
        : [tokenResult?.claims.role];

      if (isAuthorized(allRoles, roles)) {
        history.push("/");
      } else {
        await firebase.auth().signOut();
        setMessage("Invalid Login Details");
      }
    } catch (error: any) {
      setMessage(error.message);
    }
  };

  return (
    <>
      <div className='m-login__heading'>
        <h3>{WELCOME}</h3>
        <div className='bar -md'></div>
      </div>
      {message && (
        <Message negative>
          <Message.Header>{message}</Message.Header>
        </Message>
      )}
      <Form>
        <Form.Field
          control={Input}
          type='email'
          label={EMAIL}
          className='a-login__input'
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
        ></Form.Field>
        <div className='m-login__passwordField'>
          <label htmlFor='password'>{PASSWORD}</label>
          <input
            name='password'
            type={passwordVisible ? "text" : "password"}
            value={password}
            onChange={(e: any) => {
              setPassword(e.target.value);
            }}
          />
          <Icon
            name={!passwordVisible ? "eye" : "eye slash"}
            onClick={() => {
              setPasswordVisible(!passwordVisible);
            }}
          ></Icon>
        </div>
        <Button className='a-login__button' fluid onClick={handleLogin}>
          {LOGIN}
        </Button>
        <div className='m-login__formOptions'>
          <Checkbox label={REMEMBER}></Checkbox>
          <p
            onClick={() => {
              history.push("/login/reset");
            }}
          >
            {FORGOT}
          </p>
        </div>
      </Form>
    </>
  );
};
