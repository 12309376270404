import React, { FC, useEffect } from "react";
import { PropsInterface } from "./../types";
import { EditTradesmanModal } from "./EditTradesmanModal";
import { useSelector } from "react-redux";
import { CatServiceSelectors } from "../../../redux/store";
import { types } from "../../../service";
import Island from "../../../_components/Island";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_ALL_TRADESMEN,
  GET_DEFAULT_TRANSFER_RECIPIENTS_WITH_BANK_INFORMATION,
} from "../../../graphql/query";
import { DataSection } from "../../../pages/orders/job/_components/DataSection";
import { DataField } from "../../../pages/orders/job/_components/DataField";
import styles from "./styles.module.scss";
import clsx from "clsx";
import RequestFailed from "../../../_components/RequestFailed";

const TradesmanUserDetails: FC<PropsInterface> = (props: PropsInterface) => {
  const { selectCategories } = CatServiceSelectors;
  const allCategory = useSelector(selectCategories);
  const allcategories = Object.entries(allCategory);

  const { data: tradesmanData, loading, refetch } = useQuery(GET_ALL_TRADESMEN);

  const [
    getDefaultTransferRecipients,
    {
      loading: getDefaultTransferRecipientsLoading,
      data: getDefaultTransferRecipientsData,
      error,
    },
  ] = useLazyQuery(GET_DEFAULT_TRANSFER_RECIPIENTS_WITH_BANK_INFORMATION);

  const allTradesman: types.ITradesmenData[] =
    tradesmanData?.listAllTradesmen.data;

  const user = allTradesman?.find((tradesman: types.ITradesmenData) => {
    return props.match.params.userId.toString() === tradesman.id;
  });

  useEffect(() => {
    if (!loading && user) {
      getDefaultTransferRecipients({
        variables: {
          userId: user.userId,
        },
      });
    }
  }, [loading, user, getDefaultTransferRecipients]);

  const history = useHistory();

  if (loading || getDefaultTransferRecipientsLoading) {
    return <div className='loading'>loading...</div>;
  }

  if (!user || error) {
    return <RequestFailed />;
  }

  const refreshData = () => {
    refetch();
  };

  let imgTransformVar = "/upload/w_320/";

  const {
    userId,
    firstName,
    lastName,
    email,
    phoneNumber,
    level,
    rewardPoints,
    imageUrl,
    location,
    categoryId,
  } = user;

  const category = allcategories.filter(([id, rest]) => {
    return id === categoryId;
  });
  const name = category.map(([id, data]) => {
    return data.name;
  });

  const transferRecipient =
    getDefaultTransferRecipientsData?.defaultTransferRecipients[0];

  return (
    <div>
      <Island header='Pro Information'>
        <div className={styles.dataWrapper}>
          <DataSection>
            <DataField>
              <h5 className={styles.fieldHeader}>Name</h5>
              <p
                data-testid='pro-name'
                className={clsx(styles.fieldDescription, styles.capitalize)}
              >
                {`${firstName} ${lastName}`}
              </p>
            </DataField>

            <DataField>
              <h5 className={styles.fieldHeader}>Email</h5>
              <p className={clsx(styles.fieldDescription, styles.capitalize)}>
                {email}
              </p>
            </DataField>
            <DataField>
              <h5 className={styles.fieldHeader}>Phone Number</h5>
              <p className={clsx(styles.fieldDescription, styles.capitalize)}>
                {phoneNumber}
              </p>
            </DataField>
            <DataField>
              <h5 className={styles.fieldHeader}>Category</h5>
              <p className={clsx(styles.fieldDescription, styles.capitalize)}>
                {name[0]}
              </p>
            </DataField>
            <DataField>
              <h5 className={styles.fieldHeader}>Level</h5>
              <p className={clsx(styles.fieldDescription, styles.capitalize)}>
                {level}
              </p>
            </DataField>
            <DataField>
              <h5 className={styles.fieldHeader}>Location</h5>
              <p className={clsx(styles.fieldDescription, styles.capitalize)}>
                {`${location.buildingNumber}, ${location.streetName}, ${location.area}, ${location.location}, ${location.city}`}
              </p>
            </DataField>
          </DataSection>
          <DataSection>
            <DataField>
              <h5 className={clsx(styles.fieldHeader)}>Reward Point</h5>
              <p className={clsx(styles.fieldDescription, styles.capitalize)}>
                {rewardPoints}
              </p>
            </DataField>
            {getDefaultTransferRecipientsData && (
              <>
                <DataField>
                  <h5 className={clsx(styles.fieldHeader)}>Account Number</h5>
                  <p
                    className={clsx(styles.fieldDescription, styles.capitalize)}
                  >
                    {transferRecipient.bankInformation.accountNumber}
                  </p>
                </DataField>
                <DataField>
                  <h5 className={clsx(styles.fieldHeader)}>Bank</h5>
                  <p
                    className={clsx(styles.fieldDescription, styles.capitalize)}
                  >
                    {transferRecipient.bankInformation.bankName}
                  </p>
                </DataField>
                <DataField>
                  <h5 className={clsx(styles.fieldHeader)}>Account Name</h5>
                  <p
                    className={clsx(styles.fieldDescription, styles.capitalize)}
                  >
                    {transferRecipient.bankInformation.accountName}
                  </p>
                </DataField>
              </>
            )}
          </DataSection>
          <DataSection>
            <DataField>
              <h5 className={clsx(styles.fieldHeader)}>Profile Image</h5>
              <img
                src={imageUrl?.replace("/upload/", `${imgTransformVar}`)}
                alt='laborhack pro'
              />
            </DataField>
          </DataSection>
          <div className='o-tradesmen__details-buttons'>
            <EditTradesmanModal
              userId={userId}
              refresh={refreshData}
              firstname={firstName}
              propEmail={email}
              lastname={lastName}
            />
            <Button
              className='o-tradesmen__add-button'
              onClick={() => {
                const userId = props.match.params.userId;
                history.push(`/tradesmen/user/${userId}/performance`);
              }}
            >
              {" "}
              See Performance
            </Button>
          </div>
          <div className='o-tradesman-performance'></div>
        </div>
      </Island>
    </div>
  );
};

export default TradesmanUserDetails;
