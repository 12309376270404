import { gql, useQuery } from "@apollo/client";
import React, { FC } from "react";
import { Pro, ProApplication } from "../../types";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { VField as Field } from "../../_components/VField/VField";

const GET_PRO_APPLICATION = gql`
  query GetProApplication($id: String!) {
    proApplicationByProId(id: $id) {
      id
      userId
      firstName
      lastName
      phoneNumber
      email
      address
      stateOfResidence
      gender
    }
  }
`;

export interface DetailsProps {
  pro: Pro;
}

export const Details: FC<DetailsProps> = ({ pro }) => {
  const { id, associatedLaborHackEmail } = pro;

  const { data, error, loading } = useQuery<{
    proApplicationByProId: ProApplication;
  }>(GET_PRO_APPLICATION, {
    variables: {
      id,
    },
    fetchPolicy: "cache-first",
  });

  if (loading) return <Loading />;

  if (error || !data) return <RequestFailed />;

  return (
    <div className='flex flex-col gap-4'>
      <Field label={"Email"} value={associatedLaborHackEmail} />
      <Field
        label={"Phone Number"}
        value={data.proApplicationByProId.phoneNumber || "N/A"}
      />
      <Field label={"Address"} value={data.proApplicationByProId.address} />
      <Field
        label={"State of Residence"}
        value={data.proApplicationByProId.stateOfResidence}
      />
      <Field label={"Gender"} value={data.proApplicationByProId.gender} />
    </div>
  );
};
