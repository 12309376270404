import React, { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Icon, Modal, Radio, RadioProps } from "semantic-ui-react";
import Input from "@laborhack/input";
import Button from "@laborhack/custom-button";
import moment from "moment";
import { components as LayoutComponents } from "../../../../../components/layout";
import FormStep from "../../../../../_components/FormStep";
import Island from "../../../../../_components/Island";
import Select from "../../../../../_components/Select";
import ManageSchedule from "./components/ManageSchedule";

import styles from "./styles.module.scss";
import {
  Contract,
  ContractInterval,
  NonBillableDate,
  ProposedContractInfo,
} from "../../../types";
import { Confirmation } from "../../../../../_components/Confirmation/Confirmation";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { NON_REFERENCED_CONTRACT_FIELDS } from "../../../graphql/fragments";
import Loading from "../../../../../_components/Loading";
import RequestFailed from "../../../../../_components/RequestFailed";
import { isBefore, startOfDay } from "date-fns";
import { SelectContractPro } from "../../../components/SelectContractPro/SelectContractPro";
import {
  forceActualDay,
  formatNonBillableDate,
} from "../../../../../helpers/helpers";
import BackButton from "../../../../../_components/BackButton";
import { SingleDatePicker } from "react-dates";
import { WorkingDays } from "../../../../../types";

const { Trail } = LayoutComponents;

const GET_PROPOSED_CONTRACT_END_DATE = gql`
  query GetProposedContractEndDate($proposed: ProposedContractInfo!) {
    proposedContractEndDate(proposed: $proposed)
  }
`;

const GET_CONTRACT = gql`
  ${NON_REFERENCED_CONTRACT_FIELDS}
  query GetContract($id: String!) {
    contract(id: $id) {
      pro {
        firstName
        lastName
      }
      ...NonReferencedContractFields
    }
  }
`;

const ASSIGN_CONTRACT = gql`
  ${NON_REFERENCED_CONTRACT_FIELDS}
  mutation AssignContract(
    $proposed: ProposedContractInfo!
    $contractId: String!
    $proId: String!
  ) {
    assignContract(
      proposed: $proposed
      contractId: $contractId
      proId: $proId
    ) {
      pro {
        firstName
        lastName
      }
      recruitment {
        id
      }
      ...NonReferencedContractFields
    }
  }
`;

type StartDateType = "Requested" | "Custom";

export const AssignContract: FC = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const [selectedPro, setSelectedPro] = React.useState<{
    id: string;
    name: string;
  }>();

  const { loading, data, error } = useQuery<{ contract: Contract }>(
    GET_CONTRACT,
    {
      variables: {
        id,
      },
    }
  );

  const [nonWorkingDays, setNonWorkingDays] = React.useState<WorkingDays[]>([]);

  const [
    changeContractLengthDisabled,
    setChangeContractLengthDisabled,
  ] = React.useState(true);

  const [
    contractChangeConfirmationOpen,
    setContractChangeConfirmationOpen,
  ] = React.useState(false);

  const [contractLength, setContractLength] = React.useState<number>(1);

  const [
    contractInterval,
    setContractInterval,
  ] = React.useState<ContractInterval>("MONTHS");

  const [usedStartDate, setUsedStartDate] = React.useState<StartDateType>(
    "Requested"
  );

  const [customStartDate, setCustomStartDate] = React.useState<Date>(
    forceActualDay(startOfDay(new Date()))
  );

  const [focusedInput, setFocusedInput] = React.useState<boolean>(false);

  const [selectProModalOpen, setSelectProModalOpen] = React.useState(false);

  const [nonBillableDates, setNonBillableDates] = React.useState<
    NonBillableDate[]
  >([]);

  const [assignContract, assignContractResponse] = useMutation<{
    assignContract: Contract;
  }>(ASSIGN_CONTRACT, {
    onCompleted: (data) => {
      history.push(`/recruitment/${data.assignContract.recruitment.id}`);
    },
  });

  const startDates = {
    Requested: new Date(data?.contract.requestedStartDate || ""),
    Custom: customStartDate,
  };

  const [getEndDate, proposedContractEndDateResponse] = useLazyQuery<{
    proposedContractEndDate: Date;
  }>(GET_PROPOSED_CONTRACT_END_DATE, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data) {
      const {
        contract: { contractLength, contractInterval, nonWorkingDays },
      } = data;

      setContractLength(contractLength);
      setContractInterval(contractInterval);
      nonWorkingDays && setNonWorkingDays(nonWorkingDays);
    }
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <RequestFailed errorMessage={error?.message} />;
  }

  const handleClose = () => {
    setContractChangeConfirmationOpen(false);
  };

  const fetchEndDate = (
    proposedContractInfoOverride?: Partial<ProposedContractInfo>
  ) => {
    getEndDate({
      variables: {
        proposed: {
          contractStartDate: startDates[usedStartDate],
          contractLength,
          contractInterval,
          nonWorkingDays,
          nonBillableDates: nonBillableDates.map(formatNonBillableDate),
          ...proposedContractInfoOverride,
        },
      },
    });
  };

  const handleStartDateTypeChange = (
    e: React.SyntheticEvent,
    { value }: RadioProps
  ) => {
    setUsedStartDate(value as StartDateType);
    fetchEndDate({ contractStartDate: startDates[value as StartDateType] });
  };

  const handleCustomStartDateChange = (date: Date) => {
    setCustomStartDate(date);
    fetchEndDate({ contractStartDate: date });
  };

  const handleContractLengthChange = (value: number) => {
    setContractLength(value);
    fetchEndDate({ contractLength: value });
  };

  const handleContractIntervalChange = (value: ContractInterval) => {
    setContractInterval(value);
    fetchEndDate({ contractInterval: value });
  };

  const handleNonWorkingDaysChange = (value: WorkingDays[]) => {
    setNonWorkingDays(value);
    fetchEndDate({ nonWorkingDays: value });
  };

  const handleNonBillableDatesChange = (value: NonBillableDate[]) => {
    setNonBillableDates(value);
    fetchEndDate({ nonBillableDates: value.map(formatNonBillableDate) });
  };

  const { contract } = data;

  const handleProSelect = (pro: { id: string; name: string }) => {
    setSelectedPro(pro);
    setSelectProModalOpen(false);
  };

  const assignDisabled = !selectedPro;

  return (
    <>
      <Modal
        open={selectProModalOpen}
        onClose={() => setSelectProModalOpen(false)}
        closeOnDimmerClick
        closeIcon
      >
        <SelectContractPro
          proposedContract={{
            contractStartDate: startDates[usedStartDate],
            contractLength,
            contractInterval,
            nonWorkingDays,
            nonBillableDates: nonBillableDates.map(formatNonBillableDate),
          }}
          categoryId={contract.categoryId}
          onSelect={handleProSelect}
        />
      </Modal>
      <Confirmation
        prompt='This has been confirmed by the client'
        onReject={handleClose}
        onConfirm={() => {
          setChangeContractLengthDisabled(false);
          handleClose();
        }}
        open={contractChangeConfirmationOpen}
      />
      <BackButton />
      <Island header={<Trail root='Assign Contract' child={id} />}>
        <div>
          <FormStep
            step={1}
            label={
              <div className={styles.changeContractLabel}>
                <p>Change Contract Length</p>
                {changeContractLengthDisabled ? (
                  <Icon
                    style={{
                      cursor: "pointer",
                    }}
                    name='lock'
                    onClick={() => {
                      setContractChangeConfirmationOpen(true);
                    }}
                  />
                ) : (
                  <Icon
                    style={{
                      cursor: "pointer",
                    }}
                    name='lock open'
                    onClick={() => {
                      setChangeContractLengthDisabled(true);
                    }}
                  />
                )}
              </div>
            }
            disabled={changeContractLengthDisabled}
          >
            <div className={styles.field}>
              <label>Contract Length</label>
              <div className={styles.contract}>
                <Input
                  disabled={changeContractLengthDisabled}
                  className={styles.input}
                  value={contractLength?.toString() || ""}
                  onChange={(value) => {
                    handleContractLengthChange(value ? Number(value) : 1);
                  }}
                />
                <Select
                  value={contractInterval}
                  disabled={changeContractLengthDisabled}
                  options={[
                    {
                      key: "Monthly",
                      value: "MONTHS",
                      text: "Months",
                    },
                    {
                      key: "Weekly",
                      value: "WEEKS",
                      text: "Weeks",
                    },
                    {
                      key: "Daily",
                      value: "DAYS",
                      text: "Days",
                    },
                  ]}
                  onChange={(e, { value }) =>
                    handleContractIntervalChange(value as ContractInterval)
                  }
                />
              </div>
            </div>
          </FormStep>
          <FormStep step={2} label='Set Start Date'>
            <div className={styles.setStartDate}>
              <Radio
                style={{
                  marginBottom: "1rem",
                }}
                label={`Requested Start Date (${new Date(
                  contract.requestedStartDate
                ).toDateString()})`}
                value='Requested'
                checked={usedStartDate === "Requested"}
                onChange={handleStartDateTypeChange}
              />
              <Radio
                label='Custom Start Date'
                value='Custom'
                checked={usedStartDate === "Custom"}
                onChange={handleStartDateTypeChange}
              />

              {usedStartDate === "Custom" && (
                <div className={styles.calendar}>
                  <div className={styles.field}>
                    <label>Proposed Start Date</label>
                    <SingleDatePicker
                      showDefaultInputIcon
                      date={customStartDate ? moment(customStartDate) : null}
                      onDateChange={(date) => {
                        date &&
                          handleCustomStartDateChange(
                            forceActualDay(date.toDate())
                          );
                      }}
                      focused={focusedInput}
                      onFocusChange={({ focused }) => setFocusedInput(focused)}
                      id='your_unique_id'
                      numberOfMonths={1}
                      isOutsideRange={(day) =>
                        isBefore(
                          forceActualDay(day.toDate()),
                          startOfDay(new Date())
                        )
                      }
                    />
                  </div>
                </div>
              )}

              <div className={styles.endDate}>
                {proposedContractEndDateResponse.loading && (
                  <Icon name='spinner' loading />
                )}
                {proposedContractEndDateResponse.data && (
                  <p className='text-primary-500'>
                    End Date will be{" "}
                    <strong>{`${new Date(
                      proposedContractEndDateResponse.data.proposedContractEndDate
                    ).toDateString()}`}</strong>
                  </p>
                )}
              </div>
            </div>
          </FormStep>
          <FormStep step={3} label='Manage Contract Schedule'>
            <ManageSchedule
              nonWorkingDays={nonWorkingDays}
              setNonWorkingDays={handleNonWorkingDaysChange}
              nonBillableDates={nonBillableDates}
              setNonBillableDates={handleNonBillableDatesChange}
              startDate={startDates[usedStartDate]}
              endDate={
                new Date(
                  proposedContractEndDateResponse.data
                    ?.proposedContractEndDate || ""
                )
              }
            />
          </FormStep>
          <FormStep step={4} label='Select Pro'>
            <div>
              {selectedPro?.id ? (
                <div className={styles.selectedProCard}>
                  <div className={styles.details}>
                    <h4>{selectedPro.name}</h4>
                  </div>

                  <Icon
                    name='trash'
                    color='red'
                    onClick={() => setSelectedPro(undefined)}
                  />
                </div>
              ) : (
                <Button onClick={() => setSelectProModalOpen(true)}>
                  Choose Pro
                </Button>
              )}
            </div>
          </FormStep>
        </div>
        <div className={styles.actions}>
          <Button
            loading={assignContractResponse.loading}
            disabled={assignDisabled}
            variant='success'
            onClick={() => {
              selectedPro &&
                assignContract({
                  variables: {
                    contractId: id,
                    proId: selectedPro.id,
                    proposed: {
                      contractStartDate: startDates[usedStartDate],
                      contractLength,
                      contractInterval,
                      nonWorkingDays,
                      nonBillableDates: nonBillableDates.map(
                        formatNonBillableDate
                      ),
                    },
                  },
                });
            }}
          >
            Assign
          </Button>
        </div>
      </Island>
    </>
  );
};
