import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import PaymentRequests from './PaymentRequests';
import PaymentConfirmations from './PaymentConfirmations';
import PaymentRequestView from './PaymentRequestView';
import PaymentConfirmationView from './PaymentConfirmationView';

export const Payment: FC = () => {
  const { path } = useRouteMatch();

  console.log(path);

  return (
    <Switch>
      <Route path={`${path}/requests/:id`}>
        <PaymentRequestView />
      </Route>
      <Route path={`${path}/requests`}>
        <PaymentRequests />
      </Route>
      <Route path={`${path}/confirmations/:id`}>
        <PaymentConfirmationView />
      </Route>
      <Route path={`${path}/confirmations`}>
        <PaymentConfirmations />
      </Route>
    </Switch>
  );
};
