import gql from 'graphql-tag';

export const CHANGE_CATEGORY = gql`
  mutation ChangeCategory($jobId: String!, $categoryId: String!) {
    changeCustomTaskCategory(jobId: $jobId, categoryId: $categoryId) {
      id
      custom {
        categoryId
      }
    }
  }
`;

export const EDIT_CUSTOM_TASK = gql`
  mutation EditCustomTask($input: EditCustomTaskInput!) {
    editCustomTask(input: $input) {
      id
      custom {
        categoryId
        name
        description
      }
    }
  }
`;
