import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Job, Order, TradesmanAssignment } from '../../../types';
import { SelectTradesmanAssignment } from './SelectTradesmanAssignment';

export const CreateAssignment: FC<{
  order: Order;
  jobs: string[];
  selectedCategory: string;
  assignments: TradesmanAssignment[];
  refetchAssignments: any;
}> = ({ order, jobs, selectedCategory, refetchAssignments, assignments }) => {
  const { path } = useRouteMatch();

  if (!order) {
    return <div>Loading...</div>;
  }

  const jobIdsToJob: {
    [x: string]: Job;
  } = {};

  order.jobs.forEach((job) => {
    jobIdsToJob[job.id] = job;
  });

  const selectedJobIdsToJob: {
    [x: string]: Job;
  } = {};

  jobs.forEach((id) => {
    selectedJobIdsToJob[id] = jobIdsToJob[id];
  });

  return (
    <Switch>
      <Route path={`${path}`}>
        <SelectTradesmanAssignment
          selectedCategory={selectedCategory}
          selectedJobs={selectedJobIdsToJob}
          order={order}
          refetchAssignments={refetchAssignments}
          assignments={assignments}
        />
      </Route>
    </Switch>
  );
};
