import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';

import styles from './styles.module.scss';

export interface ChecklistItemProps {
  checked?: boolean;
}

export const ChecklistItem: FC<ChecklistItemProps> = ({
  checked,
  children,
}) => {
  return (
    <div className={styles.checklistItem}>
      <p>{children}</p>
      <Icon
        name={checked ? 'check circle' : 'remove circle'}
        color={checked ? 'green' : 'red'}
      />
    </div>
  );
};
