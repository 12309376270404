import React, { PropsWithChildren } from "react";

export interface EmbeddedMapProps {
  placeId: string;
}

export const EmbeddedMap = ({
  placeId,
}: PropsWithChildren<EmbeddedMapProps>) => {
  return (
    <iframe
      title='Embedded Map'
      width='100%'
      height='100%'
      loading='lazy'
      referrerPolicy='no-referrer-when-downgrade'
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=place_id:${placeId}`}
    />
  );
};
