import { gql } from "@apollo/client";

export const GET_NEXT_INVOICE_PAYMENT = gql`
  query GetNextInvoicePayment($invoiceId: String!) {
    nextInvoicePayment(invoiceId: $invoiceId) {
      total
      discount
      subTotal
      materials
      labor
      laborHackFee
      nextPaymentPercentage
      materialCommission
      laborCommission
      tax
    }
  }
`;

export const LIST_INVOICES = gql`
  query ListInvoices($first: Int, $after: String) {
    invoices(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          type
          canceled
          invoiceNumber
          invoiceDate
          isFullyPaid
          hasAtLeastOnePayment
          options {
            materialCommissionCap
          }
          recipient {
            name
            email
            phoneNumber
          }
          discount
          appliedDiscount {
            id
            code
            value
          }
          assignments {
            id
            jobs {
              custom {
                name
              }
              task {
                name
              }
              invoiceDetails {
                materials {
                  name
                  unitPrice
                  quantity
                }
                labor
              }
            }
          }
          splitPayments {
            percentage
            payment {
              id
              transactionReference
              transactionDate
              status
              amount
            }
          }
          payment {
            id
            transactionReference
            transactionDate
            status
            amount
            checkout
            email
          }
        }
      }
    }
  }
`;

export const GET_INVOICE = gql`
  query GetInvoice($invoiceId: String!) {
    getInvoiceById(invoiceId: $invoiceId) {
      status
      message
      data {
        id
        type
        canceled
        invoiceNumber
        inspectionAmount
        invoiceDate
        discount
        appliedDiscount {
          id
          code
          value
        }
        isFullyPaid
        hasAtLeastOnePayment
        options {
          materialCommissionCap
        }
        marketplaceRequest {
          id
          jobs {
            trade {
              name
            }
            matches {
              pro {
                id
              }
            }
          }
        }
        reimbursements {
          id
          excludeTax
          payout {
            id
            worksheet {
              contract {
                pro {
                  firstName
                  lastName
                }
              }
              payroll {
                period {
                  start
                  end
                }
              }
            }
            breakdown {
              gross
              net
              tax
            }
          }
        }
        recruitment {
          id
          recruitmentType
          jobs {
            category {
              name
            }
            matches {
              pro {
                id
              }
              potentialMonthlyGrossSalary
            }
          }
        }
        recruitmentData {
          contract {
            id
            contractInterval
            contractLength
            pro {
              firstName
              lastName
              categoryId
            }
            proLevel
          }
          period {
            start
            end
          }
          numberOfBilledDays
          agreedDailyRate
        }
        order {
          id
          orderCode
          client {
            userId
          }
        }
        assignments {
          id
          progress
          order {
            id
            orderCode
            inspectionPayment {
              status
              amount
            }
            locationSchedules {
              locationId
              scheduledDate
            }
            isEmergency
          }
          jobs {
            id
            jobCode
            custom {
              name
              description
              resourceUrl
              subCategoryId
              categoryId
            }
            task {
              id
              name
              description
              estimatedCost
              categoryId
              subCategoryId
              imageUrl
            }
            finalCost
            status
            completedAt
            payment {
              transactionReference
              transactionDate
              amount
              status
            }
            contact {
              notes
              location {
                id
                buildingNumber
                streetName
                area
                location
                landmark
                city
              }
            }
            invoiceDetails {
              materials {
                name
                unitPrice
                quantity
              }
              labor
            }
          }
          date
        }
        recipient {
          name
          email
          phoneNumber
          address
        }
        payment {
          id
          transactionDate
          transactionReference
          status
          providerId
        }
        splitPayments {
          percentage
          payment {
            id
            status
            amount
            transactionDate
            transactionReference
            providerId
          }
        }
      }
    }
  }
`;
