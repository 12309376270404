import { ProCertificationResult } from "@/types"
import { Button, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react"
import { parseISO } from "date-fns"
import format from "date-fns/format"
import { FC } from "react"

export interface ViewAttemptsModalProps {
  isOpen: boolean
  certificationAttempts: ProCertificationResult[]
  onClose: () => void
  certificationName: string
}
export const ViewAttemptsModal: FC<ViewAttemptsModalProps> = ({
  isOpen, certificationAttempts, onClose, certificationName }: ViewAttemptsModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth="480px">
          <ModalHeader
            width="400px"
            className='text-primary-500 text-xl font-bold m-0'>{certificationName}{" "}Attempts</ModalHeader>
          <ModalCloseButton />
          {certificationAttempts.length > 0 ? (
            <TableContainer scrollBehavior="auto" pb="20" ml="5" mr="5">
              <Table variant="striped" colorScheme="blue">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Score</Th>
                    <Th>Date</Th>
                    <Th>Source</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    certificationAttempts.map((attempt, index) => (
                      <Tr key={certificationName+index}>
                        <Td>{index + 1}</Td>
                        <Td>{attempt.score}</Td>
                        <Td>{format(parseISO(attempt.submittedAt.toString()), "dd MMM yyyy")}</Td>
                        <Td>{(attempt.attemptId) ? "LMS" : "Manual/Legacy"}</Td>
                      </Tr>
                    ))
                  }
                </Tbody>
              </Table>
            </TableContainer>
          ) : <Text textAlign="center" pb="20px">No attempts found</Text>}

        </ModalContent>
      </Modal>
    </>
  )
}
