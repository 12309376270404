import React from "react";
import Button from "@laborhack/custom-button";
import { Icon, Select } from "semantic-ui-react";

export interface SelectFieldProps {
  label: string;
  value: string;
  options: {
    text: string;
    value: string;
  }[];
  editable?: boolean;
  onSave?: (value: string) => void;
  saving?: boolean;
}

const SelectField = ({
  label,
  value,
  options,
  editable,
  onSave,
  saving,
}: SelectFieldProps) => {
  const [editMode, setEditMode] = React.useState(false);

  const [inputValue, setInputValue] = React.useState(value);

  const textForValue = options.find((option) => option.value === value)?.text;

  return (
    <div className='flex flex-col gap-1'>
      <p className='font-semibold text-sm text-primary-300 m-0 p-0'>{label}</p>
      {editMode ? (
        <div className='flex gap-6 items-center'>
          <Select
            options={options.map((option) => ({
              key: option.value,
              ...option,
            }))}
            value={inputValue}
            onChange={(e, { value }) => setInputValue(value as string)}
          />
          <div className='flex gap-4 items-center'>
            <Button
              type='link'
              onClick={() => {
                setEditMode(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type='subtle'
              variant='success'
              onClick={() => {
                onSave && onSave(inputValue);
                setEditMode(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className='flex items-center gap-2'>
          <p className='font-semibold text-base text-primary-500 m-0 p-0'>
            {textForValue}
          </p>
          {editable &&
            (saving ? (
              <Icon name='spinner' loading />
            ) : (
              <Button
                size='small'
                variant='basic'
                onClick={() => {
                  setEditMode(true);
                }}
                type='subtle'
              >
                Edit
              </Button>
            ))}
        </div>
      )}
    </div>
  );
};

export default SelectField;
