import React, { FC } from 'react';
import Badge from '../../../../_components/Badge';
import { AssignmentPayment } from '../../types';

import styles from './styles.module.scss';

export interface HistoryItemProps {
  proName: string;
  assignmentPayment: AssignmentPayment;
}

export const HistoryItem: FC<HistoryItemProps> = ({
  assignmentPayment,
  proName,
}) => {
  return (
    <div className={styles.historyItem}>
      <div className={styles.historyAvatar} />
      <p>{proName}</p>
      <Badge
        type="cancelled"
        inverted
        text={`- NGN ${assignmentPayment.transfer.amount}`}
      />
      <p>on {`${new Date(assignmentPayment.createdAt).toLocaleString()}`}</p>
    </div>
  );
};
