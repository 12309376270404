import React, { FC, useEffect } from "react";
import { components as LayoutComponents } from "../../layout";
import { components as AllAdminComponents } from "./../../alladmin";
import { components as AdminComponents } from "./../../adminuser";
import { components as CategoriesComponent } from "./../../allcategories";
import { components as SubCategory } from "./../../subcategory";
import { components as Category } from "./../../category";
import { components as TasksComponents } from "./../../tasks";
import { components as AllTradesmenComponent } from "./../../alltradesmen";
import { components as TradesmenComponent } from "./../../tradesmen";
import { components as AllClientsComponent } from "./../../allClients";
import { components as ClientComponent } from "./../../client";
import { components as OrderComponents } from "../../orders";
import { components as LoginComponents } from "../../login";
import { components as AllTasksComponents } from "../../alltasks";
import { components as InvoiceComponents } from "../../invoices";
import { components as RefundComponents } from "../../refund";
import "antd/dist/antd.css";
import { Switch, Route } from "react-router-dom";
import { userContext, hooks } from "../../../firebase";
import { ProtectedRoute } from "./ProtectedRoute";
import { components as OverviewComponents } from "../../overview";
import { useDispatch } from "react-redux";
import { CatServiceActions } from "../../../redux/store";
import { useApolloClient } from "@apollo/client";
import {
  ApplicationContext,
  useApplicationConfig,
} from "../../../context/ApplicationContext";
import AdminDetail from "../../../pages/admins/view";
import Payment from "../../../pages/payment";
import Recruitments from "../../../pages/recruitments";
import Businesses from "../../../pages/businesses";
import { CreateClient } from "../../../pages/clients/CreateClient";
import { MarketplaceRequests } from "../../../pages/marketplace-requests";
import { Certifications } from "../../../pages/certifications";
import { Pros } from "../../../pages/pros";
import { ProTradeRequirements } from "../../../pages/assessments";
import ContractPayouts from "../../../pages/contract-payouts";
import ReferrerPayouts from "../../../pages/referrer-payouts";
import { Subscriptions } from "../../../pages/subscriptions";
import Referrers from "../../../pages/referrers";
import SubscriptionRequestPayouts from "@/pages/subscription-request-payouts";

const { Layout } = LayoutComponents;
const { AllAdmins } = AllAdminComponents;
const { AdminForm } = AdminComponents;
const { DisplayCategories } = CategoriesComponent;
const { SubCategoryForm } = SubCategory;
const { CategoryForm } = Category;
const { TaskForm } = TasksComponents;
const {
	AllTradesmen,
	TradesmanUserDetails,
	Performance,
} = AllTradesmenComponent;
const { TradesmanReg } = TradesmenComponent;
const { AllClients, ClientUserDetails } = AllClientsComponent;
const { ClientForm } = ClientComponent;
const { Order } = OrderComponents;
const { Login } = LoginComponents;
const { Overview } = OverviewComponents;
const { AllTasks } = AllTasksComponents;
const { AllInvoices, InvoiceView } = InvoiceComponents;
const { Refund } = RefundComponents;

const { useAuth } = hooks;

const { fetchServices } = CatServiceActions;

export const App: FC = () => {
	const authResponse = useAuth();
	const config = useApplicationConfig();
	const dispatch = useDispatch();
	const client = useApolloClient();

	const { toggles } = config;

	useEffect(() => {
		dispatch(fetchServices({ client }));
		// eslint-disable-next-line
	}, []);

	const disableClientRegistration = () => {
		return toggles && toggles.enable_client_registration ? (
			<Route path="/clients/create">
				<CreateClient />
			</Route>
		) : null;
	};

	return (
		<ApplicationContext.Provider value={config}>
			<userContext.Provider value={authResponse}>
				<Switch>
					<Route path="/login">
						<Login />
					</Route>
					<ProtectedRoute path="/">
						<Layout>
							<Switch>
								<Route exact path="/">
									<Overview />
								</Route>
								<Route path="/payments">
									<Payment />
								</Route>
								<Route path="/contract-payouts">
									<ContractPayouts />
								</Route>
								<Route path="/referrer-payouts">
									<ReferrerPayouts />
								</Route>
								<Route path="/refunds">
									<Refund />
								</Route>
								<Route exact path="/invoices">
									<AllInvoices />
								</Route>
								<Route path="/recruitment">
									<Recruitments />
								</Route>
								<Route path="/invoices/:id">
									<InvoiceView />
								</Route>
								<Route path="/businesses">
									<Businesses />
								</Route>
								<Route path="/referrers">
									<Referrers />
								</Route>
								<Route exact path="/admins">
									<AllAdmins />
								</Route>

								<Route path="/admins/create">
									<AdminForm />
								</Route>
								<Route path="/admins/user/:id" component={AdminDetail} />
								<Route exact path="/tradesmen">
									<AllTradesmen />
								</Route>
								<Route path="/tradesmen/create">
									<TradesmanReg />
								</Route>
								<Route
									path="/tradesmen/user/:userId"
									component={TradesmanUserDetails}
									exact
								/>
								<Route
									path="/tradesmen/user/:userId/performance"
									component={Performance}
								/>
								<Route path="/pros" component={Pros} />
								<Route exact path="/clients">
									<AllClients />
								</Route>
								{disableClientRegistration()}
								<Route path="/clients/:id" component={ClientUserDetails} />

								<Route exact path="/categories">
									<DisplayCategories />
								</Route>
								<Route path="/categories/create-category">
									<CategoryForm />
								</Route>
								<Route path="/categories/create-subcategory">
									<SubCategoryForm />
								</Route>
								<Route path="/categories/create-task">
									<TaskForm />
								</Route>
								<Route path="/categories/:categoryName" component={AllTasks} />
								<Route path="/orders">
									<Order />
								</Route>
								<Route
									path="/marketplace-requests"
									component={MarketplaceRequests}
								/>
								<Route path="/certifications" component={Certifications} />
								<Route path="/assessments" component={ProTradeRequirements} />
								<Route path="/subscriptions" component={Subscriptions} />
								<Route
									path="/subscription-request-payouts"
									component={SubscriptionRequestPayouts}
								/>
							</Switch>
						</Layout>
					</ProtectedRoute>
				</Switch>
			</userContext.Provider>
		</ApplicationContext.Provider>
	);
};
