import { gql } from "@apollo/client";

export const SEND_INVOICE = gql`
  mutation SendInvoice($invoiceId: String!) {
    sendInvoiceToClient(invoiceId: $invoiceId) {
      status
      message
    }
  }
`;

export const CANCEL_INVOICE = gql`
  mutation CancelInvoice($invoiceId: String!) {
    cancelInvoice(invoiceId: $invoiceId) {
      id
      canceled
    }
  }
`;

export const ADD_LOCATION = gql`
  mutation AddLocation($userId: String!, $input: AddLocationInput!) {
    addLocationForClient(userId: $userId, input: $input) {
      id
      buildingNumber
      streetName
      landmark
      area
      location
      city
    }
  }
`;