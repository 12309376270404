import React, { FC } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { EditCategoryModal } from './EditModal';
// import { DeleteCategoryModal } from "./DeleteModal";
import { useHistory } from 'react-router-dom';
import { CatServiceSelectors } from '../../../redux/store';
import Island from '../../../_components/Island';
import { Table, Space } from 'antd';

const DisplayCategories: FC = () => {
  const { selectCategories } = CatServiceSelectors;
  const allCategory = useSelector(selectCategories);
  const allcategories = Object.entries(allCategory);

  const history = useHistory();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <EditCategoryModal categoryId={record.id} />
          <Button
            onClick={() => {
              history.push(`/categories/${record.name}`);
            }}
            size="small"
            primary
          >
            See more
          </Button>
        </Space>
      ),
    },
  ];

  const data = allcategories.map(([id, category]) => ({
    id: id,
    name: category.name,
  }));
  return (
    <Grid columns="1">
      <Grid.Column width="16">
        <Island header="All Categories">
          <div className="o-categories__add-button">
            <Button
              onClick={() => history.push('/categories/create-category')}
              className="o-categories__add-button"
            >
              Create category
            </Button>
            <Button
              className="o-categories__add-button"
              onClick={() => history.push('/categories/create-subcategory')}
            >
              Create subcategory
            </Button>
            <Button
              className="o-categories__add-button"
              onClick={() => history.push('/categories/create-task')}
            >
              Create task
            </Button>
          </div>
          <Table
            columns={columns}
            pagination={false}
            dataSource={data}
            scroll={{
              y: 400,
              scrollToFirstRowOnChange: false,
            }}
          ></Table>
        </Island>
      </Grid.Column>
    </Grid>
  );
};

export default DisplayCategories;
