import React, { FC } from "react";
import { Icon } from "semantic-ui-react";
import Invoice from "@laborhack/invoice";
import { InvoicePayment } from "../../invoices/types";
import { useQuery } from "@apollo/client";
import { GET_NEXT_INVOICE_PAYMENT } from "../../invoices/graphql/query";
import { IInvoice } from "@laborhack/invoice/lib/types";
import { useSelector } from "react-redux";
import { CatServiceSelectors } from "../../../redux/store";
import RequestFailed from "../../../_components/RequestFailed";

const { selectCategories } = CatServiceSelectors;

const OrderInvoice: FC<{
  loading?: boolean;
  invoice?: IInvoice;
}> = ({ invoice, loading }) => {
  const categories = useSelector(selectCategories);

  const {
    loading: nextInvoicePaymentLoading,
    data: nextInvoicePaymentData,
    error: nextInvoicePaymentError,
  } = useQuery<{ nextInvoicePayment: InvoicePayment }>(
    GET_NEXT_INVOICE_PAYMENT,
    {
      variables: {
        invoiceId: invoice?.id,
      },
      fetchPolicy: "network-only",
    }
  );

  if (loading) {
    return <Icon name='spinner' loading size='huge'></Icon>;
  }

  if (!invoice) {
    return <h4>Error rendering invoice</h4>;
  }

  if (nextInvoicePaymentLoading) {
    return <Icon name='spinner' loading size='huge'></Icon>;
  }

  if (nextInvoicePaymentError) {
    return <RequestFailed />;
  }

  return (
    <Invoice
      invoice={invoice}
      summary={nextInvoicePaymentData!.nextInvoicePayment}
      categories={Object.entries(categories).map(([id, value]) => ({
        id,
        ...value,
        proTitle: value.proTitle || "Pro", // use proTitle if it exists, otherwise use "Pro" as a fallback
      }))}
    />
  );
};

export default OrderInvoice;
