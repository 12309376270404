import React, { FC, useRef } from "react";
import { useLocation } from "react-router-dom";
import { SideNavigation } from "./SideNavigation";
import { MainNavigation } from "./MainNavigation";
import { BreadCrumb } from "./BreadCrumb";
import { pathTrailMap } from "../constants";
import { LayoutContext } from "../LayoutContext";

export const Layout: FC = ({ children }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  const [, ...track] = pathname.split("/");

  // Recursive function to create the track
  const labelledTrack = (
    pathTrail: any,
    track: string[],
    currentIndex: number
  ): string[] => {
    if (!track[currentIndex]) {
      return [];
    }

    if (!pathTrail[track[currentIndex]]) {
      return [];
    }

    const result = labelledTrack(
      pathTrail[track[currentIndex]].routes,
      track,
      currentIndex + 1
    );

    return [pathTrail[track[currentIndex]].name, ...result];
  };

  return (
    <LayoutContext.Provider
      value={{
        wrapperRef: wrapperRef.current,
      }}
    >
      <div className="o-layout__wrapper">
        <MainNavigation />
        <div className="o-layout__sideNavWrapper">
          <SideNavigation />
        </div>
        <div className="o-layout__mainWrapper" ref={wrapperRef}>
          <div className="m-layout__breadCrumbs">
            <BreadCrumb
              track={labelledTrack(pathTrailMap, track, 0)}
            ></BreadCrumb>
          </div>
          <main className="o-layout__mainView">{children}</main>
        </div>
      </div>
    </LayoutContext.Provider>
  );
};
