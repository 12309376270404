import React, { FC } from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { allWorkingDays } from "../../../../../../../../../constants";
import { WorkingDays } from "../../../../../../../../../types";

import styles from "./styles.module.scss";

export interface SelectWorkingDaysProps {
  nonWorkingDays: WorkingDays[];
  setNonWorkingDays: (nonWorkingDays: WorkingDays[]) => void;
}

export const SelectWorkingDays: FC<SelectWorkingDaysProps> = ({
  setNonWorkingDays,
  nonWorkingDays,
}) => {
  const handleCheckboxChange = (id: string, { checked }: CheckboxProps) => {
    if (checked) {
      setNonWorkingDays(
        nonWorkingDays.filter((nonWorkingDay) => nonWorkingDay !== id)
      );
    } else {
      setNonWorkingDays([...nonWorkingDays, id as WorkingDays]);
    }
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>Working Days</p>
      <div className={styles.days}>
        {allWorkingDays.map((item: WorkingDays) => (
          <Checkbox
            key={item}
            label={item.substring(0, 3)}
            checked={!nonWorkingDays.includes(item)}
            onChange={(e, data) => {
              handleCheckboxChange(item, data);
            }}
          />
        ))}
      </div>
    </div>
  );
};
